import { useEffect, useState } from "react";
import Task from "./task";
import TreeSortIntroduction from "./tree_sort_introduction";
import {useLocation} from 'react-router-dom';
import toast from 'react-hot-toast';
import { saveTaskResultService } from "../../../../services/test_result";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import ConfirmModal from "../../../dialog/confirm_modal";
import { FormattedMessage } from "react-intl";

export default function TreeSort({test, result, callback,timeSpent}){
    const [initialScreen, setInitialScreen] = useState(true);
    const [tasks,setTasks] = useState([]);
    const [activeTask,setActiveTask] = useState({});
    const [activeTaskIndex,setActiveTaskIndex] = useState(0);
    const [tree,setTree] = useState([]);
    const [path,setPath] = useState([]);
    const [formLoading,setFormLoading] = useState(false);
    const [answers,setAnswers] = useState({});
    const [skippedTasks,setSkippedTasks] = useState([]);
    const location = useLocation();
    const [startTime, setStartTime] = useState(0);
    const [confirm, setConfirm] = useState({open:false});
    const [navPath, setNavPath] = useState([]);
    
    useEffect(()=>{
        setTasks(test.tasks);
    },[test]);

    const saveTreeTasksAnswers = (ans) => {
        var p = location.pathname.substr(1 ,1);
                                
        if(p=="p"){
            callback();
        } else {

            setFormLoading(true);

            saveTaskResultService({
                test_id: test.id,
                result_id: result.id,
                guest_token:ReactSession.get("guest_token"),
                tasks:ans,
                skipped:skippedTasks,
                time_spent:timeSpent,
            }, ReactSession.get("token")).then((response) => {
    
                setFormLoading(false);
    
                if (response.success) {
                    callback();
    
                } else {
                    toast((
                        <div className='toastinner'>
                            {response.message}
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });
                }
            });
            
        }
    }
    const saveTreeTasks = () => {
        saveTreeTasksAnswers(answers);
    }
    const skipTreeTask = (task_id, nav_path) => {

        return () => {
            
            
            var answersLoc = {...answers};

            answersLoc[task_id] = {answer_id: null, time_spent: new Date().getTime() - startTime, path:nav_path } ;

            setAnswers(answersLoc);
            
            if(skippedTasks.length<2){

                if(navPath.length>0){
                    skippedTasks.push({"task_id":task_id,"is_direct":0});
                } else {
                    skippedTasks.push({"task_id":task_id,"is_direct":1});
                }
                
    
                setSkippedTasks(skippedTasks);
                
                if(activeTaskIndex < tasks.length-1){
                    
                    setAnswers(answersLoc);

                    //delete answers[task_id];

                    //setAnswers(answers);
                    
                    setStartTime(new Date().getTime());
    
                    setActiveTaskIndex(activeTaskIndex+1)
    
                    setActiveTask(tasks[activeTaskIndex+1]);
    
                    setTree(test.tree);

                    //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:test.tree}];
                      
                    var tree_loc = [{"id":-1,"title":test.home_name,children:test.tree}];
                      

                    setPath(tree_loc);
    
                    setNavPath([]);



                } else {
                    saveTreeTasksAnswers(answersLoc);
                }
    
            } else {
                console.log(skippedTasks);

                toast((
                    <div className='toastinner'>
                        <>You can skip maximum of 2 tasks.</>
                        <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        };
        
    }
    return (
        <>
            {initialScreen &&
                <TreeSortIntroduction goToFirstTask={
                    ()=>{ 
                        setInitialScreen(false);

                        if(tasks.length > 0){
                            setStartTime(new Date().getTime());
                            setActiveTask(tasks[activeTaskIndex]);
                            //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:test.tree}];
                            var tree_loc = [{"id":-1,"title":test.home_name,children:test.tree}];
                            
                            setTree(test.tree);
                            setPath(tree_loc);
                        }
                        
                        
                    }
                } 
                test={test}
                />
            }
            {!initialScreen &&
                <>
                    
                    <Task activeTaskIndex={activeTaskIndex} totalTasks={tasks.length} test={test} tree={tree} answer={answers[activeTask.id] && answers[activeTask.id]['answer_id']} fullTree={test.tree} navPath={navPath} path={path} task={activeTask} 
                        next={
                            (tree, path, nav_path)=>{
                                
                            
                                setTree(tree);
                                
                                setPath(path);
                                
                                setNavPath(nav_path);  
                                
                            }   
                        }
                        formLoading={formLoading}
                        nextTask={(task_id)=>{
                            if(answers[task_id]){

                                if(activeTaskIndex < tasks.length-1){

                                    var answer = answers[task_id];

                                    answer["time_spent"] = new Date().getTime() - startTime  ;
                                    setAnswers({...answers, [task_id]: answer });

                                    setStartTime(new Date().getTime())
                                    setActiveTaskIndex(activeTaskIndex+1)

                                    setActiveTask(tasks[activeTaskIndex+1]);


                                    setTree(test.tree);

                                    var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:test.tree}];
                                    
                                    

                                    setPath(tree_loc);


                                    setNavPath([]);

                                    window.scrollTo(0, 0);

                                } else {                                
                                    saveTreeTasks();
                                }
                                
                            } else {

                                toast((
                                    <div className='toastinner'>
                                        <><FormattedMessage id="Please select answer before going ahead." /></>
                                        <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                    </div>), {
                                    className: 'errortoast',
                                    position: 'bottom-center'
                                });
                            }
                        }}
                        skipTask={(task_id,nav_path)=>{
                            
                            if(skippedTasks.length<2){
                                if(tasks.length == skippedTasks.length+1){

                                    setConfirm({...confirm, open:true, callback:skipTreeTask(task_id, nav_path)});
                                } else {
                                    const skip = skipTreeTask(task_id, nav_path);

                                    skip();


                                    window.scrollTo(0, 0);
                                }
                            } else {
                                
                                    toast((
                                        <div className='toastinner'>
                                            <><FormattedMessage id="You can skip maximum of 2 tasks." /></>
                                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                        </div>), {
                                        className: 'errortoast',
                                        position: 'bottom-center'
                                    });
                                
                            }
                        }}
                        setAnswer={
                            (task_id, answer_id, nav_path)=>{

                            /* if(answers[task_id]){
                                    toast((
                                        <div className='toastinner'>
                                            <>You cannot change the answer once selected. Please click Next to Proceed.</>
                                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                        </div>), {
                                        className: 'errortoast',
                                        position: 'bottom-center'
                                    });
                                } else {*/
                                setNavPath(nav_path);
                                setAnswers({...answers,[task_id]:{answer_id: answer_id, time_spent: new Date().getTime() - startTime, path:nav_path } });
                                //}
                                
                            }
                        }
                        unsetAnswer={
                            (task_id)=>{

                                var ansLoc = Object.assign({},answers);
                                delete ansLoc[task_id];
                                console.log(task_id)
                                console.log(ansLoc)
                                setAnswers(ansLoc)
                                
                            }
                        }
                    />
                </>
            }
            <ConfirmModal openModal={confirm.open} confirm_message="If you skip this task, you confirm you want to abandon the test. Click cancel to head back to the test or OK to exit." confirm_title="Are you sure?" close={()=>{setConfirm({...confirm, open:false})}} 
                confirm = {()=>{
                    confirm.callback();
                    setConfirm({...confirm, open:false});
                }}
             />
            
        </>

    );
}