import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import Layout from "../layouts/layout";
import LayoutResearcher from "../layouts/layout_researcher";

export default function ErrorPage({errorType}) {
    const [user,setUser] = useState({"role":""});
    const [counter,setCounter] = useState(10);
    const navigate = useNavigate();


    useEffect(() => {

        let user = ReactSession.get("user");
        setUser(user);

    }, []);

    useEffect(() => {
        // set time taken by test
        function tickTime() {
            if(counter === 0){
                navigate('/');
            }else {
                setCounter(prevSeconds => prevSeconds - 1)
            }

        }
        let timeData = setInterval(() => tickTime(), 1000)

        return () => clearInterval(timeData);

    }, [counter]);



    const errorHtml = () =>{
        return (
            <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <p className="error-small-text">
                            BACK HOME IN 00:00:{String(counter).padStart(2, "0")}
                        </p>
                        <h1 className="form-heading">
                            {errorType && errorType === '404' &&
                               <> UMMMM! Well this is Strange </>
                            }
                            {errorType && errorType === 'general' &&
                                 <>OH NO! stay with us</>
                            }

                        </h1>

                    </div>
                </div>
                <div className="full home-marquee-banner section">
                    {errorType && errorType === 'general' &&
                    <div className="home-marque-banner-box">
                        <div className="scrolling-box"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                        <div className="scrolling-box" aria-hidden="true"> This test is not available</div>
                    </div>
                    }
                    {errorType && errorType === '404' &&
                    <div className="home-marque-banner-box">
                        <div className="scrolling-box"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                        <div className="scrolling-box" aria-hidden="true"> Error 404</div>
                    </div>
                    }
                </div>


                <div className="login-form-inner-data">
                    {!(ReactSession.get("token")) &&
                    <Link to={'/tester/login'} className="btn btn-block form-btn login-page-btn mt-0">
                        Login to become a tester
                    </Link>
                    }

                    {(ReactSession.get("token")) &&
                    <Link to={'/'} className="btn btn-block form-btn login-page-btn mt-0">
                        Back to home
                    </Link>
                    }
                </div>

            </div>
        );
    }

    return (
        <>
            {(ReactSession.get("token")) && ((user.role==="Tester")) &&
                <Layout isLoading={false} skipCheck={false} extended404Footer={false} wrapClass={"error-page-wrapper"}>

                    <div className="login-page-wrapper 404-err0r-wrap">
                        <div className="error-inner-404">
                            { errorHtml()}
                        </div>
                    </div>
                </Layout>
            }

            {(ReactSession.get("token")) && ((user.role==="Researcher")) &&
            <LayoutResearcher
                isLoading={false}
                skipCheck={false}
                extended404Footer={false}
                activeMenu={"dashboard"}
                wrapClass={"error-page-wrapper"}
            >

                <div className="login-page-wrapper 404-err0r-wrap">
                    <div className="error-inner-404">
                        { errorHtml()}
                    </div>
                </div>
            </LayoutResearcher>
            }

            {!(ReactSession.get("token")) &&
            <div className="login-page-wrapper 404-err0r-wrap">
                <div className="error-inner-404">
                    <div className="login-page-header">
                        <div className="login-hdr-left">
                            <Link to={"/"} className="logo"><img src={process.env.REACT_APP_URL + "images/logo.svg"}
                                                                 alt="logo"/></Link>
                        </div>
                        <div className="login-hdr-right">
                            <Link to={'/tester/login'}>Become a tester</Link>
                        </div>
                    </div>

                    { errorHtml()}
                </div>
            </div>
            }
            </>
       );
}
