export default function Frame(props) {
    return (
      <div
        // className="stud-tiles-repeat "
        className={`${props.bgstyle} stud-tiles-repeat`}
        onClick={props.onClick}
      >
        <h3 className="h3">{props.questionType}</h3>
        <p className="frames-subheading">{props.subheading}</p>
        <button type="button" className="create-btn" >
          Create{" "}
          <span>
            <img
              src={process.env.REACT_APP_URL + "images/arrow-right.png"}
              alt="img"
            />
          </span>
        </button>
      </div>
    );
    }