export default function FiveSecondsTestIntroduction({goToFirstTask,testlanguage}){
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap five-sec-welcome-page-wrap">
            <div className="container inner-page-container">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="tester-page-selected-img-wrap">
                                <div className="five-sec-test-img-view">
                                    <img src={process.env.REACT_APP_URL+"images/5-sec-img.png"}/>
                                </div>
                            </div>
                        </div>


                        <div className="selected-page-left-side">
                            <h1>{testlanguage=="en"?"5 Seconds Test":"اختبار 5 ثوان"}</h1>
                            <p>{testlanguage=="en"?"We’ll ask you to take a look at the design that will be introduced to you in the following screens. You will have only 5 seconds to explore it and understand what the message it's about. After 5 seconds, the design will disappear and you will be asked to answer some questions related to it.":
                            "سنطلب منك إلقاء نظرة على التصميم الذي سيتم تقديمه لك في الشاشات التالية. سيكون لديك 5 ثوانٍ فقط لاستكشافها وفهم مغزى الرسالة. بعد 5 ثوانٍ سيختفي التصميم وسيُطلب منك الإجابة على بعض الأسئلة المتعلقة به."}<br/><br/>

                            {testlanguage=="en"?"Remember, there’s no right or wrong answer here – just observe as you would do naturally."
                            :"تذكر أنه لا توجد إجابة صحيحة أو خاطئة هنا - فقط لاحظ كما تفعل بشكل طبيعي."}
                            </p>
                            <div className="button-wrapper">
                                <button className="button primary-btn ml-0" onClick={()=>{goToFirstTask()}}>{testlanguage=="en"?"Let’s Start":"لنبدأ"}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}