
import Select from 'react-select';
import { Dropdown, DropdownMultiple } from "reactjs-dropdown-component";
import {DropdownIndicator} from "../../../../lib/helpers";

export default function SelectElement({error, error_class, isMulti, isSearchable, searchable, labelClass, value, options,  label, placeholder, readOnly,onChange}){
    
    return (
      <div
        className={`input-field static-label mb-0 ${
          readOnly ? "read-only-field" : ""
        }`}
      >
        <label className={`${labelClass}`}>{label}</label>
        <div className={error != null ? error_class : ""}>
          <Select
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    value={value}
                    options={options}
                    onChange={onChange}
                    isMulti={isMulti}
                    isDisabled={readOnly ? true : false}
                    components={{DropdownIndicator}}
                />
          {/* <DropdownMultiple
            name="language"
            title="Select languages"
            titleSingular="location"
            list={options}
            onChange={onChange}
            searchable={searchable}
          /> */}
          {error != null && <span className={error_class}>{error}</span>}
        </div>
      </div>
    );
}
