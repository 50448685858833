import { useD3 } from '../../../../hooks/useD3';
import React, {useRef, useState} from 'react';
import * as d3 from 'd3';


function BarChart({ data, marginLeft }) {
  const tooltipref = useRef();
  const tooltip_ele = useRef();
  
  const [tooltip, showTooltip] = useState({
		tooltipLeft:0,
		tooltipTop:0,
		tooltipData:"",
		open: false
	});

  const height = 410;

  const ref = useD3(
    (svg) => {
      //svg.selectAll('*').remove();
      //console.log(ref.current.getBoundingClientRect().width)
      const width = ref.current.getBoundingClientRect().width;
      const margin = { top: 20, right: 30, bottom: 180, left: marginLeft? marginLeft: 160 };

      const x = d3
        .scaleBand()
        .domain(data.map((d) => d.label))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.5);

      const y1 = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.percentage)])
        .rangeRound([height - margin.bottom, margin.top]);

      const xAxis = (g) =>
        g.attr("transform", `translate(0,${height - margin.bottom})`)
        .attr("fill", "rgb(255, 102, 173)")
        .call(
          
          d3
            .axisBottom(x)
            .tickValues(
              data.map((d) => d.label)
                
            )
            .tickSizeOuter(2)
            
        )
        .selectAll("text")  
            .style("text-anchor", "end")
            .attr("fontSize", "12")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-45)" );;

      const y1Axis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .attr("fill", "rgb(255, 102, 173)")
          .call(d3.axisLeft(y1).ticks(null, "s"))
          //.call((g) => g.select(".domain").remove())
          /*.call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y1)
          );*/

      svg.select(".x-axis").attr("class", "baraxis").call(xAxis);
      //svg.select(".y-axis").attr("class", "baraxis").call(y1Axis);

      svg
        .select(".plot-area")
        .selectAll(".bar")
        .data(data)
        
        .join("rect")
        .attr("fill", "rgb(119, 225, 254)")
        .on("mousemove", function(d){
          handlePointerMove(d);
        })
        .on("mouseout", function(){
          showTooltip({...tooltip, open:false}) 
        })
        .attr("class", "bar")
        .attr("x", (d) => x.bandwidth() > 50 ? x(d.label) + (x.bandwidth()-50 )/2  : x(d.label))
        .attr("width",   x.bandwidth() > 50 ? 50 : x.bandwidth() )
        .attr("y", (d) => y1(d.percentage))
        .attr("height", (d) => y1(0) - y1(d.percentage));

        svg
        .select(".plot-area").selectAll(".bar-title")
        .data(data)
        .enter()
        .append("text")
        .classed('bar-title', true)
        .attr('text-anchor', 'middle')
        .attr('text-size', '12')
        .attr("x", d => x(d.label) + x.bandwidth()/2)
        .attr("y", d => y1(d.percentage)-5)
        .text(d => d.value > 0 ? d.value : '' );
    },
    [data.length]
  );
  const handlePointerMove = (event) => {
    
    let containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left + 30 ;
    let containerY = ('clientY' in event ? event.clientY : 0)  - tooltipref.current.getBoundingClientRect().top -10 ;
    
    //if(tooltip_ele && tooltip_ele.current){

      var tool_width = 222;

      var tool_height = 46;

      var window_width = window.innerWidth;

      var window_height = window.innerHeight;
   
      if(event.clientX+30+tool_width > window_width){
       // alert(event.clientX+tooltipref.current.getBoundingClientRect().width+30+tool_width )
        containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width -30;
      }
      if(event.clientY-10+tool_height > window_height){
        //alert(window_width)
        containerY = ('clientX' in event ? event.clientY : 0) - tooltipref.current.getBoundingClientRect().top - tool_height -10;
      }
    //}
    showTooltip({
      tooltipLeft: containerX,
      tooltipTop: containerY,
      tooltipData: event.target.__data__.percentage_label+" of participants", //event.target.__data__.value > 1 ? event.target.__data__.value+" Participants" : event.target.__data__.value+" Participant",
      open:true
    });
    
	}
  return (
    <div ref={tooltipref} style={{position:'relative'}} className="industry-barchart">
      {tooltip.open   ? (
				<>
					<div
            ref={tooltip_ele}
						className={"tooltipChart"}
						key={Math.random()} // needed for bounds to update correctly
						style={{  zIndex:9999, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
					>
						{tooltip.tooltipData}
					</div>
				</>
				): (
					<></>
				)}
    <svg
      ref={ref}
      style={{
        height: height,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" fill="#999"/>
      <g className="y-axis" />
    </svg>
    </div>
  );
}

export default BarChart;