//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {DropdownIndicator, maxLengthCheck} from "../../../lib/helpers";
import { createTest } from "../../../services/test";
import { getProjects } from "../../../services/project";
import Select from "react-select";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";

import { settings } from "../../../data/settings";
export default function CreditsConfirmationModal(props) {
    const navigate = useNavigate();

    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);
    const [projectName, setProjectName] = useState("");

    const [formLoading, setFormLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        test_name: "",
        project_id: null,
        project_name: null,
    });

    const [formErrors, setFormErrors] = useState({
        test_name: null,
        project_id: null,
        error_class: null,
    });

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [userProjects, setUserProjects] = useState([]);

    const [methodology, setMethodology] = useState(props.selectedMethodology ? props.selectedMethodology : null);

    const renderProjects = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProjects(token).then((response) => {
            setLoading(false);

            if (response.success) {
                // console.log(response);
                let projects = [];
                response.projects.forEach((project) => {
                    projects.push({
                        value: project.id,
                        label: project.project_name,
                    });
                });

                setUserProjects(projects);
                if (props.project_id) {
                    var projectName = projects.filter(function (item) {
                        return item.value == props.project_id;
                    });
                    setProjectName(projectName[0]["label"]);
                }
            } else {
                setErrorMessage(response.setErrorMessage);
            }
        });
    };
    const loadProjects = () => {
        let projects = [];
        if (!props.project_name) {
            props.projectlist.forEach((project) => {
                projects.push({
                    value: project.id,
                    label: project.project_name,
                });
            });

            setUserProjects(projects);
        }
        if (props.project_name) {
            setProjectName(props.project_name);
        }
    };

    useEffect(() => {
        // renderProjects();
        loadProjects();
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        resetFormErrors();

        if (!formLoading) {
            var error = false;
            if (formValues.test_name === "") {
                showFormError("test_name", "Required field");
                error = true;
            } else {
                if (methodology == null) {
                    toast(
                        <div className="toastinner">
                            {<FormattedMessage id="No methodology selected" />}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
                if (
                    methodology.indexOf("Tree Test") == -1 &&
                    methodology.indexOf("Preference Test") == -1 &&
                    methodology.indexOf("Survey") == -1 &&
                    methodology.indexOf("Card Sorting") == -1 &&
                    methodology.indexOf("Five Seconds Test") == -1 
                ) {
                    toast(
                        <div className="toastinner">
                            {<FormattedMessage id="Selected Methodology Not Available" />}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
                else {
                    if (!props.project_id && formValues.project_id == null) {
                        toast(
                            <div className="toastinner">
                                {<FormattedMessage id="Please Select a project" />}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: 2000,
                            }
                        );
                    } else {
                        if (!error) {
                            setFormLoading(true);

                            const token = ReactSession.get("token");

                            var data = new FormData();

                            data.append("test_name", formValues.test_name);
                            props.project_id
                                ? data.append("project_id", props.project_id)
                                : data.append("project_id", formValues.project_id);
                            data.append("methodology", methodology);

                            createTest(data, token).then((response) => {
                                setFormLoading(false);

                                if (response.success) {
                                    setFormValues({
                                        test_name: "",
                                        project_id: null,
                                        project_name: null,
                                    });

                                    props.closeModal(true);
                                    navigate(
                                        "/researcher/tests/" + response.test_id + "/introduction/"
                                    );
                                    toast(
                                        <div className="toastinner">
                                            {response.message}
                                            <a
                                                onClick={() => {
                                                    toast.dismiss();
                                                }}
                                            >
                                                &times;
                                            </a>
                                        </div>,
                                        {
                                            className: "successtoast",
                                            position: "bottom-center",
                                            duration: 2000,
                                        }
                                    );
                                    // setSuccessMessage(response.message);
                                } else {
                                    // setErrorMessage(response.message);
                                    toast(
                                        <div className="toastinner">
                                            {response.message}
                                            <a
                                                onClick={() => {
                                                    toast.dismiss();
                                                }}
                                            >
                                                &times;
                                            </a>
                                        </div>,
                                        {
                                            className: "errortoast",
                                            position: "bottom-center",
                                            duration: 2000,
                                        }
                                    );
                                }
                            });
                        }
                    }
                }
            }
        }
        return false;
    };

    const closeModal = () => {
        setFormValues({ test_name: "", project_id: null, project_name: null });
        setMethodology("Tree Test");
        setErrorMessage(null);
        setSuccessMessage(null);
        resetFormErrors();
        setFormLoading(false);
        props.closeModal(false);
    };
    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };

    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const clearErrorMessage = () => {
        setErrorMessage(null);
    };
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    };

    const methodnotavailable = () => {
        toast(
            <div className="toastinner">
                {<FormattedMessage id="Method Not Available For Now!!" />}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 2000,
            }
        );
    };

    return (
        <Modal
            show={props.openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap create-new-test-modal "
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal();
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img
                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                alt="cross"
                            />
                        </span>
                    </button>
                    <form onSubmit={onSubmit}>
                        <div className="create-project-data-wrap">
                            <div className="create-project-modal-data">
                                <h2 className="h2">Thank you for your purchase!</h2>

                                <div className="purchased-credit-info mt-4 mb-4">
                                    <p>You just purchased <b className="credit-amount">{props.amount} credits</b></p>
                                </div>
                                <h5 className="h5"><b>Let's get testing</b></h5>

                                <div className="new-text-modal-form">
                                    <div className="create-projectmodal-form-group">
                                        <div className="input form-group  input-field">
                                            <input
                                                type="text"
                                                className={
                                                    formErrors.test_name != null
                                                        ? "form-control " + formErrors.error_class
                                                        : "form-control"
                                                }
                                                id="tname"
                                                maxLength="45"
                                                onInput={maxLengthCheck}
                                                onChange={set("test_name")}
                                                defaultValue={formValues.test_name}
                                            />
                                            <label className="control-label" htmlFor="tname">
                                                Test name
                                            </label>
                                            <span
                                                className="clear"
                                                onClick={clearFormValue("test_name")}
                                            >
                                                <svg viewBox="0 0 24 24">
                                                    <path className="line" d="M2 2L22 22" />
                                                    <path className="long" d="M9 15L20 4" />
                                                    <path className="arrow" d="M13 11V7" />
                                                    <path className="arrow" d="M17 11H13" />
                                                </svg>
                                            </span>
                                        </div>
                                        {formErrors.test_name == null && (
                                            <p className="project-name-guide-text">
                                                Max characters:{" "}
                                                <span className="pink-color">
                                                    {formValues.test_name.length > 0 && (
                                                        <>{formValues.test_name.length}/</>
                                                    )}
                                                    45
                                                </span>
                                            </p>
                                        )}
                                        {formErrors.test_name != null && (
                                            <span className={formErrors.error_class}>
                                                {formErrors.test_name}
                                            </span>
                                        )}
                                    </div>
                                    {props.project_id ? (
                                        <div className="create-projectmodal-form-group">
                                            <div className="input form-group  input-field">
                                                <Select placeholder={projectName} isDisabled={true} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="create-projectmodal-form-group">
                                            <div className="input form-group  input-field">
                                                {userProjects.length == 0 ? (
                                                    <div
                                                        onClick={() => {
                                                            // closeModal();
                                                            props.openProjectModal();
                                                        }}
                                                        className="no-projects-found"
                                                    >
                                                        No Projects Found. Click to create a project.
                                                    </div>
                                                ) : (
                                                    <>
                                                        <Select
                                                            placeholder="Select Project..."
                                                            value={
                                                                formValues.project_id
                                                                    ? {
                                                                        value: formValues.project_id,
                                                                        label: formValues.project_name,
                                                                    }
                                                                    : null
                                                            }
                                                            options={userProjects}
                                                            onChange={(item) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    project_id: item.value,
                                                                    project_name: item.label,
                                                                });
                                                            }}
                                                            components={{DropdownIndicator}}
                                                        />
                                                        <div className="create-new-project-link">
                                                            <span
                                                                onClick={() => {
                                                                    // closeModal();
                                                                    props.openProjectModal();
                                                                }}

                                                            >
                                                                Create a new project
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="test-methodology-wrapper">
                                    <div className="modal-scroll">
                                        <div className="row">
                                            <div
                                                className="col-lg-4 col-md-6"
                                                onClick={() => {
                                                    setMethodology("Card Sorting");
                                                }}
                                            >
                                                <div
                                                    className={
                                                        methodology === "Card Sorting"
                                                            ? "dash-cards-wrap  selected-class"
                                                            : "dash-cards-wrap"
                                                    }
                                                >
                                                    <div className="cards-data">
                                                        <h3>CARD SORTING</h3>
                                                        <button className="create-btn" type="button">
                                                        Start
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/arrow-right.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                        </button>

                                                        {/* <p className="card-credit-info-wrap">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/coin.svg"
                                                                }
                                                            />
                                                            {props.publishing_credits.card_sorting!==null ? props.publishing_credits.card_sorting : settings.publishing_credits_card_sorting} credits/publish
                                                        </p> */}
                                                        <div className="method-info">

                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/info.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                            <div className="card-hover-data">
                                                                Find out how users think content should be
                                                                organised and labelled to create a validated
                                                                information architecture.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-lg-4 col-md-6"
                                                onClick={() => {
                                                    setMethodology("Preference Test");
                                                    // methodnotavailable();
                                                }}
                                            >
                                                <div
                                                    className={
                                                        methodology === "Preference Test"
                                                            ? "dash-cards-wrap  selected-class dash-card-2"
                                                            : "dash-cards-wrap dash-card-2"
                                                    }
                                                >
                                                    <div className="cards-data">
                                                        <h3>PREFERENCE TEST</h3>
                                                        <button className="create-btn" type="button">
                                                        Start
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/arrow-right.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                        </button>
                                                        {/* <button className="create-btn" type="button">
                              Start
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/arrow-right.png"
                                  }
                                  alt="img"
                                />
                              </span>
                            </button> */}
                                                        {/* <p className="card-credit-info-wrap">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/coin.svg"
                                                                }
                                                            />
                                                            {props.publishing_credits.preference_test!==null ? props.publishing_credits.preference_test : settings.publishing_credits_preference_test} credits/publish
                                                        </p> */}
                                                        <div className="method-info">


                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/info.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                            <div className="card-hover-data">
                                                                Measure impact by having users evaluate
                                                                different designs to understand which ones
                                                                perform better.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-lg-4 col-md-6"
                                                onClick={() => {
                                                    setMethodology("Tree Test");
                                                }}
                                            >
                                                <div
                                                    className={
                                                        methodology === "Tree Test"
                                                            ? "dash-cards-wrap  selected-class dash-card-3"
                                                            : "dash-cards-wrap dash-card-3"
                                                    }
                                                >
                                                    <div className="cards-data">
                                                        <h3>TREE TEST</h3>
                                                        <button className="create-btn" type="button">
                                                        Start
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/arrow-right.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                        </button>
                                                        {/* <button className="create-btn" type="button">
                              Start
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/arrow-right.png"
                                  }
                                  alt="img"
                                />
                              </span>
                            </button> */}
                                                        {/* <p className="card-credit-info-wrap"> <img src={process.env.REACT_APP_URL + "images/coin.svg"
                                                        }
                                                        />
                                                            {props.publishing_credits.tree_test!==null ? props.publishing_credits.tree_test : settings.publishing_credits_tree_test} credits/publish
                                                        </p> */}

                                                        <div className="method-info">

                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/info.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                            <div className="card-hover-data">
                                                                Pinpoint exactly where your users are getting
                                                                lost within your existing “tree” of information
                                                                & hierarchical structure.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-lg-4 col-md-6"
                                                onClick={() => {
                                                    setMethodology("Survey");
                                                    // methodnotavailable();
                                                }}
                                            >
                                                <div
                                                    className={
                                                        methodology === "Survey"
                                                            ? "dash-cards-wrap  selected-class dash-card-4"
                                                            : "dash-cards-wrap dash-card-4"
                                                    }
                                                >
                                                    <div className="cards-data">
                                                        <h3>SURVEY</h3>
                                                        <button className="create-btn" type="button">
                                                        Start
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/arrow-right.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                        </button>
                                                        {/* <button className="create-btn" type="button">
                              Start
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/arrow-right.png"
                                  }
                                  alt="img"
                                />
                              </span>
                            </button> */}
                                                        {/* <p className="card-credit-info-wrap">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/coin.svg"
                                                                }
                                                            />
                                                            {props.publishing_credits.survey!==null ? props.publishing_credits.survey : settings.publishing_credits_survey} credits/publish
                                                        </p> */}
                                                        <div className="method-info">

                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/info.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                            <div className="card-hover-data">
                                                                Get your target customers to answer your burning
                                                                questions and acquire precise insights and data
                                                                points.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6"
                                            onClick={() => {
                                                setMethodology("Five Seconds Test");
                                                // methodnotavailable();
                                            }}>
                                                <div
                                                    className={
                                                        methodology === "Five Seconds Test"
                                                            ? "dash-cards-wrap  selected-class dash-card-5"
                                                            : "dash-cards-wrap dash-card-5"
                                                    }
                                                >
                                                    <div className="cards-data">
                                                        <h3>5 SECOND TEST</h3>
                                                        <button className="create-btn" type="button">
                                                        Start
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/arrow-right.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                        </button>
                                                        {/* <button
                              className="create-btn"
                              onClick={() => {
                                //   setMethodology("fivesecondtest");
                                methodnotavailable();
                              }}
                              type="button"
                            >
                              Create
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/arrow-right.png"
                                  }
                                  alt="img"
                                />
                              </span>
                            </button> */}
                                                        <div className="method-info">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/info.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                            <div className="card-hover-data">
                                                                Discover the impact and comprehension of your
                                                                product on users with a 5 Second Test.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div
                                                    className={
                                                        methodology === "firstclick"
                                                            ? "dash-cards-wrap  selected-class dash-card-6"
                                                            : "dash-cards-wrap dash-card-6"
                                                    }
                                                >
                                                    <div className="cards-data">
                                                        <h3>FIRST CLICK</h3>
                                                        <div className="coming-soon">COMING SOON</div>
                                                        {/* <button
                              className="create-btn" 
                              onClick={() => {
                                //   setMethodology("firstclick");
                                methodnotavailable();
                              }}
                              type="button"
                            >
                              Create
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/arrow-right.png"
                                  }
                                  alt="img"
                                />
                              </span>
                            </button> */}
                                                        <div className="method-info">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL + "images/info.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                            <div className="card-hover-data">
                                                                Check where users first click on an interface to
                                                                complete their task.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-project-form-btn modal-flex-button">
                                    <button
                                        className="btn form-btn secondary-btn"
                                        onClick={() => {
                                            closeModal();
                                            navigate('/dashboard');
                                        }}
                                        type="button"
                                    >
                                        Back to dashboard
                                    </button>
                                    <button type="submit" className="btn form-btn">
                                        Create test
                                        {formLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                aria-hidden="true"
                                                style={{ marginLeft: "5px" }}
                                            ></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}
