import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import React, { useEffect, useState, useRef } from "react";
import ResearcherProfileNavigation from '../../profile/researcher_profile_navigation.js';
import { updateSettings, getSettings } from "../../../services/user.js";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import LayoutResearcher from '../../layouts/layout_researcher.js';
import Checkbox from '../../layouts/elements/fields/checkbox.js';
import { LoadingIcon } from '../../loader/loadingIcon.js';
import Layout from '../../layouts/layout.js';
import TesterProfileNavigation from '../../profile/tester_profile_navigation.js';

export default function ResearcherSettings() {

    ReactSession.setStoreType("localStorage");

    let user = ReactSession.get("user");
    
    const [formLoading, setFormLoading] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({ "newsletter": 1});

    
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Settings";

        let token = ReactSession.get("token");

        setLoading(true);

        getSettings(token).then((response) => {
            setLoading(false);

            if (response.success) {
                setFormValues({
                    newsletter: response.data.settings.newsletter,
                });
                window.setLabels();
            } else {

                let message = response.message;
                if (response.message == "Unauthenticated.") {
                    message = "If you are unable to see your profile, kindly logout and log in again";
                }
                var id = toast(
                    <div className="toastinner">
                        {message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!formLoading) {

            setFormLoading(true);

            const token = ReactSession.get("token");

            let body = Object.assign({}, formValues);

            updateSettings(body, token)
                .then(response => {
                    setFormLoading(false);

                    if (response.success) {

                        
                        toast((
                            <div className='toastinner'>
                                {response.message}
                                <a onClick={() => { toast.dismiss(); }}>&times;</a>
                            </div>), {
                            className: 'successtoast',
                            position: 'bottom-center',
                            duration: 3000
                        });

                    } else {

                        toast((
                            <div className='toastinner'>
                                {response.message}
                                <a onClick={() => { toast.dismiss(); }}>&times;</a>
                            </div>), {
                            className: 'errortoast',
                            position: 'bottom-center',
                            duration: 3000
                        });
                    }
                })


        }
        return false;
    }
    const updateSettingsForm = (newsletter) => {
        if (!formLoading) {

            setFormLoading(true);

            const token = ReactSession.get("token");

            let body = Object.assign({}, {'newsletter': newsletter});

            updateSettings(body, token)
                .then(response => {
                    setFormLoading(false);

                    if (response.success) {

                        
                        toast((
                            <div className='toastinner'>
                                {response.message}
                                <a onClick={() => { toast.dismiss(); }}>&times;</a>
                            </div>), {
                            className: 'successtoast',
                            position: 'bottom-center',
                            duration: 3000
                        });

                    } else {

                        toast((
                            <div className='toastinner'>
                                {response.message}
                                <a onClick={() => { toast.dismiss(); }}>&times;</a>
                            </div>), {
                            className: 'errortoast',
                            position: 'bottom-center',
                            duration: 3000
                        });
                    }
                })


        }
    }
    const renderSettings = () => {
        return (
            <div className="billing-plan-page-wrapper setting-page-wrapper">
                {user.role=="Researcher" &&
                <ResearcherProfileNavigation
                    isLoading={false}
                    activeMenu="settings"
                />
                }
                {user.role=="Tester" &&
                <TesterProfileNavigation
                    isLoading={false}
                    activeMenu="settings"
                />
                }
                
                
                    <div className="profile-info-section d-block pb-64 pt-64">
                        <div className="profile-info-left w-100">
                            <div className="profile-info-text  mt-0">
                                <h1 className="page-main-heading">Settings</h1>
                                <p>
                                    Choose the emails and notifications you want to receive from
                                    us at your registered email.
                                </p>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                    <div className="project-listing-loader loader_section">
                        <LoadingIcon />
                    </div>
                )}
                    {!isLoading && <>
                    <div className="profile-form-wrap">
                        <form onSubmit={onSubmit}>
                            <div className="profile-form-repeat">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-info-text">
                                            <h3>Marketing notifications</h3>
                                            <span>Get notified on topics of interest.</span>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="setting-checkwrap newsletter-spinner-wrap">
                                                    <Checkbox id={"newsletter-switch"}  className={"check-switch newsletter-spinner-checkbox"} onChange={(event)=>{ setFormValues({...formValues, newsletter: formValues.newsletter==1 ? 0 : 1});  updateSettingsForm(formValues.newsletter==1 ? 0 : 1); } } value={"1"} checked={formValues.newsletter==1?true:false} label={"Subscribe to our awesome newsletter on all things UX"} />
                                                    <span className='newsletter-spinner-span'>
                                                    {formLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-form-repeat">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-info-text">
                                            <h3>In platform notifications</h3>
                                            <span>Check status alerts in real time when logged in and running live tests.</span>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="setting-checkwrap coming_soon_heading">
                                                    Coming Soon
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-form-repeat">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-info-text">
                                            <h3>System notifications</h3>
                                            <span>you are receiving these notifications because you agreed to our <a href={
                                                        process.env.REACT_APP_SITE_URL + "privacy-policy"
                                                    }
                                                    target="_blank">privacy policy</a> and <a href={
                                                        process.env.REACT_APP_SITE_URL +
                                                        "terms-of-service"
                                                    }
                                                    target="_blank"> terms of use</a>. If you no longer wish to receive these notifications please delete your account..</span>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="setting-checkwrap">
                                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-md-12">
                                <div className="researcher-profile-form-btn   justify-content-end">
                                    <div className="inner-form-btn res-profle-btn-right">
                                        <button type="submit" className="btn form-btn">
                                            Save settings
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>*/}
                        </form>
                    </div>
                    </>
                    }
            </div>
        );
    }
    return (
        
           (user && user.role=="Researcher"
            ?
            <LayoutResearcher
                isLoading={false}
                wrapClass="rs-inner-profile-wrapper"
                skipCheck={true}
                extendedFooter={false}
                activeMenu={"settings"}
            >
                {renderSettings()}
            </LayoutResearcher>
            :
            <Layout
                isLoading={false}
                wrapClass="rs-inner-profile-wrapper"
                skipCheck={true}
                extendedFooter={false}
                activeMenu={"settings"}
            >
                {renderSettings()}
            </Layout>
           )
        
        
    );
}