import React, {useCallback} from 'react';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestCardSortingRCategoryesultData,
    getTestCardSortingCardsResultData,
    getTestCardSortingMatrixResultData,
    getTestCardSortingSimilarityMatrixResultData
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import {getCurrentPlansService} from "../../../services/plans";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import {atom, useRecoilState} from 'recoil';

import AnyChart from 'anychart-react'
import {LoadingIcon} from "../../loader/loadingIcon";
import {Dropdown} from "react-bootstrap";
import exportAsImage from "../../../lib/exportAsImage";
import {downloadBlob} from "../../../lib/helpers";

export default function TestResultCardSortingChart() {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const exportSimilarityRef = useRef();
    const exportResultGridRef = useRef();


    const testState = atom({
        key: 'testresult-'+test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-'+test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);

    const  [categoryArray,setCategoryArray] = useState([]);
    const  [cardArray,setCardArray] = useState([]);
    const  [matrixArray,setMatrixArray] = useState([]);
    const  [similarityMatrixArray,setSimilarityMatrixArray] = useState([]);
    const [metaData,setMetaData] = useState({active:'matrix','sorting_type':'open',cardSort:"",categorySort:""});
    const [expandData,setExpandData] = useState(false);
    const [expandSimilarityData,setExpandSimilarityData] = useState(false);

    const getTestafterEndTest=()=>{

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            }else{
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {

        if(!test){
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    setTest(response.test);
                    getCardSortingMatrix();


                } else {
                    navigate("/dashboard");
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getCardSortingMatrix();
        }
    };

    const getCardSorting = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        getTestCardSortingRCategoryesultData(
            {
                test_id: test_id
            }
            ,token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                setCategoryArray(response.category);

                /*const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                response.matrix.forEach((items)=>{

                    var fill = '';
                    if(response.matrixRangeColor.minMatrixData === items.heat){
                        fill = '#ECE8F7';
                    }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                        fill = '#EED9EF';
                    }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                        fill = '#F2BCDE';
                    }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                        fill = '#F7A0CE';
                    }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                        fill = '#FB83BD';
                    }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                        fill = '#FF66AD';
                    }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                        fill = '#96FFFC';
                    }else{
                        fill = '#FF66AD';
                    }



                    matrixData.push({
                        x:items.x,
                        y:items.y,
                        heat:items.heat,
                        fill:fill
                    })
                })

                //console.log(matrixData)

                setMatrixArray(matrixData);*/

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };



    const getCardSortingMatrix = () => {
        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingMatrixResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            if (response.success) {

                // setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                //setCategoryArray(response.category);

                const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                response.matrix.forEach((items)=>{

                    var fill = '';
                    if(response.matrixRangeColor.minMatrixData === items.heat){
                        fill = '#ECE8F7';
                    }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                        fill = '#EED9EF';
                    }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                        fill = '#F2BCDE';
                    }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                        fill = '#F7A0CE';
                    }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                        fill = '#FB83BD';
                    }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                        fill = '#FF66AD';
                    }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                        fill = '#96FFFC';
                    }else{
                        fill = '#FF66AD';
                    }


                    //console.log(response.MatrixDataArrayTotal)

                   // var heatpercentage =  Math.round(((items.heat)*100)/response.MatrixDataArrayTotal)

                    var totalheat = 0;
                    response.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat
                        }
                    })

                    var heatpercentage = 0;

                    if(response.totalCountTestResult) {
                        var heatpercentage = Math.round(((items.heat) * 100) / response.totalCountTestResult);
                    }

                   // var folderIcon = 'http://localhost:3000/images/back-arrow.svg';

                    if((items.isCustom) == '0'){
                        var folderIcon = '#2F2E55';
                    }else{
                        var folderIcon = '#FF66AD';
                    }

                    var folderCardIcon = '#2F2E55';

                    //console.log(items.isCustom)


                    matrixData.push({
                        x: items.x.toString().length>12?(test.language=="ar"?"</b>..."+items.x.toString().substring(0, 11)+'<b class="asdas" style="color: '+folderIcon+'">':'<b class="asdas" style="color: '+folderIcon+'">'+items.x.toString().substring(0, 11) + "..</b>"):'<b class="asdas" style="color: '+folderIcon+'">'+items.x+'</b>',
                        // y: items.y,
                        y: items.y.toString().length>6?(test.language=="ar"?"</b>..."+items.y.toString().substring(0, 5)+'<b>':'<b>'+items.y.toString().substring(0, 5) + "...</b>"):'<b>'+items.y+'</b>',

                        //x:'<b class="asdas" style="color: '+folderIcon+'">'+items.x+'</b>',
                        //y:'<b style="color: '+folderCardIcon+'">'+items.y+'</b>',
                        heat:items.heat,
                        fill:fill,
                        participants: items.y+" in "+items.x+" "+heatpercentage+"% of participants",
                        //participants: '<b>'+items.y+"</b> in <b>"+items.x+"</b><br/> "+heatpercentage+"% of participants",
                    })
                })

                //console.log(matrixData)

                setMatrixArray(matrixData);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };





    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - card sorting";
        getTest();


    }, []);

    const getSimilarityMatrix = ()=>{

        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingSimilarityMatrixResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            console.log(response.category)
            if(response){

                setSimilarityMatrixArray(response.category);
            }


        });
    }


    const callbackTopbar = (data)=>{
        getTest();
        getTestafterEndTest();
    }


    var chart1_settings = {
        id: "Aerachart1",
        width: "100%",
        background:'transparent',
        height: 600,
        type: 'heatMap',
        data: matrixArray,
        colors:{
            darken:"#ff0000"
        },
        darken:{
            fill:"#ff0000"
        },
        hovered:{
            fill:"#FF66AD"
        },

        tooltip: {
            enabled: true,
            // background: "#defcfc",
            //useHtml:true,
            background: {
                fill: "#defcfc",
                corners: 17
            },
            fontColor: "#171637",
            fontSize: "14px",
            fontFamily: test.language=="ar"?"Cairo":"Inter",
            // fontWeight: 100,
            title: false,
            separator: false,
            padding: "20px 40px",
            textAlign: "center",
            format: "{%participants}",
            // anchor:"left-center",
            offsetX: 30,
            offsetY: -30
        },
        xAxis: {
            staggerMode:true,

            labels: {
                // rotation: -40,
                fontFamily: test.language=="ar"?"Cairo":"Inter",
                fontSize: "13px",
                rotation:-45,
                useHtml:true,

            }
        },
        yAxis: {
            staggerMode:true,
            labels: {
                fontFamily: test.language=="ar"?"Cairo":"Inter",
                fontSize: "13px",
                useHtml:true,
            }
        },

        label: {
            text: '',
            width: "100%",
            height: "100%",
            fontSize: '45px',
            fontColor: "#fff",
            hAlign: 'center',
            vAlign: 'middle'
        },
        title: {
            text: '',
            fontColor: "#fff",
            fontWeight: 'bold'
        }
    };




    const downloadSVG = useCallback(() => {
        //const svg = document.getElementsByClassName('anychart-ui-support');
        //anychart-credits

        // document.getElementsByClassName('anychart-credits').remove();
        //document.querySelector("div").remove();

       //const svg = document.getElementById('Aerachart1').getElementsByTagName('div')[0].innerHTML;
       //const svg = document.getElementById('Aerachart1').getElementsByTagName('div')[0].innerHTML;
       // const svg = exportResultGridRef.current.getElementsByTagName('div')[0].innerHTML;

        var svg = '<svg xmlns="http://www.w3.org/2000/svg" border="0" data-ac-wrapper-id="237" width="100%" height="100%" class="anychart-ui-support" ac-id="ac_stage_e09343_18" role="presentation" style="display: block;">';
        svg +=  document.getElementById('Aerachart1').getElementsByTagName('div')[0].children[0].innerHTML;
        svg += '</svg>';

        //console.log(a);
        //console.log(document.getElementsByClassName('anychart-ui-support')[0]);
        //console.log(document.getElementById('Aerachart1').getElementsByTagName('div')[0].innerHTML);
        const blob = new Blob([svg], { type: "image/svg+xml" });

        downloadBlob(blob, `resultgrid.svg`);
    }, []);



    return (
        <LayoutResearcherFullWidth

            hide_header_footer={true}
            fixed_header_target={true}
            isLoading={isLoading}
            wrapClass={"researcher-test-view-overflow overflow-visible chart-full-view pt-0"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
        >
            {isLoading &&
            <div className="page-min-height">
                <LoadingIcon />
            </div>
            }
            {!isLoading && test &&
            <div className="analyze-results-page-wrapper">



                <div className={`container inner-page-container matrix-full-view`}>
                    <div className="sp5-content-wrap analyze-overflow-wrap">


                        <div className={`sp5-right-side-wrap card-sorting-anayl-data`}>
                            {sectionLoading &&
                            <LoadingIcon />
                            }
                            {!sectionLoading &&
                            <div className="bd-question-view-wrap resilt-grid-full-page">



                                <div className='bd-question-top-slide d-flex justify-content-between card-sorting-anayl-top-wrap'>
                                    <div className='bd-que-slide-left w-100 d-flex align-items-center justify-content-between'>

                                        <div>
                                            <h3 className='h3 font-inter'>Results Grid</h3>
                                            <p>Results grid shows the number of times each card was sorted into your pre-set categories in a closed card sort</p>
                                        </div>



                                    </div>


                                    <span className="small-dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle">
                                                DOWNLOAD
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#"  onClick={() =>  exportAsImage(exportResultGridRef.current, "resultGrid")}>
                                                    Download PNG
                                                    <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={() =>  downloadSVG(exportResultGridRef.current)}>
                                                    Download  SVG
                                                    <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                </div>

                                <div className="bd-que-table-wrapper">

                                    <div className="participants-overview-data-wrap mb-32">
                                        <div className="confid-tech-hraph-hold">
                                            <div className="chart-info-wrap" style={{justifyContent:"left"}}>
                                                {/*<div className="chart-info-repeat" >*/}
                                                {/*    <span style={{"display": "inline-block", "backgroundColor": "rgb(81, 178, 51)"}}></span>*/}
                                                {/*    <p>Correct path</p>*/}
                                                {/*</div>*/}
                                                <div className="chart-info-repeat">
                                                    <span style={{"display": "inline-block", "backgroundColor": "rgba(47, 46, 85, 1)"}}></span>
                                                    <p>Category created by researcher</p>
                                                </div>
                                                <div className="chart-info-repeat">
                                                    <span style={{"display": "inline-block", "backgroundColor": "#FF66AD"}}></span>
                                                    <p>Category created by participant</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="bd-que-table-search-wrap mb-32">*/}
                                    {/*    <div className="">*/}

                                    {/*        <div className="bd-question-radio-data metrix-card-sort d-flex justify-content-between w-100 border-0 pl-0 ml-0">*/}



                                    {/*            <div className="checkboxes radio_btn_class  d-flex">*/}

                                    {/*            </div>*/}


                                    {/*            <div className="max-matrix-screen">*/}


                                    {/*                {test.card_sorting.card_sorting === "close" &&*/}
                                    {/*                <span>*/}



                                    {/*                </span>*/}
                                    {/*                }*/}


                                    {/*            </div>*/}


                                    {/*        </div>*/}

                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {dataLoading &&
                                    <LoadingIcon />
                                    }
                                    {!dataLoading &&
                                    <div className={`bd-que-ans-table-data-inner ${(test.language !== 'en')?'cairo-font':''}`}>


                                        <div ref={exportResultGridRef}>
                                            <AnyChart
                                                {...chart1_settings}
                                                // type="heatMap"
                                                // data={matrixArray}
                                                // /* data={[ {x: "2010", y: "A", heat: 15},
                                                //      {x: "2011", y: "A", heat: 17},
                                                //      {x: "2012", y: "A", heat: 21},
                                                //      {x: "2010", y: "B", heat: 34},
                                                //      {x: "2011", y: "B", heat: 33},
                                                //      {x: "2012", y: "B", heat: 32},
                                                //      {x: "2010", y: "C", heat: 51},
                                                //      {x: "2011", y: "C", heat: 0},
                                                //      {x: "2012", y: "C", heat: 47}]}*/
                                                // //width={800}
                                                // height={200}
                                                // colors={["#00ccff", "#ffcc00"]}
                                                //title="Simple pie chart"
                                            />
                                        </div>

                                    </div>
                                    }

                                </div>



                            </div>
                            }

                        </div>


                    </div>
                </div>

            </div>
            }
        </LayoutResearcherFullWidth>
    )
}