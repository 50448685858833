import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { IntlProvider } from "react-intl";
import messages from "./lang/en_gz/messages";

import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

import { Toaster } from "react-hot-toast";

import ScrollToTop from "./components/layouts/scrolltotop";

import LoginTester from "./components/auth/tester/login";
import RegisterTester from "./components/auth/tester/register";
import VerifyTester from "./components/auth/tester//verify";
import ResetPasswordTester from "./components/auth/tester//reset-password";
import ForgotPasswordEmailTester from "./components/auth/tester/forgot-password-email";

import LoginResearcher from "./components/auth/researcher/login";
import RegisterResearcher from "./components/auth/researcher/register";
import VerifyResearcher from "./components/auth/researcher/verify";
import ResetPasswordResearcher from "./components/auth/researcher/reset-password";
import CollaboratorCreateAccount from "./components/auth/researcher/collaborator-create-account";
import ForgotPasswordEmailResearcher from "./components/auth/researcher/forgot-password-email";
import SocialSignUp from "./components/auth/researcher/social-signup";

import Dashboard from "./components/dashboard/dashboard";
import Home from "./components/index";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "./lib/secure_reactsession";
import GuestRoute from "./components/guards/guestroute";
import PageNotFound from "./components/page_not_found";
import ProtectedRoute from "./components/guards/protectedroute";

import Step1 from "./components/profile/step1";
import Step2 from "./components/profile/step2";
import Step3 from "./components/profile/step3";
import Step4 from "./components/profile/step4";
import Step5 from "./components/profile/step5";
import Profile from "./components/profile/profile";

import Project from "./components/researcher/projects/project";
import ProjectSettings from "./components/researcher/projects/project_settings";

import AddTargetGroup from "./components/researcher/target_groups/add_target_group";
import TargetGroup from "./components/researcher/target_groups/target_group";

import Plans from "./components/researcher/billing/plans";
import Subscribe from "./components/researcher/billing/subscribe";
import Billing from "./components/researcher/billing/billing";
import ChangePlan from "./components/researcher/billing/change-plan";
import Team from "./components/researcher/team/team";
import Credits from "./components/researcher/credits/credits";
import ResearcherSettings from "./components/researcher/settings/settings";
import BuyCredits from "./components/researcher/credits/buy-credits";
import Introduction from "./components/researcher/tests/introduction";

import StudyCunclusions from "./components/researcher/tests/conclusions";
import StudyTargetGroup from "./components/researcher/study/target-group";

import TreeSortTest from "./components/researcher/tests/tree_sort_test";
import PreferenceTest from "./components/researcher/tests/preference_test";
import Survey from "./components/researcher/tests/survey";
import CardSortingTest from "./components/researcher/tests/card_sorting_test";


import Conclusions from "./components/researcher/tests/conclusions";
import TargetGroupTest from "./components/researcher/tests/target_group_test";
import ReviewTest from "./components/researcher/tests/review_test";
import PublishTest from "./components/researcher/tests/publish_test";
import LiveTest from "./components/researcher/tests/live_test";

import TestResultOverview from "./components/researcher/test_results/overview";

import Wallet from "./components/tester/wallet";
import EarnAndInvite from "./components/tester/earn_and_invite";
import EarnAndInviteResearcher from "./components/researcher/earn_and_invite";
import Test from "./components/tests/test";
import Tests from "./components/tester/tests";
import TesterSupport from "./components/tester/support/faq";
import ResearcherSupport from "./components/researcher/support/faq";
import ResearcherSupportContact from "./components/researcher/support/contact";
import TesterSupportContact from "./components/tester/support/contact";
import TestResultParticipants from "./components/researcher/test_results/participants";
import TestResultTreeTest from "./components/researcher/test_results/tree-test";
import TreeChart from "./components/researcher/test_results/tree-chart";
import TestResultPreferenceTest from "./components/researcher/test_results/preference-test";
import TestResultQuestions from "./components/researcher/test_results/questions";
import TestResultCardSorting from "./components/researcher/test_results/card-sorting";
import TestResultCardSortingChart from "./components/researcher/test_results/card-sorting-chart";
import TestResultCardSortingSimilarity from "./components/researcher/test_results/card-sorting-similarity";
import TestResultTreeTestSample from "./components/researcher/test_results/tree-test-sample";
import ViewInvoice from "./components/researcher/billing/view-invoice";
import Auth from "./components/auth/auth";
import TesterRegistrationSuccess from "./components/auth/tester/registration_success";
import ResearcherRegistrationSuccess from "./components/auth/researcher/registration_success";
import SurveyResult from "./components/researcher/test_results/survey";
import ChangeEmail from './components/auth/change-email';
import ConfirmEmail from './components/auth/confirm-email ';
import Testing from './components/testing';
import UnderMaintenace from "./components/under_maintenance/under_maintenance";
import EmailLogin from "./components/auth/researcher/email-login";
import { load } from 'recaptcha-v3'
import FiveSecondsTest from './components/researcher/tests/five_seconds_test';
import TestResultFiveSecondsTest from "./components/researcher/test_results/five-seconds-test";

function App() {
	ReactSession.setStoreType("localStorage");
	let ptoken = ReactSession.get("token");

    const [maintenance_mode, setMaintenanceMode] = useState("loading");

	useEffect(() => {

        /*document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });*/
        //regenerateCaptcha();  
        clearCacheData();

    }, []);

    
    
    const clearCacheData = () => {
        var date = new Date;

        fetch("/meta.json?"+date.getTime())
        .then((response) => response.json())
        .then((meta) => {
            if(meta.maintenance_mode==1){
                setMaintenanceMode("true");
            } else {
                setMaintenanceMode("loaded");
            }
            let latestVersion = meta.build_version;
            let currentVersion = localStorage.getItem("build_version");

            if(!currentVersion || latestVersion!=currentVersion)
            {
                localStorage.setItem("build_version", latestVersion);

                caches.keys().then((names) => {
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });
                window.location.reload();
            }
        });

    };

    const regenerateCaptcha = ()=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            
        })
    }
    
    
	return (
		<div className="App">
            <RecoilRoot>
                <IntlProvider messages={messages} locale="en" defaultLocale="en">
                    <Router>
                        <ScrollToTop>
                            {maintenance_mode=="loaded" &&
                            <>
                            
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/maintenance" element={<Home />} />

                                <Route path="/testing" element={<Testing />} />
                                <Route path="/email/login/gatewayinchn" element={<EmailLogin />} />

                                <Route
                                    path="/tester/register"
                                    element={<GuestRoute element={<RegisterTester />} />}
                                />
                                <Route
                                    path="/tester/login"
                                    element={<GuestRoute element={<LoginTester />} />}
                                />
                                <Route
                                    path="/tester/"
                                    element={<GuestRoute element={<Auth path={"/tester/login"}/> } />}
                                />
                                <Route
                                    path="/tester/registration/success"
                                    element={<GuestRoute element={<TesterRegistrationSuccess />} />}
                                />
                                <Route
                                    path="/tester/verify/:code"
                                    element={<GuestRoute element={<VerifyTester />} />}
                                />
                                <Route
                                    path="/tester/forgot-password-email"
                                    element={<GuestRoute element={<ForgotPasswordEmailTester />} />}
                                />
                                <Route
                                    path="/tester/reset-password/:code"
                                    element={<GuestRoute element={<ResetPasswordTester />} />}
                                />
                                <Route
                                    path="/researcher/"
                                    element={<GuestRoute element={<Auth path={"/researcher/login"} /> } />}
                                />
                                <Route
                                    path="/researcher/register"
                                    element={<GuestRoute element={<RegisterResearcher />} />}
                                />
                                <Route
                                    path="/researcher/registration/success"
                                    element={<GuestRoute element={<ResearcherRegistrationSuccess />} />}
                                />
                                <Route
                                    path="/researcher/login"
                                    element={<GuestRoute element={<LoginResearcher />} />}
                                />
                                <Route
                                    path="/researcher/verify/:code"
                                    element={<GuestRoute element={<VerifyResearcher />} />}
                                />
                                <Route
                                    path="/researcher/forgot-password-email"
                                    element={
                                        <GuestRoute element={<ForgotPasswordEmailResearcher />} />
                                    }
                                />
                                <Route
                                    path="/researcher/reset-password/:code"
                                    element={<GuestRoute element={<ResetPasswordResearcher />} />}
                                />
                                <Route
                                    path="/collaborator/create-account/:code"
                                    element={<GuestRoute element={<CollaboratorCreateAccount />} />}
                                />

                                <Route
                                    path="/researcher/social-signup"
                                    element={<GuestRoute element={<SocialSignUp />} />}
                                />
                                <Route
                                    path="/researcher/project/:project_id"
                                    element={<ProtectedRoute element={<Project />} />}
                                />
                                <Route
                                    path="/researcher/project/settings/:project_id"
                                    element={<ProtectedRoute element={<ProjectSettings />} />}
                                />
                                <Route
                                    path="/researcher/target_group/add/:project_id"
                                    element={<ProtectedRoute element={<AddTargetGroup />} />}
                                />
                                <Route
                                    path="/researcher/target_group/:target_group_id"
                                    element={<ProtectedRoute element={<TargetGroup />} />}
                                />
                                <Route
                                    path="/researcher/billing/plans"
                                    element={<ProtectedRoute element={<Plans />} />}
                                />
                                <Route
                                    path="/researcher/billing/plan/change"
                                    element={<ProtectedRoute element={<ChangePlan />} />}
                                />

                                <Route
                                    path="/researcher/billing/subscribe"
                                    element={<ProtectedRoute element={<Subscribe />} />}
                                />
                                <Route
                                    path="/researcher/team"
                                    element={<ProtectedRoute element={<Team />} />}
                                />
                                <Route
                                    path="/researcher/credits"
                                    element={<ProtectedRoute element={<Credits />} />}
                                />
                                <Route
                                    path="/researcher/credits/buy-credits"
                                    element={<ProtectedRoute element={<BuyCredits />} />}
                                />
                                <Route
                                    path="/researcher/settings"
                                    element={<ProtectedRoute element={<ResearcherSettings />} />}
                                />
                                <Route
                                    path="/tester/settings"
                                    element={<ProtectedRoute element={<ResearcherSettings />} />}
                                />
                                <Route
                                    path="/researcher/billing"
                                    element={<ProtectedRoute element={<Billing />} />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/introduction/"
                                    element={<ProtectedRoute element={<Introduction />} />}
                                />

                                <Route
                                    path="/dashboard"
                                    element={<ProtectedRoute element={<Dashboard />} />}
                                />

                                <Route
                                    path="/profile/step1"
                                    element={<ProtectedRoute element={<Step1 />} />}
                                />
                                <Route
                                    path="/profile/step2"
                                    element={<ProtectedRoute element={<Step2 />} />}
                                />
                                <Route
                                    path="/profile/step3"
                                    element={<ProtectedRoute element={<Step3 />} />}
                                />
                                <Route
                                    path="/profile/step4"
                                    element={<ProtectedRoute element={<Step4 />} />}
                                />
                                <Route
                                    path="/profile/step5"
                                    element={<ProtectedRoute element={<Step5 />} />}
                                />

                                <Route
                                    path="/profile"
                                    element={<ProtectedRoute element={<Profile />} />}
                                />

                                <Route
                                    path="/researcher/study/introduction"
                                    element={<Introduction />}
                                />
                                <Route
                                    path="/researcher/study/conclusions"
                                    element={<StudyCunclusions />}
                                />
                                <Route
                                    path="/researcher/study/target-group"
                                    element={<StudyTargetGroup />}
                                />
                                <Route
                                    path="/researcher/study/publish-test"
                                    element={<PublishTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/tree-sort-test/"
                                    element={<TreeSortTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/preference-test/"
                                    element={<PreferenceTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/survey/"
                                    element={<Survey />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/card-sorting-test/"
                                    element={<CardSortingTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/five-seconds-test/"
                                    element={<FiveSecondsTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/conclusions/"
                                    element={<Conclusions />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/target-group/"
                                    element={<TargetGroupTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/review/"
                                    element={<ReviewTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/publish/"
                                    element={<PublishTest />}
                                />
                                <Route
                                    path="/researcher/tests/:test_id/live/"
                                    element={<LiveTest />}
                                />
                                <Route
                                    path="/researcher/support/"
                                    element={<ProtectedRoute element={<ResearcherSupport />} />}
                                />
                                <Route
                                    path="/researcher/support/contact"
                                    element={<ProtectedRoute element={<ResearcherSupportContact />} />}
                                />

                                <Route
                                    path="/researcher/invite-and-earn/"
                                    element={<ProtectedRoute element={<EarnAndInviteResearcher />} />}
                                />
                                <Route
                                    path="/tester/tests/"
                                    element={<ProtectedRoute element={<Tests />} />}
                                />
                                <Route
                                    path="/tester/support/"
                                    element={<TesterSupport />}
                                />
                                <Route
                                    path="/tester/support/contact"
                                    element=<TesterSupportContact />
                                />
                                <Route
                                    path="/tester/wallet/"
                                    element={<ProtectedRoute element={<Wallet />} />}
                                />

                                <Route
                                    path="/404/"
                                    element={<PageNotFound />}
                                />
                                <Route
                                    path="/tester/invite-and-earn/"
                                    element={<ProtectedRoute element={<EarnAndInvite />} />}
                                />

                                <Route path="/p/:test_id" element={<Test />} />
                                <Route path="/t/:test_id" element={<Test />} />

                                <Route
                                    path="/researcher/analytics/:test_id/overview"
                                    element={<ProtectedRoute element={<TestResultOverview />} />}
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/participants"
                                    element={<ProtectedRoute element={<TestResultParticipants />} />}
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/tree-chart/:task_id"
                                    element={<ProtectedRoute element={<TreeChart />} />}
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/tree-test/"
                                    element={<ProtectedRoute element={<TestResultTreeTest />} />}
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/preference-test"
                                    element={<ProtectedRoute element={<TestResultPreferenceTest />} />}
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/sample"
                                    element={
                                        <ProtectedRoute element={<TestResultTreeTestSample />} />
                                    }
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/briefing"
                                    element={<ProtectedRoute element={<TestResultQuestions />} />}
                                />

                                <Route
                                    path="/researcher/analytics/:test_id/debriefing"
                                    element={<ProtectedRoute element={<TestResultQuestions />} />}
                                />

                                <Route
                                    path="/researcher/analytics/:test_id/card-sorting"
                                    element={<ProtectedRoute element={<TestResultCardSorting />} />}
                                />
                                <Route
                                    path="/researcher/analytics/:test_id/card-sorting-chart"
                                    element={<ProtectedRoute element={<TestResultCardSortingChart />} />}
                                />

                                <Route
                                    path="/researcher/analytics/:test_id/card-sorting-similarity"
                                    element={<ProtectedRoute element={<TestResultCardSortingSimilarity />} />}
                                />

                                <Route
                                    path="/researcher/analytics/:test_id/survey"
                                    element={<ProtectedRoute element={<SurveyResult />} />}
                                />

                                <Route
                                    path="/researcher/analytics/:test_id/five-seconds-test/"
                                    element={<ProtectedRoute element={<TestResultFiveSecondsTest />} />}
                                />
                                <Route
                                    path="/invoice/:invoice_id"
                                    element={<ProtectedRoute element={<ViewInvoice />} />}
                                />
                                <Route
                                    path="/email/change/:code"
                                    element={<ChangeEmail/>}
                                />
                                <Route
                                    path="/email/confirm/:code"
                                    element={<ConfirmEmail />}
                                />
                            </Routes>
                            </>
                            }
                            {maintenance_mode=="true" &&
                            <Routes>
                                <Route path="/*" element={<UnderMaintenace />} />

                                
                                
                            </Routes>
                            }
                        </ScrollToTop>
                    </Router>
                    <Toaster />
                </IntlProvider>
            </RecoilRoot>
		</div>
	);
}

export default App;
