import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams,useLocation} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import TesterTestFrame from "./elements/tester_test_frame";
import NewTesterConcludeTestModal from "./dialog/new_tester_conclude_test_modal";
import { waitForAllSettled } from "recoil";
import ExistingTesterConcludeTestModal from './dialog/existing_tester_conclude_test_modal';
import { encryptId } from "../../lib/helpers";

export default function ConclusionPage({test,conclusionCallback,similarTest,settings}) {
    const [isActiveMoreTest, setActiveMoreTest] = useState(false );
    const [isCompleteMyProfile, setCompleteMyProfile] = useState(true);
    const [openNewTesterConcludeTestModal, setOpenNewTesterConcludeTestModal] = useState(true);
    const [openExistingTesterConcludeTestModal, setOpenExistingTesterConcludeTestModal] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const user=ReactSession.get("user");

    useEffect(()=>{
        // location scroll top
        window.scrollTo(0, 0);
    },[test])
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap tester-thankyou-page-wrap">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold flex-wrap">
                    
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="tester-page-selected-img-wrap">
                                <img src={
                                    (test && test.conclusion_image)?
                                        process.env.REACT_APP_IMG_URL+''+test.conclusion_image   :
                                        process.env.REACT_APP_URL+"images/thanku-placeholder.png"
                                }/>
                            </div>
                        </div>
                        <div className="selected-page-left-side">
                            <h1>
                                        {(test && test.language === 'en') ?
                                            test && test.thankyou_title :
                                            test && test.thankyou_title_arabic
                                        }


                            </h1>
                            <p>

                                        {(test && test.language === 'en') ?
                                            test && test.thankyou_description :
                                            test && test.thankyou_description_arabic
                                        }

                            </p>
                            <div className="button-wrapper none-for-mobile ">
                                {!(ReactSession.get("token")) &&

                                <a href={process.env.REACT_APP_SITE_URL+"become-a-tester"} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</a>
                                }
                                {(ReactSession.get("token")) &&
                                <Link to={'/dashboard'} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</Link>
                                }
                            </div>
                        </div>
                    </div>
                    
                
                    {(ReactSession.get("token")) && location.pathname.substr(1,1) === 't' &&
                    <div className="find-more-test-wrap">
                        <div className="find-sim-btn-hold">
                            <span className="find-similar-btn" onClick={()=>setActiveMoreTest(true)} id="open-test">Find similar tests<img
                                src={process.env.REACT_APP_URL + "images/more-load.svg"} alt="img"/></span>
                        </div>
                    </div>
                    }
                </div>
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    {(ReactSession.get("token")) &&
                    <Link to={'/dashboard'} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</Link>
                    }
                    {!(ReactSession.get("token")) &&
                    <a href={process.env.REACT_APP_SITE_URL+"become-a-tester"} className="button primary-btn ml-0 back-to-home-link">{test.language === 'en'?"Back to home":"Back to home"}</a>
                    }
                </div>
            </div>

            {!(ReactSession.get("token")) &&

            <NewTesterConcludeTestModal test={test} isCompleteMyProfile={isCompleteMyProfile} openNewTesterConcludeTestModal={openNewTesterConcludeTestModal} closeNewTesterConcludeTestModal={()=>{setOpenNewTesterConcludeTestModal(false)}}/>
            // <div
            //     className={`page-fixed-info-wrap d-flex align-items-center justify-content-center test-register-info-wrap ${!isCompleteMyProfile?'d-none':''}`}>
            //     <div>
            //         {test.language === 'en'?
            //         <>Want to get paid for your insights? <b>Register with UserQ to fill out more tests like this!</b></>
            //         :<>&nbsp; هل تريد أن تحصل على أموال مقابل افكارك؟&nbsp;<b>سجل مع UserQ لملء المزيد من الاختبارات المشابهة.</b></>}

            //     </div>
            //     <Link to={'/tester/login'} className="button primary-btn small-button">{test.language === 'en'?"Complete my profile":"أكمل ملفي الشخصي"}</Link>

            //     <span className="close-profile-complete-noti" onClick={()=>{
            //         setCompleteMyProfile(false);
            //     }}><img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img"/></span>
            // </div>
            }
            {(ReactSession.get("token")) && 
            <>
            {(user.role=="Tester") &&
            <ExistingTesterConcludeTestModal test={test}  openExistingTesterConcludeTestModal={openExistingTesterConcludeTestModal} closeExistingTesterConcludeTestModal={()=>{setOpenExistingTesterConcludeTestModal(false)}}/>
            }
            </>
            }

            <div className={`similar-test-data-hold ${isActiveMoreTest?'show-modal':''}`}>
                <div className="find-sim-btn-hold">
                    <span className="find-similar-btn none-for-mobile" id="close-test">
                        Find similar tests

                        <img
                        src={process.env.REACT_APP_URL + "images/more-load.svg"} alt="img"/></span>

                    <span className="find-similar-btn none-for-desktop" onClick={()=>setActiveMoreTest(false)} id="close-test-2"><img src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                                                               alt="img"/></span>

                </div>
                <div className="container inner-page-container">
                    <div className="das-ongoing-test-wrap similar-test-data-inner">
                        <div className="project-das-data">

                            {similarTest && similarTest.length === 0 &&
                                <h2 className="no-test-available">We don’t have any studies for you right now :(<br/> We’ll let you know when one becomes available.</h2>
                            }

                            {similarTest && similarTest.map((test)=>{
                                return (<TesterTestFrame
                                    key={"Testframe" + test.id}
                                    settings={settings}
                                    test={test}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/t/" + encryptId(test.id, test));
                                    }}
                                />)
                            })}


                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
