import {handleApiErrorResponse} from "../lib/helpers";

export function getResearcherTestResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/get?test_id="+
        formValues.test_id,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}

export function getResearcherTestResultOverviewData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/get-overview?test_id="+
        formValues.test_id,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTestParicipantsData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/participants?test_id="+
        formValues.test_id+"&type="+
        formValues.type+"&guest_token="+
        formValues.guest_token,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestQuestionResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/question?test_id="+
        formValues.test_id+"&type="+
        formValues.type,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getFiveSecondsTestQuestionResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/five-seconds-test-question?test_id="+
        formValues.test_id+"&type="+
        formValues.type,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getLoadMoreQuestionData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/load-more-question?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        formValues.search+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getLoadMoreSurveyQuestionData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/load-more-question?test_id="+
        formValues.test_id+"&test_question_id="+
        formValues.test_question_id+"&page="+
        formValues.page+"&search="+
        formValues.search+"&type="+
        formValues.type+"&sort_column="+
        formValues.sort_column+"&order_by="+
        formValues.order_by+"&questiontype="+
        formValues.questiontype,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res) => res.json());
}

export function deleteAnswerResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/delete-answer", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function hideResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/hide-result", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function reportIssueAnswer(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/report-issue-answer", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function ExportTestResult(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "researcher/test-results/export-test-result", {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestTreeTestData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test?test_id="+
        formValues.test_id,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestTreeTestTaskData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/task?test_id="+
        formValues.test_id+"&page="+formValues.page+"&order="+formValues.order+"&direction="+formValues.direction+"&task_id="+formValues.task_id,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestTreeTestTaskFirstClickData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/task/first-click?test_id="+
        formValues.test_id+"&task_id="+formValues.task_id,
        {
        method: "get",
        headers: {"Content-Type":"application/json","Accept":"application/json", Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestPreferenceTestData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/preference-test?test_id="+
        formValues.test_id,
        {
        method: "get",
        headers: {"Content-Type":"application/json","Accept":"application/json",  Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTestPreferenceTestTaskData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/preference-test/task?test_id="+
        formValues.test_id+"&page="+formValues.page+"&order="+formValues.order+"&direction="+formValues.direction,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingRCategoryesultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-category?test_id="+
        formValues.test_id,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

            const isJson = res.headers.get('content-type')?.includes('application/json');
            const data = isJson ? res.json() : null;

            if (!res.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || res.status;
                return Promise.reject(error);
            }

            return data;

        }).catch((res)=>{
            handleApiErrorResponse(res);
        });
}

export function getTestCardSortingCardsResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-cards?test_id="+
        formValues.test_id,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingMatrixResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-matrix?test_id="+
        formValues.test_id,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getTestCardSortingSimilarityMatrixResultData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "researcher/test-results/card-sorting-similarity-matrix?test_id="+
        formValues.test_id,
        {
            method: "get",
            headers: { Authorization: "Bearer " + token },
        }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function getFirstClickData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/first-click?test_id="+
        formValues.test_id+"&task_id="+formValues.task_id,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTreeChartData(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT +
        "test/result/tree-test/get-chart?test_id="+
        formValues.test_id+"&task_id="+formValues.task_id,
        {
        method: "get",
        headers: { Authorization: "Bearer " + token },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}