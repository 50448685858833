import { useState, useEffect } from "react";
import { maxLengthCheck } from "../../../../lib/helpers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import Options from './options';
import InputField from './../../../layouts/elements/fields/input';
import { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";

export default function EditQuestion(props) {
    const [questionEditFormValues, setQuestionEditFormValues] = useState({
        question: props.questionDetails.question,
        is_optional: props.questionDetails.is_optional,
        is_logic: props.questionDetails.is_logic,
    });

    const [questionEditFormErrors, setQuestionEditFormErrors] = useState({
        question: null,
        error_class: null,
    });

    const [allOptions, setAllOptions] = useState(props.questionDetails.questionType === "free" ? [] : [...props.questionDetails.options]);
    const [tempQuestionType, setTempQuestionType] = useState(props.questionDetails.questionType);
    const [tempAllOptions, setTempAllOptions] = useState([""]);
    const [allJumpTo, setAllJumpTo] = useState([]);
    const [likertSubQuestions, setLikertSubQuestions] = useState(props.questionDetails.questionType === "likertscale" ? [...props.questionDetails.subQuestions] : ['']);
    const [unsavedOptions, setUnsavedOptions] = useState([]);

    const [optionError, setOptionError] = useState("");
    const [optionErrorIndex, setOptionErrorIndex] = useState(null);
    const [otherOptionIncluded, setOtherOptionIncluded] = useState(props.other);
    const [ratingScale, setRatingScale] = useState("5");
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [logicAdded, setLogicAdded] = useState(props.questionDetails.is_logic);
    const [jumpToListItems, setJumpToListItems] = useState([]);

    //   const questionTypes=["free","singlechoice", "multiplechoice","ratingscale","likertscale","rankingscale"];
    const questionTypes = [{ label: "Free text", value: "free" }, { label: "Single choice", value: "singlechoice" }, { label: "Multiple choice", value: "multiplechoice" }, { label: "Rating scale", value: "ratingscale" }, { label: "Likert scale", value: "likertscale" }, { label: "Ranking scale", value: "rankingscale" }];



    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 2000,
            }
        );
    };

    const setQuestion = (name) => {
        return ({ target: { value } }) => {
            setQuestionEditFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            if (value.length > 0) {
                setQuestionEditFormErrors({ question: null, error_class: null });
            }
        };
    };

    const saveOptionHandler = (index, value) => {
        allOptions.splice(index, 1, value);
        setAllOptions([...allOptions]);
    };

    const saveOptionJumpToHandler = (index, value) => {
        allJumpTo.splice(index, 1, value);
        setAllJumpTo([...allJumpTo]);
    };
    const closeOtherOptionHandler = () => {
        const list = [...allOptions];
        list.pop();
        setAllOptions(list);
        const listjumto = [...allJumpTo];
        listjumto.pop();
        setAllJumpTo(listjumto);
        setOtherOptionIncluded(false);
    };


    const closeOptionHandler = (index) => {
        const list = [...allOptions];
        list.splice(index, 1);
        setAllOptions(list);
        const listjumto = [...allJumpTo];
        listjumto.splice(index, 1);
        setAllJumpTo(listjumto);
    };
    const editOptionHandler = (index) => {
        const list = [...allOptions];
        const optionToBeEdited = list[index];
        list.splice(index, 1);
        setAllOptions(list);

        const listUnsavedOptions = [...unsavedOptions];
        listUnsavedOptions.splice(0, 0, optionToBeEdited);
        setUnsavedOptions(listUnsavedOptions);
    };

    const addSubQuestionHandler = () => {
        likertSubQuestions.push("");
        setLikertSubQuestions([...likertSubQuestions]);
    };

    const removeSubQuestionHandler = (index) => {
        if (likertSubQuestions.length == 1) {
            showError(
                "Minimum one option is required."
            );
        } else {
            const list = [...likertSubQuestions];
            list.splice(index, 1);
            setLikertSubQuestions(list);
        }
    };

    useEffect(() => { }, [unsavedOptions, allOptions]);
    useEffect(() => {
        var list = [];
        for (var i = props.questionno + 1; i <= props.questionlist.length; i++) {
            list.push(i);
        }
        setJumpToListItems(list);

        if (props.questionDetails.is_logic == 1) {
            setAllJumpTo(props.questionDetails.jumpTo);
        }
        else {
            var jumptolist = [];
            for (var i = 0; i < allOptions.length; i++) {
                jumptolist.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
            }
            setAllJumpTo(jumptolist);
        }
        if (props.questionDetails.questionType == "ratingscale") {
            if (props.questionDetails.options.length == 5) {
                setRatingScale("5");
            } else if (props.questionDetails.options.length == 7) {
                setRatingScale("7");
            } else {
                setRatingScale("10");
            }
        }
    }, []);

    const showQuestionFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, questionEditFormErrors);

        for (var key in questionEditFormErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setQuestionEditFormErrors(formErrorsLocal);

    };

    const handleQuestionEditSubmit = () => {
        setOptionErrorIndex(null);
        if (questionEditFormValues.question === "") {
            showQuestionFormError("question", "Required field");
            document.getElementById("question").scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            // error = true;
        } else {
            if (props.questionDetails.questionType === "free") {
                props.editQuestions({
                    questionType: props.questionDetails.questionType,
                    question: questionEditFormValues.question,
                    is_optional: questionEditFormValues.is_optional,
                });
                setQuestionEditFormValues({
                    question: "",
                    is_optional: true,
                });
                props.cancelEditQuestion();
            }
            if (props.questionDetails.questionType === "singlechoice") {
                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element == "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                if (nulloptioncount < 1) {
                    if (allOptions.length < 2) {
                        showError("Single choice questions require atleast two options.");
                    } else {
                        props.editQuestions({
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            is_logic: questionEditFormValues.is_logic,
                            options: allOptions,
                            jumpTo: allJumpTo,
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);
                    }
                }
                else {
                    showError("Your answer is empty - please enter option.");
                }
            }
            if (props.questionDetails.questionType === "multiplechoice") {
                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element == "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                if (nulloptioncount < 1) {
                    if (allOptions.length < 2) {
                        showError(
                            "Single choice questions require atleast two options."
                        );
                    } else {
                        props.editQuestions({
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            options: allOptions,
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);
                    }
                } else {
                    showError("Your answer is empty - please enter option.");
                }
            }

            if (props.questionDetails.questionType === "rankingscale") {
                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element == "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                if (nulloptioncount < 1) {
                    if (allOptions.length < 2) {
                        showError("Ranking scale questions require atleast two options.");
                    } else {
                        props.editQuestions({
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            options: allOptions,
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);
                    }
                }
                else {
                    showError("Your answer is empty - please enter option.");
                }
            }

            if (props.questionDetails.questionType === "likertscale") {

                let nulloptioncount = 0;
                allOptions.forEach((element) => {
                    if (element == "") {
                        nulloptioncount = nulloptioncount + 1;
                    }
                });
                if (nulloptioncount < 1) {
                    let nullsubQuestioncount = 0;
                    likertSubQuestions.forEach((element) => {
                        if (element == "") {
                            nullsubQuestioncount = nullsubQuestioncount + 1;
                        }
                    });
                    if (nullsubQuestioncount > 0) {
                        showError(
                            "Your answer is empty - please enter option."
                        );
                    } else {
                        props.editQuestions({
                            questionType: props.questionDetails.questionType,
                            question: questionEditFormValues.question,
                            is_optional: questionEditFormValues.is_optional,
                            options: allOptions,
                            subQuestions: likertSubQuestions,
                        });
                        setQuestionEditFormValues({
                            question: "",
                            is_optional: true,
                        });
                        props.cancelEditQuestion();
                        setAllOptions([]);

                    }
                } else {
                    showError("Your answer is empty - please enter option.");
                }

            }

            if (props.questionDetails.questionType === "ratingscale") {

                props.editQuestions({
                    questionType: props.questionDetails.questionType,
                    question: questionEditFormValues.question,
                    is_optional: questionEditFormValues.is_optional,
                    options: allOptions,
                });
                setQuestionEditFormValues({
                    question: "",
                    is_optional: true,
                });
                props.cancelEditQuestion();
                setAllOptions([]);

            }
        }
    };

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];



        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    return (
        <>
            {props.questionDetails.questionType == "free" && (
                <>
                    <div className="dflex-wrap select-question-type">
                        <div className="edit-freeques-header-left">
                            <div className="dropdown h3 mb-0 font-inter">
                                Question {props.questionno} - Free text
                            </div>
                            <div className="small-dropdown change-questiontype-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {questionTypes.map((item, i) => (
                                                item.value != props.questionDetails.questionType &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        props.editQuestions({
                                                            questionType: item.value,
                                                            question: questionEditFormValues.question,
                                                            is_optional: questionEditFormValues.is_optional,
                                                            options: allOptions,
                                                        });
                                                        if (item.value == "likertscale") {
                                                            props.addLikertSubquestions();
                                                            setTempAllOptions(allOptions.length == 0 ? [""] : [...allOptions]);
                                                            if (props.language == "en") {
                                                                setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree", "Not applicable"]);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده", "لا ينطبق"]);
                                                            }
                                                        }
                                                        if (item.value == "ratingscale") {
                                                            setTempAllOptions(allOptions.length == 0 ? [""] : [...allOptions]);
                                                            if (props.language == "en") {
                                                                setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree"]);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده"]);
                                                            }
                                                        }
                                                        if (item.value == "singlechoice") {
                                                            setAllOptions([...tempAllOptions]);
                                                            setQuestionEditFormValues({
                                                                ...questionEditFormValues,
                                                                is_logic: 0,
                                                            })
                                                        }
                                                        if (item.value == "multiplechoice" || item.value == "rankingscale") {
                                                            setAllOptions([...tempAllOptions]);
                                                        }
                                                    }}
                                                >
                                                    {item.label}

                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </div>
                        </div>
                        <div className="sqa-check-top">
                            <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                    checked={Boolean(questionEditFormValues.is_optional)}
                                    onChange={(e) =>
                                        setQuestionEditFormValues({
                                            ...questionEditFormValues,
                                            is_optional: e.target.checked ? 1 : 0,
                                        })
                                    }
                                />
                                <label className="custom-control-label" htmlFor="customCheck1">
                                    Optional
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-projectmodal-form-group">
                        <InputField
                            required={true}
                            label="Your question"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                setTimeout(() => {
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }, 1000);
                            }}
                            value={questionEditFormValues.question}
                            maxLength="350"
                            error={questionEditFormErrors.question}
                            error_class={questionEditFormErrors.error_class}
                            id="question"
                            rtl={props.language == "ar" ? true : false}
                        />
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                        <button
                            type="button"
                            className="button secondary-btn"
                            onClick={() => {
                                props.changeQuestionType(tempQuestionType);
                                props.cancelEditQuestion();
                                setQuestionEditFormValues({
                                    question: "",
                                    is_optional: true,
                                });
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="button primary-btn"
                            onClick={handleQuestionEditSubmit}
                        >
                            Save
                        </button>
                    </div>
                </>
            )}
            {(props.questionDetails.questionType == "singlechoice" ||
                props.questionDetails.questionType == "multiplechoice" || props.questionDetails.questionType == "rankingscale") && (
                    <>
                        <div>
                            <div className="dflex-wrap select-question-type">
                                <div className="edit-ques-header-left">
                                    {props.questionDetails.questionType == "singlechoice" && (
                                        <div className="dropdown h3 mb-0 font-inter">
                                            Question {props.questionno} - Single Choice

                                        </div>
                                    )}
                                    {props.questionDetails.questionType == "multiplechoice" &&
                                        (
                                            <div className="dropdown h3 mb-0 font-inter">
                                                Question {props.questionno} - Multi Choice

                                            </div>
                                        )}
                                    {props.questionDetails.questionType == "rankingscale" &&
                                        (
                                            <div className="dropdown h3 mb-0 font-inter">
                                                Question {props.questionno} - Ranking Scale

                                            </div>
                                        )}
                                    <div className="small-dropdown change-questiontype-dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                id="dropdownMenuButton"
                                                className="dropdown-toggle"
                                            >
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="drop-down-link-hold">
                                                    {questionTypes.map((item, i) => (
                                                        item.value != props.questionDetails.questionType &&
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                props.changeQuestionType(item.value);
                                                                if (item.value == "likertscale") {
                                                                    setTempAllOptions([...allOptions]);
                                                                    props.addLikertSubquestions();
                                                                    setLikertSubQuestions([...likertSubQuestions]);
                                                                    if (props.language == "en") {
                                                                        setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree", "Not applicable"]);
                                                                    }
                                                                    else {
                                                                        setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده", "لا ينطبق"]);
                                                                    }
                                                                }
                                                                if (item.value == "ratingscale") {
                                                                    setTempAllOptions([...allOptions]);
                                                                    if (props.language == "en") {
                                                                        setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree"]);
                                                                    }
                                                                    else {
                                                                        setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده"]);
                                                                    }
                                                                }
                                                                if (item.value == "singlechoice") {
                                                                    if (props.questionType == "likertscale" || props.questionType == "likertscale") {
                                                                        setAllOptions([...tempAllOptions]);
                                                                    }
                                                                    setQuestionEditFormValues({
                                                                        ...questionEditFormValues,
                                                                        is_logic: 0,
                                                                    })
                                                                }
                                                                if (item.value == "multiplechoice" || item.value == "rankingscale") {
                                                                    setLogicAdded(false);
                                                                    setQuestionEditFormValues({
                                                                        ...questionEditFormValues,
                                                                        is_logic: 0,
                                                                    })

                                                                    setAllOptions([...allOptions]);
                                                                }
                                                                if (item.value == "free") {
                                                                    setTempAllOptions([...allOptions]);
                                                                    setLogicAdded(false);
                                                                    setAllOptions([]);
                                                                }


                                                            }}
                                                        >
                                                            {item.label}

                                                        </Dropdown.Item>
                                                    ))}
                                                </div>
                                            </Dropdown.Menu>

                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="sqa-check-top singlechoice-addlogic">
                                    <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck1"
                                            checked={Boolean(questionEditFormValues.is_optional)}
                                            onChange={(e) =>
                                                setQuestionEditFormValues({
                                                    ...questionEditFormValues,
                                                    is_optional: e.target.checked ? 1 : 0,
                                                })
                                            }
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck1">
                                            Optional
                                        </label>
                                    </div>
                                    {props.questionDetails.questionType == "singlechoice" && (
                                        <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="logicCheck"
                                                checked={Boolean(questionEditFormValues.is_logic)}
                                                onChange={(e) => {
                                                    setQuestionEditFormValues({
                                                        ...questionEditFormValues,
                                                        is_logic: e.target.checked ? 1 : 0,
                                                    });
                                                    setLogicAdded(logicAdded == true ? false : true);
                                                }
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="logicCheck"
                                            >
                                                Add logic
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="create-projectmodal-form-group">
                            <InputField
                                required={true}
                                label="Your question"
                                onChange={setQuestion("question")}
                                onClear={() => {
                                    setTimeout(() => {
                                        setQuestionEditFormValues((oldValues) => ({
                                            ...oldValues,
                                            question: "",
                                        }));
                                    }, 1000);
                                }}
                                value={questionEditFormValues.question}
                                maxLength="350"
                                error={questionEditFormErrors.question}
                                error_class={questionEditFormErrors.error_class}
                                id="question"
                                rtl={props.language == "ar" ? true : false}
                            />
                        </div>
                        <div className={`ans-list-wrap ${questionEditFormValues.is_logic == 1 ? "survey-logic-added" : ""}`}>


                            <p className="small-info-heading">Your answers</p>
                            <Options
                                allOptions={allOptions}
                                edit={(index) => editOptionHandler(index)}
                                close={(index) => closeOptionHandler(index)}
                                closeOtherOption={(index) => closeOtherOptionHandler()}
                                save={(index, value) => saveOptionHandler(index, value)}
                                saveJumpTo={(index, value) => saveOptionJumpToHandler(index, value)}
                                language={props.language}
                                addOption={(index) => {
                                    if (allOptions.length < 200) {
                                        allOptions.splice(index + 1, 0, "");
                                        setAllOptions([...allOptions]);
                                        allJumpTo.splice(index + 1, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                        setAllJumpTo([...allJumpTo]);
                                    }
                                    else {

                                        showError("Max two hundred options can be added")
                                    }
                                }}
                                logicAdded={logicAdded}
                                jumpToList={jumpToListItems}
                                allJumpTo={allJumpTo}
                                questionno={props.questionno}
                            />
                        </div>
                        <div className="d-flex align-items-center add-survey-answer-btn">
                            <button
                                className="create-btn add-question-btn text-uppercase mt-0"
                                onClick={() => {
                                    if (allOptions.length < 200) {
                                        if (otherOptionIncluded == true) {
                                            allOptions.splice(-1, 0, "");
                                            setAllOptions([...allOptions]);
                                            allJumpTo.splice(-1, 0, props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                            setAllJumpTo([...allJumpTo]);
                                        } else {
                                            allOptions.push("");
                                            setAllOptions([...allOptions]);
                                            allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                            setAllJumpTo([...allJumpTo]);
                                        }
                                    } else {
                                        showError("Max two hundred options can be added");
                                    }
                                }}
                            >
                                Add option
                                <span>
                                    <img
                                        src={process.env.REACT_APP_URL + "images/plus-white.svg"}
                                        alt="img"
                                    />
                                </span>
                            </button>
                            {props.questionDetails.questionType != "rankingscale" && otherOptionIncluded == false && (
                                <div className="add-other-survey">
                                    or add{" "}
                                    <span
                                        className="pink-color"
                                        onClick={() => {
                                            if (allOptions.length < 200) {
                                                // allOptions.push("Other");
                                                if (props.language == "ar") {
                                                    allOptions.push("آخر");
                                                    allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                }
                                                else {
                                                    allOptions.push("Other");
                                                    allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                }
                                                setOtherOptionIncluded(true);
                                                allJumpTo.push(props.questionno < props.questionlist.length ? props.questionno + 1 : "End Survey");
                                                setAllJumpTo([...allJumpTo]);
                                            } else {
                                                showError("Max two hundred options can be added.");
                                            }
                                        }}
                                    >
                                        Other
                                    </span>{" "}
                                    option
                                </div>
                            )}
                        </div>
                        <div className="button-wrap d-flex justify-content-end">
                            <button
                                type="button"
                                className="button secondary-btn"
                                onClick={() => {
                                    props.changeQuestionType(tempQuestionType);
                                    props.cancelEditQuestion();
                                    setQuestionEditFormValues({
                                        question: "",
                                        is_optional: true,
                                    });
                                    setOtherOptionIncluded(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="button primary-btn"
                                onClick={handleQuestionEditSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </>
                )}
            {(props.questionDetails.questionType == "ratingscale") && (
                <div className="survey-question-holder">
                    <div className="bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap">
                        <div className="bd-que-slide-left">
                            <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Question {props.questionno} - Rating Scale
                                </div>
                            </div>
                            <div className="small-dropdown change-questiontype-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {questionTypes.map((item, i) => (
                                                item.value != props.questionDetails.questionType &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        props.changeQuestionType(item.value);
                                                        if (item.value == "likertscale") {
                                                            props.addLikertSubquestions();
                                                            if (props.language == "en") {
                                                                setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree", "Not applicable"]);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده", "لا ينطبق"]);
                                                            }
                                                        }
                                                        if (item.value == "singlechoice") {
                                                            setAllOptions([...tempAllOptions]);
                                                            setQuestionEditFormValues({
                                                                ...questionEditFormValues,
                                                                is_logic: 0,
                                                            })
                                                        }
                                                        if (item.value == "multiplechoice" || item.value == "ratingscale" || item.value == "rankingscale" || item.value == "free") {
                                                            setAllOptions([...tempAllOptions]);
                                                        }
                                                    }}
                                                >
                                                    {item.label}

                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </div>
                        </div>
                        <div className="bd-que-slide-arrow">
                            <div className="sqa-check-top">
                                <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck1"
                                        checked={Boolean(questionEditFormValues.is_optional)}
                                        onChange={(e) =>
                                            setQuestionEditFormValues({
                                                ...questionEditFormValues,
                                                is_optional: e.target.checked ? 1 : 0,
                                            })
                                        }

                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customCheck1"
                                    >
                                        Optional
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-projectmodal-form-group">
                        <InputField
                            required={true}
                            label="Your question"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                setTimeout(() => {
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }, 1000);
                            }}
                            value={questionEditFormValues.question}
                            maxLength="350"
                            error={questionEditFormErrors.question}
                            error_class={questionEditFormErrors.error_class}
                            id="question"
                            rtl={props.language == "ar" ? true : false}
                        />
                    </div>
                    {/* <div className="input-field static-label mb-0">
                      <div className="checkboxes radio_btn_class gender_btn  d-flex language-checkbox-wrap">
                        <label htmlFor="five" className="mt-0">
                          <input
                            type="radio"
                            name="scale"
                            id="five"
                            value="5"
                            className="hidden radio_btn_input"
                            checked={ratingScale === "5" ? true : false}
                            onChange={(e) => {
                              setRatingScale("5");
                              setAllOptions(["Strongly agree","Somewhat agree","Neutral","Somewhat disagree","Strongly disagree"]);
                            }}
                          />
                          <span>
                            <img
                              className="empty-fill-icon"
                              src={
                                process.env.REACT_APP_URL +
                                "images/Polygon-blank.svg"
                              }
                            />
                            <img
                              className="fill-icon"
                              src={
                                process.env.REACT_APP_URL +
                                "images/pol-fill.svg"
                              }
                            />
                          </span>
                          <p>5 point scale</p>
                        </label>
                        <label htmlFor="seven" className="mt-0">
                          <input
                            type="radio"
                            name="scale"
                            id="seven"
                            value="7"
                            className="hidden radio_btn_input"
                            checked={ratingScale === "7" ? true : false}
                            onChange={(e) => {
                              setRatingScale("7");
                              setAllOptions(["xyz","Strongly agree","Somewhat agree","Neutral","Somewhat disagree","Strongly disagree","xyz"]);
                            }}
                          />
                          <span>
                            <img
                              className="empty-fill-icon"
                              src={
                                process.env.REACT_APP_URL +
                                "images/Polygon-blank.svg"
                              }
                            />
                            <img
                              className="fill-icon"
                              src={
                                process.env.REACT_APP_URL +
                                "images/pol-fill.svg"
                              }
                            />
                          </span>
                          <p>7 point scale</p>
                        </label>
                        <label htmlFor="ten" className="mt-0">
                          <input
                            type="radio"
                            name="scale"
                            id="ten"
                            value="10"
                            className="hidden radio_btn_input"
                            checked={ratingScale === "10" ? true : false}
                            onChange={(e) => {
                              setRatingScale("10");
                              setAllOptions(["xyz","xyz","Strongly agree","Somewhat agree","Neutral","Somewhat disagree","Strongly disagree","xyz","xyz","xyz"]);
                            }}
                          />
                          <span>
                            <img
                              className="empty-fill-icon"
                              src={
                                process.env.REACT_APP_URL +
                                "images/Polygon-blank.svg"
                              }
                            />
                            <img
                              className="fill-icon"
                              src={
                                process.env.REACT_APP_URL +
                                "images/pol-fill.svg"
                              }
                            />
                          </span>
                          <p>10 point scale</p>
                        </label>
                      </div>
                </div> */}
                    <div className="ans-list-wrap your-selected-answer-view">
                        <p className="small-info-heading">Your answers</p>
                        <div className="survey-rating-options-wrap">
                            <div className="bd-question-radio-data  rating-question-hold">
                                <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio create-rating-survey-answer">
                                    {/* {allOptions.map((option,index)=>(
                        
                                    <label for="radio4">
                                        <p>{option}</p>
                                        <input id={`radio-${index}`} type="radio" name="ans" value={option} class="hidden radio_btn_input"  required="required" />
                                        <span>
                                        <img
                                        className="empty-fill-icon"
                                        src={
                                        process.env.REACT_APP_URL +
                                        "images/Polygon-blank.svg"
                                        }
                                        />
                                        <img
                                        className="fill-icon"
                                        src={
                                        process.env.REACT_APP_URL +
                                        "images/pol-fill.svg"
                                        }
                                        />
                                        </span>
                                    </label>                                        
                                
                                    ))} */}
                                    <Options
                                        allOptions={allOptions}
                                        edit={(index) => editOptionHandler(index)}
                                        close={(index) => closeOptionHandler(index)}
                                        closeOtherOption={(index) => closeOtherOptionHandler()}
                                        save={(index, value) => saveOptionHandler(index, value)}
                                        language={props.language}
                                        questionType={props.questionDetails.questionType}
                                        addOption={(index) => {
                                            if (allOptions.length < 200) {
                                                allOptions.splice(index + 1, 0, "");
                                                setAllOptions([...allOptions]);
                                            }
                                            else {

                                                showError("Max two hundred options can be added")
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-wrap d-flex justify-content-end">
                        <button
                            type="button"
                            className="button secondary-btn"
                            onClick={() => {
                                props.changeQuestionType(tempQuestionType);
                                props.cancelEditQuestion();
                                setQuestionEditFormValues({
                                    question: "",
                                    is_optional: true,
                                });
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="button primary-btn"
                            onClick={handleQuestionEditSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
            {(props.questionDetails.questionType == "likertscale") && (
                <div className="survey-question-holder">
                    <div className="bd-question-top-slide d-flex justify-content-between survey-question-dropdoen-wrap">
                        <div className="bd-que-slide-left">
                            <div className="db-que-list-wrap survey-question-drop-down">
                                <div className="dropdown">
                                    Question {props.questionno} - Likert Scale
                                </div>
                            </div>
                            <div className="small-dropdown change-questiontype-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="drop-down-link-hold">
                                            {questionTypes.map((item, i) => (
                                                item.value != props.questionDetails.questionType &&
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        props.changeQuestionType(item.value);
                                                        if (item.value == "ratingscale") {
                                                            if (props.language == "en") {
                                                                setAllOptions(["Strongly agree", "Somewhat agree", "Neutral", "Somewhat disagree", "Strongly disagree"]);
                                                            }
                                                            else {
                                                                setAllOptions(["أوافق بشدة", "أوافق إلى حد ما", "محايد", "اختلف إلى حد ما", "اختلف بشده"]);
                                                            }
                                                        }
                                                        if (item.value == "singlechoice") {
                                                            setAllOptions([...tempAllOptions]);
                                                            setQuestionEditFormValues({
                                                                ...questionEditFormValues,
                                                                is_logic: 0,
                                                            })
                                                        }
                                                        if (item.value == "multiplechoice" || item.value == "rankingscale" || item.value == "free") {
                                                            setAllOptions([...tempAllOptions]);
                                                        }
                                                    }}
                                                >
                                                    {item.label}

                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </div>
                        </div>
                        <div className="bd-que-slide-arrow">
                            <div className="sqa-check-top">
                                <div className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck1"
                                        checked={Boolean(questionEditFormValues.is_optional)}
                                        onChange={(e) =>
                                            setQuestionEditFormValues({
                                                ...questionEditFormValues,
                                                is_optional: e.target.checked ? 1 : 0,
                                            })
                                        }
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="customCheck1"
                                    >
                                        Optional
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-projectmodal-form-group">
                        <InputField
                            required={true}
                            label="Your question"
                            onChange={setQuestion("question")}
                            onClear={() => {
                                setTimeout(() => {
                                    setQuestionEditFormValues((oldValues) => ({
                                        ...oldValues,
                                        question: "",
                                    }));
                                }, 1000);
                            }}
                            value={questionEditFormValues.question}
                            maxLength="350"
                            error={questionEditFormErrors.question}
                            error_class={questionEditFormErrors.error_class}
                            id="question"
                            rtl={props.language == "ar" ? true : false}
                        />
                    </div>
                    {/* <div className="input-field static-label mb-0">
                  <div className="checkboxes radio_btn_class gender_btn  d-flex language-checkbox-wrap">
                    <label htmlFor="five" className="mt-0">
                      <input
                        type="radio"
                        name="scale"
                        id="five"
                        value="5"
                        className="hidden radio_btn_input"
                        checked={ratingScale === "5" ? true : false}
                        onChange={(e) => {
                          setRatingScale("5");
                          setAllOptions(["Strongly agree","Somewhat agree","Neutral","Somewhat disagree","Strongly disagree"]);
                        }}
                      />
                      <span>
                        <img
                          className="empty-fill-icon"
                          src={
                            process.env.REACT_APP_URL +
                            "images/Polygon-blank.svg"
                          }
                        />
                        <img
                          className="fill-icon"
                          src={
                            process.env.REACT_APP_URL +
                            "images/pol-fill.svg"
                          }
                        />
                      </span>
                      <p>5 point scale</p>
                    </label>
                    <label htmlFor="seven" className="mt-0">
                      <input
                        type="radio"
                        name="scale"
                        id="seven"
                        value="7"
                        className="hidden radio_btn_input"
                        checked={ratingScale === "7" ? true : false}
                        onChange={(e) => {
                          setRatingScale("7");
                          setAllOptions(["xyz","Strongly agree","Somewhat agree","Neutral","Somewhat disagree","Strongly disagree","xyz"]);
                        }}
                      />
                      <span>
                        <img
                          className="empty-fill-icon"
                          src={
                            process.env.REACT_APP_URL +
                            "images/Polygon-blank.svg"
                          }
                        />
                        <img
                          className="fill-icon"
                          src={
                            process.env.REACT_APP_URL +
                            "images/pol-fill.svg"
                          }
                        />
                      </span>
                      <p>7 point scale</p>
                    </label>
                    <label htmlFor="ten" className="mt-0">
                      <input
                        type="radio"
                        name="scale"
                        id="ten"
                        value="10"
                        className="hidden radio_btn_input"
                        checked={ratingScale === "10" ? true : false}
                        onChange={(e) => {
                          setRatingScale("10");
                          setAllOptions(["xyz","xyz","Strongly agree","Somewhat agree","Neutral","Somewhat disagree","Strongly disagree","xyz","xyz","xyz"]);
                        }}
                      />
                      <span>
                        <img
                          className="empty-fill-icon"
                          src={
                            process.env.REACT_APP_URL +
                            "images/Polygon-blank.svg"
                          }
                        />
                        <img
                          className="fill-icon"
                          src={
                            process.env.REACT_APP_URL +
                            "images/pol-fill.svg"
                          }
                        />
                      </span>
                      <p>10 point scale</p>
                    </label>
                  </div>
            </div> */}
                    <div className={`ans-list-wrap your-selected-answer-view  likert-question-data-hold ${props.language == "ar" ? 'arabic_wrapper' : ''}`}>
                        <p className="small-info-heading">Your answers</p>

                        <div className="likert-text-question-inner-data">
                            <div className="survey-rating-options-wrap">
                                <div className="bd-question-radio-data">

                                    <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0">
                                        {/* {allOptions.map((option,index)=>(
                                <label for="radio4">
                                    <p>{option}</p>    
                                </label>
                                                                    
                                ))} */}
                                        <div className="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                            <div className="ans-list-repeat saved-ans-repeat">

                                                <div className="likert-option-left">
                                                    <div className="ans-icon">
                                                        &nbsp;
                                                    </div>
                                                    <div className="ans-data">
                                                        &nbsp;
                                                    </div>
                                                </div>

                                                <div className="likert-option-right">
                                                    <Options
                                                        allOptions={allOptions}
                                                        edit={(index) => editOptionHandler(index)}
                                                        close={(index) => closeOptionHandler(index)}
                                                        closeOtherOption={(index) => closeOtherOptionHandler()}
                                                        save={(index, value) => saveOptionHandler(index, value)}
                                                        language={props.language}
                                                        questionType={props.questionDetails.questionType}
                                                        addOption={(index) => {
                                                            if (allOptions.length < 200) {
                                                                allOptions.splice(index + 1, 0, "");
                                                                setAllOptions([...allOptions]);
                                                            }
                                                            else {

                                                                showError("Max two hundred options can be added")
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <DragDropContext
                                    onDragEnd={(param) => {
                                        const srcI = param.source.index;
                                        const desI = param.destination.index;
                                        likertSubQuestions.splice(desI, 0, likertSubQuestions.splice(srcI, 1)[0]);

                                        setPlaceholderProps({});
                                    }}
                                    onDragStart={handleDragStart}
                                    onDragUpdate={handleDragUpdate}
                                >
                                    <Droppable droppableId="droppable-1">
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {likertSubQuestions.map((subQuestion, index) => (
                                                    <Draggable
                                                        key={index}
                                                        draggableId={"optiondraggable-" + index}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={`ans-list-repeat saved-ans-repeat ${snapshot.isDragging ? "question-answers-dragging" : ""
                                                                    } `}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className="ans-list-wrap likert-scale-option-add" key={index}>
                                                                    <div className="ans-list-repeat saved-ans-repeat">
                                                                        <div className="likert-option-left">
                                                                            <div className="ans-icon">
                                                                                <img src={process.env.REACT_APP_URL + "images/drag-icon.svg"} alt="img" />
                                                                            </div>
                                                                            <div className="ans-data">
                                                                                <input
                                                                                    className={`likert-subquestion ${subQuestion != ""
                                                                                        ? ""
                                                                                        : optionErrorIndex != index ? optionError : ""
                                                                                        } ${props.language == "ar" ? "arabic_wrapper" : ""
                                                                                        }`}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        likertSubQuestions.splice(index, 1, e.target.value);
                                                                                        setLikertSubQuestions([...likertSubQuestions]);
                                                                                    }}
                                                                                    value={subQuestion}
                                                                                    placeholder={`Option ${index + 1}`}
                                                                                    onBlur={(e) => {
                                                                                        if (likertSubQuestions[index] == "") {
                                                                                            // e.target.placeholder =
                                                                                            //   "Please enter an option";
                                                                                            setOptionError("input_error");
                                                                                            setOptionErrorIndex(null);
                                                                                        } else {
                                                                                            // props.save(index, e.target.value);
                                                                                        }
                                                                                    }}
                                                                                    onFocus={(e) => { setOptionErrorIndex(index); }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            likertSubQuestions.splice(index + 1, 0, "");
                                                                                            setLikertSubQuestions([...likertSubQuestions]);
                                                                                        }
                                                                                    }}
                                                                                    autoFocus={likertSubQuestions.length==1?false:true}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="likert-option-right">
                                                                            <div className="survey-rating-options-wrap">
                                                                                <div className="bd-question-radio-data">
                                                                                    <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                        {allOptions.map((option, index) => (
                                                                                            <label for="radio4">
                                                                                                {/* <p>{option}</p> */}
                                                                                                <input id={`radio-${index}`} type="radio" name="ans" value={option} class="hidden radio_btn_input" required="required" />
                                                                                                <span>
                                                                                                    <img
                                                                                                        className="empty-fill-icon"
                                                                                                        src={
                                                                                                            process.env.REACT_APP_URL +
                                                                                                            "images/Polygon-blank.svg"
                                                                                                        }
                                                                                                    />
                                                                                                    <img
                                                                                                        className="fill-icon"
                                                                                                        src={
                                                                                                            process.env.REACT_APP_URL +
                                                                                                            "images/pol-fill.svg"
                                                                                                        }
                                                                                                    />
                                                                                                </span>
                                                                                            </label>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {likertSubQuestions.length > 1 &&
                                                                                <div className="ans-close-row"><span onClick={() => removeSubQuestionHandler(index)}><img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img" /></span></div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                {!isEmpty(placeholderProps) && (
                                                    <div
                                                        className="placeholder"
                                                        style={{
                                                            top: placeholderProps.clientY,
                                                            left: placeholderProps.clientX,
                                                            height: placeholderProps.clientHeight,
                                                            width: placeholderProps.clientWidth,
                                                            position: "absolute",
                                                            borderColor: "#000000",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                        </div>
                    </div>
                    <div className="d-flex align-items-center add-survey-answer-btn">
                        <button className="create-btn add-question-btn text-uppercase mt-0" onClick={addSubQuestionHandler}>Add option<span><img src={process.env.REACT_APP_URL + "images/plus-white.svg"} alt="img" /></span></button>
                    </div>

                    <div className="button-wrap d-flex justify-content-end">
                        <button
                            type="button"
                            className="button secondary-btn"
                            onClick={() => {
                                props.changeQuestionType(tempQuestionType);
                                props.cancelEditQuestion();
                                setQuestionEditFormValues({
                                    question: "",
                                    is_optional: true,
                                });
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="button primary-btn"
                            onClick={handleQuestionEditSubmit}
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
