import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "../layouts/layout";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getResultData, validateResultData } from "../../services/test_result";

import Introduction from "./introduction";
import Password from "./password";
import ErrorPage from "./error_page";
import AlreadyTakenTest from "./already_taken_test";
import Question from "./question";
import ConclusionPage from "./conclusion_page";
import DebriefingPage from "./defriefing_page";
import toast from "react-hot-toast";
import ExitTestModal from "./dialog/exit_test_modal";
import Methodology from "./methodology/methodology";
import { FullPageLoadingIcon } from './../loader/full_page_loader_lcon';
import { decryptId } from "../../lib/helpers";

export default function Test() {
	let { test_id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [test, setTest] = useState("");
	const [result, setResult] = useState("");
	const [similarTest, setSimilarTest] = useState("");
	const [settings, setSettings] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isNda, setNda] = useState(false);
	const [errorType, setErrorType] = useState("general");
	const [openExitTestModal, setOpenExitTestModal] = useState(false);
	const [timeSpent, setTimeSpent] = useState(0);
	const [isBackButtonClicked, setBackbuttonPress] = useState(false);

	const [questionType, setQuestionType] = useState("briefing");
	const [briefingQuestionArray, setBriefingQuestionArray] = useState([]);
	const [debriefingQuestionArray, setDebriefingQuestionArray] = useState([]);
	const [surveyQuestionArray, setSurveyQuestionArray] = useState([]);
	const [isActive, setActive] = useState("introduction");

	// is active type= introduction, password, 404Error, alreadyTakenTest, question

	// close exit test modal & rediect to home page if exits success
	const closeExitTestModal = (data) => {
		setOpenExitTestModal(false);
		if (data) {
			saveAbandonedTest(); // save abandoned test
			if(location.pathname.substr(1,1) === 'p'){
				navigate("/researcher/tests/" + atob(test_id) + "/introduction");
			}else{
				if(!(ReactSession.get("token"))){
					navigate("/tester/login");
				}else{
					navigate("/");
				}

			}
		}
	};

	const getTestResultApi = () => {
		let token = ReactSession.get("token");
		//if(!token){
		if (!ReactSession.get("guest_token")) {
			const randomToken = Math.random().toString(36).substr(2, 15);
			ReactSession.set("guest_token", randomToken);
		}
		// }

		setLoading(true);

		/*var regexBase64 =
			/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
		if (!regexBase64.test(test_id)) {
			setActive("404Error");
			setErrorType("404");
			return false;
		}*/


		getResultData(
			{
				test_id: test_id,
				type: location.pathname.substr(1, 1),
				guest_token: ReactSession.get("guest_token"),
			},
			token
		).then((response) => {
			setLoading(false);

			if (response.success) {
				setTest(response.test);
				setResult(response.result);
				setSimilarTest(response.similar_test);
				setSettings(response.settings);

				// save test id if test given by tester (After test given and sign up guest user will assign test automatically)
				if(!token){
					ReactSession.set("test_result_id", response.result.id);
				}

				// user role tester not completed profile then redirect to Profile complete steps
				if(!response.testerCompletedProfile){
					navigate('/profile/step1');
				}

				setActive("introduction");
				if (location.pathname.substr(1, 1) === "p") {
					// check test is password protected or not
					if (response.test && response.test.password !== null) {
						setActive("password");
					}
				} else {
					// check test is password protected or not
					if (response.result && !response.result.is_verify_password) {
						setActive("password");
					} else if (response.result && response.result.is_test_completed) {
						setActive("alreadyTakenTest");
					}
				}

				// if user has not signed nda
				if (response.result && response.result.is_nda === 0) {
					setNda(true);
				}
			} else {
				setActive("404Error");
			}
		});
	};

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Take a Test";

		getTestResultApi();

		// location scroll top
		window.scrollTo(0, 0);

		// set time taken by test
		function tickTime() {
			setTimeSpent((prevSeconds) => prevSeconds + 1);
		}
		let timeData = setInterval(() => tickTime(), 1000);

		return () => clearInterval(timeData);
	}, [location.pathname.substr(3)]);

	useEffect(() => {
		// show waring popup if user leave the meeting
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBackButtonEvent);
		window.addEventListener("beforeunload", onBackButtonEvent);

		//logic for showing popup warning on page refresh
		window.onbeforeunload = function () {
			setBackbuttonPress(true);
			return "Data will be lost if you leave the page, are you sure?";
		};
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
			window.removeEventListener("beforeunload", onBackButtonEvent);
		};
	}, ["location"]);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		if (!isBackButtonClicked) {
			if (window.confirm("Are you sure leave page without given test?")) {
				if (location.pathname.substr(1, 1) === "t") {
					setBackbuttonPress(true);
					saveAbandonedTest();
				} else {
					navigate("/");
				}
			} else {
				window.history.pushState(null, null, window.location.pathname);
				setBackbuttonPress(false);
			}
		}
	};

	const saveAbandonedTest = () => {
		const token = ReactSession.get("token");
		const data = new FormData();
		data.append("test_id", test && test.id);
		data.append("guest_token", ReactSession.get("guest_token"));
		data.append("validate", "abandoned");
		data.append("type", location.pathname.substr(1, 1));
		data.append("result_id", result && result.id);
		data.append("time_spent", timeSpent);

		setLoading(true);
		validateResultData(data, token).then((response) => {
			setLoading(false);

			if (response.success) {
				// console.log(window.history)
				navigate("/");
			} else {
				navigate("/");
				// toast(
				// 	<div className="toastinner">
				// 		{response.message}
				// 		<a
				// 			onClick={() => {
				// 				toast.dismiss();
				// 			}}
				// 		>
				// 			&times;
				// 		</a>
				// 	</div>,
				// 	{
				// 		className: response.success ? "successtoast" : "errortoast",
				// 		position: "bottom-center",
				// 		duration: 20000000,
				// 	}
				// );
			}
		});
	};

	const passwordCallback = () => {
		setActive("introduction");

		// show nda popup after password validate
		if (result && result.is_nda === 0) {
			setNda(true);
		}
	};

	// introduction callback
	const introductionCallback = (checkNda) => {
		// if user has not signed nda
		if (result && result.is_nda === 0) {
			if (checkNda === false) {
				setNda(!isNda);
				return false;
			} else {
				if (test && test.methodology === "Survey") {
					setActive("tree");
				} else {
					setQuestionType("briefing");
					setActive("question");
				}
			}
		} else {
			if (test && test.methodology === "Survey") {
				setActive("tree");
			} else {
				setQuestionType("briefing");
				setActive("question");
			}
		}
	};

	// question callback
	const questionCallback = (formData, type) => {
		if (type === "briefing") {
			setQuestionType("debriefing"); // set question type debriefing and activate tree page
			setBriefingQuestionArray(formData);
			setActive("tree");
		} else if (type === "debriefing") {
			setDebriefingQuestionArray(formData);
			setActive("conclusion");
		} else if (type === "Survey") {
			setSurveyQuestionArray(formData);
			setActive("conclusion");
		}
        else if (type === "Five Seconds Test Questions") {
			setActive("conclusion");
		}
	};

	// tree callback function
	const methodologyCallback = () => {
		// if no debriefing question then save data and redirect to conclusion page
		if (test && test.debriefing_question.length === 0) {
			setActive("question");
		} else {
			setActive("debriefing");
		}
	};

	const debriefingCallback = () => {
		setActive("question");
	};

	// conclusion page callback
	const conclusionCallback = () => {
		// console.log('kjahdjksa')
		// console.log(briefingQuestionArray)
		// console.log(debriefingQuestionArray)
	};

	return (
		<>
			{isActive === "404Error" && <ErrorPage errorType={errorType} />}
			{isActive !== "404Error" && (
				<div
					className={`${
						test.language == "en"
							? isActive === "alreadyTakenTest" || isActive === "password"
								? "login-page-wrapper "
								: isActive === "debriefing"
								? "test-debriefingquestion-page-wrapper"
								: isActive === "conclusion"
								? "tester-login-page-wrapper test-thanks-page-wrapper"
								: " tester-login-page-wrapper"
							: isActive === "alreadyTakenTest" || isActive === "password"
							? "login-page-wrapper arabic_wrapper"
							: isActive === "debriefing"
							? "test-debriefingquestion-page-wrapper arabic_wrapper"
							: isActive === "conclusion"
							? "tester-login-page-wrapper test-thanks-page-wrapper arabic_wrapper"
							: " tester-login-page-wrapper arabic_wrapper"
					}`}
				>
					{/* {isLoading && <div className="loader_page">Loading...</div>} */}
					{isLoading && <FullPageLoadingIcon/>}
					{!isLoading && (
						<>
							<div className="login-page-header tester-welcome-header">
								<div className="login-hdr-left none-for-mobile">
									<Link to={"/"} className="logo">
										<img
											src={process.env.REACT_APP_URL + "images/logo.svg"}
											alt="logo"
										/>
									</Link>
								</div>
								<div className="login-mid-sec">
									{/*<p>{test && (isActive !== 'password' && isActive !== 'alreadyTakenTest') && test.test_name}</p>*/}
								</div>
								<div className={`login-hdr-right taking-test-hdr-right`}>
									{test && isActive === "alreadyTakenTest" && (
										<Link className={"become-tester-btn"} to={"/tester/login"}>Become a tester</Link>
									)}

									{test &&
										isActive !== "alreadyTakenTest" &&
										isActive !== "conclusion" && (
											<>
											<Link to={'/tester/support/contact?reason=Problem with the platform'} className="create-btn">
												{test.language=="en"?<>CONTACT SUPPORT</>:<>اتصل بالدعم</>}
												<span>
												<img
													src={process.env.REACT_APP_URL + "images/arrow-right.png"}
													alt="img"/>
											</span>
											</Link>
											<Link className={"exit-test"} to={"#"} onClick={() => setOpenExitTestModal(true)}>
												{test.language=="en"?<>Exit test</>:<>اخرج</>}
											</Link>
											</>
										)}
								</div>
							</div>

							{location.pathname.substr(1, 1) === "p" &&
								isActive !== "password" &&
								isActive !== "alreadyTakenTest" &&
								isActive !== "debriefing" &&
								isActive !== "conclusion" && (
									<div className="profile-complete-progress-wrap notify-info">
										<div className="pro-lft-wrap">
											This is a preview. Your response <strong>will not</strong>{" "}
											be saved.
										</div>
										<div className="pro-right-wrap">
											{/*<div className="delete-progress-row">*/}
											{/*    <img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img"/>*/}
											{/*</div>*/}
										</div>
									</div>
								)}

							{isActive === "introduction" && (
								<Introduction
									introductionCallback={introductionCallback}
									timeSpent={timeSpent}
									test={test}
									result={result}
									isNda={isNda}
								/>
							)}

							{isActive === "password" && (
								<Password
									passwordCallback={passwordCallback}
									timeSpent={timeSpent}
									test={test}
									result={result}
								/>
							)}

							{isActive === "alreadyTakenTest" && <AlreadyTakenTest />}

							{isActive === "question" && (
								<Question
									test={test}
									result={result}
									timeSpent={timeSpent}
									questionCallback={questionCallback}
									questionType={questionType}
								/>
							)}
							{isActive === "tree" && (
								<Methodology
									test={test}
									result={result}
									timeSpent={timeSpent}
									methodologyCallback={methodologyCallback}
									questionCallback={questionCallback}
								/>
							)}

							{isActive === "debriefing" && (
								<DebriefingPage test={test} debriefingCallback={debriefingCallback} />
							)}

							{isActive === "conclusion" && (
								<ConclusionPage
									test={test}
									similarTest={similarTest}
									settings={settings}
									conclusionCallback={conclusionCallback}
								/>
							)}
						</>
					)}

					<ExitTestModal
						openExitTestModal={openExitTestModal}
						closeExitTestModal={closeExitTestModal}
                        test={test}
					/>
				</div>
			)}
		</>
	);
}
