import { useNavigate, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession.js";
import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/layout.js";
import { Dropdown } from "reactjs-dropdown-component";
import Select from "react-select";
import {
    getProfile,
    deleteAccount,
    updateTesterProfile,
} from "../../services/user.js";
import { nationalities } from "../../data/nationalities.js";
import { languages } from "../../data/languages_others.js";
import { countries } from "../../data/countries.js";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";
import IntlTelInput from "react-intl-tel-input";
import { PhoneNumberUtil } from "google-libphonenumber";
import toast from "react-hot-toast";
import ConfirmModal from "../dialog/confirm_modal.js";
import CheckboxNormal from "../layouts/elements/fields/checkbox_normal.js";
import { getAge , removeItemFromArray } from "../../lib/helpers.js";
import SelectElement from "../layouts/elements/fields/select_element.js";
import { FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { LoadingIcon } from "../loader/loadingIcon";
import { sendChangeEmailToUser } from './../../services/user';
import {unnormalize, normalize, DropdownIndicator} from "../../lib/helpers";
import TesterProfileNavigation from "./tester_profile_navigation.js";
import ChangePasswordModal from "./reset_password_modal.js";

export default function ProfileTester() {
    ReactSession.setStoreType("localStorage");

    let years = [];

    for (
        let year = new Date().getFullYear() - 8;
        year > new Date().getFullYear() - 8 - 50;
        year--
    ) {
        years.push({ label: year + "", value: year + "" });
    }
    const [openModal, setOpenModal] = useState(false);

    var today = new Date();

    let nationalitySelect = useRef();

    let languageSelect = useRef();

    let industrySelect = useRef();

    let yearSelect = useRef();

    let hoursSelect = useRef();

    let educationSelect = useRef();

    let countrySelect = useRef();

    let emirateSelect = useRef();

    let regionSelect = useRef();

    let jobRolesSelect = useRef();

    let employmentSelect = useRef();

    let departmentSelect = useRef();

    let gadgetSelect = useRef();

    let incomeSelect = useRef();

    const locations = nationalities;

    const [yearLabelClass, setYearLabelClass] = useState("inactive_label");

    const yearChange = (item) => {
        //yearSelect.current.selectSingleItem({ value: item.value });

        if (item) {
            setFormValue("year_of_birth", item.value);
            setYearLabelClass("active_label");
        } else {
            setFormValue("year_of_birth", null);
            setYearLabelClass("inactive_label");
        }
    };

    const dobChange = (date) => {
        setFormValue("date_of_birth", date);
        setFormErrors((oldValues) => ({ ...oldValues, date_of_birth: null }));
        setYearLabelClass("active_label");
    };

    const hoursOptions = [
        { label: "1-3 hours", value: "1-3 hours" },
        { label: "4-6 hours", value: "4-6 hours" },
        { label: "more than 6 hours", value: "more than 6 hours" },
    ];

    const industryOptions = [
        {
            label: "Accountancy, banking and finance",
            value: "Accountancy, banking and finance",
        },
        {
            label: "Business, consulting and management",
            value: "Business, consulting and management",
        },
        {
            label: "Charity and voluntary work",
            value: "Charity and voluntary work",
        },
        { label: "Creative arts and design", value: "Creative arts and design" },
        { label: "Digital", value: "Digital" },
        { label: "Energy and utilities", value: "Energy and utilities" },
        {
            label: "Engineering and manufacturing",
            value: "Engineering and manufacturing",
        },
        {
            label: "Environment and agriculture",
            value: "Environment and agriculture",
        },
        { label: "Healthcare", value: "Healthcare" },
        {
            label: "Hospitality and events management",
            value: "Hospitality and events management",
        },
        { label: "Information technology", value: "Construction" },
        { label: "Law", value: "Law" },
        {
            label: "Law enforcement and security",
            value: "Law enforcement and security",
        },
        {
            label: "Leisure, sport and tourism",
            value: "Leisure, sport and tourism",
        },
        {
            label: "Marketing, advertising and PR",
            value: "Marketing, advertising and PR",
        },
        { label: "Media and internet", value: "Media and internet" },
        { label: "Property and construction", value: "Property and construction" },
        {
            label: "Public services and administration",
            value: "Public services and administration",
        },
        { label: "Recruitment and HR", value: "Recruitment and HR" },
        { label: "Retail", value: "Retail" },
        { label: "Sales", value: "Sales" },
        {
            label: "Science and pharmaceuticals",
            value: "Science and pharmaceuticals",
        },
        { label: "Social care", value: "Social care" },
        {
            label: "Teacher training and education",
            value: "Teacher training and education",
        },
        {
            label: "Transport and logistics",
            value: "Transport and logistics",
        },
         { label: "Other (please specify)", value: "Other (please specify)" },
    ];

    const employmentOptions = [
        { label: "Student", value: "Student" },
        { label: "Self employed", value: "Self employed" },
        { label: "Unemployed", value: "Unemployed" },
        { label: "Private employee", value: "Private employee" },
        { label: "Public employee", value: "Public employee" },
        { label: "Homemaker", value: "Homemaker" },
        { label: "Retired", value: "Retired" },
    ];

    const incomelevelOptions = [
        { label: "$0 - No income", value:"$0 - No income"},
        { label:"$1 - $250", value:"$1 - $250"},
        { label: "$251 - $500", value: "$251 - $500"},
        { label: "$501 - $1,500", value: "$501 - $1,500" },
        { label: "$1,501 - $2,500", value: "$1,501 - $2,500" },
        { label: "$2,501 - $5,000", value: "$2,501 - $5,000" },
        { label: "$5,001 - $7,500", value: "$5,001 - $7,500" },
        { label: "$7,501 - $10,000", value: "$7,501 - $10,000" },
        { label: "$10,001 - $12,500", value: "$10,001 - $12,500" },
        { label: "$12,501 - $15,000", value: "$12,501 - $15,000" },
        { label: "Over $15,000", value: "Over $15,000" },
    ];

    const changePeople = (e) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (e.target.value === "" || re.test(e.target.value)) {
            setFormValue("people", Number(e.target.value));
            setFormErrors((oldValues) => ({
                ...oldValues,
                people: null,
            }));
        }
    };

    const [gadgetLabelClass, setGadgetLabelClass] = useState("inactive_label");

    const [jobRoleLabelClass, setJobRoleLabelClass] = useState("inactive_label");

    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [emirateLabelClass, setEmirateLabelClass] = useState("inactive_label");


    const [regionLabelClass, setRegionLabelClass] = useState("inactive_label");

    const [langLabelClass, setLangLabelClass] = useState("inactive_label");

    const [formLoading, setFormLoading] = useState(false);

    const [departmentLabelClass, setDepartmentLabelClass] =
        useState("inactive_label");

    const [incomeLabelClass, setIncomeLabelClass] =
        useState("inactive_label");

    const [formValues, setFormValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        whatsAppChecked: true,
        dialcode_phone: "",
        country_phone: "",
        phoneNumber: "",
        country_whatsapp: "",
        dialcode_whatsapp: "",
        whatsappPhoneNumber: "",
        // year_of_birth: "",
        date_of_birth: "",
        gender: "male",
        nationality: "",
        spoken_language: ["english"],
        language: [],
        country: "",
        region:"",
        apps: [],
        hours_spend_online: "",
        tech_gadgets: "",
        highest_education_level: "",
        employment: "",
        industry:"",
        industry_other: "",
        department_other: "",
        department: "",
        people: 0,
        income: "",
        term: "Monthly",
    });

    const [industryLabelClass, setIndustryLabelClass] =
        useState("inactive_label");

    const [formErrors, setFormErrors] = useState({
        first_name: null,
        last_name: null,
        email: null,
        phone_no: null,
        whatsapp_phone_no: null,
        // year_of_birth: null,
        date_of_birth: null,
        gender: null,
        nationality: null,
        spoken_language: null,
        language: null,
        country: null,
        region:null,
        apps: null,
        hours_spend_online: null,
        tech_gadgets: null,
        highest_education_level: null,
        employment: null,
        industry: null,
        industry_other: null,
        department_other: null,
        department: null,
        people: null,
        income: null,
        term: null,
        error_class: "input_error",
    });

    const [employmentLabelClass, setEmploymentLabelClass] =
        useState("inactive_label");

    const [isLoading, setLoading] = useState(false);

    const [username, setUsername] = useState("");

    const [accountLoading, setAccountLoading] = useState(false);

    const [successMsg, setSuccessMessage] = useState(
        ReactSession.get("successVerifiedMessage")
    );

    const [errorMsg, setErrorMessage] = useState(null);

    const [educationLabelClass, setEducationLabelClass] =
        useState("inactive_label");

    const [hoursLabelClass, setHoursLabelClass] = useState("inactive_label");

    const [confirm, setConfirm] = useState({ open: false });

    const [changeEmailModal, setChangeEmailModal] = useState({ open: false });

    const languageChange = (items) => {
        if (items.length > 0) {
            setLangLabelClass("active_label");
        } else {
            setLangLabelClass("inactive_label");
        }
        setFormValue("language", items);
    };

    const onPhoneNumberChange = (name) => {
        return (status, phoneNumber, country) => {
            if (name == "phone_number") {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    [name]: phoneNumber,
                    ["dialcode_phone"]: country.dialCode,
                    ["country_phone"]: country.iso2,
                }));
                setFormErrors((oldValues) => ({ ...oldValues, phone_no: null }));
            } else {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    [name]: phoneNumber,
                    ["dialcode_whatsapp"]: country.dialCode,
                    ["country_whatsapp"]: country.iso2,
                }));
            }
        };
    };

    const onFlagChange = (name) => {
        return (status, country) => {
            if (name == "phone_number") {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["dialcode_phone"]: country.dialCode,
                    ["country_phone"]: country.iso2,
                }));
            } else {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["dialcode_whatsapp"]: country.dialCode,
                    ["country_whatsapp"]: country.iso2,
                }));
            }
        };
    };

    const gadgetsOptions = [
        { label: "Desktop", value: "Desktop" },
        { label: "Smartphone", value: "Smartphone" },
        { label: "Tablet", value: "Tablet" },
    ];

    const appsOptions = [
        "Shopping",
        "Hobbies",
        "Social media",
        "News",
        "Gaming",
        "Chat",
        "Collaboration",
        "Banking & finance",
        "Health",
        "Transportation",
        "Travel",
        "Office",
        "Mail",
        "Food delivery",
        "Content creation",
        "Learning",
        "Maintenance",
        "Streaming",
        "Messaging",
        "Fitness",
    ];

    const departmentChange = (item) => {
        departmentSelect.current.selectSingleItem({ value: item.value });
        //setFormValue("department",item.value);
        //setDepartmentLabelClass('active_label')

        // if (items.length > 0) {
        //     setDepartmentLabelClass("active_label");
        // } else {
        //     setDepartmentLabelClass("inactive_label");
        // }
        // setFormValue("department", items);


        if (item) {
            setFormValue("department", item.value);
            setDepartmentLabelClass("active_label");
        } else {
            setFormValue("department", null);
            setDepartmentLabelClass("inactive_label");
        }
        setTimeout(function () {
            window.animate();
        }, 1000);
    };

    const educationOptions = [
        { label: "Primary school", value: "Primary school" },
        { label: "High school", value: "High school" },
        { label: "Bachelor degree", value: "Bachelor degree" },
        { label: "Master degree", value: "Master degree" },
        { label: "Post graduate degree", value: "Post graduate degree" },
        { label: "Doctorate", value: "Doctorate" }
    ];

    const departmentOptions = [
        { label: "Finance", value: "Finance" },
        { label: "HR", value: "HR" },
        { label: "Admin", value: "Admin" },
        { label: "Design & research", value: "Design & research" },
        { label: "IT & tech", value: "IT & tech" },
        { label: "Branding & marketing", value: "Branding & marketing" },
        { label: "Sales", value: "Sales" },
        { label: "Purchase", value: "Purchase" },
        { label: "Legal", value: "Legal" },
        { label: "Other", value: "Other" },
    ];

    const addPeople = () => {
        var people = Number(formValues.people);
        if (people < 10) {
            setFormValue("people", people + 1);
        }
        setFormErrors((oldValues) => ({
            ...oldValues,
            people: null,
        }));
    };

    const minusPeople = () => {
        var people = Number(formValues.people);
        if (people > 0) {
            setFormErrors((oldValues) => ({
                ...oldValues,
                people: null,
            }));
            setFormValue("people", people - 1);
        }
    };

    const hoursChange = (item) => {
        //hoursSelect.current.selectSingleItem({ value: item.value });
        //setFormValue("hours_spend_online",item.value);
        //setHoursLabelClass('active_label')
        setFormErrors((oldValues) => ({
            ...oldValues,
            hours_spend_online: null,
        }));

        if (item) {
            setFormValue("hours_spend_online", item.value);
            setHoursLabelClass("active_label");
        } else {
            setFormValue("hours_spend_online", null);
            setHoursLabelClass("inactive_label");
        }
    };

    const gadgetChange = (item) => {
        //gadgetSelect.current.selectSingleItem({ value: item.value });
        //setFormValue("tech_gadgets",item.value);
        //setGadgetLabelClass('active_label')
        setFormErrors((oldValues) => ({
            ...oldValues,
            tech_gadgets: null,
        }));

        if (item) {
            setFormValue("tech_gadgets", item.value);
            setGadgetLabelClass("active_label");
        } else {
            setFormValue("tech_gadgets", null);
            setGadgetLabelClass("inactive_label");
        }
    };

    let user = ReactSession.get("user");

    const [natLabelClass, setNatLabelClass] = useState("inactive_label");

    const nationalityChange = (item) => {
        if (item) {
            setFormValue("nationality", item.value);
            setNatLabelClass("active_label");
        } else {
            setFormValue("nationality", null);
            setNatLabelClass("inactive_label");
        }
        //nationalitySelect.current.selectSingleItem({ value: item.value });
    };

    const countryChange = (item) => {
        //countrySelect.current.selectSingleItem({ value: item.value });
        //setFormValue("country",item.value);
        //setCountryLabelClass('active_label');

        if (item) {
            setFormValue("country", item.value);
            setCountryLabelClass("active_label");
        } else {
            setFormValue("country", null);
            setCountryLabelClass("inactive_label");

        }
        
        setFormValue("region", null);
        setEmirateLabelClass("inactive_label");
        setRegionLabelClass("inactive_label");
    };
    const emirateChange = (item) => {
        
        setFormErrors((oldValues) => ({
            ...oldValues,
            region: null,
        }));

        if (item) {
            setFormValue("region", item.value);
            setEmirateLabelClass("active_label");
        } else {
            setFormValue("region", null);
            setEmirateLabelClass("inactive_label");
        }
    };
    const regionChange = (item) => {
        
        setFormErrors((oldValues) => ({
            ...oldValues,
            region: null,
        }));

        if (item) {
            setFormValue("region", item.value);
            setRegionLabelClass("active_label");
        } else {
            setFormValue("region", null);
            setRegionLabelClass("inactive_label");
        }
    };

    const educationChange = (item) => {
        //educationSelect.current.selectSingleItem({ value: item.value });
        //setFormValue("highest_education_level",item.value);
        //setEducationLabelClass('active_label');

        setFormErrors((oldValues) => ({
            ...oldValues,
            highest_education_level: null,
        }));

        if (item) {
            setFormValue("highest_education_level", item.value);
            setEducationLabelClass("active_label");
        } else {
            setFormValue("highest_education_level", null);
            setEducationLabelClass("inactive_label");
        }
    };

    const navigate = useNavigate();

    useEffect(()=>{

        if (
            formValues.region != null &&
            formValues.region != ""
        ) {
            if(formValues.country=="Saudi Arabia"){
                if(regionSelect.current){
                    regionSelect.current.selectSingleItem({
                        value: formValues.region,
                    });
                    
                    setRegionLabelClass("active_label");
                }
            }
            if(formValues.country=="United Arab Emirates"){
                
                if(emirateSelect.current){
                    
                    emirateSelect.current.selectSingleItem({
                        value: formValues.region,
                    });
                    
                    setEmirateLabelClass("active_label");
                }
            }

        }

    },[formValues]);
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Edit Profile";

        let token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (
                    response.data.user.language != null &&
                    response.data.user.language != ""
                ) {
                    //languageSelect.current.selectSingleItem({ value: response.data.user.language });

                    setLangLabelClass("active_label");
                }
                if (
                    response.data.user.country != null &&
                    response.data.user.country != ""
                ) {
                    countrySelect.current.selectSingleItem({
                        value: response.data.user.country,
                    });

                    setCountryLabelClass("active_label");
                }
                if (
                    response.data.user.region != null &&
                    response.data.user.region != ""
                ) {
                    if(response.data.user.country=="Saudi Arabia"){
                        if(emirateSelect.current){
                            emirateSelect.current.selectSingleItem({
                                value: response.data.user.region,
                            });
                            
                            setEmirateLabelClass("active_label");
                        }
                    }
                    if(response.data.user.country=="United Arab Emirates"){
                        if(regionSelect.current){
                            regionSelect.current.selectSingleItem({
                                value: response.data.user.region,
                            });
                            
                            setRegionLabelClass("active_label");
                        }
                    }

                }
                if (
                    response.data.user.highest_education_level != null &&
                    response.data.user.highest_education_level != ""
                ) {
                    //educationSelect.current.selectSingleItem({ value: response.data.user.highest_education_level });
                    setEducationLabelClass("active_label");
                }
                var industryArray = [];

                if (
                    response.data.user.industry != null &&
                    response.data.user.industry != ""
                ) {
                    //console.log(response.data.user.industry)
                    //industryArray =  unnormalize(response.data.user.industry.split("|"))
                    industrySelect.current.selectSingleItem({
                        value: response.data.user.industry,
                    });
                    // if (response.data.user.industry instanceof Array) {
                    //     response.data.user.industry.split(",").forEach(function (item) {
                    //         industryArray.push({
                    //             label: item,
                    //             value: item,
                    //         });
                    //     });
                    // } else {
                    //     industryArray.push({
                    //         label: response.data.user.industry,
                    //         value: response.data.user.industry,
                    //     });
                    // }
                    setIndustryLabelClass("active_label");
                }

                var departmentArray = [];
                if (
                    response.data.user.department != null &&
                    response.data.user.department != ""
                ) {
                    departmentSelect.current.selectSingleItem({
                        value: response.data.user.department,
                    });
                    //departmentArray =  unnormalize(response.data.user.department.split(","))
                    setDepartmentLabelClass("active_label");
                }
                if (
                    response.data.user.employment != null &&
                    response.data.user.employment != ""
                ) {
                    employmentSelect.current.selectSingleItem({
                        value: response.data.user.employment,
                    });
                    setEmploymentLabelClass("active_label");
                }

                // if (response.data.user.country == null) {
                //   //countrySelect.current.selectSingleItem({ value: response.data.user.country });
                //   setCountryLabelClass("active_label");
                // }
                if (
                    response.data.user.hours_spend_online != null &&
                    response.data.user.hours_spend_online != ""
                ) {
                    //hoursSelect.current.selectSingleItem({ value: response.data.user.hours_spend_online });
                    setHoursLabelClass("active_label");
                }
                if (
                    response.data.user.tech_gadgets != null &&
                    response.data.user.tech_gadgets != ""
                ) {
                    //gadgetSelect.current.selectSingleItem({ value: response.data.user.tech_gadgets });
                    setGadgetLabelClass("active_label");
                }

                if (
                    response.data.user.date_of_birth != null &&
                    response.data.user.date_of_birth != ""
                ) {
                    //   yearSelect.current.selectSingleItem({
                    //     value: response.data.user.year_of_birth,
                    //   });

                    setYearLabelClass("active_label");
                }
                if (
                    response.data.user.nationality != null &&
                    response.data.user.nationality != ""
                ) {
                    nationalitySelect.current.selectSingleItem({
                        value: response.data.user.nationality,
                    });

                    setNatLabelClass("active_label");
                }

                if (
                    response.data.user.job_role != null &&
                    response.data.user.job_role != ""
                ) {
                    jobRolesSelect.current.selectSingleItem({
                        value: response.data.user.job_role,
                    });
                    setJobRoleLabelClass("active_label");
                }

                if (
                    response.data.user.income_slab != null &&
                    response.data.user.income_slab != ""
                ) {
                    var exist = false;

                    for(var i=0;i<incomelevelOptions.length;i++){
                        if(incomelevelOptions[i].value==response.data.user.income_slab){
                            exist = true;
                            break;
                        }
                    }
                    if(exist){
                        incomeSelect.current.selectSingleItem({
                            value: response.data.user.income_slab,
                        });
                        setIncomeLabelClass("active_label");
                    }
                }

                var languages = [];

                if (response.data.user.language != null && response.data.user.language != "") {
                    response.data.user.language.split(",").forEach(function (item) {
                        languages.push({
                            label: item,
                            value: item,
                        });
                    });
                }

                setFormValues({
                    first_name: response.data.user.first_name,
                    last_name: response.data.user.last_name,
                    email: response.data.user.email,
                    phone_number:
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number
                            : "",
                    whatsapp_phone_number:
                        response.data.user.whatsapp_phone_number != null
                            ? response.data.user.whatsapp_phone_number
                            : "",
                    whatsAppChecked:
                        response.data.user.whatsapp_phone_number != "" &&
                            response.data.user.whatsapp_phone_number != null
                            ? false
                            : true,
                    dialcode_phone:
                        response.data.user.dialcode_phone == null
                            ? "us"
                            : response.data.user.dialcode_phone,
                    country_phone: response.data.user.country_phone,
                    dialcode_whatsapp:
                        response.data.user.dialcode_whatsapp == null
                            ? "us"
                            : response.data.user.dialcode_whatsapp,
                    country_whatsapp: response.data.user.country_whatsapp,
                    date_of_birth: response.data.user.date_of_birth
                        ? new Date(
                            moment(response.data.user.date_of_birth).utc().local().format()
                        )
                        : "",
                    gender:
                        response.data.user.gender != null
                            ? response.data.user.gender
                            : "male",
                    nationality: response.data.user.nationality,
                    spoken_language:
                        response.data.user.spoken_language == null
                            ? []
                            : response.data.user.spoken_language.split(","),
                    language: languages,
                    country: response.data.user.country,
                    region: response.data.user.region,
                    hours_spend_online: response.data.user.hours_spend_online,
                    tech_gadgets: response.data.user.tech_gadgets,
                    apps:
                        response.data.user.apps != null
                            ? response.data.user.apps.split(",")
                            : [],
                    highest_education_level: response.data.user.highest_education_level,
                    employment: response.data.user.employment,
                    //industry: industryArray,
                    industry: response.data.user.industry,
                    industry_other: response.data.user.industry_other,
                    //department: departmentArray,
                    department: response.data.user.department,
                    department_other: response.data.user.department_other,
                    people: response.data.user.people,
                    income:response.data.user.income_slab,
                    term: response.data.user.term ? response.data.user.term : "Yearly",
                });
                window.setLabels();
            } else {

                let message = response.message;
                if (response.message == "Unauthenticated.") {
                    message = "If you are unable to see your profile, kindly logout and log in again";
                }
                var id = toast(
                    <div className="toastinner">
                        {message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    }, []);


    const set = (name) => {
        return ({ target: { value } }) => {
            if (name == "income") {
                if (value == 0) {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        [name]: value.slice(0, -1),
                    }));
                } else {
                    setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
                    setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
                }
            } else {
                setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
                setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
            }
        };
    };

    const setAppOption = (option) => {
        return (event) => {
            let apps = formValues.apps;
            if (apps.includes(option)) {
                const index = apps.indexOf(option);
                if (index > -1) {
                    apps.splice(index, 1);
                }
            } else {
                apps.push(option);
            }
            setFormValues((oldValues) => ({ ...oldValues, ["apps"]: apps }));
            setFormErrors((oldValues) => ({ ...oldValues, apps: null }));
        };
    };
    const employmentChange = (item) => {

        if (item) {
            setFormValue("employment", item.value);
            setEmploymentLabelClass("active_label");
        } else {
            setFormValue("employment", null);
            setEmploymentLabelClass("inactive_label");
        }
    };
    const industryChange = (item) => {

        // if (items.length > 0) {
        //     setIndustryLabelClass("active_label");
        // } else {
        //     setIndustryLabelClass("inactive_label");
        // }
        // setFormValue("industry", items);
        if (item) {
            setFormValue("industry", item.value);
            setIndustryLabelClass("active_label");
        } else {
            setFormValue("industry", null);
            setIndustryLabelClass("inactive_label");
        }
        setTimeout(function () {
            window.animate();
        }, 1000);
    };

    const incomeChange = (item) => {
        incomeSelect.current.selectSingleItem({ value: item.value });
        setFormValue("income", item.value);
        setFormErrors((oldValues) => ({
            ...oldValues,
            income: null,
        }));

        setIncomeLabelClass("active_label");
        setTimeout(function () {
            window.animate();
        }, 1000);
    };

    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const userToClass = (option) => {
        if (formValues.apps.includes(option)) {
            return "active";
        }
        return "";
    };
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };
    const closeModal = (reload) => {
		setOpenModal(false);
	}
    const resetFormErrors = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const validateFullForm = () => {
        var error = false;

        var form_errors = resetFormErrors();

        var numeric_exp = /^[0-9]$/;

        var float_exp = /[+-]?([0-9]*[.])?[0-9]+/;

        var regExp = /^0[0-9].*$/;

        var firsterrorid = null;

        if (formValues.first_name == "") {
            form_errors = {
                ...form_errors,
                first_name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "name" : firsterrorid;
        }
        if (formValues.last_name == "") {
            form_errors = {
                ...form_errors,
                last_name: "Required field",
                error_class: "input_error",
            };

            error = true;
            firsterrorid = firsterrorid == null ? "lastname" : firsterrorid;
        }
        if (formValues.phone_number == "") {
            form_errors = {
                ...form_errors,
                phone_no: "Required field",
                error_class: "input_error",
            };

            error = true;
            firsterrorid = firsterrorid == null ? "lastname" : firsterrorid;
        } else if (formValues.phone_number != "") {
            let valid = false;
            try {
                const phoneUtil = PhoneNumberUtil.getInstance();
                valid = phoneUtil.isValidNumber(
                    phoneUtil.parse(
                        "+" + formValues.dialcode_phone + formValues.phone_number
                    )
                );
            } catch (e) {
                valid = false;

                error = true;
                firsterrorid = firsterrorid == null ? "phone_number" : firsterrorid;
            }
            if (!valid) {
                form_errors = {
                    ...form_errors,
                    phone_no: "Phone no is not valid",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "phone_number" : firsterrorid;
            }
        }

        if (!formValues.whatsAppChecked) {
            // && formValues.whatsapp_phone_number!=''

            if (formValues.whatsapp_phone_number == "") {
                form_errors = {
                    ...form_errors,
                    whatsapp_phone_no: "Required field",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "whatsapp_phone_number" : firsterrorid;
            } else if (formValues.whatsapp_phone_number != "") {
                let valid = false;
                try {
                    const phoneUtil = PhoneNumberUtil.getInstance();
                    valid = phoneUtil.isValidNumber(
                        phoneUtil.parse(
                            "+" +
                            formValues.dialcode_whatsapp +
                            formValues.whatsapp_phone_number
                        )
                    );
                } catch (e) {
                    valid = false;

                    error = true;
                    firsterrorid = firsterrorid == null ? "whatsapp_phone_number" : firsterrorid;
                }
                if (!valid) {
                    form_errors = {
                        ...form_errors,
                        whatsapp_phone_no: "Phone no is not valid",
                        error_class: "input_error",
                    };
                    error = true;
                    firsterrorid = firsterrorid == null ? "whatsapp_phone_number" : firsterrorid;
                }
            }
        }
        if (formValues.date_of_birth == "" || formValues.date_of_birth == null) {
            form_errors = {
                ...form_errors,
                date_of_birth: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "date_of_birth" : firsterrorid;
        } else if (getAge(formValues.date_of_birth) < 18) {
            form_errors = {
                ...form_errors,
                date_of_birth: "Age must be greater than 18",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "date_of_birth" : firsterrorid;
        }
        if (formValues.gender == "" || formValues.gender == null) {
            form_errors = {
                ...form_errors,
                gender: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "gender" : firsterrorid;
        }
        if (formValues.nationality == "" || formValues.nationality == null) {
            form_errors = {
                ...form_errors,
                nationality: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "nationality" : firsterrorid;
        }
        if (formValues.spoken_language.length == 0) {
            form_errors = {
                ...form_errors,
                spoken_language: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "spoken_language" : firsterrorid;
        }
        // if (formValues.language.length == 0) {
        //   form_errors = {
        //     ...form_errors,
        //     language: "Required field",
        //     error_class: "input_error",
        //   };
        //   error = true;
        // }
        if (formValues.country == "" || formValues.country == null) {
            form_errors = {
                ...form_errors,
                country: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "country" : firsterrorid;
        }
        if (formValues.country == "United Arab Emirates" && (formValues.region == "" || formValues.region == null) ) {
            form_errors = {
                ...form_errors,
                region: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "region" : firsterrorid;
        }
        if (formValues.country == "Saudi Arabia" && (formValues.region == "" || formValues.region == null) ) {
            form_errors = {
                ...form_errors,
                region: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "region" : firsterrorid;
        }
        if (
            formValues.hours_spend_online == "" ||
            formValues.hours_spend_online == null
        ) {
            form_errors = {
                ...form_errors,
                hours_spend_online: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "hours_spend_online" : firsterrorid;
        }
        if (formValues.tech_gadgets == "" || formValues.tech_gadgets == null) {
            form_errors = {
                ...form_errors,
                tech_gadgets: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "tech_gadgets" : firsterrorid;
        }
        if (formValues.apps.length == 0) {
            form_errors = {
                ...form_errors,
                apps: "Required field",
                error_class: "input_error",
            };

            error = true;
            firsterrorid = firsterrorid == null ? "apps" : firsterrorid;
        }
        if (
            formValues.highest_education_level == "" ||
            formValues.highest_education_level == null
        ) {
            form_errors = {
                ...form_errors,
                highest_education_level: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "highest_education_level" : firsterrorid;
        }
        if (formValues.employment == "" || formValues.employment == null) {
            form_errors = {
                ...form_errors,
                employment: "Required field",
                error_class: "input_error",
            };

            error = true;
            firsterrorid = firsterrorid == null ? "employment" : firsterrorid;
        }
        if (formValues.industry == "" || formValues.industry == null) {
            form_errors = {
                ...form_errors,
                industry: "Required field",
                error_class: "input_error",
            };

            error = true;
            firsterrorid = firsterrorid == null ? "industry" : firsterrorid;
        }
        // if (
        //     formValues.industry == "Other (please specify)" &&
        //     (formValues.industry_other == "" || formValues.industry_other == null)
        // ) {
        //     form_errors = {
        //         ...form_errors,
        //         industry_other: "Required field",
        //         error_class: "input_error",
        //     };
        //     error = true;
        //     firsterrorid = firsterrorid == null ? "industry_other" : firsterrorid;
        // }
        if (formValues.department == "" || formValues.department == null) {
            form_errors = {
                ...form_errors,
                department: "Required field",
                error_class: "input_error",
            };

            error = true;
            firsterrorid = firsterrorid == null ? "department" : firsterrorid;
        }
        // if (
        //   formValues.department == "Other (please specify)" &&
        //   (formValues.department_other == "" || formValues.department_other == null)
        // ) {
        //   form_errors = {
        //     ...form_errors,
        //     department_other: "Required field",
        //     error_class: "input_error",
        //   };

        //   error = true;
        // }
        if (
            Number(formValues.people) == 0 ||
            !formValues.people.toString().match(numeric_exp)
        ) {
            form_errors = {
                ...form_errors,
                people: "Please select number of people >0.",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "people" : firsterrorid;
        }
        if (
            formValues.income == "" || formValues.income == null
        ) {
            form_errors = {
                ...form_errors,
                income: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "income" : firsterrorid;
        }
        

        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        resetFormErrors();

        if (!formLoading) {
            var error = validateFullForm();

            if (!error) {
                setFormLoading(true);

                const token = ReactSession.get("token");

                var formData = { ...formValues };

                if (formData.whatsAppChecked) {
                    formData.whatsapp_phone_number = "";
                }

                formData["language"] = normalize(formValues["language"]).join(",");
                //formData["industry"] = normalize(formValues["industry"]);
                //formData["department"] = normalize(formValues["department"]);


                //console.log(normalize(formValues["industry"]))

                updateTesterProfile(formData, token).then((response) => {
                    setFormLoading(false);

                    if (response.success) {
                        let user = ReactSession.get("user");

                        user.first_name = response.user.first_name;

                        user.last_name = response.user.last_name;

                        ReactSession.set("user", user);

                        //toast.success(response.message);

                        //setSuccessMessage(response.message);

                        setUsername(user.first_name + " " + user.last_name);

                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                    } else {
                        //setErrorMessage(response.message);

                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                    }
                });
            } else {
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="There are one or more errors on the form. Please fix to proceed ahead." />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 3000,
                    }
                );
            }
        }
        return false;
    };
    const deleteUserAccount = (event) => {
        setAccountLoading(false);

        if (!accountLoading) {
            setAccountLoading(true);

            const token = ReactSession.get("token");

            deleteAccount(token).then((response) => {
                setAccountLoading(false);

                if (response.success) {
                    ReactSession.set("user", {});

                    ReactSession.set("token", null);

                    navigate("/tester/login");
                } else {
                    //setErrorMessage(response.message);

                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            duration: 3000,
                        }
                    );
                }
            });
        }
    };

    const sendChangeEmail = (event) => {
        setAccountLoading(false);
        if (!accountLoading) {

            setAccountLoading(true);

            const token = ReactSession.get("token");

            sendChangeEmailToUser(token)
                .then(response => {
                    setAccountLoading(false);

                    if (response.success) {

                        setChangeEmailModal({ ...changeEmailModal, open: false });

                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );

                        // ReactSession.set("user",{});

                        // ReactSession.set("token",null);

                        // navigate("/researcher/login");

                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                        // setErrorMessage(response.message);
                    }
                })

        }
    }

    return (
        <Layout
            isLoading={false}
            username={username}
            skipCheck={true}
            extendedFooter={false}
        >

            <div className="page-min-height">
                <TesterProfileNavigation activeMenu={"profile"} />
                <div className="profile-info-section d-block pb-64 pt-64">
                    <div className="profile-info-left">
                        <div className="profile-info-text mt-0">
                            <h1 className="page-main-heading">Your Profile</h1>
                            <p>
                                Keep your profile details up to date for the best UserQ
                                experience.
                            </p>
                        </div>
                    </div>
                </div>

                {isLoading ? (
                    <LoadingIcon />
                ) : (
                    <>
                        <div className="profile-form-wrap">
                            <form onSubmit={onSubmit}>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>About you</h3>
                                                <p>
                                                    Your personal details won't be shared with researchers.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div
                                                            className="input form-group input-field"
                                                            style={
                                                                formValues.first_name
                                                                    ? { "--clear-scale": 1, "--clear-opacity": 1 }
                                                                    : {}
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                id="name"
                                                                className={
                                                                    formErrors.first_name != null
                                                                        ? "form-control " + formErrors.error_class
                                                                        : "form-control"
                                                                }
                                                                value={formValues.first_name}
                                                                name="first_name"
                                                                onChange={set("first_name")}
                                                            />
                                                            <label htmlFor="name">First name</label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("first_name")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.first_name != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.first_name}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div
                                                            className="input form-group input-field"
                                                            style={
                                                                formValues.last_name
                                                                    ? { "--clear-scale": 1, "--clear-opacity": 1 }
                                                                    : {}
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                id="lastname"
                                                                className={
                                                                    formErrors.last_name != null
                                                                        ? "form-control " + formErrors.error_class
                                                                        : "form-control"
                                                                }
                                                                name="last_name"
                                                                value={formValues.last_name}
                                                                onChange={set("last_name")}
                                                            />
                                                            <label htmlFor="lastname">Last name</label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("last_name")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.last_name != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.last_name}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={user.email}
                                                                readOnly
                                                                id="email"
                                                                name="email"
                                                            />
                                                            <label htmlFor="email">Your email</label>
                                                            {/* <div className="data-field-icon">
                                <img src="images/fill-check.svg" alt="icon" />
                                </div> */}
                                                            <div className="data-field-icon" onClick={() => { setChangeEmailModal({ ...changeEmailModal, open: true }); }}>
                                                                Change
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>How can we reach you?</h3>
                                                <p>
                                                    From time to time, recruiters may call and invite you to
                                                    exclusive studies.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label multi-field-wrap" id="phone_number">
                                                            <label htmlFor="phone">Your phone number</label>
                                                            {formValues.phone_number != null && (
                                                                <IntlTelInput
                                                                    containerClassName="intl-tel-input"
                                                                    onPhoneNumberChange={onPhoneNumberChange(
                                                                        "phone_number"
                                                                    )}
                                                                    defaultValue={formValues.phone_number}
                                                                    defaultCountry={formValues.country_phone}
                                                                    onSelectFlag={onFlagChange("phone_number")}
                                                                    inputClassName={
                                                                        formErrors.phone_no != null
                                                                            ? "form-control " + formErrors.error_class
                                                                            : "form-control "
                                                                    }
                                                                    format={"true"}
                                                                    preferredCountries={["ae", "sa"]}
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                    }}
                                                                />
                                                            )}
                                                            {formErrors.phone_no != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.phone_no}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="form-group custom-control custom-checkbox mb-0">
                                                            <input
                                                                type="checkbox"
                                                                checked={formValues.whatsAppChecked}
                                                                onChange={(event) =>
                                                                    toggleCheck(
                                                                        "whatsAppChecked",
                                                                        event.currentTarget.checked
                                                                    )
                                                                }
                                                                className="custom-control-input"
                                                                id="customCheck1"
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="customCheck1"
                                                            >
                                                                I use WhatsApp on this number.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!formValues.whatsAppChecked && (
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field static-label multi-field-wrap" id="whatsapp_phone_number">
                                                                <label htmlFor="phone-1">Whatsapp number</label>
                                                                <IntlTelInput
                                                                    containerClassName="intl-tel-input"
                                                                    onPhoneNumberChange={onPhoneNumberChange(
                                                                        "whatsapp_phone_number"
                                                                    )}
                                                                    onSelectFlag={onFlagChange(
                                                                        "whatsapp_phone_number"
                                                                    )}
                                                                    defaultValue={formValues.whatsapp_phone_number}
                                                                    defaultCountry={formValues.country_whatsapp}
                                                                    inputClassName={
                                                                        formErrors.whatsapp_phone_no != null
                                                                            ? "form-control " + formErrors.error_class
                                                                            : "form-control "
                                                                    }
                                                                    preferredCountries={["ae", "sa"]}
                                                                    format={"true"}
                                                                />
                                                                {formErrors.whatsapp_phone_no != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.whatsapp_phone_no}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Tell us more about you</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field year-select-field static-label mb-0  birth-date-picker">
                                                            <label
                                                                className={yearLabelClass}
                                                                htmlFor="date_of_birth"
                                                            >
                                                                Date of Birth
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.date_of_birth != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <div className="date-timepickar-wrap">
                                                                    <DatePicker
                                                                        id="date_of_birth"
                                                                        placeholderText="Date of Birth"
                                                                        selected={formValues.date_of_birth}
                                                                        onChange={dobChange}
                                                                        onSelect={dobChange}
                                                                        onYearChange={dobChange}
                                                                        onMonthChange={dobChange}
                                                                        //   dateFormat="MMMM d, yyyy"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        //   minDate={new Date("01-01-1934")}
                                                                        //     maxDate={new Date("01-01-2004")}
                                                                        minDate={new Date(new Date().setFullYear(today.getFullYear() - 90))}
                                                                        maxDate={new Date(new Date().setFullYear(today.getFullYear() - 18))}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {formErrors.date_of_birth != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.date_of_birth}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label mb-0" id="gender">
                                                            <label htmlFor="male">Gender</label>
                                                            <div
                                                                className={
                                                                    formErrors.gender != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <div className="checkboxes radio_btn_class gender_btn  d-flex">
                                                                    <label htmlFor="male">
                                                                        <input
                                                                            id="male"
                                                                            checked={
                                                                                formValues.gender == "male" ? true : false
                                                                            }
                                                                            onChange={() =>
                                                                                setFormValue("gender", "male")
                                                                            }
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="male"
                                                                            className="hidden radio_btn_input"
                                                                        />
                                                                        <span>
                                                                            <img
                                                                                className="empty-fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/Polygon-blank.svg"
                                                                                }
                                                                            />
                                                                            <img
                                                                                className="fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/pol-fill.svg"
                                                                                }
                                                                            />
                                                                        </span>
                                                                        <p>Male</p>
                                                                    </label>
                                                                    <label htmlFor="female">
                                                                        <input
                                                                            id="female"
                                                                            checked={
                                                                                formValues.gender == "female"
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={() =>
                                                                                setFormValue("gender", "female")
                                                                            }
                                                                            type="radio"
                                                                            name="gender"
                                                                            value="female"
                                                                            className="hidden radio_btn_input"
                                                                        />
                                                                        <span>
                                                                            <img
                                                                                className="empty-fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/Polygon-blank.svg"
                                                                                }
                                                                            />
                                                                            <img
                                                                                className="fill-icon"
                                                                                src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/pol-fill.svg"
                                                                                }
                                                                            />
                                                                        </span>
                                                                        <p>Female</p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {formErrors.gender != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.gender}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>What’s your nationality?</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field mb-0" id="nationality">
                                                            <label
                                                                className={natLabelClass}
                                                                htmlFor="nationality"
                                                            >
                                                                Select your nationality
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.nationality != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <Dropdown
                                                                    name="nationality"
                                                                    title="Select your nationality"
                                                                    searchable={[
                                                                        "Search for nationality",
                                                                        "No matching nationality",
                                                                    ]}
                                                                    list={locations}
                                                                    onChange={nationalityChange}
                                                                    ref={nationalitySelect}
                                                                    styles={{
                                                                        headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                        headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                    }}
                                                                />
                                                            </div>
                                                            {formErrors.nationality != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.nationality}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>What languages do you speak?</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label mb-0" id="spoken_language">
                                                            <label>Spoken Language</label>
                                                            <div className="spoken-lan-check-wrap">
                                                                <div
                                                                    className={
                                                                        formErrors.spoken_language != null
                                                                            ? formErrors.error_class
                                                                            : ""
                                                                    }
                                                                >
                                                                    <div className="checkboxes radio_btn_class gender_btn  d-flex">
                                                                        <CheckboxNormal
                                                                            id="english"
                                                                            value={"english"}
                                                                            name={"spoken_language"}
                                                                            label={"English"}
                                                                            checked={
                                                                                formValues.spoken_language.includes(
                                                                                    "english"
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(event) => {
                                                                                if (event.currentTarget.checked) {
                                                                                    formValues.spoken_language.push(
                                                                                        "english"
                                                                                    );
                                                                                    setFormValue(
                                                                                        "spoken_language",
                                                                                        formValues.spoken_language
                                                                                    );
                                                                                } else {
                                                                                    formValues.spoken_language =
                                                                                        removeItemFromArray(
                                                                                            formValues.spoken_language,
                                                                                            "english"
                                                                                        );
                                                                                    setFormValue(
                                                                                        "spoken_language",
                                                                                        formValues.spoken_language
                                                                                    );
                                                                                }
                                                                                setFormErrors((oldValues) => ({
                                                                                    ...oldValues,
                                                                                    spoken_language: null,
                                                                                }));
                                                                            }}
                                                                        />
                                                                        <CheckboxNormal
                                                                            style={{ marginLeft: "10px" }}
                                                                            id="arabic"
                                                                            name={"spoken_language"}
                                                                            label={"Arabic"}
                                                                            checked={
                                                                                formValues.spoken_language.includes(
                                                                                    "arabic"
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(event) => {
                                                                                if (event.currentTarget.checked) {
                                                                                    formValues.spoken_language.push(
                                                                                        "arabic"
                                                                                    );
                                                                                    setFormValue(
                                                                                        "spoken_language",
                                                                                        formValues.spoken_language
                                                                                    );
                                                                                } else {
                                                                                    formValues.spoken_language =
                                                                                        removeItemFromArray(
                                                                                            formValues.spoken_language,
                                                                                            "arabic"
                                                                                        );
                                                                                    setFormValue(
                                                                                        "spoken_language",
                                                                                        formValues.spoken_language
                                                                                    );
                                                                                }
                                                                                setFormErrors((oldValues) => ({
                                                                                    ...oldValues,
                                                                                    spoken_language: null,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {formErrors.spoken_language != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.spoken_language}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <SelectElement
                                                            isSearchable={true}
                                                            isMulti={true}
                                                            labelClass={langLabelClass}
                                                            value={formValues.language}
                                                            id="language"
                                                            label={"Other languages"}
                                                            placeholder={"Other languages"}
                                                            options={languages}
                                                            error={formErrors.language}
                                                            error_class={formErrors.error_class}
                                                            onChange={languageChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>What's your country of residency?</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group position-relative" id="country">
                                                        <label
                                                            className={countryLabelClass}
                                                            htmlFor="country"
                                                        >
                                                            What's your country of residency?
                                                        </label>
                                                        <div
                                                            className={
                                                                formErrors.country != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            }
                                                        >
                                                            <Dropdown
                                                                name="country"
                                                                title="What's your country of residency"
                                                                searchable={[
                                                                    "Search for country",
                                                                    "No matching country",
                                                                ]}
                                                                list={countries}
                                                                onChange={countryChange}
                                                                ref={countrySelect}
                                                                styles={{
                                                                    headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                    headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                }}
                                                            />
                                                            {formErrors.country != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.country}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {formValues.country=="United Arab Emirates" &&
                                                
                                                <div className="col-md-6">
                                                    <div className="profile-form-group position-relative" id="region">
                                                        <label
                                                            className={emirateLabelClass}
                                                            htmlFor="region"
                                                        >
                                                            What's your emirate of residency?
                                                        </label>
                                                        <div
                                                            className={
                                                                formErrors.region != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            }
                                                        >
                                                            <Dropdown
                                                                name="emirates"
                                                                title="What's your emirate of residency?"
                                                                searchable={[
                                                                    "Search for emirate",
                                                                    "No matching emirate",
                                                                ]}
                                                                list={emirates}
                                                                onChange={emirateChange}
                                                                ref={emirateSelect}
                                                                styles={{
                                                                    headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                    headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                }}
                                                            />
                                                            {formErrors.region != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.region}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {formValues.country=="Saudi Arabia" &&
                                                
                                                <div className="col-md-6">
                                                    <div className="profile-form-group position-relative" id="region">
                                                        <label
                                                            className={regionLabelClass}
                                                            htmlFor="region"
                                                        >
                                                            What's your region of residency?
                                                        </label>
                                                        <div
                                                            className={
                                                                formErrors.region != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            }
                                                        >
                                                            <Dropdown
                                                                name="province"
                                                                title="What's your region of residency?"
                                                                searchable={[
                                                                    "Search for region",
                                                                    "No matching region",
                                                                ]}
                                                                list={saregions}
                                                                onChange={regionChange}
                                                                ref={regionSelect}
                                                                styles={{
                                                                    headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                    headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                }}
                                                            />
                                                            {formErrors.region != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.region}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Digital proficiency</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group position-relative" id="hours_spend_online">
                                                        <label
                                                            className={hoursLabelClass}
                                                            htmlFor="hours_spend_online"
                                                        >
                                                            Hours you spend online daily
                                                        </label>
                                                        <div
                                                            className={
                                                                formErrors.hours_spend_online != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            }
                                                        >
                                                            <Select
                                                                isClearable
                                                                name="hours_spend_online"
                                                                title="Hours you spend online daily"
                                                                placeholder="Hours you spend online daily"
                                                                options={hoursOptions}
                                                                onChange={hoursChange}
                                                                ref={hoursSelect}
                                                                value={
                                                                    formValues.hours_spend_online
                                                                        ? {
                                                                            label: formValues.hours_spend_online,
                                                                            value: formValues.hours_spend_online,
                                                                        }
                                                                        : null
                                                                }
                                                                components={{DropdownIndicator}}
                                                            />
                                                            {formErrors.hours_spend_online != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.hours_spend_online}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label" id="tech_gadgets">
                                                            <label
                                                                className={gadgetLabelClass}
                                                                htmlFor="tech_gadgets"
                                                            >
                                                                Most used device
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.tech_gadgets != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <Select
                                                                    isClearable
                                                                    name="tech_gadgets"
                                                                    title="Most used device"
                                                                    placeholder="Most used device"
                                                                    options={gadgetsOptions}
                                                                    onChange={gadgetChange}
                                                                    ref={gadgetSelect}
                                                                    value={
                                                                        formValues.tech_gadgets
                                                                            ? {
                                                                                label: formValues.tech_gadgets,
                                                                                value: formValues.tech_gadgets,
                                                                            }
                                                                            : null
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                                {formErrors.tech_gadgets != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.tech_gadgets}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="profile-form-group mobile-appslist-outer">
                                                        <div className="input-field static-label mb-0" id="apps">
                                                            <label>
                                                                Which mobile apps do you use in your daily
                                                                routine?
                                                            </label>
                                                            <div className="mobile-apps-list-wrap">
                                                                {appsOptions.map((option, index) => (
                                                                    <span
                                                                        key={option}
                                                                        className={userToClass(option)}
                                                                        onClick={setAppOption(option)}
                                                                    >
                                                                        {option}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                            {formErrors.apps != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.apps}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Education level</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group position-relative" id="highest_education_level">
                                                        <label
                                                            className={educationLabelClass}
                                                            htmlFor="highest_education_level"
                                                        >
                                                            What’s your highest level of education?
                                                        </label>
                                                        <div
                                                            className={
                                                                formErrors.highest_education_level != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            }
                                                        >
                                                            <Select
                                                                isClearable
                                                                name="highest_education_level"
                                                                title="What’s your highest level of education?"
                                                                placeholder="What’s your highest level of education?"
                                                                options={educationOptions}
                                                                onChange={educationChange}
                                                                ref={educationSelect}
                                                                value={
                                                                    formValues.highest_education_level
                                                                        ? {
                                                                            label: formValues.highest_education_level,
                                                                            value: formValues.highest_education_level,
                                                                        }
                                                                        : null
                                                                }
                                                                components={{DropdownIndicator}}
                                                            />
                                                            {formErrors.highest_education_level != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.highest_education_level}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Tell us more about your work</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6 tester-profile-industry">
                                                    <div className="profile-form-group">

                                                        {/*<SelectElement*/}
                                                        {/*    isSearchable={true}*/}
                                                        {/*    isMulti={true}*/}
                                                        {/*    labelClass={industryLabelClass}*/}
                                                        {/*    value={formValues.industry}*/}
                                                        {/*    id="industry"*/}
                                                        {/*    label={"What industry do you work in?"}*/}
                                                        {/*    placeholder={"What industry do you work in?"}*/}
                                                        {/*    options={industryOptions}*/}
                                                        {/*    error={formErrors.industry}*/}
                                                        {/*    error_class={formErrors.error_class}*/}
                                                        {/*    onChange={industryChange}*/}
                                                        {/*/>*/}
                                                        <div className="input-field static-label" id="industry">
                                                            <label
                                                                className={industryLabelClass}
                                                                htmlFor="industry"
                                                            >
                                                                What industry do you work in?
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.industry != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >

                                                                <Dropdown
                                                                    name="industry"
                                                                    title="What industry do you work in?"
                                                                    searchable={[
                                                                        "Search for industry",
                                                                        "No matching industry",
                                                                    ]}
                                                                    list={industryOptions}
                                                                    onChange={industryChange}
                                                                    ref={industrySelect}
                                                                    styles={{
                                                                        headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                        headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                    }}
                                                                />
                                                                {formErrors.industry != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.industry}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formValues.industry == "Other (please specify)" && (
                                                    <div className="col-md-6 tester-profile-industry">
                                                        <div className="profile-form-group">
                                                            <div className="input input-field form-group">
                                                                <input
                                                                    type="text"
                                                                    id="industry_other"
                                                                    className={
                                                                        formErrors.industry_other != null
                                                                            ? "form-control " + formErrors.error_class
                                                                            : "form-control"
                                                                    }
                                                                    name="industry_other"
                                                                    value={formValues.industry_other}
                                                                    onChange={set("industry_other")}
                                                                />
                                                                <label
                                                                    className="control-label"
                                                                    htmlFor="industry_other"
                                                                >
                                                                    Enter Industry (Other)
                                                                </label>
                                                                <span
                                                                    className="clear"
                                                                    onClick={clearFormValue("industry_other")}
                                                                >
                                                                    <svg viewBox="0 0 24 24">
                                                                        <path className="line" d="M2 2L22 22" />
                                                                        <path className="long" d="M9 15L20 4" />
                                                                        <path className="arrow" d="M13 11V7" />
                                                                        <path className="arrow" d="M17 11H13" />
                                                                    </svg>
                                                                </span>
                                                                {formErrors.industry_other != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.industry_other}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        {/*<SelectElement*/}
                                                        {/*    isSearchable={true}*/}
                                                        {/*    isMulti={true}*/}
                                                        {/*    labelClass={departmentLabelClass}*/}
                                                        {/*    value={formValues.department}*/}
                                                        {/*    id="department"*/}
                                                        {/*    label={"What department do you work in?"}*/}
                                                        {/*    placeholder={"What department do you work in?"}*/}
                                                        {/*    options={departmentOptions}*/}
                                                        {/*    error={formErrors.department}*/}
                                                        {/*    error_class={formErrors.error_class}*/}
                                                        {/*    onChange={departmentChange}*/}
                                                        {/*/>*/}

                                                        <div className="input-field static-label mb-0" id="department">
                                                            <label
                                                                className={departmentLabelClass}
                                                                htmlFor="department"
                                                            >
                                                                What department do you work in?
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.department != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >


                                                                <Dropdown
                                                                    name="department"
                                                                    title="What department do you work in?"
                                                                    searchable={[
                                                                        "Search for department",
                                                                        "No matching department",
                                                                    ]}
                                                                    list={departmentOptions}
                                                                    onChange={departmentChange}
                                                                    ref={departmentSelect}
                                                                    styles={{
                                                                        headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                        headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                    }}
                                                                />
                                                                {formErrors.department != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.department}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label" id="employment">
                                                            <label
                                                                className={employmentLabelClass}
                                                                htmlFor="employment"
                                                            >
                                                                Your employment status?
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.employment != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <Dropdown
                                                                    name="employment"
                                                                    title="Your employment status?"
                                                                    searchable={[
                                                                        "Search for employment",
                                                                        "No matching employment",
                                                                    ]}
                                                                    list={employmentOptions}
                                                                    onChange={employmentChange}
                                                                    ref={employmentSelect}
                                                                    styles={{
                                                                        headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                        headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                    }}
                                                                />
                                                                {formErrors.employment != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.employment}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-form-repeat">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Household</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label">
                                                            <label>
                                                                How many people live in your household?
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.people != null
                                                                        ? "qty-row-wrap " + formErrors.error_class
                                                                        : "qty-row-wrap"
                                                                }
                                                            >
                                                                <button
                                                                    type="button"
                                                                    id="sub"
                                                                    className="sub"
                                                                    onClick={minusPeople}
                                                                >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/less-icon.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </button>
                                                                <input
                                                                    readOnly={true}
                                                                    type="text"
                                                                    id="people"
                                                                    name="people"
                                                                    value={formValues.people}
                                                                    onChange={changePeople}
                                                                    min="0"
                                                                    max="10"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    id="add"
                                                                    className="add"
                                                                    onClick={addPeople}
                                                                >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/plus-icon.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </button>
                                                                {formErrors.people != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.people}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input-field static-label mb-0" id="income">
                                                            <label
                                                                className={incomeLabelClass}
                                                                htmlFor="income"
                                                            >
                                                                What's your monthly household income (USD)?
                                                            </label>
                                                            <div
                                                                className={
                                                                    formErrors.income != null
                                                                        ? formErrors.error_class
                                                                        : ""
                                                                }
                                                                tooltip="What's your monthly household income (USD)?"
                                                            >
                                                                <Dropdown
                                                                    name="income"
                                                                    title="What's your monthly household income (USD)?"
                                                                    //   searchable={[
                                                                    //     "Search for income range",
                                                                    //     "No matching income range",
                                                                    //   ]}
                                                                    list={incomelevelOptions}
                                                                    onChange={incomeChange}
                                                                    ref={incomeSelect}
                                                                    styles={{
                                                                        headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                                        headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                                    }}
                                                                />
                                                                {formErrors.income != null && (
                                                                    <span className={formErrors.error_class}>
                                                                        {formErrors.income}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="researcher-profile-form-btn tester-profile-form-button">
                                                <div
                                                    className="delete-res-profile none-for-mobile"
                                                    onClick={() => {
                                                        setConfirm({ open: true });
                                                    }}
                                                >
                                                    <span className="delete-account-btn">
                                                        Delete Account
                                                        {accountLoading && (
                                                            <i
                                                                className="fa fa-spinner fa-spin"
                                                                aria-hidden="true"
                                                                style={{ marginLeft: "5px" }}
                                                            ></i>
                                                        )}
                                                        {!accountLoading && (
                                                            <img src="images/trash.svg" alt="trash-icon" />
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="inner-form-btn res-profle-btn-right">
                                                    <a
                                                    className="btn form-btn secondary-btn change-password-btn"
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                    }}
                                                    >
                                                        Change password
                                                    </a>
                                                    <button type="submit" className="btn form-btn">
                                                        Save profile
                                                        {formLoading && (
                                                            <i
                                                                className="fa fa-spinner fa-spin"
                                                                aria-hidden="true"
                                                                style={{ marginLeft: "5px" }}
                                                            ></i>
                                                        )}
                                                    </button>
                                                </div>
                                                <div
                                                    className="delete-res-profile none-for-desktop"
                                                    onClick={() => {
                                                        setConfirm({ open: true });
                                                    }}
                                                >
                                                    <span className="delete-account-btn">
                                                        Delete Account
                                                        {accountLoading && (
                                                            <i
                                                                className="fa fa-spinner fa-spin"
                                                                aria-hidden="true"
                                                                style={{ marginLeft: "5px" }}
                                                            ></i>
                                                        )}
                                                        {!accountLoading && (
                                                            <img src="images/trash.svg" alt="trash-icon" />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                )}
                <ChangePasswordModal
                    openModal={openModal}
                    closeModal={closeModal}
                />
                <ConfirmModal
                    openModal={confirm.open}
                    confirm_btn_title={"Confirm"}
                    confirm_title="Delete account"
                    confirm_message={
                        "Are you sure you want to delete your account? This action cannot be undone and you will not be able to recover your information. Any amount left in your wallet will automatically be transferred to your registered bank, only if you have reached the minimum payout amount. If you have not reached the minimum payout amount, you will lose the wallet balance."
                    }
                    close={() => {
                        setConfirm({ ...confirm, open: false });
                    }}
                    confirm={() => {
                        deleteUserAccount();
                    }}
                />

                <ConfirmModal
                    openModal={changeEmailModal.open}
                    confirm_btn_title={"Confirm"}
                    confirm_title="Are you sure?"
                    confirm_message="An email will be sent to current email address for you to verify."
                    close={() => {
                        setChangeEmailModal({ ...changeEmailModal, open: false });
                    }}
                    confirm={() => {
                        sendChangeEmail();
                    }}
                />
            </div>
        </Layout>
    );
}
