import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import LayoutResearcher from '../../layouts/layout_researcher.js';
import ResearcherProfileNavigation from '../../profile/researcher_profile_navigation.js';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import toast from 'react-hot-toast';
import moment from 'moment';
import { getCurrentPlanService } from '../../../services/user.js';
import { getInvoicesService } from '../../../services/invoices.js';
import UpdateBillingInfo from './update-billing-info.js';
import PaymentMethods from './payment-methods.js';
import {LoadingIcon} from "../../loader/loadingIcon";

export default function Billing() {

    const navigate = useNavigate();

    const user = ReactSession.get("user");
    if (user.permission == "Editor") {
        navigate("/dashboard");
    }

    const [isLoading, setLoading] = useState(false);

    const [invoiceLoading, setInvoiceLoading] = useState(false);

    const [invoicePage, setInvoicePage] = useState(1);

    const [hasInvoices, setHasInvoices] = useState(true);

    const [invoices, setInvoices] = useState([]);


    const [plan, setPlan] = useState({ "type": "Free" });


    const fetchInvoices = () => {
        let token = ReactSession.get("token");

        setInvoiceLoading(true);

        getInvoicesService(invoicePage, token).then(response => {

            setInvoiceLoading(false);

            if (response.success) {

                setInvoices(invoices.concat(response.invoices));
                if (response.more_records) {
                    setHasInvoices(true);
                } else {
                    setHasInvoices(false);
                }
                setInvoicePage(invoicePage + 1);

            } else {


                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });
    }
    const getCurrentPlan = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCurrentPlanService(token).then(response => {

            setLoading(false);

            if (response.success) {

                setPlan(response.plan);

            } else {


                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });
    }
    useEffect(() => {

        document.title = process.env.REACT_APP_NAME + " - Subscribe";

        fetchInvoices();

        getCurrentPlan();


    }, []);


    const renderInvoices = () => {
        var invoiceRender = [];
        invoices.forEach(function (invoice) {
            invoiceRender.push(
                <tr key={"invoice" + invoice.id}>
                    <td>{moment(invoice.date_of_invoice).format("DD-MM-YY")}</td>
                    <td>{invoice.description}</td>
                    <td>
                        <b>$ {(invoice.invoice_total / 100).toFixed(2)}</b>
                    </td>
                    <td>
                        <div className="td-flex-wrap">
                            <div className="active-tiles-wrap">
                                <span className="active">{invoice.status}</span>
                            </div>
                            <span><a href={invoice.invoice_url} target="_blank"><img src={process.env.REACT_APP_URL + "images/tb-status.svg"} alt="img" /></a></span>
                            {/* <span>
                      <a
                        href={process.env.REACT_APP_URL+"invoice/"+invoice.id}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            process.env.REACT_APP_URL + "images/tb-status.svg"
                          }
                          alt="img"
                        />
                      </a>
                    </span> */}
                        </div>
                    </td>
                </tr>
            );
        })
        return invoiceRender;
    }
    const getTimeLeft = () => {
        if (plan.term == "month") {
            var days = moment(plan.current_period_end * 1000).diff(moment(), 'days');
            if (days == 1) {
                return days + " day";
            } else {
                return days + " days";
            }
        } else {
            var month = moment(plan.current_period_end * 1000).diff(moment(), 'months');
            if (month == 1) {
                return month + " month";
            } else {
                return month + " months";
            }
        }
    }
    return (
        <LayoutResearcher
            isLoading={false}
            wrapclassName="rs-inner-profile-wrapper"
            skipCheck={false}
            extendedFooter={false}
            activeMenu={"billing"}
            p
        >
            <div className="billing-plan-page-wrapper">
                <ResearcherProfileNavigation activeMenu="billing" isLoading={false} />

                <div className="profile-info-section d-block pb-64 pt-64">
                    <div className="profile-info-left w-100">
                        <div className="profile-info-text  mt-0">
                            <h1 className="page-main-heading">Billing</h1>
                        </div>
                    </div>
                    {/*plan.type == "FREE" && (
                                <div className="selected-plan-wrapper mb-0 free-plan-select-info">
                                    <div className="selected-plan-inner-data">
                                        <div className="selected-plan-top">
                                            <div className="sel-pln-tp-left">
                                                <p>
                                                    <span>Free plan</span> Free forever
                                                </p>
                                            </div>
                                            <div className="selected-plan-bottright">
                                                <Link
                                                    to={"/researcher/billing/plan/change"}
                                                    className="btn form-btn"
                                                >
                                                    Upgrade PLAN
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )*/}
                    {/*plan.type == "PAID" && (
                                <div className="selected-plan-wrapper mb-0">
                                    <div className="selected-plan-inner-data">
                                        <div className="selected-plan-top">
                                            <div className="sel-pln-tp-left">
                                                {user.permission != "Editor" ? (
                                                    <p>
                                                        <span>
                                                            PRO PLAN - ${plan.per_seat / 100} per{" "}
                                                            {plan.term == "month" ? "month" : "year"} per
                                                            user
                                                        </span>{" "}
                                                        Billed{" "}
                                                        {plan.term == "month" ? "monthly" : "annually"}
                                                        {plan.term != "month" && (
                                                            <>(save % {plan.save_perc})</>
                                                        )}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        <span>PRO PLAN</span>For more information contact
                                                        your account admin.
                                                    </p>
                                                )}
                                            </div>
                                            <div className="sel-pln-tp-right">
                                                <span className="plan-selected-text">
                                                    {plan.term == "month" ? "Monthly" : "Yearly"} plan
                                                    selected
                                                </span>
                                            </div>
                                        </div>
                                        <div className="selected-plan-mid-wrap">
                                            <div className="selected-plan-info-repeat">
                                                <p>Next billing date</p>
                                                <h2>
                                                    {moment(plan.current_period_end * 1000).format(
                                                        "DD-MM-YY"
                                                    )}
                                                </h2>
                                            </div>
                                            <div className="selected-plan-info-repeat">
                                                <p>Active users</p>
                                                <h2>{plan.seats}</h2>
                                            </div>
                                            <div className="selected-plan-info-repeat">
                                                <p>Invoice total</p>
                                                <h2>${plan.invoice_total / 100}</h2>
                                            </div>
                                        </div>
                                        <div className="selected-plan-bottom-wrap">
                                            <div className="selected-plan-bottleft">
                                                {plan.cancel_at_period_end && (
                                                    <p>
                                                        Your plan will be cancelled at end of current
                                                        billing period
                                                    </p>
                                                )}
                                                {!plan.cancel_at_period_end && (
                                                    <p>
                                                        Pro plan renewal{" "}
                                                        <span>
                                                            {moment(plan.current_period_end * 1000).format(
                                                                "DD-MM-YY"
                                                            )}{" "}
                                                            - {getTimeLeft()} left
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                            {user.permission != "Editor" && (
                                                <div className="selected-plan-bottright">
                                                    {plan.status != "canceled" && (
                                                        <Link
                                                            to={"/researcher/billing/plan/change"}
                                                            className="btn form-btn"
                                                        >
                                                            CHANGE PLAN
                                                        </Link>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                                    )*/}
                </div>


                {isLoading && (
                    <LoadingIcon/>
                )}
                {!isLoading && (
                    <>
                        {user.permission != "Editor" && (
                            <>
                                <PaymentMethods showRadioBtns={false} />
                                <UpdateBillingInfo />

                                <div className="profile-form-repeat pb-48">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-info-text">
                                                <h3>Invoice history</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="table-wrapper">
                                                {invoices.length > 0 && (
                                                    <div className="table-responsive">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Description</th>
                                                                    <th>Total</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>{renderInvoices()}</tbody>
                                                        </table>
                                                    </div>
                                                )}
                                                {!hasInvoices && invoices.length == 0 && (
                                                    <div className="nodata-wrap">
                                                        No bills available at this moment.
                                                    </div>
                                                )}
                                                {hasInvoices && !invoiceLoading && (
                                                    <div
                                                        className="load-more-data"
                                                        onClick={fetchInvoices}
                                                    >
                                                        <span>
                                                            Load more{" "}
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/dotts.png"
                                                                }
                                                            />{" "}
                                                        </span>
                                                    </div>
                                                )}
                                                {hasInvoices && invoiceLoading && (
                                                    <div
                                                        className="load-more-data"
                                                        onClick={fetchInvoices}
                                                    >
                                                        <span>
                                                            Loading{" "}
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/dotts.png"
                                                                }
                                                            />{" "}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </LayoutResearcher>
    );
}