import { useNavigate, useParams, Link } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession.js';
import Frame from '../study/frame/frame.js';
import Progress from '../study/progress/progress.js';
import Topbar from '../study/topbar/topbar.js';
import StudyTargetModal from "../study/targetmodal/study-target-modal.js";
import React, { useEffect, useRef, useState } from 'react';
import { getTestData, saveConclusion } from "../../../services/test";
import { getTargetGroupOptions } from "../../../services/target_group.js";
import moment from "moment";
import toast from "react-hot-toast";
import { Dropdown } from 'reactjs-dropdown-component';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { getCurrentPlansService } from "../../../services/plans";
import { saveTargetGroup } from "../../../services/test";
import NewTargetGroupModal from "../target_groups/new_target_modal";
import redirectToStep from "./redirect_to_step";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { settings } from '../../../data/settings.js';
import { LoadingIcon } from "../../loader/loadingIcon";
import { roundNumber } from "../../../lib/helpers";


export default function TargetGroupTest() {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const targetGroupErrorSectionRef = useRef();
    let projectTargetGroupSelect = useRef();

    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [targetGroupStepLoading, setTargetGroupStepLoading] = useState(false);

    const [planType, setPlanType] = useState("FREE");
    const [participantsRange, setParticipantsRange] = useState({ min: 5, max: 30 });
    const [perParticipantsCreditCharge, setPerParticipantsCreditCharge] = useState(null);
    const [showActiveOption, setShowActiveOption] = useState({ isActive: false, activeOption: '' });
    const [projectTargetGroup, setProjectTargetGroup] = useState([]);
    const [projectTargetGroupArray, setProjectTargetGroupArray] = useState([]);
    const [targetGroupDetail, setTargetGroupDetail] = useState(null);
    const [targetGroupSliderMaxVal, setTargetGroupSliderMaxVal] = useState(0);
    const [targetGroupOptionApi, setTargetGroupOptionApi] = useState(null);
    const [formValues, setFormValues] = useState({ targetGroupId: '', participantsSelected: 0, totalCredits: 0 });
    const [formErrors, setFormErrors] = useState({ projectTargetGroup: null, error_class: null });

    const [test, setTest] = useState("");
    const [publishingCredits, setPublishingCredits] = useState({});

    const [openModal, setOpenModal] = useState(false);
    const [openTargetGroupModal, setOpenTargetGroupModal] = useState(false);
    const [openStudyTargetModal, setOpenStudyTargetModal] = useState(false);
    const [credits_required, setCreditsRequired] = useState(null);

    const closeStudyTargetModal = () => {
        setOpenStudyTargetModal(false);
    };
    const closeNewTargetGroupModal = () => {
        ReactSession.set("is_add_new_target_group", null);
        setOpenTargetGroupModal(false);
    };

    const handleScroll = ref => {
        window.scrollTo({
            behavior: "smooth",
            top: ref.current.offsetTop
        });
    };

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };



    function showmore(divID) {
        //document.getElementById(divID).style.height = '600px';
        document.getElementById(divID).classList.add("expendit");
    }

    function showless(divID) {
        document.getElementById(divID).classList.remove("expendit");
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            var slides = document.getElementsByClassName("target-group-data-repeat");
            for (var i = 0; i < slides.length; i++) {
                var bbheight = 0;
                var ccheight = 0;
                var bb = slides.item(i).getElementsByClassName("targer-group-left-wrap")[0];
                var cc = slides.item(i).getElementsByClassName("target-group-info-repeat")[0];
                if (bb) {
                    bbheight = bb.offsetHeight;
                }
                if (cc) {
                    ccheight = cc.offsetHeight;
                }
                //console.log('>>'+bbheight+'<><>'+ccheight);
                if (bbheight < ccheight) {
                    cc.classList.add('lessshow');
                    cc.style.height = bbheight + 'px';
                } else {
                    cc.classList.add('normalshow');
                }
            }



        }, 3000);
        return () => clearTimeout(timer);

    }, []);


    const getTargetGroupOptionsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTargetGroupOptions({}, token).then(response => {
            setLoading(false);

            if (response.success) {
                setTargetGroupOptionApi(response.options)

            } else {
                toast.error(response.message)
            }
        });
    }
    const getPlan = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCurrentPlansService(token).then((response) => {
            setLoading(false);

            if (response.success) {

                setPlanType(response.plan.type);
            }
        });
    };
    const getTestApi = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setPageLoading(false);

            if (response.success) {



                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/researcher/project/" + response.test.project_id);
                    }

                    else {
                        if (
                            response.test.is_briefing_questions == 1 &&
                            response.test.briefingQuestion.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/introduction");
                            showError("Please add briefing questions");
                        } /*else if (response.test.methodology == "Tree Test" && response.test.tasks.length == 0) {
                            navigate("/researcher/tests/" + test_id + "/tree-sort-test/");
                            showError("Please add tasks");
                        } */ else if (response.test.methodology === "Card Sorting") {

                            if (response.test.card_sorting_card.length === 0) {
                                navigate("/researcher/tests/" + test_id + "/card-sorting-test/");
                                showError("Looks like you haven't created card for this test! Please add a card to move to the next steps.");
                            } else if (response.test.card_sorting.card_sorting === 'close' && response.test.card_sorting_category.length === 0) {
                                navigate("/researcher/tests/" + test_id + "/card-sorting-test/");
                                showError("Looks like you haven't created category for this test! Please add a category to move to the next steps.");

                            }

                        }
                        else if (response.test.methodology === "Five Seconds Test") {
                            if (response.test.fiveSecondsTestTask == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Some required information for the five seconds test is missing or incomplete. Please review and try again."
                                );
                            }
                            else if (response.test.fiveSecondsTestTask.task == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added questions for this test! Please add atleast 1 question to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                        if (JSON.parse(question).is_optional == 0) {
                                            nonoptionalcount = 1
                                        }
                                    }
                                );

                                if (nonoptionalcount == 0) {
                                    navigate(
                                        "/researcher/tests/" + test_id + "/five-seconds-test/"
                                    );
                                    showError(
                                        "Atleast one question should not be optional."
                                    );
                                }

                            }
                        }
                        else if (
                            response.test.methodology == "Survey" &&
                            response.test.surveyQuestion.length === 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/survey");
                            showError("Please add survey questions");
                        }
                        else if (
                            response.test.is_debriefing_questions == 1 &&
                            response.test.debriefingQuestion.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/conclusions");
                            showError("Please add debriefing questions");
                        } else if (
                            response.test.methodology == "Tree Test"
                        ) {
                            if (response.test.tasks.length === 0) {
                                navigate("/researcher/tests/" + test_id + "/tree-sort-test");
                                showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                            } else {
                                console.log(response.test.tasks);
                                for (var i = 0; i < response.test.tasks.length; i++) {
                                    if (response.test.tasks[i].correct_answer_id == null) {
                                        navigate("/researcher/tests/" + test_id + "/tree-sort-test");
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    }
                                }

                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            if (response.test.preference_test_designs.length < 2) {
                                navigate("/researcher/tests/" + test_id + "/preference-test");
                                showError("There's some information we still need – please take a look to see if you missed anything!");
                            }
                        }
                    }
                }
                setTest(response.test);
                setCreditsRequired(response.test.credits_required);
                setPerParticipantsCreditCharge(response.per_participants_credits); // set credits
                setPublishingCredits(response.settings.publishing_credits);
                setParticipantsRange(response.test_target_group_participants_range);

                const targetGroupOption = [];
                if (response.projectTargetGroup.length > 0) {
                    response.projectTargetGroup.forEach((items) => {
                        targetGroupOption.push({ value: items.id, label: items.target_group_name });
                    });
                } else {
                    //targetGroupOption.push({ value: null, label: 'No target group created' });
                }
                setProjectTargetGroup(targetGroupOption);
                setProjectTargetGroupArray(response.projectTargetGroup);

                // redirectToStep({test_id:test_id, current_step:4,saved_step:response.test.saved_step,navigate:navigate});

                const responseTargetGroup = response.test.target_group || '';
                if (responseTargetGroup) {

                    // set data
                    if (responseTargetGroup.target_type !== "test-links") {
                        console.log('asdsa')
                        setShowActiveOption({ isActive: false, activeOption: responseTargetGroup.target_type });
                    } else {
                        setShowActiveOption({ isActive: true, activeOption: responseTargetGroup.target_type });
                    }



                    if (responseTargetGroup.target_type !== "test-links") {

                        if (responseTargetGroup.target_type === "target-group") {
                            const targetDetailData = response.projectTargetGroup.filter((targetGroup) => parseInt(targetGroup.id) === parseInt(responseTargetGroup.project_target_id))[0];
                            if (targetDetailData) {
                                setTargetGroupDetail(response.projectTargetGroup.filter((targetGroup) => parseInt(targetGroup.id) === parseInt(responseTargetGroup.project_target_id))[0]);
                                //setTargetGroupSliderMaxVal(parseInt((1000000) / 5));
                                setTargetGroupSliderMaxVal(parseInt((targetDetailData.total_testers_available) / 5));
                                //  setTargetGroupSliderMaxVal(40);
                            } else {
                                setShowActiveOption({ isActive: true, activeOption: responseTargetGroup.target_type });
                            }
                        }


                        if (responseTargetGroup.target_type === "target-group") {
                            if (responseTargetGroup.participants_selected) {
                                setFormValues({ targetGroupId: responseTargetGroup.project_target_id, participantsSelected: responseTargetGroup.participants_selected, totalCredits: responseTargetGroup.credits });
                            } else {
                                setFormValues({ targetGroupId: responseTargetGroup.project_target_id, participantsSelected: 5, totalCredits: 5 * response.per_participants_credits });
                            }
                        } else {
                            if (responseTargetGroup.participants_selected) {
                                setFormValues({ targetGroupId: '', participantsSelected: responseTargetGroup.participants_selected, totalCredits: responseTargetGroup.credits });
                            } else {
                                setFormValues({ targetGroupId: '', participantsSelected: 20, totalCredits: 20 * response.per_participants_credits });
                            }
                        }

                    } else {
                        setFormValues({ targetGroupId: '', participantsSelected: 20, totalCredits: 20 * response.per_participants_credits });
                    }


                    // setLastSaved(moment(response.test.target_group.updated_at));
                } else {
                    setShowActiveOption({ 'isActive': true, 'activeOption': 'test-links' }); // by default target-group option selected
                    //setShowActiveOption({ 'isActive': true, 'activeOption': 'target-group' }); // by default target-group option selected
                    setFormValues({ targetGroupId: '', participantsSelected: 5, totalCredits: 5 * response.per_participants_credits });
                    setLastSaved(moment(response.test.updated_at));
                }

                //projectTargetGroupChange
                if (ReactSession.get("new_target_id")) {
                    //projectTargetGroupChange({value:parseInt(ReactSession.get("new_target_id"))})
                    const targetDetailDatas = response.projectTargetGroup.filter((targetGroup) => parseInt(targetGroup.id) === parseInt(ReactSession.get("new_target_id")))[0];

                    if (targetDetailDatas) {
                        setTargetGroupDetail(targetDetailDatas);

                        const totalTester = parseInt((targetDetailDatas.total_testers_available) / 5);

                        //const totalTester = parseInt((1000000) / 5);

                        setShowActiveOption({ 'isActive': false, 'activeOption': 'target-group' });

                        //const totalTester = 40;
                        setTargetGroupSliderMaxVal(totalTester);
                        if (totalTester > 5) {
                            setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': parseInt(ReactSession.get("new_target_id")), "participantsSelected": 5, "totalCredits": response.per_participants_credits * 5 }));
                            setCreditsRequired(response.settings.publishing_credits + response.per_participants_credits * 5);
                        } else {
                            setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': parseInt(ReactSession.get("new_target_id")), "participantsSelected": 0, "totalCredits": 0 }));
                            setCreditsRequired(response.settings.publishing_credits);
                        }

                    } else {

                        setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': parseInt(ReactSession.get("new_target_id")) }));
                        setCreditsRequired(response.settings.publishing_credits);
                    }

                    ReactSession.set("new_target_id", null);
                    ReactSession.set("is_add_new_target_group", null);
                }

                setLastSaved(moment(response.test.updated_at));



            } else {

                toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Target Group";

        getTestApi();
        getPlan();

        getTargetGroupOptionsApi();

    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (test && test.status != "published") {
                saveTargetGroupData(formValues, false, showActiveOption.activeOption);
            }

        }, 30000);

        return () => {
            clearTimeout(timer);
        };
    }, [formValues, showActiveOption]);

    const handleSubmit = () => {
        console.log(showActiveOption)

        setFormErrors({ "projectTargetGroup": "", "error_class": "" });
        if (showActiveOption.activeOption === 'target-group') {
            // check user has selected any target group option or not
            if (!formValues.targetGroupId) {
                setFormErrors({ "projectTargetGroup": 'Please select target group', "error_class": "input_error" });


                setShowActiveOption({ 'isActive': false, 'activeOption': 'target-group' })

                return false;
            }

            if (targetGroupDetail && (targetGroupSliderMaxVal < 6)) {
                showError(
                    <span className={"w-100"}>
                        Unfortunately, we can't fulfil your target group requirements.
                        Please contact us at
                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                    </span>
                );

                handleScroll(targetGroupErrorSectionRef)
                return false;
            }


            if (targetGroupDetail && (formValues.participantsSelected > targetGroupSliderMaxVal)) {
                showError(
                    <span className={"w-100"}>
                        Unfortunately, we can't fulfil your target group requirements.
                        Please contact us at
                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                    </span>
                );
                handleScroll(targetGroupErrorSectionRef)
                return false;
            }

            //
        }

        saveTargetGroupData(formValues, true, showActiveOption.activeOption);
        //console.log(formValues)
        return false;
    }

    const saveTargetGroupData = (form, shouldnavigate, activeOption) => {
        if (shouldnavigate) {
            setTargetGroupStepLoading(true);
        } else {
            setLoading(true);
        }

        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test_id);
        data.append("target_type", activeOption);
        data.append("autosave", 1);
        if (shouldnavigate) {
            data.append("autosave", 0);
        }

        // console.log(showActiveOption)

        if (activeOption !== "test-links") {
            data.append("project_target_id", formValues.targetGroupId);

            if (activeOption === 'target-group' && formValues.participantsSelected > targetGroupSliderMaxVal) {
                data.append("participants_selected", 0);
                data.append("credits", 0);
            } else {
                data.append("participants_selected", formValues.participantsSelected);
                data.append("credits", formValues.totalCredits);
            }

        }



        saveTargetGroup(data, token).then((response) => {
            setTargetGroupStepLoading(false);
            setLoading(false);

            if (response.success) {
                setLastSaved(moment.now());
                if (shouldnavigate) {
                    navigate("/researcher/tests/" + test_id + "/review");
                }
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });

    }

    const projectTargetGroupChange = (item) => {
        if (item.value) {
            //projectTargetGroupSelect.current.selectSingleItem({ value: item.value });

            setShowActiveOption({ 'isActive': false, 'activeOption': 'target-group' }); // show target group option details show


            const targetDetail = projectTargetGroupArray.filter((targetGroup) => parseInt(targetGroup.id) === parseInt(item.value))[0];

            if (targetDetail) {
                setTargetGroupDetail(targetDetail);

                const totalTester = parseInt((targetDetail.total_testers_available) / 5);

                //const totalTester = parseInt((1000000) / 5);

                // const totalTester = 40;
                setTargetGroupSliderMaxVal(totalTester);
                if (totalTester > 5) {
                    setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': parseInt(item.value), "participantsSelected": 5, "totalCredits": perParticipantsCreditCharge * 5 }));
                    setCreditsRequired(publishingCredits + perParticipantsCreditCharge * 5);
                } else {
                    setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': parseInt(item.value), "participantsSelected": 0, "totalCredits": 0 }));
                    setCreditsRequired(publishingCredits);
                }

            } else {
                setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': parseInt(item.value) }));
                setCreditsRequired(publishingCredits);
            }



        }
    }

    // select participants range value
    const changeParticipants = (value) => {

        if (showActiveOption.activeOption === 'target-group' && value > targetGroupSliderMaxVal) {

        } else {
            setCreditsRequired(publishingCredits + perParticipantsCreditCharge * value);
        }

        setFormValues((oldValues) => ({ ...oldValues, "participantsSelected": value, "totalCredits": perParticipantsCreditCharge * value }));



    }

    const unlinkTargetGroup = () => {
        setShowActiveOption({ 'isActive': false, 'activeOption': 'target-group' });
        setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': '' })); // set target_group_id blank

    }

    const setActiveOptionHandler = (val) => {
        setShowActiveOption({ 'isActive': true, 'activeOption': val })
    }

    // create new target model and unlink old target value
    const createNewTargetGroup = () => {

        ReactSession.set("new_target_id", null);
        ReactSession.set("is_add_new_target_group", 'yes');
        setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': '' }));
        saveTargetGroupData(formValues, false, showActiveOption.activeOption);
        setOpenTargetGroupModal(true);
    }


    const horizontalLabelsTestersFirstThirty = {
        5: 5,
        30: 30
    }

    const horizontalLabelsTestersFirstHoundred = {
        5: 5,
        1000: 1000
    }


    return (
        <LayoutResearcherFullWidth fixed_header_target={true} isLoading={false} wrapClass={test && test.language == "ar" ? "arabic_wrapper_main" : ""} skipCheck={false} extendedFooter={false} activeMenu={""}>
            <div className='study-introduction-page-wrapper stydy-pages-wrapper stydy-target-group-wrap pb-0'>
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <Topbar stepCallback={() => { saveTargetGroupData(formValues, false, showActiveOption.activeOption); }} test={test} credits_required={credits_required} onChange={(test) => { setTest({ ...test }); }} status={test.status} lastSaved={lastSaved} path={"/researcher/tests/" + test_id + "/conclusions"} isLoading={false} pageLoading={pageLoading} title={test.test_name} />
                    </div>
                </div>
                <div className="container inner-page-container">
                    <div className="study-steps-progress-wrap">
                        {(
                            <Progress stepCallback={() => { saveTargetGroupData(formValues, false, showActiveOption.activeOption); }} test={test} completed_step={3} />
                        )}
                    </div>

                    {pageLoading &&
                    <LoadingIcon />
                    }
                    {!pageLoading &&
                    <div className='target-group-data-wrapper recruitment-page-wrapper'>

                        <div className='target-group-data-repeat recruitment-target-repeat'>
                            <div className={`targer-group-left-wrap p-0 border-0 
                            ${showActiveOption.isActive === false &&
                            showActiveOption.activeOption === 'target-group' &&
                            targetGroupDetail && (targetGroupSliderMaxVal < 6) ? '' : ''}
                            `}>
                                <div className="full target-form-wrap">
                                    {(showActiveOption.isActive === true) &&
                                    <>
                                        <div className='recruitment-boxes-wrap'>
                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <div className='bg-1 stud-tiles-repeat'>
                                                        <h3 className='flex-heading'>RECRUIT FROM USERQ PANEL <span>Credits required</span></h3>
                                                        <p>
                                                            With more than 6000+ tester in MENA you can get results from our panel in minutes!.
                                                            Choose the demographics or pick random testers from our extensive pool.
                                                        </p>

                                                        <button onClick={() => {

                                                            setShowActiveOption({ 'isActive': false, 'activeOption': 'random-testers' })
                                                            setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': '', "participantsSelected": 20, "totalCredits": perParticipantsCreditCharge * 20 }));

                                                        }} className='create-btn'>
                                                            I NEED A PANEL{" "}
                                                            <span>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL + "images/arrow-right.png"
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={"col-md-6"}>
                                                    <div className='bg-2 stud-tiles-repeat'>
                                                        <h3>SHARE THE STUDY WTH YOUR OWN PARTICIPANTS</h3>
                                                        <p>
                                                            Share the test link with your own panel.
                                                            This option doesn’t require additional credits.
                                                        </p>
                                                        <button onClick={(e) => {
                                                            //setActiveOptionHandler('test-links')
                                                            saveTargetGroupData(formValues, true, 'test-links')
                                                            //handleSubmit(e)
                                                        }} className='create-btn'>
                                                            {targetGroupStepLoading && (
                                                                <i
                                                                    className="fa fa-spinner fa-spin"
                                                                    aria-hidden="true"
                                                                    style={{ marginLeft: "5px" }}
                                                                ></i>
                                                            )}
                                                            I DON’T NEED A PANEL{" "}
                                                            <span>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL + "images/arrow-right.png"
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </span>

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<h3>Your participants</h3>*/}
                                        {/*<div className='sdudy-target-grop-selection-wrap'>*/}
                                        {/*    <div*/}
                                        {/*        className="checkboxes radio_btn_class gender_btn  d-flex language-checkbox-wrap study-target-froup-raido-wrap">*/}
                                        {/*        <label htmlFor="select-1" className="mt-0 mb-32">*/}
                                        {/*            <input type="radio" name="testerOption" id="select-1"*/}
                                        {/*                   value="target-group"*/}
                                        {/*                   checked={showActiveOption.activeOption == "target-group" ? true : false}*/}
                                        {/*                   onChange={() => setActiveOptionHandler('target-group')}*/}
                                        {/*                   className="hidden radio_btn_input"/>*/}
                                        {/*            <span>*/}
                                        {/*                    <img className="empty-fill-icon"*/}
                                        {/*                         src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"}/>*/}
                                        {/*                    <img className="fill-icon"*/}
                                        {/*                         src={process.env.REACT_APP_URL + "images/pol-fill.svg"}/>*/}
                                        {/*                </span>*/}
                                        {/*            <p className={showActiveOption.activeOption === "target-group" ? 'bold-radio-label' : ''}>Select*/}
                                        {/*                or create a target group from the UserQ panel</p>*/}
                                        {/*        </label>*/}
                                        {/*        <label htmlFor="select-2" className="mt-0 mb-32">*/}
                                        {/*            <input type="radio" name="testerOption" id="select-2"*/}
                                        {/*                   value="random-testers"*/}
                                        {/*                   checked={showActiveOption.activeOption == "random-testers" ? true : false}*/}
                                        {/*                   onChange={() => {*/}
                                        {/*                       setActiveOptionHandler('random-testers')*/}
                                        {/*                       setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': '' ,"participantsSelected":5,"totalCredits": perParticipantsCreditCharge * 5}));*/}
                                        {/*                   }*/}
                                        {/*                   }*/}
                                        {/*                   className="hidden radio_btn_input"/>*/}
                                        {/*            <span>*/}
                                        {/*                    <img className="empty-fill-icon"*/}
                                        {/*                         src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"}/>*/}
                                        {/*                    <img className="fill-icon"*/}
                                        {/*                         src={process.env.REACT_APP_URL + "images/pol-fill.svg"}/>*/}
                                        {/*                </span>*/}
                                        {/*            <p className={showActiveOption.activeOption === "random-testers" ? 'bold-radio-label' : ''}>Select*/}
                                        {/*                a number of random participants from the UserQ panel</p>*/}
                                        {/*        </label>*/}
                                        {/*        <label htmlFor="select-3" className="mt-0">*/}
                                        {/*            <input type="radio" name="testerOption" id="select-3"*/}
                                        {/*                   value="test-links"*/}
                                        {/*                   checked={showActiveOption.activeOption == "test-links" ? true : false}*/}
                                        {/*                   onChange={(e) => setActiveOptionHandler('test-links')}*/}
                                        {/*                   className="hidden radio_btn_input"/>*/}
                                        {/*            <span>*/}
                                        {/*                    <img className="empty-fill-icon"*/}
                                        {/*                         src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"}/>*/}
                                        {/*                    <img className="fill-icon"*/}
                                        {/*                         src={process.env.REACT_APP_URL + "images/pol-fill.svg"}/>*/}
                                        {/*                </span>*/}
                                        {/*            <p className={showActiveOption.activeOption === "test-links" ? 'bold-radio-label d-flex radio-ph-flex' : 'd-flex radio-ph-flex'}>Share*/}
                                        {/*                the test link with your own panel <span*/}
                                        {/*                    className='no-credit-req'>No credits required</span></p>*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </>
                                    }

                                    {showActiveOption.isActive === false &&
                                    <div>
                                        <div className='recurt-userq-panal-head'>
                                            <h3>Recruit from UserQ panel</h3>
                                            <p>
                                                {targetGroupStepLoading && (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        aria-hidden="true"
                                                        style={{ marginLeft: "5px" }}
                                                    ></i>
                                                )}
                                                or share only with your
                                                <button onClick={(e) => {
                                                    // setShowActiveOption({ 'isActive': false, 'activeOption': 'test-links' })
                                                    //setTimeout(handleSubmit(e), 1000);
                                                    saveTargetGroupData(formValues, true, 'test-links')

                                                }} className='create-btn'>

                                                    own participants

                                                </button>
                                            </p>
                                        </div>


                                        <div className='recurt-checkboxes-inner-data'>
                                            <h3>Who do you want to target with this study?</h3>

                                            <div className='sdudy-target-grop-selection-wrap recurt-selection-data-wrap'>

                                                <div
                                                    className="checkboxes radio_btn_class w-100 gender_btn  d-flex language-checkbox-wrap study-target-froup-raido-wrap">
                                                    <div className='recurt-radio-wrap-repeat'>
                                                        <label htmlFor="select-2" className="mt-0 mb-32 radio-label">
                                                            <input type="radio" name="testerOption" id="select-2"
                                                                   value="random-testers"
                                                                   checked={showActiveOption.activeOption == "random-testers" ? true : false}
                                                                   onChange={() => {
                                                                       setShowActiveOption({ 'isActive': false, 'activeOption': 'random-testers' })
                                                                       //setActiveOptionHandler('random-testers')
                                                                       setFormValues((oldValues) => ({ ...oldValues, 'targetGroupId': '', "participantsSelected": 20, "totalCredits": perParticipantsCreditCharge * 20 }));
                                                                   }
                                                                   }
                                                                   className="hidden radio_btn_input" />
                                                            <span>
                                                                        <img className="empty-fill-icon"
                                                                             src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"} />
                                                                        <img className="fill-icon"
                                                                             src={process.env.REACT_APP_URL + "images/pol-fill.svg"} />
                                                                    </span>
                                                            <p className={showActiveOption.activeOption === "random-testers" ? 'bold-radio-label' : ''}>
                                                                Random participants - no specific demographics needed
                                                            </p>


                                                        </label>

                                                        {showActiveOption.activeOption == "random-testers" &&
                                                        <div className='radio-right-side-data'>

                                                            <div className={"recurt-qty-wrap"}>
                                                                <p>Selected participants
                                                                    <span tooltip="You can select up to 1000 participants">
                                                                        <img src={process.env.REACT_APP_URL + "images/info-icon.svg"} />
                                                                    </span>
                                                                </p>
                                                                <div className='qty-input-wrap'>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        changeParticipants((formValues.participantsSelected > 1) ? (formValues.participantsSelected - 1) : 1)
                                                                                    }}
                                                                                >-</span>
                                                                    <input type="text"
                                                                           value={(formValues.participantsSelected) ? formValues.participantsSelected : ''}
                                                                           onChange={(e) => {
                                                                               if (!isNaN(+e.target.value) && e.target.value !== 0 && e.target.value !== 'Na') {
                                                                                   if(e.target.value<=1000){
                                                                                       changeParticipants(parseInt(e.target.value));
                                                                                   }

                                                                               }
                                                                           }
                                                                           }
                                                                           onBlur={(e) => {
                                                                               if (!formValues.participantsSelected) {
                                                                                   changeParticipants(20);
                                                                               }
                                                                           }}
                                                                           style={{ width: '50px' }}
                                                                           className="input-participants-increase" />
                                                                    <span className={`${(formValues.participantsSelected === 1000)?'disable-inc':''}`} onClick={() => {
                                                                        changeParticipants((formValues.participantsSelected) ? ((formValues.participantsSelected<=999)?(formValues.participantsSelected + 1):1000) : 20)
                                                                    }}>+</span>
                                                                </div>
                                                            </div>


                                                            <div
                                                                className='recut-credit-wrap'>
                                                                <div className='pt-text-repeat'>
                                                                    <p>Total cost</p>
                                                                    <div className='recut-credit-count'>
                                                                        <b>{roundNumber(formValues.totalCredits, 2)}$</b>  {" "}
                                                                        {roundNumber(formValues.totalCredits, 2)} Credits - $1 per credit
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        }
                                                    </div>

                                                    <div className='recurt-radio-wrap-repeat'>
                                                        <label htmlFor="select-1" className="mt-0 mb-32  radio-label">
                                                            <input type="radio" name="testerOption" id="select-1"
                                                                   value="target-group"
                                                                   checked={showActiveOption.activeOption == "target-group" ? true : false}
                                                                   onChange={() => {
                                                                       setShowActiveOption({ 'isActive': false, 'activeOption': 'target-group' })
                                                                       //setActiveOptionHandler('target-group')
                                                                   }}
                                                                   className="hidden radio_btn_input" />
                                                            <span>
                                                                        <img className="empty-fill-icon"
                                                                             src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"} />
                                                                        <img className="fill-icon"
                                                                             src={process.env.REACT_APP_URL + "images/pol-fill.svg"} />
                                                                    </span>
                                                            <p className={showActiveOption.activeOption === "target-group" ? 'bold-radio-label' : ''}>
                                                                Specific target of participants
                                                            </p>

                                                        </label>


                                                        <div className='radio-right-side-data'>

                                                            {showActiveOption.activeOption == "target-group" && (formValues.targetGroupId === '') &&

                                                            <div className={`study-target-group-option-wrap ${formErrors.error_class}`}>

                                                                {projectTargetGroup.length>0 &&
                                                                <div className="input form-group  input-field">
                                                                    <Dropdown
                                                                        name="target_group"
                                                                        title="Select Target Group"
                                                                        list={projectTargetGroup}
                                                                        onChange={projectTargetGroupChange}
                                                                        ref={projectTargetGroupSelect}

                                                                    />
                                                                    {(formErrors.projectTargetGroup != null) &&
                                                                    <span
                                                                        className={`${formErrors.error_class} text-danger`}>{formErrors.projectTargetGroup}</span>
                                                                    }
                                                                </div>
                                                                }


                                                                <div className="new-targroup-btn">
                                                                    <button onClick={() => {
                                                                        createNewTargetGroup();
                                                                    }} type="button"
                                                                            className="button secondary-btn small-button">Create
                                                                        NEW TARGET GROUP
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            }

                                                            {showActiveOption.activeOption == "target-group" && formValues.targetGroupId && targetGroupDetail && (targetGroupSliderMaxVal >= 6) &&
                                                            <>
                                                                <div className={"recurt-qty-wrap"}>
                                                                    <p>
                                                                        Selected participants
                                                                    </p>
                                                                    <div className='qty-input-wrap'>
                                                                            <span
                                                                                onClick={() => {
                                                                                    changeParticipants((formValues.participantsSelected > 5) ? (formValues.participantsSelected - 1) : 5)
                                                                                }}
                                                                            >-</span>
                                                                        <input type="text"
                                                                               value={(formValues.participantsSelected) ? formValues.participantsSelected : ''}
                                                                               onChange={(e) => {
                                                                                   if (!isNaN(+e.target.value) && e.target.value !== 0 && e.target.value !== 'Na') {
                                                                                       changeParticipants(parseInt(e.target.value));
                                                                                   }
                                                                               }
                                                                               }
                                                                               onBlur={(e) => {
                                                                                   if (!formValues.participantsSelected) {
                                                                                       changeParticipants(5);
                                                                                   }

                                                                                   if (formValues.participantsSelected < 5) {
                                                                                       changeParticipants(5);
                                                                                   }
                                                                               }}
                                                                               style={{ width: '50px' }}
                                                                               className="input-participants-increase" />
                                                                        <span onClick={() => {
                                                                            changeParticipants((formValues.participantsSelected) ? (formValues.participantsSelected + 1) : 20)
                                                                        }}>+</span>
                                                                    </div>
                                                                </div>

                                                                {(formValues.participantsSelected <= targetGroupSliderMaxVal) &&
                                                                <div
                                                                    className='recut-credit-wrap'>
                                                                    <div className='pt-text-repeat'>
                                                                        <p>Total cost</p>
                                                                        <div className='recut-credit-count'>
                                                                            <b>{roundNumber(formValues.totalCredits, 2)}$</b> {" "}
                                                                            {roundNumber(formValues.totalCredits, 2)} Credits - $1 per credit
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </>
                                                            }
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        {showActiveOption.activeOption == "target-group" && (formValues.targetGroupId) &&
                                        <>
                                            <div className='study-tester-selected-view recut-target-view'>

                                                <div className='study-selectd-tester-left-side'>
                                                    <h3>{targetGroupDetail && targetGroupDetail.target_group_name}</h3>

                                                    {/*targetGroupDetail && (targetGroupSliderMaxVal >= 6) &&
                                                <div className='selected-tester-data-count'>
                                                    {/*<span><b>{targetGroupDetail && targetGroupDetail.total_tester}</b> testers</span>*/}
                                                    {/*<span><b>{targetGroupDetail && targetGroupDetail.total_speaker}</b> {(test.language === 'en') ? 'English' : 'Arabic'} speakers</span>*/}
                                                    {/*</div>*/}
                                                    {/*}*/}


                                                </div>

                                                <div className='study-selectd-tester-right-side'>
                                                                <span onClick={() => {
                                                                    setOpenStudyTargetModal(true);
                                                                }}><a href='#'>View details <img className="fill-icon"
                                                                                                 src={process.env.REACT_APP_URL + "images/eye.svg"} /></a></span>
                                                    <span onClick={() => unlinkTargetGroup()}><a href='#'>Unlink target group <img
                                                        className="fill-icon"
                                                        src={process.env.REACT_APP_URL + "images/link.svg"} /></a></span>
                                                </div>
                                            </div>
                                            {/*{targetGroupDetail && targetGroupDetail.total_tester < formValues.participantsSelected &&*/}
                                            {/*    <div className="limited-testers-message profile-complete-progress-wrap login-message-wrap error-bg-color">*/}
                                            {/*        <div className="pro-lft-wrap">*/}
                                            {/*            Unfortunately, we can’t fulfil your target group requirements. Please <a href='mailto://hello@userq.com'>contact us</a>.*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*}*/}

                                            {(formValues.participantsSelected > targetGroupSliderMaxVal &&
                                                showActiveOption.isActive === false && showActiveOption.activeOption === 'target-group') &&
                                            <div
                                                className="limited-testers-message profile-complete-progress-wrap login-message-wrap error-bg-color"
                                            >
                                                <div className="pro-lft-wrap">
                                                    <p>We cannot fulfil your request, as there may not be enough
                                                        participants to meet your criteria.
                                                        Reduce number of testers or contact us at <a
                                                            href="mailto:support@userq.com">support@userq.com</a> for
                                                        help.
                                                    </p>
                                                </div>
                                            </div>
                                            }

                                            {targetGroupDetail && (targetGroupSliderMaxVal < 6) &&
                                            <div
                                                className="limited-testers-message profile-complete-progress-wrap login-message-wrap error-bg-color"
                                                ref={targetGroupErrorSectionRef}>
                                                <div className="pro-lft-wrap">
                                                    <p>You can’t use the selected target group because it doesn’t
                                                        contain
                                                        enough participants to meet your criteria. Unlink and select
                                                        another target group or
                                                        <Link
                                                            to={'/researcher/support/contact?reason=Help with recruitment'}> reach
                                                            us</Link> for help.
                                                    </p>
                                                </div>
                                            </div>
                                            }


                                        </>
                                        }
                                    </div>
                                    }


                                </div>
                            </div>
                            <div id="repaetinfobox1" className="target-group-info-repeat repaetinfobox1">
                                {showActiveOption.isActive === true ?
                                    <>
                                        <h3>Recruitment</h3>
                                        <p>Get the best feedback on your site by choosing who you test with. Here you can choose to test with UserQ panel or share the test with your own testers. </p>
                                    </>
                                    :
                                    <>
                                        <h3>Participants to target</h3>
                                        <p>Select or create a target group with your specific requirements in terms of demographics and then choose how many participants you need to recruit from our panel. Choose random participants if you don’t have specif requirements.</p>
                                    </>
                                }
                                <div
                                    className="seemorebox-sidebar"
                                    onClick={() => {
                                        showmore("repaetinfobox1");
                                    }}
                                >
                                    See more
                                </div>
                                <div
                                    className="seelessbox-sidebar"
                                    onClick={() => {
                                        showless("repaetinfobox1");
                                    }}
                                >
                                    See less
                                </div>
                            </div>
                        </div>

                        <div className='target-group-data-repeat study-target-repeat border-0 pb-0 pt-0'>
                            <div className={`targer-group-left-wrap 
                            ${showActiveOption.isActive === false &&
                            showActiveOption.activeOption === 'target-group' &&
                            targetGroupDetail && (targetGroupSliderMaxVal < 6) ? 'border-0' : ''}`}>
                                <div className='select-tg-group-wrp pb-0 border-0'>
                                    <div className="target-form-wrap">
                                        {(showActiveOption.isActive === true) && (showActiveOption.activeOption === 'target-group') ? <>
                                            {/*<h3 className="select-target-group-option-head">Select target group </h3>*/}
                                            {/*<div className={`study-target-group-option-wrap ${formErrors.error_class}`}>*/}

                                            {/*    <div className="input form-group  input-field">*/}
                                            {/*        <Dropdown*/}
                                            {/*            name="target_group"*/}
                                            {/*            title="Select Target Group"*/}
                                            {/*            list={projectTargetGroup}*/}
                                            {/*            onChange={projectTargetGroupChange}*/}
                                            {/*            ref={projectTargetGroupSelect}*/}

                                            {/*        />*/}
                                            {/*        {(formErrors.projectTargetGroup != null) &&*/}
                                            {/*        <span*/}
                                            {/*            className={`${formErrors.error_class} text-danger`}>{formErrors.projectTargetGroup}</span>*/}
                                            {/*        }*/}
                                            {/*    </div>*/}
                                            {/*    <span>or</span>*/}


                                            {/*    <div className="new-targroup-btn">*/}
                                            {/*        <button onClick={() => {*/}
                                            {/*            createNewTargetGroup();*/}
                                            {/*        }} type="button"*/}
                                            {/*                className="button secondary-btn small-button">Create*/}
                                            {/*            NEW*/}
                                            {/*        </button>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </> : ''
                                        }

                                        {(showActiveOption.isActive === true) && (showActiveOption.activeOption === 'random-testers') &&
                                        <>
                                            {/*<div className='tg-pratic-top'>*/}
                                            {/*    <h3>How many participants do you need?</h3>*/}
                                            {/*    {planType !== "PAID" &&*/}
                                            {/*    <span className='free-pln-text-guide'><div><Link*/}
                                            {/*        className={"updrate-plan-link"}*/}
                                            {/*        to={'/researcher/billing/plan/change'}>Upgrade to PRO PLAN</Link> to add more than 30 participants</div>  <img*/}
                                            {/*        src={process.env.REACT_APP_URL + "images/lock-icon.svg"} alt="img"/></span>*/}
                                            {/*    }*/}
                                            {/*</div>*/}
                                            {/*<div className="target-group-col-data">*/}
                                            {/*    /!*<input type="range" value={formValues.participantsSelected} min={participantsRange.min} max={participantsRange.max} step={1} onChange={(e) => changeParticipants(e)} />*!/*/}
                                            {/*    /!*<div className="range-data-info-count">*!/*/}
                                            {/*    /!*    <span><b>{participantsRange.min}</b></span>*!/*/}
                                            {/*    /!*    <span><b>{participantsRange.max}</b></span>*!/*/}
                                            {/*    /!*</div>*!/*/}
                                            {/*    <Slider*/}
                                            {/*        min={participantsRange.min}*/}
                                            {/*        max={1000}*/}
                                            {/*        step={1}*/}
                                            {/*        value={formValues.participantsSelected}*/}
                                            {/*        labels={(participantsRange.max === 30) ? horizontalLabelsTestersFirstThirty : horizontalLabelsTestersFirstHoundred}*/}
                                            {/*        tooltip={false}*/}
                                            {/*        // format={value => value + ' testers'}*/}
                                            {/*        handleLabel={formValues.participantsSelected}*/}
                                            {/*        onChange={(value) => {*/}
                                            {/*            changeParticipants(value)*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </>
                                        }
                                        {(showActiveOption.isActive === false) && (showActiveOption.activeOption === 'target-group') &&
                                        <>
                                            <div className="target-group-col-data">

                                                {/*{targetGroupDetail && (targetGroupSliderMaxVal >= 6) &&*/}
                                                {/*<div className='target-paid-free-plan-head-wrap'>*/}
                                                {/*    <h3>How many participants would you like from this target group?</h3>*/}
                                                {/*    {planType !== "PAID" &&*/}
                                                {/*    <span className='free-pln-text-guide'><div><Link*/}
                                                {/*        className={"updrate-plan-link"}*/}
                                                {/*        to={'/researcher/billing/plan/change'}>Upgrade to PRO PLAN</Link> to add more than 30 participants </div>  <img*/}
                                                {/*        src={process.env.REACT_APP_URL + "images/lock-icon.svg"}*/}
                                                {/*        alt="img"/></span>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                {/*}*/}
                                                {/*<div className='study-target-aud-progress'>
                                                            <span className='inner-progress' style={{width: "10%"}}></span>
                                                        </div>*/}
                                                {/*<input type="range" value={formValues.participantsSelected} min={participantsRange.min} max={participantsRange.max} step={1} onChange={(e) => changeParticipants(e)} />*/}
                                                {/*<div className="range-data-info-count">*/}
                                                {/*    <span><b>{participantsRange.min}</b></span>*/}
                                                {/*    <span><b>{participantsRange.max}</b></span>*/}
                                                {/*</div>*/}


                                                {targetGroupDetail && (targetGroupSliderMaxVal >= 6) &&

                                                <>
                                                    {/*<Slider*/}
                                                    {/*    min={participantsRange.min}*/}
                                                    {/*    max={1000}*/}
                                                    {/*    //max={targetGroupSliderMaxVal}*/}
                                                    {/*    step={1}*/}
                                                    {/*    value={formValues.participantsSelected}*/}
                                                    {/*    tooltip={false}*/}
                                                    {/*    // format={value => value + ' testers'}*/}
                                                    {/*    handleLabel={formValues.participantsSelected}*/}
                                                    {/*    onChange={(value) => {*/}
                                                    {/*        changeParticipants(value);*/}
                                                    {/*    }}*/}
                                                    {/*/>*/}

                                                    {/*<div className="range-data-info-count">*/}
                                                    {/*    <span>{participantsRange.min}</span>*/}
                                                    {/*    <span>1000</span>*/}
                                                    {/*    /!*<span>{targetGroupSliderMaxVal}</span>*!/*/}
                                                    {/*</div>*/}
                                                </>

                                                }

                                            </div>
                                        </>
                                        }
                                        {/*{((showActiveOption.isActive === true && showActiveOption.activeOption === 'random-testers') ||*/}
                                        {/*    (showActiveOption.isActive === false && showActiveOption.activeOption === 'target-group' &&*/}
                                        {/*        targetGroupDetail && (targetGroupSliderMaxVal >= 6)*/}
                                        {/*    )) &&*/}
                                        {/*<>*/}
                                        {/*    {(formValues.participantsSelected > targetGroupSliderMaxVal &&*/}
                                        {/*        showActiveOption.isActive === false && showActiveOption.activeOption === 'target-group') ?*/}
                                        {/*        <div*/}
                                        {/*            className="limited-testers-message profile-complete-progress-wrap login-message-wrap error-bg-color"*/}
                                        {/*        >*/}
                                        {/*            <div className="pro-lft-wrap">*/}
                                        {/*                <p>We cannot fulfil your request, as there may not be enough participants to meet your criteria.*/}
                                        {/*                    Reduce number of testers or contact us at <a*/}
                                        {/*                        href="mailto:support@userq.com">support@userq.com</a> for help.*/}
                                        {/*                </p>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}

                                        {/*        :*/}
                                        {/*        <div className='target-group-pt-selected target-group-credits'>*/}
                                        {/*            <div className='pt-text-repeat'>*/}
                                        {/*                <p>Participants selected</p>*/}
                                        {/*                <h2 className='h2'>{formValues.participantsSelected} </h2>*/}
                                        {/*            </div>*/}
                                        {/*            <div className='pt-text-repeat'>*/}
                                        {/*                <p>Total cost</p>*/}
                                        {/*                <h2 className='h2'>{roundNumber(formValues.totalCredits,2)} Credits</h2>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    }*/}
                                        {/*</>*/}
                                        {/*}*/}
                                    </div>
                                </div>
                                {showActiveOption.isActive === false &&
                                <div className='button-wrap d-flex justify-content-end mt-64'
                                     id="r_create_step_4_target group_next_btn">
                                    <button type="submit" onClick={handleSubmit}
                                            id={`${(showActiveOption.activeOption === 'target-group') ?
                                                'r_create test_target group' :
                                                (showActiveOption.activeOption === 'random-testers') ?
                                                    'r_create test_random participants'
                                                    : 'r_create test_shared link'
                                                }`}
                                            className={`button primary-btn
                                        ${showActiveOption.isActive === false &&
                                            showActiveOption.activeOption === 'target-group' &&
                                            targetGroupDetail && formValues.targetGroupId &&
                                            ((targetGroupSliderMaxVal < 6) || formValues.participantsSelected > targetGroupSliderMaxVal) ? 'disabled-button' : ''}
                                        `}>
                                        Next
                                        {targetGroupStepLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                aria-hidden="true"
                                                style={{ marginLeft: "5px" }}
                                            ></i>
                                        )}
                                    </button>
                                </div>
                                }
                            </div>
                            <div id="repaetinfobox2" className="target-group-info-repeat repaetinfobox2 recuit-bottom-info-text-wrap">
                                {showActiveOption.isActive === false &&
                                <>
                                    <h3>Need help defining<br/>your target group?</h3>
                                    <p>Tell us who the ideal candidates for your tests are, and we’ll create a personalised panel that ticks all your boxes.</p>
                                    <Link to={'/researcher/support/contact?reason=Help with recruitment'} className='create-btn'>
                                        SEND REQUEST{" "}
                                        <span>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/arrow-right.png"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                    </Link>


                                    <div
                                        className="seemorebox-sidebar"
                                        onClick={() => {
                                            showmore("repaetinfobox2");
                                        }}
                                    >
                                        See more
                                    </div>
                                    <div
                                        className="seelessbox-sidebar"
                                        onClick={() => {
                                            showless("repaetinfobox2");
                                        }}
                                    >
                                        See less
                                    </div>

                                </>
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <StudyTargetModal
                target_group={targetGroupDetail && targetGroupDetail}
                target_group_option_api={targetGroupOptionApi && targetGroupOptionApi}
                openModal={openStudyTargetModal}
                closeModal={closeStudyTargetModal} />
            <NewTargetGroupModal
                project_id={test.project_id}
                openModal={openTargetGroupModal}
                closeModal={closeNewTargetGroupModal}
            />
        </LayoutResearcherFullWidth>
    )
}