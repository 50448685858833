import SortableTree from "react-sortable-tree";
import { useState, useEffect } from "react";
import { maxLengthCheck } from "../../../../lib/helpers";
import toast from 'react-hot-toast';
import InputField from "../../../layouts/elements/fields/input";
import { FormattedMessage } from "react-intl";
import InputSearchField from "../../../layouts/elements/fields/inputSearch";

export default function Task(props) {
	const [isTreeVisible, setTreeVisible] = useState(false);
	const [search, setSearch] = useState("");
    const [tree, setTree] = useState(props.tree);
    const [formErrors, setFormErrors] = useState(props.errors);
	//{title:null, answer:null, error_class:"input_error"}
    const [formValues, setFormValues] = useState({"correct_answer_id":props.task.correct_answer_id, "correct_answer":props.task.correct_answer, "title":props.task.title});

	const [answerPath, setAnswerPath] = useState([]);

    useEffect(()=>{

        setTimeout(function(){
            window.setLabels();
        },1000);
    },[]);
	useEffect(()=>{

        setTree(props.tree);
		setAnswerPath([]);
		navigateTree(props.tree, "answer",[],props.task.correct_answer_id);

    },[props.tree]);

	
	useEffect(()=>{

        setFormValues({"correct_answer_id":props.task.correct_answer_id, "correct_answer":props.task.correct_answer, "title":props.task.title});
		navigateTree(props.tree, "answer",[],props.task.correct_answer_id)
    },[props.task]);

	useEffect(()=>{

        setFormErrors(props.errors);
		
    },[props.errors]);
	const updateCorrectAnswer = (id, title) => {
		return (e) => {
			e.preventDefault();

			if(!title){
				
				setFormErrors({...formErrors,answer_error:'Required',answer:id});
				setTree(tree)
				
			} else {
				setFormValue("correct_answer_id",id);

            	setFormValue("correct_answer",title);
				setAnswerPath([]);


				setTreeVisible(false);
				var task = props.task;
				task.correct_answer_id = id;
				task.correct_answer = title;
				
				navigateTree(tree,"answer",[],id);

				
				props.onChange(task, props.index);

				//navigateTree(tree,"answer",[]);

				/*var task = props.task;
				task.correct_answer_id = formValues.correct_answer_id;
				task.correct_answer = formValues.correct_answer;
				task.title = formValues.title;
				console.log(task)
				props.onChange(task, props.index);*/
			}
			
            
		};
	};
    const saveCorrectAnswer = () => {
        resetFormErrors();
        if(!formValues.title){
            setFormErrors({...formErrors,"title":"Required","error_class":"input_error"});
        } else if(!formValues.correct_answer_id){
			
			setFormErrors({...formErrors,answer_error:'Required'});

			toast((
				<div className='toastinner'>
					<FormattedMessage id="Please select a correct answer" />
					<a  onClick={()=>{toast.dismiss(); } }>&times;</a>
				</div>), {
				className: 'errortoast',
				position: 'bottom-center'
			});

		} else {
            resetFormErrors();
            setTreeVisible(false);
            var task = props.task;
            task.correct_answer_id = formValues.correct_answer_id;
            task.correct_answer = formValues.correct_answer;
            task.title = formValues.title;
            //console.log(task)
			navigateTree(tree,"answer",[]);
            props.onChange(task, props.index);
        }
	};
    const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
    const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
	};
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);
    
        for (var key in formErrors) {
          formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
      };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            console.log(value)
            setTimeout(function () {
            setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const getNodeBg = (extendedNode) => {
		if(extendedNode.path.length==2){
			return "rgba(150, 255, 252, 0.8)";
		}
		else if(extendedNode.path.length==3){
			return "rgba(150, 255, 252, 0.35)";
		}
		else if(extendedNode.path.length>=4){
			return "rgba(150, 255, 252, 0.2)";
		} else {
			return "rgba(150, 255, 252, 1)";
		}
	}
	const navigateTree = (tree,task,path,correct_answer_id=null)=>{
		tree.forEach(function(item){
			var pathL = [];
			pathL = Object.assign([],path); 
			if(task=="answer"){
				//item.id == formValues.correct_answer_id || 
				if(item.id == correct_answer_id){
					pathL.push({id:item.id, title:item.title})
					setAnswerPath(pathL);
					props.updateTaskPath(props.index,pathL);
					return;
				}
			}
			if(item.children.length>0){
				pathL.push({id:item.id, title:item.title})
				navigateTree(item.children, task,pathL,correct_answer_id);
			}
		})
	}
	const renderAnswerPath = ()=>{

		console.log(answerPath)
		var path = [];

		answerPath.forEach(function(item, index){
			
			if(index==answerPath.length-1){
				path.push((
					<span key={"item"+index} className="answer-path pink-color">{item.title}</span>
				))
			} else {
				path.push((
					<span key={"item"+index} className="answer-path">{item.title} &gt; </span>
				))
			}
			
		})
		return path;
	}
	return (
		<div className="tree-task-wrap">
			<div className="tree-top-wrap tree-task-title-wrap">
				<div className="tree-top-left">
					<h3>{props.title}</h3>
				</div>
				{props.total_tasks > 1 &&
                <div className="tree-top-right-wrap">
					<div className="remove-task-wrap">
						<span className="button secondary-btn small-button remove-task-btn" onClick={()=>{props.removeTask(props.index)} }>
                        	<img
                               src={process.env.REACT_APP_URL + "images/trash-black.svg"}
                                    />
                        Remove Task</span>
					</div>	
				</div>
				}
            </div>
			<div className="tree-sort-task">
				<InputField id={"Add Task"} rtl={(props.language=="ar") ?true:false} label={"Task description"} maxLength="300" error={formErrors.title} error_class={formErrors.error_class} onClear={()=>{
					clearFormValue('title')

					var task = props.task;
					task.title = '';
					//setFormValues({...formValues,'title':''})
					props.onChange(task, props.index);
				
				}} value={formValues.title==null?'':formValues.title} onChange={(e)=>{
					var task = props.task;
					task.title = e.target.value;
					setFormValues({...formValues,'title':e.target.value})
					props.onChange(task, props.index);
					
				}} onInput={maxLengthCheck}  />
				
			</div>
			{!isTreeVisible && (
				<div className="define-correct-answer-wrap">
					{!props.task.correct_answer_id && (
						<div
							onClick={() => {
								if(tree.length>0){
									setTreeVisible(true);
								}
							}}
						>
							<button className={`button secondary-btn small-button ${tree.length==0?"disabled-button":""} ${formErrors.answer_error ? 'error_class' : ''}`}>Define Correct Answer</button>
						</div>
					)}
					{props.task.correct_answer_id && (
						<>
							<div className={`correct-answer`}>
								<span>Correct answer</span>
								
							</div>
							<span  className={`${props.language == "ar"
																	? "cairo-font"
																	: ""
																}`}>
								{renderAnswerPath()}
							</span>
							<span
								className="edit-icon"
								onClick={() => {
									setTreeVisible(true);
								}}
							>
								<img src={process.env.REACT_APP_URL + "images/edit-icon.svg"} />
							</span>
						</>
					)}
				</div>
			)}
			{isTreeVisible && (
				<>
                <div className="correct-answer-wrap">
					{/* ${formErrors.answer_error ? 'error_class' : ''}{formErrors.answer_error != null && (
						<span className={'error_class'}>
							{formErrors.answer_error}
						</span>
					)} */}
                    <div className={`correct-answer tree-sort-search-hold`}>
						<span>Correct answer</span>
						{isTreeVisible && (
							<div className="tree-search-wrap">
								<InputSearchField
									label="Search"
									onChange={(event) => {
										setSearch(event.target.value);
									}}
									onClear={() => {
										setTimeout(function(){
											
											setSearch("");
										},1000);
									}}
									value={search}
									placeholder="Search"
									rtl={`${props.language=="ar"?'arabic_wrapper':''}`}
								/>
								{/*<div className={`tree-search-inner ${props.language=="ar"?'arabic_wrapper':''}`}>
									
									<div className={`input form-group input-field mb-0  `}>
										<input
											type="text"
											value={search}
											placeholder="Search"
											className={`${props.language=="ar"?'arabic_wrapper':''}`}
											onChange={(event) => {
												setSearch(event.target.value);
											}}
										/>
									</div>
									<span className="tree-search-icon">
										<img
											src={process.env.REACT_APP_URL + "images/search-icon.svg"}
										/>
									</span>
										</div>*/}
							</div>
						)}
                    </div>
					<div className="tree-answer-list-wrap" style={{ height: "100%" }}>
						<SortableTree
							
                            isVirtualized={false}
							canDrag={false}
							className={"userQTree"}
							searchQuery={search}
							searchMethod={({ node, searchQuery }) =>{ return searchQuery && node.title && node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ? true: false; } }
							treeData={tree}
							ignoreCollapsed={false}
							
							onChange={(tree) => {
								setTree(tree)
							}}
							generateNodeProps={(extendedNode) => ({
								className: (formErrors.answer==extendedNode.node.id) ? "error_class" : '',
								style: {
									background: getNodeBg(extendedNode),
									width: "calc(100% - "+(44*(extendedNode.path.length-1))+"px)"
								},
								title: (
									<>
									<span className={`${props.language == "ar"
																	? "cairo-font"
																	: ""
																}`} style={{fontWeight: (extendedNode.path.length > 1) ? '300':'600' }}>
										{extendedNode.node.title &&
											<>{extendedNode.node.title}</>
										}
										{!extendedNode.node.title &&
											<>{extendedNode.path.length==1 ? "Level 1" : "This is your child node level "+extendedNode.path.length}</>
										}
										
									</span>
									{extendedNode.node.id == formValues.correct_answer_id && (
										<>
											
											<button type="button" className="correct-answer">This is the answer</button>
										</>
									)}
									{extendedNode.node.children.length==0 && extendedNode.node.id != formValues.correct_answer_id && (
										<button
											type="button"
											className="select-correct-answer"
											onClick={updateCorrectAnswer(
												extendedNode.node.id,
												extendedNode.node.title
											)}
										>
											Select
										</button>
									)}
									</>
								),
								buttons: [],
							})}
						/>
					</div>
                </div>

                <div className="button-wrap d-flex justify-content-end mt-32">
					<button type="button" onClick={() => {
									setTreeVisible(false);
								}}  className="button secondary-btn">
						Close
					</button>
					{/*<button type="button" onClick={saveCorrectAnswer} className="button primary-btn">
						Save
							</button>*/}
                </div>
                
				</>
			)}
		</div>
	);
}
