import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';

export default function DebriefingPage({debriefingCallback,test}) {
    return (
        <div className="tester-page-wrapper test-question-instruction-wrap tester-question-selection-wrap">
            <div className="container inner-page-container">
                <div className="tester-screens-hold">
                    <div className="tester-form-steps-wrapper">
                        <div className="tester-form-steps-inner w-100 debriefing-question-wrap">
                            <h1 className="h1 text-center mb-32">
                                {test.language == "en" ?
                                    <>
                                        Almost there – just a <span>few<br/> more questions</span> to go!
                                    </>
                                    :
                                    <>
                                        على وشك الانتهاء- <span>بضعة اسئلة اخرى </span>وننتهي!
                                    </>
                                }
                            </h1>
                            <div className="button-wrapper d-flex justify-content-center">
                                <button type="button" onClick={()=>debriefingCallback()} className="button primary-btn ml-0">
                                    {test.language=="en"?"Next":"التالي"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
