import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import React, {useEffect, useState, useRef} from "react";
import { getBillingInfoService , updateBillingInfoService } from '../../../services/user';
import toast from 'react-hot-toast';
import { countries } from '../../../data/stripe_countries.js';
import Select from 'react-select';
import {DropdownIndicator} from "../../../lib/helpers";


export default function UpdateBillingInfo({forSection}) {
	
    ReactSession.setStoreType("localStorage");
	
    let user =  ReactSession.get("user");
    
    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [isLoading, setLoading] = useState(false);

    const [billingLoading, setBillingLoading] = useState(false);

    const [isBillingEditable, setBillingEditable] = useState(false);

    const [isExpanded, setExpanded] = useState(false);

    const [formValues, setFormValues] = useState({"name": "", "country":"","city":"","address":""});

    const [billingInfo, setBillingInfo] = useState({"name": "", "country":"","city":"","address":""});

    const [formErrors, setFormErrors] = useState({email:null,"name": null, "country":null,"city":null,"address":null,  "error_class":null});

    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({...oldValues, [name]: value }));
        setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
    };
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }
   const showFormError = (name, value) => {
      let formErrorsLocal = Object.assign({}, formErrors);
      
      for(var key in formErrors){
          formErrorsLocal[key] = null;
      }
      formErrorsLocal[name] = value;

      formErrorsLocal['error_class'] = 'input_error';

      setFormErrors(formErrorsLocal);


   }
   
    const getBillingInfo = ()=>{
        let token = ReactSession.get("token");

        setLoading(true);

        getBillingInfoService(token).then(response=> {
            setLoading(false);

            if(response.success){
                for(var key in response.billing_address){
                    if(response.billing_address[key]==null){
                        response.billing_address[key] = '';
                    }
                }
                var country = null;

                countries.forEach(function(item){
                    if(item.value == response.billing_address.country){
                        country = item;
                    }
                })
                if(response.billing_address.country==''){
                    setCountryLabelClass('inactive_label');
                  } else {
                    setCountryLabelClass('active_label');
                  }
                setBillingInfo(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                setFormValues(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));
                
                window.setLabels();
            } else {
				
                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
			}
        });
    }
	useEffect(function(){
      
        getBillingInfo();

        return () => {
            setLoading(false);   
        };
    },[]);

    const validateBillingInfoForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid=null;

        if (formValues.name=='') {
          form_errors = {
            ...form_errors,
            name: "Required field",
            error_class: "input_error",
          };
          error = true;
          firsterrorid=firsterrorid==null?"name":firsterrorid;
        }
        if (!formValues.country) {
            form_errors = {
              ...form_errors,
              country: "Required field",
              error_class: "input_error",
            };
            error = true;
            firsterrorid=firsterrorid==null?"contry":firsterrorid;
        }

        if (formValues.city=='') {
            form_errors = {
              ...form_errors,
              city: "Required field",
              error_class: "input_error",
            };
            error = true;
            firsterrorid=firsterrorid==null?"city":firsterrorid;
        }
        if (formValues.address=='') {
          form_errors = {
            ...form_errors,
            address: "Required field",
            error_class: "input_error",
          };
          error = true;
          firsterrorid=firsterrorid==null?"address":firsterrorid;
        }
        
        

        setFormErrors(form_errors);

        if(firsterrorid!=null){
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
        }

        return error;
      };
   const updateBillingInfo = () => {
      
      resetFormErrors();

      if(!billingLoading){

         var error = false;

         error = validateBillingInfoForm();
         
         if(!error){
            setBillingLoading(true);

            const token = ReactSession.get("token");

            var formData =  {};

            formData["name"] = formValues.name;

            formData["address"] = formValues.address;

            formData["country"] = formValues.country.value;

            formData["city"] = formValues.city;
            
            updateBillingInfoService(formData,token)
            .then(response=>{
               
               setBillingLoading(false);

               if(response.success){

                  for(var key in response.billing_address){
                     if(response.billing_address[key]==null){
                           response.billing_address[key] = '';
                     }
                  }
                  var country = null;

                  countries.forEach(function(item){
                     if(item.value == response.billing_address.country){
                           country = item;
                     }
                  })
                  if(response.billing_address.country==''){
                    setCountryLabelClass('inactive_label');
                  } else {
                    setCountryLabelClass('active_label');
                  }
                  
                  setBillingInfo(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                  setFormValues(oldValues => ({...oldValues, ["address"]: response.billing_address.address, ["city"]: response.billing_address.city, ["country"]: country, ["name"]: response.billing_address.name }));

                  setBillingEditable(false);

                  window.setLabels();
               } else {
                     var id = toast((
                           <div className='toastinner'>
                                 {response.message}
                                 <a  onClick={()=>{toast.dismiss(id); } }>&times;</a>
                           </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                     });
               }
            })
          }
      }
   }
   
   return (
    <>
    {forSection && forSection=="publish_test" &&
    <>
        <div className="`   `">
            <h3 className="d-flex align-items-center bill-h-dflex">Billing information
                {user.permission != "Editor" && 
                    <>
                    {!isBillingEditable &&
                    <span className="edit-icon"onClick={()=>{setBillingEditable(true);  setTimeout(function(){window.setLabels();},100); }}><img src={process.env.REACT_APP_URL+"images/edit-icon.svg"}/></span>
                    }
                    </>
                }
                <span className='expand-icon' onClick={()=>{setExpanded(!isExpanded)}}>
                    {isExpanded &&
                    <>
                    -
                    </>
                    }
                    {!isExpanded &&
                    <>
                    +
                    </>
                    }
                </span>


            </h3>
            {isExpanded &&
            <div className="row">
                <div className="col-md-12">
                    {isBillingEditable &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input form-group input-field" >
                                <input type="text" readOnly={!isBillingEditable} className={`form-control ${formErrors.name!=null ? formErrors.error_class : ""} `} value={formValues.name} onChange={set('name')} id="name"  />
                                <label htmlFor="name" className="control-label">Name/Company name</label> 
                                <span className="clear" onClick={clearFormValue('name')}>
                                    <svg viewBox="0 0 24 24">
                                        <path className="line" d="M2 2L22 22" />
                                        <path className="long" d="M9 15L20 4" />
                                        <path className="arrow" d="M13 11V7" />
                                        <path className="arrow" d="M17 11H13" />
                                    </svg>
                                </span>
                                    {(formErrors.name!=null) &&
                                    <span className={formErrors.error_class}>{formErrors.name}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-form-group ">
                                <div className="input input-field form-group read-only-field" >
                                <input type="text" className="form-control" value={user.email} readOnly id="email"/>
                                <label htmlFor="email">Email</label>
                                <div className="data-field-icon"><img src={process.env.REACT_APP_URL+"images/fill-check.svg"} alt="icon" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input-field mb-0">
                                    <div className={`${formErrors.country!=null ? formErrors.error_class : ""} `} id="contry">
                                        <label className={countryLabelClass} htmlFor="country">Country</label> 
                                        <Select
                                            isClearable
                                            id='country'
                                            isDisabled={!isBillingEditable}
                                            value={formValues.country}
                                            placeholder="Country"
                                            options={countries}
                                            onChange={(country)=>{ if(country==null){ setCountryLabelClass("inactive_label"); } else { setCountryLabelClass("active_label");  } setFormValue("country",country)}}
                                            components={{DropdownIndicator}}
                                        />
                                    </div>
                                    {(formErrors.country!=null) &&
                                    <span className={formErrors.error_class}>{formErrors.country}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input form-group input-field" >
                                <input type="text" readOnly={!isBillingEditable} className={`form-control ${formErrors.city!=null ? formErrors.error_class : ""} `} value={formValues.city} onChange={set('city')} id="city"  />
                                <label htmlFor="city" className="control-label">City</label> 
                                <span className="clear" onClick={clearFormValue('city')}>
                                    <svg viewBox="0 0 24 24">
                                        <path className="line" d="M2 2L22 22" />
                                        <path className="long" d="M9 15L20 4" />
                                        <path className="arrow" d="M13 11V7" />
                                        <path className="arrow" d="M17 11H13" />
                                    </svg>
                                </span>
                                {(formErrors.city!=null) &&
                                    <span className={formErrors.error_class}>{formErrors.city}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input form-group input-field ">
                                    <input type="text" readOnly={!isBillingEditable} id="address" className={`form-control ${formErrors.address!=null ? formErrors.error_class : ""} `} value={formValues.address}  onChange={set('address')} />
                                    <label htmlFor="address " className="control-label">Address </label> 
                                    <span className="clear">
                                        <svg viewBox="0 0 24 24" onClick={clearFormValue('address')}>
                                            <path className="line" d="M2 2L22 22" />
                                            <path className="long" d="M9 15L20 4" />
                                            <path className="arrow" d="M13 11V7" />
                                            <path className="arrow" d="M17 11H13" />
                                        </svg>
                                    </span>
                                    {(formErrors.address!=null) &&
                                    <span className={formErrors.error_class}>{formErrors.address}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="flex-buttons-wrap justify-content-end">
                            <button type='button' className="btn form-btn secondary-btn"
                                onClick={()=>{ 
                                    setFormValue("name",billingInfo.name);
                                    setFormValue("country",billingInfo.country);
                                    setFormValue("address",billingInfo.address);
                                    setFormValue("city",billingInfo.city); 
                                    resetFormErrors(); 
                                    setBillingEditable(false)}}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn form-btn" onClick={updateBillingInfo} >
                                Save
                                {billingLoading &&
                                    <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                }
                            </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isBillingEditable  &&
                    <div className="bill-inactive-wrap">
                        <div className="row">
                            <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input-field">
                                    <label>Name/Company name</label>
                                    <p>{billingInfo.name ? billingInfo.name : "-"}</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input-field inactive-text-wrp">
                                    <label>Your email</label>
                                    <p>{user.email}</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-6">
                                <div className="profile-form-group">
                                    <div className="input-field">
                                        <label>Country</label>
                                        <p>{billingInfo.country ? billingInfo.country.label: '-'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="profile-form-group">
                                    <div className="input-field">
                                        <label>City</label>
                                        <p>{billingInfo.city ? billingInfo.city : '-'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input-field mb-0">
                                    <label>Address </label>
                                    <p>{billingInfo.address ? billingInfo.address : "-"}</p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
            }
        </div>
        <div className="target-group-info-repeat">
            <h3>Billing Information</h3>
            <p>
                Your billing informations are required for invoicing pursposes.
            </p>
        </div>
    </>
    }
    {!forSection &&
    <div className="profile-form-repeat">
        <div className="row">
            
            <div className="col-md-4">
                <div className="form-info-text">
                    <h3 className="d-flex align-items-center bill-h-dflex">Billing information 
                    {user.permission != "Editor" && <>
                        {!isBillingEditable &&
                        <span className="edit-icon"onClick={()=>{setBillingEditable(true);  setTimeout(function(){window.setLabels();},100); }}><img src={process.env.REACT_APP_URL+"images/edit-icon.svg"}/></span>
                        }</>}
                    </h3>
                </div>
            </div>
            <div className="col-md-8">
                {isBillingEditable &&
                <div className="row">
                    <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input form-group input-field" >
                            <input type="text" readOnly={!isBillingEditable} className={`form-control ${formErrors.name!=null ? formErrors.error_class : ""} `} value={formValues.name} onChange={set('name')} id="name"  />
                            <label htmlFor="name" className="control-label">Name/Company name</label> 
                            <span className="clear" onClick={clearFormValue('name')}>
                                <svg viewBox="0 0 24 24">
                                    <path className="line" d="M2 2L22 22" />
                                    <path className="long" d="M9 15L20 4" />
                                    <path className="arrow" d="M13 11V7" />
                                    <path className="arrow" d="M17 11H13" />
                                </svg>
                            </span>
                                {(formErrors.name!=null) &&
                                <span className={formErrors.error_class}>{formErrors.name}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-form-group ">
                            <div className="input input-field form-group read-only-field" >
                            <input type="text" className="form-control" value={user.email} readOnly id="email"/>
                            <label htmlFor="email">Email</label>
                            <div className="data-field-icon"><img src={process.env.REACT_APP_URL+"images/fill-check.svg"} alt="icon" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input-field mb-0">
                                <div className={`${formErrors.country!=null ? formErrors.error_class : ""} `} id="contry">
                                    <label className={countryLabelClass} htmlFor="country">Country</label> 
                                    <Select
                                        isClearable
                                        id='country'
                                        isDisabled={!isBillingEditable}
                                        value={formValues.country}
                                        placeholder="Country"
                                        options={countries}
                                        onChange={(country)=>{ if(country==null){ setCountryLabelClass("inactive_label"); } else { setCountryLabelClass("active_label");  } setFormValue("country",country)}}
                                        components={{DropdownIndicator}}
                                    />
                                </div>
                                {(formErrors.country!=null) &&
                                <span className={formErrors.error_class}>{formErrors.country}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input form-group input-field" >
                            <input type="text" readOnly={!isBillingEditable} className={`form-control ${formErrors.city!=null ? formErrors.error_class : ""} `} value={formValues.city} onChange={set('city')} id="city"  />
                            <label htmlFor="city" className="control-label">City</label> 
                            <span className="clear" onClick={clearFormValue('city')}>
                                <svg viewBox="0 0 24 24">
                                    <path className="line" d="M2 2L22 22" />
                                    <path className="long" d="M9 15L20 4" />
                                    <path className="arrow" d="M13 11V7" />
                                    <path className="arrow" d="M17 11H13" />
                                </svg>
                            </span>
                            {(formErrors.city!=null) &&
                                <span className={formErrors.error_class}>{formErrors.city}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input form-group input-field ">
                                <input type="text" readOnly={!isBillingEditable} id="address" className={`form-control ${formErrors.address!=null ? formErrors.error_class : ""} `} value={formValues.address}  onChange={set('address')} />
                                <label htmlFor="address " className="control-label">Address </label> 
                                <span className="clear">
                                    <svg viewBox="0 0 24 24" onClick={clearFormValue('address')}>
                                        <path className="line" d="M2 2L22 22" />
                                        <path className="long" d="M9 15L20 4" />
                                        <path className="arrow" d="M13 11V7" />
                                        <path className="arrow" d="M17 11H13" />
                                    </svg>
                                </span>
                                {(formErrors.address!=null) &&
                                <span className={formErrors.error_class}>{formErrors.address}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="flex-buttons-wrap justify-content-end">
                        <button type='button' className="btn form-btn secondary-btn"
                            onClick={()=>{ 
                                setFormValue("name",billingInfo.name);
                                setFormValue("country",billingInfo.country);
                                setFormValue("address",billingInfo.address);
                                setFormValue("city",billingInfo.city); 
                                resetFormErrors(); 
                                setBillingEditable(false)}}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn form-btn" onClick={updateBillingInfo} >
                            Save
                            {billingLoading &&
                                <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                            }
                        </button>
                        </div>
                    </div>
                </div>
                }
                {!isBillingEditable &&
                <div className="bill-inactive-wrap">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input-field">
                                <label>Name/Company name</label>
                                <p>{billingInfo.name ? billingInfo.name : "-"}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input-field inactive-text-wrp">
                                <label>Your email</label>
                                <p>{user.email}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input-field">
                                    <label>Country</label>
                                    <p>{billingInfo.country ? billingInfo.country.label: '-'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="profile-form-group">
                                <div className="input-field">
                                    <label>City</label>
                                    <p>{billingInfo.city ? billingInfo.city : '-'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="profile-form-group">
                            <div className="input-field mb-0">
                                <label>Address </label>
                                <p>{billingInfo.address ? billingInfo.address : "-"}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                }
            </div>

        </div>
    </div>
    }
    </>
    )
}