import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//import { preventClick } from '../../../lib/helpers';
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { FormattedMessage } from 'react-intl';

export default function TesterRegistrationSuccess() {

    const navigate = useNavigate();

    ReactSession.setStoreType("localStorage");

    useEffect(() => {
        
        document.title = process.env.REACT_APP_NAME+" - Registration Success";

        
    }, []);

    
   
    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_TESTER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <h1 className="form-heading">Verify your email</h1>
                        <p className="form-sub-head">We've sent you an email to verify your account. You'll find the link in your inbox.</p>
                        <div className="login-form-inner-data">
                            
                            <form method='POST'  className="mt-4">    
                                <Link to="/tester/login" className="link linktype-2 back-to-sign-inbtn">BACK TO SIGN IN</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}