import React, { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'
import { PhoneNumberUtil } from 'google-libphonenumber';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { Dropdown } from 'reactjs-dropdown-component';
import { load } from 'recaptcha-v3'

export default function SocialSignUp() {

    ReactSession.setStoreType("localStorage");

    let jobRolesSelect = useRef();

    const navigate = useNavigate();

    const jobRoleOptions = [{"label":"UX/UI designer","value":"UX/UI designer"},{"label":"UX researcher","value":"UX researcher"},{"label":"Product owner","value":"Product owner"},{"label":"Product manager","value":"Product manager"},{"label":"Social media manager","value":"Social media manager"},{"label":"Marketing manager","value":"Marketing manager"},{"label":"Entrepreneur","value":"Entrepreneur"},{"label":"Other...","value":"Other..."}];

    const [jobRoleLabelClass, setJobRoleLabelClass] = useState("inactive_label");

    const [errorMsg, setErrorMessage] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({dialcode_phone: null, phone_number: "", country_phone:"", company_name:'', job_role:'',job_role_other:'', role:'Researcher'});

    const [formErrors, setFormErrors] = useState({phone_no: null, company_name:null, job_role:null,  error_class:null});

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    const emailAlreadySet = ReactSession.get('register_email') ? true : false;

    useEffect(() => {
        window.animate();
        document.title = process.env.REACT_APP_NAME+" - Register";
    }, []);

    const generateCaptchaLogin = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                
                callback(token);
                
            })
        })
    }
    const onPhoneNumberChange = name => {
        return (status, phoneNumber, country) => {

            if (name == "phone_number") {

                setFormValues(oldValues => ({ ...oldValues, [name]: phoneNumber, ['dialcode_phone']: country.dialCode, ['country_phone']: country.iso2 }));
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: phoneNumber, ['dialcode_whatsapp']: country.dialCode, ['country_whatsapp']: country.iso2 }));
            }
            setFormErrors((oldValues) => ({ ...oldValues, phone_no: null }));

        }
    };
    const onFlagChange = name => {
        return (status, country) => {

            if (name == "phoneNumber") {

                setFormValues(oldValues => ({ ...oldValues, ['dialcode_phone']: country.dialCode, ['country_phone']: country.iso2 }));

            } else {
                setFormValues(oldValues => ({ ...oldValues, ['dialcode_whatsapp']: country.dialCode, ['country_whatsapp']: country.iso2 }));
            }
        }
    };
    const registerUser = () => {
        
        setLoading(true);

        generateCaptchaLogin(function(token){
            setErrorMessage(null);

            var provider = ReactSession.get("provider");

            var body = ReactSession.get("provider_response");
            
            body['captcha'] = token;

            body['company_name'] = formValues.company_name;

            body['phone_number'] = formValues.phone_number;

            body['dialcode_phone'] = formValues.dialcode_phone;

            body['country_phone'] = formValues.country_phone;

            body['job_role'] = formValues.job_role;

            body['role'] = 'Researcher';
            
            if(body["job_role"]=="Other..."){
                body["job_role"] = body["job_role_other"];
            }

            fetch(process.env.REACT_APP_API_END_POINT+'social-login/'+provider+'/Researcher', {
                method: 'POST',
                body: JSON.stringify(body),
                headers:{"Content-Type":"application/json"}
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);
                if(response.success){
                    ReactSession.set("token", response.token);
                    ReactSession.set("user", response.user);
                    navigate('/dashboard');

                } else {
                    
                    setErrorMessage(response.message);
                }
            });
        })

        
    }
    
    const onSubmit =  async (event) => {
        event.preventDefault();

        setFormErrors({phone_no:null, first_name: null, last_name: null, company_name:null, job_role:null, job_role_other: null, password:null, email: null, terms:false, password_error_class:null,  "error_class": null});
        
        setErrorMessage(null);

        if(!isLoading){

            var error = false;
            
            if(formValues.company_name==''){

                setFormErrors({
                    phone_no:null,
                    first_name: null, 
                    last_name: null, 
                    company_name: "Required field",
                    job_role: null,
                    job_role_other: null,
                    password:null, 
                    email: null, 
                    terms:false, 
                    password_error_class:null,  
                    error_class: "input_error"
                });
                error = true;
            } 
            else if(formValues.job_role==''){

                setFormErrors({
                    phone_no:null,
                    first_name: null, 
                    last_name: null, 
                    company_name: null,
                    job_role: "Required field",
                    job_role_other: null,
                    password:null, 
                    email: null, 
                    terms:false, 
                    password_error_class:null,  
                    error_class: "input_error"
                });
                error = true;
            } else if(formValues.job_role=='Other...' && formValues.job_role_other==''){

                setFormErrors({
                    phone_no:null,
                    first_name: null, 
                    last_name: null, 
                    company_name: null,
                    job_role: null,
                    job_role_other: "Required field",
                    password:null, 
                    email: null, 
                    terms:false, 
                    password_error_class:null,  
                    error_class: "input_error"
                });
                error = true;
            } else {
                 if (formValues.phone_number == '') {
                    
                    setFormErrors({
                        phone_no:"Required field",
                        first_name: null, 
                        last_name: null, 
                        company_name: null,
                        job_role: null,
                        job_role_other: null,
                        password:null, 
                        email: null, 
                        terms:false, 
                        password_error_class:null,  
                        error_class: "input_error"
                    });
        
                    error = true;
        
                } else if (formValues.phone_number != '') {
        
                    let valid = false;
                    try {
        
                        const phoneUtil = PhoneNumberUtil.getInstance();
                        valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + formValues.dialcode_phone + formValues.phone_number));
        
                    } catch (e) {
        
                        valid = false;
        
                        error = true;
                    }
                    if (!valid) {
                        
                        setFormErrors({
                            phone_no:"Phone no is not valid",
                            first_name: null, 
                            last_name: null, 
                            company_name: null,
                            job_role: null,
                            job_role_other: null,
                            password:null, 
                            email: null, 
                            terms:false, 
                            password_error_class:null,  
                            error_class: "input_error"
                        });
                        error = true;
                    }
                }
            }
            if(!error){
                registerUser();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const jobRoleChange = (item) => {
        jobRolesSelect.current.selectSingleItem({ value: item.value });
        setFormValue("job_role",item.value);
        setJobRoleLabelClass('active_label')
    }
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({...oldValues, [name]: value }));  
    };
    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
                <div className="login-hdr-right">
                    <Link to="/tester/login">Become a tester</Link>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <h1 className="form-heading">Tell us more about yourself</h1>
                        <div className="login-form-inner-data">
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <form method='POST' onSubmit={onSubmit} className="mt-4">
                                <div className="input input-field form-group ">
                                    <input type="text" id="company_name" className={formErrors.company_name!=null ? "form-control "+formErrors.error_class : "form-control"} name='company_name' value={formValues.company_name} onChange={set('company_name')}  />
                                    <label htmlFor="company_name">Company name <img src={process.env.REACT_APP_URL+"images/star-field.svg" } className="required-field-icon" alt="required-field"/></label>
                                    <span className="clear" onClick={clearFormValue('company_name')}>
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22" />
                                            <path className="long" d="M9 15L20 4" />
                                            <path className="arrow" d="M13 11V7" />
                                            <path className="arrow" d="M17 11H13" />
                                        </svg>
                                    </span>
                                    {(formErrors.company_name!=null) &&
                                        <span className={formErrors.error_class}>{formErrors.company_name}</span>
                                    }
                                </div>
                                <div className="profile-form-group">
                                    <div className="input-field static-label">
                                        <label className={jobRoleLabelClass} htmlFor="job_role">Job role</label> 
                                        <div className={formErrors.job_role!=null ? formErrors.error_class:''}>
                                            <Dropdown
                                                name="job_role"
                                                title="Job Role"
                                                list={jobRoleOptions}
                                                onChange={jobRoleChange}
                                                ref={jobRolesSelect}
                                                styles={{
                                                    headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                    headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                }}
                                            />
                                        </div>
                                        {(formErrors.job_role!=null) &&
                                            <span className={formErrors.error_class}>{formErrors.job_role}</span>
                                        }
                                    </div>
                                </div>
                                {formValues.job_role=="Other..." &&
                                    <div className="profile-form-group">
                                        <div className="input input-field form-group">
                                            <input type="text" id="job_role_other" className={formErrors.job_role_other!=null ? "form-control "+formErrors.error_class : "form-control"} name='job_role_other' value={formValues.job_role_other} onChange={set('job_role_other')}  />
                                            <label htmlFor="job_role_other" className='control-label'>Job Role Other <img src={process.env.REACT_APP_URL+"images/star-field.svg" } className="required-field-icon" alt="required-field"/></label>
                                            <span className="clear" onClick={clearFormValue('job_role_other')}>
                                                <svg viewBox="0 0 24 24">
                                                    <path className="line" d="M2 2L22 22" />
                                                    <path className="long" d="M9 15L20 4" />
                                                    <path className="arrow" d="M13 11V7" />
                                                    <path className="arrow" d="M17 11H13" />
                                                </svg>
                                            </span>
                                            {(formErrors.job_role_other!=null) &&
                                                <span className={formErrors.error_class}>{formErrors.job_role_other}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="profile-form-group">
                                    <div className="input-field static-label multi-field-wrap">
                                        <label htmlFor="phone">Your phone number</label>
                                        <IntlTelInput
                                            containerClassName="intl-tel-input"
                                            onPhoneNumberChange={onPhoneNumberChange(
                                                "phone_number"
                                            )}
                                            defaultValue={formValues.phone_number}
                                            defaultCountry={formValues.country_phone}
                                            onSelectFlag={onFlagChange("phone_number")}
                                            inputClassName={
                                                formErrors.phone_no != null
                                                    ? "form-control " + formErrors.error_class
                                                    : "form-control "
                                            }
                                            format={"true"}
                                            preferredCountries={["ae", "sa"]}
                                        />
                                        {formErrors.phone_no != null && (
                                            <span className={formErrors.error_class}>
                                                {formErrors.phone_no}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="researcher-form-btn">
                                    <button id="r_google_sign_in" type="submit" className="btn btn-block form-btn">
                                        Continue to Dashboard
                                        {isLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                        }
                                    </button>
                                </div>
                            </form>
                            <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap email-sent-modal">
                                <Modal.Body> 
                                    <div className="modal-inner-text">
                                        <button type="button" className="close" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } aria-label="Close">
                                            <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                                        </button>
                                        <h2>{modal.modalHeading}</h2>
                                        <p>{modal.modalMessage}</p>
                                        <Link to="/researcher/login" className="btn btn-block form-btn">BACK TO SIGN IN</Link>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}