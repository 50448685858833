import React from "react";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";

import { useState, useEffect, useRef } from "react";
import {
	getTestData,
	deleteDesignService,
	reorderDesignService,
	updatePreferenceTestService,
} from "../../../services/test";
import toast from "react-hot-toast";
import ConfirmModal from "../../dialog/confirm_modal";
import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { FormattedMessage } from "react-intl";
import { maxLengthCheck } from "../../../lib/helpers";
import InputField from "../../layouts/elements/fields/input";
import Checkbox from "../../layouts/elements/fields/checkbox";
import PreferenceTestDesignModal from "./preference_test/preference_test_design_modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EditPreferenceTestDesignModal from "./preference_test/edit_preference_test_design_modal";
import { LoadingIcon } from "../../loader/loadingIcon";

export default function PreferenceTest() {
	let { test_id } = useParams();
	const nodeRef = useRef(null);
	const navigate = useNavigate();
	ReactSession.setStoreType("localStorage");
	const [formValues, setFormValues] = useState({
		title: "",
		randomize_order: 0,
		show_fullscreen: 0,
		show_design_titles:0,
		designs: [],
	});
	const [pageLoading, setPageLoading] = useState(false);

	const [preferenceTestLoading, setPreferenceTestLoading] = useState(false);

	const [preferenceTestStepLoading, setPreferenceTestStepLoading] =
		useState(false);

	const [lastSaved, setLastSaved] = useState(null);

	const [isLoading, setLoading] = useState(false);

	const [confirmModal, setConfirmModal] = useState({ open: false });

	const [test, setTest] = useState("");

	const [editable_text, setEditableText] = useState("");

	const [step, setStep] = useState(0);

	const [addDesignModal, setAddDesignModal] = useState({ open: false });

	const [editDesignModal, setEditDesignModal] = useState({ open: false, design:null });

	const [formErrors, setFormErrors] = useState({
		title: null,
		error_class: "input_error",
	});

	let treeError = false;

	let treeAnswerError = false;

	const showError = (error) => {
		toast(
			<div className="toastinner">
				{error}
				<a
					onClick={() => {
						toast.dismiss();
					}}
				>
					&times;
				</a>
			</div>,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: 3000,
			}
		);
	};

	const navigateTree = (tree, task) => {
		tree.forEach(function (item) {
			if (task == "expand") {
				item["expanded"] = true;
			}
			if (task == "error") {
				if (!item.title) {
					treeError = true;

					item["error"] = true;
				} else {
					item["error"] = false;
				}
			}
			if (item.children.length > 0) {
				navigateTree(item.children, task);
			}
		});
	};
	const checkIfNonLeaf = (tree, answer_id) => {
		tree.forEach(function (item) {
			if (answer_id == item.id && item.children.length > 0) {
				treeAnswerError = true;

				item["error"] = true;
			}

			if (item.children.length > 0) {
				checkIfNonLeaf(item.children, answer_id);
			}
		});
	};
	const getTestApi = () => {
		let token = ReactSession.get("token");

		setPageLoading(true);

		getTestData({ test_id: test_id }, token).then((response) => {
			setPageLoading(false);

			if (response.success) {
				setTest(response.test);

				if (response.test.status) {
					if (response.test.status == "published") {
						navigate("/researcher/project/" + response.test.project_id);
					} else {
						if (
							response.test.is_briefing_questions == 1 &&
							response.test.briefingQuestion.length == 0
						) {
							navigate("/researcher/tests/" + test_id + "/introduction");
							showError("Please add briefing questions");
						}
					}
				}
				// redirectToStep({
				//   test_id: test_id,
				//   current_step: 2,
				//   saved_step: response.test.saved_step,
				//   navigate: navigate,
				// });
				/*if(response.test.saved_step || response.test.saved_step==0){
					
					if(response.test.saved_step<1){
					
						
						//navigate("/researcher/tests/"+test_id+"/introduction");
					}
				}*/
				setLastSaved(moment(response.test.updated_at));


				setFormValues({
					designs: response.test.preferenceTestDesigns,
					title: response.test.preference_test_setting
						? (response.test.preference_test_setting.preference_test_title == null? "":response.test.preference_test_setting.preference_test_title)
						: "",
					randomize_order: response.test.preference_test_setting
						? response.test.preference_test_setting.randomize_order
						: 0,
					show_fullscreen: response.test.preference_test_setting
						? response.test.preference_test_setting.show_fullscreen
						: 0,
					show_design_titles: response.test.preference_test_setting
						? response.test.preference_test_setting.show_design_titles
						: 0,
					designs: response.test.preference_test_designs,
				});
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});
	};
	const saveBuildTestStep = () => {
		
		var error = false;

		var form_errors = resetFormErrors();

		if (formValues.designs.length < 2) {
			error = true;
		}
		if (!formValues.title) {
			error = true;

			form_errors = {
				...form_errors,
				"title":"required",
				"error_class":"input_error"
			}
			setFormErrors(form_errors);
		}

		
		
		if (error) {
			toast(
				<div className="toastinner">
					{
						<FormattedMessage id="There's some information we still need – please take a look to see if you missed anything!" />
					}
					<a
						onClick={() => {
							toast.dismiss();
						}}
					>
						&times;
					</a>
				</div>,
				{
					className: "errortoast",
					position: "bottom-center",
				}
			);
		}
		if (!error) {
			savePreferenceTestData(formValues, true);
		}
	};
	const savePreferenceTestData = (form, shouldnavigate) => {
		if (shouldnavigate) {
			setPreferenceTestStepLoading(true);
		} else {
			setLoading(true);
		}

		let new_designs = [];

		for(var i=0; i< form.designs.length;i++){

			let temp_design = form.designs[i];

			delete temp_design["test_id"];
			
			new_designs.push(temp_design);

		}
		let data = Object.assign({}, form);

		data["test_id"] = test_id;

		data["designs"] = new_designs;

		data["autosave"] = 1;

		



		if (shouldnavigate) {
			data["autosave"] = 0;
		}

		updatePreferenceTestService(data, ReactSession.get("token")).then(
			(response) => {
				setLoading(false);

				setPreferenceTestStepLoading(false);

				if (response.success) {
					setLastSaved(moment.now());
					if (shouldnavigate) {
						navigate("/researcher/tests/" + test_id + "/conclusions");
					}
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};
	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Preference Test";

		//window.addEventListener('scroll', handleScroll);

		getTestApi();
	}, []);

	useEffect(() => {
		if (nodeRef.current) {
			nodeRef.current.focus();
		}
		const timer = setInterval(() => {
			if (test && test.status != "published") {
				savePreferenceTestData(formValues, false);
			}
		}, 30000);

		return () => {
			clearTimeout(timer);
		};
	}, [formValues]);
	const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
	const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
	};
	const showFormError = (name, value) => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		formErrorsLocal[name] = value;
		formErrorsLocal["error_class"] = "input_error";
		setFormErrors(formErrorsLocal);
		console.log(formErrorsLocal);
	};
	const clearFormValue = (name) => {
		
		setTimeout(function () {
			setFormValues({ ...formValues, [name]: "" });
		}, 1000);
	};
	const resetFormErrors = () => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		setFormErrors(formErrorsLocal);
	};

	const addDesign = (e) => {
		e.preventDefault();

		setAddDesignModal({ ...addDesignModal, open: true });
	};
	const renderAddDesign = (e) => {
		e.preventDefault();
	};

	const deleteDesign = (design_id) => {
		let token = ReactSession.get("token");

		setLoading(true);
		deleteDesignService({ id: design_id, test_id: test_id }, token).then(
			(response) => {
				setLoading(false);
				if (response.success) {
					setLastSaved(moment());

					var designs = [];

					formValues.designs.forEach(function (item, index) {
						if (item.id != design_id) {
							var new_design = item;
							new_design.sequence = index + 1;
							designs.push(new_design);
						}
					});

					setFormValues({ ...formValues, designs: designs });
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};
	
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const designs = reorder(
			formValues.designs,
			result.source.index,
			result.destination.index
		);
		console.log(designs);
		console.log(result.destination.index);

		setFormValues({...formValues, "designs":designs});
		/*let token = ReactSession.get("token");

		setLoading(true);

		var design_id = [];

		designs.forEach(function (item) {
			design_id.push(item.id);
		});

		reorderDesignService(
			{ design_id: design_id, test_id: test_id },
			token
		).then((response) => {
			setLoading(false);
			if (response.success) {
				setLastSaved(moment());
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});*/

	}
	const renderDesigns = () => {
		var designs_render = [];
		
		formValues.designs.forEach(function (item, i) {
			designs_render.push(
				<Draggable
					draggableId={"design" + item.id}
					key={"design" + item.id}
					index={i}
					
				>
					{(provided) => (

						<div 
							className="preference-test-design-wrap"
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
						>
							<div className="prefer-design-image-view-hold">
								<div className="prefer-test-selected-design-view">
									{item.type=="video" &&
									<div className="video-design-icon">
										<img src={process.env.REACT_APP_URL+"images/video-play-btn.svg"} alt="icon"/>
									</div>
									}
									<img src={process.env.REACT_APP_IMG_URL + item.thumb} />
									
									<div className="prefer-test-preview-hover">
										<div className="prefer-design-drag-icon">
											<img src={process.env.REACT_APP_URL+"images/drag-icon.svg"} />
										</div>
										<div className="prefer-design-action-option">
											<span
												onClick={() => {
													console.log(item)
													setEditDesignModal({ open: true, design: item, index: i});
												}}
											>
												<img src={process.env.REACT_APP_URL+"images/edit-icon.svg"} alt="icon" />
											</span>
											<span>
												<a href={process.env.REACT_APP_IMG_URL + item.design_file} target="_blank">
													<img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="icon" />
												</a>
											</span>
											<span
												onClick={() => {
													setConfirmModal({ open: true, design_id: item.id });
												}}
											>
												<img
													src={process.env.REACT_APP_URL+"images/trash-black.svg"}
													alt="icon"
												/>
											</span>
										</div>
									</div>
								</div>
								<h3 className={`h3 prefer-design-name ${(test.language=="ar") ? "arabic_wrapper" : ""}`}>
									{/*<span>{i.toString().length==1 ? "0"+(i+1):(i+1)}</span>*/}	{item.title}
								</h3>
							</div>
						</div>
					)}
				</Draggable>
			);
		});

		return (
			<DragDropContext onDragEnd={onDragEnd} className="row">
				<Droppable droppableId={"list"}>
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}
						className="preference-designs-repeat"
						style={{"width":"100%"}}
						
						>
							{designs_render}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	};


	return (
		<LayoutResearcherFullWidth
			isLoading={false}
			fixed_header_target={true}
			wrapClass={test && test.language == "ar" ? "arabic_wrapper_main" : ""}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
		>
			<div className="study-introduction-page-wrapper stydy-pages-wrapper target_group_fixed_header tree-page-wrapper">
				<div className="page-back-option-wrap">
					<div className="container inner-page-container">
						<Topbar
							stepCallback={() => {
								savePreferenceTestData(formValues, false);
							}}
							test={test}
							enablePublish={false}
							onChange={(test) => {
								setTest({ ...test });
							}}
							credits_required={test.credits_required}
							status={test.status}
							lastSaved={lastSaved}
							path={"/researcher/tests/" + test_id + "/introduction"}
							isLoading={isLoading}
							title={test.test_name}
							pageLoading={pageLoading}
						/>
					</div>
				</div>
				<div className="container inner-page-container">
					<div className="study-steps-progress-wrap">
						
							<Progress
								stepCallback={() => {
									savePreferenceTestData(formValues, false);
								}}
								test={test}
								completed_step={1}
							/>
						
					</div>
					{pageLoading &&
						<LoadingIcon />
					}
					{!pageLoading &&
					<div className="study-introduction-page-wrapper preference-test-page-data">
						<div className="target-group-data-wrapper">
							<div className="target-group-data-repeat">
								<div className="targer-group-left-wrap pb-0 border-0">
									<h3 className="mb-0">Task</h3>
									<div className="prefer-test-title-wrap mt-32 mb-0">
										<InputField
											
											id={"Add Task"}
											rtl={test.language == "ar" ? true : false}
											label={"Task description"}
											maxLength="200"
											error={formErrors.title}
											error_class={formErrors.error_class}
											onClear={() => {
												clearFormValue("title");
											}}
											value={formValues.title}
											onChange={set("title")}
											onInput={(e)=>{maxLengthCheck(e);
												if(e.target.value.length >0){
													setFormErrors({...formErrors, title:null});
												}
											}}
										/>
									</div>
								</div>
								<div className="target-group-info-repeat pb-0">
									<h3>Task</h3>
									<p>Describe the options you want participants to choose from. For example, “Which design do you prefer?”</p>
								</div>
							</div>
							<div className="target-group-data-repeat border-0 pb-0 sort-card-name-wrap">
								<div className="targer-group-left-wrap border-0 pb-0">
									<h3>Designs</h3>
									<div className="selected-card-sort-option closed-card-selected ">
										<div className="row">
											<div className="col-lg-12">
												<Checkbox
													checked={
														formValues.randomize_order == 1 ? true : false
													}
													onChange={() => {
														console.log(formValues)
														setFormValues({
															...formValues,
															randomize_order: !formValues.randomize_order,
														});
													}}
													label="Randomise the order that designs are shown to participants"
												/>
											</div>
											<div className="col-lg-12">
												<Checkbox
													checked={
														formValues.show_fullscreen == 1 ? true : false
													}
													onChange={() => {
														setFormValues({
															...formValues,
															show_fullscreen: !formValues.show_fullscreen,
														});
													}}
													label="Show designs one at a time"
												/>
											</div>
											<div className="col-lg-12">
												<Checkbox
													checked={
														formValues.show_design_titles == 1 ? true : false
													}
													onChange={() => {
														setFormValues({
															...formValues,
															show_design_titles: !formValues.show_design_titles,
														});
													}}
													label="Show designs titles to the participants"
												/>
											</div>
										</div>
									</div>
									<div className="upload-design-img-video-wrap">
										<div className="preference-designs-repeat">
											{renderDesigns()}
											

											<div className="add-more-design-opton w-100">
												<button
													className="create-btn add-new-card-btn add-new-member-btn "
													onClick={addDesign}
												>
													Add Design
													<span>
														<img
															src={
																process.env.REACT_APP_URL +
																"images/plus-white.svg"
															}
														/>
													</span>
												</button>
											</div>
										</div>
									</div>
									<div className="button-wrap d-flex justify-content-end mt-64">
										<button
                                            id="r_create_step_2_build_next_btn"
											type="submit"
											className="button primary-btn"
											onClick={saveBuildTestStep}
										>
											Next
											{preferenceTestStepLoading && (
												<i
													className="fa fa-spinner fa-spin"
													aria-hidden="true"
													style={{ marginLeft: "5px" }}
												></i>
											)}
										</button>
									</div>
								</div>
								<div className="target-group-info-repeat">
									<h3>Designs</h3>
									<p>
									The designs you choose will be shown to your participants, where they’ll pick the one the prefer. <br/><br/>
									You can randomise the order of the options are shown to participants, and you can also select to show them one by one, rather than in a grid.
									</p>
								</div>
							</div>
						</div>
						<div
							className="target-group-right-side"
							style={{ display: "none" }}
						>
							<div className="target-group-info-repeat">
								<h3>Task</h3>
								<p>Describe the options you want participants to choose from. For example, “Which design do you prefer?” </p>
							</div>
							<div className="target-group-info-repeat">
							<h3>Designs</h3>
									<p>
									The designs you choose will be shown to your participants, where they’ll pick the one the prefer. <br/><br/>
									You can randomise the order of the options are shown to participants, and you can also select to show them one by one, rather than in a grid.
									</p>
							</div>
						</div>
					</div>
					}
				</div>
			</div>
			<ConfirmModal
				confirm_message="Are you sure you want to delete this design?"
				confirm_btn_title="Delete"
				confirm_title="Delete Design"
				openModal={confirmModal.open}
				close={() => {
					setConfirmModal({ ...confirmModal, open: false });
				}}
				confirm={() => {
					setConfirmModal({ ...confirmModal, open: false });

					deleteDesign(confirmModal.design_id);
				}}
			/>
			<PreferenceTestDesignModal
				test={test}
				open={addDesignModal.open}
				close={() => {
					setAddDesignModal({ ...addDesignModal, open: false });
				}}
				confirm={(design) => {
					setAddDesignModal({ ...addDesignModal, open: false });

					var designs = [...formValues.designs];

					designs.push(design);

					setLastSaved(moment());

					setFormValues({ ...formValues, designs: designs });
				}}
			/>
			
			<EditPreferenceTestDesignModal
				design={editDesignModal.design}
				test={test}
				open={editDesignModal.open}
				close={() => {
					setEditDesignModal({ ...editDesignModal, open: false });
				}}
				confirm={(design) => {
					setEditDesignModal({ ...editDesignModal, open: false });

					var designs = [...formValues.designs];

					designs[editDesignModal.index] = design;

					setLastSaved(moment());

					setFormValues({ ...formValues, designs: designs });
				}}
			/>
		</LayoutResearcherFullWidth>
	);
}
