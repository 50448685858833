import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import { getResearcherTestResultData, getResearcherTestResultOverviewData } from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import RechartArea from './components/rechart_area';
import {getCurrentPlansService} from "../../../services/plans";
import  moment from "moment";
import {
    atom,
    useRecoilState,
  } from 'recoil';
import {LoadingIcon} from "../../loader/loadingIcon";



export default function TestResultOverview() {

    let { test_id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);

    const testState = atom({
        key: 'testresult-'+test_id, 
        default: ""
    });

    const planState = atom({
        key: 'testresultplan-'+test_id, 
        default: ""
    });
    const testResultOverviewState = atom({
        key: 'testresultoverview-'+test_id, 
        default: ""
    });
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [participantPercentage, setParticipantPercentage] = useState(0);
    const [averageTimeSpend, setAverageTimeSpend] = useState("");
    //const [areaClosedChartData, setAreaClosedChartData] = useRecoilState(testResultOverviewState);
    const [areaClosedChartData, setAreaClosedChartData] = useState([]);

    const getTestafterEndTest=()=>{

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            }else{

                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {
        if(!test){
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setTest(response.test);
                    getOverview();
                   // setAreaClosedChartData(response.testResultoverviewChart);


                    if(response.test.test_results_count){
                        const totalParticipants = (response.test.test_results_count)/(response.test.participants)*100;

                        var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                        if(totalParticipantCalculated > 100){
                            setParticipantPercentage(100);
                        }else {
                            setParticipantPercentage(totalParticipantCalculated);
                        }

                    }

                    if(response.test.total_time_spent){

                        const avearagetime = parseInt((response.test.total_time_spent)/(response.test.test_results_count));

                        var d = Math.floor(avearagetime / (3600*24));
                        var h = Math.floor(avearagetime % (3600*24) / 3600);
                        var m = Math.floor(avearagetime % 3600 / 60);
                        var s = Math.floor(avearagetime % 60);

                            if (d > 0) {
                                setAverageTimeSpend(`${d}d ${h}h ${m}m ${s}s`);

                            } else if (h > 0) {

                                setAverageTimeSpend(`${h}h ${m}m ${s}s`);

                            } else if (m > 0) {
                                setAverageTimeSpend(`${m}m ${s}s`);

                            } else if(s > 0) {
                                setAverageTimeSpend(`0m ${s}s`);
                            }else{
                                setAverageTimeSpend('0m 0s');

                            }
                    }


                } else {

                    navigate("/dashboard");


                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {

            getOverview();
            if(test.test_results_count){
                const totalParticipants = (test.test_results_count)/(test.participants)*100;

                var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                if(totalParticipantCalculated > 100){
                    setParticipantPercentage(100);
                }else {
                    setParticipantPercentage(totalParticipantCalculated);
                }
            }

            if(test.total_time_spent){

                const avearagetime = parseInt((test.total_time_spent)/(test.test_results_count));

                var d = Math.floor(avearagetime / (3600*24));
                var h = Math.floor(avearagetime % (3600*24) / 3600);
                var m = Math.floor(avearagetime % 3600 / 60);
                var s = Math.floor(avearagetime % 60);

                    if (d > 0) {
                        setAverageTimeSpend(`${d}d ${h}h ${m}m ${s}s`);

                    } else if (h > 0) {

                        setAverageTimeSpend(`${h}h ${m}m ${s}s`);

                    } else if (m > 0) {
                        setAverageTimeSpend(`${m}m ${s}s`);

                    } else if(s > 0) {
                        setAverageTimeSpend(`0m ${s}s`);
                    }else{
                        setAverageTimeSpend('0m 0s');
                    }
            }
        }
    };

    const getOverview = ()=>{
        let token = ReactSession.get("token");

        setSectionLoading(true);

        getResearcherTestResultOverviewData({ test_id: test_id }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {
                setTest(response.test);

                 setAreaClosedChartData(response.testResultoverviewChart);


                if(response.test.test_results_count){
                    const totalParticipants = (response.test.test_results_count)/(response.test.participants)*100;

                    var totalParticipantCalculated = parseFloat(totalParticipants.toFixed());
                    if(totalParticipantCalculated > 100){
                        setParticipantPercentage(100);
                    }else {
                        setParticipantPercentage(totalParticipantCalculated);
                    }

                }

                if(response.test.total_time_spent){

                    const avearagetime = parseInt((response.test.total_time_spent)/(response.test.test_results_count));

                    var d = Math.floor(avearagetime / (3600*24));
                    var h = Math.floor(avearagetime % (3600*24) / 3600);
                    var m = Math.floor(avearagetime % 3600 / 60);
                    var s = Math.floor(avearagetime % 60);

                    if (d > 0) {
                        setAverageTimeSpend(`${d}d ${h}h ${m}m ${s}s`);

                    } else if (h > 0) {

                        setAverageTimeSpend(`${h}h ${m}m ${s}s`);

                    } else if (m > 0) {
                        setAverageTimeSpend(`${m}m ${s}s`);

                    } else if(s > 0) {
                        setAverageTimeSpend(`0m ${s}s`);
                    }else{
                        setAverageTimeSpend('0m 0s');

                    }
                }


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const getPlan = () => {

        if(!planType){
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    };




    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Test Result Overview";
        getPlan();
        getTest();

    }, []);

    const callbackTopbar = (data)=>{
        getTest();
        getTestafterEndTest();
    }


    return (
        <LayoutResearcherFullWidth fixed_header_target={true} isLoading={isLoading} wrapClass={"researcher-test-view-overflow overflow-visible"}  skipCheck={false} extendedFooter={false} activeMenu={""}>
            {test &&
            <div className="analyze-results-page-wrapper">


                <Topbar test={test} callbackTopbar={callbackTopbar} />

                <div className="container inner-page-container">
                    <div className="sp5-content-wrap analyze-overflow-wrap">

                        <Sidebar test={test} planType={planType} activeMenu='overview' />



                        <div className="sp5-right-side-wrap">
                            {/*{planType &&*/}
                            {/*(planType === 'PAID' ||*/}
                            {/*    (planType === 'FREE' && (moment.utc(test.published_on).add(30, 'days').format('DD-MM-Y') < moment.utc().format('DD-MM-Y'))*/}
                            {/*    ))*/}
                            {/*&&*/}

                            {sectionLoading &&
                            <LoadingIcon />
                            }
                            {!sectionLoading &&
                            <div className="sp5-overview-data">
                                <h3 className="h3 font-inter">Overview</h3>
                                <div className="sp5-overview-data-inner">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="sp5-overview-txt">
                                                {test.methodology == "Survey" ? (<p>Total number of participants</p>) : (
                                                    <p>Total number of participants</p>)}


                                                <h3>
                                                    {test && test.participants
                                                        ?
                                                        <>
                                                            {test.test_results_count} /
                                                            <span>{test.participants}</span>
                                                        </>
                                                        : test.test_results_count}

                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="sp5-overview-txt">
                                                <p>Study language</p>
                                                <h3 className="sp5-study-lan-wrap">

                                                    {(test.language === 'en') ?
                                                        <>
                                                            <img src={process.env.REACT_APP_URL + "images/lan-icon.svg"}
                                                                 alt="img"/>
                                                            English
                                                        </>
                                                        :
                                                        <>
                                                            <img
                                                                src={process.env.REACT_APP_URL + "images/arabic_icon.svg"}
                                                                alt="img"/>
                                                            Arabic
                                                        </>
                                                    }
                                                </h3>
                                            </div>
                                        </div>
                                        {test && (test.target_group.target_type !== 'test-links') &&
                                        <>
                                            <div className="col-md-6">
                                                <div className="sp5-overview-txt">
                                                    {/*{test.target_group.project_target_group.target_group_name || ''}*/}
                                                    <p>Users from UserQ Panel
                                                        {/*{ (test.target_group && test.target_group.project_target_group && test.target_group.target_type === 'target-group') &&*/}

                                                        {/*    " UserQ Panel"*/}

                                                        {/*}*/}
                                                        {/*{ (test.target_group && test.target_group.project_target_group &&  test.target_group.target_type !== 'target-group') &&*/}
                                                        {/*    " UserQ Panel"*/}
                                                        {/*}*/}
                                                    </p>
                                                    <h3>{test.test_results_tester_count}</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="sp5-overview-txt">
                                                    <p>Users from shared link</p>
                                                    <h3>{test.test_results_guest_count}</h3>
                                                </div>
                                            </div>
                                        </>
                                        }
                                    </div>

                                    {test.participants &&
                                    <div className="profile-complete-number">
                                        <div className="comp-profile-count">
                                            <div role="progressbar" aria-valuenow={participantPercentage}
                                                 aria-valuemin="0"
                                                 aria-valuemax="100" style={{"--value": participantPercentage}}></div>
                                        </div>
                                        {test.methodology == "Survey" ? (
                                            <p>Users that have completed your survey, out of the total number of
                                                respondents.</p>) : (<p>Users that have completed your test.</p>)}

                                    </div>
                                    }


                                    <div
                                        className={`sp5-overview-graph-area ${!test.participants ? 'no-participants-count' : ''}`}>


                                        <div className="sp5-overview-graph-info">
                                            {test.published_on &&
                                            <div className="graph-info-repeat">
                                                <p>Live from</p>
                                                <h4>{moment.utc(test.published_on).format('DD-MM-Y')}</h4>
                                            </div>
                                            }
                                            <div className={`graph-info-repeat`}>
                                                <p>Avg. engagement time</p>
                                                <h4> {averageTimeSpend}</h4>
                                            </div>
                                            {test && test.status === 'published' &&
                                            <div className="graph-info-repeat">
                                                {test.methodology == "Survey" ? (<p>Surveyed today</p>) : (
                                                    <p>Tested today</p>)}

                                                <h4>{test.test_results_today_count}</h4>
                                            </div>
                                            }
                                        </div>
                                        <div className="sp5-graph-area">

                                            <RechartArea areaClosedChartData={areaClosedChartData}
                                                         testmethodology={test.methodology}/>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {/*{planType && planType === 'FREE' &&*/}
                            {/*(moment.utc(test.published_on).add(30, 'days').format('DD-MM-Y') >= moment.utc().format('DD-MM-Y'))*/}
                            {/*&&*/}
                            {/*<div className="block-colaborator-section d-block mt-64 free-user-analy-overview">*/}
                            {/*    <div className="lock-img-sec">*/}
                            {/*        <img src={process.env.REACT_APP_URL + "images/lock-icon.svg"} alt="img"/>*/}
                            {/*    </div>*/}
                            {/*    <div className="text-wrap">*/}
                            {/*        <p>Sorry! You don’t have access to this test as it ended more than 30 days*/}
                            {/*            ago. <br/>To see the test report, please upgrade to PRO.</p>*/}
                            {/*        <Link to={"/researcher/billing/plan/change"} className="btn form-btn">Upgrade*/}
                            {/*            now</Link>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}



                        </div>

                    </div>
                </div>
            </div>
            }
        </LayoutResearcherFullWidth>
    )
}