import moment from "moment";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {roundNumber} from "../../../lib/helpers";

export default function TesterTestFrame({ settings, test, onClick,testsLoading }) {
  const getDaysLeft = () => {
    var daysdiff = moment.utc(test.closed_on).local().diff(moment(), "days");

    var hoursdiff = moment.utc(test.closed_on).local().diff(moment(), "hours");

    var minutesdiff = moment
      .utc(test.closed_on)
      .local()
      .diff(moment(), "minutes");

    if (daysdiff <= 3 && daysdiff > 0) {
      return (
        <span className="days-left-text">
          {daysdiff} day{daysdiff == 1 ? "" : "s"} left
        </span>
      );
    } else if (hoursdiff < 24 && hoursdiff > 0) {
      return (
        <span className="days-left-text">
          {hoursdiff} hour{hoursdiff == 1 ? "" : "s"} left
        </span>
      );
    } else if (minutesdiff < 60 && minutesdiff >= 0) {
      return (
        <span className="days-left-text none-for-mobile">
          {minutesdiff} minute{minutesdiff <= 1 ? "" : "s"} left
        </span>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div className={`dash-projects-repeat tester-dash-test ${testsLoading?'tester-dashboard-skelton-loading':''}`}>
      <div className="dash-project-content">
        <div className="das-pro-head justify-content-end">
          {!testsLoading ?
              <>
                {(test.close_condition == "specific-date" ||
                    test.close_condition == "specific-date-after-no-participants") && (
                    <>{getDaysLeft()}</>
                )}
              </>
              :
              <></>

          }

        </div>
        <div className="ongoing-test-head mt-0">
          <h3 className={`h3 ${testsLoading?'w-100':''}`}>
            {!testsLoading ?
                test.test_name
                :
                <Skeleton width={'100%'} height={15}/>
            }

            </h3>
        </div>
        <div className="dash-pro-status-count">
          <div className="dp-stc-repeat border-0">
            <div className="ong-text">
              <p className="">
                {!testsLoading ?
                    "You receive"
                    :
                    <Skeleton width={70} height={15} />
                }
              </p>
              {!testsLoading ?
                  <>
                    {(test.close_condition == "specific-date" ||
                        test.close_condition ==
                        "specific-date-after-no-participants") && (
                        <>{/*<p className="none-for-desktop">Study ending in</p>*/}</>
                    )}
                  </>
                  :
                  ""
              }
              <h3 className="h3 pink-color">
                {!testsLoading ?
                    <>
                      ${" "}
                      {test.recruitment_fees_for_tester.toFixed(2)}
                      {/*{settings && settings["methodologies"][test.methodology]*/}
                      {/*    ? parseFloat(*/}
                      {/*        settings["methodologies"][test.methodology]["payment"]*/}
                      {/*    ).toFixed(2)*/}
                      {/*    : null}*/}
                    </>
                    :
                    <Skeleton width={70} height={20}/>
                }

              </h3>
              {/*(test.close_condition == "specific-date" ||
                test.close_condition == "specific-date-no-participants") && (
                <h3 className="h3 none-for-desktop">3&nbsp;&nbsp; days left</h3>
                )*/}
            </div>
          </div>
          <div className="dp-stc-repeat border-0">
            <div className="ong-text">
              <p>
                {!testsLoading ?
                    "Duration"
                    :
                    <Skeleton width={70} height={15}/>
                }

              </p>
              <h3 className="h3">
                {!testsLoading ?
                    <>
                      {test.methodology == "Tree Test" ||
                      test.methodology == "Card Sorting" ||
                      test.methodology == "Survey"
                          ? "5-6 minutes"
                          : test.methodology == "Preference Test" || test.methodology == "Five Seconds Test"
                              ? "2-3 minutes"
                              : "5-6 minutes"}
                    </>
                    :
                    <Skeleton width={70} height={20}/>
                }

              </h3>
            </div>
          </div>

          <div
            className="dp-stc-repeat pr-0 justify-content-end d-flex"
            onClick={onClick}
          >
            {!testsLoading ?
                <a
                    href="#"
                    className="project-link test-publish-btn take-test-btn font-weight-600 font-rajdhani"
                >
                  Take test{" "}
                  <img
                      src={process.env.REACT_APP_URL + "images/arrow-frame.svg"}
                      alt="img"
                  />
                </a>
                :
                <Skeleton
                    circle
                    height="30px"
                    width="30px"
                    containerClassName="avatar-skeleton"
                />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
