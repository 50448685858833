import React from 'react';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../lib/secure_reactsession';
import { Navigate } from "react-router-dom";

export default function Home() {
  ReactSession.setStoreType("localStorage");
  let token = ReactSession.get("token");
  let user = ReactSession.get("user");
  console.log(user);
  if(user && token && token!=null){
    
    return (<Navigate to="/dashboard" />);
} else {
    return (<Navigate to="/researcher/login" />);
}
  
  
}
