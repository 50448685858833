import React from 'react';
import { Link,useLocation } from 'react-router-dom';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import Layout from "../../layouts/layout";
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
import Accordian from '../../Accordian';
import InputField from "../../layouts/elements/fields/input";
import IntlTelInput from "react-intl-tel-input";
import {Dropdown} from "reactjs-dropdown-component";
import TextAreaField from "../../layouts/elements/fields/textarea";
import {getProfile, researcherTesterSupportContact} from "../../../services/user";
import {PhoneNumberUtil} from "google-libphonenumber";
import { LoadingIcon } from './../../loader/loadingIcon';



export default function TesterSupportContact(props) {

    const [formErrors, setFormErrors] = useState({ name: null, error_class: "input_error",email: null,phone_no: null, reason: null,message: null });

    const [formValues, setFormValues] = useState({  "name": "","email": "", "phone_number":"","reason":'','message':''})

    const [reasonLabelClass, setReasonLabelClass] = useState('inactive_label');

    const [isLoading, setLoading] = useState(false);
    const [profile_loaded, setProfileLoaded] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    let reasonSelect = useRef();
    const navigate = useNavigate();


    // const useQuery= () => {
    //     return new URLSearchParams(useLocation().search);
    // }

    const [searchParams] = useSearchParams()

    const reasonOptions = [
        {"label":"Suggest an improvement","value":"Suggest an improvement"},
        {"label":"Problem with the platform","value":"Problem with the platform"},
        {"label":"Help with payout","value":"Help with payout"},
        {"label":"Other","value":"Other"}
    ];

    const ReasonChange = (item) => {
        reasonSelect.current.selectSingleItem({ value: item.value });
        setFormValues({...formValues,"reason":item.value});
        setFormErrors((oldValues) => ({ ...oldValues, reason: null }));
        setReasonLabelClass('active_label')
        window.setLabels()
    }


    const onFlagChange = (name) => {
        return (status, country) => {
            if (name == "phone_number") {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["dialcode_phone"]: country.dialCode,
                    ["country_phone"]: country.iso2,
                }));
            } else {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["dialcode_whatsapp"]: country.dialCode,
                    ["country_whatsapp"]: country.iso2,
                }));
            }
        };
    };

    const onPhoneNumberChange = (name) => {
        return (status, phoneNumber, country) => {
            if (name == "phone_number") {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    [name]: phoneNumber,
                    ["dialcode_phone"]: country.dialCode,
                    ["country_phone"]: country.iso2,
                }));
                setFormErrors((oldValues) => ({ ...oldValues, phone_no: null }));
            } else {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    [name]: phoneNumber,
                    ["dialcode_whatsapp"]: country.dialCode,
                    ["country_whatsapp"]: country.iso2,
                }));
            }
        };
    };

    const getProfileResponse = () =>{
        let token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response=> {
            setLoading(false);


            if(response.success){

                // setUserProfile(response.data.user)
                if(response.data.user){

                    if(searchParams.get("reason")){
                        let reasonParam = searchParams.get("reason");
                        setFormValues({...formValues,'name':response.data.user.first_name+' '+response.data.user.last_name,'email':response.data.user.email,"phone_number":
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number
                            : "",
                    "dialcode_phone":
                        response.data.user.dialcode_phone == null
                            ? "us"
                            : response.data.user.dialcode_phone,
                    "country_phone": response.data.user.country_phone,'reason':reasonParam});

                        reasonSelect.current.selectSingleItem({ value: reasonParam });
                        setReasonLabelClass('active_label')
                        window.setLabels()
                    }else{
                        setFormValues({...formValues,'name':response.data.user.first_name+' '+response.data.user.last_name,'email':response.data.user.email,"phone_number":
                        response.data.user.phone_number != null
                            ? response.data.user.phone_number
                            : "",
                    "dialcode_phone":
                        response.data.user.dialcode_phone == null
                            ? "us"
                            : response.data.user.dialcode_phone,
                    "country_phone": response.data.user.country_phone});

                    }


                }
                setProfileLoaded(true);
            }
        });
    }



    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Tester Support";

        if(ReactSession.get("token")) {
            getProfileResponse();
        }else{
            if(searchParams.get("reason")){
                let reasonParam = searchParams.get("reason");
                setFormValues({...formValues,'reason':reasonParam});

                reasonSelect.current.selectSingleItem({ value: reasonParam });
                setReasonLabelClass('active_label')
                window.setLabels()

            }
        }


    }, []);

    const resetFormErrors = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const checkUser = () =>{
        if(ReactSession.get("token")){
            return  false;
        }
        return true;
    }

    const handleSubmit = () => {
        //console.log(formValues)

        var error = false;

        // setFormErrors({ name: null, error_class: "input_error",email: null,phone_no: null, reason: null,message: null });

        var form_errors = resetFormErrors();

        if (formValues.name == "") {
            form_errors = {
                ...form_errors,
                name: "Required field",
                error_class: "input_error",
            };
            error = true;
        }
        if (formValues.email == "") {
            form_errors = {
                ...form_errors,
                email: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        if (formValues.phone_number == "") {
            form_errors = {
                ...form_errors,
                phone_no: "Required field",
                error_class: "input_error",
            };

            error = true;
        } else if (formValues.phone_number != "") {
            let valid = false;
            try {
                const phoneUtil = PhoneNumberUtil.getInstance();
                valid = phoneUtil.isValidNumber(
                    phoneUtil.parse(
                        "+" + formValues.dialcode_phone + formValues.phone_number
                    )
                );
            } catch (e) {
                valid = false;

                error = true;
            }
            if (!valid) {
                form_errors = {
                    ...form_errors,
                    phone_no: "Phone no is not valid",
                    error_class: "input_error",
                };
                error = true;
            }
        }

        if (formValues.reason == "") {
            form_errors = {
                ...form_errors,
                reason: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        if (formValues.message == "") {
            form_errors = {
                ...form_errors,
                message: "Required field",
                error_class: "input_error",
            };

            error = true;
        }

        setFormErrors(form_errors);

        if(!error){
            //console.log(formValues)
            setFormLoading(true);

            const token = ReactSession.get("token");

            var formData = { ...formValues };
            researcherTesterSupportContact(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {
                    setFormValues({ ...formValues,'message':''})
                }

                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: (response.success)?"successtoast":"errortoast",
                        position: "bottom-center",
                        duration: 3000,
                    }
                );
            });
        }

    }





    return (
        <Layout fixed_header_target={true} isLoading={false} skipCheckLogin={checkUser()} skipCheck={false} isFullWidth={true} extendedFooter={false}  >

            <div className="analyze-results-page-wrapper FAQ-support-page-wrap">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <div className="profile-info-section">
                            <div className="target-back-option-data">
                                <div className="backto-list-wrap">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1)
                                    }}>
                                        <img src={process.env.REACT_APP_URL+"images/back-arrow.svg"} alt="img"/> Back</a>
                                </div>
                                <div className="profile-info-text">
                                    <div className="target-group-heading-top studyint-top-flex-wrap w-100">
                                        <h1 className="h1"><span>Tester</span> support</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inner-page-container">
                    <div className="sp5-content-wrap analyze-overflow-wrap">
                        <div className="sidebar sp5-left-side-bar">
                            <div className="sp5-side-bar-innerdata">
                                <div className="sp5-side-nav-wrap border-0">
                                    <ul>
                                        <li><Link to={'/tester/support/'}>Frequently asked questions</Link></li>
                                        <li><Link to={'/tester/support/contact'} className="active">Contact us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        {isLoading &&
						<div className="page-min-height contactus-page-loader">
							<LoadingIcon />
						</div>
                        }
                        {!isLoading && (
                        <div className="sp5-right-side-wrap">
                            <div className="sp5-overview-data faq-data-wrapper">

                                <p className="faqsub-title">﻿We will review and respond to your inquiry within 2 working days.</p>

                                <div className="create-contact-support row">

                                    <div className={'form-group col-md-6'}>
                                        <InputField
                                            label={"Name"}
                                            error={formErrors.name}
                                            error_class={formErrors.error_class}
                                            onChange={(e)=>{
                                                setFormValues({...formValues,"name":e.target.value})
                                                setFormErrors({...setFormErrors,"name":''});
                                            }}
                                            value={formValues.name}
                                            type="text"
                                        />
                                    </div>

                                    <div className={'form-group col-md-6'}>
                                        <InputField
                                            label={"Email"}
                                            error={formErrors.email}
                                            error_class={formErrors.error_class}
                                            onChange={(e)=>{
                                                setFormValues({...formValues,"email":e.target.value})
                                                setFormErrors({...setFormErrors,"email":''});
                                            }}
                                            value={formValues.email}
                                            type="text"
                                        />
                                    </div>

                                    <div className={'form-group col-md-6'}>
                                        <div className="input-field static-label multi-field-wrap" id="phone_number">
                                            <label htmlFor="phone">Your phone number</label>
                                            {profile_loaded && (
                                                <IntlTelInput
                                                    containerClassName="intl-tel-input"
                                                    onPhoneNumberChange={onPhoneNumberChange(
                                                        "phone_number"
                                                    )}
                                                    defaultValue={formValues.phone_number}
                                                    value={formValues.phone_number}
                                                    defaultCountry={formValues.country_phone}
                                                    onSelectFlag={onFlagChange("phone_number")}
                                                    inputClassName={
                                                        formErrors.phone_no != null
                                                            ? "form-control " + formErrors.error_class
                                                            : "form-control "
                                                    }
                                                    format={"true"}
                                                    preferredCountries={["ae", "sa"]}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            )}
                                            {formErrors.phone_no != null && (
                                                <span className={formErrors.error_class}>
                                                                    {formErrors.phone_no}
                                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <div className="profile-form-group">
                                            <div className="input-field static-label" id='job_role'>
                                                <label htmlFor="reason" className={reasonLabelClass}>
                                                    Reason for enquiry
                                                </label>
                                                <div
                                                    className={
                                                        formErrors.reason != null
                                                            ? formErrors.error_class
                                                            : ""
                                                    }
                                                >
                                                    <Dropdown
                                                        name="job_role"
                                                        title="Reason of enquiry"
                                                        list={reasonOptions}
                                                        onChange={ReasonChange}
                                                        ref={reasonSelect}
                                                        styles={{
                                                            headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                                            headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                                        }}
                                                    />
                                                </div>
                                                {formErrors.reason != null && (
                                                    <span className={formErrors.error_class}>
                                                      {formErrors.reason}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-12">
                                        {/*<p className={"reason-heading"}>My message goes here</p>*/}
                                        <TextAreaField
                                            error={formErrors.message}
                                            error_class={formErrors.error_class}
                                            onChange={(e) => {
                                                setFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    "message": e.target.value
                                                }))
                                                setFormErrors({...setFormErrors,"message":''});

                                            }}
                                            value={formValues.message}
                                            maxLength={350}
                                            autosize={true}
                                            type="text"
                                            label={"Your message"} />
                                    </div>

                                    <div className={"form-group col-md-12 d-flex justify-content-end"}>

                                        <button type="submit" onClick={()=>handleSubmit()} className="button primary-btn ml-0 mr-0 mt-64">
                                            Submit
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>

                                    </div>

                                </div>

                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}