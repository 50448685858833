import React from "react";
import { useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestPreferenceTestData,
    getTestTreeTestTaskData
} from "../../../services/researcher_test_results";
import toast from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import LinearAxisChart from "./components/linear_axis_chart";
import { calculateTime } from "../../../lib/helpers";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import SankeyChart from "./components/sankey_chart";
import { atom, useRecoilState, useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE } from 'recoil';
import TaskAttempts from "./components/preference_test/task_attempts";
import { LoadingIcon } from "../../loader/loadingIcon";


export default function TestResultPreferenceTest() {
    let { test_id } = useParams();

    const [isLoading, setLoading] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);

    const [fullView, setFullView] = useState(false);

    const [section, setSection] = useState("Aggregated");

    const [order, setOrder] = useState("Preference");

    const [pref_direction, setPrefDirection] = useState("desc");

    const [votes_direction, setVotesDirection] = useState("asc");

    const [time_direction, setTimeDirection] = useState("asc");

    const [designs, setDesigns] = useState([]);

    const [sectionLoading, setSectionLoading] = useState(false);

    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [task, setTask] = useState({});

    const sortDesigns = (order, dir, designs = []) => {
        var api_designs = [];

        if (designs.length > 0) {
            api_designs = Object.assign([], designs);
        } else {
            api_designs = Object.assign([], task.designs);
        }

        if (order == "preference") {
            order = "votes";
        }
        if (order == "time") {
            order = "time_spent";
        }

        var max = api_designs[0];

        for (var i = 0; i < api_designs.length - 1; i++) {

            for (var j = i + 1; j < api_designs.length; j++) {

                if (dir == "desc") {
                    if (api_designs[i][order] < api_designs[j][order]) {
                        var temp = api_designs[i];

                        api_designs[i] = api_designs[j];

                        api_designs[j] = temp;

                    }
                } else {
                    if (api_designs[i][order] > api_designs[j][order]) {
                        var temp = api_designs[i];

                        api_designs[i] = api_designs[j];

                        api_designs[j] = temp;

                    }
                }
            }

        }
        setDesigns(api_designs);
    }
    const getTestPreferenceTestDataApi = () => {


        let token = ReactSession.get("token");

        setSectionLoading(true);

        getTestPreferenceTestData({ test_id: test_id }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                setTask(response.data);



                setDesigns(response.data.designs);

                setOrder("preference");

                setPrefDirection("desc");

                sortDesigns("preference", "desc", response.data.designs);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });

    };

    const getTestafterEndTest=()=>{

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            }else{
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then(
                (response) => {
                    setLoading(false);

                    if (response.success) {
                        setTest(response.test);

                        getTestPreferenceTestDataApi();
                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: response.success ? "successtoast" : "errortoast",
                                position: "bottom-center",
                                duration: 2000,
                            }
                        );
                    }
                }
            );
        } else {
            getTestPreferenceTestDataApi();
        }
    };

    const getPlan = () => {

        if (!planType) {
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);

                    getTest();
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: response.success ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getTest();
        }
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Analytics | Preference Test";
        getPlan();
    }, []);
    const renderFullView = () => {
        var designs_render = [];

        var designs_loop = [];

        if (designs.length > 0) {
            designs_loop.push(designs[activeIndex]);
        }
        designs_loop.forEach(function (item, i) {
            designs_render.push(
                <div className="preference-test-design-wrap preference-taking-task-wrap"
                style={{backgroundColor:item.color?item.color:""}}
                >


                    <div className="prefer-design-image-view-hold">
                        <div className="prefer-test-selected-design-view">


                            <>
                                {item.type == "image" &&
                                    <img src={process.env.REACT_APP_IMG_URL + item.design_file} />
                                }
                                {item.type == "video" &&
                                    <video width="320" height="240" controls>
                                        <source src={process.env.REACT_APP_IMG_URL + item.design_file} type="video/mp4" />
                                    </video>
                                }
                            </>


                            <div className="design-max-screen-icon" onClick={() => { setActiveIndex(i); setFullView(true) }}>
                                <img src={process.env.REACT_APP_URL + "images/maxim-icon.svg"} alt="icon" />
                            </div>
                        </div>
                        <div className="test-design-name-and-option-wrap">
                            <h3 className={`h3 prefer-design-name ${(test.language=="ar") ? "cairo-font" : "arabic_wrapper"}`}>
                                <span>
                                    {activeIndex.toString().length == 1 ? "0" + (activeIndex + 1) : (activeIndex + 1)}
                                </span>
                                {item.title}
                            </h3>
                        </div>
                    </div>
                </div>

            );
        });

        return (
            <div className="design-fixed-full-view">
                <div className="prefer-design-slider-hold">
                    <div onClick={() => { setFullView(false) }} className="close-design-modal"><img src={process.env.REACT_APP_URL + "images/close-menu.svg"} alt="icon" /></div>
                    <div className="pref-test-btns-wrap">

                        <div className={`btn prevPage ${activeIndex == 0 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex != 0) { setActiveIndex(activeIndex - 1); } }}>
                            <img src={process.env.REACT_APP_URL + "images/design-slide-prev.png"} alt="icon" />
                        </div>
                        <div className={`btn nextPage ${activeIndex == designs.length - 1 ? 'disabled-nav' : ''}`} onClick={() => { if (activeIndex < designs.length - 1) { setActiveIndex(activeIndex + 1); } }}>
                            <img src={process.env.REACT_APP_URL + "images/design-slide-next.png"} alt="icon" />
                        </div>
                    </div>
                    {designs_render}
                </div>
            </div>
        );

    };

    const callbackTopbar = (data)=>{
        getTest();
        getTestafterEndTest();
    }

    return (
        <LayoutResearcherFullWidth
            fixed_header_target={true}
            isLoading={isLoading}
            wrapClass={"researcher-test-view-overflow overflow-visible"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
        >
            {test && (
                <div className="analyze-results-page-wrapper">
                    <Topbar test={test} callbackTopbar={callbackTopbar}/>

                    <div className="container inner-page-container">
                        <div className="sp5-content-wrap analyze-overflow-wrap">
                            <Sidebar
                                test={test}
                                planType={planType}
                                activeMenu="preference-test"
                            />

                            <div className="sp5-right-side-wrap preference-test-analytics-data">
                                {sectionLoading &&
                                    <LoadingIcon />
                                }
                                {!sectionLoading &&
                                    <div className="bd-question-view-wrap">

                                        <div className="bd-question-top-slide d-flex justify-content-between prefer-test-analy-top-data">
                                            <div className="bd-que-slide-left">
                                                <p className="text-small-info">Task title</p>
                                                <div className="db-que-list-wrap">
                                                    <h3 className={` h3 font-inter ${test.language=='ar'?'cairo-font':''}`}>{task.title}</h3>
                                                </div>
                                                {task.max_design &&
                                                    <div className="tester-prefer-test-name"> <h3 class="h3 font-inter"><img src={process.env.REACT_APP_URL + "images/recommend-icon.svg"} alt="icon" /> <span>It looks like participants prefer <span className={` ${test.language=='ar'?'cairo-font':''}`}>{task.max_design.title}</span>!</span></h3></div>
                                                }
                                            </div>
                                        </div>
                                        <div className="bd-que-table-wrapper">

                                            <div className="bd-que-table-search-wrap mb-32">
                                                <div className="bd-question-search-left">
                                                    <span className="text-small-info">{task.total_answers} answer{task.total_answers == 1 ? '' : 's'} </span>
                                                    <div className="bd-question-radio-data">
                                                        <div className="checkboxes radio_btn_class  d-flex">
                                                            <Radiobtn isSelectedlabelBold={true} id={"section-aggregated"} name="section" value={"Aggregated"} checked={section == "Aggregated" ? true : false} onChange={() => { setSection("Aggregated") }} label={"Aggregated data"} />
                                                            <Radiobtn isSelectedlabelBold={true} id={"section-user"} name="section" value={"User"} checked={section == "User" ? true : false} onChange={() => { setSection("User") }} label={"By user"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bd-que-ans-table-data-inner bd-que-aggregated-table prefer-anayl-data-table">
                                            {section == "Aggregated" &&
                                                <div className="table-responsive"> 
                                                    {fullView &&
                                                        <>{renderFullView()}</>
                                                    }
                                                    <table className="prefer-aggregatd-data-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Design preview</th>
                                                                <th>Design name</th>
                                                                <th
                                                                    onClick={() => {
                                                                        setOrder("preference");

                                                                        setPrefDirection(pref_direction == "asc" ? "desc" : "asc");

                                                                        sortDesigns("preference", pref_direction == "asc" ? "desc" : "asc");
                                                                    }}
                                                                >
                                                                <div className={`${order=="preference" ? pref_direction: '' } col-sort justify-content-between`}>
                                                                    Preference %
                                                                    {order == "preference" && pref_direction == "asc" &&
                                                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                                                    }
                                                                    {order == "preference" && pref_direction == "desc" &&
                                                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                                                    }
                                                                    {order!="preference"  &&
                                                                    <span>
                                                                        
                                                                        <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                                                    </span>
                                                                    }
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    onClick={() => {
                                                                        setOrder("votes");

                                                                        setVotesDirection(votes_direction == "asc" ? "desc" : "asc");

                                                                        sortDesigns("votes", votes_direction == "asc" ? "desc" : "asc");
                                                                    }}
                                                                >
                                                                 <div className={`${order=="votes" ? votes_direction: '' } col-sort justify-content-between`}>
                                                                    Votes
                                                                    {order == "votes" && votes_direction == "asc" &&
                                                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                                                    }
                                                                    {order == "votes" && votes_direction == "desc" &&
                                                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                                                    }
                                                                    {order!="votes"  &&
                                                                    <span>
                                                                        <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                                                    </span>
                                                                    }
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    onClick={() => {
                                                                        setOrder("time_spent");

                                                                        setTimeDirection(time_direction == "asc" ? "desc" : "asc");

                                                                        sortDesigns("time_spent", time_direction == "asc" ? "desc" : "asc");
                                                                    }}
                                                                >
                                                                <div className={`${order=="time_spent" ? time_direction: '' } col-sort justify-content-between`}>
                                                                    Time spent
                                                                    {order == "time_spent" && time_direction == "asc" &&
                                                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                                                    }
                                                                    {order == "time_spent" && time_direction == "desc" &&
                                                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                                                    }
                                                                    {order!="time_spent"  &&
                                                                    <span>
                                                                        
                                                                        <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                                                    </span>
                                                                    }
                                                                    </div>    
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {designs.map(function (item, index) {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <div className="preference-test-thum-preview">


                                                                                <div className="prefer-design-image-view-hold">
                                                                                    <div className="prefer-test-selected-design-view">
                                                                                        {item.type == "video" &&
                                                                                            <div className="video-design-icon">
                                                                                                <img src={process.env.REACT_APP_URL + "images/video-play-btn.svg"} alt="icon" />
                                                                                            </div>
                                                                                        }
                                                                                        {/* <img src={process.env.REACT_APP_IMG_URL + item.thumb} /> */}
                                                                                        <img src={process.env.REACT_APP_IMG_URL + item.thumb} />
                                                                                        <div className="design-max-screen-icon" onClick={() => { setActiveIndex(index); setFullView(true) }}>
                                                                                            <img src={process.env.REACT_APP_URL + "images/maxim-icon.svg"} alt="icon" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className={` ${test.language=='ar'?'cairo-font':''}`}>
                                                                            <b>{item.title}</b>
                                                                        </td>
                                                                        <td>
                                                                            <b>{task.total_answers > 0 ? Math.round(((item.votes / task.total_answers) * 100) * 100) / 100 : "0"}%</b>
                                                                        </td>
                                                                        <td>
                                                                            {item.votes}
                                                                        </td>
                                                                        <td>
                                                                            <b>{calculateTime(item.time_spent)}</b>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }

                                            {section == "User" && test &&
                                                <div>
                                                    <TaskAttempts test_id={test && test.id} test={test} />
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </LayoutResearcherFullWidth>
    );
}
