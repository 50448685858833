
//import { ReactSession } from 'react-client-session';
import { ReactSession } from "../../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import { addNewPaymentMethodService, getPaymentMethodsService, deletePaymentMethodService, setDefaultCard } from '../../../services/payment_methods';
import toast from 'react-hot-toast';
import Cleave, { propTypes } from 'cleave.js/react';
import validator from 'validator'
import ConfirmModal from "../../dialog/confirm_modal";
import { encryptClient } from "../../../lib/helpers";


export default function PaymentMethods(props) {

    ReactSession.setStoreType("localStorage");
    let user = ReactSession.get("user");

    const [isLoading, setLoading] = useState(false);

    const [cardFormLoading, setCardFormLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState({ open: false, target_id: null });

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [addNewCard, setAddNewCard] = useState(false);

    const [formValues, setFormValues] = useState({ "card_no": "", "card_name": "", "exp_date": "", "cvv": "", "payment_method_id": '' });

    const [formErrors, setFormErrors] = useState({ "card_no": null, "card_name": null, "expiration_date": null, "cvv": null, "error_class": null });

    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
            }, 1000)

        }
    };
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));



    };
    const validateCreditCard = (value) => {

        if (!validator.isCreditCard(value)) {

            showFormError('card_no', 'Enter valid Credit Card Number!')
        } else {
            showFormError('card_no', null)
        }
    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    }
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal['error_class'] = 'input_error';

        setFormErrors(formErrorsLocal);

    }
    const setDefaultCardValue = (value) => {

        let token = ReactSession.get("token");
        var data = new FormData();
        data.append('stripe_id', value);
        setDefaultCard(data, token).then(response => {

            if (response.success) {
                getPaymentMethods();

            } else {
                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });

    }
    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method) {
            render.push((
                <div key={"methods" + method.id} className="checkboxes radio_btn_class gender_btn d-flex card-radio-wrap">
                    <div className="card-left-side">
                        <label htmlFor={method.stripe_payment_method_id} className="mt-0">
                            {props.showRadioBtns &&
                                <>
                                    <input id={method.stripe_payment_method_id} type="radio" checked={formValues.payment_method_id == method.id ? true : false} onChange={() => { setFormValue("payment_method_id", method.id); propTypes.onChange(method.id); }} name="payment_method" value={method.stripe_payment_method_id} className="hidden radio_btn_input" required="required" />
                                    <span>
                                        <img className="empty-fill-icon" src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"} />
                                        <img className="fill-icon" src={process.env.REACT_APP_URL + "images/pol-fill.svg"} />
                                    </span>
                                </>
                            }
                            <p><img src={process.env.REACT_APP_URL + "images/" + method.brand + "-icon.svg"} /> {method.brand} ending in *{method.last_4} <b>{method.card_name}</b></p>
                        </label>
                        {method.default == 1 &&
                            <span className="primary-card">Primary</span>
                        }
                    </div>
                    {user.permission != "Editor" && (
                        <div className="card-right-side">
                            {method.default == 0 &&
                                <span className="make-primary-card" onClick={() => { setDefaultCardValue(method.stripe_payment_method_id) }}>Make primary</span>
                            }
                            <span className="delete-account-btn" onClick={() => { setConfirmModal({ open: true, target_id: method.id, confirm_btn_title: "Delete", confirm_title: "Delete Card", confirm_message: "Are you sure you want to delete this card?" }) }}> <img src={process.env.REACT_APP_URL + "images/trash.svg"} alt="trash-icon" /></span>
                        </div>)}
                </div>
            ))
        })
        if (render.length > 0) {
            return render;
        } else {
            return (<div className='no_payment_add'>No payment methods added.</div>);
        }

    }
    const getPaymentMethods = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPaymentMethodsService(token).then(response => {
            setLoading(false);

            if (response.success) {

                setPaymentMethods(response.payment_methods);

            } else {

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });
    }
    const deletePaymentMethod = (id) => {

        if (formValues.payment_method_id == id) {

            var id = toast((
                <div className='toastinner'>
                    You cannot delete selected payment method.
                    <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                </div>), {
                className: 'errortoast',
                position: 'bottom-center'
            });
            return;
        }
        let token = ReactSession.get("token");

        setLoading(true);


        deletePaymentMethodService({ "id": id }, token).then(response => {
            setLoading(false);

            if (response.success) {

                getPaymentMethods();

            } else {

                var id = toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });
    }


    useEffect(function () {
        getPaymentMethods();

        return () => {
            setLoading(false);
        };
    }, []);

    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        if (formValues.card_name == "") {
            form_errors = {
                ...form_errors,
                card_name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cardholder-name" : firsterrorid;
        }
        if (formValues.exp_date == "") {
            form_errors = {
                ...form_errors,
                exp_date: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }

        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };

    const addNewPaymentMethod = () => {


        resetFormErrors();


        if (!cardFormLoading) {

            var error = false;

            //  resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setCardFormLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token)
                    .then(response => {

                        setCardFormLoading(false);

                        if (response.success) {

                            setFormValue("card_name", "");
                            setFormValue("card_no", "");
                            setFormValue("cvv", "");
                            setFormValue("exp_date", "");

                            getPaymentMethods();

                            setAddNewCard(false);

                            window.setLabels();
                        } else {
                            var id = toast((
                                <div className='toastinner'>
                                    {response.message}
                                    <a onClick={() => { toast.dismiss(id); }}>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });
                        }
                    })
            }
        }
    }


    return (
        <>
        {props.forSection && props.forSection=="publish_test" &&
        <>
        <div className="targer-group-left-wrap">
            <h3>Payment methods</h3>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            {renderPaymentMethods()}
                            {user.permission != "Editor" && (
                                <>
                                    {!addNewCard && (
                                        <div
                                            className="add-new-card-option mb-0"
                                            onClick={() => {
                                                setAddNewCard(true);
                                            }}
                                        >
                                            <div className="create-btn add-new-card-btn">
                                                Add new card{" "}
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/plus-white.svg"
                                                        }
                                                    />
                                                </span>{" "}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {addNewCard == true && (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <Cleave
                                                        id="card-number"
                                                        options={{ creditCard: true }}
                                                        onChange={set("card_no")}
                                                        className={`form-control ${formErrors.card_no != null
                                                                ? formErrors.error_class
                                                                : ""
                                                            } `}
                                                        value={formValues.card_no}
                                                        onKeyUp={(e) => {
                                                            validateCreditCard(e.target.value);
                                                        }}
                                                    />
                                                    <label htmlFor="card-number">Card number</label>
                                                    <span
                                                        className="clear"
                                                        onClick={clearFormValue("card_no")}
                                                    >
                                                        <svg viewBox="0 0 24 24">
                                                            <path className="line" d="M2 2L22 22" />
                                                            <path className="long" d="M9 15L20 4" />
                                                            <path className="arrow" d="M13 11V7" />
                                                            <path className="arrow" d="M17 11H13" />
                                                        </svg>
                                                    </span>
                                                    {formErrors.card_no != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.card_no}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <input
                                                        type="text"
                                                        id="cardholder-name"
                                                        className={`form-control ${formErrors.card_name != null
                                                                ? formErrors.error_class
                                                                : ""
                                                            } `}
                                                        value={formValues.card_name}
                                                        onChange={set("card_name")}
                                                    />
                                                    <label htmlFor="cardholder-name">
                                                        Cardholder’s name
                                                    </label>
                                                    <span
                                                        className="clear"
                                                        onClick={clearFormValue("card_name")}
                                                    >
                                                        <svg viewBox="0 0 24 24">
                                                            <path className="line" d="M2 2L22 22" />
                                                            <path className="long" d="M9 15L20 4" />
                                                            <path className="arrow" d="M13 11V7" />
                                                            <path className="arrow" d="M17 11H13" />
                                                        </svg>
                                                    </span>
                                                    {formErrors.card_name != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.card_name}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <Cleave
                                                        id="expiration-date"
                                                        options={{
                                                            date: true,
                                                            datePattern: ["m", "y"],
                                                        }}
                                                        onChange={set("exp_date")}
                                                        className={`form-control ${formErrors.exp_date != null
                                                                ? formErrors.error_class
                                                                : ""
                                                            } `}
                                                        value={formValues.exp_date}
                                                    />
                                                    <label htmlFor="expiration-date">
                                                        Expiration date mm/yy
                                                    </label>
                                                    <span
                                                        className="clear"
                                                        onClick={clearFormValue("exp_date")}
                                                    >
                                                        <svg viewBox="0 0 24 24">
                                                            <path className="line" d="M2 2L22 22" />
                                                            <path className="long" d="M9 15L20 4" />
                                                            <path className="arrow" d="M13 11V7" />
                                                            <path className="arrow" d="M17 11H13" />
                                                        </svg>
                                                    </span>
                                                    {formErrors.exp_date != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.exp_date}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <div className="position-relative w-100">
                                                        <Cleave
                                                            id="cvv"
                                                            options={{
                                                                blocks: [3],
                                                                numericOnly: true,
                                                            }}
                                                            onChange={set("cvv")}
                                                            className={`form-control ${formErrors.exp_date != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                } `}
                                                            value={formValues.cvv}
                                                        />
                                                        <label className="control-label" htmlFor="cvv">
                                                            CVV/CVC
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue("cvv")}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path className="line" d="M2 2L22 22" />
                                                                <path className="long" d="M9 15L20 4" />
                                                                <path className="arrow" d="M13 11V7" />
                                                                <path className="arrow" d="M17 11H13" />
                                                            </svg>
                                                        </span>
                                                        {formErrors.cvv != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.cvv}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="d-flex justify-content-end cvv-holder">
                                                        <span tooltip="CVV is the last three digits on the back of your credit card.">
                                                            What is cvv/cvc?{" "}
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/info-icon.svg"
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-12">
                                            <div className="flex-buttons-wrap justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn form-btn secondary-btn"
                                                    onClick={() => {
                                                        setFormValue("card_name", "");
                                                        setFormValue("card_no", "");
                                                        setFormValue("cvv", "");
                                                        setFormValue("exp_date", "");
                                                        resetFormErrors();
                                                        setAddNewCard(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn form-btn"
                                                    onClick={addNewPaymentMethod}
                                                >
                                                    Save
                                                    {cardFormLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="target-group-info-repeat">
            <h3>Payment methods</h3>
            <p>
                Save time on your next purchase by saving your preferred payment method. 
            </p>
        </div>
        </>
        }
        {!props.forSection && 
        <div className="profile-form-repeat">
            <div className="row">
                <div className="col-md-4">
                    <div className="form-info-text">
                        <h3>Your payment methods</h3>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12">
                            {renderPaymentMethods()}
                            {user.permission != "Editor" && (
                                <>
                                    {!addNewCard && (
                                        <div
                                            className="add-new-card-option mb-0"
                                            onClick={() => {
                                                setAddNewCard(true);
                                            }}
                                        >
                                            <div className="create-btn add-new-card-btn">
                                                Add new card{" "}
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/plus-white.svg"
                                                        }
                                                    />
                                                </span>{" "}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {addNewCard == true && (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <Cleave
                                                        id="card-number"
                                                        options={{ creditCard: true }}
                                                        onChange={set("card_no")}
                                                        className={`form-control ${formErrors.card_no != null
                                                                ? formErrors.error_class
                                                                : ""
                                                            } `}
                                                        value={formValues.card_no}
                                                        onKeyUp={(e) => {
                                                            validateCreditCard(e.target.value);
                                                        }}
                                                    />
                                                    <label htmlFor="card-number">Card number</label>
                                                    <span
                                                        className="clear"
                                                        onClick={clearFormValue("card_no")}
                                                    >
                                                        <svg viewBox="0 0 24 24">
                                                            <path className="line" d="M2 2L22 22" />
                                                            <path className="long" d="M9 15L20 4" />
                                                            <path className="arrow" d="M13 11V7" />
                                                            <path className="arrow" d="M17 11H13" />
                                                        </svg>
                                                    </span>
                                                    {formErrors.card_no != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.card_no}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <input
                                                        type="text"
                                                        id="cardholder-name"
                                                        className={`form-control ${formErrors.card_name != null
                                                                ? formErrors.error_class
                                                                : ""
                                                            } `}
                                                        value={formValues.card_name}
                                                        onChange={set("card_name")}
                                                    />
                                                    <label htmlFor="cardholder-name">
                                                        Cardholder’s name
                                                    </label>
                                                    <span
                                                        className="clear"
                                                        onClick={clearFormValue("card_name")}
                                                    >
                                                        <svg viewBox="0 0 24 24">
                                                            <path className="line" d="M2 2L22 22" />
                                                            <path className="long" d="M9 15L20 4" />
                                                            <path className="arrow" d="M13 11V7" />
                                                            <path className="arrow" d="M17 11H13" />
                                                        </svg>
                                                    </span>
                                                    {formErrors.card_name != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.card_name}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <Cleave
                                                        id="expiration-date"
                                                        options={{
                                                            date: true,
                                                            datePattern: ["m", "y"],
                                                        }}
                                                        onChange={set("exp_date")}
                                                        className={`form-control ${formErrors.exp_date != null
                                                                ? formErrors.error_class
                                                                : ""
                                                            } `}
                                                        value={formValues.exp_date}
                                                    />
                                                    <label htmlFor="expiration-date">
                                                        Expiration date mm/yy
                                                    </label>
                                                    <span
                                                        className="clear"
                                                        onClick={clearFormValue("exp_date")}
                                                    >
                                                        <svg viewBox="0 0 24 24">
                                                            <path className="line" d="M2 2L22 22" />
                                                            <path className="long" d="M9 15L20 4" />
                                                            <path className="arrow" d="M13 11V7" />
                                                            <path className="arrow" d="M17 11H13" />
                                                        </svg>
                                                    </span>
                                                    {formErrors.exp_date != null && (
                                                        <span className={formErrors.error_class}>
                                                            {formErrors.exp_date}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-form-group">
                                                <div className="input form-group input-field">
                                                    <div className="position-relative w-100">
                                                        <Cleave
                                                            id="cvv"
                                                            options={{
                                                                blocks: [3],
                                                                numericOnly: true,
                                                            }}
                                                            onChange={set("cvv")}
                                                            className={`form-control ${formErrors.exp_date != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                } `}
                                                            value={formValues.cvv}
                                                        />
                                                        <label className="control-label" htmlFor="cvv">
                                                            CVV/CVC
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue("cvv")}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path className="line" d="M2 2L22 22" />
                                                                <path className="long" d="M9 15L20 4" />
                                                                <path className="arrow" d="M13 11V7" />
                                                                <path className="arrow" d="M17 11H13" />
                                                            </svg>
                                                        </span>
                                                        {formErrors.cvv != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.cvv}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="d-flex justify-content-end cvv-holder">
                                                        <span tooltip="CVV is the last three digits on the back of your credit card.">
                                                            What is cvv/cvc?{" "}
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/info-icon.svg"
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-md-12">
                                            <div className="flex-buttons-wrap justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn form-btn secondary-btn"
                                                    onClick={() => {
                                                        setFormValue("card_name", "");
                                                        setFormValue("card_no", "");
                                                        setFormValue("cvv", "");
                                                        setFormValue("exp_date", "");
                                                        resetFormErrors();
                                                        setAddNewCard(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn form-btn"
                                                    onClick={addNewPaymentMethod}
                                                >
                                                    Save
                                                    {cardFormLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        
        <ConfirmModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title="Delete"
                confirm_title="Delete Card"
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deletePaymentMethod(confirmModal.target_id);
                    if (formValues.payment_method_id == confirmModal.target_id) {
                        setFormValue("payment_method_id", "");
                    }
                }}
            />
        </>
    );
}