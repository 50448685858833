import { useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout.js";
import { getLiveTestService } from "./../../services/test";
import { sendFeedback, closeFeedback } from "./../../services/test";
import Radiobtn from "../layouts/elements/fields/radiobtn.js";
import { Dropdown } from "react-bootstrap";
import TesterTestFrame from "../tests/elements/tester_test_frame.js";
import TextAreaField from "../layouts/elements/fields/textarea.js";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import toast from "react-hot-toast";
import { encryptId } from "../../lib/helpers.js";

export default function TesterDashboard() {
    const [isLoading, setLoading] = useState(false);
    const [testsLoading, setTestsLoading] = useState(false);
    const [tests, setTests] = useState([]);
    const [filter, setFilter] = useState({
        location: "",
        duration: "",
        language: "",
        sort: "Newly",
    });
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [isActiveOpinion, setActiveOpinion] = useState(false);
    const [settings, setSettings] = useState(null);
    const [isActiveRating, setActiveRating] = useState(true);
    const [isActiveRatingStep2, setActiveRatingStep2] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formValues, setFormValues] = useState({ rating: 3, opinion: "", issues:[] })
    const [formErrors, setFormErrors] = useState({ opinion: null, error_class: "" });
    

    const [feedbackIssues, setFeedbackIssues] = useState([]);

    ReactSession.setStoreType("localStorage");
    const navigate = useNavigate();
    const logout = (e) => {
        e.preventDefault();
        ReactSession.set("token", null);
        ReactSession.set("user", null);
        navigate("/tester/login");
    };

    const renderTests = () => {
        var render = [];
        if (!testsLoading && tests.length > 0) {
            tests.forEach(function (test) {
                render.push(
                    <TesterTestFrame
                        key={"Testframe" + test.id}
                        settings={settings}
                        test={test}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/t/" + encryptId(test.id, test));
                        }}
                    />
                );
            });
        } else {
            for (var i = 0; i < 9; i++) {
                render.push(
                    <TesterTestFrame
                        key={"Testframe" + i}
                        settings={settings}
                        test={null}
                        testsLoading={testsLoading}

                    />
                );
            }
        }
        return (
            <div className="das-ongoing-test-wrap similar-test-data-inner  tester-dashboard-test-wrap mt-64 mb-64">
                <div className="page-min-height">
                    <h2 className="h2">
                        {testsLoading ?
                            <Skeleton width={'30%'} />
                            :
                            <>
                                {tests.length} {tests.length < 2 ? "test" : "tests"} available for
                                you{" "}
                            </>
                        }



                        {/*{testsLoading == true && (*/}
                        {/*  <i*/}
                        {/*    className="fa fa-spinner fa-spin"*/}
                        {/*    aria-hidden="true"*/}
                        {/*    style={{ marginLeft: "5px" }}*/}
                        {/*  ></i>*/}
                        {/*)}*/}
                    </h2>
                    <div className="project-das-data">{render}</div>
                </div>
            </div>
        );
    };

    const getLiveTests = () => {
        let token = ReactSession.get("token");

        // setLoading(true);
        setTestsLoading(true);
        var data = {};

        data["sort"] = filter.sort;

        data["language"] = filter.language;

        data["duration"] = filter.duration;

        data["location"] = filter.location;

        getLiveTestService(data, token)
            .then((response) => {
                setLoading(false);
                setTestsLoading(false);

                if (response.success) {
                    setTests(response.tests);
                    setSettings(response.settings);
                    if(response.is_npa==1){

                            setTimeout(function () {
                                        setActiveRating(false);
                                    }, 1000)
                        // setActiveRating(false)
                    }
                } else {
                    let message = response.message;
                    if (response.message == "Unauthenticated.") {
                        message = "It seems your session has been expired, kindly logout and log in again";
                    }
                    var id = toast(
                        <div className="toastinner">
                            {message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            })
            .catch(function (err) {
                var id = toast(
                    <div className="toastinner">
                        {err.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
                console.error(` Err: ${err}`);
            });
    };


    useEffect(() => {
        getLiveTests();
    }, [filter]);

    const setRating = (e) => {
        setFormValues((oldValues) => ({ ...oldValues, "rating": e.target.value }));
        setActiveOpinion(true);
    }

    const submitFeedback = () => {



        setFormErrors({ opinion: "", error_class: "" });
        if (formValues.opinion === '') {
            setFormErrors({ opinion: "Required field", error_class: "input_error" });
            return false;
        }

        setFeedbackLoading(true);
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("rating", formValues.rating);
        data.append("opinion", formValues.opinion);
        data.append("issues", feedbackIssues);
        // data.append("test_id", test_id);



        sendFeedback(data, token).then((response) => {
            setFeedbackLoading(false);

            if (response.success) {
                /*setTimeout(function () {
                    setActiveRating(true);
                },5000)

                setSuccessMessage(response.message);
                setActiveOpinion(false)*/

                setActiveRating(true);
                setActiveRatingStep2(false);
                toast(
                    <div className="toastinner">
                        <p className="feedback-success-message-wrap mb-0 mt-0"><img
                            src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' /> {response.message}</p>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const cancelFeedback = () => {

        const token = ReactSession.get("token");
        const data = new FormData();

        closeFeedback(data, token).then((response) => {
            setActiveRating(true);
            setActiveRatingStep2(false);

            if (response.success) {
                console.log(response.message);
            } else {
                console.log(response.message);
            }
        });

    }

    const getFastClass = (name) => {
        let issues = formValues.issues;

        let check = true;

            let exist = false;
            issues.forEach(function (item) {
                if (item == name) {
                    exist = true;
                }
            })
            if (!exist) {
                check = false;
            }
        if (check) {
            return 'active';
        }
        return '';
    }
    const selectFast = (name) => {

        return (e) => {

            let issues = [...formValues.issues];

            if (getFastClass(name) != 'active') {

                    let exist = false;
                    issues.forEach(function (item) {
                        if (item == name) {
                            exist = true;
                        }
                    })
                    if (!exist) {
                        issues.push(name);
                    }
            }
            if (getFastClass(name) == 'active') {

                    var exist = -1;
                    issues.forEach(function (item, index) {
                        if (item == name) {
                            exist = index;
                        }
                    })
                    if (exist != -1) {
                        issues.splice(exist, 1);
                    }

            }
            setFormValues({...formValues,'issues': issues});
            setFeedbackIssues([...issues]);

            return;
        }

    }

    return (
        <Layout isLoading={false} skipCheck={false}>
            <div className="dashboard-data-wrapper">
                <h1 className="page-main-heading dashboard-page-heading">
                    Browse tests
                    <div className="headmobile-border-wrap"></div>
                </h1>
                <div className="page-filter-wraper">
                    <div className="filter-left-side filter-data-wrap">
                        <span className="filter-heading">Filters:</span>
                        <div className="username-drop-down filter-dropdown-wrap small-dropdown">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdownDurationMenuButton"
                                    className="dropdown-toggle"
                                >
                                    {filter.location == "" ? (
                                        "Location"
                                    ) : (
                                        <div className="selected-filter-pink">
                                            {filter.location}
                                        </div>
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.location == "Remote") {
                                                setFilter({ ...filter, location: "" });
                                            } else {
                                                setFilter({ ...filter, location: "Remote" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        Remote
                                        {filter.location == "Remote" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.location == "Dubai Office") {
                                                setFilter({ ...filter, location: "" });
                                            } else {
                                                setFilter({ ...filter, location: "Dubai Office" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        Dubai Office
                                        {filter.location == "Dubai Office" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();

                                            setFilter({ ...filter, location: "" });
                                        }}
                                        className="profile-link"
                                    >
                                        All
                                        {filter.location == "" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="username-drop-down filter-dropdown-wrap small-dropdown">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdownDurationMenuButton"
                                    className="dropdown-toggle"
                                >
                                    {filter.duration == "" ? (
                                        "Duration"
                                    ) : filter.duration == "short" ? (
                                        <div className="selected-filter-pink">Short</div>
                                    ) : filter.duration == "medium" ? (
                                        <div className="selected-filter-pink">Medium</div>
                                    ) : (
                                        <div className="selected-filter-pink">Long</div>
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.duration == "short") {
                                                setFilter({ ...filter, duration: "" });
                                            } else {
                                                setFilter({ ...filter, duration: "short" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        Short
                                        {filter.duration == "short" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.duration == "medium") {
                                                setFilter({ ...filter, duration: "" });
                                            } else {
                                                setFilter({ ...filter, duration: "medium" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        Medium
                                        {filter.duration == "medium" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.duration == "long") {
                                                setFilter({ ...filter, duration: "" });
                                            } else {
                                                setFilter({ ...filter, duration: "long" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        Long
                                        {filter.duration == "long" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();

                                            setFilter({ ...filter, duration: "" });
                                        }}
                                        className="profile-link"
                                    >
                                        All
                                        {filter.duration == "" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="username-drop-down filter-dropdown-wrap small-dropdown">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdownLangMenuButton"
                                    className="dropdown-toggle"
                                >
                                    {filter.language == "" ? (
                                        "Language"
                                    ) : filter.language == "en" ? (
                                        <div className="selected-filter-pink">English</div>
                                    ) : (
                                        <div className="selected-filter-pink">Arabic</div>
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.language == "en") {
                                                setFilter({ ...filter, language: "" });
                                            } else {
                                                setFilter({ ...filter, language: "en" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        English
                                        {filter.language == "en" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (filter.language == "ar") {
                                                setFilter({ ...filter, language: "" });
                                            } else {
                                                setFilter({ ...filter, language: "ar" });
                                            }
                                        }}
                                        className="profile-link"
                                    >
                                        Arabic
                                        {filter.language == "ar" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            e.preventDefault();

                                            setFilter({ ...filter, language: "" });
                                        }}
                                        className="profile-link"
                                    >
                                        All
                                        {filter.language == "" && (
                                            <span className="fa fa-check"></span>
                                        )}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {(filter.location != "" ||
                            filter.duration != "" ||
                            filter.language != "") && (
                                <span className="filter-reset-btn-hold">
                                    <button
                                        className="reset-filter"
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFilter({
                                                ...filter,
                                                language: "",
                                                duration: "",
                                                location: "",
                                            });
                                        }}
                                    >
                                        {" "}
                                        <i className="fa fa-repeat" aria-hidden="true"></i>
                                        Reset filters
                                    </button>
                                </span>
                            )}
                    </div>
                    <div className="filter-right-side filter-data-wrap">
                        <span className="filter-heading">Sort by:</span>
                        <div className="checkboxes radio_btn_class gender_btn  d-flex">
                            <Radiobtn
                                bigLabel={filter.sort == "Newly" ? true : false}
                                label={"Latest"}
                                onChange={() => {
                                    setFilter({ ...filter, sort: "Newly" });
                                }}
                                id={"Newly"}
                                name={"sortby"}
                                labelClass="mt-0"
                                value={"Newly"}
                                checked={filter.sort == "Newly" ? true : false}
                            />
                            <Radiobtn
                                bigLabel={filter.sort == "Closing soon" ? true : false}
                                label={"Closing soon"}
                                onChange={() => {
                                    setFilter({ ...filter, sort: "Closing soon" });
                                }}
                                id={"Closing soon"}
                                name={"sortby"}
                                labelClass="mt-0"
                                value={"Closing soon"}
                                checked={filter.sort == "Closing soon" ? true : false}
                            />
                        </div>
                    </div>
                </div>
                <SkeletonTheme baseColor="#dae5f1" highlightColor="#ebf4ff">
                    <div className="tester-dashboard-wrapper">


                        {tests.length == 0 && !testsLoading ? (
                            <div className="dashboard-inner-data-wrap page-min-height">
                                <h2>
                                    There are no live tests available right now.
                                </h2>
                                <p className="page-small-info-text mt-32 mb-32">Keep checking back as there are always new tests being launched. </p>
                            </div>
                        ) : (
                            renderTests()
                        )}

                        {!testsLoading && (

                        <>

                        <div className={`live-feedback-modal-wrap ${isActiveRating ? 'd-none ' : 'fadeIn'}`}>
                            <div className='live-feedback-modal-inner-data'>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => cancelFeedback()}
                                >
                                    <span aria-hidden="true">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="cross"
                                        />
                                    </span>
                                </button>

                                <div className='live-modal-step'>
                                    <p>Step 1 of 2</p>
                                </div>
                                <div className='live-modal-top-heading'>
                                    <p>Your feedback helps make UserQ the best it can be.<br />
                                        How likely are you to recommend UserQ to a friend, colleague or peer?</p>
                                </div>

                                <div className='live-test-feedback-type-wrap'>
                                    {(formValues.rating == 1) &&
                                        <div className='select-feedback-type note-sure-option not-likely step1'>
                                            <span>Very Unlikely</span>
                                            <img src={process.env.REACT_APP_URL + "images/not-like-face.svg"} alt="img" />
                                        </div>
                                    }

                                    {(formValues.rating == 2) &&
                                        <div className='select-feedback-type note-sure-option not-likely step2'>
                                            <span>Unlikely</span>
                                            <img src={process.env.REACT_APP_URL + "images/not-like-face.svg"} alt="img" />
                                        </div>
                                    }



                                    {formValues.rating == 3 &&
                                        <div className='select-feedback-type note-sure-option not-sure step3'>
                                            <span>Neutral</span>
                                            <img src={process.env.REACT_APP_URL + "images/neutral-face.svg"} alt="img" />
                                        </div>
                                    }


                                    {(formValues.rating == 4) &&
                                        <div className='select-feedback-type note-sure-option will-definitely step4'>
                                            <span>Likely</span>
                                            <img src={process.env.REACT_APP_URL + "images/recommend-icon.svg"} alt="img" />
                                        </div>
                                    }

                                    {(formValues.rating == 5) &&
                                        <div className='select-feedback-type note-sure-option will-definitely step5'>
                                            <span>Very likely</span>
                                            <img src={process.env.REACT_APP_URL + "images/recommend-icon.svg"} alt="img" />
                                        </div>
                                    }

                                </div>

                                <div className="target-group-col-data">
                                    <input type="range" value={formValues.rating} min={1} max={5} onChange={(e) => setRating(e)} />
                                </div>

                                
                                    <>
                                        <div className='share-feedback-option-for-live-test'>

                                            <div className='d-flex justify-content-center'>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setActiveRatingStep2(true)
                                                        setActiveRating(true);
                                                        // setTimeout(function () {
                                                        //     setActiveRatingStep2(true)
                                                        // }, 200);
                                                    }}
                                                    className="button primary-btn ml-0 mr-0 mt-64">
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                


                                {successMessage &&
                                    <p className="feedback-success-message-wrap mb-0"> <img src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' />  {successMessage}</p>
                                }
                            </div>
                        </div>

                        <div className={`live-feedback-modal-wrap ${(formValues.rating == 5) && isActiveRatingStep2 ? '' : 'd-none'}`}>
                            <div className='live-feedback-modal-inner-data'>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => cancelFeedback()}
                                >
                                    <span aria-hidden="true">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="cross"
                                        />
                                    </span>
                                </button>

                                <div className='live-modal-step'>
                                    <p>Step 2 of 2</p>
                                </div>

                                <div className='live-modal-top-heading'>
                                    <p>We are really pleased to know you love UserQ.<br />
                                        What is the most important reason you would recommend us?</p>
                                </div>
                                    <>
                                        <div className='share-feedback-option-for-live-test'>
                                            <TextAreaField
                                                error={formErrors.opinion}
                                                error_class={formErrors.error_class}
                                                onChange={(e) => {
                                                    setFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    "opinion": e.target.value
                                                    }));
                                                    setFormErrors((oldValues) =>({...oldValues, "opinion": null, "error_class":null}));
                                                }}
                                                value={formValues.opinion}
                                                maxLength={350}
                                                autosize={true}
                                                type="text"
                                                label={"Share your opinion"} />

                                            <div className='d-flex justify-content-center'>
                                                <button type="submit" onClick={() => submitFeedback()} className="button primary-btn ml-0 mr-0 mt-64">
                                                    Send Feedback
                                                    {feedbackLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                


                                {successMessage &&
                                    <p className="feedback-success-message-wrap mb-0"> <img src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' />  {successMessage}</p>
                                }
                            </div>
                        </div>

                        <div className={`live-feedback-modal-wrap ${(formValues.rating < 5) && isActiveRatingStep2 ? '' : 'd-none'}`}>
                            <div className='live-feedback-modal-inner-data'>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => { cancelFeedback() }}
                                >
                                    <span aria-hidden="true">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="cross"
                                        />
                                    </span>
                                </button>
                                <div className='live-modal-step'>
                                    <p>Step 2 of 2</p>
                                </div>
                                <div className='live-modal-top-heading'>
                                    <p>Is there anything we can improve?</p>
                                </div>

                                <div className="input-field static-label mb-4 mt-4">
                                    <div className="mobile-apps-list-wrap nps-chips">
                                        <span
                                            className={getFastClass("Interface & Navigation")}
                                            onClick={selectFast("Interface & Navigation")}
                                        >
                                            INTERFACE & NAVIGATION
                                        </span>
                                        <span
                                            className={getFastClass("Taking the test experience")}
                                            onClick={selectFast("Taking the test experience")}
                                        >
                                            TAKING THE TEST EXPERIENCE
                                        </span>
                                        <span
                                            className={getFastClass("Technical issues")}
                                            onClick={selectFast("Technical issues")}
                                        >
                                            TECHNICAL ISSUES
                                        </span>
                                        <span
                                            className={getFastClass("Payouts & Rewards")}
                                            onClick={selectFast("Payouts & Rewards")}
                                        >
                                            PAYOUTS & REWARDS
                                        </span>
                                        <span
                                            className={getFastClass("Other")}
                                            onClick={selectFast("Other")}
                                        >
                                            OTHER
                                        </span>
                                    </div>
                                </div>

                                
                                    <>
                                        <div className='share-feedback-option-for-live-test'>
                                            <TextAreaField
                                                error={formErrors.opinion}
                                                error_class={formErrors.error_class}
                                                onChange={(e) => {
                                                    setFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    "opinion": e.target.value
                                                    }));
                                                    setFormErrors((oldValues) =>({...oldValues, "opinion": null, "error_class":null}));
                                                }}
                                                value={formValues.opinion}
                                                maxLength={350}
                                                autosize={true}
                                                type="text"
                                                label={"Share your opinion"} />

                                            <div className='d-flex justify-content-center'>
                                                <button type="submit" onClick={() => submitFeedback()} className="button primary-btn ml-0 mr-0 mt-64">
                                                    Send Feedback
                                                    {feedbackLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                


                                {successMessage &&
                                    <p className="feedback-success-message-wrap mb-0"> <img src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' />  {successMessage}</p>
                                }
                            </div>
                        </div>

                        </>

                        )}

                    </div>
                </SkeletonTheme>
            </div>
        </Layout>
    );
}
