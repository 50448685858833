import React, { useState, useEffect } from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import { encryptClient, preventClick } from '../../../lib/helpers';
import { Modal } from 'react-bootstrap';

export default function ResetPassword() {
    
    let { code } = useParams();

    const [errorMsg, setErrorMessage] = useState(null);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [confirmPasswordVisible, setConfirmPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({password:'', confirm_password:'', code: code});

    const [formErrors, setFormErrors] = useState({password:null, passwordErrorStyle: {color:'#000'}, terms:null});

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    useEffect(() => {
        setTimeout(function(){
            window.animate();
        },1000);
        document.title = process.env.REACT_APP_NAME+" - Reset Password";
    }, []);

    const resetPassword = () => {
        
        setLoading(true);

        setErrorMessage(null);

        var body = Object.assign({},formValues);

        body['role'] = 'Tester';
        

        body['password'] = encryptClient(body.password);

        body['confirm_password'] = encryptClient(body.confirm_password);
        
        fetch(process.env.REACT_APP_API_END_POINT+'reset-password', {
            method: 'POST',
            body: JSON.stringify(body),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);
            if(response.success){
                setFormValues({password:'', confirm_password:'', code: code } );
                setModal({modalHeading:"Password Updated", modalMessage:response.message, modalOpen: true});                

            } else {
                setErrorMessage(response.message);
            }
        });
    }
    const validateForm = (name) => {
        
        
        return (event)=>{
            
            setFormValues(oldValues => ({...oldValues, [name]: event.target.value }));


            var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;

            if(formValues.password.length<6 || !formValues.password.match(re)){
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  "password_error" }));    
            } else {
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  'none' }));   
            } 
        }
        
    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = () => {
        
        var form_errors = resetFormErrors();
        
        var error = false;
            
        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;
            
        if(formValues.password.length<6 || !formValues.password.match(re)){
            
            form_errors = {
                ...form_errors,
                password:"Password",
                password_error_class:"password_error"
            };
            error = true;
            
        }  else {
            form_errors ={
                ...form_errors,
                password: null,  
                error_class: "input_error",
                password_error_class:"none", 
            };
        }
        if(formValues.confirm_password==''){
            
            form_errors = {
                ...form_errors,
                confirm_password: "Required field",
                error_class: "input_error"
            };
            error = true;
            
        }
        else if(formValues.password!=formValues.confirm_password){
        
            form_errors = {
                ...form_errors,
                confirm_password: "Confirm password must be same as password.",
                error_class: "input_error"
            };
            error = true;
            
        }
        
        setFormErrors(form_errors);

        return error;
    }
    
    const onSubmit =  async (event) => {
        event.preventDefault();

        setFormErrors({password:null, confirm_password:null, terms:false,password_error_class:null });

        setErrorMessage(null);

        if(!isLoading){

            var error = validateFullForm();
            
            if(!error){
                resetPassword();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordVisibility(!confirmPasswordVisible);
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_TESTER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
                <div className="login-hdr-right">
                    <Link to={"/researcher/reset-password/"+code}> Become a researcher</Link>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <h1 className="form-heading">Reset password</h1>
                        <p className="form-sub-head">&nbsp;</p>
                        <div className="login-form-inner-data">
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <form action="#" onSubmit={onSubmit}>
                                <div className=" input-field form-group "  >
                                    <div className='position-relative w-100'>
                                        <input  type={passwordVisible ? "text" : "password"} className={formErrors.password_error_class!=null ? "form-control "+formErrors.password_error_class : "form-control"} id="pass_log_id" value={formValues.password} onChange={set('password')} onKeyUp={validateForm('password')}  />
                                        <i className="toggle-password"  onClick={togglePassword}><img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="eye"/></i>
                                        <label for="pass_log_id">Password <img src={process.env.REACT_APP_URL+"images/star-field.svg"} /></label>
                                        
                                    </div>
                                    {formErrors.password_error_class!='none' &&
                                    <p className={formErrors.password_error_class!=null ? "password-guide-text "+formErrors.password_error_class : "password-guide-text "}>Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)</p>
                                    }
                                </div>
                                <div className=" input-field  form-group mb-0 password-margin-top">
                                    <div className='position-relative w-100'>
                                        <input  type={confirmPasswordVisible ? "text" : "password"} className={formErrors.confirm_password!=null ? "form-control "+formErrors.error_class : "form-control"} value={formValues.confirm_password} onChange={set('confirm_password')} id="pass_log_id-2"  />
                                        <i className="toggle-password" onClick={toggleConfirmPassword}><img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="eye"/></i>
                                        
                                        <label for="pass_log_id-2">Confirm Password <img src={process.env.REACT_APP_URL+"images/star-field.svg"} /></label>
                                        
                                        {formErrors.confirm_password &&
                                            <span className={formErrors.error_class}>{formErrors.confirm_password}</span>
                                        }
                                    </div>
                                </div>
                                <div className="reset-btn-wrap">
                                    <button type="submit" className="btn btn-block form-btn">
                                        Update Password
                                        {isLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"margin-left": "5px"}}></i>
                                        }
                                    </button>
                                    <Link to="/tester/login" className="btn btn-block form-btn form-back-btn">Cancel</Link>
                                </div>
                                
                            </form>
                            <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap email-sent-modal">
                                <Modal.Body> 
                                    <div className="modal-inner-text">
                                        <button type="button" className="close" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } aria-label="Close">
                                            <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                                        </button>
                                        <h2>{modal.modalHeading}</h2>
                                        <p>{modal.modalMessage}</p>
                                        <Link to="/tester/login" className="btn btn-block form-btn">BACK TO SIGN IN</Link>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}