import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../../lib/secure_reactsession';
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import DownloadCsv from "../dialog/download_csv"
import ArchiveTest from "../dialog/archive_test";
import {ExportTestResult} from "../../../../services/researcher_test_results";
import { encryptId } from '../../../../lib/helpers';

export default function Sidebar({test,planType, activeMenu,hideSidebar}) {

    const [linkCopied, setLinkCopied] = useState(false);
    const [passwordCopied, setPasswordCopied] = useState(false);
    const [openDownloadCsvModal, setOpenDownloadCsvModal] = useState(false );
    const [downloadTestLoading, setDownloadTestLoading] = useState(false);


    const downTestHandler = ()=>{
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);

        if(downloadTestLoading){
            return false;
        }
        setDownloadTestLoading(true);
        ExportTestResult(data, token).then((response) => {
            setDownloadTestLoading(false);

            if (response.success) {
               // window.open(response.url,'_blank')
                 let newTab = window.open();
                 newTab.location.href = response.url;
                 setTimeout (function() {newTab.close();},200);
            }
            toast(
                <div className="toastinner">
                    <span dangerouslySetInnerHTML={{ __html:response.message}}></span>
                    <a onClick={() => {
                        toast.dismiss();
                    }}>&times;</a>
                </div>,
                {
                    className: (response.success) ? "successtoast" : "errortoast",
                    position: "bottom-center",
                    duration: 20000,
                }
            );
        });
    }



    return (
        <div className={`sidebar sp5-left-side-bar ${hideSidebar === true?'d-none':''}`}>
            <div className="sp5-side-bar-innerdata">
                <div className="sp5-test-link-wrapper">
                    <div className="sp5-page-link-wrap">
                    {test.methodology=="Survey" ?(<h3 className="h3 font-inter">Survey link</h3>):(<h3 className="h3 font-inter">Test link</h3>)}
                        <div className="generated-study-link-wrap mb-32">
                            <p className="study-gen-link">{process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}</p>
                            <div className="copy-share-test-wrap">
                                <CopyToClipboard text={process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}
                                                 onCopy={() => { setLinkCopied(true); setTimeout(function () { setLinkCopied(false); }, 2000) }}>
                                    <span>Copy link  <img src={process.env.REACT_APP_URL + "images/link.svg"} /></span>
                                </CopyToClipboard>
                                <span className={`${linkCopied ? 'fade-in' : 'fade-out'} `}>Copied!!</span>
                            </div>
                        </div>
                    </div>
                    {test.password &&
                    <div className="sp5-page-link-wrap">
                        <h3 className="h3 font-inter">Password</h3>
                        <div className="generated-study-link-wrap mb-32">
                            <p className="study-gen-link">{test.password}</p>
                            <div className="copy-share-test-wrap">
                                <CopyToClipboard text={test.password}
                                                 onCopy={() => { setPasswordCopied(true); setTimeout(function () { setPasswordCopied(false); }, 2000) }}>
                                    <span>Copy password <img src={process.env.REACT_APP_URL + "images/link.svg"} alt="img" /></span>
                                </CopyToClipboard>
                                <span className={`${passwordCopied ? 'fade-in' : 'fade-out'} `}>Copied!!</span>
                            </div>
                        </div>
                    </div>
                    }
                </div>

                {planType &&
                (planType === 'PAID' ||
                    (planType === 'FREE' &&  (moment.utc(test.published_on).add(30,'days').format('DD-MM-Y') < moment.utc().format('DD-MM-Y'))
                    )) &&
                <>
                <div className="sp5-side-nav-wrap">
                    <ul>
                        <li><Link to={`/researcher/analytics/${test.id}/overview`} className={`${activeMenu=='overview'?'active':''}`}>Overview</Link></li>
                        <li><Link to={`/researcher/analytics/${test.id}/participants`} className={`${activeMenu=='participants'?'active':''}`}>Participants</Link></li>
                        {test.methodology!="Survey" &&
                        <li><Link to={`/researcher/analytics/${test.id}/briefing`} className={`${activeMenu=='briefing'?'active':''}`}>Briefing questions</Link></li>
                        }
                        {test.methodology=="Tree Test" &&
                        <li><Link to={`/researcher/analytics/${test.id}/tree-test`} className={`${activeMenu=='tree-test'?'active':''}`}>Tree testing</Link></li>
                        }
                        {test.methodology=="Preference Test" &&
                        <li><Link to={`/researcher/analytics/${test.id}/preference-test`} className={`${activeMenu=='preference-test'?'active':''}`}>Preference test</Link></li>
                    }
                        {test.methodology=="Card Sorting" &&
                        <li><Link to={`/researcher/analytics/${test.id}/card-sorting`} className={`${activeMenu=='card-sorting'?'active':''}`}>
                            {test.card_sorting.card_sorting === "close" ? ' Closed card sorting':' Open card sorting'}
                        </Link></li>
                        }
                        {test.methodology=="Survey" &&
                        <li><Link to={`/researcher/analytics/${test.id}/survey`} className={`${activeMenu=='survey'?'active':''}`}>Survey results</Link></li>
                        }
                        {test.methodology=="Five Seconds Test" &&
                        <li><Link to={`/researcher/analytics/${test.id}/five-seconds-test`} className={`${activeMenu=='five-seconds-test'?'active':''}`}>5 seconds test</Link></li>
                        }
                        {!(test.methodology=="Survey" || test.methodology=="Five Seconds Test") &&
                        <li><Link to={`/researcher/analytics/${test.id}/debriefing`} className={`${activeMenu=='debriefing'?'active':''}`}>Debriefing questions</Link></li>
                        }
                    </ul>
                </div>
                <div className="sp5-download-csv-btn">
                    <button id="r_results_download_xlsx_report" type="button" onClick={(e)=>downTestHandler(e)}>Download XLSX
                        report
                        <img src={process.env.REACT_APP_URL + "images/export-icon.svg"} alt="icon"/>

                        {downloadTestLoading && (
                            <i
                                className="fa fa-spinner fa-spin"
                                aria-hidden="true"
                                style={{ marginLeft: "5px" }}
                            ></i>
                        )}

                    </button>
                </div>
                </>
                }
            </div>

            <DownloadCsv
                test={test}
                openModal={openDownloadCsvModal}
                closeModal={()=>{setOpenDownloadCsvModal(false)}}/>
        </div>
    )
}