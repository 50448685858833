import React, { useState, useEffect } from 'react';
import { preventClick } from '../../../lib/helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { FormattedMessage } from 'react-intl';

export default function Verify() {

    let { code } = useParams();

    const navigate = useNavigate();

    const [isEmailVerified, setEmailVerified] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME+" - Verify";
        verifyEmail();
    },[]);

    const verifyEmail = () => {
        
        setLoading(true);

        setErrorMessage(null);

        var verification_code = code;

        
        
        fetch(process.env.REACT_APP_API_END_POINT+'verify_code', {
            method: 'POST',
            body: JSON.stringify({code: verification_code, 'role': 'Researcher'}),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);

            if(response.success){
                
                setEmailVerified(true);

                
                ReactSession.set(
                  "successVerifiedMessage",
                  ""
                );
                /*<FormattedMessage id="Success! Your account has now been verified. Login to get started with UserQ!" /> */

                ReactSession.set("token", response.token);
                ReactSession.set("user", response.user);

                // navigate("/researcher/login");
                navigate('/dashboard');

            } else {
                
                setErrorMessage(response.message);
            }
        });
        
    }
    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a target="_blank" href={process.env.REACT_APP_RESEARCHER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
                {/*<div className="login-hdr-right">
                    <a href="" onClick={preventClick}>I am a researcher</a>
                </div>*/}
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        {isLoading &&
                            <div>
                                <h1 className="form-heading">Verifying your email...</h1>
                                <p className="form-sub-head">
                                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                </p>
                            </div>
                        }
                        {!isLoading &&
                            <div>
                                {isEmailVerified &&
                                    <div>
                                        <h1 className="form-heading">Successfully Verified!</h1>
                                        <div className="login-form-inner-data">
                                            <div className="reset-btn-wrap">
                                                <Link to="/login"  className="btn btn-block form-btn">CONTINUE TO DASHBOARD</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!isEmailVerified &&
                                    <div>
                                        <h1 className="form-heading">Invalid link!</h1>
                                        <p className="form-sub-head">
                                            {errorMsg}
                                        </p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}