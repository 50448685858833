import React from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import Topbar from "../study/topbar/topbar.js";
import { useState, useEffect, useRef } from "react";
import {
    getTestData,
    getTestOrderData,
    updatePublishSettingsService,
} from "../../../services/test";
import toast from "react-hot-toast";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Checkbox from "../../layouts/elements/fields/checkbox";
import InputField from "../../layouts/elements/fields/input";
import 'react-rangeslider/lib/index.css';

import "react-datepicker/dist/react-datepicker.css";
import { getCreditSummaryService } from "../../../services/credits";
import { publishTestService } from "../../../services/test";

import Progress from "../study/progress/progress.js";
import { FormattedMessage } from 'react-intl';
import { atom, useResetRecoilState } from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import {encryptId, encryptClient, roundNumber} from "../../../lib/helpers";

import { getBillingInfoService, updateBillingInfoService } from "../../../services/user";
import { countries } from '../../../data/stripe_countries.js';
import Select from 'react-select';
import {
    addNewPaymentMethodService,
    getPaymentMethodsService,
    deletePaymentMethodService,
    setDefaultCard,
} from "../../../services/payment_methods";

import { DropdownIndicator } from "../../../lib/helpers";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import Cleave from "cleave.js/react";
import validator from "validator";

import ConfirmModal from "../../dialog/confirm_modal";

import { applyCouponOnTestService, removeCouponOnTestService, publishCreditsTestService, makePaymentForTestService } from "../../../services/credits";
import PublishModal  from "../study/publishmodal/publish_modal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function PublishTest(props) {
    return (<Elements stripe={stripePromise}>
        <PaymentComponent {...props} />
    </Elements>);
};


const PaymentComponent = (props) => {

    ReactSession.setStoreType("localStorage");

    const stripe = useStripe();

    let { test_id } = useParams();
    
    let user = ReactSession.get("user");
    
    const dateTesterSectionRef = useRef();


    const navigate = useNavigate();

    const [test, setTest] = useState("");

    const [lastSaved, setLastSaved] = useState(null);

    const [linkCopied, setLinkCopied] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [orderLoading, setOrderLoading] = useState(false);
    

    const [order, setOrder] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        closed_on: new Date(),
        password: "",
        password_field: 0,
        close_condition: "no-specific-date",
        participants: 5,
        promo_code:"",
        payment_method_id:"",
        name:"",
        city:"",
        country:"",
        address:"",
        card_name:"",
        card_no:"",
        exp_date:"",
        cvv:""
    });

    const handleScroll = ref => {
        window.scrollTo({
            behavior: "smooth",
            top: ref.current.offsetTop
        });
    };

    const [formErrors, setFormErrors] = useState({
        password: null,
        promo_code: null,
        payment_method_id: null,
        name: null,
        city: null,
        country: null,
        address: null,
        card_name:null,
        card_no:null,
        exp_date:null,
        cvv:null,
        error_class: "input_error",
    });

    const [summary, setSummary] = useState({ credits_balance: 100 });

    const [settings, setSettings] = useState({ max_participants: 500 });

    const [participants_selected, setParticipantsSelected] = useState(5);

    const [targetType, setTargetType] = useState('test-links');
    

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const reset = useResetRecoilState(creditsState);

    /*New Form */
    const [isBillingEditable, setBillingEditable] = useState(false);

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [selected_package, setSelectPackage] = useState(null);

    const [addNewCard, setAddNewCard] = useState(false);

    const [credits, setCredits] = useState(50);

    const [credits_error, setCreditsError] = useState(null);

    const [isExpanded, setExpanded] = useState(false);

    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [billingInfo, setBillingInfo] = useState({
        name: "",
        country: null,
        city: "",
        address: "",
    });

    const [billingLoading, setBillingLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const errorRef = useRef(null);

    const [firstErrorRef, setFirstErrorRef] = useState(null);

    const [cardFormLoading, setCardFormLoading] = useState(false);

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        target_id: null,
    });

    const [publishModal, setPublishModal] = useState({
        open: false,
        close:null,
        confirm:null,
    });

    const [couponLoading, setCouponLoading] = useState(false);

    /*New Form */
    
    useEffect(function () {

        scroll();

    }, [firstErrorRef])

    const scroll = () => {
        if (errorRef && errorRef.current) {

            const y = errorRef.current.getBoundingClientRect().top + window.pageYOffset - 300;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Publish";

        getTestApi();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            savePublishSettings(formValues, false);
        }, 30000);

        return () => {
            clearTimeout(timer);
        };
    }, [formValues]);

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    const savePublishSettings = () => {
        if (test && test.status != "published") {
            let data = Object.assign({}, formValues);

            data["test_id"] = test_id;

            setSaveLoading(true);

            updatePublishSettingsService(data, ReactSession.get("token")).then(
                (response) => {
                    setSaveLoading(false);

                    if (response.success) {
                        setLastSaved(moment.now());

                        //reset();

                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                }
            );
        }
    };
    const fetchCreditsSummary = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCreditSummaryService(token).then((response) => {
            //setLoading(false);
            if (response.success) {
                setSummary(response.summary);

                ReactSession.set("credits", response.summary.credits_balance);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            //setLoading(false);

            if (response.success) {

                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/researcher/project/" + response.test.project_id);
                    }

                    else {
                        if (
                            response.test.is_briefing_questions == 1 &&
                            response.test.briefingQuestion.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/introduction");
                            showError("Please add briefing questions");
                        } /*else if (response.test.methodology == "Tree Test" && response.test.tasks.length == 0) {
                            navigate("/researcher/tests/" + test_id + "/tree-sort-test/");
                            showError("Please add tasks");
                        } */ else if (response.test.methodology === "Card Sorting") {

                            if (response.test.card_sorting_card.length === 0) {
                                navigate("/researcher/tests/" + test_id + "/card-sorting-test/");
                                showError("Looks like you haven't created card for this test! Please add a card to move to the next steps.");
                            } else if (response.test.card_sorting.card_sorting === 'close' && response.test.card_sorting_category.length === 0) {
                                navigate("/researcher/tests/" + test_id + "/card-sorting-test/");
                                showError("Looks like you haven't created category for this test! Please add a category to move to the next steps.");

                            }
                            else if (
                                response.test.is_debriefing_questions == 1 &&
                                response.test.debriefingQuestion.length == 0
                            ) {
                                navigate("/researcher/tests/" + test_id + "/conclusions");
                                showError("Please add debriefing questions");
                            }
                            else if (response.test.target_group == null) {
                                navigate("/researcher/tests/" + test_id + "/target-group/");
                                showError("Please select target group");
                            } else if (
                                response.test.target_group != null &&
                                response.test.target_group.target_type !== 'test-links'
                            ) {
                                if (response.test.target_group.participants_selected == null) {
                                    navigate("/researcher/tests/" + test_id + "/target-group/");
                                    showError("Please select target group");
                                } else if (response.test.target_group.participants_selected === 0) {
                                    navigate("/researcher/tests/" + test_id + "/target-group/");
                                    showError(
                                        <span className={"w-100"}>
                                            Unfortunately, we can't fulfil your target group requirements.
                                            Please contact us at
                                            <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                                        </span>
                                    );
                                }
    
                            }

                        } 
                        else if (response.test.methodology === "Five Seconds Test") {
                            if (response.test.fiveSecondsTestTask == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Some required information for the five seconds test is missing or incomplete. Please review and try again."
                                );
                            }
                            else if (response.test.fiveSecondsTestTask.task == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added questions for this test! Please add atleast 1 question to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    navigate(
                                        "/researcher/tests/" + test_id + "/five-seconds-test/"
                                    );
                                    showError(
                                        "Atleast one question should not be optional."
                                    );
                                }
                                else if (
                                    response.test.is_debriefing_questions == 1 &&
                                    response.test.debriefingQuestion.length == 0
                                ) {
                                    navigate("/researcher/tests/" + test_id + "/conclusions");
                                    showError("Please add debriefing questions");
                                }
                                else if (response.test.target_group == null) {
                                    navigate("/researcher/tests/" + test_id + "/target-group/");
                                    showError("Please select target group");
                                } else if (
                                    response.test.target_group != null &&
                                    response.test.target_group.target_type !== 'test-links'
                                ) {
                                    if (response.test.target_group.participants_selected == null) {
                                        navigate("/researcher/tests/" + test_id + "/target-group/");
                                        showError("Please select target group");
                                    } else if (response.test.target_group.participants_selected === 0) {
                                        navigate("/researcher/tests/" + test_id + "/target-group/");
                                        showError(
                                            <span className={"w-100"}>
                                                Unfortunately, we can't fulfil your target group requirements.
                                                Please contact us at
                                                <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                                            </span>
                                        );
                                    }
        
                                }

                            }
                        }
                        else if (
                            response.test.methodology == "Survey" &&
                            response.test.surveyQuestion.length === 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/survey");
                            showError("Please add survey questions");
                        }
                        else if (
                            response.test.is_debriefing_questions == 1 &&
                            response.test.debriefingQuestion.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/conclusions");
                            showError("Please add debriefing questions");
                        } else if (response.test.target_group == null) {
                            navigate("/researcher/tests/" + test_id + "/target-group/");
                            showError("Please select target group");
                        } else if (
                            response.test.target_group != null &&
                            response.test.target_group.target_type !== 'test-links'
                        ) {
                            if (response.test.target_group.participants_selected == null) {
                                navigate("/researcher/tests/" + test_id + "/target-group/");
                                showError("Please select target group");
                            } else if (response.test.target_group.participants_selected === 0) {
                                navigate("/researcher/tests/" + test_id + "/target-group/");
                                showError(
                                    <span className={"w-100"}>
                                        Unfortunately, we can't fulfil your target group requirements.
                                        Please contact us at
                                        <a href={"mailto:support@userq.com"} className={'font-weight-600'} style={{ fontSize: '16px' }}> support@userq.com</a>
                                    </span>
                                );
                            }

                        }  else if (
                            response.test.methodology == "Tree Test"
                        ) {
                            if(response.test.tasks.length === 0){
                                navigate("/researcher/tests/" + test_id + "/tree-sort-test");
                                showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                            } else {
                                console.log(response.test.tasks);
                                for(var i=0; i<response.test.tasks.length; i++){
                                    if(response.test.tasks[i].correct_answer_id==null){
                                        navigate("/researcher/tests/" + test_id + "/tree-sort-test");
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    }
                                }

                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            if(response.test.preference_test_designs.length < 2){
                                navigate("/researcher/tests/" + test_id + "/preference-test");
                                showError("There's some information we still need – please take a look to see if you missed anything!");
                            }
                        }
                    }
                }

                if (response.test.target_group) {
                    if (response.test.target_group.participants_selected) {
                        setFormValue(
                            "pariticipants",
                            response.test.target_group.participants_selected
                        );
                        setParticipantsSelected(
                            response.test.target_group.participants_selected
                        );
                    }

                    setTargetType(response.test.target_group.target_type);
                }
                setSettings(response.settings);

                setTest(response.test);

                if (response.test.password) {
                    setFormValue("password", response.test.password);
                    setFormValue("password_field", 1);
                } else {
                    setFormValue("password", "");
                    setFormValue("password_field", 0);
                }
                setFormValue("close_condition", response.test.close_condition);

                if (response.test.closed_on) {
                    setFormValue(
                        "closed_on",
                        new Date(moment().utc(response.test.closed_on).local().format())
                    );
                } /*else {
                    setFormValue("closed_on", new Date());
                }*/

                if(response.test.participants){
                    setFormValue("participants", response.test.participants);
                } else {
                    setFormValue("participants",response.test.target_group.participants_selected);
                }

                /*if(response.test.saved_step || response.test.saved_step==0){
                            
                            if(response.test.saved_step<5){
                                navigate("/researcher/tests/"+test_id+"/review");
                            }
                        }*/
                // redirectToStep({
                //   test_id: test_id,
                //   current_step: 2,
                //   saved_step: response.test.saved_step,
                //   navigate: navigate,
                // });

                setLastSaved(moment(response.test.updated_at));

                fetchCreditsSummary();
                
                getOrderApi();


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getOrderApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestOrderData({ test_id: test_id }, token).then((response) => {
            //setLoading(false);

            if (response.success) {

                if (response.order) {
                    setOrder(response.order);

                    if(response.order.coupon_code){
                        setFormValues({...formValues, "promo_code":response.order.coupon_code})
                    }
                    getBillingInfo();

                    setTimeout(function(){
                        window.setLabels();
                    },100);
                }


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getBillingInfo = () => {
        let token = ReactSession.get("token");

        setLoading(true);


        getBillingInfoService(token).then((response) => {
            //setLoading(false);

            if (response.success) {
                //setFormValues()

                for (var key in response.billing_address) {
                    if (response.billing_address[key] == null) {
                        response.billing_address[key] = "";
                    }
                }
                var country = null;

                countries.forEach(function (item) {
                    if (item.value == response.billing_address.country) {
                        country = item;
                    }
                });
                if (response.billing_address.country == "") {
                    setCountryLabelClass("inactive_label");
                } else {
                    setCountryLabelClass("active_label");
                }
                setBillingInfo((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));

                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));
                if (!response.billing_address.name) {
                    setBillingEditable(true);
                }
                window.setLabels();
                getPaymentMethods();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const updateBillingInfo = () => {
        resetFormErrors();

        if (!billingLoading) {
            var error = false;

            resetFormErrors();

            if (formValues.name == "") {
                showFormError("name", "Required field");
                error = true;
            } else if (formValues.address == "") {
                showFormError("address", "Required field");
                error = true;
            } else if (formValues.city == "") {
                showFormError("city", "Required field");
                error = true;
            } else if (!formValues.country) {
                showFormError("country", "Required field");
                error = true;
            }

            if (!error) {
                setBillingLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["name"] = formValues.name;

                formData["address"] = formValues.address;

                formData["country"] = formValues.country.value;

                formData["city"] = formValues.city;

                updateBillingInfoService(formData, token).then((response) => {
                    setBillingLoading(false);

                    if (response.success) {
                        for (var key in response.billing_address) {
                            if (response.billing_address[key] == null) {
                                response.billing_address[key] = "";
                            }
                        }
                        var country = null;

                        countries.forEach(function (item) {
                            if (item.value == response.billing_address.country) {
                                country = item;
                            }
                        });

                        setBillingInfo((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setFormValues((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setBillingEditable(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method, index) {
            render.push(
                <div
                    key={"methods" + method.id}
                    className="checkboxes radio_btn_class gender_btn d-flex card-radio-wrap"
                >
                    <div className="card-left-side">
                        <label htmlFor={method.stripe_payment_method_id} className="mt-0">
                            <input
                                id={method.stripe_payment_method_id}
                                type="radio"
                                checked={
                                    formValues.payment_method_id == method.id ? true : false
                                }
                                onChange={() => {
                                    setFormValue("payment_method_id", method.id);
                                }}
                                name="payment_method"
                                value={method.stripe_payment_method_id}
                                className="hidden radio_btn_input"
                                required="required"
                            />
                            <span>
                                <img
                                    className="empty-fill-icon"
                                    src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"}
                                />
                                <img
                                    className="fill-icon"
                                    src={process.env.REACT_APP_URL + "images/pol-fill.svg"}
                                />
                            </span>
                            <p>
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "images/" +
                                        method.brand +
                                        "-icon.svg"
                                    }
                                />{" "}
                                {method.brand} ending in *{method.last_4}{" "}
                                <b>{method.card_name}</b>
                            </p>
                        </label>
                        {method.default == 1 && (
                            <span className="primary-card">Primary</span>
                        )}
                    </div>
                    {user.permission != "Editor" && (
                        <div className="card-right-side">
                            {method.default == 0 && (
                                <span
                                    className="make-primary-card"
                                    onClick={() => {
                                        setDefaultCardValue(method.stripe_payment_method_id);
                                    }}
                                >
                                    Make primary
                                </span>
                            )}
                            <span
                                className="delete-account-btn"
                                onClick={() => {
                                    setConfirmModal({
                                        open: true,
                                        target_id: method.id,
                                        confirm_title: "Delete Card",
                                        confirm_btn_title: "Delete",
                                        confirm_message:
                                            "Are you sure you want to delete this card?",
                                    });
                                }}
                            >
                                {" "}
                                <img
                                    src={process.env.REACT_APP_URL + "images/trash.svg"}
                                    alt="trash-icon"
                                />
                            </span>
                        </div>
                    )}
                </div>
            );
        });

        if (render.length > 0) {
            return render;
        } else {
            return <div className="no_payment_add">No payment methods added.</div>;
        }
    };
    const setDefaultCardValue = (value) => {

        let token = ReactSession.get("token");
        var data = new FormData();
        data.append("stripe_id", value);
        setDefaultCard(data, token).then((response) => {
            if (response.success) {
                getPaymentMethods();
            }
        });
    };
    const getPaymentMethods = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPaymentMethodsService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (response.payment_methods.length > 0) {
                    setFormValue("payment_method_id", response.payment_methods[0].id);
                }
                setPaymentMethods(response.payment_methods);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const deletePaymentMethod = (id) => {
        let token = ReactSession.get("token");

        setLoading(true);

        deletePaymentMethodService({ id: id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                getPaymentMethods();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const addNewPaymentMethod = () => {
        resetFormErrors();

        if (!cardFormLoading) {
            var error = false;

            // resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setCardFormLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token).then((response) => {
                    setCardFormLoading(false);

                    if (response.success) {
                        setFormValue("card_name", "");
                        setFormValue("card_no", "");
                        setFormValue("cvv", "");
                        setFormValue("exp_date", "");

                        getPaymentMethods();

                        setAddNewCard(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        if (formValues.card_name == "") {
            form_errors = {
                ...form_errors,
                card_name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cardholder-name" : firsterrorid;
        }
        if (formValues.exp_date == "") {
            form_errors = {
                ...form_errors,
                exp_date: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.exp_date.length == 5) {

            if (new Date().getTime() > new Date("01/" + formValues.exp_date).getTime()
            ) {
                form_errors = {
                    ...form_errors,
                    exp_date: "Expiry cannot be past date",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
            }

        } else if (formValues.exp_date.length > 0) {
            form_errors = {
                ...form_errors,
                exp_date: "Invalid CVV/CVC",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }


        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };
    const validateCreditCard = (value) => {
        if (!validator.isCreditCard(value)) {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Enter valid Credit Card Number!", "error_class": "card-error" }));
            // showFormError("card_no", "Enter valid Credit Card Number!");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }
        if (formValues.card_type != "visa" && formValues.card_type != "mastercard") {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Only visa and mastercard are allowed.", "error_class": "card-error" }));
            // showFormError("card_no", "Only visa and mastercard are allowed.");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }
    };
    const removePromoCode = () => {
        
        if (!couponLoading) {
            
            resetFormErrors();

            
            setCouponLoading(true);

            const token = ReactSession.get("token");

            var formData = {};

            formData.promo_code = formValues.promo_code;

            formData.test_id = test.id;

            removeCouponOnTestService(formData, token).then((response) => {
                
                setCouponLoading(false);

                if (response.success) {

                    setFormValues({
                        ...formValues,
                        promo_code:""
                    })
                    setOrder(response.order);

                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "successtoast",
                            position: "bottom-center",
                        }
                    );

                } else {
                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });
            

        }
    }
    const checkPublishConditions = () => {
        
        resetFormErrors();

        if (!formLoading && !(paymentMethods.length ==0 && order.amount>0) ) {

            var error = false;

            var address_error = false;

            setFirstErrorRef(null);

            
            if(!error){

                if(order && order.amount > 0){
                    
                    if (formValues.payment_method_id == "") {
                        var id = toast(
                            <div className="toastinner">
                                Please select payment_method
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                        error = true;
                    }
                    if (!error) {
                        var address_error = false;

                        var first_error_ref = null;
                        var form_errors = formErrors;

                        if (formValues.password_field == 1) {
                            if (formValues.password == "") {
                                setFormErrors({ ...formErrors, password: "Required!" });
                                error = true;
                            }
                        }

                        if (formValues.name == "") {
                            // showFormError("name", "Required field");
                            form_errors = {
                                ...form_errors,
                                name: "Required field",
                                error_class: "input_error",
                            };
                            address_error = true;
                            error = true;
                            if (!first_error_ref) {

                                first_error_ref = "name";
                            }
                        }
                        if (formValues.city == "") {
                            // showFormError("city", "Required field");
                            form_errors = {
                                ...form_errors,
                                city: "Required field",
                                error_class: "input_error",
                            };
                            address_error = true;
                            error = true;
                            if (!first_error_ref) {

                                first_error_ref = "city";
                            }
                        }
                        if (!formValues.country) {
                            // showFormError("country", "Required field");
                            form_errors = {
                                ...form_errors,
                                country: "Required field",
                                error_class: "input_error",
                            };
                            address_error = true;
                            error = true;
                            if (!first_error_ref) {

                                first_error_ref = "country";
                            }
                        }
                        if (formValues.address == "") {
                            // showFormError("address", "Required field");
                            form_errors = {
                                ...form_errors,
                                address: "Required field",
                                error_class: "input_error",
                            };
                            address_error = true;
                            error = true;
                            if (!first_error_ref) {

                                first_error_ref = "address";
                            }
                        }
                        setFormErrors(form_errors);
                    }
                
                    if (address_error) {

                        if (first_error_ref) {
                            if (errorRef && errorRef.current) {

                                scroll();
                            }
                            setFirstErrorRef(first_error_ref);
                        }
                        var id = toast(
                            <div className="toastinner">
                                Please reverify billing information. Some of the required fields are missing.
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }

                    if (!error) {
                        
                        setPublishModal({...publishModal, open:true, "confirm":function(){
                            setPublishModal({
                                ...publishModal,
                                open:false
                            });
                            publishTest()
                        }})
                    }
                    

                }  else {
                    setPublishModal({...publishModal, open:true, "confirm":function(){
                        setPublishModal({
                            ...publishModal,
                            open:false
                        });
                        publishTest()
                    }})
                }
            }
        }
    };
    const publishTest = () => {
        
        if(!formLoading){
            setFormLoading(true);

            const token = ReactSession.get("token");

            var formData = formValues;

            formData["test_id"] = test.id;

            if (formValues.country) {
                formData["country"] = formValues.country.value;
            }
            
            publishCreditsTestService(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {

                    if (response.client_secret) {
                        setFormValues({ ...formValues, order_id: response.order_id });
                        setFormValues({ ...formValues, invoice_id: response.invoice_id });
                        stripe.confirmCardPayment(response.client_secret).then(handleStripeJsResult)

                    } else {
                        reset();
                        
                        navigate("/researcher/tests/" + test.id + "/live");
                    }
                } else {
                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });
        }
    }
    function handleStripeJsResult(result) {
        console.log(result)
        if (result.error) {
            // Show error in payment form
        } else {
            setFormLoading(true);

            var formData = formValues;

            const token = ReactSession.get("token");

            formData["payment_intent_id"] = result.paymentIntent.id;

            makePaymentForTestService(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {

                    navigate("/researcher/tests/" + test.id + "/live");

                } else {
                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });

        }
    }
    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;
        formErrorsLocal["error_class"] = "input_error";
        setFormErrors(formErrorsLocal);
        console.log(formErrorsLocal);
    };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);
        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };
    const setPublish = () => {
        var error = false;

        if (formValues.password_field == 1) {
            if (formValues.password == "") {
                setFormErrors({ ...formErrors, password: "Required!" });
                error = true;
            }
        }
        if (!error) {
            let token = ReactSession.get("token");

            setSaveLoading(true);

            publishTestService(
                {
                    test_id: test.id,
                    password: formValues.password_field == 1 ? formValues.password : "",
                    close_condition: formValues.close_condition,
                    participants: formValues.participants ? formValues.participants:participants_selected,
                    closed_on: moment(formValues.closed_on)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss"),
                    validate: true,
                },
                token
            ).then((response) => {
                setSaveLoading(false);

                if (response.success) {
                    reset();
                    if (test.status == "draft") {
                        navigate("/researcher/tests/" + test.id + "/live");
                    } else {
                        test.status = "published";
                        setTest(test);


                        toast(
                            <div className="toastinner">
                                <><FormattedMessage id="Your test has been published successfully." /></>
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                            }
                        );
                    }
                } else {
                    if (response.last_published) {
                        toast(
                            <div className="toastinner free-plan-published-notification">
                                <div className="free-plan-published-error d-flex">
                                    <p>You've reached your <strong>monthly active test limit (1/1)</strong>. But don't worry, you can publish this test by upgrading your plan. </p>
                                    
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/researcher/billing");
                                        }}
                                        className="btn form-btn"
                                        href={"/researcher/billing"}
                                    >
                                        Upgrade to publish now
                                    </a>
                                </div>
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                }
            });
        }
    };

    const applyCouponApi = () => {

        if (!couponLoading) {

            resetFormErrors();

            var error = false;

            if (!formValues.promo_code) {
                error = true;
                setFormErrors({ ...formErrors, promo_code: "Required field", error_class: "input_error" });
            }
            if (!error) {
                setCouponLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData.promo_code = formValues.promo_code;

                formData.test_id = test.id;

                applyCouponOnTestService(formData, token).then((response) => {
                    
                    setCouponLoading(false);

                    if (response.success) {

                        setOrder(response.order);

                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                            }
                        );

                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }

        }
    };
    const horizontalLabels = {
        participants_selected,
        5000: 5000
    }
    const formatkg = value => value + ' kg'
    return (
        <LayoutResearcherFullWidth
            credits={summary.credits_balance}
            fixed_header_target={true}
            isLoading={false}
            wrapClass={test && test.language == "ar" ? "" : ""}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
        >
            <div className="study-introduction-page-wrapper pay-on-fly-wrapper stydy-pages-wrapper study-publish-test-wrap pb-0 mb-0">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <Topbar
                            test={test}
                            enablePublish={test.credits_required <= parseInt(summary.credits_balance)}
                            onChange={(test) => {
                                console.log(test);
                                setTest({ ...test });
                            }}
                            credits_required={test.credits_required}
                            status={test.status}
                            lastSaved={lastSaved}
                            path={"/researcher/tests/" + test_id + "/review"}
                            isLoading={saveLoading}
                            pageLoading={isLoading}
                            title={test.test_name}
                            onPublish={() => {
                                setPublish();
                            }}
                            stepCallback={() => {
                                savePublishSettings();
                            }}
                        />
                    </div>
                </div>
                <div className="container inner-page-container">
                    <div className="study-steps-progress-wrap">

                        <Progress
                            stepCallback={() => {
                                savePublishSettings();
                            }}
                            test={test}
                            completed_step={5}
                        />

                    </div>
                    {isLoading &&
                        <LoadingIcon />
                    }
                    {!isLoading &&
                        <div className="target-group-data-wrapper pb-0 mb-64">

                            <div className="target-group-data-repeat ">
                                <div className="targer-group-left-wrap">
                                    <h3>Study link</h3>
                                    <div className="generated-study-link-wrap mb-32">
                                        <p className="study-gen-link">
                                            {process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}
                                        </p>
                                        <div className="copy-share-test-wrap">
                                            <CopyToClipboard
                                                text={process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}
                                                onCopy={() => {
                                                    setLinkCopied(true);
                                                    setTimeout(function () {
                                                        setLinkCopied(false);
                                                    }, 2000);
                                                }}
                                            >
                                                <span>
                                                    Copy link{" "}
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/link.svg"}
                                                    />
                                                </span>
                                            </CopyToClipboard>
                                            <span
                                                className={`${linkCopied ? "fade-in" : "fade-out"} `}
                                            >
                                                Copied!
                                            </span>
                                        </div>
                                    </div>
                                    <div className="publ-test-password-access-wrap">
                                        <div className={`${(targetType !== 'test-links')?'published-test-prd-enable':''}`}>
                                            <Checkbox
                                                value={"1"}
                                                checked={formValues.password_field == 1 ?
                                                    (targetType !== 'test-links')?false: true
                                                    : false}
                                                onChange={(e) => {
                                                    setFormValue(
                                                        "password_field",
                                                        e.currentTarget.checked ? 1 : 0
                                                    );
                                                }}

                                                label="Protect test with a password"
                                                isDisabled={(targetType !== 'test-links')?true:false}

                                            />

                                            {(targetType !== 'test-links') &&
                                            <p className="mt-4 protect-pass-info">
                                                <img
                                                    src={process.env.REACT_APP_URL + "images/enable-protected-password.svg"}
                                                />
                                                <span>
                                                    Password can only be enabled when sharing the study with your own panel.
                                                </span>
                                            </p>
                                            }
                                        </div>

                                        {targetType === 'test-links' && formValues.password_field == 1 && (
                                            <div className="pub-pass-field-wrap">
                                                <InputField
                                                    error={formErrors.password}
                                                    error_class={formErrors.error_class}
                                                    onChange={set("password")}
                                                    value={formValues.password}
                                                    type="text"
                                                    label={"Set password"}
                                                    id="password"
                                                />
                                                <p className="field-guide-text">
                                                    Must contain minimum 8 characters and 1 number
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="target-group-info-repeat">
                                    <h3>Study Link</h3>
                                    <p>
                                        You can use the unique link to send the test to anyone.
                                        For confidential projects, protect your
                                        test with a password.
                                    </p>
                                </div>

                            </div>
                            {order && order.amount > 0 &&
                            <>
                            <div className="target-group-data-repeat pay-on-fly-billing">
                                <div className="targer-group-left-wrap">
                                    <div className="pay-on-fly-billing-info-top">
                                        <h3 className="d-flex align-items-center bill-h-dflex" id="billing-info-section">
                                            Billing Information
                                            {user.permission != "Editor" && (
                                                <>
                                                    {!isBillingEditable && (
                                                        <span
                                                            className="edit-icon"
                                                            onClick={() => {
                                                                setExpanded(true);
                                                                setBillingEditable(true);
                                                                setTimeout(function () {
                                                                    window.setLabels();
                                                                }, 300);
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/edit-icon.svg"
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                            
                                        </h3>
                                        <div className="pay-on-fly-billing-arrow-wrap">
                                            <span className='expand-icon' onClick={()=>{
                                                    setExpanded(!isExpanded);
                                                    if(!isExpanded){
                                                        setTimeout(function () {
                                                            window.setLabels();
                                                        }, 100);
                                                    }
                                                }}>
                                                {billingInfo.address &&
                                                    <>
                                                        {isExpanded &&
                                                        <>
                                                        <img src={process.env.REACT_APP_URL+"images/arrow-down.svg"} />
                                                        </>
                                                        }
                                                        {!isExpanded &&
                                                        <>
                                                        <img src={process.env.REACT_APP_URL+"images/arrow-up.svg"} />
                                                        </>
                                                        }
                                                    </>
                                                }    
                                                
                                            </span>
                                        </div>
                                    </div>
                                    {(!billingInfo.address || isExpanded) &&
                                    <div className="col-md-12">
                                        {isBillingEditable && (
                                            <div className="row">
                                                <div className="col-md-6" ref={firstErrorRef == "name" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input form-group input-field">
                                                            <input

                                                                type="text"
                                                                readOnly={!isBillingEditable}
                                                                className={`form-control ${formErrors.name != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                                value={formValues.name}
                                                                onChange={set("name")}
                                                                id="name"
                                                            />
                                                            <label htmlFor="name" className="control-label">
                                                                Name/Company name
                                                            </label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("name")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.name != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.name}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group ">
                                                        <div className="input input-field form-group read-only-field">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={user.email}
                                                                readOnly
                                                                id="email"
                                                            />
                                                            <label htmlFor="email">Email</label>
                                                            <div className="data-field-icon">
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/green-check.svg"
                                                                    }
                                                                    alt="icon"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" ref={firstErrorRef == "country" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input-field mb-0">
                                                            <div
                                                                className={`${formErrors.country != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                            >
                                                                <label
                                                                    className={countryLabelClass}
                                                                    htmlFor="country"
                                                                >
                                                                    Country
                                                                </label>
                                                                <Select
                                                                    isClearable
                                                                    id="country"
                                                                    isDisabled={!isBillingEditable}
                                                                    value={
                                                                        formValues.country
                                                                            ? formValues.country
                                                                            : null
                                                                    }
                                                                    placeholder="Country"
                                                                    options={countries}
                                                                    onChange={(country) => {
                                                                        if (country == null) {
                                                                            setCountryLabelClass("inactive_label");
                                                                        } else {
                                                                            setCountryLabelClass("active_label");
                                                                            setFormErrors({ ...formErrors, country: null })
                                                                        }
                                                                        setFormValue("country", country);
                                                                    }}
                                                                    components={{ DropdownIndicator }}
                                                                />
                                                            </div>
                                                            {formErrors.country != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.country}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" ref={firstErrorRef == "city" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input form-group input-field">
                                                            <input
                                                                type="text"
                                                                readOnly={!isBillingEditable}
                                                                className={`form-control ${formErrors.city != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                                value={formValues.city}
                                                                onChange={set("city")}
                                                                id="city"
                                                            />
                                                            <label htmlFor="city" className="control-label">
                                                                City
                                                            </label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("city")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.city != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.city}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" ref={firstErrorRef == "address" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input form-group input-field ">
                                                            <input
                                                                type="text"
                                                                readOnly={!isBillingEditable}
                                                                id="address "
                                                                className={`form-control ${formErrors.address != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                                value={formValues.address}
                                                                onChange={set("address")}
                                                            />
                                                            <label
                                                                htmlFor="address "
                                                                className="control-label"
                                                            >
                                                                Address{" "}
                                                            </label>
                                                            <span className="clear">
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    onClick={clearFormValue("address")}
                                                                >
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.address != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.address}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {billingInfo.address &&
                                                    <div className="col-md-12">
                                                        <div className="flex-buttons-wrap justify-content-end">
                                                            <button
                                                                type="button"
                                                                className="btn form-btn secondary-btn"
                                                                onClick={() => {
                                                                    setFormValue("name", billingInfo.name);
                                                                    setFormValue("country", billingInfo.country);
                                                                    setFormValue("address", billingInfo.address);
                                                                    setFormValue("city", billingInfo.city);
                                                                    resetFormErrors();
                                                                    setBillingEditable(false);
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn form-btn"
                                                                onClick={updateBillingInfo}
                                                            >
                                                                Save
                                                                {billingLoading && (
                                                                    <i
                                                                        className="fa fa-spinner fa-spin"
                                                                        aria-hidden="true"
                                                                        style={{ marginLeft: "5px" }}
                                                                    ></i>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        {!isBillingEditable && (
                                            <div className="bill-inactive-wrap">
                                                <div className="row">
                                                    <div className="col-md-6" ref={firstErrorRef == "name" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field" >
                                                                <label>Name/Company name</label>
                                                                <p>{billingInfo.name ? billingInfo.name : '-'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field inactive-text-wrp">
                                                                <label>Your email</label>
                                                                <p>{user.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" ref={firstErrorRef == "country" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Country</label>
                                                                <p>
                                                                    {billingInfo.country
                                                                        ? billingInfo.country.label
                                                                        : "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" ref={firstErrorRef == "city" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>City</label>
                                                                <p>{billingInfo.city ? billingInfo.city : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" ref={firstErrorRef == "address" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field mb-0">
                                                                <label>Address </label>
                                                                <p>{billingInfo.address ? billingInfo.address : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    }
                                </div>
                                <div className="target-group-info-repeat">
                                    <h3>Billing Information</h3>
                                    <p>
                                        Your billing informations are required for invoicing pursposes.
                                    </p>
                                </div>
                            </div>
                            <div className="target-group-data-repeat  pay-on-fly-coupon-code">
                                <div className="targer-group-left-wrap">
                                    <h3>Promo Code</h3>
                                    <div className="col-md-12">
                                        <div className="promocode-field-wrap">
                                            {order.discount == 0 &&
                                                <>
                                                    <InputField
                                                        label="Promo Code"
                                                        onChange={set("promo_code")}
                                                        onClear={() => {
                                                            setTimeout(() => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    promo_code: ""
                                                                })
                                                            }, 1000);
                                                        }}
                                                        value={formValues.promo_code}
                                                        // maxLength="50"
                                                        id="promo_code"
                                                        error={formErrors.promo_code}
                                                        error_class={formErrors.error_class}
                                                    />

                                                    <div className="apply-promo-code-btn d-flex justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="button primary-btn m-0"
                                                            onClick={() => {
                                                                applyCouponApi();
                                                            }}
                                                            id="r_promo_code"
                                                        >
                                                            Apply
                                                            {couponLoading && (
                                                                <i
                                                                    className="fa fa-spinner fa-spin"
                                                                    aria-hidden="true"
                                                                    style={{ marginLeft: "5px" }}
                                                                ></i>
                                                            )}
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                            {order.discount > 0 &&
                                                <div id="r_promo_code_applied" className="applied-promo-code-wrap">
                                                    <>
                                                        <label>Promo code</label>

                                                        <p className="promocode-text" id="r_promo_code_applied"><i class="fa fa-check-circle" aria-hidden="true"></i>
                                                            Promo code applied : <span>{order.coupon_code}</span></p>

                                                        <span className="remove-promocode-icon">
                                                            <img
                                                                src={process.env.REACT_APP_URL + "images/trash.svg"}
                                                                alt="trash-icon" onClick={() => {
                                                                    removePromoCode();
                                                                }}
                                                            />
                                                        </span>
                                                        <br />
                                                        <div className={"promocode_description"}><p>{order.promo_code_desc}</p></div>


                                                    </>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </>
                            }
                            {order &&
                            <div className="target-group-data-repeat  pay-on-fly-order-summary">
                                <div className="targer-group-left-wrap">
                                    <h3>Order Summary</h3>
                                    <div className="publish-credits-wrap">
                                        <table>
                                            <tbody>
                                                {order.participants > 0 &&
                                                <tr>
                                                    <td>Recruitment (<strong>{order.participants}</strong> participants from UserQ panel)</td>
                                                    <td><strong>{order.recruitment_fees} Credits</strong></td>
                                                </tr>
                                                }
                                                <tr>
                                                    <td>Test publishing fee</td>
                                                    <td><strong>{order.publishing_fees} Credits</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Total required</td>
                                                    <td><strong>{order.total_fees} Credits</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Available in your wallet</td>
                                                    <td><strong>{order.credits_balance} Credits</strong></td>
                                                </tr>
                                                

                                                <tr>
                                                    <td>Outstanding Amount</td>
                                                    <td className={`${order.amount > 0 ? 'pink-color' :''} `}>
                                                        <div className="outstanding-amount">
                                                            <strong>{order.recruitment_fees + order.publishing_fees - order.credits_used} Credits</strong>
                                                        
                                                        </div>
                                                    </td>
                                                </tr>
                                                {order.discount > 0 &&
                                                <tr>
                                                    <td>
                                                        Discount
                                                    </td>
                                                    <td>
                                                        <strong>${order.discount} </strong>
                                                        
                                                    </td>
                                                </tr>
                                                }
                                                <tr  className={`pay-on-fly-order-summary-total`}>
                                                    <td>Totat cost</td>
                                                    <td>
                                                        <div className="outstanding-amount-total">
                                                        {order.discount >0 &&
                                                        <span className="crdis-price-text">
                                                            ${order.recruitment_fees + order.publishing_fees - order.credits_used}
                                                        </span>
                                                        }
                                                        <strong>${order.amount}</strong>
                                                        
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><strong>1 credit = $1</strong></td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        
                                    </div>

                                </div>
                                <div className="target-group-info-repeat">
                                    <h3>Do you want to save more on this order?</h3>
                                    <p>
                                        Earn up to 20% discount when <Link to="/researcher/credits/buy-credits">buying credits in bulk</Link>. 
                                    </p>
                                </div>
                            </div>
                            }
                            {order && order.amount > 0 &&
                            <div className="target-group-data-repeat  pay-on-fly-payment-methods">
                                <div className="targer-group-left-wrap">
                                    <h3>Your payment methods</h3>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {renderPaymentMethods()}
                                                {user.permission != "Editor" && (
                                                    <>
                                                        {!addNewCard && (
                                                            <div
                                                                className="add-new-card-option mb-0"
                                                                onClick={() => {
                                                                    setAddNewCard(true);
                                                                }}
                                                            >
                                                                <div className="create-btn add-new-card-btn">
                                                                    Add new card{" "}
                                                                    <span>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/plus-white.svg"
                                                                            }
                                                                        />
                                                                    </span>{" "}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {addNewCard == true && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="add-card-field-hold">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <div className="position-relative w-100">
                                                                        <Cleave
                                                                            id="card-number"
                                                                            options={{
                                                                                creditCard: true,
                                                                                onCreditCardTypeChanged: (type) => {

                                                                                    setFormValues({ ...formValues, card_type: type })
                                                                                }
                                                                            }}
                                                                            onChange={set("card_no")}
                                                                            className={`form-control ${formErrors.card_no != null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                                } `}
                                                                            value={formValues.card_no}
                                                                            onKeyUp={(e) => {
                                                                                validateCreditCard(e.target.value);
                                                                            }}


                                                                        />


                                                                        <label htmlFor="card-number">
                                                                            Card number
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue("card_no")}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path className="line" d="M2 2L22 22" />
                                                                                <path className="long" d="M9 15L20 4" />
                                                                                <path className="arrow" d="M13 11V7" />
                                                                                <path className="arrow" d="M17 11H13" />
                                                                            </svg>
                                                                        </span>
                                                                        </div>
                                                                        {formValues.card_type && formValues.card_type != 'unknown' &&
                                                                            <span className="selected_card_type">
                                                                                <img src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/" + formValues.card_type + "-icon.svg"
                                                                                } />
                                                                            </span>
                                                                        }
                                                                        {formErrors.card_no != null && (
                                                                            <span className={formErrors.error_class}>
                                                                                {formErrors.card_no}
                                                                            </span>
                                                                        )}
                                                                        <div className="card-secure-text">
                                                                            <img src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/" + "lock-icon.svg"
                                                                                } />
                                                                                <span>This is a secure 128-bit SSL encrypted payment</span>
                                                                                </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <input
                                                                            type="text"
                                                                            id="cardholder-name"
                                                                            className={`form-control ${formErrors.card_name != null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                                } `}
                                                                            value={formValues.card_name}
                                                                            onChange={set("card_name")}
                                                                        />
                                                                        <label htmlFor="cardholder-name">
                                                                            Cardholder’s name
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue("card_name")}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path className="line" d="M2 2L22 22" />
                                                                                <path className="long" d="M9 15L20 4" />
                                                                                <path className="arrow" d="M13 11V7" />
                                                                                <path className="arrow" d="M17 11H13" />
                                                                            </svg>
                                                                        </span>
                                                                        {formErrors.card_name != null && (
                                                                            <span className={formErrors.error_class}>
                                                                                {formErrors.card_name}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <Cleave
                                                                            id="expiration-date"
                                                                            options={{
                                                                                date: true,
                                                                                datePattern: ["m", "y"],
                                                                            }}
                                                                            onChange={set("exp_date")}
                                                                            className={`form-control ${formErrors.exp_date != null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                                } `}
                                                                            value={formValues.exp_date}
                                                                        />
                                                                        <label htmlFor="expiration-date">
                                                                            Expiration date mm/yy
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue("exp_date")}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path className="line" d="M2 2L22 22" />
                                                                                <path className="long" d="M9 15L20 4" />
                                                                                <path className="arrow" d="M13 11V7" />
                                                                                <path className="arrow" d="M17 11H13" />
                                                                            </svg>
                                                                        </span>
                                                                        {formErrors.exp_date != null && (
                                                                            <span className={formErrors.error_class}>
                                                                                {formErrors.exp_date}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <div className="position-relative w-100">
                                                                            {formValues.card_type == "amex" &&
                                                                                <Cleave
                                                                                    id="cvv"
                                                                                    options={{
                                                                                        blocks: [4],
                                                                                        numericOnly: true,
                                                                                    }}
                                                                                    onChange={set("cvv")}
                                                                                    className={`form-control ${formErrors.cvv != null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                        } `}
                                                                                    value={formValues.cvv}
                                                                                />
                                                                            }
                                                                            {formValues.card_type != "amex" &&
                                                                                <Cleave
                                                                                    id="cvv"
                                                                                    options={{
                                                                                        blocks: [3],
                                                                                        numericOnly: true,
                                                                                    }}
                                                                                    onChange={set("cvv")}
                                                                                    className={`form-control ${formErrors.cvv != null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                        } `}
                                                                                    value={formValues.cvv}
                                                                                />
                                                                            }
                                                                            <label
                                                                                className="control-label"
                                                                                htmlFor="cvv"
                                                                            >
                                                                                CVV/CVC
                                                                            </label>
                                                                            <span
                                                                                className="clear"
                                                                                onClick={clearFormValue("cvv")}
                                                                            >
                                                                                <svg viewBox="0 0 24 24">
                                                                                    <path
                                                                                        className="line"
                                                                                        d="M2 2L22 22"
                                                                                    />
                                                                                    <path
                                                                                        className="long"
                                                                                        d="M9 15L20 4"
                                                                                    />
                                                                                    <path
                                                                                        className="arrow"
                                                                                        d="M13 11V7"
                                                                                    />
                                                                                    <path
                                                                                        className="arrow"
                                                                                        d="M17 11H13"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                            {formErrors.cvv != null && (
                                                                                <span
                                                                                    className={formErrors.error_class}
                                                                                >
                                                                                    {formErrors.cvv}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div className="d-flex justify-content-end cvv-holder">
                                                                            <span tooltip="CVV is the last three digits on the back of your credit card.">
                                                                                What is cvv/cvc?{" "}
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_URL +
                                                                                        "images/info-icon.svg"
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"row"}>
                                                            <div className="col-md-12">
                                                                <div className="flex-buttons-wrap justify-content-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn form-btn secondary-btn"
                                                                        onClick={() => {
                                                                            setFormValue("card_name", "");
                                                                            setFormValue("card_no", "");
                                                                            setFormValue("cvv", "");
                                                                            setFormValue("exp_date", "");
                                                                            resetFormErrors();
                                                                            setAddNewCard(false);
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn form-btn"
                                                                        onClick={addNewPaymentMethod}
                                                                    >
                                                                        Save
                                                                        {cardFormLoading && (
                                                                            <i
                                                                                className="fa fa-spinner fa-spin"
                                                                                aria-hidden="true"
                                                                                style={{ marginLeft: "5px" }}
                                                                            ></i>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="target-group-info-repeat">
                                    <h3>Payment methods</h3>
                                    <p>
                                        Save time on your next purchase by saving your preferred payment method. 
                                    </p>
                                </div> 
                            </div>
                            }

                            <div className="target-group-data-repeat pay-on-fly-form-button-wrapper">
                                <div className="button-wrap-publish-btn">
                                    {order.amount > 0 &&
                                    <div className="card-secure-text">
                                        <img src={
                                                process.env.REACT_APP_URL +
                                                "images/" + "lock-icon.svg"
                                            } />
                                        <span>This is a secure 128-bit SSL encrypted payment</span>
                                    </div>
                                    }
                                    <button
                                        id="r_create_step_6_build_next_btn"
                                        type="submit"
                                        className={`button primary-btn ${(paymentMethods.length ==0 && order.amount>0) ? 'disabled-btn' : ''}`}
                                        onClick={()=>{checkPublishConditions() }}
                                    >
                                        Publish {order.amount > 0 && <>for ${order.amount}</>}
                                        {formLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                aria-hidden="true"
                                                style={{ marginLeft: "5px" }}
                                            ></i>
                                        )}
                                    </button>
                                </div>
                            </div>  
                        </div>
                    }
                </div>
            </div>
            <PublishModal 
                open={publishModal.open} 
                close={() => {
                    setPublishModal({ ...publishModal, open: false });
                }}
                confirm={publishModal.confirm}
            />
            <ConfirmModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title="Delete"
                confirm_title="Delete Card"
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deletePaymentMethod(confirmModal.target_id);
                    if (formValues.payment_method_id == confirmModal.target_id) {
                        setFormValue("payment_method_id", "");
                    }
                }}
            />
        </LayoutResearcherFullWidth>
    );
}
