export default function PreferenceTestIntroduction({ goToTask , test}) {
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap task-tree-testpage-wrap ">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                    <div className="tester-screens-hold">
                        <div className="tester-selected-page-wrap">
                            <div className="selected-page-right-side">
                                <div className="tester-page-selected-img-wrap pref-image-top-wrap">
                                    <div className="card-sort-test-img-view pref-test-prev-welcome-img">
                                        <div className="pv-img-1">
                                            <img src={process.env.REACT_APP_URL + "images/vs-img.png"} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="selected-page-left-side">
                                <h1>{test.language=="en"?"Preference Test":"اختبار التفضيل"}</h1>
                                <p>
                                {test.language=="en"?"We’ll ask your preference between some options that will be shown. Before indicating your choice, please take a moment to carefully review the options. "
                                :"في هذا التمرين ، سنطلب منك الاختيار بين التصميمات المختلفة التي ستظهر لك. قبل الإشارة إلى ما تفضله ، يرجى تخصيص بعض الوقت لمراجعة كل حل مقدم بعناية."}
                                    <br /><br />
                                    {test.language=="en"?"Remember, there is no right or wrong answer here - just choose what comes naturally. "
                                    :
                                    "تذكر - لا توجد إجابة صحيحة أو خاطئة هنا ، فقط افعل ما يأتي بشكل طبيعي."}
                                </p>
                                <div className="button-wrapper none-for-mobile">
                                    <button className="button primary-btn ml-0 " onClick={() => { goToTask() }}>
                                        {test.language=="en" &&
                                        <>Let’s Start</>
                                        }
                                        {test.language=="ar" &&
                                        <>لنبدأ</>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    <button className="button primary-btn ml-0" onClick={() => { goToTask() }}>
                        {test.language=="en" &&
                        <>Let’s Start</>
                        }
                        {test.language=="ar" &&
                        <>لنبدأ</>
                        }
                    </button>
                </div>
            </div>
            
        </div>

    );
}