//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {duplicateTest} from "../../../../services/test";


export default function CopyTest({openModal,closeModal,test}) {
    const navigate = useNavigate();

    const [copyTestLoading, setCopyTestLoading] = useState(false);

    const copyTestHandler = ()=>{
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);

        setCopyTestLoading(true);
        duplicateTest(data, token).then((response) => {
            setCopyTestLoading(false);

            if (response.success) {
                navigate("/researcher/tests/" + response.new_test.id + "/introduction");
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }
    return (
        <Modal
            show={openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal copytest-modal-wrap"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64 text-center">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <h2 className="h2">Copy Test</h2>
                    <p className="w-100">Are you sure you want to make a copy for this test?</p>

                    <div className="button-wrap d-flex nda-agreement-modal-button mt-32">
                        <button type="button" onClick={() => {
                            closeModal(false);
                        }} className="button secondary-btn">back to test</button>
                        <button type="button" onClick={(e)=>copyTestHandler()}
                                className={`button  primary-btn`}>
                            copy test
                            {copyTestLoading && (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                ></i>
                            )}
                        </button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}