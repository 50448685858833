import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";

export default function Options(props) {
  const [optionError, setOptionError] = useState("");
  const [optionErrorIndex, setOptionErrorIndex] = useState(null);
  const [otherOptionIncluded, setOtherOptionIncluded] = useState(false);
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});

  const handleDragStart = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
    return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
    ),
    });
};

const handleDragUpdate = (event) => {
    if (!event.destination) {
    return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
    return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
    ...childrenArray.slice(0, destinationIndex),
    movedItem,
    ...childrenArray.slice(destinationIndex + 1),
    ];



    var clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
    }, 0);

    setPlaceholderProps({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
    ),
    });
};

const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
};
  

  return (
    props.questionType != "likertscale" ?
    props.questionType != "ratingscale"?
    <div style={{position:'relative'}}>
      <DragDropContext
        onDragEnd={(param) => {
          const srcI = param.source.index;
          const desI = param.destination.index;
          props.allOptions.splice(desI, 0, props.allOptions.splice(srcI, 1)[0]);

          setPlaceholderProps({});
        }}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
          {props.language=="ar"?
        <Droppable droppableId="droppable-1">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props.allOptions.map((option, index) =>
                (option != "آخر") ? (
                  <Draggable
                    key={index}
                    draggableId={"optiondraggable-" + index}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`ans-list-repeat saved-ans-repeat ${
                          snapshot.isDragging ? "question-answers-dragging" : ""
                        } `}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="ans-icon" {...provided.dragHandleProps}>
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/drag-icon.svg"
                            }
                            alt="img"
                          />
                        </div>
                        <div className="ans-data">
                          <input
                            className={`${
                              option != ""
                                ? ""
                                : optionErrorIndex == index
                                ? optionError
                                : ""
                            } ${
                              props.language == "ar" ? "arabic_wrapper" : ""
                            }`}
                            type="text"
                            onChange={(e) => {
                              props.save(index, e.target.value);
                            }}
                            value={option}
                            // placeholder={`option ${index + 1}`}
                            placeholder="Please enter an option"
                            onBlur={(e) => {
                              if (props.allOptions[index] == "") {
                                e.target.placeholder =
                                  "Please enter an option";
                                // setOptionError("input_error");
                                // setOptionErrorIndex(index);
                              } else {
                                props.save(index, e.target.value);
                              }
                            }}
                            onKeyDown={(e)=>{
                                if (e.key === 'Enter') {
                                    props.addOption(index);
                                  }
                            }}
                            autoFocus={props.allOptions.length==1?false:true}
                          />
                        </div>
                        
                        <div className="ans-close-row">
                        <span onClick={() => props.close(index)}>
                          <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="img"
                            
                          />
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ) : (
                  setOtherOptionIncluded(true)
                )
              )}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && (
                    <div
                    className="placeholder"
                    style={{
                         top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                        position:"absolute",
                        borderColor: "#000000",
                    }}
                    />
                )}
            </div>
          )}
        </Droppable>
        :
        <Droppable droppableId="droppable-1">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props.allOptions.map((option, index) =>
                (option != "Other") ? (
                  <Draggable
                    key={index}
                    draggableId={"optiondraggable-" + index}
                    id={"draggable-" + index}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`ans-list-repeat saved-ans-repeat ${
                          snapshot.isDragging ? "question-answers-dragging" : ""
                        } `}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="ans-icon" {...provided.dragHandleProps}>
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/drag-icon.svg"
                            }
                            alt="img"
                          />
                        </div>
                        <div className="ans-data">
                          <input
                            className={`${
                              option != ""
                                ? ""
                                : optionErrorIndex == index
                                ? optionError
                                : ""
                            } ${
                              props.language == "ar" ? "arabic_wrapper" : ""
                            }`}
                            type="text"
                            onChange={(e) => {
                              props.save(index, e.target.value);
                            }}
                            value={option}
                            // placeholder={`option ${index + 1}`}
                            placeholder="Please enter an option"
                            onBlur={(e) => {
                              if (props.allOptions[index] == "") {
                                e.target.placeholder =
                                  "Please enter an option";
                                // setOptionError("input_error");
                                // setOptionErrorIndex(index);
                              } else {
                                props.save(index, e.target.value);
                              }
                            }}
                            onKeyDown={(e)=>{
                                if (e.key === 'Enter') {
                                    props.addOption(index);
                                  }
                            }}
                            autoFocus={props.allOptions.length==1?false:true}
                          />
                        </div>
                        
                        <div className="ans-close-row">
                        <span onClick={() => props.close(index)}>
                          <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="img"
                            
                          />
                        </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ) : (
                  setOtherOptionIncluded(true)
                )
              )}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && (
                    <div
                    className="placeholder"
                    style={{
                         top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                        position:"absolute",
                        borderColor: "#000000",
                    }}
                    />
                )}
            </div>
          )}
        </Droppable>}
      </DragDropContext>
      {otherOptionIncluded && (
        <div className="ans-list-repeat saved-ans-repeat">
            {props.language == "ar"?(<div className="ans-data arabic-ans-data arabic-wrapper">آخر</div>):(<div className="ans-data">Other</div>)
          }
          <div className="ans-close-row">
            <span onClick={() => {
                props.closeOtherOption();
                setOtherOptionIncluded(false);
              }}>
            <img
              src={process.env.REACT_APP_URL + "images/cross.svg"}
              alt="img"
              
            />
            </span>
          </div>
        </div>
      )}
    </div>
    :
    <>
    {props.allOptions.map((option, index) =>(
    
        <label for="radio4">
        <input
        key={index}
        className={`${
        option != ""
            ? ""
            : optionErrorIndex == index
            ? optionError
            : ""
        } ${
        props.language == "ar" ? "arabic_wrapper" : ""
        }`}
        type="text"
        onChange={(e) => {
        props.save(index, e.target.value);
        }}
        value={option}
        // placeholder={`option ${index + 1}`}
        placeholder="Please enter an option"
        onBlur={(e) => {
        if (props.allOptions[index] == "") {
            e.target.placeholder =
            "Please enter an option";
            // setOptionError("input_error");
            // setOptionErrorIndex(index);
        } else {
            props.save(index, e.target.value);
        }
        }}
        />
        <input  type="radio" name="ans"  className="hidden radio_btn_input"  required="required" />
        <span>
            <img
            className="empty-fill-icon"
            src={
            process.env.REACT_APP_URL +
            "images/Polygon-blank.svg"
            }
            />
            <img
            className="fill-icon"
            src={
            process.env.REACT_APP_URL +
            "images/pol-fill.svg"
            }
            />
        </span>
        </label>
        ))
        }                                
    </>
    :
    <>
    {props.allOptions.map((option, index) =>(
        <input
        key={index}
        className={`${
        option != ""
            ? ""
            : optionErrorIndex == index
            ? optionError
            : ""
        } ${
        props.language == "ar" ? "arabic_wrapper" : ""
        }`}
        type="text"
        onChange={(e) => {
        props.save(index, e.target.value);
        }}
        value={option}
        // placeholder={`option ${index + 1}`}
        placeholder="Please enter an option"
        onBlur={(e) => {
        if (props.allOptions[index] == "") {
            e.target.placeholder =
            "Please enter an option";
            // setOptionError("input_error");
            // setOptionErrorIndex(index);
        } else {
            props.save(index, e.target.value);
        }
        }}
        />
    ))
    }
  </> 
  );
}
