import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import Layout from "../layouts/layout";
import LayoutResearcher from "../layouts/layout_researcher";

export default function UnderMaintenace({errorType}) {
    const [counter,setCounter] = useState(10);
    const navigate = useNavigate();


    useEffect(() => {
        clearCacheData();

    }, []);

    const clearCacheData = () => {
        var date = new Date;
    
        fetch("/meta.json?"+date.getTime())
        .then((response) => response.json())
        .then((meta) => {
            if(meta.maintenance_mode!=1){
                navigate("/");
            } 
        });
    
    };
    const errorHtml = () =>{
        return (
            <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <p className="error-small-text">
                           Did you know on an average, websites are down for 3 hours per month?
                        </p>
                        <h1 className="form-heading">
                            This is Sad

                        </h1>
                        <p className="error-small-text">
                           UserQ is down for maintenance please check back soon.
                        </p>
                    </div>
                </div>
                <div className="full home-marquee-banner section">
                    <div className="home-marque-banner-box">
                        <div className="scrolling-box"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                        <div className="scrolling-box" aria-hidden="true"> Under Maintenace</div>
                    </div>
                    
                </div>

            </div>
        );
    }

    return (
        <>
            

            

                <div className="login-page-wrapper 404-err0r-wrap under_maintenance_wrap">
                    <div className="error-inner-404">
                        { errorHtml()}
                    </div>
                </div>
            
            
            </>
       );
}
