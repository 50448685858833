import React, { useState, useEffect } from 'react';
import { Link, useNavigate,useSearchParams } from 'react-router-dom';

//import { preventClick } from '../../../lib/helpers';
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import { FormattedMessage } from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";
import { load } from 'recaptcha-v3'
let captcha = null;

export default function Register() {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    ReactSession.setStoreType("localStorage");

    const [errorMsg, setErrorMessage] = useState(null);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({first_name:'', last_name:'', email:ReactSession.get('register_email'), password:'', newsletter: true, terms:false, role:'Tester',referralCode:''});

    const [formErrors, setFormErrors] = useState({password:null, passwordErrorStyle: {color:'#000'}, terms:null});

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    const emailAlreadySet = ReactSession.get('register_email') ? true : false;

    useEffect(() => {
        window.animate();
        document.title = process.env.REACT_APP_NAME+" - Register";

        if(!ReactSession.get('register_email')){
            navigate("/researcher/login");
        }
        /*const fpPromise = import('https://openfpcdn.io/fingerprintjs/v3')
        .then(FingerprintJS => FingerprintJS.load())

        fpPromise
            .then(fp => fp.get())
            .then(result => {
                // This is the visitor identifier:
                const visitorId = result.visitorId
                console.log(visitorId)
            })*/
            regenerateCaptcha();
    }, []);
    const regenerateCaptcha = ()=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                captcha = token;
    
                setFormValues({...formValues, captcha:captcha});
                
            })
        })
    }
    const generateCaptcha = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('register').then((token) => {
                captcha = token;
    
                setFormValues({...formValues, captcha:captcha});
                
                callback(token);
                
            })
        })
    }

    const registerUser = () => {
        
        setLoading(true);

        generateCaptcha(function(token){
            
            formValues.captcha = token;

            
            setErrorMessage(null);

            var body = formValues;

            body['role'] = 'Tester';
            body['referralCode'] = searchParams.get('refID');
            body['test_result_id'] = ReactSession.get("test_result_id");

            body["fingerprint"] = window.fingerprintvisitorid;
            
            fetch(process.env.REACT_APP_API_END_POINT+'register', {
                method: 'POST',
                body: JSON.stringify(body),
                headers:{"Content-Type":"application/json"}
            })
            .then(res => res.json())
            .then(response => {
                //regenerateCaptcha();
                setLoading(false);
                if(response.success){
                    ReactSession.set("register_email","");
                    setFormValues({first_name:'', last_name:'', email:'', password:'', newsletter: true, terms:false, role:"Tester"});
                    navigate("/tester/registration/success");
                    /*setModal({
                    modalHeading: "Thanks for signing up!",
                    modalMessage: response.message,
                    modalOpen: true,
                    });*/                

                } else {
                    setErrorMessage(response.message);
                }
            });
        })
    }
    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = () => {
        
        var error = false;
            
        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;
        
        var form_errors = resetFormErrors();
        var firsterrorid=null;
        
        if(formValues.first_name==''){       
            
            form_errors = {
                ...form_errors,
                first_name: "Required field",  
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"first-name":firsterrorid;

        }
        if(formValues.last_name==''){

            form_errors ={
                ...form_errors,
                last_name: "Required field",  
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"last-name":firsterrorid;
        } 
        if(formValues.company_name==''){
            form_errors ={
                ...form_errors,
                company_name: "Required field",  
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"company_name":firsterrorid;
            
        } 
        if(formValues.email==''){

            form_errors ={
                ...form_errors,
                email: "Required field",  
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"email":firsterrorid;
        }
        else if(!formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            
            form_errors ={
                ...form_errors,
                email: "Please enter a valid email",  
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"email":firsterrorid;
        }
        if(formValues.password.length<6 || !formValues.password.match(re)){
            
            form_errors ={
                ...form_errors,
                password: "Password",  
                error_class: "input_error",
                password_error_class:"password_error", 
            };
            error = true;
            firsterrorid=firsterrorid==null?"pass_log_id":firsterrorid;
        } else {
            form_errors ={
                ...form_errors,
                password: null,  
                error_class: "input_error",
                password_error_class:"none", 
            };
        }
        if(!formValues.terms){
            
            setErrorMessage(<FormattedMessage id="You must agree to our Terms and Conditions and Privacy Policy" />);
            error = true;
            
        }
        setFormErrors(form_errors);

        if(firsterrorid!=null){
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
        }

        return error;
    }
    const onSubmit =  async (event) => {

        event.preventDefault();


        if(formValues.terms){
            

            setErrorMessage(null);

            if(!isLoading){

                var error = validateFullForm();

                if(!error){
                    registerUser();
                }
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const toggleCheck = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
      };
    const validateForm = (name) => {
        
        
        return (event)=>{
            
            setFormValues(oldValues => ({...oldValues, [name]: event.target.value }));
            

            var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

            if(formValues.password.length<6 || !formValues.password.match(re)){
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  'password_error' }));    
            } else {
                setFormErrors(oldValues => ({...oldValues, ['password_error_class']:  'none' }));   
            } 
        }
        
    }
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
   
    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_TESTER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
                {/*<div className="login-hdr-right">
                    <Link to="/researcher/register">Become a researcher</Link>
                </div>*/}
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <h1 className="form-heading">Create a new account</h1>
                        <p className="form-sub-head">Get paid for your insights</p>
                        <div className="login-form-inner-data">
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <form method='POST' onSubmit={onSubmit} className="mt-4">
                                <div className="input input-field form-group ">
                                    <input type="text" id="first-name" className={formErrors.first_name!=null ? "form-control "+formErrors.error_class : "form-control"} name='first_name' value={formValues.first_name} onChange={set('first_name')}  />
                                    <label htmlFor="first-name">First name <img src={process.env.REACT_APP_URL+"images/star-field.svg" } className="required-field-icon" alt="required-field"/></label>
                                    <span className="clear" onClick={clearFormValue('first_name')}>
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22" />
                                            <path className="long" d="M9 15L20 4" />
                                            <path className="arrow" d="M13 11V7" />
                                            <path className="arrow" d="M17 11H13" />
                                        </svg>
                                    </span>
                                    {(formErrors.first_name!=null) &&
                                        <span className={formErrors.error_class}>{formErrors.first_name}</span>
                                    }
                                </div>
								<div className="input input-field form-group ">
                                    <input type="text" id="last-name" name='last_name' className={formErrors.last_name!=null ? "form-control "+formErrors.error_class : "form-control"} value={formValues.last_name} onChange={set('last_name')}  />
                                    <label htmlFor="last-name">Last name <img src={process.env.REACT_APP_URL+"images/star-field.svg" } className="required-field-icon" alt="required-field"/></label>
                                    <span className="clear" onClick={clearFormValue('last_name')}>
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22" />
                                            <path className="long" d="M9 15L20 4" />
                                            <path className="arrow" d="M13 11V7" />
                                            <path className="arrow" d="M17 11H13" />
                                        </svg>
                                    </span>
                                    {formErrors.last_name &&
                                        <span className={formErrors.error_class}>{formErrors.last_name}</span>
                                    }
                                </div>
                                <div className={`input input-field form-group ${emailAlreadySet ? "read-only-field" : ""}`}>
                                    <input type="text" id="email" readOnly={emailAlreadySet} className={formErrors.email!=null ? "form-control "+formErrors.error_class : "form-control"} name='email' value={formValues.email} onChange={set('email')}  />
                                    <label htmlFor="email">Your email <img src={process.env.REACT_APP_URL+"images/star-field.svg" } className="required-field-icon" alt="required-field"/></label>
                                    <span className="clear" onClick={clearFormValue('email')}>
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22" />
                                            <path className="long" d="M9 15L20 4" />
                                            <path className="arrow" d="M13 11V7" />
                                            <path className="arrow" d="M17 11H13" />
                                        </svg>
                                    </span>
                                    {emailAlreadySet &&
                                        <div className="data-field-icon">
                                        <img src={process.env.REACT_APP_URL+"images/fill-check.svg"} alt="icon" />
                                        </div>
                                    }
                                    {formErrors.email &&
                                        <span className={formErrors.error_class}>{formErrors.email}</span>
                                    }
                                </div>

                                <div className=" input-field form-group  mb-0 password-margin-top">
                                    <div className='position-relative w-100'>
                                        <input type={passwordVisible ? "text" : "password"} className={formErrors.password_error_class!=null ? "form-control "+formErrors.password_error_class : "form-control"} id="pass_log_id" name='password' value={formValues.password} onChange={set('password')} onKeyUp={validateForm('password')}  />
                                        <i className="toggle-password" onClick={togglePassword}><img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="eye"/></i>
                                        <label htmlFor="pass_log_id">Password <img src={process.env.REACT_APP_URL+"images/star-field.svg"} className="required-field-icon" alt="required-field"/></label>
                                        
                                    </div>
                                    {formErrors.password_error_class!='none' && 
                                    <p className={formErrors.password_error_class!=null ? "password-guide-text "+formErrors.password_error_class : "password-guide-text "} style={formErrors.passwordErrorStyle}>
                                    Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)</p>
                                    }
                                </div>
                                <div className="account-term-and-condition-wrap">
                                    <div className="form-group custom-control custom-checkbox">
                                        <input type="checkbox" checked={formValues.newsletter} onChange={(event)=>{toggleCheck('newsletter',event.currentTarget.checked)}} className="custom-control-input" id="newsletterCheckBox" />
                                        <label className="custom-control-label" htmlFor="newsletterCheckBox">Subscribe to our awesome newsletter on all things UX</label>
                                    </div>
                                    <div className="form-group custom-control custom-checkbox">
                                        <input type="checkbox" checked={formValues.terms} onChange={(event)=>{toggleCheck('terms',event.currentTarget.checked)}} className="custom-control-input " id="termsCheckBox" />
                                        <label className="custom-control-label line-height-24" htmlFor="termsCheckBox">I agree to the  <b><a href={process.env.REACT_APP_SITE_URL+"terms-of-service"} target="_blank">Terms of Use</a></b> and have read the <b><a href={process.env.REACT_APP_SITE_URL+"privacy-policy"} target="_blank">Privacy Policy</a></b></label>
                                    </div>
                                    {/*<div className='captcha-wrapper'>
                                        <ReCAPTCHA
                                            sitekey="6LdFARUhAAAAAC3FE7vHo_uPt1Kk523M-ZhxX3y-"
                                            onChange={(captcha) => {
                                                setFormValues({...formValues,captcha:captcha});
                                            }}
                                        />
                                        </div>*/}
                                </div>
                                
                                <button type="submit" id='t_registration' className={`btn btn-block form-btn ${formValues.terms!=true?'disabled-button':''}`}>
                                    Continue
                                    {isLoading &&
                                        <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                    }
                                </button>
                                <div className="login-bottom-link researcher-btn-register">
                                    <Link to="/researcher/login">Are you a researcher?</Link>
                                </div>
                            </form>
                            <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap email-sent-modal">
                                <Modal.Body> 
                                    <div className="modal-inner-text">
                                        <button type="button" className="close" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } aria-label="Close">
                                            <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                                        </button>
                                        <h2>{modal.modalHeading}</h2>
                                        <p>{modal.modalMessage}</p>
                                        {/*<div className="email-ver-count">
                                        02:35
                                        </div>
                                        <button type="submit" className="resend-email-btn">Resend Email</button>*/}
                                        <Link to="/tester/login" className="btn btn-block form-btn">BACK TO SIGN IN</Link>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}