export default function TreeSortIntroduction({goToFirstTask, test}){
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap task-tree-testpage-wrap">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                    <div className="tester-screens-hold">
                        <div className="tester-selected-page-wrap">
                            <div className="selected-page-right-side">
                                <div className="task-selected-tree-view">
                                    <div className="test-question-radiocheck-wrap test-selected-main-tree">
                                        <img src={process.env.REACT_APP_URL+"images/resting-multiple-nodes.png"} alt="img" className="tree-sort-image" />
                                    </div>
                                </div>
                            </div>
                            <div className="selected-page-left-side">
                                <h1>
                                    {test.language=="en" &&
                                    <>Tree testing</>
                                    }
                                    {test.language=="ar" &&
                                    <>اختبار الشجرة</>
                                    }
                                
                                </h1>
                                {test.language=="en" &&
                                <p>You’ll be asked to find certain items after being presented with a list of links. Click through the list, until you find the place where you think the item would be located. <br/><br/>
                                Remember, there is no right or wrong answer here - just choose what comes naturally.
                                </p>
                                }
                                {test.language=="ar" &&
                                <>
                                <p>سيُطلب منك في كل مهمة العثور على عنصر معين بعد تقديمه بقائمة من الروابط.</p>
                                <p>انقر فوق القائمة حتى تصل إلى خيار تعتقد أنه سيساعدك بشكل أفضل في العثور على العنصر.</p>
                                <p>إذا اتخذت منعطفًا عرضيًا - فلا داعي للقلق - يمكنك الرجوع بالنقر فوق أحد الروابط أعلاه.</p>
                                <p>تذكر - ليس هناك إجابة صحيحة أو خاطئة - فقط افعل ما هو طبيعي بالنسبة لك.</p>
                                </>
                                }
                                
                                <div className="button-wrapper none-for-mobile">
                                    <button className="button primary-btn ml-0" onClick={()=>{goToFirstTask()}}>
                                    {test.language=="en" &&
                                    <>Let’s Start</>
                                    }
                                    {test.language=="ar" &&
                                    <>لنبدأ</>
                                    }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    <button className="button primary-btn ml-0" onClick={() => { goToFirstTask() }}>Let’s Start</button>
                </div>
            </div>
        </div>

    );
}