import { scaleSqrt } from "d3";
import { useEffect, useState } from "react";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'


export default function Task({activeTaskIndex, totalTasks, test, tree, path, navPath, skipTask ,task, next, answer, nextTask, fullTree,unsetAnswer, setAnswer, formLoading}){
    
    const [closed, setClosed] = useState('');

    const [node, setNode] = useState(0);

    const [nodeIndex, setNodeIndex] = useState(0);

    const [animating, setAnimating] = useState(false);

    useEffect(()=>{
        console.log(navPath)
    })

    const renderNodes = (index) => {
        var nodes = [];



        var answer_at_same_level = false;

        tree.forEach(item => {
            
            if(answer==item.id){
                answer_at_same_level = true;
            }
        });

        tree.forEach(item => {
            var pathLocal = [...path];
            pathLocal.push(item);

            if(!answer_at_same_level || item.id==answer){
                nodes.push((
                    <div key={"node"+item.id} className={`test-selected-mt-rpt slider ${node!=item.id ? closed : ""} `} 
                        onClick={
                            (e)=>{
                                    e.preventDefault(); 
                                    console.log("going front")
                                    //going front
                                    
                                    if(!animating && answer!=item.id){
                                        
                                        setAnimating(true);

                                        //item.title+

                                        var new_nav_path = [...navPath,"forward",  item.id];
                                         
                                        if(item.children.length>0 ){

                                            setNode(item.id);

                                            setNodeIndex(index);

                                            setClosed("closed");

                                            setTimeout(function(){
                                                
                                                setAnimating(false);
                                                
                                                next(item.children,pathLocal, new_nav_path);
                                                
                                                setTimeout(function(){
                                                   setClosed("fade-in")
                                                },10)

                                            },300)
                                            
                                        } else if(item.children.length==0 ){

                                            setClosed("closed");

                                            setNode(item.id);

                                            setTimeout(() => {
                                                setAnimating(false);
                                               // setClosed("fade-in")
                                                setAnswer(task.id,item.id,new_nav_path);

                                                setTimeout(function(){
                                                    setClosed("fade-in");
                                                },10)
                                            }, 300);

                                            
                                        }
                                        
                                    
                                    }
                                }
                            }>
                        <a href="#" onClick={(e)=>{e.preventDefault()}} className={`sl-mt-link-wrp ${answer==item.id?'test-answer-selected':''}`}>
                        <span className="task-tree-cat-data">{item.title}</span>
                            {/*item.children.length>0 &&
                            <span className="back-tree-icon"><img src={process.env.REACT_APP_URL+"images/plus-icon-black.png"} /></span>
                        Select this as the answer*/}
                            {item.children.length==0 &&
                            <span className={`${answer==item.id?'selected-correct-answer':'select-this-answer-1'}`}>
                                {answer==item.id?<><p className="selected-answer">{test.language=="en"?"I'd find it here":"سأجدها هنا"}</p>  <img src={process.env.REACT_APP_URL+"/images/find-answer-icon.svg"} /></>:''}
                            </span>
                            }
                        </a>
                    </div>
                ))
            }
        });
        return nodes;
    }
    const checkAnswer = () => {
        var answer_at_same_level = false;

        tree.forEach(item => {
            
            if(answer==item.id){
                answer_at_same_level = true;
            }
        });
        return answer_at_same_level;
    }
    const navigateTree = (path, index) => {
        if(index == path.length){
            return (
                <>
               
                    {
                        renderNodes(index)
                    }
                </>
            )
        } else {

            return (
                <>
                    <div key={"node"+path[index].id} className={`test-selected-mt-rpt slider ${index > nodeIndex && node!=path[index].id ? closed : ""} `} 
                        onClick={(e)=>{

                            e.preventDefault();

                            if(path[index].id==-1 && (index == path.length-1 && !checkAnswer()) ){
                                return;
                            }
                            console.log("going back")

                            // going back

                            var answer_selected = false;

                            tree.forEach(function(item){
                                if(item.id==answer){
                                    answer_selected = true;
                                }
                            })
                            if(answer_selected && index == path.length-1){
                                setAnimating(true);
                            
                                setNode(path[index].id);

                                setNodeIndex(index);

                                setAnimating(false);
                                    
                                setClosed("closed");

                                setTimeout(function(){
                                    var pathLocal = [...path];
                                    var new_path = [...path];
                                    var back_path = [];
                                    
                                    var from = pathLocal.length-1;

                                   
                                    var till = 0;

                                    till = index;
                                    
                                    if(till<0){
                                        till = 0;
                                    }
                                    
                                    /*for(var i=from;i>=till;i--){
                                        back_path.push("back");
                                        //pathLocal[i].title+
                                        back_path.push(pathLocal[i].id);
                                    }*/
                                    back_path.push("back");
                                    back_path.push(pathLocal[till].id);

                                    var new_nav_path = [];
                                    
                                    if(navPath[navPath.length-2]=="back" && navPath[navPath.length-1]==-1 && back_path[0]=="back" && back_path[1]==-1 ){
                                        new_nav_path = navPath;
                                    } else {
                                        new_nav_path = navPath.concat(back_path);
                                    }
                                    
                                    //new_path.splice(index);

                                    var new_tree;
                                    if(index == 0){
                                        new_tree = fullTree;

                                        //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:fullTree}];
                                        
                                        var tree_loc = [{"id":-1,"title":test.home_name,children:fullTree}];
                                        

                                        new_path = [tree_loc[0]];

                                    } else {
                                        new_tree = pathLocal[index]["children"];
                                    }
                                    
                                    next(new_tree, new_path, new_nav_path);

                                    unsetAnswer(task.id);
                                    
                                    setClosed("fade-in");
                                },300);
                                
                            }
                            else if(!animating){
                                setAnimating(true);
                            
                                setNode(path[index].id);

                                setNodeIndex(index);
                                            

                                setClosed("closed");

                                setTimeout(function(){
                                    var pathLocal = [...path];
                                    var new_path = [...path];
                                    var back_path = [];
                                    
                                    var from = pathLocal.length-2;

                                
                                    var till = 0;

                                    till = index;

                                    if(index==pathLocal.length-1){
                                        till = index-1;
                                    }
                                    
                                    if(till<0){
                                        till = 0;
                                    }
                                    
                                    /*for(var i=from;i>=till;i--){
                                        back_path.push("back");
                                        //pathLocal[i].title+
                                        back_path.push(pathLocal[i].id);
                                    }*/
                                    back_path.push("back");
                                    back_path.push(pathLocal[till].id);

                                    var new_nav_path = [];
                                    
                                    if(navPath[navPath.length-2]=="back" && navPath[navPath.length-1]==-1 && back_path[0]=="back" && back_path[1]==-1 ){
                                        new_nav_path = navPath;
                                    } else {
                                        new_nav_path = navPath.concat(back_path);
                                    }
                                    if(index==pathLocal.length-1){
                                        new_path.splice(index);
                                    } else {
                                        new_path.splice(index+1);
                                    }
                                    

                                    var new_tree;
                                    if(index == 0){
                                        new_tree = fullTree;

                                        //var tree_loc = [{"id":-1,"title":test.language=="en"?"Home":"القائمة الرئيسية",children:fullTree}];
                                        
                                        var tree_loc = [{"id":-1,"title":test.home_name,children:fullTree}];
                                        

                                        new_path = [tree_loc[0]];

                                    } else {

                                        if(index==pathLocal.length-1){
                                            new_tree = pathLocal[index-1]["children"];
                                        } else {
                                            new_tree = pathLocal[index]["children"];
                                        }
                                        
                                    }
                                    
                                    unsetAnswer(task.id);
                                    next(new_tree, new_path, new_nav_path)

                                    setAnimating(false);
                                    

                                    setTimeout(function(){
                                        setClosed("fade-in");
                                    },300) 
                                    

                                },300)
                            } 
                           
                            
                                
                        }}
                        >
                        <a href="#" onClick={(e)=>{e.preventDefault()}} className={`sl-mt-link-wrp`}>
                            <span className="task-tree-cat-data">{path[index].title}</span>
                            <span className="back-tree-icon" >
                                {index == path.length-1 && !checkAnswer() &&
                                
                                    <img src={process.env.REACT_APP_URL+"images/minus-icon.svg"} />
                                
                                }
                                {!(index == path.length-1 && !checkAnswer()) &&
                                <>&nbsp;</>
                                }
                            </span>                            
                        </a>
                    </div>
                    <div className={`sel-chile-cat-wrap `}>
                        
                        {navigateTree(path, index+1)}
                    </div>
                </>
            )
        }
    }
    return (
        <div className="tester-page-wrapper tester-question-selection-wrap test-selected-main-cat-wrap">
            
            <div className="container inner-page-container">
                
                <div className="tester-screens-hold">
                    <div className="tester-form-steps-wrapper">
                        <div className="tester-form-steps-inner">
                            <div className="taking-test-page-height">
                              <div className="tree-test-top-fixed">
                                <div className="tester-page-wrapper task-step-progress tester-question-selection-wrap test-selected-main-cat-wrap">
                                    <div className="container inner-page-container">
                                        <div className="tester-form-steps-wrapper">
                                            <div className="tester-form-steps-inner" style={{textAlign:"center"}}>
                                                {test.language=="en"?"Task":"مهمة"} {activeTaskIndex+1} / {totalTasks}
                                            </div>
                                        </div>
                                        <h2 className={`h2 ${test.language=="ar"?'cairo-font':''} `} >{task.title}</h2>
                                    </div>
                                </div>
                                
                               </div>
                                <div className="test-form-hold mt-64">
                                    {/*navPath.map(function(item){
                                        if(item=="back"){
                                            return <>&nbsp;&nbsp;&lt;&nbsp;&nbsp;</>;
                                        }
                                        else if(item=="forward"){
                                            return <>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</>;
                                        } else {
                                            return item;
                                        }
                                    })*/}
                                    {navigateTree(path,0)}
                                </div>
                                
                                
                            </div>
                            <div className="button-wrap d-flex justify-content-end mt-64">
                                <button type="button" onClick={()=>{skipTask(task.id, navPath)}} className="button secondary-btn">
                                    {test.language=="en" &&
                                    <>Skip</>
                                    }
                                    {test.language=="ar" &&
                                    <>تخطي</>
                                    }
                                </button>
                                <button type="button" onClick={()=>{nextTask(task.id)}} className="button primary-btn">
                                    {test.language=="en" &&
                                    <>Next</>
                                    }
                                    {test.language=="ar" &&
                                    <>التالي</>
                                    }
                                    {formLoading &&
                                        <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                    }
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}