//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";


export default function NewTesterConcludeTestModal({openNewTesterConcludeTestModal,closeNewTesterConcludeTestModal, test, isCompleteMyProfile}) {

    return (
        <Modal
            show={openNewTesterConcludeTestModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal nda-agreement-modal test-exit-modal"
        >
            <Modal.Body className="modal-lg p-0">
                <div className={`modal-inner-text p-64 text-left ${test.language=="ar"? 'arabic_wrapper':''}` }>
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeNewTesterConcludeTestModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <div>
                <div>
                    <h2>{test.language=="en"?"Want to get paid next time?":"تريد الحصول على المال المرة القادمة؟"}</h2>
                    
                    {test.language === 'en'?
                    <p className="w-100 text-left">Register with UserQ to fill out more tests like this and get paid for your insights.</p>
                    :<p className="w-100 text-right"> قم بالتسجبل في UserQ للقيام بالمزيد من الاختبارات المشابهة لهذا الإختبار واحصل على المال مقابل أراك &nbsp;</p>}
                    
                </div>


            </div>

                    <div className="button-wrap d-flex justify-content-between new-tester-conclude mt-32">
                        <Link to={'/tester/login'} className="button primary-btn w-100 m-0">{test.language === 'en'?"Register":"سجل"}</Link>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}