import React from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";

export default function ConfirmModal({
    openModal,
    confirm_message,
    confirm_title,
    confirm_btn_title,
    cancel_btn_title,
    close,
    confirm,
}) {
    const closeModal = () => {
        close();
    };

    const navigate = useNavigate();
    return (
        <Modal
            show={openModal}
            centered
            className="fade custom-modal-wrap  mid-width-modal confirm-modal confirm-modal-wrapper"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal();
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img
                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                alt="cross"
                            />
                        </span>
                    </button>
                    <h2 className="h2">{confirm_title}</h2>
                    <p>{confirm_message}</p>

                            <div className="confirm-buttons-div button-wrap d-flex">
                                {confirm_message !=
                                "We cannot delete your account while you have live tests running. Please close all tests and try again." ?
                                    <>
                                        <button
                                            type="button"
                                            className="button secondary-btn"
                                            onClick={() => {
                                                closeModal();
                                            }}
                                            aria-label="Close"
                                        >
                                            {cancel_btn_title ? cancel_btn_title : 'CANCEL'}
                                        </button>

                                        <button
                                            type="button"
                                            className="button primary-btn"
                                            onClick={() => {
                                                confirm();
                                            }}
                                            aria-label="Close"
                                        >
                                            {confirm_btn_title ? confirm_btn_title : "OK"}
                                        </button>
                                    </>
                                    :
                                    <button
                                        type="button"
                                        className="button primary-btn"
                                        onClick={() => {
                                            navigate("/dashboard");
                                            closeModal();

                                        }}
                                        aria-label="Close"
                                    >
                                        Go to project
                                    </button>
                                }
                            </div>

                </div>
            </Modal.Body>
        </Modal>
    );
}
