import React, { useState } from 'react';

export default function Accordian({ title, info }) {
    const [expanded, setExpanded] = useState(false)

    return (
        <div className="card">
            <div className="card-header">
                <a className={`btn btn-header-link ${expanded?'':'collapsed'}`}  onClick={() => setExpanded(!expanded)}>{title}</a>
            </div>
            <div className={`collapse ${expanded?'show':''}`}>

                <div className="card-body" dangerouslySetInnerHTML={{ __html:info }}>

                </div>
            </div>
        </div>
    )
}