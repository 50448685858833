import React from "react";
import { Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

export default function CopyLinkModal(props) {


  const closeModal = () => {
    props.close();
  };
  return (
    <Modal
      show={props.openModal}
      centered
      className="fade custom-modal-wrap align-left-modal share-link-modal mid-width-modal"
    >
      <Modal.Body className="modal-lg p-0">
        <div className="align-left-modal p-64">
          <button
            type="button"
            className="close"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <img
                src={process.env.REACT_APP_URL + "images/cross.svg"}
                alt="cross"
              />
            </span>
          </button>
          <h2 className="h2">Share link to the test</h2>

          <p className="copy-link-subheading">
          Share the link (and any passwords) to your own group of participants. 
          </p>

          <div className="copy-link-subwrapper">
            <h5 className="test-link-head">Your test link</h5>

            <p className="copy-link-data">{props.link}</p>

            <div className="copy-share-test-wrap">
              <span>
                Copy link{" "}
                <CopyToClipboard
                  text={props.link}
                  onCopy={() => {
                    toast(
                      <div className="toastinner">
                        {"Link Copied"}
                        <a
                          onClick={() => {
                            toast.dismiss();
                          }}
                        >
                          &times;
                        </a>
                      </div>,
                      {
                        className: "successtoast",
                        position: "bottom-center",
                        duration: 2000,
                      }
                    );
                  }}
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/copy-icon.svg"}
                  />
                </CopyToClipboard>
              </span>

              {/* <span>
                Share link
                <img
                  src={process.env.REACT_APP_URL + "images/repo-forked.svg"}
                />
              </span> */}
            </div>
          </div>

          <div className="copy-link-subwrapper access-password-link-wrap">
            {props.password && (
              <>
                <h5 className="test-link-head">Password to access test</h5>
                <p className="copy-link-data">{props.password}</p>
                <div className="copy-share-test-wrap">
                  <span>
                    Copy{" "}
                    <CopyToClipboard
                      text={props.password}
                      onCopy={() => {
                        toast(
                          <div className="toastinner">
                            {"Password Copied"}
                            <a
                              onClick={() => {
                                toast.dismiss();
                              }}
                            >
                              &times;
                            </a>
                          </div>,
                          {
                            className: "successtoast",
                            position: "bottom-center",
                            duration: 2000,
                          }
                        );
                      }}
                    >
                      <img
                        src={process.env.REACT_APP_URL + "images/copy-icon.svg"}
                      />
                    </CopyToClipboard>
                  </span>
                </div>
              </>
            )}
          </div>
          {/* <div className="button-wrap d-flex">
            <button
              type="button"
              className="button secondary-btn w-100 ml-0 mr-0"
              onClick={() => {
                closeModal();
              }}
              aria-label="Close"
            >
              CANCEL
            </button>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
