import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import { getCurrentPlansService } from "../../../services/plans";
import { getTestData } from "../../../services/test";
import { maxLengthCheck, isFileImage } from "../../../lib/helpers";
import toast from "react-hot-toast";
import { saveConclusion } from "../../../services/test";
import Frame from "./../study/frame/frame";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import Questions from "./introduction/questions";
import EditQuestion from "./introduction/editQuestion";
import moment from "moment";
import TextAreaField from "./../../layouts/elements/fields/textarea";
import redirectToStep from "./redirect_to_step";
import InputField from "./../../layouts/elements/fields/input";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import { LoadingIcon } from "../../loader/loadingIcon";

export function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    // if(file.type.match('video.*'))
    //   return 'video';

    // if(file.type.match('audio.*'))
    //   return 'audio';



    return 'other';
}
export function matchExtensions(extns, file) {

    return extns.indexOf(file.name.split('.').pop()) != -1 ? true : false;
}

export default function Conclusions() {
    ReactSession.setStoreType("localStorage");

    let { test_id } = useParams();
    const navigate = useNavigate();
    const [test, setTest] = useState("");
    const [lastSaved, setLastSaved] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [planType, setPlanType] = useState("FREE");
    const [language, setLanguage] = useState("en");
    const [title, setTitle] = useState("");
    const [isThankyou, setIsThankyou] = useState(1);
    const [isDebriefingQuestions, setIsDebriefingQuestions] = useState(0);
    const formValueRef = useRef({ thankyou_title: "", thankyou_description: "" });
    const [debriefingQuestions, setDebriefingQuestions] = useState([
        { question: "" },
    ]);
    const [formValues, setFormValues] = useState({
        thankyou_title: "",
        thankyou_description: "",
    });

    const [formErrors, setFormErrors] = useState({
        image: null,
        test_name: null,
        message: null,
        error_class: null,
    });

    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");
    const [thankyouImage, setThankyouImage] = useState(null);
    const [isWelcome, setIsWelcome] = useState(1);
    const [is_briefingQuestion, SetIs_briefingQuestion] = useState(1);
    const [isBriefingQuestions, setIsBriefingQuestions] = useState(0);
    const [questionType, setQuestionType] = useState("");
    const [briefingQuestions, setBriefingQuestions] = useState([]);

    const [addingBriefingQuestions, setAddingBriefingQuestions] = useState(false);
    const [edittingDebriefingQuestions, setEdittingDebriefingQuestions] =
        useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [imageDeleted, setImageDeleted] = useState(false);

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const handleFile = (file) => {

        resetFormErrors();
        //let's grab the image file
        var error = false;

        var fileType = getFileType(file);

        if (fileType == "image" && file.size / 1024 > 2048) {

            setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });

            error = true;
        }
        if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {


            setFormErrors({ ...formErrors, image: "Only image files are accepted with extensions  jpg, jpeg, gif and png.", "error_class": "input_error" });
            error = true;
        }

        if (fileType != "image") {

            setFormErrors({ ...formErrors, image: "Only image files are accepted.", "error_class": "input_error" });
            error = true;
        }

        if (!error) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }

    };

    // const handleFile = (file) => {
    //     //you can carry out any file validations here...
    //     if (!isFileImage(file)) {
    //         showFormError("image", "Please select a valid image");
    //         return;
    //     }
    //     setImage(file);
    //     setPreviewUrl(URL.createObjectURL(file));
    // };    

    const handleOnDragOver = (event) => {
        event.preventDefault();
    };
    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    };
    const removeImage = (e) => {
        setImage(null);
        setPreviewUrl(null);
        setThankyouImage(null);
        setImageDeleted(true);
    };
    const handleFileInput = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.target.files[0];

        handleFile(imageFile);
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);

    };

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            formValueRef.current = { ...formValueRef.current, [name]: value };
        };
    };

    const cancelQuestionHandler = () => {
        setAddingBriefingQuestions(false);
        setQuestionType("");
    };

    const getPlan = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCurrentPlansService(token).then((response) => {
            setLoading(false);

            if (response.success) {

                setPlanType(response.plan.type);
                response.plan.type == "FREE" && setIsBriefingQuestions(0);
            } else {
                showError(response.message);
            }
        });
    };
    const getTest = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);
            if (response.success) {
                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/researcher/project/" + response.test.project_id);
                    } else {
                        if (
                            response.test.is_briefing_questions == 1 &&
                            response.test.briefingQuestion.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/introduction");
                            showError("Please add briefing questions");
                        } else if (
                            response.test.methodology == "Tree Test" &&
                            response.test.tasks.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/tree-sort-test/");
                            showError("Please add tasks");
                        } else if (response.test.methodology === "Card Sorting") {
                            if (response.test.card_sorting_card.length === 0) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/card-sorting-test/"
                                );
                                showError(
                                    "Looks like you haven't created card for this test! Please add a card to move to the next steps."
                                );
                            } else if (
                                response.test.card_sorting.card_sorting === "close" &&
                                response.test.card_sorting_category.length === 0
                            ) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/card-sorting-test/"
                                );
                                showError(
                                    "Looks like you haven't created category for this test! Please add a category to move to the next steps."
                                );
                            }
                        } else if (response.test.methodology === "Five Seconds Test") {
                            if (response.test.fiveSecondsTestTask == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Some required information for the five seconds test is missing or incomplete. Please review and try again."
                                );
                            }
                            else if (response.test.fiveSecondsTestTask.task == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added any task for this test! Please add task to move to the next steps."
                                );
                            } else if (response.test.fiveSecondsTestTask.task_file == null) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added design for this test! Please add design to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length === 0) {
                                navigate(
                                    "/researcher/tests/" + test_id + "/five-seconds-test/"
                                );
                                showError(
                                    "Looks like you haven't added questions for this test! Please add atleast 1 question to move to the next steps."
                                );
                            }
                            else if (response.test.fiveSecondsTestQuestion.length > 0) {
                                let nonoptionalcount = 0;
                                response.test.fiveSecondsTestQuestion.forEach((question) => {
                                    if (JSON.parse(question).is_optional == 0) {
                                        nonoptionalcount = 1
                                    }
                                }
                                );

                                if (nonoptionalcount == 0) {
                                    navigate(
                                        "/researcher/tests/" + test_id + "/five-seconds-test/"
                                    );
                                    showError(
                                        "Atleast one question should not be optional."
                                    );
                                }

                            }
                        } else if (
                            response.test.methodology == "Survey" &&
                            response.test.surveyQuestion.length === 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/survey");
                            showError("Please add survey questions");
                        } else if (
                            response.test.methodology == "Tree Test"
                        ) {
                            if (response.test.tasks.length === 0) {
                                navigate("/researcher/tests/" + test_id + "/tree-sort-test");
                                showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                            } else {
                                for (var i = 0; i < response.test.tasks.length; i++) {
                                    if (response.test.tasks[i].correct_answer_id == null) {
                                        navigate("/researcher/tests/" + test_id + "/tree-sort-test");
                                        showError("Some required information for the tree testing is missing or incomplete. Please review and try again.");
                                    }
                                }

                            }
                        } else if (
                            response.test.methodology == "Preference Test"
                        ) {
                            if(response.test.preference_test_designs.length < 2){
                                navigate("/researcher/tests/" + test_id + "/preference-test");
                                showError("There's some information we still need – please take a look to see if you missed anything!");
                            }
                        }
                    }
                }

                setTest(response.test);
                setLastSaved(moment(response.test.updated_at));
                const {
                    thankyou_title,
                    thankyou_title_arabic,
                    thankyou_description,
                    thankyou_description_arabic,
                    language,
                    is_thank_you,
                    test_name,
                    conclusion_image,
                    is_welcome,
                    is_briefing_questions,
                    is_debriefing_questions,
                } = response.test;

                let questions = [];
                response.test.debriefingQuestion.forEach((question) => {
                    questions.push(JSON.parse(question));
                });
                
                if (questions.length < 1) {
                    setAddingBriefingQuestions(true);
                }
                setBriefingQuestions(questions);

                if (language == "en") {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        thankyou_title: thankyou_title,
                        thankyou_description: thankyou_description,
                    }));
                    formValueRef.current = {
                        ...formValueRef.current,
                        thankyou_title: thankyou_title,
                        thankyou_description: thankyou_description,
                    };
                } else {
                    setFormValues((oldValues) => ({
                        ...oldValues,
                        thankyou_title: thankyou_title_arabic,
                        thankyou_description: thankyou_description_arabic,
                    }));
                    formValueRef.current = {
                        ...formValueRef.current,
                        thankyou_title: thankyou_title_arabic,
                        thankyou_description: thankyou_description_arabic,
                    };
                }
                setLanguage(language);
                setIsThankyou(is_thank_you);
                setTitle(test_name);
                setThankyouImage(conclusion_image);
                setIsWelcome(is_welcome);
                SetIs_briefingQuestion(is_briefing_questions);
                setIsDebriefingQuestions(is_debriefing_questions);
            } else {
                //   setErrorMessage(response.setErrorMessage);
            }
        });
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Summary";
        getPlan();
        getTest();
    }, []);

    const debriefingQuestionsHandler = () => {
        if (planType === "FREE") {
            toast(
                <div className="toastinner">
                    <FormattedMessage id="Free users can not access this feature" />
                    <a
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: 3000,
                }
            );
        } else {
            if(isDebriefingQuestions){
                setQuestionType("");
                setEdittingDebriefingQuestions(false);
                setEditQuestionId(null);
                setAddingBriefingQuestions(false);
            }
            isDebriefingQuestions
                ? setIsDebriefingQuestions(0)
                : setIsDebriefingQuestions(1);
        }
    };

    const saveConclusionData = () => {
        const token = ReactSession.get("token");

        const { thankyou_title, thankyou_description } = formValueRef.current;

        var data = new FormData();

        data.append("test_id", test_id);
        data.append("language", language);
        if (language == "en") {
            data.append("thankyou_title", formValues.thankyou_title);
            data.append("thankyou_description", formValues.thankyou_description);
        } else {
            data.append("thankyou_title_arabic", formValues.thankyou_title);
            data.append(
                "thankyou_description_arabic",
                formValues.thankyou_description
            );
        }
        if (image) {
            data.append("conclusion_image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        data.append("is_thank_you", isThankyou);
        data.append("is_debriefing_questions", isDebriefingQuestions);

        briefingQuestions.forEach((item) => {
            data.append("debriefing_questions[]", JSON.stringify(item));
        });

        saveConclusion(data, token).then((response) => {
            if (response.success) {
                setLastSaved(moment.now());
            } else {
                // console.log(response);
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            // This will run every 30 seconds
            if (test.status === "draft") {
                saveConclusionData();
            }
        }, 300000);
        return () => clearInterval(interval);
    }, [isThankyou, image, language, formValues, isDebriefingQuestions]);

    const conclusionSubmitHandler = (saveUsingNavigation) => {
        const token = ReactSession.get("token");
        setFormLoading(true);
        const { thankyou_title, thankyou_description } = formValueRef.current;

        var data = new FormData();

        data.append("test_id", test_id);
        data.append("language", language);
        if (language == "en") {
            data.append("thankyou_title", formValues.thankyou_title);
            data.append("thankyou_description", formValues.thankyou_description);
        } else {
            data.append("thankyou_title_arabic", formValues.thankyou_title);
            data.append(
                "thankyou_description_arabic",
                formValues.thankyou_description
            );
        }

        if (image) {
            data.append("conclusion_image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        data.append("is_thank_you", isThankyou);
        data.append("is_debriefing_questions", isDebriefingQuestions);
        if (!saveUsingNavigation) {
            data.append("saved_step", 3);
        }

        briefingQuestions.forEach((item) => {
            data.append("debriefing_questions[]", JSON.stringify(item));
        });

        saveConclusion(data, token).then((response) => {
            setFormLoading(false);
            if (response.success) {
                if (!saveUsingNavigation) {
                    navigate("/researcher/tests/" + test_id + "/target-group");
                }
            } else {
                if (response.message == "The image must be an image.") {
                    setImage(null);
                    setPreviewUrl("");
                    setFormErrors({ ...formErrors, image: "The image must be an image.", "error_class": "input_error" });
                } else {
                    showError(response.message);
                }
            }
        });
    };

    function showmore(divID) {
        //document.getElementById(divID).style.height = '600px';
        document.getElementById(divID).classList.add("expendit");
    }

    function showless(divID) {
        document.getElementById(divID).classList.remove("expendit");
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            var slides = document.getElementsByClassName("target-group-data-repeat");
            for (var i = 0; i < slides.length; i++) {
                var bbheight = 0;
                var ccheight = 0;
                var bb = slides.item(i).getElementsByClassName("targer-group-left-wrap")[0];
                var cc = slides.item(i).getElementsByClassName("target-group-info-repeat")[0];
                if (bb) {
                    bbheight = bb.offsetHeight;
                }
                if (cc) {
                    ccheight = cc.offsetHeight;
                }
                //console.log('>>'+bbheight+'<><>'+ccheight);
                if (bbheight < ccheight) {
                    cc.classList.add('lessshow');
                    cc.style.height = bbheight + 'px';
                } else {
                    cc.classList.add('normalshow');
                }
            }



        }, 3000);
        return () => clearTimeout(timer);

    }, []);

    const removequestion = (index) => {
        if (!edittingDebriefingQuestions) {
            const list = [...briefingQuestions];
            list.splice(index, 1);
            setBriefingQuestions(list);
            // if(list.length<1){
            //     setAddingBriefingQuestions(true);
            // }
        } else {
            showError("Finish editing question");
        }
    };

    const duplicatequestion = (index) => {
        if (!edittingDebriefingQuestions) {
            const list = [...briefingQuestions];
            const question = briefingQuestions[index];
            // list.push(question);
            list.splice(index, 0, question);
            setBriefingQuestions(list);
        } else {
            showError("Finish editing question");
        }
    };

    const cancelEditQuestionHandler = () => {
        setEdittingDebriefingQuestions(false);
        setEditQuestionId(null);
    };
    const edittingQuestionsHandler = (index) => {
        if (!edittingDebriefingQuestions) {
            setEdittingDebriefingQuestions(true);
            setEditQuestionId(index);
        } else {
            toast(
                <div className="toastinner">
                    <FormattedMessage id="Finish editing earlier question." />
                    <a
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: 3000,
                }
            );
        }
    };
    const backPath = (test) => {
        if (test.methodology == "Preference Test") {
            return "/researcher/tests/" + test_id + "/preference-test";
        }
        if (test.methodology == "Card Sorting") {
            return "/researcher/tests/" + test_id + "/card-sorting";
        }
        if (test.methodology == "Survey") {
            return "/researcher/tests/" + test_id + "/survey";
        }
        return "/researcher/tests/" + test_id + "/tree-sort-test";
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const handleDragEnd = result => {
        setPlaceholderProps({});
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            briefingQuestions,
            result.source.index,
            result.destination.index
        );

        setBriefingQuestions(items);
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };
    return (
        <LayoutResearcherFullWidth
            isLoading={false}
            wrapClass={test && test.language == "ar" ? "arabic_wrapper_main" : ""}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={""}
        >
            <div className="study-introduction-page-wrapper stydy-pages-wrapper">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <Topbar
                            credits_required={test.credits_required}
                            test={test}
                            enablePublish={false}
                            onChange={(test) => {
                                setTest({ ...test });
                            }}
                            status={test.status}
                            lastSaved={lastSaved}
                            path={backPath(test)}
                            isLoading={false}
                            pageLoading={isLoading}
                            title={test.test_name}
                            stepCallback={() => {
                                conclusionSubmitHandler(true);
                            }}
                        />
                    </div>
                </div>

                <div className="container inner-page-container">
                    <div className="study-steps-progress-wrap">
                        {(
                            <Progress
                                test={test}
                                completed_step={2}
                                stepCallback={() => {
                                    conclusionSubmitHandler(true);
                                }}
                            />
                        )}
                    </div>

                    {isLoading &&
                        <LoadingIcon />
                    }
                    {!isLoading &&
                        <div className="target-group-data-wrapper ">
                            {(test.methodology != "Survey" && test.methodology != "Five Seconds Test") && (
                                <div className="target-group-data-repeat ">
                                    <div className="targer-group-left-wrap ">
                                        <p className="page-step-count">Page 4</p>
                                        {planType === "PAID" ? (
                                            <React.Fragment>
                                                <div className="target-form-wrap">
                                                    <label className="check-switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={Boolean(isDebriefingQuestions)}
                                                            onChange={debriefingQuestionsHandler}
                                                        />
                                                        <span className="check-slider"></span>
                                                        <p className="big-label-text">Debriefing questions</p>
                                                    </label>
                                                </div>
                                                {isDebriefingQuestions ? (
                                                    <div className={`ans-list-wrap question-prev-wrap mt-32 ${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                                                        {briefingQuestions.length === 0 ? (
                                                            <div></div>
                                                        ) : (
                                                            <div className="nodata-wrap" style={{ position: 'relative' }}>
                                                                <DragDropContext
                                                                    // onDragEnd={(param) => {
                                                                    // if (edittingDebriefingQuestions) {
                                                                    //     showError(
                                                                    //     "Finish editing your question first."
                                                                    //     );
                                                                    // } else {
                                                                    //     const srcI = param.source.index;
                                                                    //     const desI = param.destination.index;
                                                                    //     briefingQuestions.splice(
                                                                    //     desI,
                                                                    //     0,
                                                                    //     briefingQuestions.splice(srcI, 1)[0]
                                                                    //     );
                                                                    // }
                                                                    // }}
                                                                    onDragEnd={handleDragEnd}
                                                                    onDragStart={handleDragStart}
                                                                    onDragUpdate={handleDragUpdate}
                                                                >
                                                                    <Droppable droppableId="droppable-1">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.droppableProps}
                                                                            >
                                                                                {briefingQuestions.map(
                                                                                    (question, index) => (
                                                                                        <div key={index}
                                                                                            className="survey-added-question-repeat"
                                                                                        >
                                                                                            <Draggable
                                                                                                draggableId={"draggable-" + index}
                                                                                                index={index}
                                                                                            >
                                                                                                {(provided, snapshot) => (
                                                                                                    <div
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        className={
                                                                                                            snapshot.isDragging
                                                                                                                ? "question-answers-dragging"
                                                                                                                : ""
                                                                                                        }
                                                                                                    >
                                                                                                        {editQuestionId === index ? (
                                                                                                            <></>
                                                                                                        ) : (
                                                                                                            <div
                                                                                                                key={index}
                                                                                                                className="survey-question-preview"
                                                                                                            >
                                                                                                                <div
                                                                                                                    className={`question-answer-holder ${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                                                                                                                    <div className="add-more-question-option">
                                                                                                                        <div
                                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                                            <div
                                                                                                                                className="ans-icon"
                                                                                                                                {...provided.dragHandleProps}
                                                                                                                            >
                                                                                                                                <img
                                                                                                                                    src={
                                                                                                                                        process.env
                                                                                                                                            .REACT_APP_URL +
                                                                                                                                        "images/drag-icon.svg"
                                                                                                                                    }
                                                                                                                                    alt="img"
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <div className="ans-data">
                                                                                                                                {question.questionType ==
                                                                                                                                    "free" ? (
                                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                                        Question {index + 1} -
                                                                                                                                        Free Text
                                                                                                                                    </h3>
                                                                                                                                ) : question.questionType ==
                                                                                                                                    "singlechoice" ? (
                                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                                        Question {index + 1} -
                                                                                                                                        Single
                                                                                                                                        Choice
                                                                                                                                    </h3>
                                                                                                                                ) : question.questionType ==
                                                                                                                                    "multiplechoice" ? (
                                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                                        Question {index + 1} -
                                                                                                                                        Multi Choice
                                                                                                                                    </h3>
                                                                                                                                ) : question.questionType ==
                                                                                                                                    "ratingscale" ? (
                                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                                        Question {index + 1} -
                                                                                                                                        Rating Scale
                                                                                                                                    </h3>
                                                                                                                                ) : question.questionType ==
                                                                                                                                    "likertscale" ? (
                                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                                        Question {index + 1} -
                                                                                                                                        Likert Scale
                                                                                                                                    </h3>
                                                                                                                                ) : (
                                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                                        Question {index + 1} -
                                                                                                                                        Ranking
                                                                                                                                        Scale
                                                                                                                                    </h3>)}
                                                                                                                                {question.is_optional ==
                                                                                                                                    true && (
                                                                                                                                        <span
                                                                                                                                            className="optional-span">
                                                                                                                                            optional
                                                                                                                                        </span>
                                                                                                                                    )}
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="ans-close-row ans-edit-icon">
                                                                                                                                <span onClick={() =>
                                                                                                                                    duplicatequestion(index)
                                                                                                                                }>
                                                                                                                                    <img
                                                                                                                                        src={
                                                                                                                                            process.env
                                                                                                                                                .REACT_APP_URL +
                                                                                                                                            "images/copy-icon.svg"
                                                                                                                                        }
                                                                                                                                        alt="img"

                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="ans-close-row ans-edit-icon">
                                                                                                                                <span onClick={() =>
                                                                                                                                    edittingQuestionsHandler(
                                                                                                                                        index
                                                                                                                                    )
                                                                                                                                }>
                                                                                                                                    <img
                                                                                                                                        src={
                                                                                                                                            process.env
                                                                                                                                                .REACT_APP_URL +
                                                                                                                                            "images/edit-icon.svg"
                                                                                                                                        }
                                                                                                                                        alt="img"

                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="ans-close-row">
                                                                                                                                <span onClick={() =>
                                                                                                                                    removequestion(index)
                                                                                                                                }>
                                                                                                                                    <img
                                                                                                                                        src={
                                                                                                                                            process.env
                                                                                                                                                .REACT_APP_URL +
                                                                                                                                            "images/cross.svg"
                                                                                                                                        }
                                                                                                                                        alt="img"

                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <p className="survey-question-added-info">
                                                                                                                            {question.question}
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    {question.questionType !=
                                                                                                                        "free" && (
                                                                                                                            <div
                                                                                                                                className="ans-list-wrap your-selected-answer-view">
                                                                                                                                <p className="small-info-heading">
                                                                                                                                    Your answers
                                                                                                                                </p>

                                                                                                                                {(question.questionType != "ratingscale" && question.questionType != "likertscale") && question.options.map(
                                                                                                                                    (option, index) => (

                                                                                                                                        <div
                                                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                                                            <img
                                                                                                                                                src={
                                                                                                                                                    process.env
                                                                                                                                                        .REACT_APP_URL +
                                                                                                                                                    "images/Polygon-bg.svg"
                                                                                                                                                }
                                                                                                                                                alt="img"
                                                                                                                                            />

                                                                                                                                            <div
                                                                                                                                                className="ans-data">
                                                                                                                                                {option}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                )}
                                                                                                                                {((question.questionType == "ratingscale") || (question.questionType == "likertscale")) && (
                                                                                                                                    <div
                                                                                                                                        className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                                        <div
                                                                                                                                            className="rating-survey-view-mode">
                                                                                                                                            {question.questionType == "ratingscale" && question.options.map(
                                                                                                                                                (option, index) => (

                                                                                                                                                    <label
                                                                                                                                                        for="radio4">
                                                                                                                                                        <p>{option}</p>
                                                                                                                                                        <input
                                                                                                                                                            id={`radio-${index}`}
                                                                                                                                                            type="radio"
                                                                                                                                                            name="ans"
                                                                                                                                                            value={option}
                                                                                                                                                            className="hidden radio_btn_input"
                                                                                                                                                            required="required" />
                                                                                                                                                        <span>
                                                                                                                                                            <img
                                                                                                                                                                className="empty-fill-icon"
                                                                                                                                                                src={
                                                                                                                                                                    process.env.REACT_APP_URL +
                                                                                                                                                                    "images/Polygon-blank.svg"
                                                                                                                                                                }
                                                                                                                                                            />

                                                                                                                                                        </span>
                                                                                                                                                    </label>

                                                                                                                                                )
                                                                                                                                            )}
                                                                                                                                        </div>

                                                                                                                                        <div
                                                                                                                                            className="likert-question-data-hold likert-view-wrap">

                                                                                                                                            {question.questionType == "likertscale" && (
                                                                                                                                                <div
                                                                                                                                                    className="survey-rating-options-wrap">
                                                                                                                                                    <div
                                                                                                                                                        className="bd-question-radio-data">
                                                                                                                                                        <div
                                                                                                                                                            className="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                                                                            <div
                                                                                                                                                                className="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                                                                                <div
                                                                                                                                                                    className="ans-list-repeat saved-ans-repeat">

                                                                                                                                                                    <div
                                                                                                                                                                        className="likert-option-left">
                                                                                                                                                                        <div
                                                                                                                                                                            className="ans-icon">
                                                                                                                                                                            &nbsp;
                                                                                                                                                                        </div>
                                                                                                                                                                        <div
                                                                                                                                                                            className="ans-data">
                                                                                                                                                                            &nbsp;
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>

                                                                                                                                                                    <div
                                                                                                                                                                        className="likert-option-right">
                                                                                                                                                                        {question.options.map((option, index) => (
                                                                                                                                                                            <label
                                                                                                                                                                                for="radio4">
                                                                                                                                                                                <p>{option}</p>
                                                                                                                                                                            </label>
                                                                                                                                                                        ))}
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>


                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            )}
                                                                                                                                            {question.questionType == "likertscale" && question.subQuestions.map(
                                                                                                                                                (subQuestion, index) => (

                                                                                                                                                    <div
                                                                                                                                                        className="ans-list-wrap likert-scale-option-add">
                                                                                                                                                        <div
                                                                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                                                                            <div
                                                                                                                                                                className="likert-option-left">
                                                                                                                                                                <div
                                                                                                                                                                    className="ans-data">{subQuestion}</div>
                                                                                                                                                            </div>


                                                                                                                                                            <div
                                                                                                                                                                className="likert-option-right">
                                                                                                                                                                <div
                                                                                                                                                                    className="survey-rating-options-wrap">
                                                                                                                                                                    <div
                                                                                                                                                                        className="bd-question-radio-data">
                                                                                                                                                                        <div
                                                                                                                                                                            className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                                                                            {question.options.map((option, index) => (
                                                                                                                                                                                <label
                                                                                                                                                                                    for="radio4">
                                                                                                                                                                                    {/* <p>{option}</p> */}
                                                                                                                                                                                    <input
                                                                                                                                                                                        id={`radio-${index}`}
                                                                                                                                                                                        type="radio"
                                                                                                                                                                                        name="ans"
                                                                                                                                                                                        value={option}
                                                                                                                                                                                        className="hidden radio_btn_input"
                                                                                                                                                                                        required="required" />
                                                                                                                                                                                    <span>
                                                                                                                                                                                        <img
                                                                                                                                                                                            className="empty-fill-icon"
                                                                                                                                                                                            src={
                                                                                                                                                                                                process.env.REACT_APP_URL +
                                                                                                                                                                                                "images/Polygon-blank.svg"
                                                                                                                                                                                            }
                                                                                                                                                                                        />
                                                                                                                                                                                        <img
                                                                                                                                                                                            className="fill-icon"
                                                                                                                                                                                            src={
                                                                                                                                                                                                process.env.REACT_APP_URL +
                                                                                                                                                                                                "images/pol-fill.svg"
                                                                                                                                                                                            }
                                                                                                                                                                                        />
                                                                                                                                                                                    </span>
                                                                                                                                                                                </label>
                                                                                                                                                                            ))}
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>

                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                    </div>)}
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Draggable>
                                                                                            <div className="question-edit">
                                                                                                {edittingDebriefingQuestions &&
                                                                                                    editQuestionId === index && (
                                                                                                        <EditQuestion
                                                                                                            questionDetails={
                                                                                                                briefingQuestions[index]
                                                                                                            }
                                                                                                            cancelEditQuestion={
                                                                                                                cancelEditQuestionHandler
                                                                                                            }
                                                                                                            editQuestions={(
                                                                                                                question
                                                                                                            ) => {
                                                                                                                briefingQuestions.splice(
                                                                                                                    index,
                                                                                                                    1,
                                                                                                                    question
                                                                                                                );
                                                                                                                setBriefingQuestions([
                                                                                                                    ...briefingQuestions,
                                                                                                                ]);
                                                                                                            }}
                                                                                                            language={language}
                                                                                                            other={
                                                                                                                test.language == "en" ?
                                                                                                                    question.questionType == "free"
                                                                                                                        ? "Other"
                                                                                                                        : briefingQuestions[
                                                                                                                            index
                                                                                                                        ].options.slice(-1) == "Other"
                                                                                                                            ? true
                                                                                                                            : false : question.questionType == "free"
                                                                                                                        ? "آخر"
                                                                                                                        : briefingQuestions[
                                                                                                                            index
                                                                                                                        ].options.slice(-1) == "آخر"
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                            }
                                                                                                            questionno={index + 1}
                                                                                                            changeQuestionType={(questionType) => { briefingQuestions[index].questionType = questionType }}
                                                                                                            addLikertSubquestions={() => { briefingQuestions[index].subQuestions = [""] }}
                                                                                                        />
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                                {provided.placeholder}
                                                                                {!isEmpty(placeholderProps) && (
                                                                                    <div
                                                                                        className="placeholder"
                                                                                        style={{
                                                                                            top: placeholderProps.clientY,
                                                                                            left: placeholderProps.clientX,
                                                                                            height: placeholderProps.clientHeight,
                                                                                            width: placeholderProps.clientWidth,
                                                                                            position: "absolute",
                                                                                            borderColor: "#000000",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                        )}
                                                        {!edittingDebriefingQuestions &&
                                                            (!addingBriefingQuestions ? (
                                                                <button
                                                                    className="create-btn add-question-btn"
                                                                    onClick={() => {
                                                                        if (briefingQuestions.length < 5) {
                                                                            setAddingBriefingQuestions(true);
                                                                        } else {
                                                                            toast(
                                                                                <div className="toastinner">
                                                                                    {
                                                                                        <FormattedMessage
                                                                                            id="You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones." />
                                                                                    }
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            toast.dismiss();
                                                                                        }}
                                                                                    >
                                                                                        &times;
                                                                                    </a>
                                                                                </div>,
                                                                                {
                                                                                    className: "errortoast",
                                                                                    position: "bottom-center",
                                                                                    duration: 3000,
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    Add question
                                                                    <span>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/plus-white.svg"
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </span>
                                                                </button>
                                                            ) : (
                                                                <></>
                                                            ))}
                                                        {addingBriefingQuestions && questionType === "" ? (
                                                            <div className="add-more-question-option">
                                                                <h3 className="mt-32 mb-0 d-flex justify-content-between">
                                                                    Question {briefingQuestions.length + 1}
                                                                    {briefingQuestions.length>0 && (
                                                                    <span className="close-question-options" onClick={() => { setAddingBriefingQuestions(false) }}>
                                                                        <img alt="close-icon" src={process.env.REACT_APP_URL + "images/cross.svg"} />
                                                                    </span>
                                                                    )}
                                                                </h3>
                                                                <div className="stud-tiles-hold mt-32">
                                                                    <Frame
                                                                        questionType="FREE TEXT"
                                                                        subheading="Free form answers"
                                                                        bgstyle="bg-1"
                                                                        onClick={() => {
                                                                            setQuestionType("free");
                                                                        }}
                                                                    />
                                                                    <Frame
                                                                        questionType="SINGLE CHOICE"
                                                                        subheading="One answer can be selected"
                                                                        bgstyle="bg-2"
                                                                        onClick={() => {
                                                                            setQuestionType("singlechoice");
                                                                        }}
                                                                    />
                                                                    <Frame
                                                                        questionType="MULTIPLE-CHOICE"
                                                                        subheading="Multiple answers can be selected"
                                                                        bgstyle="bg-3"
                                                                        onClick={() => {
                                                                            setQuestionType("multiplechoice");
                                                                        }}
                                                                    />
                                                                    <Frame
                                                                        questionType="LIKERT SCALE"
                                                                        subheading="Answers on a rating scale"
                                                                        bgstyle="bg-2"
                                                                        onClick={() => {
                                                                            setQuestionType("likertscale");
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <Questions
                                                                questionType={questionType}
                                                                cancelQuestion={cancelQuestionHandler}
                                                                addQuestions={(question) => {
                                                                    setBriefingQuestions([
                                                                        ...briefingQuestions,
                                                                        question,
                                                                    ]);
                                                                }}
                                                                language={language}
                                                                questionno={briefingQuestions.length + 1}
                                                                changeQuestionType={(questionType) => { setQuestionType(questionType) }}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <div className="free-plan-selected-top-wrap">
                                                <h3 className="big-label-text">Debriefing questions</h3>

                                                <span>
                                                    <Link
                                                        className={"updrate-plan-link"}
                                                        to={"/researcher/billing/plan/change"}
                                                    >
                                                        Upgrade to PRO PLAN{" "}
                                                    </Link>{" "}
                                                    to add debriefing questions{" "}
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/lock-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="target-group-info-repeat">
                                        <h3>Debriefing questions</h3>
                                        <p>
                                            Gather participant feedback by asking debriefing
                                            questions.
                                        </p>
                                    </div>

                                </div>
                            )}
                            <div className="target-group-data-repeat repaetbox1 welcome-section-with-subheading">
                                <div className="targer-group-left-wrap pb-0 border-0 repaetbox1left">
                                    {(test.methodology != "Survey" && test.methodology != "Five Seconds Test") && (
                                        <p className="page-step-count">Page 5</p>
                                    )}
                                    {test.methodology == "Survey" && (
                                        <p className="page-step-count">Page 3</p>
                                    )}
                                    {test.methodology == "Five Seconds Test" && (
                                        <p className="page-step-count">Page 4</p>
                                    )}
                                    <div className="target-form-wrap free-plan-selected-top-wrap">
                                        <label
                                            className={`${planType == "FREE"
                                                ? "check-switch d-flex justify-content-between w-100 free-plan-class"
                                                : "check-switch"
                                                }`}
                                        >
                                            <p className="big-label-text pl-0">Thank you page</p>
                                            {planType === "FREE" && (
                                                <span>
                                                    <Link
                                                        className={"updrate-plan-link"}
                                                        to={"/researcher/billing/plan/change"}
                                                    >
                                                        Upgrade to PRO PLAN{" "}
                                                    </Link>{" "}
                                                    to edit the thank you page{" "}
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/lock-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    {isThankyou ? (
                                        <div className={`intro-page-text-hold ${(test.language == "ar") ? "arabic_wrapper" : ""}`}>
                                            <div className="row row-auto-margin">
                                                <div className="col-md-6">
                                                    <div className="image-size-info">Images files must be under 2MB</div>
                                                    <div
                                                        className={`${planType == "FREE"
                                                            ? "project-modal-img disabled-item"
                                                            : "project-modal-img"
                                                            }`}
                                                    >

                                                        {!previewUrl &&
                                                            (thankyouImage ? (
                                                                <>
                                                                    <div className="uploaded-project-img">
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_IMG_URL +
                                                                                thankyouImage
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </div>
                                                                    <img
                                                                        className="remove-img"
                                                                        onClick={removeImage}
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/cross.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </>
                                                            ) : (
                                                                <div
                                                                    className="upload-project-img up-img-wrap"
                                                                    onDragOver={handleOnDragOver}
                                                                    onDrop={handleOnDrop}
                                                                >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/upload-img.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                    <p>
                                                                        Drag and drop an image, or &nbsp;
                                                                        <b>
                                                                            Browse
                                                                            {planType == "PAID" && (
                                                                                <input
                                                                                    type="file"
                                                                                    onChange={handleFileInput}
                                                                                />
                                                                            )}
                                                                        </b>
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        {previewUrl && (
                                                            <>
                                                                <div className="uploaded-project-img">
                                                                    <img src={previewUrl} alt="img" />
                                                                </div>
                                                                <img
                                                                    className="remove-img"
                                                                    onClick={removeImage}
                                                                    src={
                                                                        process.env.REACT_APP_URL + "images/cross.svg"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </>
                                                        )}
                                                        <div
                                                            className="uploaded-project-img"
                                                            style={{ display: "none" }}
                                                        >
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/project-img.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </div>

                                                        {formErrors.image != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.image}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="welcome-page-data-wrap">
                                                        <div className="create-projectmodal-form-group">
                                                            <InputField
                                                                label="Title of the page"
                                                                onChange={set("thankyou_title")}
                                                                onClear={() => {
                                                                    setTimeout(() => {
                                                                        setFormValues((oldValues) => ({
                                                                            ...oldValues,
                                                                            test_name: "",
                                                                        }));
                                                                    }, 1000);
                                                                }}
                                                                value={formValues.thankyou_title}
                                                                maxLength="45"
                                                                error={formErrors.thankyou_title}
                                                                error_class={formErrors.error_class}
                                                                id="title"
                                                                rtl={language == "ar" ? true : false}
                                                                readOnly={planType == "FREE" ? true : false}
                                                            />
                                                        </div>
                                                        <div className="create-projectmodal-form-group">
                                                            <TextAreaField
                                                                autosize={true}
                                                                value={formValues.thankyou_description}
                                                                onChange={set("thankyou_description")}
                                                                maxLength="300"
                                                                label="Edit your welcome message"
                                                                language={language}
                                                                readOnly={planType == "FREE" ? true : false}
                                                                onClear={() => {
                                                                    setTimeout(() => {
                                                                        setFormValues((oldValues) => ({
                                                                            ...oldValues,
                                                                            thankyou_description: "",
                                                                        }));
                                                                    }, 1000);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {isWelcome ? (
                                    <div
                                        id="repaetinfobox1"
                                        className="target-group-info-repeat repaetinfobox1"
                                    >
                                        <h3>Thank you page</h3>
                                        <p>
                                            Thank your participants for their time. You can upload a
                                            background image and create a customised message to show
                                            your appreciation.
                                        </p>
                                        <img
                                            src={process.env.REACT_APP_URL + "images/uq_thaknu-image_reference.jpg"}
                                            alt="img"
                                            className="welcome-page-img-prev"
                                        />

                                        <div
                                            className="seemorebox-sidebar"
                                            onClick={() => {
                                                showmore("repaetinfobox1");
                                            }}
                                        >
                                            See more
                                        </div>
                                        <div
                                            className="seelessbox-sidebar"
                                            onClick={() => {
                                                showless("repaetinfobox1");
                                            }}
                                        >
                                            See less
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div className="target-group-data-repeat">
                                <div className="targer-group-left-wrap  border-0 pt-0">
                                    <div className="button-wrap d-flex justify-content-end mt-64">
                                        {!edittingDebriefingQuestions &&
                                            questionType === "" &&
                                            (briefingQuestions.length < 1 && isDebriefingQuestions ? (
                                                <button
                                                    id="r_create_step_3_summary_next_btn"
                                                    type="submit"
                                                    className="button primary-btn disabled-button"
                                                    disabled={true}
                                                >
                                                    Next
                                                </button>
                                            ) : (
                                                <button
                                                    id="r_create_step_3_summary_next_btn"
                                                    type="submit"
                                                    className="button primary-btn"
                                                    onClick={() => conclusionSubmitHandler(false)}
                                                >
                                                    Next
                                                    {formLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="target-group-right-side"
                                style={{ display: "none" }}
                            >
                                <div className="target-group-info-repeat">
                                    <h3>Test language</h3>
                                    <p>
                                        The test language you select will be applied sitewide for your
                                        testers, so everything from the user interface to questions
                                        will be shown in the chosen language. Remember to create
                                        questions in the language you want your testers to see.
                                    </p>
                                    <a href="#" className="preview-example-link">
                                        Preview example
                                    </a>
                                </div>
                                <div className="target-group-info-repeat">
                                    <h3>Welcome page</h3>
                                    <p>
                                        To help participants feel more at home, customise your welcome
                                        page with a title, background, and custom message.
                                    </p>
                                    <img
                                        src={process.env.REACT_APP_URL + "images/welcome-img.png"}
                                        alt="img"
                                        className="welcome-page-img-prev"
                                    />
                                </div>
                                {is_briefingQuestion ? (
                                    <div className="target-group-info-repeat">
                                        <h3>Briefing questions</h3>
                                        <p>
                                            Ask your participants pre-test questions to gain deeper
                                            insights.
                                        </p>
                                        <a href="#" className="preview-example-link">
                                            Preview example
                                        </a>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </LayoutResearcherFullWidth>
    );
}
