import React, { useEffect  } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';



export default function EmailLogin() {

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(()=>{
        loginUser();
    },[]);

    const loginUser = () => {
        
        
        var body = {email: searchParams.get("u_email")};
        
        fetch(process.env.REACT_APP_API_END_POINT+'email-login', {
            method: 'POST',
            body: JSON.stringify(body),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            if(response.success){
                ReactSession.set("token", response.token);
                ReactSession.set("user", response.user);
                navigate('/dashboard');
            }
        });
    }
    return (
        <></>
    )
}