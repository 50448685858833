export default function RadiobtnRating({labelClass, isSelectedlabelBold,name, checked, value, bigLabel,  defaultValue, id,onChange,label}){
    
    return (
        <label htmlFor={id} className={labelClass}>
            <p className={`none-for-mobile ${(isSelectedlabelBold && checked) ? 'bold-radio-label':''}` }>
                {label}
            </p>
            <input
                type="radio" 
                id={id} 
                checked={checked}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                 name={name} 
                 className="hidden radio_btn_input"  />
            <span>
                <img className="empty-fill-icon" src={process.env.REACT_APP_URL+"images/Polygon-blank.svg"}/>
                <img className="fill-icon" src={process.env.REACT_APP_URL+"images/pol-fill.svg"} />
            </span>
            <p className={`none-for-desktop ${(isSelectedlabelBold && checked) ? 'bold-radio-label':''}` }>
                {label}
            </p>
        </label>
    )
}
