import React from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../../lib/secure_reactsession";
import { useState, useEffect } from "react";
import {
    getTestPreferenceTestTaskData
} from "../../../../../services/researcher_test_results";
import toast from "react-hot-toast";
import { calculateTime } from "../../../../../lib/helpers";
import moment from "moment";
import DeleteAnswer from "../../dialog/hide-participant";
import ReportAnswer from "../../dialog/report-answer";
import { Dropdown } from "react-bootstrap";
import { LoadingIcon } from "../../../../loader/loadingIcon";

export default function TaskAttempts({ test_id, test }) {

    const [isTaskLoading, setTaskLoading] = useState(false);

    const [tests, setTests] = useState([]);

    const [isActive, setActive] = useState(0);

    const [page, setPage] = useState(1);

    const [has_records, setHasRecords] = useState(true);

    const [initial_loaded, setInitialLoaded] = useState(false);

    const [order, setOrder] = useState("time_spent");

    const [sortLoading, setSortLoading] = useState("time_spent");

    const [direction, setDirection] = useState("asc");

    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, answer_id: null });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null, type:null });


    const getTaskAttempts = (page, order, direction) => {


        let token = ReactSession.get("token");

        setTaskLoading(true);

        getTestPreferenceTestTaskData({ test_id: test_id, order: order, direction: direction, page: page }, token).then((response) => {
            
            setTaskLoading(false);

            setSortLoading(false);

            if (response.success) {

                if (page == 1) {
                    setTests(response.data);
                } else {
                    var testsLoc = [];
                    
                    testsLoc = Object.assign([], tests);
                    var newtests = testsLoc.concat(response.data);

                    setTests(newtests);
                }
                if (response.data.length < 10) {
                    setHasRecords(false);
                }
                if(response.has_records===0){
                    setHasRecords(false);
                }

                setInitialLoaded(true);

                setPage(page);

                setOrder(order);

                setDirection(direction);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: response.success ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });

    };



    useEffect(() => {

        getTaskAttempts(1, "time_spent", "desc");
    }, []);
    const deleteAnswerModal = (id,hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true,  test_result_id: id,hidden_result:hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            //const newAnswerList = tests.filter(item => item.id !== openDeleteAnswerModal.answer_id)

            //console.log(newAnswerList)
            //setTests(newAnswerList);

            var testsLocal = [];
            
            for(var i =0; i < tests.length; i++){
                if(tests[i].test_result_id==openDeleteAnswerModal.test_result_id){
                    if(tests[i].result && tests[i].result.is_hidden===1){
                        
                        var test = Object.assign({},tests[i]);

                        test.result.is_hidden = 0;

                        testsLocal.push(test);

                        
                    }
                    else if(tests[i].result && tests[i].result.is_hidden===0){
                        
                        var test = Object.assign({},tests[i]);

                        test.result.is_hidden = 1;

                        testsLocal.push(test);
                    } else {
                        testsLocal.push(tests[i]);

                    }
                } else {
                    testsLocal.push(tests[i]);
                }
            }
            
            setTests(testsLocal);
        }
        setOpenDeleteAnswerModal({ active: false,  test_result_id: null,hidden_result:false  })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id, type:'methodology' });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }

    return (
        <div className="bd-que-ans-table-data-inner bd-que-buy-user-table tree-path-view-table prefer-analy-user-view-table" id="field1">
            <div className="table-responsive sort-loading-table">
                {sortLoading &&
                <div className="sort-loading">
                    <LoadingIcon />
                </div>
                }
                <table>
                    <thead>
                        <tr>
                            <th>Participant</th>
                            <th>Preferred design</th>
                            <th>Design order</th>
                            <th
                                onClick={() => {
                                    setInitialLoaded(false);
                                    setSortLoading(true);
                                    getTaskAttempts(1, "time_spent", direction == "asc" ? "desc" : "asc");
                                }}
                            >
                                <div className={`${order=="time_spent" ? direction: '' } col-sort justify-content-between`}>
                                    Time taken

                                    {order == "time_spent" && direction == "asc" &&
                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                    }
                                    {order == "time_spent" && direction == "desc" &&
                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                    }
                                    {order!="time_spent"  &&
                                    <span>
                                        
                                        <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                    </span>
                                    }
                                </div>
                            </th>
                            <th
                                onClick={() => {
                                    setInitialLoaded(false);
                                    setSortLoading(true);
                                    getTaskAttempts(1, "created_at", direction == "asc" ? "desc" : "asc");
                                }}
                            >
                                <div  className={`${order=="created_at" ? direction: '' } col-sort justify-content-between`}>
                                    Date
                                    {order == "created_at" && direction == "asc" &&
                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                    }
                                    {order == "created_at" && direction == "desc" &&
                                        <span><img src={process.env.REACT_APP_URL + "images/sort_enable.svg"} alt="icon" /></span>
                                    }
                                    {order!="created_at"  &&
                                    <span>
                                        
                                        <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                    </span>
                                    }
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tests.map(function (item, index) {

                            return (
                                <tr key={"test" + item.id} className={` ${(item.result && item.result.is_hidden==1)?'disabled-row':''}`}>
                                    <td>
                                        {item.result && item.result.user_id &&
                                            <b>Participant {" "}
                                                
                                                {item.result && item.result.result_id ?
                                                            item.result.result_id
                                                            :
                                                            index+1
                                                        }  </b>
                                        }
                                        {item.result && !item.result.user_id &&
                                            <b>Unknown{" "} {item.result && item.result.result_id ?
                                                item.result.result_id
                                                :
                                                index+1
                                            }  </b>
                                        }
                                    </td>
                                    <td className={`${test.language=='ar'?'cairo-font':''}`}>
                                        {item.design.title}
                                    </td>
                                    <td className={`${test.language=='ar'?'cairo-font':''}`}>
                                        {item.sequence_designs ? item.sequence_designs : item.sequence }
                                    </td>
                                    <td><b>{calculateTime(item.time_spent)}</b></td>
                                    <td>
                                        <div className="bd-col-action-wrap d-flex">
                                            <span>{moment(item.created_at).format("DD/MM/YYYY")}</span>

                                            <span
                                                className="pro-menu-icon small-dropdown mid-menu-wrap dropdown-withclose-btn">

                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        id={"dropdownMenuButton" + item.id}
                                                        className="dropdown-toggle"
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                                                            alt="img"
                                                        />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="dropdown-close">
                                                            <Dropdown.Toggle>
                                                                <img
                                                                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                                    alt="img"
                                                                />
                                                            </Dropdown.Toggle>
                                                        </div>
                                                        <div className="drop-down-link-hold">
                                                            <Dropdown.Item onClick={() => deleteAnswerModal(item.test_result_id,(item.result && item.result.is_hidden)?true:false)}>
                                                                
                                                                {(item.result && item.result.is_hidden)?'Show participant':'Hide participant'}
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/eye.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => reportAnswerModal(item.test_result_id)}>
                                                                Report participant
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL + "images/warning.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </span>
                                                            </Dropdown.Item>
                                                        </div>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        {tests.length == 0 && !isTaskLoading &&
                            <tr>
                                <td colSpan={10} className="table-without-data">No data available</td>
                            </tr>
                        }

                    </tbody>
                </table>
            </div>
            {initial_loaded && !isTaskLoading && has_records &&
                <div className="load-more-data" onClick={() => { getTaskAttempts(page + 1, order, direction); }}>
                    <span>Load more <img src={process.env.REACT_APP_URL + "images/dotts.png"} alt="load-more" /></span>
                </div>
            }
            {isTaskLoading  && !sortLoading &&
                <div className="load-more-data">
                    <span>Loading <img src={process.env.REACT_APP_URL + "images/dotts.png"} alt="load-more" /></span>
                </div>
            }
            <DeleteAnswer
                test_id={test_id}
                openModal={openDeleteAnswerModal}
                closeModal={closeDeleteAnswerModal} />
            <ReportAnswer
                test_id={test_id}
                openModal={openReportAnswerModal}
                closeModal={closeReportAnswerModal} />
        </div>
    );
}
