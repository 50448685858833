import React, { useEffect,useRef, useState } from "react";
import {useLocation} from 'react-router-dom';
import toast from 'react-hot-toast';
import { saveCardSortingResultService } from "../../../../services/test_result";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { FormattedMessage } from "react-intl";
import CardSortingIntroduction from "./card_sorting_introduction";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RenameModal from "./rename_model";
import InputField from "../../../layouts/elements/fields/input";
import ConfirmModal from "../../../dialog/confirm_modal";
import LayoutResearcherFullWidth from "../../../layouts/layout_researcher_full_width";
import Masonry from 'react-masonry-css';


export default function CardSorting({test, result, callback,timeSpent}){
    const [initialScreen, setInitialScreen] = useState(true);
    const location = useLocation();
    const inputref = useRef(null);

    const [infoText, setInfoText] = useState(false);
    const [minimizeData, setMinimizeData] = useState(false);
    const [formLoading,setFormLoading] = useState(false);
    const [isEnableNextButton,setEnableNextButton] = useState(true);
    const [cardSorting, setCardSorting] = useState(null);

    const [cardData, setCardData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);

    // check all cards move in single category
    const [cardDataResearcher, setCardDataResearcher] = useState([]);
    const [isAllCardsInSingleCategory, setAllCardsInSingleCategory] = useState(false);


    const [onDraggingCreateNewCategory, setOnDraggingCreateNewCategory] = useState(false);
    const [onDraggingCreateNewCategoryCardData, setOnDraggingCreateNewCategoryCardData] = useState(null);


    const [columns, setColumns] = useState([]);
    const [editCategoryArray, setEditCategoryArray] = useState([]);
    const [tmpCategoryHtml, setTmpCategoryHtml] = useState(false);
    const [onTouchMobileCreateTmpHtml, setOnTouchMobileCreateTmpHtml] = useState(false);
    const [databaseCategoryArray, setDatabaseCategoryArray] = useState([]);

    const [isTyping, setIsTyping] = useState(false);
    const [isDragingStartItem, setIsDragingStartItem] = useState(false);
    const [formErrors, setFormErrors] = useState({"title":null,"error_class":""});

    const [deleteCategoryModal, setDeleteCategoryModal] = useState({ open: false });

    const [cardSortingTimeSpent,setCardSortingTimeSpent] = useState(0);


    const [isAddNewCategoryLoading, setIsAddNewCategoryLoading] = useState(false);
    const [isDeleteCategoryLoading, setIsDeleteCategoryLoading] = useState(false);
    const [moveItemsCategoryToTmpCategory, setMoveItemsCategoryToTmpCategory] = useState({id:'',editCategoryId:''});

    useEffect(()=>{

        setCardSorting(test.card_sorting);
        setCardData(test.card_sorting_card);
        setDatabaseCategoryArray(test.card_sorting_category);

        /// check all cards move in single category
        setCardDataResearcher(test.card_sorting_card);

        setEditCategoryArray([]);

        const columnsFromBackend = {
            ['1']: {
                name: "",
                type:"",
                items: test.card_sorting_card
            }
        };


        if(test.card_sorting_category.length > 0){

            for(var totalCategory=1; totalCategory<=test.card_sorting_category.length; totalCategory++){
                Object.assign(columnsFromBackend,{
                    [totalCategory+1]:{
                        id:   test.card_sorting_category[totalCategory-1].id,
                        name: test.card_sorting_category[totalCategory-1].name || '-',
                        type:"category",
                        uniqueId:Date.now()+parseInt(test.card_sorting_category[totalCategory-1].id),
                        items: []
                    }})
            }
        }

        setColumns(columnsFromBackend);

        // location scroll top
        window.scrollTo(0, 0);

        function tickTime() {
            setCardSortingTimeSpent(prevSeconds => prevSeconds + 1)
        }

       let timeData = setInterval(() => tickTime(), 1000)

        return () => clearInterval(timeData);
    },[test]);

    const saveCardSorting = () => {
        var p = location.pathname.substr(1 ,1);

        if(p==="p"){
            callback();
        } else {

            if (cardSorting.is_required_categories === 1 && Object.entries(columns)[0][1].items.length > 0) {
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="You have to sort all the cards to proceed" />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }else if (cardSorting.is_required_categories === 0 && Object.entries(columns)[0][1].items.length === cardData.length){
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="Please move atleast one card into category" />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }else if(!isEnableNextButton){
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="There are one or more errors on the form. Please fix to proceed ahead." />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }else {

                //// check all cards move in single category
                //cardDataResearcher
                //console.log(columns)

                var iteration=1;
                for (const [key, value] of Object.entries(columns)) {

                    if(iteration !== 1){

                        if(Object.entries(columns)[iteration-1][1].items.length === cardDataResearcher.length){
                            toast((
                                <div className='toastinner'>
                                    You need to categorize the cards into a minimum of two categories
                                    <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });


                            return false;
                        }
                    }

                    iteration++;

                }

                // check any empty category name
                // check category name exits is already category name & edit category array
                if(editCategoryArray.length > 0){
                    for(var i=0; i<editCategoryArray.length; i++){
                        if(editCategoryArray[i].name === ''){
                            toast((
                                <div className='toastinner'>
                                    Category title cannot be blank.
                                    <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });

                            return false;

                        }
                    }
                }


                setFormLoading(true);

                saveCardSortingResultService({
                    test_id: test.id,
                    result_id: result.id,
                    guest_token:ReactSession.get("guest_token"),
                    time_spent:timeSpent,
                    card_sorting_time_spent:cardSortingTimeSpent,
                    data:columns,
                    editCategory:editCategoryArray,
                }, ReactSession.get("token")).then((response) => {

                    setFormLoading(false);

                    if (response.success) {
                        callback();

                    } else {
                        toast((
                            <div className='toastinner'>
                                {response.message}
                                <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                            </div>), {
                            className: 'errortoast',
                            position: 'bottom-center'
                        });
                    }
                });
            }



        }
    }


    const onDragEnd = (result, columns, setColumns) => {

        setIsDragingStartItem(false)

       // console.log('yes')


        //console.log(result)
        if (!result.destination){

            if(onTouchMobileCreateTmpHtml){
                createNewCategoryTmpToPermanent();

                setOnTouchMobileCreateTmpHtml(false);
            }

            // apply some condition
            setOnDraggingCreateNewCategory(false)
            setOnDraggingCreateNewCategoryCardData(null)
            setTmpCategoryHtml(false); // hide tmp catgeory html

            console.log('das1245')

            return;
        }

        console.log('noa')
        // apply some condition
        setOnDraggingCreateNewCategory(false)
        setOnDraggingCreateNewCategoryCardData(null)
        setTmpCategoryHtml(false); // hide tmp catgeory html

        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };

    // add new category
    const addCategory = ()=>{

        /*Object.assign(columns,{
            [Object.keys(columns).length+1]:{
                id:  "",
                name: 'Category name',
                type:"category",
                items: []
            }})*/

        //setRenameData({isEditing:false,id:'',title:''})
        if(isAddNewCategoryLoading){
            return false;
        }

        setIsAddNewCategoryLoading(true);


        const yourCardItems = Object.entries(columns)[0][1].items;

        var uniqueIdData = Date.now()+Math.floor((Math.random() * 100000) + 1);

        const columnsFromBackend = {
            ['1']: {
                name: "",
                type:"",
                items: yourCardItems
            },
            ['2']:{
                id:  "",
                name: '',
                type:"category",
                uniqueId:uniqueIdData,
                items: []
            }
        };

        const editCategoryData = [{'id':2,'name':'','uniqueId':uniqueIdData,'isEditing':true,'error_msg':'','error_class':'',categoryRef:true}];
        if(editCategoryArray.length > 0){
            for (var i=0; i<editCategoryArray.length; i++){
                editCategoryArray[i].id = editCategoryArray[i].id+1;
                editCategoryArray[i].error_msg = (editCategoryArray[i].name === '')?"Required field":editCategoryArray[i].error_msg;
                editCategoryArray[i].error_class = (editCategoryArray[i].name === '')?"input_error":editCategoryArray[i].error_class;
                editCategoryArray[i].categoryRef = false;
                editCategoryData.push(editCategoryArray[i]);
            }
        }


        setEditCategoryArray(editCategoryData);



       var iteration=1;
        for (const [key, value] of Object.entries(columns)) {

            if(iteration !== 1){
                Object.assign(columnsFromBackend,{
                    [iteration+1]:columns[key]
                })
            }

            iteration++;

        }

        setColumns(columnsFromBackend);

        // set button type enable or not
        setEnableNextButton(false)

        //setIsTyping(false);
        setIsAddNewCategoryLoading(false);
    }

    const deleteCard = (cardId,cardName,columnId,uniqueId)=>{

        //setRenameData({isEditing:false,id:'',title:''})
        //setIsTyping(false);

        const columnKeys = Object.keys(columns);

        const CategoryItems = Object.entries(columns)[columnId][1];


        if(CategoryItems) {
            const newCategoryItems = CategoryItems.items.filter(item => item.id !== cardId)

            // delete items from category
            Object.assign(columns, {
                [columnKeys[columnId]]: {
                    id: CategoryItems.id,
                    name: CategoryItems.name,
                    uniqueId: CategoryItems.uniqueId,
                    type: CategoryItems.type,
                    items: newCategoryItems
                }
            })



            // add items in your card
            const yourCardItems = Object.entries(columns)[0][1].items;
            yourCardItems.push({'id':cardId,'name':cardName})

            setColumns(columns);
        }
    }

    // delete category confirmDeleteCategory
    const deleteCategory = (columnId,editCategoryId,uniqueId)=>{

        //setRenameData({isEditing:false,id:'',title:''})
        //setIsTyping(false);
        if(isDeleteCategoryLoading){
           // console.log('dasd')
            return false;
        }



        setIsDeleteCategoryLoading(true);
        const columnKeys = Object.keys(columns);

        const CategoryItems = Object.entries(columns)[columnId][1].items;

        // show confirmation model if any cards assigned to this category
        if (CategoryItems.length > 0){

            setDeleteCategoryModal({ open: true, id: columnId,editCategoryid:editCategoryId,unique_id:uniqueId });

        }else{


            // delete category
            delete columns[columnKeys[columnId]];

            const oldEditCategoryArray = editCategoryArray;
            const IndexEditCategory = editCategoryArray.findIndex(function(item) {return item.uniqueId === parseInt(uniqueId)});

            oldEditCategoryArray.splice(IndexEditCategory,1);
            setEditCategoryArray(oldEditCategoryArray);
            checkAllErrors(oldEditCategoryArray);

           // console.log(columns)
            //console.log(columnKeys[columnId])
            setTimeout(function () {
                //console.log('hrllo')
                setIsDeleteCategoryLoading(false);
            },1500)


        }

        //console.log(columns)
        //console.log(editCategoryArray)


    }

    const confirmDeleteCategory = (columnId,editCategoryid,uniqueId)=>{

        const columnKeys = Object.keys(columns);
        const CategoryItems = Object.entries(columns)[columnId][1].items;
        if (CategoryItems){

            // add items in your card
            const yourCardItems = Object.entries(columns)[0][1].items;
            CategoryItems.forEach(function(items){
                yourCardItems.push({'id':items.id,'name':items.name})
            });
        }

        delete columns[columnKeys[columnId]];

        const oldEditCategoryArray = editCategoryArray;
        const IndexEditCategory = editCategoryArray.findIndex(function(item) {return item.uniqueId === parseInt(uniqueId)});

        oldEditCategoryArray.splice(IndexEditCategory,1);
        setEditCategoryArray(oldEditCategoryArray);

        checkAllErrors(oldEditCategoryArray);
        setTimeout(function () {
            //console.log('hrllo')
            setIsDeleteCategoryLoading(false);
        },1500)

    }

    // edit category editCategory
    const editCategory = (columnId,name)=>{

        const oldEditCategoryArray = editCategoryArray;
        const editCategory = editCategoryArray.find(function(item) {return item.id === parseInt(columnId)});
        const IndexEditCategory = editCategoryArray.findIndex(function(item) {return item.id === parseInt(columnId)});

        oldEditCategoryArray[IndexEditCategory] = {...oldEditCategoryArray[IndexEditCategory],"isEditing":true};
        //console.log(oldEditCategoryArray)
        setEditCategoryArray(oldEditCategoryArray);

        // setRenameData({isEditing:true,id:columnId,title:name})
        //setRenameData({open:true,isLoading:false,id:columnId,title:name})
    }


    const renameCategory = (e,columnId,index) => {

        e.preventDefault();
        //console.log(e.type)
        //console.log('yes')
        if(e.keyCode === 13 || e.type === 'click') {


            const oldEditCategoryArray = editCategoryArray;
            const editCategory = editCategoryArray.find(function (item) {
                return item.id === parseInt(columnId)
            });
            const IndexEditCategory = editCategoryArray.findIndex(function (item) {
                return item.id === parseInt(columnId)
            });

            //oldEditCategoryArray[IndexEditCategory] = {...oldEditCategoryArray[IndexEditCategory],'name':e.target.value};
            //console.log(oldEditCategoryArray);
            //setEditCategoryArray(oldEditCategoryArray);

            // console.log(editCategory)
            // console.log(columnId)
            // console.log(IndexEditCategory)

            oldEditCategoryArray[IndexEditCategory] = {
                ...oldEditCategoryArray[IndexEditCategory],
                "error_msg": "",
                "error_class": ""
            };


            if (editCategory.name === '') {

                oldEditCategoryArray[IndexEditCategory] = {
                    ...oldEditCategoryArray[IndexEditCategory],
                    "error_msg": "Required field",
                    "error_class": "input_error"
                };
                setEditCategoryArray(oldEditCategoryArray);
                checkAllErrors(oldEditCategoryArray);
                //setFormErrors({"title":"Required field","error_class":"input_error"});
            } else {
                //console.log('dasd')
                const columnKeys = Object.keys(columns);

                // check category name exits is already category name & edit category array
                if(editCategoryArray.length > 0){
                    for(var i=0; i<editCategoryArray.length; i++){
                        if(editCategoryArray[i].name === editCategory.name && editCategoryArray[i].id !== editCategory.id){
                            toast((
                                <div className='toastinner'>
                                    A category with the same name already exists.
                                    <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });

                            checkAllErrors(editCategoryArray);

                            return false;

                        }
                    }
                }

                const checkAlreadyNameFromDatabase = databaseCategoryArray.find(function(item) {return item.name === editCategory.name});
                if(checkAlreadyNameFromDatabase){
                    toast((
                        <div className='toastinner'>
                            A category with the same name already exists
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });


                    const checkAlreadyNameFromDatabaseIndex = editCategoryArray.findIndex(function(item) {return item.name === editCategory.name});
                    //console.log(checkAlreadyNameFromDatabaseIndex)
                    if(checkAlreadyNameFromDatabaseIndex !== -1){
                        const oldCategoryArray = editCategoryArray;
                        oldCategoryArray[checkAlreadyNameFromDatabaseIndex].isEditing = true;
                        oldCategoryArray[checkAlreadyNameFromDatabaseIndex].error_msg = 'Category name already exists.';
                        oldCategoryArray[checkAlreadyNameFromDatabaseIndex].error_class = 'input_error';
                        setEditCategoryArray(oldCategoryArray);

                        checkAllErrors(oldCategoryArray);
                    }

                    return false;

                }


                // check category name exits is already category name & edit category array
                /*var iteration=1;
                for (const [key, value] of Object.entries(columns)) {

                    if(iteration !== 1){

                        if(Object.entries(columns)[iteration-1][1].name === editCategory.name){
                            toast((
                                <div className='toastinner'>
                                    A category with the same name already exists
                                    <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });


                            return false;
                        }
                    }

                    iteration++;

                }*/


                //console.log(columns)
                //console.log(columnId)
                //console.log(index)
                //console.log(Object.entries(columns)[index])

                const CategoryItems = Object.entries(columns)[index][1];

                if (CategoryItems) {

                    Object.assign(columns, {
                        [columnKeys[index]]: {
                            id: CategoryItems.id,
                            name: editCategory.name,
                            type: CategoryItems.type,
                            items: CategoryItems.items
                        }
                    })
                }

                oldEditCategoryArray[IndexEditCategory] = {
                    ...oldEditCategoryArray[IndexEditCategory],
                    "isEditing": false,
                    "error_msg":'',
                    "error_class":''

                };
                //console.log(oldEditCategoryArray)
                setEditCategoryArray(oldEditCategoryArray);

                checkAllErrors(oldEditCategoryArray);

                //setRenameData({isEditing:false,id:'',title:''})
                //setIsTyping(false);
            }

        }

    }

    // create category if no exists
    const onDraggingCreateCategory = (item,index,columnId)=>{
       // console.log(e)
        //console.log(item)
        if(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1)) {

            // create category if no category is exists
            setOnDraggingCreateNewCategory(true) // on dragging create new catgory
            setOnDraggingCreateNewCategoryCardData(item) // on dragging create new catgory

            // move items from category to tmp category
            if(index) {
                setMoveItemsCategoryToTmpCategory({id: index, editCategoryId: columnId});
            }
        }
       //  if(Object.keys(columns).length === 1) {
       //      const yourCardItems = Object.entries(columns)[0][1].items;
       //
       //      const columnsFromBackend = {
       //          ['1']: {
       //              name: "",
       //              type: "",
       //              items: yourCardItems
       //          },
       //          ['2']: {
       //              id: "",
       //              name: '',
       //              type: "category",
       //              items: []
       //          }
       //      };
       //
       //      setColumns(columnsFromBackend);
       //
       //      // enable edit category data
       //      const editCategoryData = [{'id':2,'name':'','isEditing':true,'error_msg':'','error_class':'','categoryRef':true}];
       //      setEditCategoryArray(editCategoryData);
       //      setEnableNextButton(false);
       // }
    }

    useEffect(()=>{
        if (inputref.current) {
            inputref.current.focus()
            setIsTyping(true)
        }
    },[editCategoryArray])

    const runFunction = (e) => {


        /*if (
            e.target.type !== 'text'
        ) {
        setIsTyping(false)
            e.preventDefault();
            return;
        }*/

        if(e.target.type !== 'text'){
            setIsTyping(false)
        }

        if (e.target.classList.contains("create-btn") ||
            e.target.classList.contains('save-category-input') ||
            e.target.classList.contains('fa fa-check save-category-input') ||
            e.target.type === 'text'
        ) {
            e.preventDefault();
            return;
        }




       /* const columnKeys = Object.keys(columns);

        // check category name exits is already category name & edit category array
        if(editCategoryArray.length > 0){
            for(var i=0; i<editCategoryArray.length; i++){
                if(editCategoryArray[i].name === editCategory.name && editCategoryArray[i].id !== editCategory.id){
                    toast((
                        <div className='toastinner'>
                            A category with the same name already exists.
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });

                    return false;

                }
            }
        }


        // check category name exits is already category name & edit category array
        var iteration=1;
        for (const [key, value] of Object.entries(columns)) {

            if(iteration !== 1){

                if(Object.entries(columns)[iteration-1][1].name === editCategory.name){
                    toast((
                        <div className='toastinner'>
                            A category with the same name already exists
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });


                    return false;
                }
            }

            iteration++;

        }
*/

        //console.log(databaseCategoryArray)
        // disabled isediting false
        const newCategoryArray = [];
        if(editCategoryArray.length > 0){
            editCategoryArray.forEach((items)=>{

                var nameNew = items.name;
                var newId = items.id-1;
                if(items.name === ''){
                    //nameNew = 'Category name '+newId;
                    newCategoryArray.push({
                        ...items,
                        'name':'',
                        'uniqueId':items.uniqueId,
                        'isEditing':true,
                        'error_msg':'Required field',
                        'error_class':'input_error'
                    })
                }else{

                    const checkAlreadyName = newCategoryArray.find(function(item) {return item.name === nameNew});
                    //console.log(newCategoryArray)
                    if(checkAlreadyName){
                        newCategoryArray.push({
                            ...items,
                            'name':nameNew,
                            'uniqueId':items.uniqueId,
                            'isEditing':true,
                            'error_msg':'Category name already exists',
                            'error_class':'input_error'
                        })
                    }else{
                        newCategoryArray.push({
                            ...items,
                            'name':nameNew,
                            'uniqueId':items.uniqueId,
                            'isEditing':false,
                            'error_msg':'',
                            'error_class':''
                        })
                    }

                    // check name exists from database
                    const checkAlreadyNameFromDatabase = databaseCategoryArray.find(function(item) {return item.name === nameNew});
                    if(checkAlreadyNameFromDatabase){
                        const checkAlreadyNameFromDatabaseIndex = newCategoryArray.findIndex(function(item) {return item.name === nameNew});
                        newCategoryArray[checkAlreadyNameFromDatabaseIndex].isEditing = true;
                        newCategoryArray[checkAlreadyNameFromDatabaseIndex].error_msg = 'Category name already exists.';
                        newCategoryArray[checkAlreadyNameFromDatabaseIndex].error_class = 'input_error';
                    }

                }

            })
        }


        var iteration=1;
        //console.log(columns)
        //console.log(newCategoryArray)
        /*for (const [key, value] of Object.entries(columns)) {


            if(iteration !== 1){

                const editCategory = newCategoryArray.find(function(item) {return item.id === iteration});

                //console.log(editCategory)
                if(editCategory){

                    Object.assign(columns,{
                        [iteration]:{
                            ...columns[key],
                            'name':editCategory.name
                        }
                    })
                }

            }

            iteration++;

        }*/

        setEditCategoryArray(newCategoryArray);

        checkAllErrors(newCategoryArray);

       // console.log(columns)
        //setIsTyping(false);

    }

    const checkAllErrors = (newCategoryArray)=>{

        // set enable button open
        setEnableNextButton(true);

        if(newCategoryArray.length > 0){
            newCategoryArray.forEach((items)=>{

                // check if any edit category field left
                if(items.isEditing){
                    setEnableNextButton(false);
                    return false;
                }

            })
        }


    }

    const renderedHtml = () => {

        var render_html = [];
        {Object.entries(columns).map(([columnId, column], index) => {
            {index === 0 &&
            render_html.push(
                <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                        return (
                            <div className="cards-top-bar-fixed">

                            <div className="card-sorting-view-wrap" {...provided.droppableProps}
                                ref={provided.innerRef} key={columnId}>

                                <div className="card-count-top-wrap">
                                    <span>

                                        {test.language === 'en' ?
                                            "Your cards"
                                            :
                                            "بطاقاتك"
                                        }
                                    </span>
                                    <b>{" "} {column.items.length}{" "}
                                        {test.language === 'en' ?
                                            "left"
                                            :
                                            "متبقي"
                                        }

                                    </b>
                                </div>


                                <div  className={`
                                        ${snapshot.isDraggingOver
                                            ? "your-card-dragging"
                                            : ""}
                                          all-cards-view-wrap  
                                        `}
                                >
                                    <p className="no-cards">
                                        {column.items.length === 0 ?
                                            <>
                                            {test.language === 'en' ?
                                                    "No cards left"
                                                    :
                                                    " لم يتبقى أي بطاقات"
                                            }
                                            </>
                                        :''}

                                    </p>

                                    <div className="cards-view-inner-wrap">

                                        <div className="cardsort-view-data-repeat">


                                            {column.items.map((item, index) => {
                                                return (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={`test-${item.id}`}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <span
                                                                      className={
                                                                          snapshot.isDragging
                                                                              ? "dragging-card-items draggable-item"
                                                                              : "draggable-item"
                                                                      } ref={provided.innerRef}
                                                                      {...provided.draggableProps}
                                                                      {...provided.dragHandleProps}
                                                                      onMouseDown={()=>onDraggingCreateCategory(item,'','')}
                                                                      onTouchStart={(e)=>{
                                                                          if(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1)) {

                                                                              setOnDraggingCreateNewCategory(true) // on dragging create new catgory
                                                                              setOnDraggingCreateNewCategoryCardData(item) // on dragging create new catgory
                                                                              setOnTouchMobileCreateTmpHtml(true);
                                                                              onMouseOverCreateTmpCategory(e, item);
                                                                          }
                                                                      }}
                                                                     // onTouchEnd={onMouseMoveCreateCategory}
                                                                >
                                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M8 5.1547L8.73205 5.57735V6.42265L8 6.8453L7.26795 6.42265L7.26795 5.57735L8 5.1547Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                        <path d="M16.8453 6L16.4226 6.73205H15.5774L15.1547 6L15.5774 5.26795L16.4226 5.26795L16.8453 6Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                        <path d="M8.8453 12L8.42265 12.7321H7.57735L7.1547 12L7.57735 11.2679L8.42265 11.2679L8.8453 12Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                        <path d="M16 11.1547L16.7321 11.5774V12.4226L16 12.8453L15.2679 12.4226L15.2679 11.5774L16 11.1547Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                        <path d="M8 17.1547L8.73205 17.5774V18.4226L8 18.8453L7.26795 18.4226L7.26795 17.5774L8 17.1547Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                        <path d="M16.8453 18L16.4226 18.7321H15.5774L15.1547 18L15.5774 17.2679L16.4226 17.2679L16.8453 18Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                   </svg>
                                                                    {item.name}
                                                                    <i
                                                                        className="fa fa-times-circle"
                                                                        aria-hidden="true"></i></span>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>

                                    </div>
                                </div>


                            </div>


                                <div className={`cardtake-test-top none-for-desktop d-flex align-items-center ${(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1))?'justify-content-between':'justify-content-end'}`}>
                                    {(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1)) &&

                                    <button onClick={addCategory} className="create-btn">{test.language=="en"?"Add category":"أضف فئة"} <span className="save-category-input"><img className="save-category-input" src={process.env.REACT_APP_URL + "images/plus-white.svg"}/></span> </button>
                                    }

                                    <div className="minimize-box-wrap">
                                        {!minimizeData &&
                                        <span onClick={() => setMinimizeData(true)}>
                                            {test.language === 'en' ?
                                                "Minimize"
                                                :
                                                "تصغير"
                                            }
                                            <img
                                            src={process.env.REACT_APP_URL + "images/minim-icon.svg"}/> </span>
                                        }
                                        {minimizeData &&
                                        <span onClick={() => setMinimizeData(false)}>


                                            {test.language === 'en' ?
                                                "Expand"
                                                :
                                                "تكبير"
                                            }
                                            <img
                                            src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}/> </span>
                                        }
                                    </div>
                                </div>


                            </div>
                        );
                    }}
                </Droppable>
            );
        }
        })}
    return(
        <>{render_html}</>
    );
}



    const renderedCategoryCommonHtml = (columnId,column,index) => {
        return(


                    <div  key={columnId}
                         className={`
                              card-category-data-hold`}>
                        <form action="post" onSubmit={(e)=>renameCategory(e,columnId,index)}>
                        <div className="card-cat-top-wrp">
                            <h3>
                                {editCategoryArray && editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}) && editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).isEditing === true ?
                                    <>
                                        <InputField
                                            onInput={(e)=>{if(e.target.value.length>0){ setFormErrors({...formErrors,'title':null,'error_class':''}) }}}
                                            error_class={editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).error_class}
                                            error={editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).error_msg}
                                            label={test.language=="en"?"Category name":"اسم الفئة"}
                                            value={editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).name}
                                            onChange={(e)=>{
                                                //console.log(editCategoryArray)
                                                //console.log(columnId)
                                                //console.log(editCategoryArray.find(function(item) {return item.id === parseInt(columnId)}).name)
                                                 //setRenameData({...renameData,title:e.target.value})
                                                const oldEditCategoryArray = editCategoryArray;
                                                const IndexEditCategory = editCategoryArray.findIndex(function(item) {return item.uniqueId === parseInt(column.uniqueId)});
                                                oldEditCategoryArray[IndexEditCategory] = {...oldEditCategoryArray[IndexEditCategory],'name':e.target.value};
                                                //console.log(oldEditCategoryArray);categoryRef
                                                setEditCategoryArray(oldEditCategoryArray);

                                            }}
                                            //onKeyDown={(e)=>renameCategory(e,columnId,index)}
                                            onClick={()=>{
                                                setIsTyping(true);
                                            }}
                                            inputref={
                                                editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).categoryRef
                                                ? inputref
                                                : undefined
                                            }
                                            // inputref={()=>{
                                            //     if(editCategoryArray.find(function(item) {return item.id === parseInt(columnId)}).categoryRef) {
                                            //        // console.log(inputref)
                                            //        //  inputref.current.focus()
                                            //        //  if (inputref.current) {
                                            //        //      console.log('djkasdj')
                                            //        //      inputref.current.focus()
                                            //        //  }
                                            //     }
                                            // }}
                                        />

                                    </>
                                    :
                                    <strong>
                                        {column.name?
                                            column.name:
                                            editCategoryArray && editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}) &&  editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).name
                                        }
                                    </strong>
                                }

                                <span className="pink-color">
                                    {column.items.length > 0 &&
                                    <> ({column.items.length}) </>
                                    }
                                </span></h3>

                            {column.id === "" &&
                            <div className="card-test-action-option">
                                {editCategoryArray && editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}) && editCategoryArray.find(function(item) {return item.uniqueId === parseInt(column.uniqueId)}).isEditing === true ?
                                    <button type="submit" onClick={(e)=>renameCategory(e,columnId,index)} className="save-category-input">
                                        <img
                                            className="save-category-input"
                                            src={process.env.REACT_APP_URL + "images/save-icon.svg"}/>
                                    </button>
                                    :
                                    <span className="save-category-input" onClick={() => editCategory(columnId, column.name)}><img
                                        className="save-category-input"
                                        src={process.env.REACT_APP_URL + "images/edit-icon.svg"}/></span>
                                }
                                <span className="save-category-input" onClick={()=>deleteCategory(index,columnId,column.uniqueId)}><img className="save-category-input" src={process.env.REACT_APP_URL + "images/trash-black.svg"}/></span>
                            </div>
                            }


                        </div>
                        </form>



                        <Droppable droppableId={columnId} key={columnId}>
                            {(provided, snapshot) => {
                                return (

                                <div className={`card-cat-card-data-hold ${minimizeData?'d-none':''}`}
                                     {...provided.droppableProps}
                                     ref={provided.innerRef}
                                >
                                    <div className="active-tiles-wrap ondragAddDesign" id={column.uniqueId}>
                                        {column.items.map((item, columnIndex) => {
                                            return (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={`test-${item.id}`}
                                                    index={columnIndex}
                                                >
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <span id={"2"}  ref={provided.innerRef}
                                                                   {...provided.draggableProps}
                                                                   {...provided.dragHandleProps}
                                                                   className={
                                                                       snapshot.isDragging
                                                                           ? "dragging-card-items draggable-item"
                                                                           : "draggable-item"
                                                                   }
                                                                   style={
                                                                       snapshot.isDragging?{
                                                                       userSelect: "none",
                                                                       // borderColor: snapshot.isDragging
                                                                       //     ? "transparent"
                                                                       //     : "#595972",
                                                                       ...provided.draggableProps.style
                                                                        }:{
                                                                           userSelect: "none",
                                                                          // ...provided.draggableProps.style
                                                                       }
                                                                   }
                                                                  onMouseDown={()=>onDraggingCreateCategory(item,index,columnId)}
                                                                  // onTouchStart={(e)=>{
                                                                  //     if(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1)) {
                                                                  //
                                                                  //         setOnDraggingCreateNewCategory(true) // on dragging create new catgory
                                                                  //         setOnDraggingCreateNewCategoryCardData(item) // on dragging create new catgory
                                                                  //         setOnTouchMobileCreateTmpHtml(true);
                                                                  //         setMoveItemsCategoryToTmpCategory({id: index, editCategoryId: columnId});
                                                                  //         onMouseOverCreateTmpCategory(e, item);
                                                                  //     }
                                                                  // }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M8 5.1547L8.73205 5.57735V6.42265L8 6.8453L7.26795 6.42265L7.26795 5.57735L8 5.1547Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                    <path d="M16.8453 6L16.4226 6.73205H15.5774L15.1547 6L15.5774 5.26795L16.4226 5.26795L16.8453 6Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                    <path d="M8.8453 12L8.42265 12.7321H7.57735L7.1547 12L7.57735 11.2679L8.42265 11.2679L8.8453 12Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                    <path d="M16 11.1547L16.7321 11.5774V12.4226L16 12.8453L15.2679 12.4226L15.2679 11.5774L16 11.1547Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                    <path d="M8 17.1547L8.73205 17.5774V18.4226L8 18.8453L7.26795 18.4226L7.26795 17.5774L8 17.1547Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                                    <path d="M16.8453 18L16.4226 18.7321H15.5774L15.1547 18L15.5774 17.2679L16.4226 17.2679L16.8453 18Z" fill="#FF66AD" stroke="#FF66AD" stroke-width="2"/>
                                                               </svg>
                                                                {item.name}
                                                                <i className="fa fa-times-circle" onClick={()=>deleteCard(item.id,item.name,index,column.uniqueId)} aria-hidden="true"></i>
                                                                                                    </span>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}

                                    </div>

                                    {snapshot.isDraggingOver || column.items.length=== 0 &&
                                    <p className={`${snapshot.isDraggingOver?'yes':'no'} no-cards-data`}>{test.language=="en"?"No cards added in category":"لا يوجد بطاقات مضافة في الفئة"}</p>
                                    }
                                </div>
                                );
                            }}
                        </Droppable>

                    </div>

        );
    }

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    const renderedCategoryHtml = () => {

        var render_html_left = [];
        var render_html_center = [];
        var render_html_right = [];
        var checkColumn = 0;
        {Object.entries(columns).map(([columnId, columnss], index) => {


            /*if(index !== 0){
                checkColumn = 2 + (index-1)*3;
                console.log(checkColumn)
            }*/


            // {index !== 0 && index%3 !== 0 &&
            //    (checkColumn === index)?
            //     render_html_center.push(
            //             renderedCategoryCommonHtml(columnId,column,index)
            //         )
            //     :
            //         render_html_left.push(
            //             renderedCategoryCommonHtml(columnId,column,index)
            //         )
            // }

            /*if(index !== 0) {
                {index % 3 === 0 ?
                    render_html_right.push(renderedCategoryCommonHtml(columnId, column, index))
                    :
                    (checkColumn !== 2 && checkColumn === index)?
                        render_html_center.push(renderedCategoryCommonHtml(columnId,column,index))
                         :
                         render_html_left.push(renderedCategoryCommonHtml(columnId,column,index))

                }
            }*/

            if(index !== 0) {
                render_html_left.push(renderedCategoryCommonHtml(columnId,columnss,index))
            }
        })}
        return(

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid card-sorting-selected-test-cat"
                columnClassName="my-masonry-grid_column card-sort-test-view-lhs"
                onMouseUp={onMouseMoveCreateCategory}
                onMouseOver={onMouseOverCreateTmpCategory}
            >
                {render_html_left}


                {/*create tmp div */}
                <div className={`card-category-data-hold tmp-cat-hover tmp-category ${!tmpCategoryHtml?'d-none':''} `}>
                    <div className="card-cat-top-wrp tmp-cat-hover">
                        <h3 className={"tmp-cat-hover"}>
                            <b className={"tmp-cat-hover"}>Drop to create category</b>
                        </h3>
                    </div>
                    <div className="card-cat-card-data-hold tmp-cat-hover tmp-cat-style" style={{height:'30px'}}>
                        <div className="active-tiles-wrap tmp-cat-hover"></div>
                    </div>
                </div>

            </Masonry>
            // <div className="card-sorting-selected-test-cat">
            //
            //     <Masonry
            //         breakpointCols={breakpointColumnsObj}
            //         className="my-masonry-grid card-sorting-selected-test-cat"
            //         columnClassName="my-masonry-grid_column card-sort-test-view-lhs"
            //     >
            //         {render_html_left}
            //     </Masonry>
            //     {/*<div className="card-sort-test-view-lhs">{render_html_left}</div>*/}
            //     {/*<div className="card-sort-test-view-rhs">{render_html_center}</div>*/}
            //     {/*<div className="card-sort-test-view-rhs end-right">{render_html_right}</div>*/}
            //
            //
            // </div>
        );
    }

    const onMouseOverCreateTmpCategory = (e,item= null) =>{
        //console.log(e.target.classList)

        if(e.target.classList.contains('my-masonry-grid') || e.target.classList.contains('my-masonry-grid_column') || e.target.classList.contains('draggable-item')) {


            //console.log(onDraggingCreateNewCategoryCardData)
            if (item || onDraggingCreateNewCategoryCardData) {

               // console.log('sss')
                if(item){
                  //  console.log('yes')
                    setOnDraggingCreateNewCategoryCardData(item);
                }

                // show tmp category html
                setTmpCategoryHtml(true)
            }

        }

    }

    const onMouseMoveCreateCategory = e =>{
        //console.log(e.pageX)
        //console.log(e.pageY)
        //console.log(e.target.classList)
        //  || e.target.classList.contains('dragging-card-items')

        // if(e.target.classList.contains('my-masonry-grid') || e.target.classList.contains('my-masonry-grid_column')){
         if(e.target.classList.contains('tmp-cat-hover') || e.target.classList.contains('dragging-card-items')){

             //console.log(onDraggingCreateNewCategoryCardData)
             if(onDraggingCreateNewCategoryCardData) {
                 createNewCategoryTmpToPermanent();

             }
        }

    }

    const createNewCategoryTmpToPermanent = () =>{

        //const [moveItemsCategoryToTmpCategory, setMoveItemsCategoryToTmpCategory] = useState({id:'',editCategoryId:''});

        console.log(moveItemsCategoryToTmpCategory)
        if(moveItemsCategoryToTmpCategory.id){
            const checkCategoryItems = Object.entries(columns)[moveItemsCategoryToTmpCategory.id];
           // const checkCategoryItems = Object.entries(columns)[moveItemsCategoryToTmpCategory.id][1];
            if(checkCategoryItems){
                var yourCardItems = Object.entries(columns)[moveItemsCategoryToTmpCategory.id][1].items;
            }else{
                var yourCardItems = Object.entries(columns)[0][1].items;
            }
        }else{
            var yourCardItems = Object.entries(columns)[0][1].items;

        }


        const yourCardItemsNew = yourCardItems.filter(item => item.id !== onDraggingCreateNewCategoryCardData.id)

        const newCategoryCard = [onDraggingCreateNewCategoryCardData];
        // const columnsFromBackend = {
        //     ['1']: {
        //         name: "",
        //         type: "",
        //         items: yourCardItems
        //     },
        //     ['2']: {
        //         id: "",
        //         name: '',
        //         type: "category",
        //         items: newCategoryCard
        //     }
        // };


        // enable edit category data
        var editCategoryData = editCategoryArray;
        //console.log(editCategoryData)

        var getEditCategoryLength = editCategoryArray.length;
        if(getEditCategoryLength=== 0){
            var getEditCategoryId = 2 + databaseCategoryArray.length;
        }else{
            //console.log(editCategoryArray)


            if(databaseCategoryArray.length === 0){
                var getEditCategoryId = editCategoryArray[editCategoryArray.length -1].id + 1;
            }else{

                if(editCategoryArray[editCategoryArray.length -1].id < (editCategoryArray.length + databaseCategoryArray.length)){
                    var getEditCategoryId = editCategoryArray[editCategoryArray.length -1].id + 1 + databaseCategoryArray.length;
                }else{
                    var getEditCategoryId = editCategoryArray[editCategoryArray.length -1].id + 1;
                }
            }

            // var getEditCategoryId = 4;
            // console.log()
        }

        //console.log(getEditCategoryId)
        var uniqueIdData = Date.now();
        editCategoryData.push({
            'id': getEditCategoryId,
            'name': '',
            'uniqueId':uniqueIdData,
            'isEditing': true,
            'error_msg': '',
            'error_class': '',
            'categoryRef': true
        });
        //console.log(editCategoryData)
        setEditCategoryArray(editCategoryData);

        //
        //
        // const columnKeys = Object.keys(columns);
        // console.log(columnKeys);
        // const ItemCards = Object.entries(columns)[1][1];
        // console.log()
        var columnKeys = Object.keys(columns);
        if(moveItemsCategoryToTmpCategory.id) {
            const CategoryItemss = Object.entries(columns)[moveItemsCategoryToTmpCategory.id];
            if(CategoryItemss) {

                const CategoryItems = Object.entries(columns)[moveItemsCategoryToTmpCategory.id][1];

                console.log(columns)
                console.log(columnKeys[moveItemsCategoryToTmpCategory.id]);
                var newCard = Object.entries(columns)[0][1].items;

                var newCardItems = newCard.filter(item => item.id !== onDraggingCreateNewCategoryCardData.id);
                console.log(newCardItems)
                console.log(onDraggingCreateNewCategoryCardData)
                Object.assign(columns, {
                    [columnKeys[moveItemsCategoryToTmpCategory.id]]: {
                        id: CategoryItems.id,
                        name: CategoryItems.name,
                        uniqueId: CategoryItems.uniqueId,
                        type: CategoryItems.type,
                        items: yourCardItemsNew
                    }
                })


                Object.assign(columns, {
                    ['1']: {
                        name: "",
                        type: "",
                        items: newCardItems
                    }
                })
            }else{

                console.log('dasdasd')
                Object.assign(columns, {
                    ['1']: {
                        name: "",
                        type: "",
                        items: yourCardItemsNew
                    }
                })
            }
        }else{
            Object.assign(columns, {
                ['1']: {
                    name: "",
                    type: "",
                    items: yourCardItemsNew
                }
            })
        }

        Object.assign(columns, {
            [(parseInt(columnKeys.pop())+1)]: {
                id: "",
                uniqueId:uniqueIdData,
                name: "",
                type: "category",
                items: newCategoryCard
            }
        })


        // console.log(columnKeys.pop());

        // add items in your card
        //const yourCardItems = Object.entries(columns)[0][1].items;
        //yourCardItems.push({'id':cardId,'name':cardName})

        // setColumns(columns);

        /*var iteration=1;
        for (const [key, value] of Object.entries(columns)) {

            if(iteration !== 1){
                Object.assign(columnsFromBackend,{
                    [iteration+1]:columns[key]
                })
            }

            iteration++;

        }*/

        //setColumns(columnsFromBackend);



        //console.log(editCategoryArray)
        //console.log(editCategoryArray.length)






        setEnableNextButton(false);
        //onDragEnd([], columns, setColumns);

        // hide tmp category html
        setTmpCategoryHtml(false);

        // apply some condition
        setOnDraggingCreateNewCategory(false)
        setOnDraggingCreateNewCategoryCardData(null)
        setMoveItemsCategoryToTmpCategory({id: '', editCategoryId: ''});
        const elemt= document.getElementsByClassName("dragging-card-items");
        if(elemt){
            console.log(elemt)
            /*if(elemt.getAttribute("style")){
                console.log('adasdsa')
            }else{
                console.log('daasdasd')
            }*/
            /*if(elemt.hasAttribute('style')){
                console.log('yes gound')
            }else{
                console.log('das');
            }*/
            elemt.removeAttr("style");

            //span.dragging-card-items.draggable-item
            //span#2.dragging-card-items.draggable-item
            //span.dragging-card-items.draggable-item
        }
    }


    return (
        <>
            {initialScreen &&
            <CardSortingIntroduction  goToFirstTask={
                ()=>{
                    setInitialScreen(false);
                    // location scroll top
                    window.scrollTo(0, 0);


                }
            }
            testlanguage={test.language}
             />
            }
            {!initialScreen &&
            <>
                <div onClick={(e)=>runFunction(e)} className={`tester-page-wrapper tester-welcome-page-wrap ${isTyping?'input-type-active':''} ${isDragingStartItem?'card-dragging-start':''}`}>
                    <DragDropContext
                        onDragEnd={result => onDragEnd(result, columns, setColumns)} onDragStart={(e)=>{
                        setIsDragingStartItem(true)
                        //console.log(e);

                    }} onDragUpdate={(e)=>{

                        if(e.destination){
                            //console.log(parseInt(e.destination.droppableId));
                            //console.log(parseInt(e.destination.droppableId)-2)
                            var cusid_ele = document.getElementsByClassName('ondragAddDesign')[parseInt(e.destination.droppableId)-2];
                           //console.log(cusid_ele)
                          // console.log(document.getElementsByClassName('ondragAddDesign'))
                            if(cusid_ele){
                               // cusid_ele.lastChild.className = "addBorderWhileDragging";
                               // console.log(cusid_ele.lastChild)
                              // console.log(cusid_ele.getAttribute('id'))
                                if(cusid_ele.lastChild){
                                   // cusid_ele.lastChild.className = "addBorderWhileDragging";
                                }
                           }

                        }
                       // onMouseMoveCreateCategory(e);
                    }}>
                    <div className="container inner-page-container">

                        <div className="card-sorting-test-view-data-wrap">
                            <div className="sort-top-mob-secton none-for-desktop">
                                <h1 className="sorting-heading">{test.language=="en"?"Take a look at the cards. Group them into categories that you think would best fit. "
                                :"ألق نظرة على البطاقات، في أي فئة أدناه ستضع كل بطاقة؟"}</h1>
                                <div className="cards-info-help-wrap">

                                    {infoText &&
                                    <div className="profile-complete-progress-wrap card-sorting-info">
                                        <div className="pro-lft-wrap">

                                            {cardSorting.is_required_categories === 1 &&
                                                <>
                                                    {test.language == "en" ?
                                                        "Drag the cards into categories that you think would suit it best."
                                                        :
                                                        "اسحب البطاقات إلى الفئات التي تعتقد أنها تناسبها بشكل أفضل"
                                                    }
                                                </>
                                            }
                                            {cardSorting.is_required_categories !== 1 &&
                                            <>
                                                {test.language == "en" ?
                                                    "Drag the cards into categories that you think would suit it best."
                                                    :
                                                    "اسحب البطاقات إلى الفئات التي تعتقد أنها تناسبها بشكل أفضل"
                                                }
                                            </>
                                            }
                                        </div>
                                        <div className="pro-right-wrap">

                                            <div className="delete-progress-row" onClick={() => setInfoText(false)}>
                                                <img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img"/>
                                            </div>
                                        </div>
                                    </div>
                                    }


                                    <span className="open-card-info-btn" onClick={()=>setInfoText(true)}><img src={process.env.REACT_APP_URL+"images/help.svg"}/></span>
                                </div>
                            </div>

                            {renderedHtml()}


                            <div className="card-sorting-cat-view-wrap ">
                                <h1 className="none-for-mobile">{test.language=="en"?"Take a look at the cards. Group them into categories that you think would best fit. "
                                :"ألق نظرة على البطاقات، في أي فئة أدناه ستضع كل بطاقة؟"}</h1>
                                <div className="cards-info-help-wrap none-for-mobile">

                                    {infoText &&
                                    <div className="profile-complete-progress-wrap card-sorting-info" onMouseOut={() => { setInfoText(false); }}>
                                        <div className="pro-lft-wrap">
                                            {cardSorting.is_required_categories === 1 &&
                                            <>
                                                {test.language == "en" ?
                                                    "Drag the cards into categories that you think would suit it best."
                                                    :
                                                    "اسحب البطاقات إلى الفئات التي تعتقد أنها تناسبها بشكل أفضل"
                                                }
                                            </>
                                            }
                                            {cardSorting.is_required_categories !== 1 &&
                                            <>
                                                {test.language == "en" ?
                                                    "Drag the cards into categories that you think would suit it best."
                                                    :
                                                    "اسحب البطاقات إلى الفئات التي تعتقد أنها تناسبها بشكل أفضل"
                                                }
                                            </>
                                            }
                                        </div>
                                        <div className="pro-right-wrap">

                                            <div className="delete-progress-row" onClick={() => setInfoText(false)}>
                                                <img src={process.env.REACT_APP_URL + "images/cross.svg"} alt="img"/>
                                            </div>
                                        </div>
                                    </div>
                                    }


                                    <span className="open-card-info-btn" onClick={()=>setInfoText(true)} onMouseOver={() => { setInfoText(true) }}><img src={process.env.REACT_APP_URL+"images/help.svg"}/></span>
                                </div>

                                <div className={`cardtake-test-top none-for-mobile d-flex align-items-center ${(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1))?'justify-content-between':'justify-content-end'}`}>
                                    {(cardSorting.card_sorting=== 'open' || (cardSorting.card_sorting=== 'close' && cardSorting.is_add_new_category=== 1)) &&

                                        <button onClick={addCategory} className="create-btn">{test.language=="en"?"Add category":"أضف فئة"} <span className="save-category-input"><img className="save-category-input" src={process.env.REACT_APP_URL + "images/plus-white.svg"}/></span> </button>
                                    }

                                    <div className="minimize-box-wrap">
                                        {!minimizeData &&
                                        <span onClick={() => setMinimizeData(true)}>
                                            {test.language === 'en' ?
                                                "Minimize"
                                                :
                                                "تصغير"
                                            }
                                            <img
                                            src={process.env.REACT_APP_URL + "images/minim-icon.svg"}/> </span>
                                        }
                                        {minimizeData &&
                                        <span onClick={() => setMinimizeData(false)}>
                                            {test.language === 'en' ?
                                                "Expand"
                                                :
                                                "تكبير"
                                            }
                                            <img
                                            src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}/> </span>
                                        }
                                    </div>
                                </div>

                                {renderedCategoryHtml()}

                                <div className={`button-wrap d-flex justify-content-end mt-64 card-sorting-test-buttons ${isTyping?'keyboard-active-button':''} ${isDragingStartItem?'hide-next-btn-for-mobile-when-dragging':''}`}>
                                    {/*<button type="submit" className="button secondary-btn">Skip task</button>*/}
                                    <button onClick={saveCardSorting} type="button"
                                            className={`button primary-btn
                                             ${(cardSorting.is_required_categories === 1 && Object.entries(columns)[0][1].items.length > 0)?'disabled-button':
                                                (cardSorting.is_required_categories === 0 && Object.entries(columns)[0][1].items.length === cardData.length)?'disabled-button':
                                                !isEnableNextButton ? 'disabled-button'
                                                    :'' }
                                             `}>
                                        {test.language=="en"?"Next":"التالي"}
                                        {formLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                aria-hidden="true"
                                                style={{ marginLeft: "5px" }}
                                            ></i>
                                        )}
                                    </button>
                                </div>

                            </div>


                        </div>
                    </div>
                    </DragDropContext>
                </div>
                {/*<RenameModal*/}
                {/*    title={renameData.title}*/}
                {/*    id={renameData.id}*/}
                {/*    open={renameData.open}*/}
                {/*    heading={"Rename category"}*/}
                {/*    close={()=>{setRenameData({...renameData,"open":false,isLoading:false} )}}*/}
                {/*    isLoading={false}*/}
                {/*    confirm={(title,id)=>renameCategory(title,id)}*/}
                {/*/>*/}
                <ConfirmModal
                    confirm_message="Are you sure you want to delete this category? This will remove all the cards that you've added."
                    confirm_btn_title="YES"
                    confirm_title="Delete Category"
                    openModal={deleteCategoryModal.open}
                    close={() => {
                        setDeleteCategoryModal({ ...deleteCategoryModal, open: false });
                        setIsDeleteCategoryLoading(false);
                    }}
                    confirm={() => {
                        setDeleteCategoryModal({ ...deleteCategoryModal, open: false });
                        confirmDeleteCategory(deleteCategoryModal.id, deleteCategoryModal.editCategoryid,deleteCategoryModal.unique_id);
                    }}
                />
            </>
            }

        </>

    );
}