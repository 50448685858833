import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate , Link } from 'react-router-dom';

export default function ForgotPasswordEmail() {
    
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME+" - Forgot Password";
    }, []);

    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                <a href={process.env.REACT_APP_TESTER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
                {/*
                <div className="login-hdr-right">
                    <a href="javascript:void();">Become a researcher</a>
                </div>*/}
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <h1 className="form-heading"><FormattedMessage id="An email has just been sent to your email address!" /></h1>
                        <p className="form-sub-head"><FormattedMessage id="Click on the link in the email to generate a new password" /></p>
                        <div className="login-form-inner-data">                 
                            <div className="reset-btn-wrap">
                                <Link to="/login" className="btn btn-block form-btn form-back-btn">BACK TO SIGN IN</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}