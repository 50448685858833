import React from "react";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import SortableTree from "react-sortable-tree";
import { maxLengthCheck } from "../../../lib/helpers";
import {
	addNodeUnderParent,
	getNodeAtPath,
	removeNodeAtPath,
} from "react-sortable-tree";
import Task from "./tree_sort/task";
import { useState, useEffect, useRef } from "react";
import {
	addNodeService,
	getTestData,
	removeNodeService,
	removeNodesService,
	addTaskService,
	updateTreeService,
	importCSVService,
} from "../../../services/test";
import toast from "react-hot-toast";
import ConfirmModal from "../../dialog/confirm_modal";
import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { FormattedMessage } from "react-intl";
import InputSearchField from "../../layouts/elements/fields/inputSearch";
import { count } from "d3";
import { LoadingIcon } from "../../loader/loadingIcon";


//const div = document.querySelector('.target-group-data-wrapper.full');
//const resizeObserver = new ResizeObserver(() => {
//  console.log(div.clientHeight);
//});

//resizeObserver.observe(div);

export default function TreeSortTest() {
	let { test_id } = useParams();
	const nodeRef = useRef(null);
	const fileRef = useRef(null);
	const homeRef = useRef(null);
	const sectionsRef = useRef([]);
	const navigate = useNavigate();
	ReactSession.setStoreType("localStorage");
	const [formValues, setFormValues] = useState({
		tree: [],
		tasks: [],
		home_name:"Home"
	});
	const [answer_paths, setAnswerPaths] = useState({});

	const [pageLoading, setPageLoading] = useState(false);

	const [treeLoading, setTreeLoading] = useState(false);

	const [treeStepLoading, setTreeStepLoading] = useState(false);

	const [lastSaved, setLastSaved] = useState(null);

	const [isLoading, setLoading] = useState(false);

	const [confirmModal, setConfirmModal] = useState({ open: false });

	const [confirmDeleteModal, setConfirmDeleteModal] = useState({ open: false });

	const [confirmDeleteInnerModal, setConfirmDeleteInnerModal] = useState({ open: false, callback: null });


	const [importModal, setImportModal] = useState({ open: false });

	const [search, setSearch] = useState("");

	const [test, setTest] = useState("");

	const [editable_text, setEditableText] = useState("");

	const [editable_home, setEditableHome] = useState(false);

	const [step, setStep] = useState(0);

	function showmore(divID) {
		//document.getElementById(divID).style.height = '600px';
		document.getElementById(divID).classList.add("expendit");
	}

	function showless(divID) {
		document.getElementById(divID).classList.remove("expendit");
	}

	const [formErrors, setFormErrors] = useState({
		node: "",
		error_class: "input_error",
	});

	let treeError = false;

	let treeAnswerError = false;

	let lessthan5children = true;

	let timer;

	function autoAdjustHeight(){
		timer = setTimeout(() => {
			
			var slides = document.getElementsByClassName("target-group-data-repeat");
			for (var i = 0; i < slides.length; i++) {
				var bbheight = 0;
				var ccheight = 0;
				var bb = slides
					.item(i)
					.getElementsByClassName("targer-group-left-wrap")[0];
				var cc = slides
					.item(i)
					.getElementsByClassName("target-group-info-repeat")[0];
				if (bb) {
					bbheight = bb.offsetHeight;
				}
				if (cc) {
					ccheight = cc.offsetHeight;
				}
				//console.log('>>'+bbheight+'<><>'+ccheight);
				if (bbheight < ccheight) {
					cc.classList.add("lessshow");
					cc.style.height = bbheight + "px";
				} else {
					cc.classList.add("normalshow");
				}
			}
		}, 3000);
		
	}
	/*useEffect(() => {
		
		autoAdjustHeight();

		return () => clearTimeout(timer);
	}, []);*/

	const showError = (error) => {
		toast(
			<div className="toastinner">
				{error}
				<a
					onClick={() => {
						toast.dismiss();
					}}
				>
					&times;
				</a>
			</div>,
			{
				className: "errortoast",
				position: "bottom-center",
				duration: 3000,
			}
		);
	};

	const navigateTree = (tree, task) => {
		tree.forEach(function (item) {
			if (task == "expand") {
				item["expanded"] = true;
			}
			if (task == "error") {
				if (!item.title) {
					treeError = true;

					item["error"] = true;
				} else {
					item["error"] = false;
				}
			}
			if (task == "editable") {
				item["editable"] = false;
			}
			if (item.children.length > 0) {
				navigateTree(item.children, task);
			}
		});
	};
	const checkIfNonLeaf = (tree, answer_id) => {
		tree.forEach(function (item) {
			if (answer_id == item.id && item.children.length > 0) {
				treeAnswerError = true;

				item["error"] = true;
			}

			if (item.children.length > 0) {
				checkIfNonLeaf(item.children, answer_id);
			}
		});
	};
	const getTestApi = () => {
		let token = ReactSession.get("token");

		setPageLoading(true);

		getTestData({ test_id: test_id }, token).then((response) => {
			setPageLoading(false);

			if (response.success) {
				setTest(response.test);

				//setFormValues({...formValues, home_name:response.test.home_name});

				if (response.test.status) {
					if (response.test.status == "published") {
						navigate("/researcher/project/" + response.test.project_id);
					} else {
						if (
							response.test.is_briefing_questions == 1 &&
							response.test.briefingQuestion.length == 0
						) {
							navigate("/researcher/tests/" + test_id + "/introduction");
							showError("Please add briefing questions");
						}
					}
				}
				// redirectToStep({
				//   test_id: test_id,
				//   current_step: 2,
				//   saved_step: response.test.saved_step,
				//   navigate: navigate,
				// });
				/*if(response.test.saved_step || response.test.saved_step==0){
					
					if(response.test.saved_step<1){
					
						
						//navigate("/researcher/tests/"+test_id+"/introduction");
					}
				}*/
				setLastSaved(moment(response.test.updated_at));

				navigateTree(response.test.tree, "expand");

				setFormValues({ tree: response.test.tree, tasks: response.test.tasks, home_name:response.test.home_name });

				if(response.test.tasks.length==0){
					addTask();
				}

				autoAdjustHeight();

			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});
	};
	const saveTreeStep = () => {
		var error = false;

		treeError = false;

		treeAnswerError = false;
		if (formValues.tasks.length == 0) {
			error = true;

			/*toast(
				<div className="toastinner">
					{
						<FormattedMessage id="Looks like you haven't entered a task for this test! Please add a task to move to the next steps." />
					}
					<a
						onClick={() => {
							toast.dismiss();
						}}
					>
						&times;
					</a>
				</div>,
				{
					className: "errortoast",
					position: "bottom-center",
				}
			);*/
		}

		formValues.tasks.forEach(function (item) {
			item["errors"] = {
				title: null,
				answer_error: null,
				answer: null,
				error_class: "input_error",
			};
			if (!item.correct_answer_id) {
				//item["errors"] = { ...item.errors, answer_error: "Required" };

				error = true;


			} else {
				checkIfNonLeaf(formValues.tree, item.correct_answer_id);
			}
			if (!item.title) {

				item["errors"] = { ...item.errors, title: "Required" };

				error = true;
			}
		});
		setFormValues({ ...formValues, tasks: formValues.tasks });
		navigateTree(formValues.tree, "error");

		/*if(error){
			toast(
				<div className="toastinner">
					{
						"There are some errors in the defined tasks. Kindly check."
					}
					<a
						onClick={() => {
							toast.dismiss();
						}}
					>
						&times;
					</a>
				</div>,
				{
					className: "errortoast",
					position: "bottom-center",
				}
			);
		}*/
		if (treeError) {
			error = true;

			setFormValues({ ...formValues, tree: formValues.tree });

			/*toast(
				<div className="toastinner">
					{
						"There are some errors in the defined tree. Kindly check."
					}
					<a
						onClick={() => {
							toast.dismiss();
						}}
					>
						&times;
					</a>
				</div>,
				{
					className: "errortoast",
					position: "bottom-center",
				}
			);*/

		}
		if (treeAnswerError) {
			error = true;
			/*toast(
				<div className="toastinner">
					{
						<FormattedMessage id="Looks like you have selected a wrong Correct Answer. Make sure that the Correct Answer for all tasks is the last child of a node." />
					}
					<a
						onClick={() => {
							toast.dismiss();
						}}
					>
						&times;
					</a>
				</div>,
				{
					className: "errortoast",
					position: "bottom-center",
				}
			);*/
		}
		if (error) {
			toast(
				<div className="toastinner">
					{
						<FormattedMessage id="Some required information for the tree testing is missing or incomplete. Please review and try again." />
					}
					<a
						onClick={() => {
							toast.dismiss();
						}}
					>
						&times;
					</a>
				</div>,
				{
					className: "errortoast",
					position: "bottom-center",
				}
			);
		}
		if (!error) {
			saveTreeData(formValues, true);
		}
	};
	const saveTreeData = (form, shouldnavigate) => {
		if (shouldnavigate) {
			setTreeStepLoading(true);
		} else {
			setLoading(true);
		}

		let new_tree = [];

		for(var i=0; i< form.tree.length;i++){

			let temp_tree = form.tree[i];

			delete temp_tree["test_id"];
			
			new_tree.push(temp_tree);

		}
		

		let data = Object.assign({}, form);

		data["test_id"] = test_id;

		data["tree"] = new_tree;

		data["autosave"] = 1;

		if (shouldnavigate) {
			data["autosave"] = 0;
		}

		updateTreeService(data, ReactSession.get("token")).then((response) => {
			setLoading(false);

			setTreeStepLoading(false);

			if (response.success) {
				setLastSaved(moment.now());
				if (shouldnavigate) {
					navigate("/researcher/tests/" + test_id + "/conclusions");
				}
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});
	};
	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Tree Sort";

		//window.addEventListener('scroll', handleScroll);

		getTestApi();
	}, []);

	useEffect(() => {
		
		
			
		if (nodeRef && nodeRef.current) {
			nodeRef.current.focus();

			nodeRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
		
		const timer = setInterval(() => {
			if (test && test.status != "published") {
				saveTreeData(formValues, false);
			}
		}, 30000);
		

		return () => {
			clearTimeout(timer);
		};
	}, [formValues]);
	useEffect(() => {
		if (homeRef && homeRef.current) {
			
			homeRef.current.focus();

			
		}
		
	}, [editable_home]);
	const set = (name) => {
		return ({ target: { value } }) => {
			setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		};
	};
	const setFormValue = (name, value) => {
		setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
		console.log(formValues)
	};
	const showFormError = (name, value) => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		formErrorsLocal[name] = value;
		formErrorsLocal["error_class"] = "input_error";
		setFormErrors(formErrorsLocal);
		console.log(formErrorsLocal);
	};
	const clearFormValue = (name) => {
		return ({ target: { value } }) => {
			setTimeout(function () {
				setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
			}, 1000);
		};
	};
	const resetFormErrors = () => {
		let formErrorsLocal = Object.assign({}, formErrors);
		for (var key in formErrors) {
			formErrorsLocal[key] = null;
		}
		setFormErrors(formErrorsLocal);
	};
	const addNode = (e) => {
		e.preventDefault();
		//if (node != "") {
		let token = ReactSession.get("token");

		setTreeLoading(true);

		setEditableText("");

		addNodeService(
			{ test_id: test_id, parent_id: null, title: "", placeholder: "Level 1" },
			token
		).then((response) => {
			setTreeLoading(false);

			if (response.success) {
				var tree = [...formValues.tree];

				response.node.editable = true;

				tree.push(response.node);

				setFormValue("tree", tree);

				setFormValue("tree", tree);

				autoAdjustHeight();
				
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});

		/*} else {
			showFormError("node", "Please enter name");
		}*/
	};
	const addTask = (e=null) => {
		if(e){
			e.preventDefault();
		}
		let token = ReactSession.get("token");

		setLoading(true);

		addTaskService({ test_id: test_id, title: "" }, token).then(
			(response) => {
				setLoading(false);

				if (response.success) {
					var tasks = formValues.tasks;
					tasks.push({
						id: response.task.id,
						title: response.task.title,
						correct_answer: "",
						correct_answer_id: null,
					});
					setFormValue("tasks", tasks);

					autoAdjustHeight();

				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};
	const removeNodeApi = (node, path) => {
		console.log(path);
		let token = ReactSession.get("token");

		setTreeLoading(true);

		removeNodeService({ test_id: test_id, id: node.id }, token).then(
			(response) => {
				setTreeLoading(false);

				if (response.success) {
					var id = node.id;
					var tree = removeNodeAtPath({
						treeData: formValues.tree,
						path: path,
						getNodeKey: ({ treeIndex: number, node: TreeNode }) => {
							return number;
						},
						ignoreCollapsed: true,
					});
					setFormValue("tree", tree);

					var tasks = formValues.tasks;

					tasks.forEach(function (test) {
						if (test.correct_answer_id == id) {
							test.correct_answer_id = null;
							test.correct_answer = "";
						}
					});
					autoAdjustHeight();

				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};
	const removeNode = (node, path) => {
		return (e) => {
			setConfirmModal({ open: true, node: node, path: path });
		};
	};
	const removeAllNodesApi = () => {

		let token = ReactSession.get("token");

		setTreeLoading(true);

		removeNodesService({ test_id: test_id }, token).then(
			(response) => {
				setTreeLoading(false);

				if (response.success) {

					setFormValue("tree", []);

					autoAdjustHeight();

				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: "errortoast",
							position: "bottom-center",
						}
					);
				}
			}
		);
	};
	const makeEditableNode = (path) => {
		return (e) => {
			let found_node = getNodeAtPath({
				treeData: formValues.tree,
				path: path, // list of tree indexes on the editing node
				getNodeKey: ({ treeIndex }) => treeIndex,
			});
			if(!found_node.node.editable){
				found_node.node.editable = true;

				setEditableText(found_node.node.title);

				//console.log()
				setFormValue("tree", formValues.tree);
			}
		};
	};
	const updateNodeTitle = (path) => {
		return (e) => {
			//alert()
			let found_node = getNodeAtPath({
				treeData: formValues.tree,
				path: path, // list of tree indexes on the editing node
				getNodeKey: ({ treeIndex }) => treeIndex,
			});
			found_node.node.editable = false;

			if (editable_text != "") {
				found_node.node.title = editable_text;
			}
			navigateTree(formValues.tree, "error");

			

			setFormValue("tree", formValues.tree);
		};
	};
	const addChild = (path) => {
		return (e) => {
			if (path.length > 5) {
				toast(
					<div className="toastinner">
						{<FormattedMessage id="You can add up to 5 child nodes only." />}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);

				return;
			}

			let parentNode = getNodeAtPath({
				treeData: formValues.tree,
				path: path,
				getNodeKey: ({ treeIndex }) => treeIndex,
				ignoreCollapsed: true,
			});
			

			if(!treeLoading){
				setTreeLoading(true);

				navigateTree(formValues.tree, "editable");

				setEditableText("");

				addNodeService(
					{ test_id: test_id, parent_id: parentNode.node.id, title: "" },
					ReactSession.get("token")
				).then((response) => {
					setTreeLoading(false);

					if (response.success) {
						let getNodeKey = ({ node: object, treeIndex: number }) => {
							return number;
						};
						

						response.node.editable = true;
						
						let parentKey = getNodeKey(parentNode);
						
						var tree = addNodeUnderParent({
							treeData: formValues.tree,
							newNode: response.node,
							expandParent: true,
							parentKey: parentKey,
							ignoreCollapsed: true,
							getNodeKey: ({ treeIndex }) => treeIndex,
						});
						setFormValue("tree", tree.treeData);


						autoAdjustHeight();

						
					} else {
						toast(
							<div className="toastinner">
								{response.message}
								<a
									onClick={() => {
										toast.dismiss();
									}}
								>
									&times;
								</a>
							</div>,
							{
								className: "errortoast",
								position: "bottom-center",
							}
						);
					}
				});
			}
		};
	};
	const removeAnswer = (node_id) => {

		var tasks_to_remove = [];

		var new_tasks = [];


		for (var key in answer_paths) {
			for (var i = 0; i < answer_paths[key].length; i++) {

				if (answer_paths[key][i]["id"] == node_id) {
					tasks_to_remove.push(key);
				}
			}
		}
		for (var i = 0; i < formValues.tasks.length; i++) {
			var temp_task = formValues.tasks[i];
			for (var j = 0; j < tasks_to_remove.length; j++) {
				if (parseInt(tasks_to_remove[j]) == i) {
					temp_task["correct_answer_id"] = null;
				}
			}
			new_tasks.push(temp_task);
		}
		setFormValues({ ...formValues, tasks: new_tasks });

		autoAdjustHeight();
	}
	const renderTasks = () => {
		var tasks_render = [];
		for (var i = 0; i < formValues.tasks.length; i++) {
			tasks_render.push(
				<Task
					errors={
						formValues.tasks[i].errors
							? formValues.tasks[i].errors
							: { title: null, answer_error: null, error_class: "input_error" }
					}
					updateTaskPath={(task_id, path) => {
						console.log(path)
						setAnswerPaths({ ...answer_paths, [task_id]: path })
					}}
					title={"Task " + (i + 1)}
					key={"task" + i}
					language={test ? test.language : ""}
					index={i}
					total_tasks={formValues.tasks.length}
					task={formValues.tasks[i]}
					tree={formValues.tree}
					onChange={(task, index) => {
						var tasks = formValues.tasks;
						tasks[index] = task;
						console.log(tasks);
						setFormValue("tasks", tasks);
					}}
					removeTask={(index) => {

						if(formValues.tasks.length>1){
							var tasks = formValues.tasks;
							delete answer_paths[index];
							tasks.splice(index, 1);
							setFormValue("tasks", tasks);
						} else {
							toast(
								<div className="toastinner">
									You cannot remove the only task.
									<a
										onClick={() => {
											toast.dismiss();
										}}
									>
										&times;
									</a>
								</div>,
								{
									className: "errortoast",
									position: "bottom-center",
								}
							);
						}
					}}
				/>
			);
		}
		return tasks_render;
	};
	const importCSVApi = (csvFile) => {
		let token = ReactSession.get("token");

		setLoading(true);

		var data = new FormData();

		data.append("file", csvFile);

		data.append("test_id", test_id);

		importCSVService(data, token).then((response) => {
			setLoading(false);

			if (response.success) {
				getTestApi();
				fileRef.current.value = null;
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: "errortoast",
						position: "bottom-center",
					}
				);
			}
		});
	};
	const handleFileInput = (event) => {
		//prevent the browser from opening the image
		event.preventDefault();
		event.stopPropagation();
		//let's grab the image file
		let csvFile = event.target.files[0];

		//console.log(formValues)

		// does not show confirmation msg if tree is empty
		if(formValues.tree.length === 0){
			importCSVApi(csvFile);
		}else{
			setImportModal({ open: true, csvFile: csvFile });
		}


	};
	const getNodeBg = (extendedNode) => {
		if (extendedNode.path.length == 2) {
			return "rgba(150, 255, 252, 0.8)";
		} else if (extendedNode.path.length == 3) {
			return "rgba(150, 255, 252, 0.35)";
		} else if (extendedNode.path.length >= 4) {
			return "rgba(150, 255, 252, 0.2)";
		} else {
			return "rgba(150, 255, 252, 1)";
		}
	};
	const checkIfMoreThan5Children = (tree, child_count) => {
		tree.forEach(function (item) {
			var child_count_loc = child_count + 1;

			if (child_count_loc > 6) {
				lessthan5children = false;

			}
			if (item.children.length > 0) {
				checkIfMoreThan5Children(item.children, child_count_loc);
			}
		})
	}

	return (
		<LayoutResearcherFullWidth
			isLoading={false}
			fixed_header_target={true}
			wrapClass={test && test.language == "ar" ? "arabic_wrapper_main" : ""}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
		>
			<div className="study-introduction-page-wrapper stydy-pages-wrapper target_group_fixed_header tree-page-wrapper">
				<div className="page-back-option-wrap">
					<div className="container inner-page-container">
						<Topbar
							stepCallback={() => {
								saveTreeData(formValues, false);
							}}
							credits_required={test.credits_required}
							test={test}
							enablePublish={false}
							onChange={(test) => {
								console.log(test);
								setTest({ ...test });
							}}
							status={test.status}
							lastSaved={lastSaved}
							path={"/researcher/tests/" + test_id + "/introduction"}
							isLoading={isLoading}
							pageLoading={pageLoading}
							title={test.test_name}
						/>
					</div>
				</div>
				<div className="container inner-page-container">
					<div className="study-steps-progress-wrap">
						{(
							<Progress
								stepCallback={() => {
									saveTreeData(formValues, false);
								}}
								test={test}
								completed_step={1}
							/>
						)}
					</div>
					{pageLoading &&
					<LoadingIcon />
					}
					{!pageLoading &&
					<div className="target-group-data-wrapper study-tree-sort-page-wrap">
						<div className="target-group-data-wrapper full">
							<div className="target-group-data-repeat repaetbox1">
								<div className="targer-group-left-wrap repaetbox1left">
									<div className=" study-target-repeat">
										<div className="target-form-wrap w-100">
											<p className="page-step-count">Page 3</p>
											<div
												id="importSection"
												ref={(element) =>
													sectionsRef.current[0] ??
													sectionsRef.current.push(element)
												}
												target="sidebarMenu1"
												className="sidebarSection"
											>
												<div className="tree-sort-top-search">
												
													<div className="tree-top-wrap">
														<div className="tree-top-left">
															<h3>
																Tree
																{treeLoading && (
																	<i
																		className="fa fa-spinner fa-spin"
																		aria-hidden="true"
																		style={{ marginLeft: "5px" }}
																	></i>
																)}
															</h3>
														</div>
														<div className="tree-top-right">
															<button
																type="button"
																className="create-btn tree-import-btn"
															>
																Import{" "}
																<span>
																	{" "}
																	<img
																		src={
																			process.env.REACT_APP_URL +
																			"images/import-icon.svg"
																		}
																		alt="img"
																	/>
																</span>
																<input
																	type="file"
																	ref={fileRef}
																	onChange={handleFileInput}
																/>
															</button>
														</div>
														
													</div>
													

													{formValues.tree.length > 0 && (
														<div className="tree-search-wrap">
															
															<div
																className={`tree-search-inner ${test.language == "ar" ? "arabic_wrapper" : ""
																	}`}
															>
																<div className="input form-group input-field mb-0">
																	<input
																		className={`${test.language == "ar"
																				? "arabic_wrapper"
																				: ""
																			}`}
																		type="text"
																		placeholder="Search"
																		value={search}
																		onChange={(event) => {
																			setSearch(event.target.value);
																		}}
																	/>
																</div>
																<span className="tree-search-icon">
																	<img
																		src={
																			process.env.REACT_APP_URL +
																			"images/search-icon.svg"
																		}
																	/>
																</span>
															</div>
														</div>
													)}
												</div>
											</div>
											<div
												id="treeSection"
												ref={(element) =>
													sectionsRef.current[1] ??
													sectionsRef.current.push(element)
												}
												target="sidebarMenu2"
												className="sidebarSection"
											>
												<div className="row">
															
													<div
														className="col-md-12 tree-node-repeat-wrap"
														style={{ height: "100%" }}
													>
														
														<div className="treeScrollbar" style={{height:"300px"}}>
															<div className="ans-list-repeat selected-card-cat-repeat">
																<div className={`ans-data`}>
																	{editable_home &&
																	<input
																		className={`form-control ${test && test.language == "ar"
																				? "arabic_wrapper"
																				: ""
																			}`}
																		type="text"
																		ref={homeRef}
																		placeholder={formValues.home_name}
																		value={formValues.home_name}
																		onChange={(e) => {
																			setEditableHome(true);

																			setFormValues({...formValues,home_name:e.target.value})
																		}}
																		onBlur={(e) => {
																			setEditableHome(false);

																			if(e.target.value){
																				setFormValues({...formValues,home_name:e.target.value})
																			} else {
																				setFormValues({...formValues,home_name:"Home"})
																			}
																			
																		}}
																		
																		onKeyPress={(e) => { if(e.key === 'Enter'){ 
																			setEditableHome(false);

																			if(e.target.value){
																				setFormValues({...formValues,home_name:e.target.value})
																			} else {
																				setFormValues({...formValues,home_name:"Home"})
																			}
																		} 
																	}
																}
																		maxLength="200"
																		onInput={maxLengthCheck} 
																	/>
																	}
																	{console.log(formValues)}
																	{!editable_home &&
																	<span className={`${test && test.language == "ar"
																	? "cairo-font"
																	: ""
																}`}>{formValues.home_name}</span>
																	}
																</div>
																
																{!editable_home &&
																<div class="ans-close-row">
																	<img onClick={()=>{setEditableHome(true)}} src={process.env.REACT_APP_URL +"images/edit-icon.svg"} />
																</div>
																}

															</div>	
															<SortableTree
																className={"userQTree"}
																searchQuery={search}
																onMoveNode={function(){
																	console.log("happended")
																}}
																searchMethod={({ node, searchQuery }) => {
																	return searchQuery &&
																		node.title &&
																		node.title
																			.toLowerCase()
																			.indexOf(searchQuery.toLowerCase()) > -1
																		? true
																		: false;
																}}
																treeData={formValues.tree}
																ignoreCollapsed={true}
																onChange={(tree) => {
																	
		
																	lessthan5children = true;
		
																	checkIfMoreThan5Children(tree, 0);
		
																	if (lessthan5children) {
		
																		setFormValues((oldValues) => ({
																			...oldValues,
																			["tree"]: tree,
																		}));

																		autoAdjustHeight();

																	} else {
																		toast(
																			<div className="toastinner">
																				{<FormattedMessage id="You can add up to 5 child nodes only." />}
																				<a
																					onClick={() => {
																						toast.dismiss();
																					}}
																				>
																					&times;
																				</a>
																			</div>,
																			{
																				className: "errortoast",
																				position: "bottom-center",
																			}
																		);
																	}
																}}
																
																generateNodeProps={(extendedNode) => ({
																	className: extendedNode.node.error
																		? "error_class"
																		: "",
																	style: {
																		background: getNodeBg(extendedNode),
																		width:
																			"calc(100% - " +
																			44 * (extendedNode.path.length - 1) +
																			"px)",
																	},
																	title: (
																		<span
																			id={"inputnode"+extendedNode.node.id}
																			style={{
																				fontWeight:
																					extendedNode.path.length > 1
																						? "300"
																						: "600",
																			}}
																			onClick={makeEditableNode(
																				extendedNode.path
																			)}
																		>
																			{extendedNode.node.editable && (
																				<input
																					className={`form-control ${test && test.language == "ar"
																							? "arabic_wrapper"
																							: ""
																						}`}
																					type="text"
																					placeholder={
																						extendedNode.path.length == 1
																							? "Level 1"
																							: "This is your child node level " +
																							extendedNode.path.length
																					}
																					ref={
																						extendedNode.node.editable
																							? nodeRef
																							: undefined
																					}
																					onBlur={(updateNodeTitle(
																						extendedNode.path
																					))}
																					onKeyPress={(e) => { if(e.key === 'Enter'){ 
																								var call = updateNodeTitle(
																									extendedNode.path
																								);
																								call();
																							} 
																						}
																					}
																					value={editable_text}
																					onChange={(e) => {
																						setEditableText(e.target.value);
																						
																					}}
																					maxLength="200"
																					onInput={maxLengthCheck} 
																				/>
																			)}
																			{!extendedNode.node.editable && (
																				<>
																					{extendedNode.node.title && (
																						<span className={`${test && test.language == "ar"
																						? "cairo-font"
																						: ""
																					}`}>{extendedNode.node.title}</span>
																					)}
																					{!extendedNode.node.title &&
																						extendedNode.node.error && (
																							<>Title needs to be entered</>
																						)}
																					{!extendedNode.node.title &&
																						!extendedNode.node.error && (
																							<>
																								{extendedNode.path.length == 1
																									? "Level 1"
																									: "This is your child node level " +
																									extendedNode.path.length}
																							</>
																						)}
																				</>
																			)}
																		</span>
																	),
																	buttons: [
																		<button
																			onClick={addChild(extendedNode.path)}
																			className="create-btn"
																		>
																			Add child{" "}
																			<span>
																				<img
																					src={
																						process.env.REACT_APP_URL +
																						"images/plus-white.svg"
																					}
																				/>
																			</span>
																		</button>,
																		<button
																			onClick={makeEditableNode(
																				extendedNode.path
																			)}
																		>
																			<span>
																				<img
																					src={
																						process.env.REACT_APP_URL +
																						"images/edit-icon.svg"
																					}
																				/>
																			</span>
																		</button>,
																		<button
																			onClick={
																				() => {
		
																					if (extendedNode.node.children.length > 0) {
																						setConfirmModal({ ...confirmModal, open: true, node: extendedNode.node, path: extendedNode.path });
																					} else {
																						removeAnswer(extendedNode.node.id);
		
																						removeNodeApi(extendedNode.node, extendedNode.path);
																					}
																				}
																			}
																		>
																			<span>
																				<img
																					src={
																						process.env.REACT_APP_URL +
																						"images/trash-black.svg"
																					}
																				/>
																			</span>
																		</button>,
																	],
																})}
															/>
															
														</div>
														<div className="add-node-wrap withoutborder">
																<div className="add-new-memberbtn-option mb-0">
																	<button
																		className="create-btn add-new-card-btn add-new-member-btn"
																		onClick={addNode}
																	>
																		Add Node
																		<span>
																			<img
																				src={
																					process.env.REACT_APP_URL +
																					"images/plus-white.svg"
																				}
																			/>
																		</span>
																	</button>
																	{formValues.tree.length > 0 &&
																		<button
																			className="create-btn delete-all-btn"
																			onClick={() => {
																				setConfirmDeleteModal({ ...confirmDeleteModal, open: true });
																			}}
																		>
																			Delete All Nodes
																			<span>
																				<img
																					src={
																						process.env.REACT_APP_URL +
																						"images/trash-black.svg"
																					}
																				/>
																			</span>
																		</button>
																	}
																</div>
															</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									id="repaetinfobox1"
									class="target-group-info-repeat repaetinfobox1"
								>
									<h3>Creating a tree</h3>
									<p>
										Your Tree is a text-only version of your webiste hierarchy.
										Your category labels (first level, second level, etc.) are
										called 'nodes', and subcategories are known as 'child
										nodes'.
									</p>
									<p>
										If you don't want to manually input the information for your
										tree test, you can import a .csv file to autmomatically
										create your tree.
									</p>
									<p>
										Need a hand with your import? Use our free template to
										populate your tree.
									</p>
									<p>
										<a
											href={
												process.env.REACT_APP_IMG_URL +
												"/docs/import-tree-example.csv"
											}
											target="_blank"
										>
											Download template
										</a>
									</p>
									<div
										className="seemorebox-sidebar"
										onClick={() => {
											showmore("repaetinfobox1");
										}}
									>
										See more
									</div>
									<div
										className="seelessbox-sidebar"
										onClick={() => {
											showless("repaetinfobox1");
										}}
									>
										See less
									</div>
								</div>
							</div>
							<div className="target-group-data-repeat repaetbox2">
								<div className="targer-group-left-wrap repaetbox2left border-0 pb-0">
									<div
										id="taskSection"
										ref={(element) =>
											sectionsRef.current[2] ??
											sectionsRef.current.push(element)
										}
										target="sidebarMenu3"
										className="row sidebarSection"
									>
										<div className="col-md-12">{renderTasks()}</div>
										<div className="col-md-12">
											<div className="add-new-memberbtn-option mb-0">
												<button
													className="create-btn add-new-card-btn add-new-member-btn "
													onClick={addTask}
												>
													Add Task
													<span>
														<img
															src={
																process.env.REACT_APP_URL +
																"images/plus-white.svg"
															}
														/>
													</span>
												</button>
											</div>

											<div className="button-wrap justify-content-end d-flex mt-64">
											<button
                                                id="r_create_step_2_build_next_btn"
												type="submit"
												className="button primary-btn"
												onClick={saveTreeStep}
											>
												Next
												{treeStepLoading && (
													<i
														className="fa fa-spinner fa-spin"
														aria-hidden="true"
														style={{ marginLeft: "5px" }}
													></i>
												)}
											</button>
										</div>
										</div>
									</div>
								</div>
								<div
									id="repaetinfobox2"
									class="target-group-info-repeat repaetinfobox2"
								>
									<h3>Tasks</h3>
									<p>
										Your tasks should reflect how your participants naturally approach
										your website and be linked to your testing objectives. Tip:
										when you're setting tasks, use a hypothetical scenario – for
										example ‘if you’re hosting a dinner party, where would you
										find glassware?’
									</p>
									{/*<a href="#" className="preview-example-link">
										Preview example
									</a>*/}
									<div
										className="seemorebox-sidebar"
										onClick={() => {
											showmore("repaetinfobox2");
										}}
									>
										See more
									</div>
									<div
										className="seelessbox-sidebar"
										onClick={() => {
											showless("repaetinfobox2");
										}}
									>
										See less
									</div>
								</div>
								
							</div>
						</div>
					</div>
					}
				</div>
			</div>
			<ConfirmModal
				confirm_message="Are you sure you want to delete this parent node? All related children nodes will be deleted as well."
				confirm_btn_title="Delete"
				confirm_title="Delete"
				openModal={confirmModal.open}
				close={() => {
					setConfirmModal({ ...confirmModal, open: false });
				}}
				confirm={() => {

					setConfirmModal({ ...confirmModal, open: false });

					removeAnswer(confirmModal.node.id);
					removeNodeApi(confirmModal.node, confirmModal.path);
				}}
			/>

			<ConfirmModal
				confirm_message="Are you sure you want to delete this parent node? All related children nodes will be deleted as well."
				confirm_btn_title="Delete"
				confirm_title="Delete"
				openModal={confirmDeleteInnerModal.open}
				close={() => {
					setConfirmDeleteInnerModal({ ...confirmDeleteInnerModal, open: false });
				}}
				confirm={() => {
					setConfirmDeleteInnerModal({ ...confirmDeleteInnerModal, open: false });
					confirmDeleteInnerModal.callback();
				}}
			/>
			<ConfirmModal
				confirm_message="Are you sure you want to delete all nodes?"
				confirm_btn_title="Delete"
				confirm_title="Delete"
				openModal={confirmDeleteModal.open}
				close={() => {

					setConfirmDeleteModal({ ...confirmDeleteModal, open: false });
				}}
				confirm={() => {
					var new_tasks = Object.assign([], formValues.tasks);
					for (var i = 0; i < new_tasks.length; i++) {
						new_tasks[i]["correct_answer_id"] = null;
					}
					setFormValues({ ...formValues, tasks: new_tasks });
					setConfirmDeleteModal({ ...confirmDeleteModal, open: false });
					removeAllNodesApi();
				}}
			/>
			<ConfirmModal
				confirm_message="Are you sure you want to import this file? This will remove all the nodes that you've added."
				confirm_btn_title="YES"
				confirm_title="Import CSV"
				openModal={importModal.open}
				close={() => {
					setImportModal({ ...importModal, open: false });
					fileRef.current.value = "";
				}}
				confirm={() => {
					setImportModal({ ...importModal, open: false });
					importCSVApi(importModal.csvFile);
					fileRef.current.value = "";
				}}
			/>
		</LayoutResearcherFullWidth>
	);
}
