import { useNavigate, useSearchParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import React, { useEffect, useState, useRef } from "react";
import LayoutResearcher from "../../layouts/layout_researcher.js";
import { getPackagesService } from "../../../services/packages";
import {
    getBillingInfoService,
    updateBillingInfoService,
} from "../../../services/user";
import {
    addNewPaymentMethodService,
    getPaymentMethodsService,
    deletePaymentMethodService,
    setDefaultCard,
} from "../../../services/payment_methods";
import { getProjects } from "../../../services/project.js";
import toast from "react-hot-toast";
import { countries } from "../../../data/stripe_countries.js";
import Cleave from "cleave.js/react";
import Select from "react-select";
import validator from "validator";
import ConfirmModal from "../../dialog/confirm_modal";
import { buyCreditsService, applyCouponService, makePaymentService } from "../../../services/credits";
import InputField from "../../layouts/elements/fields/input";
import { FormattedMessage } from "react-intl";
import { atom, useResetRecoilState } from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { encryptClient, roundNumber } from "../../../lib/helpers.js";
import { DropdownIndicator } from "../../../lib/helpers";
import NewTestModal from "../tests/new_test_modal.js";
import NewProjectModal from "../projects/new_project_modal.js";
import CreditsConfirmationModal from './credits_confirmation_modal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);



export default function BuyCredits(props) {
    return (<Elements stripe={stripePromise}>
        <PaymentComponent {...props} />
    </Elements>);
};
const PaymentComponent = (props) => {
    ReactSession.setStoreType("localStorage");

    const stripe = useStripe();


    const errorRef = useRef(null);

    const [firstErrorRef, setFirstErrorRef] = useState(null);

    let user = ReactSession.get("user");

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

    const [isLoading, setLoading] = useState(false);

    const [formLoading, setFormLoading] = useState(false);

    const [creditPrice, setCreditPrice] = useState(1);

    const [couponLoading, setCouponLoading] = useState(false);

    const [cardFormLoading, setCardFormLoading] = useState(false);

    const [billingLoading, setBillingLoading] = useState(false);

    const [projects, setProjects] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalMethodology, setModalMethodology] = useState("");
    const [openNewTestModal, setOpenNewTestModal] = useState(false);
    const [openCreditConfirmationModal, setOpenCreditConfirmationModal] = useState(false);
    const [userSettings, setUserSettings] = useState({ "publishing_credits": { "card_sorting": null, "preference_test": null, "tree_test": null, "survey": null } });
    const abortController = new AbortController();

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        target_id: null,
    });

    const [isBillingEditable, setBillingEditable] = useState(false);

    const [packages, setPackages] = useState([]);

    const [paymentMethods, setPaymentMethods] = useState([]);

    const [selected_package, setSelectPackage] = useState(null);

    const [addNewCard, setAddNewCard] = useState(false);

    const [credits, setCredits] = useState(50);

    const [credits_error, setCreditsError] = useState(null);

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const reset = useResetRecoilState(creditsState);

    const [formValues, setFormValues] = useState({
        name: "",
        country: null,
        city: "",
        address: "",
        card_no: "",
        card_name: "",
        exp_date: "",
        cvv: "",
        payment_method_id: "",
        card_type: "",
        credits: "50",
        promo_code: "",
        description: "",
        discount: 0,
        applied: false
    });

    const [billingInfo, setBillingInfo] = useState({
        name: "",
        country: null,
        city: "",
        address: "",
    });

    const [formErrors, setFormErrors] = useState({
        email: null,
        name: null,
        country: null,
        city: null,
        address: null,
        card_no: null,
        card_name: null,
        expiration_date: null,
        cvv: null,
        credits: null,
        promo_code: null,
        error_class: null,
    });

    const closeNewTestModal = (reload) => {
        setOpenNewTestModal(false);
        navigate('/researcher/credits');

        if (reload) {
            getProjectsApi();
        }
    };

    const closeModal = (reload) => {
        setOpenModal(false);
        navigate('/researcher/credits');

        if (reload) {
            getProjectsApi();
        }
    };

    const closeCreditConfirmationModal = (reload) => {
        setOpenCreditConfirmationModal(false);

        // if (reload) {
        //     getProjectsApi();
        // }
    };

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        };
    };
    const clearFormValue = (name) => {
        return ({ target: { value } }) => {
            setTimeout(function () {
                setFormValues((oldValues) => ({ ...oldValues, [name]: "" }));
            }, 1000);
        };
    };
    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const validateCreditCard = (value) => {
        if (!validator.isCreditCard(value)) {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Enter valid Credit Card Number!", "error_class": "card-error" }));
            // showFormError("card_no", "Enter valid Credit Card Number!");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }
        if (formValues.card_type != "visa" && formValues.card_type != "mastercard") {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": "Only visa and mastercard are allowed.", "error_class": "card-error" }));
            // showFormError("card_no", "Only visa and mastercard are allowed.");
        } else {
            setFormErrors((oldValues) => ({ ...oldValues, "card_no": null }));
            // showFormError("card_no", null);
        }
    };

    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);


        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";


        setFormErrors(formErrorsLocal);

    };
    const setDefaultCardValue = (value) => {

        let token = ReactSession.get("token");
        var data = new FormData();
        data.append("stripe_id", value);
        setDefaultCard(data, token).then((response) => {
            if (response.success) {
                getPaymentMethods();
            }
        });
    };
    const addCustomCredits = (plan_id, plan_price) => {
        if (parseInt(credits) < 50) {
            var id = toast(
                <div className="toastinner">
                    <><FormattedMessage id="Please enter a valid amount greater than or equal to 50" /></>
                    <a
                        onClick={() => {
                            toast.dismiss(id);
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                }
            );
        } else {
            setFormValues({ ...formValues, credits: credits });

            removePromoCode();

            setSelectPackage(plan_id);
        }
    }
    const renderPaymentMethods = () => {
        var render = [];

        paymentMethods.forEach(function (method, index) {
            render.push(
                <div
                    key={"methods" + method.id}
                    className="checkboxes radio_btn_class gender_btn d-flex card-radio-wrap"
                >
                    <div className="card-left-side">
                        <label htmlFor={method.stripe_payment_method_id} className="mt-0">
                            <input
                                id={method.stripe_payment_method_id}
                                type="radio"
                                checked={
                                    formValues.payment_method_id == method.id ? true : false
                                }
                                onChange={() => {
                                    setFormValue("payment_method_id", method.id);
                                }}
                                name="payment_method"
                                value={method.stripe_payment_method_id}
                                className="hidden radio_btn_input"
                                required="required"
                            />
                            <span>
                                <img
                                    className="empty-fill-icon"
                                    src={process.env.REACT_APP_URL + "images/Polygon-blank.svg"}
                                />
                                <img
                                    className="fill-icon"
                                    src={process.env.REACT_APP_URL + "images/pol-fill.svg"}
                                />
                            </span>
                            <p>
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "images/" +
                                        method.brand +
                                        "-icon.svg"
                                    }
                                />{" "}
                                {method.brand} ending in *{method.last_4}{" "}
                                <b>{method.card_name}</b>
                            </p>
                        </label>
                        {method.default == 1 && (
                            <span className="primary-card">Primary</span>
                        )}
                    </div>
                    {user.permission != "Editor" && (
                        <div className="card-right-side">
                            {method.default == 0 && (
                                <span
                                    className="make-primary-card"
                                    onClick={() => {
                                        setDefaultCardValue(method.stripe_payment_method_id);
                                    }}
                                >
                                    Make primary
                                </span>
                            )}
                            <span
                                className="delete-account-btn"
                                onClick={() => {
                                    setConfirmModal({
                                        open: true,
                                        target_id: method.id,
                                        confirm_title: "Delete Card",
                                        confirm_btn_title: "Delete",
                                        confirm_message:
                                            "Are you sure you want to delete this card?",
                                    });
                                }}
                            >
                                {" "}
                                <img
                                    src={process.env.REACT_APP_URL + "images/trash.svg"}
                                    alt="trash-icon"
                                />
                            </span>
                        </div>
                    )}
                </div>
            );
        });

        if (render.length > 0) {
            return render;
        } else {
            return <div className="no_payment_add">No payment methods added.</div>;
        }
    };
    const renderPackages = () => {

        const packageData = (isLoading) ? [{ "id": 1 }, { "id": 2 }, { "id": 3 }, { "id": 4 }, { "id": 5 }] : packages;
        return renderPackagesHtmlWithLoader(packageData)


    };
    useEffect(function () {

        scroll();

    }, [firstErrorRef])

    const scroll = () => {
        if (errorRef && errorRef.current) {

            const y = errorRef.current.getBoundingClientRect().top + window.pageYOffset - 100;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const renderPackagesHtmlWithLoader = (packageData) => {
        var render = [];
        packageData.forEach(function (packageItem) {
            if (!selected_package) {
                if (packageItem.is_default == 1) {
                    removePromoCode();
                    setSelectPackage(packageItem);
                }
            }
            render.push(
                <div
                    key={"package" + packageItem.id}
                    className={`buy-credit-repeat-col ${packageItem.is_custom == 1 ? "custom-credit-hold" : ""
                        }  ${isLoading ? 'buy-credits-skeleton' : ''}`}

                    id={`${packageItem.is_custom == 1 ? "r_credits_custom" : "r_credits_"+packageItem.credits}`}

                    onClick={() => {
                        setSelectPackage(packageItem);
                        document.getElementById("billing-info-section").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }}
                >
                    <div
                        className={`buy-credit-repeat ${selected_package && selected_package.id == packageItem.id
                            ? "plan-selected"
                            : ""
                            }  `}
                    >
                        <div className="buy-credit-top">

                            {isLoading ?
                                <>
                                    <h3><Skeleton /></h3>
                                </>
                                :
                                <>
                                    {packageItem.is_custom == 1 && (
                                        <>
                                            <h3>{packageItem.subtitle}</h3>
                                            <p>{packageItem.description}</p>
                                        </>

                                    )}



                                    {packageItem.is_custom != 1 && (
                                        <>
                                            <span className="dis-offier-sec pink-color">
                                                {packageItem.subtitle}
                                            </span>
                                            <h3>
                                                {packageItem.credits} <sub>Credits</sub>
                                            </h3>

                                        </>
                                    )}
                                </>

                            }

                        </div>

                        {isLoading ?
                            <>
                                <div className="buy-credit-mid-sec">
                                    <p>
                                        <Skeleton height={35} />
                                    </p>
                                    <div className="buy-cr-price-wrap">
                                        <h3><Skeleton height={20} /></h3>
                                    </div>

                                    <Skeleton height={20} />
                                </div>
                            </>
                            :
                            <>
                                {packageItem.is_custom == 1 && (
                                    <div className="buy-credit-mid-sec">
                                        <div className="buy-custom-credit-form">
                                            <div className="input form-group input-field">
                                                <InputField
                                                    type={"text"}
                                                    min="50"
                                                    inline_label={"credits"}
                                                    label={"Total credits"}
                                                    id="credit"
                                                    value={formValues.credits}
                                                    onChange={(event) => {

                                                        removePromoCode();

                                                        setFormValues({ ...formValues, credits: event.target.value })
                                                        //setCredits(event.target.value);

                                                        setFormValues({ ...formValues, credits: event.target.value });

                                                        var credits = parseInt(event.target.value);

                                                        if (credits < 500) {
                                                            setCreditPrice(1);
                                                        } else if (credits >= 500 && credits < 1000) {
                                                            setCreditPrice(0.95);
                                                        } else if (credits >= 1000 && credits < 1500) {
                                                            setCreditPrice(0.9);
                                                        } else if (credits >= 1500 && credits < 2000) {
                                                            setCreditPrice(0.85);
                                                        } else if (credits >= 2000) {
                                                            setCreditPrice(0.8);
                                                        }
                                                        setCreditsError(null);

                                                        let regExp = /[\u0600-\u06FF]/g;

                                                        let found = regExp.test(event.target.value);

                                                        if(found){
                                                            setCreditsError("[0-9] allowed.");
                                                        }
                                                        regExp = /^[0-9]*$/;

                                                        found = regExp.test(event.target.value);

                                                        if(!found){
                                                            setCreditsError("[0-9] allowed.");
                                                        }


                                                    }}

                                                    error_class={"input_error"}
                                                    error={credits_error}
                                                    onBlur={(event) => {


                                                        if (parseInt(event.target.value) < 50 || event.target.value == "") {
                                                            setFormValues({ ...formValues, credits: "50" });

                                                        }
                                                        var credits = parseInt(event.target.value);

                                                        if (credits < 500) {
                                                            setCreditPrice(1);
                                                        } else if (credits >= 500 && credits < 1000) {
                                                            setCreditPrice(0.95);
                                                        } else if (credits >= 1000 && credits < 1500) {
                                                            setCreditPrice(0.9);
                                                        } else if (credits >= 1500 && credits < 2000) {
                                                            setCreditPrice(0.85);
                                                        } else if (credits >= 2000) {
                                                            setCreditPrice(0.8);
                                                        }
                                                    }}
                                                    name="credits"
                                                />

                                            </div>
                                        </div>
                                        <button
                                            className="btn secondary-btn"
                                            onClick={() => {
                                                addCustomCredits(packageItem, packageItem.discounted_price);
                                            }}
                                        >
                                            <span className="without-hover-btn">
                                                {/*selected_package && selected_package.id == packageItem.id
                                            ? "Selected"
                                        :*/} {"Buy for $" +
                                                    (formValues.credits
                                                        ? roundNumber(packageItem.discounted_price * parseInt(formValues.credits) * creditPrice, 2)
                                                        : 0)}
                                            </span>
                                            <span className="hover-sate-btn">{"Buy for $" +
                                                (formValues.credits
                                                    ? roundNumber(packageItem.discounted_price * parseInt(formValues.credits) * creditPrice, 2)
                                                    : 0)}</span>
                                        </button>
                                    </div>
                                )}
                                {packageItem.is_custom != 1 && (
                                    <div className="buy-credit-mid-sec">
                                        <p>
                                            <b>{packageItem.description} </b>
                                        </p>
                                        <div className="buy-cr-price-wrap">


                                            {!packageItem.discounted_price && (
                                                <h3>${roundNumber(packageItem.discounted_price, 2)} /credit</h3>
                                            )}
                                            {packageItem.discounted_price && (
                                                <h3 className="dis-price-wrap pink-color">
                                                    ${roundNumber(packageItem.discounted_price, 2)} /credit
                                                    {/* $ {packageItem.discounted_price}{" "}
                    <span className="crdis-price-text">
                      $ {packageItem.price}
                </span>*/}
                                                </h3>
                                            )}
                                        </div>
                                        <button
                                            className="btn secondary-btn"
                                            onClick={() => {
                                                setSelectPackage(packageItem);
                                            }}
                                        >
                                            <span className="without-hover-btn">
                                                {/*selected_package && selected_package.id == packageItem.id
                                            ? "Selected"
                                        : */}{"Buy for $" + roundNumber(packageItem.price, 2)}
                                            </span>
                                            <span className="hover-sate-btn">{"Buy for $" + roundNumber(packageItem.price, 2)}</span>
                                        </button>
                                    </div>
                                )}
                            </>

                        }

                    </div>
                </div>
            );
        });

        return render;

    }
    const getProjectsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProjects(token, abortController.signal)
            .then((response) => {


                if (response.success) {
                    setProjects(response.projects);
                    setUserSettings(response.settings);
                }
                setLoading(false);
            })
            .catch(function (err) {
                console.error(` Err: ${err}`);
            });
    };


    const getPaymentMethods = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPaymentMethodsService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (response.payment_methods.length > 0) {
                    setFormValue("payment_method_id", response.payment_methods[0].id);
                }
                setPaymentMethods(response.payment_methods);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const deletePaymentMethod = (id) => {
        let token = ReactSession.get("token");

        setLoading(true);

        deletePaymentMethodService({ id: id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                getPaymentMethods();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const fetchPackages = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getPackagesService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                setPackages(response.packages);
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    const getBillingInfo = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getBillingInfoService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                //setFormValues()

                for (var key in response.billing_address) {
                    if (response.billing_address[key] == null) {
                        response.billing_address[key] = "";
                    }
                }
                var country = null;

                countries.forEach(function (item) {
                    if (item.value == response.billing_address.country) {
                        country = item;
                    }
                });
                if (response.billing_address.country == "") {
                    setCountryLabelClass("inactive_label");
                } else {
                    setCountryLabelClass("active_label");
                }
                setBillingInfo((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));

                setFormValues((oldValues) => ({
                    ...oldValues,
                    ["address"]: response.billing_address.address,
                    ["city"]: response.billing_address.city,
                    ["country"]: country,
                    ["name"]: response.billing_address.name,
                }));
                if (!response.billing_address.name) {
                    setBillingEditable(true);
                }
                window.setLabels();
            } else {
                var id = toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };
    useEffect(function () {
        document.title = process.env.REACT_APP_NAME + " - Buy Credits";
        fetchPackages();
        getBillingInfo();
        getProjectsApi();

        // rediret to dashboard if role is collaborator editor
        if (user.permission === "Editor") {
            navigate('/dashboard');
        }

        getPaymentMethods();
    }, []);
    useEffect(function () {
        setTimeout(function () {
            window.setLabels();
        }, 100);
    }, [formValues, isLoading]);
    const removePromoCode = () => {
        setFormValues({ ...formValues, discount: 0, promo_code: "" });
    }
    const applyCouponApi = () => {

        if (!couponLoading) {

            resetFormErrors();

            var error = false;

            if (!formValues.promo_code) {
                error = true;
                setFormErrors({ ...formErrors, promo_code: "Required field", error_class: "input_error" });
            }
            if (!error) {
                setCouponLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData.promo_code = formValues.promo_code;

                if (selected_package) {

                    if (selected_package.is_custom != 1) {
                        formData.credits = selected_package.credits;

                        formData.amount = selected_package.price;

                    } else {
                        formData.credits = formValues.credits;

                        formData.amount = formValues.credits;
                    }

                }
                applyCouponService(formData, token).then((response) => {
                    setCouponLoading(false);

                    if (response.success) {

                        setFormValues({
                            ...formValues,
                            discount: response.discount,
                            description: response.description
                        })

                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                            }
                        );

                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }

        }
    };
    const buyCreditsApi = () => {
        resetFormErrors();

        if (!formLoading) {
            var error = false;



            setFirstErrorRef(null);

            if (!selected_package) {
                var id = toast(
                    <div className="toastinner">
                        Please select package
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
                error = true;
            } else if (formValues.payment_method_id == "") {
                var id = toast(
                    <div className="toastinner">
                        Please select payment_method
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
                error = true;
            }
            if (!error) {
                var address_error = false;

                var first_error_ref = null;
                var form_errors = formErrors;

                if (formValues.name == "") {
                    // showFormError("name", "Required field");
                    form_errors = {
                        ...form_errors,
                        name: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "name";
                    }
                }
                if (formValues.city == "") {
                    // showFormError("city", "Required field");
                    form_errors = {
                        ...form_errors,
                        city: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "city";
                    }
                }
                if (!formValues.country) {
                    // showFormError("country", "Required field");
                    form_errors = {
                        ...form_errors,
                        country: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "country";
                    }
                }
                if (formValues.address == "") {
                    // showFormError("address", "Required field");
                    form_errors = {
                        ...form_errors,
                        address: "Required field",
                        error_class: "input_error",
                    };
                    address_error = true;
                    error = true;
                    if (!first_error_ref) {

                        first_error_ref = "address";
                    }
                }
                setFormErrors(form_errors);
            }

            if (address_error) {

                if (first_error_ref) {
                    if (errorRef && errorRef.current) {

                        scroll();
                    }
                    setFirstErrorRef(first_error_ref);
                }
                var id = toast(
                    <div className="toastinner">
                        Please reverify billing information. Some of the required fields are missing.
                        <a
                            onClick={() => {
                                toast.dismiss(id);
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }

            if (!error) {
                setFormLoading(true);

                const token = ReactSession.get("token");

                var formData = formValues;

                formData["package_id"] = selected_package.id;

                if (formValues.country) {
                    formData["country"] = formValues.country.value;
                }
                buyCreditsService(formData, token).then((response) => {
                    setFormLoading(false);

                    if (response.success) {

                        if (response.client_secret) {
                            setFormValues({ ...formValues, order_id: response.order_id });
                            setFormValues({ ...formValues, invoice_id: response.invoice_id });
                            stripe.confirmCardPayment(response.client_secret).then(handleStripeJsResult)

                        } else {
                            reset();
                            // var id = toast(
                            //     <div className="toastinner">
                            //         Credits purchased successfully.
                            //         <a
                            //             onClick={() => {
                            //                 toast.dismiss(id);
                            //             }}
                            //         >
                            //             &times;
                            //         </a>
                            //     </div>,
                            //     {
                            //         className: "successtoast",
                            //         position: "bottom-center",
                            //     }
                            // );

                            if (searchParams.get("return")) {
                                navigate(-1);
                            } else {
                                setOpenCreditConfirmationModal(true);
                                // navigate('/researcher/credits');
                            }
                        }
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    function handleStripeJsResult(result) {
        console.log(result)
        if (result.error) {
            // Show error in payment form
        } else {
            setFormLoading(true);

            var formData = formValues;

            formData["package_id"] = selected_package.id;

            const token = ReactSession.get("token");

            formData["payment_intent_id"] = result.paymentIntent.id;

            makePaymentService(formData, token).then((response) => {
                setFormLoading(false);

                if (response.success) {



                    var id = toast(
                        <div className="toastinner">
                            Credits purchased successfully.
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "successtoast",
                            position: "bottom-center",
                        }
                    );
                    navigate("/researcher/credits");

                } else {
                    var id = toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss(id);
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });

        }
    }

    const validatePaymentMethodForm = () => {
        var error = false;

        var form_errors = formErrors;

        var firsterrorid = null;

        if (formValues.card_no == "") {
            form_errors = {
                ...form_errors,
                card_no: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        } else if (!validator.isCreditCard(formValues.card_no)) {
            showFormError("card_no", "Enter valid Credit Card Number!");
            form_errors = {
                ...form_errors,
                card_no: "Enter valid Credit Card Number!",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "card-number" : firsterrorid;
        }
        if (formValues.card_name == "") {
            form_errors = {
                ...form_errors,
                card_name: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cardholder-name" : firsterrorid;
        }
        if (formValues.exp_date == "") {
            form_errors = {
                ...form_errors,
                exp_date: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.exp_date.length == 5) {

            if (new Date().getTime() > new Date("01/" + formValues.exp_date).getTime()
            ) {
                form_errors = {
                    ...form_errors,
                    exp_date: "Expiry cannot be past date",
                    error_class: "input_error",
                };
                error = true;
                firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
            }

        } else if (formValues.exp_date.length > 0) {
            form_errors = {
                ...form_errors,
                exp_date: "Invalid CVV/CVC",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "expiration-date" : firsterrorid;
        }
        if (formValues.cvv == "") {
            form_errors = {
                ...form_errors,
                cvv: "Required field",
                error_class: "input_error",
            };
            error = true;
            firsterrorid = firsterrorid == null ? "cvv" : firsterrorid;
        }


        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };

    const addNewPaymentMethod = () => {
        resetFormErrors();

        if (!cardFormLoading) {
            var error = false;

            // resetFormErrors();

            error = validatePaymentMethodForm();

            if (!error) {
                setCardFormLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["card_no"] = encryptClient(formValues.card_no);

                formData["exp_date"] = formValues.exp_date;

                formData["cvv"] = encryptClient(formValues.cvv);

                formData["card_name"] = formValues.card_name;

                addNewPaymentMethodService(formData, token).then((response) => {
                    setCardFormLoading(false);

                    if (response.success) {
                        setFormValue("card_name", "");
                        setFormValue("card_no", "");
                        setFormValue("cvv", "");
                        setFormValue("exp_date", "");

                        getPaymentMethods();

                        setAddNewCard(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };
    const updateBillingInfo = () => {
        resetFormErrors();

        if (!billingLoading) {
            var error = false;

            resetFormErrors();

            if (formValues.name == "") {
                showFormError("name", "Required field");
                error = true;
            } else if (formValues.address == "") {
                showFormError("address", "Required field");
                error = true;
            } else if (formValues.city == "") {
                showFormError("city", "Required field");
                error = true;
            } else if (!formValues.country) {
                showFormError("country", "Required field");
                error = true;
            }

            if (!error) {
                setBillingLoading(true);

                const token = ReactSession.get("token");

                var formData = {};

                formData["name"] = formValues.name;

                formData["address"] = formValues.address;

                formData["country"] = formValues.country.value;

                formData["city"] = formValues.city;

                updateBillingInfoService(formData, token).then((response) => {
                    setBillingLoading(false);

                    if (response.success) {
                        for (var key in response.billing_address) {
                            if (response.billing_address[key] == null) {
                                response.billing_address[key] = "";
                            }
                        }
                        var country = null;

                        countries.forEach(function (item) {
                            if (item.value == response.billing_address.country) {
                                country = item;
                            }
                        });

                        setBillingInfo((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setFormValues((oldValues) => ({
                            ...oldValues,
                            ["address"]: response.billing_address.address,
                            ["city"]: response.billing_address.city,
                            ["country"]: country,
                            ["name"]: response.billing_address.name,
                        }));

                        setBillingEditable(false);

                        window.setLabels();
                    } else {
                        var id = toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss(id);
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                            }
                        );
                    }
                });
            }
        }
    };

    return (
        <LayoutResearcher
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            skipCheck={true}
            extendedFooter={false}
        >
            <div className="buy-cedit-page-wrap">
                <div className="profile-info-section d-block pb-64 pt-64">
                    <div className="profile-info-left w-100">
                        <div className="profile-info-text  mt-0">
                            <h1 className="page-main-heading">
                                Buy credits
                                {/*{isLoading && (*/}
                                {/*    <i*/}
                                {/*        className="fa fa-spinner fa-spin"*/}
                                {/*        aria-hidden="true"*/}
                                {/*        style={{ marginLeft: "5px" }}*/}
                                {/*    ></i>*/}
                                {/*)}*/}
                            </h1>
                            <p>
                                You can use credits to recruit participants from the UserQ panel
                                for your tests at any time. Plus, they don't expire – so
                                take advantage of bulk-buying discounts now.{" "}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="profile-form-wrap">
                    <div className="profile-form-repeat">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="buy-credit-option-wrap">
                                    {renderPackages()}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isLoading && (
                        <>

                            <div className="profile-form-repeat">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-info-text">
                                            <h3 className="d-flex align-items-center bill-h-dflex" id="billing-info-section">
                                                Billing information
                                                {user.permission != "Editor" && (
                                                    <>
                                                        {!isBillingEditable && (
                                                            <span
                                                                className="edit-icon"
                                                                onClick={() => {
                                                                    setBillingEditable(true);
                                                                    setTimeout(function () {
                                                                        window.setLabels();
                                                                    }, 100);
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/edit-icon.svg"
                                                                    }
                                                                />
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        {isBillingEditable && (
                                            <div className="row">
                                                <div className="col-md-6" ref={firstErrorRef == "name" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input form-group input-field">
                                                            <input

                                                                type="text"
                                                                readOnly={!isBillingEditable}
                                                                className={`form-control ${formErrors.name != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                                value={formValues.name}
                                                                onChange={set("name")}
                                                                id="name"
                                                            />
                                                            <label htmlFor="name" className="control-label">
                                                                Name/Company name
                                                            </label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("name")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.name != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.name}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="profile-form-group ">
                                                        <div className="input input-field form-group read-only-field">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={user.email}
                                                                readOnly
                                                                id="email"
                                                            />
                                                            <label htmlFor="email">Email</label>
                                                            <div className="data-field-icon">
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/fill-check.svg"
                                                                    }
                                                                    alt="icon"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" ref={firstErrorRef == "country" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input-field mb-0">
                                                            <div
                                                                className={`${formErrors.country != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                            >
                                                                <label
                                                                    className={countryLabelClass}
                                                                    htmlFor="country"
                                                                >
                                                                    Country
                                                                </label>
                                                                <Select
                                                                    isClearable
                                                                    id="country"
                                                                    isDisabled={!isBillingEditable}
                                                                    value={
                                                                        formValues.country
                                                                            ? formValues.country
                                                                            : null
                                                                    }
                                                                    placeholder="Country"
                                                                    options={countries}
                                                                    onChange={(country) => {
                                                                        if (country == null) {
                                                                            setCountryLabelClass("inactive_label");
                                                                        } else {
                                                                            setCountryLabelClass("active_label");
                                                                            setFormErrors({ ...formErrors, country: null })
                                                                        }
                                                                        setFormValue("country", country);
                                                                    }}
                                                                    components={{ DropdownIndicator }}
                                                                />
                                                            </div>
                                                            {formErrors.country != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.country}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" ref={firstErrorRef == "city" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input form-group input-field">
                                                            <input
                                                                type="text"
                                                                readOnly={!isBillingEditable}
                                                                className={`form-control ${formErrors.city != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                                value={formValues.city}
                                                                onChange={set("city")}
                                                                id="city"
                                                            />
                                                            <label htmlFor="city" className="control-label">
                                                                City
                                                            </label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("city")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.city != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.city}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6" ref={firstErrorRef == "address" ? errorRef : null}>
                                                    <div className="profile-form-group">
                                                        <div className="input form-group input-field ">
                                                            <input
                                                                type="text"
                                                                readOnly={!isBillingEditable}
                                                                id="address "
                                                                className={`form-control ${formErrors.address != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                                    } `}
                                                                value={formValues.address}
                                                                onChange={set("address")}
                                                            />
                                                            <label
                                                                htmlFor="address "
                                                                className="control-label"
                                                            >
                                                                Address{" "}
                                                            </label>
                                                            <span className="clear">
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    onClick={clearFormValue("address")}
                                                                >
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.address != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.address}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {billingInfo.address &&
                                                    <div className="col-md-12">
                                                        <div className="flex-buttons-wrap justify-content-end">
                                                            <button
                                                                type="button"
                                                                className="btn form-btn secondary-btn"
                                                                onClick={() => {
                                                                    setFormValue("name", billingInfo.name);
                                                                    setFormValue("country", billingInfo.country);
                                                                    setFormValue("address", billingInfo.address);
                                                                    setFormValue("city", billingInfo.city);
                                                                    resetFormErrors();
                                                                    setBillingEditable(false);
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn form-btn"
                                                                onClick={updateBillingInfo}
                                                            >
                                                                Save
                                                                {billingLoading && (
                                                                    <i
                                                                        className="fa fa-spinner fa-spin"
                                                                        aria-hidden="true"
                                                                        style={{ marginLeft: "5px" }}
                                                                    ></i>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        {!isBillingEditable && (
                                            <div className="bill-inactive-wrap">
                                                <div className="row">
                                                    <div className="col-md-6" ref={firstErrorRef == "name" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field" >
                                                                <label>Name/Company name</label>
                                                                <p>{billingInfo.name ? billingInfo.name : '-'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field inactive-text-wrp">
                                                                <label>Your email</label>
                                                                <p>{user.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" ref={firstErrorRef == "country" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Country</label>
                                                                <p>
                                                                    {billingInfo.country
                                                                        ? billingInfo.country.label
                                                                        : "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" ref={firstErrorRef == "city" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>City</label>
                                                                <p>{billingInfo.city ? billingInfo.city : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" ref={firstErrorRef == "address" ? errorRef : null}>
                                                        <div className="profile-form-group">
                                                            <div className="input-field mb-0">
                                                                <label>Address </label>
                                                                <p>{billingInfo.address ? billingInfo.address : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="profile-form-repeat">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-info-text">
                                            <h3>Your payment methods</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {renderPaymentMethods()}
                                                {user.permission != "Editor" && (
                                                    <>
                                                        {!addNewCard && (
                                                            <div
                                                                className="add-new-card-option mb-0"
                                                                onClick={() => {
                                                                    setAddNewCard(true);
                                                                }}
                                                            >
                                                                <div className="create-btn add-new-card-btn">
                                                                    Add new card{" "}
                                                                    <span>
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_URL +
                                                                                "images/plus-white.svg"
                                                                            }
                                                                        />
                                                                    </span>{" "}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {addNewCard == true && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="add-card-field-hold">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <div className="position-relative w-100">
                                                                        <Cleave
                                                                            id="card-number"
                                                                            options={{
                                                                                creditCard: true,
                                                                                onCreditCardTypeChanged: (type) => {

                                                                                    setFormValues({ ...formValues, card_type: type })
                                                                                }
                                                                            }}
                                                                            onChange={set("card_no")}
                                                                            className={`form-control ${formErrors.card_no != null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                                } `}
                                                                            value={formValues.card_no}
                                                                            onKeyUp={(e) => {
                                                                                validateCreditCard(e.target.value);
                                                                            }}


                                                                        />


                                                                        <label htmlFor="card-number">
                                                                            Card number
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue("card_no")}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path className="line" d="M2 2L22 22" />
                                                                                <path className="long" d="M9 15L20 4" />
                                                                                <path className="arrow" d="M13 11V7" />
                                                                                <path className="arrow" d="M17 11H13" />
                                                                            </svg>
                                                                        </span>
                                                                        </div>
                                                                        {formValues.card_type && formValues.card_type != 'unknown' &&
                                                                            <span className="selected_card_type">
                                                                                <img src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/" + formValues.card_type + "-icon.svg"
                                                                                } />
                                                                            </span>
                                                                        }
                                                                        {formErrors.card_no != null && (
                                                                            <span className={formErrors.error_class}>
                                                                                {formErrors.card_no}
                                                                            </span>
                                                                        )}
                                                                        <div className="card-secure-text">
                                                                            <img src={
                                                                                    process.env.REACT_APP_URL +
                                                                                    "images/" + "lock-icon.svg"
                                                                                } />
                                                                                <span>This is a secure 128-bit SSL encrypted payment</span>
                                                                                </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <input
                                                                            type="text"
                                                                            id="cardholder-name"
                                                                            className={`form-control ${formErrors.card_name != null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                                } `}
                                                                            value={formValues.card_name}
                                                                            onChange={set("card_name")}
                                                                        />
                                                                        <label htmlFor="cardholder-name">
                                                                            Cardholder’s name
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue("card_name")}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path className="line" d="M2 2L22 22" />
                                                                                <path className="long" d="M9 15L20 4" />
                                                                                <path className="arrow" d="M13 11V7" />
                                                                                <path className="arrow" d="M17 11H13" />
                                                                            </svg>
                                                                        </span>
                                                                        {formErrors.card_name != null && (
                                                                            <span className={formErrors.error_class}>
                                                                                {formErrors.card_name}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <Cleave
                                                                            id="expiration-date"
                                                                            options={{
                                                                                date: true,
                                                                                datePattern: ["m", "y"],
                                                                            }}
                                                                            onChange={set("exp_date")}
                                                                            className={`form-control ${formErrors.exp_date != null
                                                                                ? formErrors.error_class
                                                                                : ""
                                                                                } `}
                                                                            value={formValues.exp_date}
                                                                        />
                                                                        <label htmlFor="expiration-date">
                                                                            Expiration date mm/yy
                                                                        </label>
                                                                        <span
                                                                            className="clear"
                                                                            onClick={clearFormValue("exp_date")}
                                                                        >
                                                                            <svg viewBox="0 0 24 24">
                                                                                <path className="line" d="M2 2L22 22" />
                                                                                <path className="long" d="M9 15L20 4" />
                                                                                <path className="arrow" d="M13 11V7" />
                                                                                <path className="arrow" d="M17 11H13" />
                                                                            </svg>
                                                                        </span>
                                                                        {formErrors.exp_date != null && (
                                                                            <span className={formErrors.error_class}>
                                                                                {formErrors.exp_date}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="profile-form-group">
                                                                    <div className="input form-group input-field">
                                                                        <div className="position-relative w-100">
                                                                            {formValues.card_type == "amex" &&
                                                                                <Cleave
                                                                                    id="cvv"
                                                                                    options={{
                                                                                        blocks: [4],
                                                                                        numericOnly: true,
                                                                                    }}
                                                                                    onChange={set("cvv")}
                                                                                    className={`form-control ${formErrors.cvv != null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                        } `}
                                                                                    value={formValues.cvv}
                                                                                />
                                                                            }
                                                                            {formValues.card_type != "amex" &&
                                                                                <Cleave
                                                                                    id="cvv"
                                                                                    options={{
                                                                                        blocks: [3],
                                                                                        numericOnly: true,
                                                                                    }}
                                                                                    onChange={set("cvv")}
                                                                                    className={`form-control ${formErrors.cvv != null
                                                                                        ? formErrors.error_class
                                                                                        : ""
                                                                                        } `}
                                                                                    value={formValues.cvv}
                                                                                />
                                                                            }
                                                                            <label
                                                                                className="control-label"
                                                                                htmlFor="cvv"
                                                                            >
                                                                                CVV/CVC
                                                                            </label>
                                                                            <span
                                                                                className="clear"
                                                                                onClick={clearFormValue("cvv")}
                                                                            >
                                                                                <svg viewBox="0 0 24 24">
                                                                                    <path
                                                                                        className="line"
                                                                                        d="M2 2L22 22"
                                                                                    />
                                                                                    <path
                                                                                        className="long"
                                                                                        d="M9 15L20 4"
                                                                                    />
                                                                                    <path
                                                                                        className="arrow"
                                                                                        d="M13 11V7"
                                                                                    />
                                                                                    <path
                                                                                        className="arrow"
                                                                                        d="M17 11H13"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                            {formErrors.cvv != null && (
                                                                                <span
                                                                                    className={formErrors.error_class}
                                                                                >
                                                                                    {formErrors.cvv}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div className="d-flex justify-content-end cvv-holder">
                                                                            <span tooltip="CVV is the last three digits on the back of your credit card.">
                                                                                What is cvv/cvc?{" "}
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_URL +
                                                                                        "images/info-icon.svg"
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"row"}>
                                                            <div className="col-md-12">
                                                                <div className="flex-buttons-wrap justify-content-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn form-btn secondary-btn"
                                                                        onClick={() => {
                                                                            setFormValue("card_name", "");
                                                                            setFormValue("card_no", "");
                                                                            setFormValue("cvv", "");
                                                                            setFormValue("exp_date", "");
                                                                            resetFormErrors();
                                                                            setAddNewCard(false);
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn form-btn"
                                                                        onClick={addNewPaymentMethod}
                                                                    >
                                                                        Save
                                                                        {cardFormLoading && (
                                                                            <i
                                                                                className="fa fa-spinner fa-spin"
                                                                                aria-hidden="true"
                                                                                style={{ marginLeft: "5px" }}
                                                                            ></i>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-form-repeat mb-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-info-text">
                                            <h3>Promo Code</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="promocode-field-wrap">
                                            {formValues.discount == 0 &&
                                                <>
                                                    <InputField
                                                        label="Promo Code"
                                                        onChange={set("promo_code")}
                                                        onClear={() => {
                                                            setTimeout(() => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    promo_code: ""
                                                                })
                                                            }, 1000);
                                                        }}
                                                        value={formValues.promo_code}
                                                        // maxLength="50"
                                                        id="promo_code"
                                                        error={formErrors.promo_code}
                                                        error_class={formErrors.error_class}
                                                    />

                                                    <div className="apply-promo-code-btn d-flex justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="button primary-btn m-0"
                                                            onClick={() => {
                                                                applyCouponApi();
                                                            }}
                                                            id="r_promo_code"
                                                        >
                                                            Apply
                                                            {couponLoading && (
                                                                <i
                                                                    className="fa fa-spinner fa-spin"
                                                                    aria-hidden="true"
                                                                    style={{ marginLeft: "5px" }}
                                                                ></i>
                                                            )}
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                            {formValues.discount > 0 &&
                                                <div id="r_promo_code_applied" className="applied-promo-code-wrap">
                                                    <>
                                                        <label>Promo code</label>

                                                        <p className="promocode-text" id="r_promo_code_applied"><i class="fa fa-check-circle" aria-hidden="true"></i>
                                                            Promo code applied : <span>{formValues.promo_code}</span></p>

                                                        <span className="remove-promocode-icon">
                                                            <img
                                                                src={process.env.REACT_APP_URL + "images/trash.svg"}
                                                                alt="trash-icon" onClick={() => {
                                                                    removePromoCode();
                                                                }}
                                                            />
                                                        </span>
                                                        <br />
                                                        <div className={"promocode_description"}><p>{formValues.description}</p></div>


                                                    </>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(selected_package && ((selected_package.is_custom == 1 && parseInt(formValues.credits) > 0) || selected_package.is_custom != 1)) &&
                                <>
                                    <div className="profile-form-repeat border-0 mb-0">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-info-text">
                                                    <h3>Summary</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                {selected_package && (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="plan-summery-test">

                                                                {selected_package.is_custom == 0 &&
                                                                    <div className="sumery-text-repeat mt-0">
                                                                        <span>{selected_package.credits} Credits</span>
                                                                        <div className="total-credit-prce-wrap">
                                                                            <h4>
                                                                                ${selected_package.price}
                                                                                <span className="crdis-price-text">
                                                                                    ${selected_package.credits}
                                                                                </span>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(formValues.discount > 0 || formValues.credits >= 500) && selected_package.is_custom == 1 &&
                                                                    <div className="sumery-text-repeat mt-0">
                                                                        <span>{formValues.credits} Credits</span>

                                                                        <div className="total-credit-prce-wrap">
                                                                            <h4>
                                                                                ${formValues.credits * creditPrice}
                                                                                {formValues.credits >= 500 &&
                                                                                    <span className="crdis-price-text">
                                                                                        ${formValues.credits}
                                                                                    </span>
                                                                                }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {formValues.discount > 0 &&
                                                                    <div className="sumery-text-repeat applied-promo-discount">
                                                                        <span>Discount</span>

                                                                        <div className="total-credit-prce-wrap">
                                                                            <h4>
                                                                                - ${roundNumber(formValues.discount, 2)}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className={`sumery-text-repeat ${selected_package.is_custom == 1 ? 'mt-0' : ''} `}>
                                                                    <div className="total-biling-head">
                                                                        Total (USD)
                                                                    </div>
                                                                    <div className="billing-total-ammount">
                                                                        <h3>

                                                                            {selected_package.is_custom == 1 && (
                                                                                <>
                                                                                    ${roundNumber(selected_package.discounted_price *
                                                                                        parseInt(formValues.credits) * creditPrice - formValues.discount, 2)}
                                                                                </>
                                                                            )}
                                                                            {selected_package.is_custom == 0 && (
                                                                                <>${roundNumber(selected_package.price - formValues.discount, 2)}</>
                                                                            )}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {!selected_package && (
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="plan-summery-test">
                                                                Please select package
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-form-btn res-profle-btn-right justify-content-end plan-subscribe-btn">
                                        <button
                                            type="button"
                                            className="btn form-btn"
                                            onClick={() => {
                                                buyCreditsApi();
                                            }}
                                            id="r_buy_credits_pay_now"
                                        >
                                            Pay Now
                                            {formLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{ marginLeft: "5px" }}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                </>
                            }
                        </>
                    )}
                </div>
            </div>

            <ConfirmModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title="Delete"
                confirm_title="Delete Card"
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deletePaymentMethod(confirmModal.target_id);
                    if (formValues.payment_method_id == confirmModal.target_id) {
                        setFormValue("payment_method_id", "");
                    }
                }}
            />

            {openNewTestModal && (
                <NewTestModal
                    openModal={openNewTestModal}
                    closeModal={closeNewTestModal}
                    projectlist={projects}
                    openProjectModal={() => {
                        setOpenModal(true);
                    }}
                    selectedMethodology={modalMethodology}
                    publishing_credits={userSettings.publishing_credits}
                />
            )}

            <NewProjectModal
                openModal={openModal}
                closeModal={closeModal}
            />
            {openCreditConfirmationModal && (
                <CreditsConfirmationModal
                    openModal={openCreditConfirmationModal}
                    closeModal={() => {
                        closeCreditConfirmationModal();
                        navigate('/researcher/credits');
                    }}
                    projectlist={projects}
                    openProjectModal={() => {
                        closeCreditConfirmationModal();
                        setOpenModal(true);
                    }}
                    selectedMethodology={modalMethodology}
                    publishing_credits={userSettings.publishing_credits}
                    amount={selected_package.credits?selected_package.credits:formValues.credits}
                    createTest={(methodology) => {
                        setModalMethodology(methodology);
                        closeCreditConfirmationModal();
                        setOpenNewTestModal(true);
                    }}
                />
            )}
        </LayoutResearcher>
    );
}
