import { useNavigate, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "reactjs-dropdown-component";
import Layout from "../layouts/layout";
import { getProfile, updateStep3 } from "../../services/user.js";
import { countries } from "../../data/countries.js";
import { emirates } from "../../data/emirates.js";
import { saregions } from "../../data/saregions.js";
import { languages } from "../../data/languages_others.js";
import CheckboxNormal from "../layouts/elements/fields/checkbox_normal";
import { normalize, removeItemFromArray } from "../../lib/helpers";
import SelectElement from "../layouts/elements/fields/select_element";
import toast from "react-hot-toast";

export default function Step3() {
  ReactSession.setStoreType("localStorage");

  const navigate = useNavigate();

  let languageSelect = useRef();

  let countrySelect = useRef();

  let emirateSelect = useRef();

  let regionSelect = useRef();

  const [formValues, setFormValues] = useState({
    spoken_language: ["english"],
    language: [],
    country: "",
    region:""
  });

  const [formErrors, setFormErrors] = useState({
    spoken_language: null,
    language: null,
    country: null,
    error_class: null,
  });

  const [formLoading, setFormLoading] = useState(false);

  const [errorMsg, setErrorMessage] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const [langLabelClass, setLangLabelClass] = useState("inactive_label");

  const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");

  const [emirateLabelClass, setEmirateLabelClass] = useState("inactive_label");

  const [regionLabelClass, setRegionLabelClass] = useState("inactive_label");
  
  useEffect(()=>{

      if (
          formValues.region != null &&
          formValues.region != ""
      ) {
          if(formValues.country=="Saudi Arabia"){
              if(regionSelect.current){
                  regionSelect.current.selectSingleItem({
                      value: formValues.region,
                  });
                  
                  setRegionLabelClass("active_label");
              }
          }
          if(formValues.country=="United Arab Emirates"){
              
              if(emirateSelect.current){
                  
                  emirateSelect.current.selectSingleItem({
                      value: formValues.region,
                  });
                  
                  setEmirateLabelClass("active_label");
              }
          }

      }

  },[formValues]);

  useEffect(() => {
    document.title =
      process.env.REACT_APP_NAME + " - Complete Profile | Step 3";

    const user = ReactSession.get("user");

    const token = ReactSession.get("token");

    

    setLoading(true);

    getProfile(token).then((response) => {
      setLoading(false);

      if (response.success) {
        if (
          response.data.user.language != null &&
          response.data.user.language != ""
        ) {
          //languageSelect.current.selectSingleItem({ value: response.data.user.language });

          setLangLabelClass("active_label");
        }
        if (
          response.data.user.country != null &&
          response.data.user.country != ""
        ) {
          countrySelect.current.selectSingleItem({
            value: response.data.user.country,
          });

          setCountryLabelClass("active_label");
        }
        if (
          response.data.user.region != null &&
          response.data.user.region != ""
        ) {
            if(response.data.user.country=="Saudi Arabia"){
                if(emirateSelect.current){
                    emirateSelect.current.selectSingleItem({
                        value: response.data.user.region,
                    });
                    
                    setEmirateLabelClass("active_label");
                }
            }
            if(response.data.user.country=="United Arab Emirates"){
                if(regionSelect.current){
                    regionSelect.current.selectSingleItem({
                        value: response.data.user.region,
                    });
                    
                    setRegionLabelClass("active_label");
                }
            }

        }
        if (response.data.user.language == null) {
          countrySelect.current.selectSingleItem({
            value: response.data.user.country,
          });

          setCountryLabelClass("active_label");
        }
        var languages = [];

        response.data.user.language.split(",").forEach(function (item) {
          languages.push({
            label: item,
            value: item,
          });
        });
        setFormValues({
          spoken_language: response.data.user.spoken_language.split(","),
          country: response.data.user.country,
          language: languages,
          region: response.data.user.region
        });

        if (response.data.user.completed_step == 5) {
          navigate('/dashboard');
        }
        if (response.data.user.completed_step < 2) {
          if (response.data.user.completed_step == 0) {
            navigate("/profile/step1");
          } else {
            navigate("/profile/step" + response.data.user.completed_step);
          }
        }
      }
    });

    //}
  }, []);

  const set = (name) => {
    return ({ target: { value } }) => {
      setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const setFormValue = (name, value) => {
    setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
  };
  const resetFormErrors = () => {
    let formErrorsLocal = Object.assign({}, formErrors);

    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }
    setFormErrors(formErrorsLocal);

    return formErrorsLocal;
  };
  const validateFullForm = () => {
    var error = false;

    var form_errors = resetFormErrors();

    if (formValues.spoken_language.length == 0) {
      form_errors = {
        ...form_errors,
        spoken_language: "Required field.",
        error_class: "input_error",
      };
      error = true;
    }
    // if (formValues.language.length == 0) {
    //   form_errors = {
    //     ...form_errors,
    //     language: "Required field.",
    //     error_class: "input_error",
    //   };
    //   error = true;
    // }
    if (formValues.country == "" || formValues.country == null) {
      form_errors = {
        ...form_errors,
        country: "Required field.",
        error_class: "input_error",
      };
      error = true;
    }
    if (formValues.country == "United Arab Emirates" && (formValues.region == "" || formValues.region == null) ) {
      form_errors = {
          ...form_errors,
          region: "Required field",
          error_class: "input_error",
      };
      error = true;
      
  }
  if (formValues.country == "Saudi Arabia" && (formValues.region == "" || formValues.region == null) ) {
      form_errors = {
          ...form_errors,
          region: "Required field",
          error_class: "input_error",
      };
      error = true;
     
  }
    setFormErrors(form_errors);

    return error;
  };
  const onSubmit = async (event) => {
    event.preventDefault();

    if (!formLoading) {
      var error = validateFullForm();

      if (!error) {
        setFormLoading(true);

        const token = ReactSession.get("token");

        var formData = { ...formValues };

        formData["language"] = normalize(formValues["language"]).join(",");

        updateStep3(formData, token).then((response) => {
          setFormLoading(false);

          if (response.success) {
            let user = ReactSession.get("user");

            user.completed_step = response.user.completed_step;

            ReactSession.set("user", user);

            navigate("/profile/step4");
          } else {
            toast(
              <div className="toastinner">
                {response.message}
                <a
                  onClick={() => {
                    toast.dismiss();
                  }}
                >
                  &times;
                </a>
              </div>,
              {
                className: "errortoast",
                position: "bottom-center",
              }
            );
            //setErrorMessage(response.message);
          }
        });
      }
    }
  };

  const countryChange = (item) => {
      if (item) {
        setFormValue("country", item.value);
        setCountryLabelClass("active_label");
    } else {
        setFormValue("country", null);
        setCountryLabelClass("inactive_label");

    }
    
    setFormValue("region", null);
    setEmirateLabelClass("inactive_label");
    setRegionLabelClass("inactive_label");
  };
  const emirateChange = (item) => {
    setFormErrors((oldValues) => ({
      ...oldValues,
      region: null,
  }));

      if (item) {
          setFormValue("region", item.value);
          setEmirateLabelClass("active_label");
      } else {
          setFormValue("region", null);
          setEmirateLabelClass("inactive_label");
      }
  };
  const regionChange = (item) => {
    setFormErrors((oldValues) => ({
        ...oldValues,
        region: null,
    }));
      
      if (item) {
          setFormValue("region", item.value);
          setRegionLabelClass("active_label");
      } else {
          setFormValue("region", null);
          setRegionLabelClass("inactive_label");
      }
  };
  const languageChange = (items) => {
    if (items.length > 0) {
      setLangLabelClass("active_label");
    } else {
      setLangLabelClass("inactive_label");
    }
    setFormValue("language", items);
    setFormErrors((oldValues) => ({
      ...oldValues,
      language: null,
    }));
  };
  const clearErrorMessage = () => {
    setErrorMessage(null);
  };
  return (
    <Layout isLoading={false} skipCheck={true} profileCompleted={false}>
      <div className="profile-info-section complete-profile-info-wrap">
        <div className="profile-info-left">
          <div className="backto-list-wrap">
            <Link to="/profile/step2">
              <img
                src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
                alt="img"
              />{" "}
              Back
            </Link>
          </div>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="complete-profile-outer-hold">
          <div className="complete-profile-steps-wrapper align-items-start">
            <div className="profile-inner-flex-wrap">
              <div className="profile-steps-left-wrap">
                <div
                  className={
                    isLoading
                      ? "steps-count-wrap loader-show"
                      : "steps-count-wrap"
                  }
                >
                  <span className="step-complete">
                    {isLoading && (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    )}
                    <img
                      className="complete-step-icon"
                      src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                      alt="icon"
                    />
                    <svg
                      className="empty-step-icon"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path-1"
                        d="M16 1.1547L28.8564 8.57735V23.4226L16 30.8453L3.14359 23.4226L3.14359 8.57735L16 1.1547Z"
                        stroke="#171637"
                        strokeWidth="2"
                      />
                      <path
                        className="path-2"
                        d="M17.767 10.3636V22H15.6591V12.4148H15.5909L12.8693 14.1534V12.2216L15.7614 10.3636H17.767Z"
                        fill="#171637"
                      />
                    </svg>
                  </span>
                  <span className="step-complete">
                    <img
                      className="complete-step-icon"
                      src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                      alt="icon"
                    />
                    <svg
                      className="empty-step-icon"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path-1"
                        d="M16 1.1547L28.8564 8.57735V23.4226L16 30.8453L3.14359 23.4226L3.14359 8.57735L16 1.1547Z"
                        stroke="#595972"
                        strokeWidth="2"
                      />
                      <path
                        className="path-2"
                        d="M12.2955 22V21.2216L16.0966 16.9489C16.5928 16.3883 16.9924 15.9091 17.2955 15.5114C17.5985 15.1136 17.8182 14.7462 17.9545 14.4091C18.0947 14.072 18.1648 13.7216 18.1648 13.358C18.1648 12.9148 18.0606 12.5284 17.8523 12.1989C17.6477 11.8655 17.3655 11.6061 17.0057 11.4205C16.6458 11.2348 16.2405 11.142 15.7898 11.142C15.3125 11.142 14.8939 11.2443 14.5341 11.4489C14.178 11.6534 13.9015 11.9337 13.7045 12.2898C13.5076 12.6458 13.4091 13.0549 13.4091 13.517H12.392C12.392 12.8731 12.5398 12.303 12.8352 11.8068C13.1345 11.3068 13.5417 10.9148 14.0568 10.6307C14.5758 10.3466 15.1629 10.2045 15.8182 10.2045C16.4659 10.2045 17.0417 10.3447 17.5455 10.625C18.0492 10.9015 18.4451 11.2784 18.733 11.7557C19.0208 12.233 19.1648 12.767 19.1648 13.358C19.1648 13.786 19.089 14.2008 18.9375 14.6023C18.7898 15.0038 18.5341 15.4508 18.1705 15.9432C17.8068 16.4356 17.303 17.0379 16.6591 17.75L13.7727 20.9716V21.0455H19.5284V22H12.2955Z"
                        fill="#595972"
                      />
                    </svg>
                  </span>
                  <span className="step-inprogress">
                    <img
                      className="complete-step-icon"
                      src={process.env.REACT_APP_URL + "images/step-fill.svg"}
                      alt="icon"
                    />
                    <svg
                      className="empty-step-icon"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path-1"
                        d="M16 1.1547L28.8564 8.57735V23.4226L16 30.8453L3.14359 23.4226L3.14359 8.57735L16 1.1547Z"
                        stroke="#595972"
                        strokeWidth="2"
                      />
                      <path
                        className="path-2"
                        d="M16.0739 22.1591C15.358 22.1591 14.7178 22.0303 14.1534 21.7727C13.589 21.5114 13.142 21.1496 12.8125 20.6875C12.483 20.2254 12.3087 19.6932 12.2898 19.0909H13.3636C13.3826 19.5152 13.5133 19.8864 13.7557 20.2045C13.9981 20.5189 14.3201 20.7652 14.7216 20.9432C15.1231 21.1174 15.5701 21.2045 16.0625 21.2045C16.6004 21.2045 17.0739 21.1042 17.483 20.9034C17.8958 20.7027 18.2178 20.428 18.4489 20.0795C18.6837 19.7273 18.8011 19.3295 18.8011 18.8864C18.8011 18.4167 18.6818 18.0019 18.4432 17.642C18.2045 17.2822 17.8617 17.0019 17.4148 16.8011C16.9716 16.6004 16.4413 16.5 15.8239 16.5H15.142V15.5455H15.8239C16.3277 15.5455 16.7727 15.4545 17.1591 15.2727C17.5492 15.0871 17.8561 14.8277 18.0795 14.4943C18.303 14.161 18.4148 13.7689 18.4148 13.3182C18.4148 12.8902 18.3182 12.5133 18.125 12.1875C17.9318 11.8617 17.6591 11.6061 17.3068 11.4205C16.9583 11.2348 16.5511 11.142 16.0852 11.142C15.6345 11.142 15.2216 11.2292 14.8466 11.4034C14.4716 11.5777 14.1686 11.8239 13.9375 12.142C13.7102 12.4602 13.589 12.8371 13.5739 13.2727H12.5455C12.5606 12.6667 12.7273 12.1345 13.0455 11.6761C13.3674 11.214 13.7936 10.8542 14.3239 10.5966C14.858 10.3352 15.4489 10.2045 16.0966 10.2045C16.7822 10.2045 17.375 10.3447 17.875 10.625C18.375 10.9053 18.7614 11.2784 19.0341 11.7443C19.3106 12.2064 19.4489 12.7159 19.4489 13.2727C19.4489 13.9356 19.2689 14.5038 18.9091 14.9773C18.5492 15.447 18.0644 15.7727 17.4545 15.9545V16.0284C18.197 16.1761 18.7803 16.5057 19.2045 17.017C19.6326 17.5246 19.8466 18.1477 19.8466 18.8864C19.8466 19.5076 19.6837 20.0663 19.358 20.5625C19.0322 21.0549 18.5852 21.4451 18.017 21.733C17.4527 22.017 16.8049 22.1591 16.0739 22.1591Z"
                        fill="#595972"
                      />
                    </svg>
                  </span>
                  <span>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1.1547L28.8564 8.57735V23.4226L16 30.8453L3.14359 23.4226L3.14359 8.57735L16 1.1547Z"
                        stroke="#595972"
                        strokeWidth="2"
                      />
                      <path
                        d="M12.017 19.5V18.6364L17.2045 10.3636H17.8409V11.8125H17.3693L13.2045 18.4716V18.5455H20.0057V19.5H12.017ZM17.4489 22V19.2273V18.8295V10.3636H18.4602V22H17.4489Z"
                        fill="#595972"
                      />
                    </svg>
                  </span>
                  <span>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 1.1547L28.8564 8.57735V23.4226L16 30.8453L3.14359 23.4226L3.14359 8.57735L16 1.1547Z"
                        stroke="#595972"
                        strokeWidth="2"
                      />
                      <path
                        d="M15.6989 22.1591C15.0511 22.1591 14.4716 22.0284 13.9602 21.767C13.4527 21.5019 13.0473 21.1383 12.7443 20.6761C12.4451 20.214 12.286 19.6856 12.267 19.0909H13.2955C13.3295 19.7083 13.572 20.2159 14.0227 20.6136C14.4735 21.0076 15.0322 21.2045 15.6989 21.2045C16.2178 21.2045 16.6799 21.0814 17.0852 20.8352C17.4905 20.5852 17.8068 20.2443 18.0341 19.8125C18.2652 19.3769 18.3807 18.8864 18.3807 18.3409C18.3769 17.7727 18.2538 17.2727 18.0114 16.8409C17.7689 16.4053 17.4394 16.0644 17.0227 15.8182C16.6098 15.572 16.1458 15.4489 15.6307 15.4489C15.2405 15.4451 14.858 15.5095 14.483 15.642C14.108 15.7746 13.7955 15.9451 13.5455 16.1534L12.483 16.017L13.1818 10.3636H18.9318V11.3182H14.0739L13.608 15.2102H13.6648C13.9223 14.9943 14.2348 14.8182 14.6023 14.6818C14.9735 14.5455 15.3655 14.4773 15.7784 14.4773C16.464 14.4773 17.0795 14.642 17.625 14.9716C18.1742 15.3011 18.608 15.7557 18.9261 16.3352C19.2481 16.911 19.4091 17.5739 19.4091 18.3239C19.4091 19.0587 19.2481 19.7159 18.9261 20.2955C18.608 20.8712 18.1686 21.3258 17.608 21.6591C17.0511 21.9924 16.4148 22.1591 15.6989 22.1591Z"
                        fill="#595972"
                      />
                    </svg>
                  </span>
                </div>
                <p className="step-count-info">Step 3 of 5</p>
                <div className="steps-info-text">
                  <h1 className="page-main-heading">Tell us about yourself</h1>
                </div>
              </div>
              <div className="complete-profile-right-form-section">
                {errorMsg && (
                  <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                    <div className="pro-lft-wrap">{errorMsg}</div>
                    <div className="pro-right-wrap">
                      <div className="delete-progress-row">
                        <img
                          onClick={clearErrorMessage}
                          src={process.env.REACT_APP_URL + "images/cross.svg"}
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="profile-row-border">
                  <div className="row align-items-center profile-form-wrap">
                    <div className="col-md-6">
                      <div className="profile-form-group">
                        <div className="input-field static-label mb-0">
                          <label forHtml="english">Spoken Language</label>
                          <div className="spoken-lan-check-wrap">
                            <div
                              className={
                                formErrors.spoken_language != null
                                  ? formErrors.error_class
                                  : ""
                              }
                            >
                              <div
                                className="checkboxes radio_btn_class gender_btn  d-flex"
                                style={{ marginTop: "20px" }}
                              >
                                <CheckboxNormal
                                  id="english"
                                  value={"english"}
                                  name={"spoken_language"}
                                  label={"English"}
                                  checked={
                                    formValues.spoken_language.includes(
                                      "english"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    if (event.currentTarget.checked) {
                                      formValues.spoken_language.push(
                                        "english"
                                      );
                                      setFormValue(
                                        "spoken_language",
                                        formValues.spoken_language
                                      );
                                      setFormErrors((oldValues) => ({
                                        ...oldValues,
                                        spoken_language: null,
                                      }));
                                    } else {
                                      formValues.spoken_language =
                                        removeItemFromArray(
                                          formValues.spoken_language,
                                          "english"
                                        );
                                      setFormValue(
                                        "spoken_language",
                                        formValues.spoken_language
                                      );
                                    }
                                  }}
                                />
                                <CheckboxNormal
                                  style={{ marginLeft: "10px" }}
                                  id="arabic"
                                  name={"spoken_language"}
                                  label={"Arabic"}
                                  checked={
                                    formValues.spoken_language.includes(
                                      "arabic"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    if (event.currentTarget.checked) {
                                      formValues.spoken_language.push("arabic");
                                      setFormValue(
                                        "spoken_language",
                                        formValues.spoken_language
                                      );
                                      setFormErrors((oldValues) => ({
                                        ...oldValues,
                                        spoken_language: null,
                                      }));
                                    } else {
                                      formValues.spoken_language =
                                        removeItemFromArray(
                                          formValues.spoken_language,
                                          "arabic"
                                        );
                                      setFormValue(
                                        "spoken_language",
                                        formValues.spoken_language
                                      );
                                    }
                                  }}
                                />
                                {/*<label htmlFor="english">
                                                                    <input id="english" checked={formValues.spoken_language=='english' ? true : false } onChange={()=>setFormValue('spoken_language','english')} type="radio" name="spoken_language" value="english" class="hidden radio_btn_input"   />
                                                                    <span>
                                                                        <img className="empty-fill-icon" src={process.env.REACT_APP_URL+"images/Polygon-blank.svg"}/>
                                                                        <img className="fill-icon" src={process.env.REACT_APP_URL+"images/pol-fill.svg"} />
                                                                    </span>
                                                                    <p>English</p>
                                                                </label>
                                                                <label htmlFor="arabic">
                                                                    <input id="arabic" checked={formValues.spoken_language=='arabic' ? true : false } onChange={()=>setFormValue('spoken_language','arabic')}  type="radio" name="spoken_language" value="arabic" class="hidden radio_btn_input" />
                                                                    <span>
                                                                        <img className="empty-fill-icon" src={process.env.REACT_APP_URL+"images/Polygon-blank.svg"} />
                                                                        <img className="fill-icon" src={process.env.REACT_APP_URL+"images/pol-fill.svg"} />
                                                                    </span>
                                                                    <p>Arabic</p>
                                </label>*/}
                              </div>
                            </div>
                          </div>
                          {formErrors.spoken_language != null && (
                            <span className={formErrors.error_class}>
                              {formErrors.spoken_language}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="profile-form-group select-lang-dropdown">
                        <SelectElement
                          isSearchable={true}
                          isMulti={true}
                          labelClass={langLabelClass}
                          value={formValues.language}
                          id="language"
                          label={"Other languages"}
                          placeholder={"Other languages"}
                          options={languages}
                          error={formErrors.language}
                          error_class={formErrors.error_class}
                          onChange={languageChange}
                        />
                        {/*<div className="input-field static-label mb-0">
                                                    
                                                    <label className={langLabelClass} htmlFor="language">Other languages</label> 
                                                    <div className={formErrors.language!=null ? formErrors.error_class:''}>
                                                        <Dropdown
                                                            name="language"
                                                            title="Select language"
                                                            searchable={["Search for language", "No matching language"]}
                                                            list={languages}
                                                            onChange={languageChange}
                                                            ref={languageSelect}
                                                        />
                                                        {(formErrors.language!=null) &&
                                                            <span className={formErrors.error_class}>{formErrors.language}</span>
                                                        }
                                                    </div>
                                                </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-form-group">
                      <div className="input-field mb-0">
                        <label className={countryLabelClass} htmlFor="country">
                          What's your country of residency?
                        </label>
                        <div
                          className={
                            formErrors.country != null
                              ? formErrors.error_class
                              : ""
                          }
                        >
                          <Dropdown
                            name="country"
                            title="What's your country of residency"
                            searchable={[
                              "Search for country",
                              "No matching country",
                            ]}
                            list={countries}
                            onChange={countryChange}
                            ref={countrySelect}
                            styles={{
                              headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                              headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                            }}
                          />
                          {formErrors.country != null && (
                            <span className={formErrors.error_class}>
                              {formErrors.country}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {formValues.country=="United Arab Emirates" &&
                                                
                  <div className="col-md-6">
                      <div className="profile-form-group position-relative" id="region">
                          <label
                              className={emirateLabelClass}
                              htmlFor="region"
                          >
                              What's your emirate of residency?
                          </label>
                          <div
                              className={
                                  formErrors.region != null
                                      ? formErrors.error_class
                                      : ""
                              }
                          >
                              <Dropdown
                                  name="emirates"
                                  title="What's your emirate of residency?"
                                  searchable={[
                                      "Search for emirate",
                                      "No matching emirate",
                                  ]}
                                  list={emirates}
                                  onChange={emirateChange}
                                  ref={emirateSelect}
                                  styles={{
                                      headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                      headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                  }}
                              />
                              {formErrors.region != null && (
                                  <span className={formErrors.error_class}>
                                      {formErrors.region}
                                  </span>
                              )}
                          </div>
                      </div>
                  </div>
                  }
                  {formValues.country=="Saudi Arabia" &&
                  
                  <div className="col-md-6">
                      <div className="profile-form-group position-relative" id="region">
                          <label
                              className={regionLabelClass}
                              htmlFor="region"
                          >
                              What's your region of residency?
                          </label>
                          <div
                              className={
                                  formErrors.region != null
                                      ? formErrors.error_class
                                      : ""
                              }
                          >
                              <Dropdown
                                  name="province"
                                  title="What's your region of residency?"
                                  searchable={[
                                      "Search for region",
                                      "No matching region",
                                  ]}
                                  list={saregions}
                                  onChange={regionChange}
                                  ref={regionSelect}
                                  styles={{
                                      headerArrowUpIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px'},
                                      headerArrowDownIcon: {  backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px',width: '24px',height: '24px' }
                                  }}
                              />
                              {formErrors.region != null && (
                                  <span className={formErrors.error_class}>
                                      {formErrors.region}
                                  </span>
                              )}
                          </div>
                      </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="inner-form-btn profile-complete-btn-wrap">
            <button type="submit" className="btn form-btn">
              Next
              {formLoading && (
                <i
                  className="fa fa-spinner fa-spin"
                  aria-hidden="true"
                  style={{ marginLeft: "5px" }}
                ></i>
              )}
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}
