import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import TextAreaField from '../layouts/elements/fields/textarea';
import Radiobtn from "../layouts/elements/fields/radiobtn";
import toast from "react-hot-toast";
import CheckboxNormal from "../layouts/elements/fields/checkbox_normal";
import { removeItemFromArray } from "../../lib/helpers";
import { saveTestResultQuestion } from "../../services/test_result";
import InputField from './../layouts/elements/fields/input';


export default function Question({ test, result, questionCallback, questionType, timeSpent }) {

    const location = useLocation();
    const [isActiveQuestion, setActiveQuestion] = useState(null);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [QuestionArrayList, setQuestionArrayList] = useState([]);
    const [formValues, setFormValues] = useState({ type: null, question_id: null, answer: '', option_id: [] });
    const [formErrors, setFormErrors] = useState({ error: '', error_class: '' });
    const [questionLoading, setQuestionLoading] = useState(false);
    const [questionArray, setQuestionArray] = useState([]);
    const [questionTimeSpend, setQuestionTimeSpend] = useState(0);
    const [likertActive, setLikertActive] = useState(false);
    const [options, setOptions] = useState([]);
    const isTyping = useRef(false);
    const isActiveQuestionId = useRef(null);
    const isSkipped = useRef(0);
    const isOptional = useRef(false);
    const skippedCount = useRef(0);

    useEffect(() => {

        let Questions = [];

        if (questionType === 'briefing') {
            if (test && test.briefing_question.length) {
                setActiveQuestion(1);
                setTotalQuestion(test && test.briefing_question.length);

                test && test.briefing_question.forEach((items) => {
                    Questions.push({ 'data': items, 'questionData': JSON.parse(items.questions) });
                });

                setQuestionArrayList(Questions);
                isOptional.current = Questions[0]['questionData']['is_optional'];

                //Questions.
                const questionTypeData = Questions.filter((item, indexItem) => indexItem + 1 === 1)[0];
                if (questionTypeData.data.question_type === 'singlechoice') {
                    setFormValues({ ...formValues, type: 'single', is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === 'multiplechoice') {
                    setFormValues({ ...formValues, type: 'multiple', is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "likertscale") {
                    setFormValues({ ...formValues, type: "likertscale", is_optional: questionTypeData.questionData.is_optional });
                } else {
                    setFormValues({ ...formValues, type: 'free', is_optional: questionTypeData.questionData.is_optional });
                }


            } else {
                saveQuestionData([], true);
                //questionCallback(questionArray, questionType);
            }
        } else {
            if (test && test.debriefing_question.length) {
                setActiveQuestion(1);
                setTotalQuestion(test && test.debriefing_question.length);

                test && test.debriefing_question.forEach((items) => {
                    Questions.push({ 'data': items, 'questionData': JSON.parse(items.questions) });
                });

                setQuestionArrayList(Questions);
                isOptional.current = Questions[0]['questionData']['is_optional'];

                //Questions.
                const questionTypeData = Questions.filter((item, indexItem) => indexItem + 1 === 1)[0];
                if (questionTypeData.data.question_type === 'singlechoice') {
                    setFormValues({ ...formValues, type: 'single', is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === 'multiplechoice') {
                    setFormValues({ ...formValues, type: 'multiple', is_optional: questionTypeData.questionData.is_optional });
                } else if (questionTypeData.data.question_type === "likertscale") {
                    setFormValues({ ...formValues, type: "likertscale", is_optional: questionTypeData.questionData.is_optional });
                } else {
                    setFormValues({ ...formValues, type: 'free', is_optional: questionTypeData.questionData.is_optional });
                }

            } else {
                saveQuestionData([], true);
                //questionCallback(questionArray, questionType);
            }
        }

        function tickTime() {
            setQuestionTimeSpend(prevSeconds => prevSeconds + 1)
        }
        window.scrollTo(0, 0);

        let timeData = setInterval(() => tickTime(), 1000)

        return () => clearInterval(timeData);

        // location scroll top

    }, [])

    const handleSubmitQuestion = (e) => {
        e.preventDefault();
        setFormErrors({ error: '', error_class: '' });
        if (isActiveQuestionId.current == null) {
            isActiveQuestionId.current = QuestionArrayList[0]['data']['id']
        }

        if (isSkipped.current == 0) {
            if (formValues.is_optional == 1 && ((formValues.answer === "" && formValues.type !== "multiple" && formValues.type !== "rankingscale" && formValues.type !== "likertscale") || (formValues.type === "multiple" || formValues.type === "rankingscale") && formValues.option_id.length === 0) || (formValues.type === "likertscale" && formValues.option_id.length === 0)) {
                if (skippedCount.current < 2) {

                    isSkipped.current = 1;
                    setFormValues({
                        ...formValues,
                        question_id: isActiveQuestionId.current,
                    });
                    skippedCount.current = skippedCount.current + 1;
                } else {
                    toast((
                        <div className='toastinner'>
                            <>You can skip maximum of 2 questions.</>
                            <a onClick={() => { toast.dismiss(); }}>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });
                    return false;
                }
            }
        }

        if (isSkipped.current == 0) {

            if (formValues.answer === '' && formValues.type !== 'multiple' && formValues.type !== 'likertscale') {
                setFormErrors({ error: 'Required field', error_class: 'input_error' });

                if (formValues.type === 'single') {
                    toast((
                        <div className='toastinner'>
                            You need to select one answer before moving to the next question
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center',
                        timeout: 500
                    });
                }
                return false;
            }

            if (formValues.type === 'multiple' && formValues.option_id.length === 0) {
                setFormErrors({ error: 'Required field', error_class: 'input_error' });
                toast((
                    <div className='toastinner'>
                        You need to select one answer before moving to the next question
                        <a onClick={() => { toast.dismiss(); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center',
                    timeout: 500
                });
                return false;
            }
            if (formValues.type === "likertscale" && formValues.option_id.length === 0) {
                setFormErrors({ error: "Required field", error_class: "input_error" });
                toast(
                    <div className="toastinner">
                        You need to answer before moving to the next question
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        timeout: 500,
                    }
                );
                return false;
            }
            else {
                if (formValues.option_id.length < formValues.totalSubQuestions) {
                    setFormErrors({ error: "Required field", error_class: "input_error" });
                    toast(
                        <div className="toastinner">
                            You need to answer for each subquestion.
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                            timeout: 500,
                        }
                    );
                    return false;
                }
            }
        }

        // location scroll top
        window.scrollTo(0, 0);
        // check & activate next question
        formValues['question_time_spent'] = questionTimeSpend;
        formValues["is_skipped"] = isSkipped.current;
        if (isActiveQuestionId.current) {
            formValues["question_id"] = isActiveQuestionId.current;
        }
        setQuestionTimeSpend(0);
        setQuestionArray([...questionArray, formValues]) // set data in array & make form value blank
        if (isActiveQuestion < totalQuestion) {
            const questionTypeData = QuestionArrayList.filter((item, indexItem) => indexItem + 1 === isActiveQuestion + 1)[0];
            if (questionTypeData.data.question_type === 'singlechoice') {
                var types = 'single';
            } else if (questionTypeData.data.question_type === 'multiplechoice') {
                var types = 'multiple';
            } else if (questionTypeData.data.question_type === "likertscale") {
                var types = "likertscale";
            } else {
                var types = 'free';
            }
            setFormValues({ type: types, question_id: null, answer: '', option_id: [], is_optional: questionTypeData.questionData.is_optional });
            setActiveQuestion(isActiveQuestion + 1);
            isSkipped.current = 0;
            isActiveQuestionId.current = QuestionArrayList[isActiveQuestion]['data']['id'];
            isOptional.current = QuestionArrayList[isActiveQuestion]['questionData']['is_optional'];
        } else {
            saveQuestionData([...questionArray, formValues], false);

        }

    }

    const saveQuestionData = (formData, shouldNavigate) => {

        if (location.pathname.substr(1, 1) === 't') {


            let data = Object.assign({}, { "questions": formData });
            data["test_id"] = result && result.test_id;
            data["result_id"] = result && result.id;
            data["question_type"] = questionType;
            data["guest_token"] = ReactSession.get("guest_token");
            data["time_spent"] = timeSpent;

            // cannot hit api multiple time if already ruuning api request
            if (questionLoading) {
                return false;
            }

            if (!shouldNavigate) {
                setQuestionLoading(true);
            }

            if (shouldNavigate) {
                questionCallback(formData, questionType);
            }

            saveTestResultQuestion(data, ReactSession.get("token")).then((response) => {

                if (!shouldNavigate) {
                    setQuestionLoading(false);
                }
                if (response.success) {

                    questionCallback(formData, questionType);
                } else {

                    toast((
                        <div className='toastinner'>
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });
                }
            });
        } else {
            questionCallback(formData, questionType);
        }

    }

    const movetonextlikertsubquestion = (subquestionindex) => {
        setLikertActive(true);
        var nextquestionid = "subquestion-" + parseInt(subquestionindex + 1, 10);
        const item = document.getElementById(nextquestionid);
        item.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }

    const runFunction = (e) => {

        if (
            e.target.type == 'text' || e.target.type == 'textarea'
        ) {
            // e.preventDefault();
            return;
        }
        else {
            // e.preventDefault();
            isTyping.current = false;
            return;
        }
    }


    return (
        <div className="tester-page-wrapper test-question-instruction-wrap tester-question-selection-wrap" onClick={(e) => runFunction(e)}>
            <div className="container inner-page-container">
                <div className="tester-screens-hold">
                    <div className="tester-form-steps-wrapper">
                        <div className="tester-form-steps-inner">

                            <form onSubmit={(e) => handleSubmitQuestion(e)}>
                                <div className="tacking-test-page-height">
                                    {QuestionArrayList && QuestionArrayList.map((items, index) => {
                                        return (
                                            <div key={index}>
                                                {(isActiveQuestion === index + 1) &&
                                                    <div key={index}>
                                                        <h2 className="h2 flex-heading">{items.questionData.question}
                                                            {items.questionData.is_optional == true &&
                                                                <span className="optional-span">
                                                                    {test.language == "ar" ? "اختياري" : "optional"}
                                                                </span>
                                                            }
                                                        </h2>
                                                        {(items.questionData.questionType === 'free') &&
                                                            <div className="test-form-hold" onClick={() => {
                                                                isTyping.current = true;
                                                            }} >
                                                                <TextAreaField
                                                                    autosize={true}
                                                                    label={test.language == "en" ? "Type your answer here...." : "جاوب هنا"}
                                                                    value={formValues.answer == null ? '' : formValues.answer}
                                                                    error={formErrors.error}
                                                                    error_class={formErrors.error_class}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSubmitQuestion(e);
                                                                        }
                                                                    }}
                                                                    maxLength="300"
                                                                    onChange={(e) => setFormValues({ ...formValues, question_id: items.data.id, answer: e.target.value })}
                                                                    onClear={() => {
                                                                        setFormValues({ ...formValues, "answer": '' });

                                                                    }}
                                                                />

                                                            </div>
                                                        }

                                                        {(items.questionData.questionType === 'singlechoice') &&
                                                            <div className="test-form-hold mt-64">
                                                                <h3 className="que-select-head">{test.language == "en" ? "Select 1 option" : "حدد خيار واحد"}</h3>
                                                                <div className={`${test.language == "en" ? "test-question-radiocheck-wrap" : "test-question-radiocheck-wrap radiocheck_arabic-wrap"}`}>
                                                                    <div
                                                                        className="checkboxes radio_btn_class gender_btn  d-flex  full-width-check-radio">
                                                                        {(items.data.options && items.data.options.map((optionData) => {

                                                                            return (
                                                                                <div key={optionData.id}>
                                                                                    <Radiobtn
                                                                                        id={`option-select-${optionData.id}`}
                                                                                        isSelectedlabelBold={true}
                                                                                        name="option-select-"
                                                                                        value={optionData.id}
                                                                                        checked={(formValues.answer === optionData.id) ? true : false}
                                                                                        onChange={(e) => {
                                                                                            if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                setFormValues({ ...formValues, question_id: items.data.id, answer: parseInt(e.target.value), other_option_value: "", })
                                                                                            } else {
                                                                                                setFormValues({ ...formValues, question_id: items.data.id, answer: parseInt(e.target.value) })
                                                                                            }
                                                                                        }}
                                                                                        label={optionData.option_value} />

                                                                                    {formValues.answer === optionData.id && ((optionData.option_value == "Other" && test.language == "en") || (optionData.option_value == "آخر" && test.language == "ar")) &&

                                                                                        <div className="other-input-field">
                                                                                            <InputField
                                                                                                label={test.language == "en"?"Specify other":"تحديد أخرى"}
                                                                                                onChange={(e) => {
                                                                                                    setFormValues({
                                                                                                        ...formValues,
                                                                                                        other_option_value: e.target.value,
                                                                                                    });
                                                                                                }}
                                                                                                // onClear={() => {
                                                                                                //     setTimeout(() => {
                                                                                                //     setFormValues((oldValues) => ({
                                                                                                //         ...oldValues,
                                                                                                //         other_option_value: "",
                                                                                                //     }));
                                                                                                //     }, 1000);
                                                                                                // }}
                                                                                                maxLength="45"
                                                                                                language={test.language}
                                                                                                value={formValues.other_option_value}
                                                                                                id="othervalue"
                                                                                                rtl={test.language == "en" ? false : true}
                                                                                                readOnly={false}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                            );
                                                                        }))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {(items.questionData.questionType === 'multiplechoice') &&
                                                            <div className="test-form-hold mt-64">
                                                                <h3 className="que-select-head">
                                                                    {test.language == "en" ? "You can select multiple options" : "يمكنك تحديد خيارات متعددة"}
                                                                </h3>
                                                                <div className="test-question-radiocheck-wrap">
                                                                    <div
                                                                        className="checkboxes radio_btn_class gender_btn  d-flex   full-width-check-radio">

                                                                        {(items.data.options && items.data.options.map((optionData) => {

                                                                            return (
                                                                                <div key={optionData.id}>
                                                                                    <CheckboxNormal
                                                                                        value={optionData.id}
                                                                                        isSelectedlabelBold={true}
                                                                                        onChange={(e) => {
                                                                                            if (e.currentTarget.checked) {
                                                                                                formValues.option_id.push(parseInt(e.target.value));
                                                                                                if ((test.language == "en" && optionData.option_value == "Other") || (optionData.option_value == "آخر" && test.language == "ar")) {
                                                                                                    setFormValues({
                                                                                                        ...formValues,
                                                                                                        question_id: items.data.id,
                                                                                                        option_id: formValues.option_id,
                                                                                                        other_option_value: "",
                                                                                                    });
                                                                                                } else {
                                                                                                    setFormValues({
                                                                                                        ...formValues,
                                                                                                        question_id: items.data.id,
                                                                                                        option_id: formValues.option_id
                                                                                                    });
                                                                                                }
                                                                                            } else {
                                                                                                formValues.option_id =
                                                                                                    removeItemFromArray(
                                                                                                        formValues.option_id,
                                                                                                        optionData.id
                                                                                                    );
                                                                                                setFormValues(
                                                                                                    {
                                                                                                        ...formValues,
                                                                                                        question_id: items.data.id,
                                                                                                        option_id: formValues.option_id
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }}

                                                                                        id={`custom-checks-${optionData.id}`}
                                                                                        checked={formValues.option_id.includes(optionData.id) ? true : false}
                                                                                        darkCheckbox={"dark-checkbox"}
                                                                                        label={optionData.option_value}
                                                                                    />
                                                                                    {formValues.option_id.includes(optionData.id) && ((optionData.option_value == "Other" && test.language == "en") || (optionData.option_value == "آخر" && test.language == "ar")) &&
                                                                                        <div className="other-input-field">
                                                                                            <InputField
                                                                                                label={test.language == "en"?"Specify other":"تحديد أخرى"}
                                                                                                onChange={(e) => {
                                                                                                    setFormValues({
                                                                                                        ...formValues,
                                                                                                        other_option_value: e.target.value,
                                                                                                    });
                                                                                                }}
                                                                                                // onClear={() => {
                                                                                                //     setTimeout(() => {
                                                                                                //     setFormValues((oldValues) => ({
                                                                                                //         ...oldValues,
                                                                                                //         other_option_value: "",
                                                                                                //     }));
                                                                                                //     }, 1000);
                                                                                                // }}
                                                                                                maxLength="45"
                                                                                                language={test.language}
                                                                                                value={formValues.other_option_value}
                                                                                                id="othervalue"
                                                                                                rtl={test.language == "en" ? false : true}
                                                                                                readOnly={false}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        }))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="likert-taking-test-view">
                                                            {items.questionData.questionType == "likertscale" && (
                                                                <div className="survey-rating-options-wrap none-for-mobile">
                                                                    <div className="bd-question-radio-data">
                                                                        <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                            <div className="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                <div className="ans-list-repeat saved-ans-repeat">
                                                                                    <div className="likert-option-left">
                                                                                        <div className="ans-icon">&nbsp;</div>
                                                                                        <div className="ans-data">&nbsp;</div>
                                                                                    </div>
                                                                                    <div className="likert-option-right">
                                                                                        {items.data.options.map((option, index) => (
                                                                                            <label for="radio4" key={index}>
                                                                                                <p>{option.option_value}</p>
                                                                                            </label>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div className={`likert-innerview ${likertActive == true ? "likert-active-view" : ""}`}>
                                                                {items.questionData.questionType == "likertscale" && items.data.testsubquestions.map(
                                                                    (subQuestion, subquestionindex) => (
                                                                        <div className="ans-list-wrap likert-scale-option-add" key={subquestionindex} id={`subquestion-${subquestionindex}`}>
                                                                            <div className="ans-list-repeat saved-ans-repeat">
                                                                                <div className="likert-option-left">
                                                                                    <div className="ans-data">{subQuestion.subquestion}</div>
                                                                                </div>
                                                                                <div className="likert-option-right">
                                                                                    <div className="survey-rating-options-wrap">
                                                                                        <div className="bd-question-radio-data">
                                                                                            <div className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                {items.data.options.map((option, index) => (
                                                                                                    <>

                                                                                                        <div className="likert-view-option-repeat" key={subquestionindex + '-' + index}>
                                                                                                            <a
                                                                                                                href={`#subquestion-${subquestionindex + 1}`}
                                                                                                                onClick={() => {
                                                                                                                    movetonextlikertsubquestion(subquestionindex);
                                                                                                                }
                                                                                                                }
                                                                                                            >
                                                                                                                <Radiobtn
                                                                                                                    id={`option-select-${subquestionindex + '-' + index}`}
                                                                                                                    isSelectedlabelBold={true}
                                                                                                                    name={`option-select-${subquestionindex}`}
                                                                                                                    value={option.id}
                                                                                                                    checked={
                                                                                                                        formValues.option_id.forEach((subquestion) => {
                                                                                                                            if ((subquestion.subquestionid === subQuestion.id) && (subquestion.optionid === option.id)) {
                                                                                                                                return true;
                                                                                                                            }
                                                                                                                        })

                                                                                                                    }
                                                                                                                    onChange={(e) => {

                                                                                                                        options.forEach((subquestion, index) => {
                                                                                                                            if (subquestion.subquestionid === subQuestion.id) {
                                                                                                                                options.splice(index, 1);
                                                                                                                            }
                                                                                                                        })
                                                                                                                        options.push({ subquestionid: subQuestion.id, optionid: option.id });
                                                                                                                        setFormValues({
                                                                                                                            ...formValues,
                                                                                                                            question_id: items.data.id,
                                                                                                                            option_id: options,
                                                                                                                            totalSubQuestions: items.data.testsubquestions.length,
                                                                                                                        });
                                                                                                                    }}
                                                                                                                    label={option.option_value}
                                                                                                                />
                                                                                                            </a>
                                                                                                            {/* <div>{option.option_value}</div> */}
                                                                                                        </div>

                                                                                                    </>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                }

                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={`button-wrap d-flex justify-content-end mt-64 ${isTyping.current == true ? 'keyboard-active-button' : ''} ${isOptional.current == true ? 'optional-button' : ''}`}>
                                    {isOptional.current == true &&
                                        <button
                                            type="button"
                                            className="button secondary-btn"
                                            onClick={(e) => {
                                                if (skippedCount.current < 2) {

                                                    isSkipped.current = 1;
                                                    setFormValues({
                                                        ...formValues,
                                                        question_id: isActiveQuestionId.current,
                                                    });
                                                    handleSubmitQuestion(e);
                                                    skippedCount.current = skippedCount.current + 1;
                                                }
                                                else {
                                                    toast((
                                                        <div className='toastinner'>
                                                            <>You can skip maximum of 2 questions.</>
                                                            <a onClick={() => { toast.dismiss(); }}>&times;</a>
                                                        </div>), {
                                                        className: 'errortoast',
                                                        position: 'bottom-center'
                                                    });
                                                }
                                            }}
                                        >
                                            {test.language == "ar" ? "تخطي" : "Skip"}
                                        </button>}
                                    <button type="submit" className="button primary-btn">
                                        {test.language == "en" ? "Next" : "التالي"}
                                        {questionLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                aria-hidden="true"
                                                style={{ marginLeft: "5px" }}
                                            ></i>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
