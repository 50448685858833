import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import { getTestData, sendFeedback } from "../../../services/test";
import toast from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "react-datepicker/dist/react-datepicker.css";
import TextAreaField from "../../layouts/elements/fields/textarea";
import { FormattedMessage } from 'react-intl';
import { encryptId } from '../../../lib/helpers';
export default function LiveTest() {

    let { test_id } = useParams();

    const navigate = useNavigate();

    const [test, setTest] = useState({});

    const [linkCopied, setLinkCopied] = useState(false);
    const [passwordCopied, setPasswordCopied] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [feedbackLoading, setFeedbackLoading] = useState(false);


    const [isActiveRating, setActiveRating] = useState(true);
    const [isActiveRatingStep2, setActiveRatingStep2] = useState(false);
    const [isActiveOpinion, setActiveOpinion] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const [formValues, setFormValues] = useState({ rating: 3, opinion: "", issues:[] })

    const [formErrors, setFormErrors] = useState({ opinion: null, error_class: "" });

    const [feedbackIssues, setFeedbackIssues] = useState([]);




    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - live";

        getTestApi();

    }, []);


    const getTestApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {

                if (response.test.status) {
                    if (response.test.status !== "published") {
                        navigate("/researcher/project/" + response.test.project_id);
                    }
                }
                setTest(response.test);
                if(response.test.is_nps==1){
                    setTimeout(function () {
                                setActiveRating(false);
                            }, 1000)
                }


                if (response.test.feedback) {
                    setActiveRating(true);
                    setSuccessMessage(<FormattedMessage id="Thank you, your feedback has been recorded." />);
                    setActiveOpinion(false)
                }


            } else {

                toast((
                    <div className='toastinner'>
                        {response.message}
                        <a onClick={() => { toast.dismiss(); }}>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });
    }

    const setRating = (e) => {
        setFormValues((oldValues) => ({ ...oldValues, "rating": e.target.value }));
        setActiveOpinion(true);
    }

    const submitFeedback = () => {



        setFormErrors({ opinion: "", error_class: "" });
        if (formValues.opinion === '') {
            setFormErrors({ opinion: "Required field", error_class: "input_error" });
            return false;
        }

        setFeedbackLoading(true);
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("rating", formValues.rating);
        data.append("opinion", formValues.opinion);
        data.append("issues", feedbackIssues);
        data.append("test_id", test_id);



        sendFeedback(data, token).then((response) => {
            setFeedbackLoading(false);

            if (response.success) {
                /*setTimeout(function () {
                    setActiveRating(true);
                },5000)

                setSuccessMessage(response.message);
                setActiveOpinion(false)*/

                setActiveRating(true);
                setActiveRatingStep2(false);
                toast(
                    <div className="toastinner">
                        <p className="feedback-success-message-wrap mb-0 mt-0"><img
                            src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' /> {response.message}</p>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const getFastClass = (name) => {
        let issues = formValues.issues;

        let check = true;

            let exist = false;
            issues.forEach(function (item) {
                if (item == name) {
                    exist = true;
                }
            })
            if (!exist) {
                check = false;
            }
        if (check) {
            return 'active';
        }
        return '';
    }
    const selectFast = (name) => {

        return (e) => {

            let issues = [...formValues.issues];

            if (getFastClass(name) != 'active') {

                    let exist = false;
                    issues.forEach(function (item) {
                        if (item == name) {
                            exist = true;
                        }
                    })
                    if (!exist) {
                        issues.push(name);
                    }
            }
            if (getFastClass(name) == 'active') {

                    var exist = -1;
                    issues.forEach(function (item, index) {
                        if (item == name) {
                            exist = index;
                        }
                    })
                    if (exist != -1) {
                        issues.splice(exist, 1);
                    }

            }
            setFormValues({...formValues,'issues': issues});
            setFeedbackIssues([...issues]);

            return;
        }

    }


    return (
        <LayoutResearcherFullWidth fixed_header_target={true} isLoading={isLoading} wrapClass={""} skipCheck={false} extendedFooter={false} activeMenu={""}>
            <div className='study-introduction-page-wrapper stydy-pages-wrapper study-live-page-wrap position-relative pb-0'>
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">

                        <div className="profile-info-section">
                            <div className="target-back-option-data published-test">
                                <div className="backto-list-wrap">
                                    <a href="#" onClick={(e) => { e.preventDefault(); navigate("/researcher/project/" + test.project_id) }}>
                                        <img
                                            src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
                                            alt="img"
                                        />{" "}
                                        BACK TO PROJECT
                                    </a>
                                </div>
                                <div className="profile-info-text">
                                    <div className="target-group-heading-top studyint-top-flex-wrap">
                                        <h1 className="h1">
                                            {test.test_name}
                                        </h1>
                                        <div className="study-draft-and-save-wrap">
                                            <span className="green-btn pink-bg">Live</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container inner-page-container">
                    <div className='publish-test-page-data pt-64 pb-64'>
                        <div className='row study-test-live-row'>
                            <div className='col-md-6 col-lhs-wrap'>
                                <div className='publ-left-side test-live-lhs'>
                                    <h2 className='h2'>Your test is now live!</h2>
                                    <p>Insights will be available after the first few participants complete your test. <br />Please note, you cannot make edits to your live test. </p>
                                </div>
                            </div>
                            <div className='col-md-6 col-rhs-wrap'>
                                <div className='publ-right-side test-live-rhs'>
                                    <h3 className='h3'>Your test link</h3>
                                    <div className='generated-study-link-wrap mb-32'>
                                        <p className='study-gen-link'>{process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}</p>
                                        <div className='copy-share-test-wrap'>
                                            <CopyToClipboard text={process.env.REACT_APP_URL + "t/" + encryptId(test.id, test)}
                                                onCopy={() => { setLinkCopied(true); setTimeout(function () { setLinkCopied(false); }, 2000) }}>
                                                <span>Copy link  <img src={process.env.REACT_APP_URL + "images/link.svg"} /></span>
                                            </CopyToClipboard>
                                            <span className={`${linkCopied ? 'fade-in' : 'fade-out'} `}>Copied!</span>

                                            {/*<span>Share link  <img src={process.env.REACT_APP_URL + "images/repo-forked.svg"} /></span>*/}
                                        </div>
                                    </div>

                                    <div className='publ-test-password-access-wrap study-live-pass-access-wrap'>
                                        {test.password &&
                                            <>
                                                <h3 className='h3'>Password to access test</h3>
                                                <div className='generated-study-link-wrap mb-32'>
                                                    <p className='study-gen-link'>{test.password}</p>
                                                    <div className='copy-share-test-wrap'>
                                                        <CopyToClipboard text={test.password}
                                                            onCopy={() => { setPasswordCopied(true); setTimeout(function () { setPasswordCopied(false); }, 2000) }}>
                                                            <span>Copy password <img src={process.env.REACT_APP_URL + "images/copy-icon.svg"} alt="img" /></span>
                                                        </CopyToClipboard>
                                                        <span className={`${passwordCopied ? 'fade-in' : 'fade-out'} `}>Copied!</span>

                                                    </div>
                                                </div>

                                            </>
                                        }
                                        <p className='share-your-test-info'>Share your test with your own circle of participants for free to get even more results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`live-feedback-modal-wrap ${isActiveRating ? 'd-none' : 'fadeIn'}`}>
                            <div className='live-feedback-modal-inner-data'>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => setActiveRating(true)}
                                >
                                    <span aria-hidden="true">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="cross"
                                        />
                                    </span>
                                </button>

                                <div className='live-modal-step'>
                                    <p>Step 1 of 2</p>
                                </div>
                                <div className='live-modal-top-heading'>
                                    <p>Your feedback helps make UserQ the best it can be.<br />
                                        How likely are you to recommend UserQ to a friend, colleague or peer?</p>
                                </div>

                                <div className='live-test-feedback-type-wrap'>
                                    {(formValues.rating == 1) &&
                                        <div className='select-feedback-type note-sure-option not-likely step1'>
                                            <span>Very Unlikely</span>
                                            <img src={process.env.REACT_APP_URL + "images/not-like-face.svg"} alt="img" />
                                        </div>
                                    }

                                    {(formValues.rating == 2) &&
                                        <div className='select-feedback-type note-sure-option not-likely step2'>
                                            <span>Unlikely</span>
                                            <img src={process.env.REACT_APP_URL + "images/not-like-face.svg"} alt="img" />
                                        </div>
                                    }



                                    {formValues.rating == 3 &&
                                        <div className='select-feedback-type note-sure-option not-sure step3'>
                                            <span>Neutral</span>
                                            <img src={process.env.REACT_APP_URL + "images/neutral-face.svg"} alt="img" />
                                        </div>
                                    }


                                    {(formValues.rating == 4) &&
                                        <div className='select-feedback-type note-sure-option will-definitely step4'>
                                            <span>Likely</span>
                                            <img src={process.env.REACT_APP_URL + "images/recommend-icon.svg"} alt="img" />
                                        </div>
                                    }

                                    {(formValues.rating == 5) &&
                                        <div className='select-feedback-type note-sure-option will-definitely step5'>
                                            <span>Very likely</span>
                                            <img src={process.env.REACT_APP_URL + "images/recommend-icon.svg"} alt="img" />
                                        </div>
                                    }

                                </div>

                                <div className="target-group-col-data">
                                    <input type="range" value={formValues.rating} min={1} max={5} onChange={(e) => setRating(e)} />
                                </div>

                                
                                    <>
                                        <div className='share-feedback-option-for-live-test'>

                                            <div className='d-flex justify-content-center'>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setActiveRatingStep2(true)
                                                        setActiveRating(true);
                                                        // setTimeout(function () {
                                                        //     setActiveRatingStep2(true)
                                                        // }, 200);
                                                    }}
                                                    className="button primary-btn ml-0 mr-0 mt-64">
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                


                                {successMessage &&
                                    <p className="feedback-success-message-wrap mb-0"> <img src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' />  {successMessage}</p>
                                }
                            </div>
                        </div>

                        <div className={`live-feedback-modal-wrap ${(formValues.rating == 5) && isActiveRatingStep2 ? '' : 'd-none'}`}>
                            <div className='live-feedback-modal-inner-data'>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => setActiveRatingStep2(false)}
                                >
                                    <span aria-hidden="true">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="cross"
                                        />
                                    </span>
                                </button>

                                <div className='live-modal-step'>
                                    <p>Step 2 of 2</p>
                                </div>

                                <div className='live-modal-top-heading'>
                                    <p>We are really pleased to know you love UserQ.<br />
                                        What is the most important reason you would recommend us?</p>
                                </div>


                                
                                    <>
                                        <div className='share-feedback-option-for-live-test'>
                                            <TextAreaField
                                                error={formErrors.opinion}
                                                error_class={formErrors.error_class}
                                                onChange={(e) => setFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    "opinion": e.target.value
                                                }))}
                                                value={formValues.opinion}
                                                maxLength={350}
                                                autosize={true}
                                                type="text"
                                                label={"Share your opinion"} />

                                            <div className='d-flex justify-content-center'>
                                                <button type="submit" onClick={() => submitFeedback()} className="button primary-btn ml-0 mr-0 mt-64">
                                                    Send Feedback
                                                    {feedbackLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                


                                {successMessage &&
                                    <p class="feedback-success-message-wrap mb-0"> <img src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' />  {successMessage}</p>
                                }
                            </div>
                        </div>

                        <div className={`live-feedback-modal-wrap ${(formValues.rating < 5) && isActiveRatingStep2 ? '' : 'd-none'}`}>
                            <div className='live-feedback-modal-inner-data'>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => { setActiveRatingStep2(false) }}
                                >
                                    <span aria-hidden="true">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                            alt="cross"
                                        />
                                    </span>
                                </button>
                                <div className='live-modal-step'>
                                    <p>Step 2 of 2</p>
                                </div>
                                <div className='live-modal-top-heading'>
                                    <p>Is there anything we can improve?</p>
                                </div>

                                <div className="input-field static-label mb-4 mt-4">
                                    <div className="mobile-apps-list-wrap nps-chips">
                                        <span
                                            className={getFastClass("Interface & Navigation")}
                                            onClick={selectFast("Interface & Navigation")}
                                        >
                                            INTERFACE & NAVIGATION
                                        </span>
                                        <span
                                            className={getFastClass("Test creation functionalities")}
                                            onClick={selectFast("Test creation functionalities")}
                                        >
                                            TEST CREATION FUNCTIONALITIES
                                        </span>
                                        <span
                                            className={getFastClass("Technical issues")}
                                            onClick={selectFast("Technical issues")}
                                        >
                                            TECHNICAL ISSUES
                                        </span>
                                        <span
                                            className={getFastClass("Pricing & Billing")}
                                            onClick={selectFast("Pricing & Billing")}
                                        >
                                            PRICING & BILLING
                                        </span>
                                        <span
                                            className={getFastClass("Testers recruitment")}
                                            onClick={selectFast("Testers recruitment")}
                                        >
                                            TESTERS RECRUITMENT
                                        </span>
                                        <span
                                            className={getFastClass("Other")}
                                            onClick={selectFast("Other")}
                                        >
                                            OTHER
                                        </span>
                                    </div>
                                </div>

                                
                                    <>
                                        <div className='share-feedback-option-for-live-test'>
                                            <TextAreaField
                                                error={formErrors.opinion}
                                                error_class={formErrors.error_class}
                                                onChange={(e) => setFormValues((oldValues) => ({
                                                    ...oldValues,
                                                    "opinion": e.target.value
                                                }))}
                                                value={formValues.opinion}
                                                maxLength={350}
                                                autosize={true}
                                                type="text"
                                                label={"Share your opinion"} />

                                            <div className='d-flex justify-content-center'>
                                                <button type="submit" onClick={() => submitFeedback()} className="button primary-btn ml-0 mr-0 mt-64">
                                                    Send Feedback
                                                    {feedbackLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                


                                {successMessage &&
                                    <p className="feedback-success-message-wrap mb-0"> <img src={process.env.REACT_APP_URL + 'images/green-check.svg'} alt='img' />  {successMessage}</p>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </LayoutResearcherFullWidth>
    )
}