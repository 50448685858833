//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {hideResult} from "../../../../services/researcher_test_results";


export default function HideParticipant({openModal,closeModal,test_id}) {
    const navigate = useNavigate();

    const [hideParticipantLoading, setHideParticipantLoading] = useState(false);

    const hideParticipantHandler = ()=>{
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id",test_id);
        data.append("test_result_id",openModal.test_result_id);

        setHideParticipantLoading(true);
        hideResult(data, token).then((response) => {
            setHideParticipantLoading(false);

            if (response.success) {
                closeModal(true);
            }

            toast(
                <div className="toastinner">
                    {response.message}
                    <a onClick={() => {
                        toast.dismiss();
                    }}>&times;</a>
                </div>,
                {
                    className: (response.success) ? "successtoast" : "errortoast",
                    position: "bottom-center",
                    duration: 2000,
                }
            );
        });
    }
    return (
        <Modal
            show={openModal.active}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal delete-modal-wrap"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64 text-center">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <h2 className="h2">
                        {(openModal.hidden_result)?'Show participant':'Hide participant'}
                    </h2>
                    <p className="w-100">Are you sure you want to {(openModal.hidden_result)?'show':'hide'} this participant?</p>

                    <div className="button-wrap d-flex justify-content-between mt-32 btn-50-wrap flex-nowrap">
                        <button type="button" onClick={() => {
                            closeModal(false);
                        }} className="button secondary-btn">Cancel</button>
                        <button type="button" onClick={(e)=>hideParticipantHandler()}
                                className={`button  primary-btn`}>
                            Confirm
                            {hideParticipantLoading && (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                ></i>
                            )}
                        </button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}