import React, { useState,  useEffect, useRef  } from 'react';
import { useNavigate , Link,useSearchParams } from 'react-router-dom';
import { encryptClient, preventClick } from '../../../lib/helpers';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';

import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import {FullPageLoadingIcon} from "../../loader/full_page_loader_lcon";
import { gapi } from 'gapi-script';


import { load } from 'recaptcha-v3'
import GoogleAuth from '../login-with-google';

let login_token = null;

let verify_token = null;

export default function Login() {

    ReactSession.setStoreType("localStorage");

    let passwordInput = useRef();
    const [searchParams, setSearchParams] = useSearchParams();

    

    const navigate = useNavigate();

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    const [formErrors, setFormErrors] = useState({password:null, email: null, error_class:null});

    const [pageHeading, setPageHeading] = useState(<>I'm a  <br/><span>Tester</span></>);

    const [subHeading, setSubHeading] = useState(
      <>And get paid to give your feedback</>
    );

    const [social, setSocial] = useState("All");

    const [isEmailVerified, setEmailVerified] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [isAccountExist, setAccount] = useState(true);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [socialLoading, setSocialLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', password:''});

    const [accountLinked, setAccountLinked] = useState(false);

    const [hoverClass, setHoverClass] = useState("");

    const responseApple = (response) => {
        //setSocialLoading(true);

        setErrorMessage(null);

        if('authorization' in response){

            setSocialLoading(true);

            generateCaptchaLogin(function(token){
                response['captcha'] = token;
                response['referralCode'] = searchParams.get('refID');
                response['test_result_id'] = ReactSession.get("test_result_id");


                fetch(process.env.REACT_APP_API_END_POINT+'social-login/apple/Tester', {
                    method: 'POST',
                    body: JSON.stringify(response),
                    headers:{"Content-Type":"application/json"}
                })
                .then(res => res.json())
                .then(response => {
                    setSocialLoading(false);
                    if(response.success){
                        ReactSession.set("token", response.token);
                        ReactSession.set("user", response.user);
                        if(response.user.role === "Tester"){
                            if(response.tester && response.tester.completed_step === 5){
                                navigate('/dashboard');
                            }else{
                                navigate('/profile/step1');
                            }
                        }else{
                            navigate('/dashboard');
                        }

                    } else {
                        
                        setErrorMessage(response.message);
                    }
                });
            })
            
            
        }
    }
   
    const responseFacebook = (response) => {
        
        if(response){
          //  setLoading(true);
            setSocialLoading(true);

            generateCaptchaLogin(function(token){
                setErrorMessage(null);
                
                response['captcha'] = token;
                response['referralCode'] = searchParams.get('refID');
                response['test_result_id'] = ReactSession.get("test_result_id");

                fetch(process.env.REACT_APP_API_END_POINT+'social-login/facebook/Tester', {
                    method: 'POST',
                    body: JSON.stringify(response),
                    headers:{"Content-Type":"application/json"}
                })
                .then(res => res.json())
                .then(response => {
                    setSocialLoading(false);
                    if(response.success){
                        ReactSession.set("token", response.token);
                        ReactSession.set("user", response.user);
                        if(response.user.role === "Tester"){
                            if(response.tester && response.tester.completed_step === 5){
                                navigate('/dashboard');
                            }else{
                                navigate('/profile/step1');
                            }
                        }else{
                            navigate('/dashboard');
                        }

                    } else {
                        
                        setErrorMessage(response.message);
                    }
                });
            })
                
        }
    }
    const responseGoogle = (response) => {
        
        if(response.profileObj){
          //  setLoading(true);
            setSocialLoading(true);

            generateCaptchaLogin(function(token){
                setErrorMessage(null);

                response.profileObj['captcha'] = token;

                response.profileObj['tokenId'] = response.tokenId;
                response.profileObj['referralCode'] = searchParams.get('refID');
                response.profileObj['test_result_id'] = ReactSession.get("test_result_id");
                
                fetch(process.env.REACT_APP_API_END_POINT+'social-login/google/Tester', {
                    method: 'POST',
                    body: JSON.stringify(response.profileObj),
                    headers:{"Content-Type":"application/json"}
                })
                .then(res => res.json())
                .then(response => {
                    setSocialLoading(false);
                    if(response.success){
                        ReactSession.set("token", response.token);
                        ReactSession.set("user", response.user);

                        if(response.user.role === "Tester"){
                            if(response.tester && response.tester.completed_step === 5){
                                navigate('/dashboard');
                            }else{
                                navigate('/profile/step1');
                            }
                        }else{
                            navigate('/dashboard');
                        }

                    } else {
                        
                        setErrorMessage(response.message);
                    }
                });
            })
                
        }
    }
      
    useEffect(() => {
        ReactSession.set("register_email","");
        ReactSession.set("successVerifiedMessage","");
        ReactSession.set("hide_new_account_toast",false);
        setTimeout(function(){
            window.animate();
        },1000);
        
        document.title = process.env.REACT_APP_NAME+" - Login";
    }, [isEmailVerified]);

    useEffect(()=>{

        

        if(searchParams.get('refID')){
            //console.log('dasd')
            navigate('/404');
        }

        regenerateCaptcha();
    },[]);
    const regenerateCaptcha = ()=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;
    
                setFormValues({...formValues, login_token:login_token});
                
            })
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;           
            })
        })
    }
    const generateCaptchaLogin = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;
    
                setFormValues({...formValues, login_token:login_token});

                callback(token);
                
            })
        })
    }
    const generateCaptchaVerify = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;      
                
                callback(token);
            })
        })
    }

    const loginUser = () => {
        
        setLoading(true);

        generateCaptchaLogin(function(token){

            formValues.login_token = token;

            setErrorMessage(null);

            var body = Object.assign({},formValues);

            body['password'] = encryptClient(body.password);

            body['role'] = 'Tester';

            fetch(process.env.REACT_APP_API_END_POINT+'login', {
                method: 'POST',
                body: JSON.stringify(body),
                headers:{"Content-Type":"application/json"}
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);
                regenerateCaptcha();
                if(response.success){
                    ReactSession.set("token", response.token);
                    ReactSession.set("user", response.user);
                    if(response.user.role === "Tester"){
                        if(response.tester && response.tester.completed_step === 5){
                            navigate('/dashboard');
                        }else{
                            navigate('/profile/step1');
                        }
                    }else{
                        navigate('/dashboard');
                    }

                } else {
                    
                    setErrorMessage(response.message);
                }
            });
        })
    }
    const checkIfEmailVerified = () => {

        setLoading(true);

        generateCaptchaVerify(function(token){
            
            verify_token = token;

            
            setErrorMessage(null);

            fetch(process.env.REACT_APP_API_END_POINT+'check_if_email_verified?verify_token='+verify_token+'&email='+formValues.email+"&role=Tester", {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);
                regenerateCaptcha();
                if(response.success){
                    
                    if(response.data.verified){ 
                    
                        setEmailVerified(true);
                        passwordInput.current.focus();
                    } else {

                        navigate("/tester/registration/success");
                        //setErrorMessage(response.message);    
                    }
                } else {
                    if(response.data && response.data.account && response.data.used_with_social){
                        setPageHeading(response.message);
                        setSubHeading(response.data.sub_heading);
                        setSocial(response.data.provider);
                        setAccountLinked(true);
                    }
                    else if(response.data && !response.data.account){
                        
                        ReactSession.set("register_email", formValues.email);
                        if(searchParams.get('refID')){
                            navigate({
                                "pathname":"/tester/register",
                                "search" : '?refID='+searchParams.get('refID')
                            });
                        }else{
                            navigate("/tester/register");
                        }

                    } else {
                        
                        setErrorMessage(response.message);
                    }
                    
                }
            });
        })
        
    }
    const ForgotPasswordService = () => {
        
        setLoading(true);

        setErrorMessage(null);

        generateCaptchaLogin(function(token){

            fetch(process.env.REACT_APP_API_END_POINT+'forgot-password?email='+formValues.email+"&role=Tester&captcha="+token, {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);

                if(response.success){
                    
                    regenerateCaptcha();
                    //navigate("/forgot-password-email");

                    setModal({
                    modalHeading: "Reset your password now",
                    modalMessage:
                        "We've just sent you an email with the link to reset your password.",
                    modalOpen: true,
                    });

                    /*if(response.data.verified){ 
                        setEmailVerified(true);

                    } else {
                        setErrorMessage(response.message);    
                    }*/
                } else {
                    if(!response.data.account){
                        //setAccount(false);
                        ReactSession.set("register_email", formValues.email);
                        navigate("/tester/register");
                    } else {
                        //setAccount(true);
                        setErrorMessage(response.message);
                    }
                    
                }
            });
        });
    }
    const onSubmit =  async (event) => {
        event.preventDefault();

        setFormErrors({email: null, password: null, "error_class": null});
        
        setErrorMessage(null);

        if(!isLoading){
            if(formValues.email=='' || !formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                setLoading(false);
                setFormErrors({email: 'Please enter a valid email', password: null, "error_class": "input_error"});
                //setErrorMessage('Please enter a valid email');
            }  else {
                if(isEmailVerified==false){
                    checkIfEmailVerified();
                } else {
                    if(formValues.password==''){
                        setLoading(false);
                        setFormErrors({email: null, password: "Required field", "error_class": "input_error"});
                    } else {
                        loginUser();
                    }
                    
                }
            }
            
        }
        return false;
    }
    const forgotPassword = async (event) => {
        event.preventDefault();

        setFormErrors({email: null, password: null, "error_class": null});
        

        if(!isLoading){
            if(formValues.email=='' || !formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                setLoading(false);
                setFormErrors({email: 'Please enter a valid email', password: null, "error_class": "input_error"});
                //setErrorMessage('Please enter a valid email');
            } else {
                ForgotPasswordService();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setErrorMessage(null);
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors(oldValues => ({...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    }
    return (
        <div className="login-page-wrapper  new-login-page-wrap tester-login-pg">
            {socialLoading &&
            <FullPageLoadingIcon />
            }
            {!socialLoading &&
            <>
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_TESTER_URL} className="logo"><img width={113} height={28} src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="login-left-side"  onMouseOver={()=>{ setHoverClass('hovered_fade')}} onMouseOut={()=>{ setHoverClass('')}}>

                    <Link className='login-fade-link' to="/researcher/login">
                        <div className='test-logon-data'>
                            <h1 className="form-heading">I'm A <br /><span>Researcher</span></h1>
                            <p className="form-sub-head">And start testing your designs in minutes</p>

                            <div className='login-form-inner-data'>

                                <div className="input input-field form-group">
                                    <input className="form-control" value="" />
                                    <label>Enter your email to sign in or signup</label>
                                    <span className="clear">
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22"></path>
                                            <path className="long" d="M9 15L20 4"></path>
                                            <path className="arrow" d="M13 11V7"></path>
                                            <path className="arrow" d="M17 11H13"></path>
                                        </svg>
                                    </span>
                                </div>

                                <div className="login-page-btn-wrap">
                                    <button id="r_email_sign_in" type="submit" className="btn btn-block form-btn">Continue</button>
                                    <span id="r_google_sign_in" className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn"><img width={24} height={24} src={process.env.REACT_APP_URL + "images/sign-in-wgoogle.svg"} alt="icon" /> Sign in with google</span>
                                </div>




                            </div>
                        </div>

                    </Link>

                </div>

                <div className={`login-right tester-login-side ${hoverClass} `} >
                    <div className="login-left-data-inner">
                        {accountLinked?
                        <h2 className="form-heading google-linked-heading">{pageHeading}</h2>
                        :
                        <h1 className="form-heading">{pageHeading}</h1>
                        }
                        <p className="form-sub-head">{subHeading}</p>
                        
                        <div className="login-form-inner-data">
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                        {!isAccountExist &&
                                            <span> 
                                                Click <Link to="/tester/register">here</Link> to create an account.
                                            </span>
                                        }
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {successMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap">
                                    <div className="pro-lft-wrap">
                                        {successMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearSuccessMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <form method='POST' onSubmit={onSubmit}>
                                <div className={`input input-field form-group ${(isEmailVerified || (social=="Apple" || social=="Google"  || social=="Facebook") ) ? "read-only-field" : ""}`}>
									<input autoComplete='off' autocapitalize="off" readOnly={(isEmailVerified || (social=="Apple" || social=="Google"  || social=="Facebook"))}  type="email"  className={formErrors.email!=null ? "form-control "+formErrors.error_class : "form-control"} value={formValues.email} onChange={set('email')} id="email" name='email'  />
                                    <label htmlFor="email">Enter your email to sign in or signup</label>
                                    {!isEmailVerified &&
                                        <span className="clear"  onClick={clearFormValue('email')}>
                                            <svg viewBox="0 0 24 24">
                                                <path className="line" d="M2 2L22 22" />
                                                <path className="long" d="M9 15L20 4" />
                                                <path className="arrow" d="M13 11V7" />
                                                <path className="arrow" d="M17 11H13" />
                                            </svg>
                                        </span>
                                    }
                                    {(isEmailVerified || (social=="Apple" || social=="Google"  || social=="Facebook")) &&
                                        <div className="data-field-icon">
                                            <img src={process.env.REACT_APP_URL+"images/fill-check.svg"} alt="icon" />
                                        </div>
                                    }
                                    {(formErrors.email!=null) &&
                                        <span className={formErrors.error_class}>{formErrors.email}</span>
                                    }
                                </div>
                                {isEmailVerified &&
                                    <div>
                                        <div className=" input-field form-group mb-0 password-margin-top">
                                            <div className='position-relative w-100'>
                                                <input ref={passwordInput} className={formErrors.password!=null ? "pass-input form-control "+formErrors.error_class : "pass-input form-control"} type={passwordVisible ? "text" : "password"} id="pass_log_id" value={formValues.password}   onChange={set('password')} />
                                                <i className="toggle-password" onClick={togglePassword}><img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="eye"/></i>
                                                <label htmlFor="pass_log_id">Password <img src={process.env.REACT_APP_URL+"images/star-field.svg"} /></label>
                                                
                                            </div>
                                            {(formErrors.password!=null) &&
                                                <span className={formErrors.error_class}>{formErrors.password}</span>
                                            }
                                        </div>
                                        <p className="forgot-password-link"><a href="#" onClick={forgotPassword}>Forgot password</a></p>
                                    </div>
                                }
                                
                                <div className="login-page-btn-wrap">
                                    {social=="All" &&
                                    <button id='t_email_sign_in' type="submit" className="btn btn-block form-btn">
                                        Continue
                                        {isLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                        }
                                    </button>
                                    }
                                    {!isEmailVerified &&
                                    <div className="divider-wrap"><span>or</span></div>
                                    }
                                    {(social=="Google") &&
                                        <>
                                        {/* <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_ID}
                                            render={renderProps => (
                                                <a href="#" id="t_google_sign_in" onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn"><img width={113} height={48} src={process.env.REACT_APP_URL+"images/sign-in-wgoogle.svg"} alt="icon"/> Sign in with google</a>
                                            
                                            )}
                                            buttonText="Login"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        /> */}
                                        <GoogleAuth role="tester" referralCode={searchParams.get('refID')} setLoading={(loading)=>{setSocialLoading(loading);}} setErrorMessage={(message)=>{setErrorMessage(message);}}/>
                                        </>
                                    }
                                    {(social=="Apple") &&
                                        <AppleLogin  
                                            clientId={"com.userq.app.apple"} 
                                            redirectURI={process.env.REACT_APP_URL+"tester/login"}   
                                            responseType={"code"} 
                                            responseMode={"query"}  
                                            usePopup={true} 
                                            callback={responseApple}
                                            scope="name email"
                                            render={renderProps => (
                                                <a href="#" id="t_apple_sign_in" onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn"><img width={113} height={48} src={process.env.REACT_APP_URL+"images/sign-in-wapple.png"} alt="icon"/> Sign in with Apple</a>
                                            )}
                                        />
                                    }
                                    {(social=="Facebook") &&
                                        <FacebookLogin
                                            appId={process.env.REACT_APP_FB_ID}
                                            fields="name,email,picture"
                                            status={true}
                                            autoLoad={false}
                                            callback={responseFacebook}
                                            render={renderProps => (
                                                <a href="#" id='t_facebook_sign_in' onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn"><img width={113} height={48} src={process.env.REACT_APP_URL+"images/sign-in-wfacebook.png"} alt="icon"/> Sign in with Facebook</a>
                                            )}
                                        />
                                    }
                                </div>
                                
                                {!isEmailVerified &&
                                    <>
                                        
                                        <div className="social-login-wrap">
                                            {(social=="All") &&
                                            <>
                                            {/* <GoogleLogin
                                                clientId={process.env.REACT_APP_GOOGLE_ID}
                                                render={renderProps => (
                                                    <span onClick={renderProps.onClick} disabled={renderProps.disabled}><a id='t_google_sign_in' href="#" onClick={preventClick}><img width={113} height={48} src={process.env.REACT_APP_URL+"images/google.svg"} alt="icon"/></a></span>
                                                
                                                )}
                                                buttonText="Login"
                                                onSuccess={responseGoogle}
                                                onFailure={responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            /> */}
                                            <GoogleAuth role="tester" referralCode={searchParams.get('refID')} setLoading={(loading)=>{setSocialLoading(loading);}} setErrorMessage={(message)=>{setErrorMessage(message);}}/>
                                            </>
                                            }
                                            {(social=="All") &&
                                            <AppleLogin  
                                                clientId={"com.userq.app.apple"} 
                                                redirectURI={process.env.REACT_APP_URL+"tester/login"}   
                                                responseType={"code"} 
                                                responseMode={"query"}  
                                                usePopup={true} 
                                                callback={responseApple}
                                                scope="name email"
                                                render={renderProps => (
                                                    <span onClick={renderProps.onClick} disabled={renderProps.disabled}><a href="#" id='t_apple_sign_in' onClick={preventClick}><img width={113} height={48} src={process.env.REACT_APP_URL+"images/apple.svg"} alt="icon"/></a></span>
                                                )}
                                            />
                                            }
                                            {(social=="All") &&
                                            <FacebookLogin
                                                appId={process.env.REACT_APP_FB_ID}
                                                fields="name,email,picture"
                                                status={true}
                                                autoLoad={false}
                                                callback={responseFacebook}
                                                render={renderProps => (
                                                    <span onClick={renderProps.onClick}><a href="#" id='t_facebook_sign_in' onClick={preventClick}><img width={113} height={48} src={process.env.REACT_APP_URL+"images/facebook.svg"} alt="icon"/></a></span>
                                                )}
                                            />
                                            }
                                            
                                        </div>
                                        <div className="login-bottom-link researcher-btn-login">
                                            <Link to="/researcher/login">Are you a researcher?</Link>
                                        </div>
                                    </>
                                }
                            </form>
                            <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap email-sent-modal login-email-modal">
                                <Modal.Body> 
                                    <div className="modal-inner-text">
                                        <button type="button" className="close" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } aria-label="Close">
                                            <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                                        </button>
                                        <h2>{modal.modalHeading}</h2>
                                        <p>{modal.modalMessage}</p>
                                        <button type="button" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } className="btn btn-block form-btn">BACK TO SIGN IN</button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
                
            </div>
            </>
            }
        </div>
    )
}