import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/layout";
import { stripeCountries } from "../../data/stripe-countries";
import SelectElement from "../layouts/elements/fields/select_element";
import InputField from "../layouts/elements/fields/input";
import { useNavigate, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getTesterCreditSummaryService } from "../../services/credits";
import { updatetesterbankdetails, gettesterbankdetails, requesttesterpayout } from "../../services/user";
import { bankdetailsCountries } from "../../data/countries_bankdetails";
import TextAreaField from './../layouts/elements/fields/textarea';
import { LoadingIcon } from "../loader/loadingIcon";
import {DropdownIndicator, roundNumber} from "../../lib/helpers";
import { countries as payoutCountries } from "../../data/countries.js";
import Select from "react-select";
import toast from "react-hot-toast";
import TesterProfileNavigation from "../profile/tester_profile_navigation";
import Radiobtn from "../layouts/elements/fields/radiobtn.js";

export default function Wallet() {

    const [bankFormValues, setBankFormValues] = useState({country:null, name:'', nickname:'',beneficiary_bank:'',branch:'',city:'',beneficiary_resident_country:null,account_no:'', account_no_type:"account_no"});
    const [bankFormErrors, setBankFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryLabelClass, setCountryLabelClass] = useState("inactive_label");
    const [beneficiaryLabelClass, setBeneficiaryLabelClass] = useState("inactive_label");
    const [isBankAccountEditable, setIsBankAccountEditable] = useState(false);
    const [tempSelectedCountry, setTempSelectedCountry] = useState(null);
    const [tempBankFormValues, setTempBankFormValues] = useState({});
    const [minimumThresholdsForPayouts, setMinimumThresholdsForPayouts] = useState(0);

    let countrySelect = useRef(0);

    let beneficiaryCountrySelect = useRef(0);

    const navigate = useNavigate();

    const [credits, setCredits] = useState(null);
    const [requestedPayout, setRequestedPayout] = useState(null);
    const [depositedPayout, setDepositedPayout] = useState(null);
    let user = ReactSession.get("user");

    useEffect(() => {

        document.title = process.env.REACT_APP_NAME + " - Wallet";

        fetchCreditsSummary();
        gettesterbank();
    }, []);

    const fetchCreditsSummary = () => {

        if (!credits) {
            let token = ReactSession.get("token");

            setIsLoading(true);

            getTesterCreditSummaryService(token).then((response) => {
                setIsLoading(false);
                if (response.success) {
                    ReactSession.set("credits", response.summary.credits_balance);
                    setCredits(response.summary.credits_balance);
                    setRequestedPayout(response.summary.requestedpayoutamount);
                    setDepositedPayout(response.summary.depositedpayoutamount);
                }
            });
        }
    };

    const requestpayout = () => {
        setLoading(true);
        const token = ReactSession.get("token");
        var data = new FormData();
        data.append("bankdetails", JSON.stringify(bankFormValues));
        data.append("selectedbankcountry", selectedCountry);
        data.append("amount", credits);
        requesttesterpayout(data, token).then((response) => {
            setCredits(response.balance);
            setRequestedPayout(response.requestedpayoutamount);
            setDepositedPayout(response.depositedpayoutamount);

            setLoading(false);
            if (response.success) {
                gettesterbank();
                toast(
                    <div className="toastinner">
                        Your payout request has been submitted successfully! Your payment should be processed within 10/15 business days. If there is an issue, we will contact you via email.
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "successtoast",
                        position: "bottom-center",
                        duration: 5000,
                    }
                );

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 5000,
                    }
                );
                // console.log(response);
            }
        });
    };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, bankFormErrors);
        
        for(var key in bankFormErrors){
            formErrorsLocal[key] = null;
        }
        setBankFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }

    const validateBankDetails = () => {

        var error = false;

		var form_errors = resetFormErrors();

        var firsterrorid=null;

		if(!bankFormValues.country){       
                
            form_errors  = {
                ...form_errors,
                country: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"country":firsterrorid;

        }
        if(bankFormValues.name==''){       
                
            form_errors  = {
                ...form_errors,
                name: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"name":firsterrorid;

        }
        if(bankFormValues.beneficiary_bank==''){       
                
            form_errors  = {
                ...form_errors,
                beneficiary_bank: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"beneficiary_bank":firsterrorid;

        }
        if(bankFormValues.branch==''){       
                
            form_errors  = {
                ...form_errors,
                branch: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"branch":firsterrorid;

        }
        if(bankFormValues.city==''){       
                
            form_errors  = {
                ...form_errors,
                city: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"city":firsterrorid;

        }
        if(!bankFormValues.beneficiary_resident_country){       
                
            form_errors  = {
                ...form_errors,
                beneficiary_resident_country: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"beneficiary_resident_country":firsterrorid;

        }
        if(!bankFormValues.account_no){       
                
            form_errors  = {
                ...form_errors,
                account_no: "Required!", 
                error_class: "input_error"
            };
            error = true;
            firsterrorid=firsterrorid==null?"account_no":firsterrorid;

        }
		setBankFormErrors(form_errors);

        /*if(firsterrorid!=null){
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
        }*/

		return error;

    }

    const updatebankdetails = () => {
        var error = validateBankDetails();
        if(!error){
        setLoading(true);
        const token = ReactSession.get("token");
        var data = new FormData();
        data.append("bankdetails", JSON.stringify(bankFormValues));
        data.append("selectedbankcountry", bankFormValues.country);
        updatetesterbankdetails(data, token).then((response) => {

            setLoading(false);
            if (response.success) {
                gettesterbank();
                setIsBankAccountEditable(false);

                toast(
                    <div className="toastinner">
                        Your Bank account details have been updated successfully
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "successtoast",
                        position: "bottom-center",
                        duration: 5000,
                    }
                );

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 5000,
                    }
                );
                // console.log(response);
            }
        });
        }
    };

    const gettesterbank = () => {
        const token = ReactSession.get("token");
        gettesterbankdetails(token).then((response) => {
            if (response.success) {

                setMinimumThresholdsForPayouts(parseInt(response.minimum_thresholds_for_payouts));
                if (response.bankdetails != null) {
                    

                    setBankFormValues({
                        country: response.bankdetails.country ? response.bankdetails.country : null,
                        name: response.bankdetails.name ? response.bankdetails.name : '',
                        nickname: response.bankdetails.nickname ? response.bankdetails.nickname : '',
                        beneficiary_bank: response.bankdetails.beneficiary_bank ? response.bankdetails.beneficiary_bank : '',
                        branch: response.bankdetails.branch ? response.bankdetails.branch : '',
                        city: response.bankdetails.city ? response.bankdetails.city : '',
                        beneficiary_resident_country: response.bankdetails.beneficiary_resident_country ? response.bankdetails.beneficiary_resident_country : null,
                        account_no_type: response.bankdetails.account_no_type ? response.bankdetails.account_no_type : 'account_no',
                        account_no: response.bankdetails.account_no ? response.bankdetails.account_no : '',
                    });
                    
                    console.log(response.bankdetails)

                    if (
                        response.bankdetails.country != null &&
                        response.bankdetails.country != ""
                    ) {

                        setCountryLabelClass("active_label");
                    }
                    

                    if (
                        response.bankdetails.beneficial_resident_country != null &&
                        response.bankdetails.beneficial_resident_country != ""
                    ) {

                        setBeneficiaryLabelClass("active_label");
                    } 
                }
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 5000,
                    }
                );
                // console.log(response);
            }
        });
    };

    
    



    const countryChange = (item) => {
        setBankFormValues({...bankFormValues, country:item.value});
        setCountryLabelClass("active_label");
        setBankFormErrors({...bankFormErrors,country:null});

    };
    const beneficiaryCountryChange = (item) => {
        setBeneficiaryLabelClass("active_label");
        setBankFormErrors({...bankFormErrors,beneficiary_resident_country:null});

        if(item.value=="United Arab Emirates"){
            setBankFormValues({...bankFormValues, account_no_type:"iban", beneficiary_resident_country:item.value});
        } else {
            setBankFormValues({...bankFormValues, beneficiary_resident_country:item.value});
       
        }

    };
    const checkBankDetailsEntered = () => {
        
        if(bankFormValues.country && bankFormValues.name && bankFormValues.beneficiary_bank  && bankFormValues.branch && bankFormValues.city && bankFormValues.beneficiary_resident_country && bankFormValues.account_no ){
            
            return true;
        }
        return false;
    }
    return (
        <Layout isLoading={false} skipCheck={false} extendedFooter={false} setCreditZero={credits==0?true:false}>
            <div className="credits-page-wrapper wallet-page-wrapper page-min-height">
                <TesterProfileNavigation
                    isLoading={false}
                    activeMenu="wallet"
                />

                <div className="profile-info-section d-block pb-64 pt-64">
                    <div className="profile-info-left w-100">
                        <div className="profile-info-text  mt-0">
                            <h1 className="page-main-heading">Wallet</h1>
                        </div>
                    </div>

                    {isLoading ?
                        <LoadingIcon />
                        :

                        <div className="selected-plan-wrapper mb-0 credits-pln-wrapper">
                            <div className="selected-plan-inner-data wallet-count-option">
                                <div className="selected-plan-mid-wrap">
                                    <div className="selected-plan-info-repeat">
                                        <p>In your wallet</p>
                                        <h2>$ {" "}

                                            {roundNumber(credits, 2)}{" "}

                                        </h2>
                                    </div>
                                    <div className="selected-plan-info-repeat">
                                        <p>Payouts in progress</p>
                                        <h2>$ {" "}

                                            {roundNumber(requestedPayout, 2)}{" "}</h2>
                                    </div>
                                    <div className="selected-plan-info-repeat">
                                        <p>Deposited in your account</p>
                                        <h2>$ {" "}

                                            {roundNumber(depositedPayout, 2)}{" "}</h2>
                                    </div>
                                </div>
                                <div className="selected-plan-bottom-wrap">
                                    <div className="selected-plan-bottleft">
                                        {requestedPayout>0?
                                        <p>Your payout is in progress, you will receive it within 10/15 business days</p>
                                        :
                                        <p>You can reedem after reaching minimum of ${minimumThresholdsForPayouts}</p>
                                        }
                                    </div>
                                    {/* <div className="selected-plan-bottright">
                                        {credits >= 30 &&
                                            <button type="submit"
                                                className={`btn form-btn  ${(credits >= 30) ? '' : 'disabled-link'}`}
                                                onClick={() => {
                                                    setRedeem(true)
                                                }}>
                                                Redeem now
                                            </button>
                                        }

                                    </div> */}
                                </div>
                            </div>
                        </div>
                    }

                </div>
                {isLoading ?
                    <></>
                    :
                    <>
                        {((credits >= minimumThresholdsForPayouts) || (requestedPayout+depositedPayout>=minimumThresholdsForPayouts)) &&
                            <>
                                <div className="profile-form-wrap ">
                                    <div className="profile-form-repeat border-0">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-info-text">
                                                    <h3 className="d-flex align-items-center bill-h-dflex">
                                                        Bank account
                                                        {!isBankAccountEditable &&
                                                        <span className="edit-icon"onClick={()=>{
                                                            setIsBankAccountEditable(true);
                                                            setTempSelectedCountry(selectedCountry); 
                                                            setTempBankFormValues(bankFormValues); 
                                                            resetFormErrors();
                                                            if (
                                                                bankFormValues.country != null &&
                                                                bankFormValues.country != ""
                                                            ) {
                                        
                                                                
                                                                setCountryLabelClass("active_label");
                                                            } 
                                                            if (
                                                                bankFormValues.beneficiary_resident_country != null &&
                                                                bankFormValues.beneficiary_resident_country != ""
                                                            ) {
                                                                
                                        
                                                                setBeneficiaryLabelClass("active_label");
                                                            } 
                                                        }}><img src={process.env.REACT_APP_URL+"images/edit-icon.svg"}/></span>
                                                        }
                                                    </h3>
                                                    <p>
                                                        Fill in your bank account details to redeem the credits in your wallet. Transfers may take up to 10/15 business days for processing.
                                                    </p>
                                                    <a target={"_blank"} href={process.env.REACT_APP_SITE_URL + "faq/?role=tester"}
                                                        className="payout-btn-wrap">
                                                        How do payouts work?
                                                    </a>
                                                    <div className="payout-support">
                                                        <p>
                                                            Are you having issue requesting your payout? Get in touch with us.
                                                        </p>
                                                        <Link
                                                            to={'/tester/support/contact?reason=Help with payout'}
                                                            className="project-link test-publish-btn take-test-btn contact-support-button font-weight-600 font-rajdhani"
                                                        >
                                                        Contact support{" "}
                                                        <img
                                                            src={process.env.REACT_APP_URL + "images/arrow-frame.svg"}
                                                            alt="img"
                                                        />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {isBankAccountEditable &&
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="country">
                                                            <label
                                                                className={countryLabelClass}
                                                                htmlFor="country"
                                                            >
                                                                Country 
                                                                <img src={process.env.REACT_APP_URL+"images/star-field.svg"} className="required-field-icon" alt="required-field" />
                                                            </label>
                                                            <div
                                                                className={
                                                                    bankFormErrors.country != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <Select
                                                                    name="country"
                                                                    title="Country"
                                                                    placeholder="Country"
                                                                    searchable={[
                                                                        "Search for country",
                                                                        "No matching country",
                                                                    ]}
                                                                    options={payoutCountries}
                                                                    onChange={countryChange}
                                                                    ref={countrySelect}
                                                                    value={
                                                                        bankFormValues.country
                                                                            ? {
                                                                                label: bankFormValues.country,
                                                                                value: bankFormValues.country,
                                                                            }
                                                                            : null
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                                {bankFormErrors.country != null && (
                                                                    <span className={bankFormErrors.error_class}>
                                                                        {bankFormErrors.country}
                                                                    </span>
                                                                )}
                                                                {/*<span className="bank-country-subtitle">Where your bank account is located</span>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="beneficiary_bank">
                                                            
                                                            <div
                                                                className={
                                                                    bankFormErrors.beneficiary_bank != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <InputField 
                                                                    value={bankFormValues.beneficiary_bank ? bankFormValues.beneficiary_bank:''}
                                                                    name="beneficiary_bank"
                                                                    label={"Beneficiary Bank"}
                                                                    onChange={(e)=>{
                                                                        setBankFormValues({...bankFormValues, beneficiary_bank:e.target.value});

                                                                        if(!e.target.value){
                                                                            setBankFormErrors({...bankFormErrors,"beneficiary_bank":"Required!", error_class:"input_error"});
                                                                        } else {
                                                                            setBankFormErrors({...bankFormErrors,"beneficiary_bank":null, error_class:"input_error"});
                                                                        }
                                                                    }}
                                                                    error_class={
                                                                        bankFormErrors.beneficiary_bank != null
                                                                            ? bankFormErrors.error_class
                                                                            : ""
                                                                    }
                                                                    error={bankFormErrors.beneficiary_bank}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="beneficiary_bank">
                                                            
                                                            <div
                                                                className={
                                                                    bankFormErrors.branch != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <InputField 
                                                                    value={bankFormValues.branch ? bankFormValues.branch:''}
                                                                    name="branch"
                                                                    label={"Branch"}
                                                                    onChange={(e)=>{
                                                                        setBankFormValues({...bankFormValues, branch:e.target.value});

                                                                        if(!e.target.value){
                                                                            setBankFormErrors({...bankFormErrors,"branch":"Required!", error_class:"input_error"});
                                                                        } else {
                                                                            setBankFormErrors({...bankFormErrors,"branch":null, error_class:"input_error"});
                                                                        }
                                                                    }}
                                                                    error_class={
                                                                        bankFormErrors.branch != null
                                                                            ? bankFormErrors.error_class
                                                                            : ""
                                                                    }
                                                                    error={bankFormErrors.branch}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="beneficiary_bank">
                                                            
                                                            <div
                                                                className={
                                                                    bankFormErrors.branch != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <InputField 
                                                                    value={bankFormValues.city ? bankFormValues.city:''}
                                                                    name="city"
                                                                    label={"City"}
                                                                    onChange={(e)=>{
                                                                        setBankFormValues({...bankFormValues, city:e.target.value});

                                                                        if(!e.target.value){
                                                                            setBankFormErrors({...bankFormErrors,"city":"Required!", error_class:"input_error"});
                                                                        } else {
                                                                            setBankFormErrors({...bankFormErrors,"city":null, error_class:"input_error"});
                                                                        }
                                                                    }}
                                                                    error_class={
                                                                        bankFormErrors.city != null
                                                                            ? bankFormErrors.error_class
                                                                            : ""
                                                                    }
                                                                    error={bankFormErrors.city}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="name">
                                                            
                                                            <div
                                                                className={
                                                                    bankFormErrors.name != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <InputField 
                                                                    value={bankFormValues.name ? bankFormValues.name:''}
                                                                    name="name"
                                                                    label={"Account holder name"}
                                                                    onChange={(e)=>{
                                                                        setBankFormValues({...bankFormValues, name:e.target.value});

                                                                        if(!e.target.value){
                                                                            setBankFormErrors({...bankFormErrors,"name":"Required!", error_class:"input_error"});
                                                                        } else {
                                                                            setBankFormErrors({...bankFormErrors,"name":null, error_class:"input_error"});
                                                                        }
                                                                    }}
                                                                    error_class={
                                                                        bankFormErrors.name != null
                                                                            ? bankFormErrors.error_class
                                                                            : ""
                                                                    }
                                                                    error={bankFormErrors.name}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="bene_country">
                                                            <label
                                                                className={beneficiaryLabelClass}
                                                                htmlFor="benneficiary_resident_country"
                                                            >
                                                                Beneficiary Resident Country
                                                                <img src={process.env.REACT_APP_URL+"images/star-field.svg"} className="required-field-icon" alt="required-field" />
                                                            </label>
                                                            <div
                                                                className={
                                                                    bankFormErrors.beneficiary_resident_country != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <Select
                                                                    name="beneficiary_resident_country"
                                                                    title="Beneficiary Resident Country"
                                                                    placeholder="Beneficiary Resident Country"
                                                                    searchable={[
                                                                        "Search for country",
                                                                        "No matching country",
                                                                    ]}
                                                                    options={payoutCountries}
                                                                    onChange={beneficiaryCountryChange}
                                                                    ref={beneficiaryCountrySelect}
                                                                    value={
                                                                        bankFormValues.beneficiary_resident_country
                                                                            ? {
                                                                                label: bankFormValues.beneficiary_resident_country,
                                                                                value: bankFormValues.beneficiary_resident_country,
                                                                            }
                                                                            : null
                                                                    }
                                                                    components={{DropdownIndicator}}
                                                                />
                                                                {bankFormErrors.beneficiary_resident_country != null && (
                                                                    <span className={bankFormErrors.error_class}>
                                                                        {bankFormErrors.beneficiary_resident_country}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {bankFormValues.country!="United Arab Emirates" &&
                                                                    
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="beneficiary_bank">
                                                            
                                                            <div
                                                                
                                                            >
                                                                <div className="checkboxes radio_btn_class gender_btn  d-flex">
                                                                    <Radiobtn onChange={()=>{
                                                                        
                                                                        setBankFormValues({...bankFormValues, account_no:'', account_no_type:"account_no"})}
                                                                    } name={"account_no_type"} id="account_no" value={"account_no"} checked={(!bankFormValues.account_no_type || bankFormValues.account_no_type=="account_no")?true:false } label="Account  No." />
                                                                    <Radiobtn onChange={()=>{
                                                                        setBankFormValues({...bankFormValues, account_no:'', account_no_type:"iban"})}
                                                                    } name={"account_no_type"} id="iban" checked={(bankFormValues.account_no_type=="iban")?true:false } value={"iban"} label="IBAN" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group position-relative" id="beneficiary_bank">
                                                            
                                                            <div
                                                                className={
                                                                    bankFormErrors.account_no != null
                                                                        ? bankFormErrors.error_class
                                                                        : ""
                                                                }
                                                            >
                                                                <InputField 
                                                                    value={bankFormValues.account_no ? bankFormValues.account_no:''}
                                                                    name="account_no"
                                                                    label={bankFormValues.country=="United Arab Emirates" ? "IBAN" : ( bankFormValues.account_no_type=="iban" ? "IBAN" : "Account no.")}
                                                                    onChange={(e)=>{
                                                                        setBankFormValues({...bankFormValues, account_no:e.target.value});

                                                                        if(!e.target.value){
                                                                            setBankFormErrors({...bankFormErrors,"account_no":"Required!", error_class:"input_error"});
                                                                        } else {
                                                                            setBankFormErrors({...bankFormErrors,"account_no":null, error_class:"input_error"});
                                                                        }
                                                                    }}
                                                                    error_class={
                                                                        bankFormErrors.account_no != null
                                                                            ? bankFormErrors.error_class
                                                                            : ""
                                                                    }
                                                                    error={bankFormErrors.account_no}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            }
                                            
                                            {!isBankAccountEditable &&
                                            <div className="bill-inactive-wrap col-md-8">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Country</label>
                                                                <div>{bankFormValues.country ? bankFormValues.country : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    

                                                    
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Beneficiary bank</label>
                                                                <div>{bankFormValues.beneficiary_bank ? bankFormValues.beneficiary_bank : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Branch</label>
                                                                <div>{bankFormValues.branch ? bankFormValues.branch : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>City</label>
                                                                <div>{bankFormValues.city ? bankFormValues.city : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Account holder name</label>
                                                                <div>{bankFormValues.name ? bankFormValues.name : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>Beneficiary resident country</label>
                                                                <div>{bankFormValues.beneficiary_resident_country ? bankFormValues.beneficiary_resident_country : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-6">
                                                        <div className="profile-form-group">
                                                            <div className="input-field">
                                                                <label>
                                                                    {bankFormValues.country=="United Arab Emirates"
                                                                    ?
                                                                    <>
                                                                    IBAN
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {bankFormValues.account_no_type=="account_no"?"Account No.":"IBAN"}
                                                                    </>
                                                                    }
                                                                </label>
                                                                <div>{bankFormValues.account_no ? bankFormValues.account_no : "-"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {!isBankAccountEditable &&
                                            <>
                                    {checkBankDetailsEntered() ?
                                        <>  
                                            {credits>=minimumThresholdsForPayouts?
                                            <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">
                                                <button type="submit" className="btn form-btn" onClick={requestpayout} id="t_wallet_request_payout">
                                                    Request Payout
                                                    {loading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                            :
                                            <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">
                                                <button type="submit" className="btn form-btn disabled-button" id="t_wallet_request_payout">
                                                    Request Payout
                                                    {loading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                            }
                                            
                                        </>
                                        :
                                        <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">
                                                <button type="submit" className="btn form-btn disabled-button" id="t_wallet_request_payout">
                                                    Request Payout
                                                    {loading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                        </div>
                                        }
                                        </>
                                        }
                                        {isBankAccountEditable &&
                                            <div className="button-wrap d-flex justify-content-end">
                                                <div className="inner-form-btn justify-content-end plan-subscribe-btn d-flex mb-64">
                                                    <button type="button" className="btn secondary-btn form-btn" onClick={()=>{
                                                        setIsBankAccountEditable(false);
                                                        setSelectedCountry(tempSelectedCountry); 
                                                        setBankFormValues(tempBankFormValues); 
                                                        setCountryLabelClass("inactive_label");
                                                        resetFormErrors();
                                                        }}>
                                                        Cancel
                                                    </button>
                                            
                                                
                                                    <button type="submit" className="btn primary-btn form-btn" onClick={updatebankdetails}>
                                                        Save
                                                        {loading && (
                                                            <i
                                                                className="fa fa-spinner fa-spin"
                                                                aria-hidden="true"
                                                                style={{ marginLeft: "5px" }}
                                                            ></i>
                                                        )}
                                                    </button>
                                            
                                                </div>
                                            </div>
                                        }
                                </div>
                                <div className="profile-form-group">
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </Layout>
    );
}
