
import { useEffect, useRef, useState } from 'react';
import { load } from 'recaptcha-v3';
import { ReactSession } from '../../lib/secure_reactsession';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

let login_token = null;

let verify_token = null;


const loadScript = (src) =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve()
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    })

const GoogleAuth = ({ role, referralCode, setLoading, setErrorMessage }) => {

    const [formValues, setFormValues] = useState({ email: '', password: '' });
    const [socialLoading, setSocialLoading] = useState(false);
    // const [errorMsg, setErrorMessage] = useState(null);

    const googleButton = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'
        const id = process.env.REACT_APP_GOOGLE_ID

        loadScript(src)
            .then(() => {
                /*global google*/
                // console.log(google)
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                })
                if(role == "tester"){
                    google.accounts.id.renderButton(
                        googleButton.current,
                        { theme: 'outline', size: 'large',shape:'pill', width:380 }
                    )

                }else{
                    google.accounts.id.renderButton(
                        googleButton.current,
                        { theme: 'outline', size: 'large',shape:'pill',width:800}
                    )
                    
                }
                
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const generateCaptchaLogin = (callback) => {
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;

                setFormValues({ ...formValues, login_token: login_token });

                callback(token);

            })
        })
    }

    function handleCredentialResponse(response) {
        // console.log("Encoded JWT ID token: " + response.credential);

        const responsePayload = parseJwt(response.credential);
        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);

        setSocialLoading(true);
        setLoading(true);

        const requestPayload = {};

        if (role == "researcher") {

            generateCaptchaLogin(function (token) {
                requestPayload['captcha'] = token;
                requestPayload['tokenId'] = response.credential;
                requestPayload['email'] = responsePayload.email;
                requestPayload['name'] = responsePayload.name;
                requestPayload['familyName'] = responsePayload.family_name;
                requestPayload['givenName'] = responsePayload.given_name;
                requestPayload['googleId'] = responsePayload.sub;
                requestPayload['imageUrl'] = responsePayload.picture;

                fetch(process.env.REACT_APP_API_END_POINT + 'check-social-login/google/Researcher', {
                    method: 'POST',
                    body: JSON.stringify(requestPayload),
                    headers: { "Content-Type": "application/json" }
                })
                    .then(res => res.json())
                    .then(response => {
                        setSocialLoading(false);
                        setLoading(false);

                        if (response.success) {

                            if (response.login) {
                                ReactSession.set("token", response.token);
                                ReactSession.set("user", response.user);
                                navigate('/dashboard');
                            } else {
                                ReactSession.set("provider", "google");
                                ReactSession.set("provider_response", requestPayload);
                                navigate("/researcher/social-signup");
                            }

                        } else {

                            setErrorMessage(response.message);
                        }
                    });
            })

        }

        if (role == "tester") {

            generateCaptchaLogin(function (token) {
                requestPayload['captcha'] = token;
                requestPayload['tokenId'] = response.credential;
                requestPayload['referralCode'] = referralCode;
                requestPayload['test_result_id'] = ReactSession.get("test_result_id");
                requestPayload['email'] = responsePayload.email;
                requestPayload['name'] = responsePayload.name;
                requestPayload['familyName'] = responsePayload.family_name;
                requestPayload['givenName'] = responsePayload.given_name;
                requestPayload['googleId'] = responsePayload.sub;
                requestPayload['imageUrl'] = responsePayload.picture;

                fetch(process.env.REACT_APP_API_END_POINT + 'social-login/google/Tester', {
                    method: 'POST',
                    body: JSON.stringify(requestPayload),
                    headers: { "Content-Type": "application/json" }
                })
                    .then(res => res.json())
                    .then(response => {
                        setSocialLoading(false);
                        setLoading(false);

                        if (response.success) {
                            ReactSession.set("token", response.token);
                            ReactSession.set("user", response.user);

                            if (response.user.role === "Tester") {
                                if (response.tester && response.tester.completed_step === 5) {
                                    navigate('/dashboard');
                                } else {
                                    navigate('/profile/step1');
                                }
                            } else {
                                navigate('/dashboard');
                            }

                        } else {

                            setErrorMessage(response.message);
                        }
                    });
            })

        }

    }


    return (
        <>
        {role=="researcher" && (
        <>
        <div className='position-relative google-icon-hold researcher-google-icon-hold'>
        <div ref={googleButton} className={`custom-google-signin ${role == "researcher" ? "researcher-google-signin" : "tester-google-signin"}`}></div>
        <a href="#" id="r_google_sign_in" className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn"><img width={24} height={24} src={process.env.REACT_APP_URL+"images/sign-in-wgoogle.svg"} alt="icon"/> Sign in with google</a>
        </div>
        </>
        )}
        {role=="tester" && (
            <span className='position-relative google-icon-hold'>
                <div ref={googleButton} className={`custom-google-signin ${role == "researcher" ? "researcher-google-signin" : "tester-google-signin"}`}></div>
                <a id='t_google_sign_in' href="#" ><img width={113} height={48} src={process.env.REACT_APP_URL+"images/google.svg"} alt="icon"/></a></span>
        )}
        </>
    )
}

export default GoogleAuth