//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {changeTestStatus} from "../../../../services/test";
import {atom, useRecoilState} from 'recoil';

export default function Unpublished({openModal,closeModal,test}) {

    const creditsState = atom({
        key: 'credits', 
        default: 0
    });
    
    const [credits, setCredits] = useRecoilState(creditsState);

    const navigate = useNavigate();

    const [unpublishedLoading, setUnpublishedLoading] = useState(false);
    const publishHandler = (e)=>{

        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);
        data.append("status", 'completed');

        setUnpublishedLoading(true);
        changeTestStatus(data, token).then((response) => {
            setUnpublishedLoading(false);

            if (response.success) {
                closeModal(true);


                ReactSession.set("credits", response.credits_balance);
                setCredits(response.credits_balance);
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    return (
        <Modal
            show={openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal unPublish-modal-wrap"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64 text-left">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <h2 className="h2">Are you sure you want to end this {test.methodology=="Survey"?"survey":"test"}?</h2>
                    <p className="w-100">By ending this {test.methodology=="Survey"?"survey":"test"}, you’ll stop collecting results. But don’t worry, any
                        unspent credits will be refunded to your account.</p>

                    <div className="button-wrap d-flex nda-agreement-modal-button">
                        <button type="button" onClick={() => {
                            closeModal(false);
                        }} className="button secondary-btn">Back to {test.methodology=="Survey"?"survey":"test"}</button>
                        <button type="button" onClick={(e)=>publishHandler(e)}
                                className={`button  primary-btn`}>
                            End {test.methodology=="Survey"?"survey":"test"}
                            {unpublishedLoading && (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                ></i>
                            )}
                        </button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}