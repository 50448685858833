import { propTypes } from "cleave.js/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ScrollToTop(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    //clearCacheData();
  }, [pathname]);

  const clearCacheData = () => {
    var date = new Date;

    fetch("/meta.json?"+date.getTime())
    .then((response) => response.json())
    .then((meta) => {
        if(meta.maintenance_mode==1){
            navigate("/maintenance");
        } 
    });

};
  
  return <>{props.children}</>;
}