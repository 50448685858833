import Layout from "../layouts/layout";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import { getTesterHistory } from '../../services/test_result';
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate,Link } from "react-router-dom";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import {LoadingIcon} from "../loader/loadingIcon";
import {roundNumber} from "../../lib/helpers";
import TesterProfileNavigation from "../profile/tester_profile_navigation";

export default function Tests() {

const [history, setHistory] = useState([]);
const [testhistory, setTestHistory] = useState([]);
const [referralandpayouthistory, setReferralandpayouthistory] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const navigate = useNavigate();

const showErrorToast = (error) => {
  toast(
    <div className="toastinner">
      {error}
      <a
        onClick={() => {
          toast.dismiss();
        }}
      >
        &times;
      </a>
    </div>,
    {
      className: "errortoast",
      position: "bottom-center",
      duration: 2000,
    }
  );
};

  const getPlan = () => {
    let token = ReactSession.get("token");
	setIsLoading(true);
    getTesterHistory(token).then((response) => {
        setIsLoading(false);
      if (response.success) {
		    setHistory(response.totalhistory);
		    setTestHistory(response.testhistory);
		    setReferralandpayouthistory(response.referralandpayouthistory);

      } else {
        let message = response.message;
        if(response.message=="Unauthenticated."){
          message = "Please log out and log in again, if you’re not able to see the history";
        }
        var id = toast(
            <div className="toastinner">
                {message}
                <a
                    onClick={() => {
                        toast.dismiss(id);
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
            }
        );
        //showErrorToast(<FormattedMessage id="Error loading history" />);


      }
    });

  };
  
  useEffect(()=>{getPlan();},[]);

	return (
    <Layout isLoading={false} skipCheck={false} extendedFooter={false}>
      <div className="credits-page-wrapper studies-hpage-wrapper page-min-height">
        
        <TesterProfileNavigation activeMenu={"history"} />

        <div className="profile-info-section d-block pb-64 pt-64">
          <div className="profile-info-left w-100">
            <div className="profile-info-text  mt-0">
              <h1 className="page-main-heading">History</h1>
            </div>
          </div>
        </div>
        {isLoading ? (
            <LoadingIcon />
        ) : (
            <>
            <div className="profile-form-wrap">
              <div className="profile-form-repeat border-0">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-info-text">
                      <h3>
                        {testhistory.length < 1
                          ? "You haven't taken any tests yet"
                          : testhistory.length > 1
                          ? testhistory.length + " tests taken"
                          : testhistory.length + " test taken"}{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="table-wrapper study-history-table">
                      <div className="table-responsive">
                        <table>
                          {history.length > 0 ? (
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>
                                  <div className="sdh-reward-wrap">Reward</div>
                                </th>
                              </tr>
                            </thead>
                          ) : (
                            ""
                          )}
                          <tbody>
                          {/* {referralandpayouthistory.map((result, index) => {
                              return (
                                <tr key={index}>
                                  <td>{result.description}</td>
                                  <td className="no-status">
                                    -
                                  </td>

                                  <td>
                                    {format(
                                      new Date(result.created_at),
                                      "dd/MM/yyyy"
                                    )}
                                  </td>
                                  <td>
                                    <div className="td-flex-wrap">
                                        {result.credits ? (
                                            result.transaction_type=="credit"?(
                                          <b className="green-color">
                                            +&nbsp;&nbsp; $
                                            {roundNumber(result.credits,2)}{" "}
                                          </b>
                                            ):(
                                                <b className="red-color">
                                                -&nbsp;&nbsp; $
                                                {roundNumber(result.credits,2)}{" "}
                                              </b>  
                                            )
                                        ) : (
                                          <b className="red-color">$0</b>
                                        )
                                        }
                                    </div>
                                  </td>

                                  <td className="sh-m-td">
                                    <div className="study-history-mobile">
                                      <p className="study-title">
                                        {result.description}
                                      </p>
                                      <p className="study-date">
                                        {format(
                                          new Date(result.created_at),
                                          "dd/MM/yyyy"
                                        )}
                                      </p>
                                      <div className="study-status-cr-wrap">
                                        <div className="active-tiles-wrap no-status">
                                          -
                                        </div>
                                        <div className="study-cr-m">
                                        {result.credits ? (
                                            result.transaction_type=="credit"?(
                                          <b className="green-color">
                                            +&nbsp;&nbsp; $
                                            {roundNumber(result.credits,2)}{" "}
                                          </b>
                                            ):(
                                                <b className="red-color">
                                                -&nbsp;&nbsp; $
                                                {roundNumber(result.credits,2)}{" "}
                                              </b>  
                                            )
                                        ) : (
                                          <b className="red-color">$0</b>
                                        )
                                        }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            {testhistory.map((result, index) => {
                              return (
                                <tr key={index}>
                                  <td>{result.test && result.test.test_name}</td>
                                  <td>
                                    <div className="active-tiles-wrap">
                                      {result.is_test_completed === 1 ? (
                                        <span className="active">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="in-active">
                                          Abandoned
                                        </span>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    {format(
                                      new Date(result.created_at),
                                      "dd/MM/yyyy"
                                    )}
                                  </td>
                                  <td>
                                    <div className="td-flex-wrap">
                                      {result.is_test_completed === 1 ? (
                                        result.credit ? (
                                          <b className="green-color">
                                            +&nbsp;&nbsp; $
                                            {roundNumber(result.credit.credits,2)}{" "}
                                          </b>
                                        ) : (
                                          <b className="red-color">$0</b>
                                        )
                                      ) : (
                                        <b className="red-color">$0</b>
                                      )}
                                    </div>
                                  </td>

                                  <td className="sh-m-td">
                                    <div className="study-history-mobile">
                                      <p className="study-title">
                                        {result.test &&  result.test.test_name}
                                      </p>
                                      <p className="study-date">
                                        {format(
                                          new Date(result.created_at),
                                          "dd/MM/yyyy"
                                        )}
                                      </p>
                                      <div className="study-status-cr-wrap">
                                        <div className="active-tiles-wrap">
                                          {result.is_test_completed === 1 ? (
                                            <span className="active">
                                              Completed
                                            </span>
                                          ) : (
                                            <span className="in-active">
                                              Abandoned
                                            </span>
                                          )}
                                        </div>
                                        <div className="study-cr-m">
                                          {result.is_test_completed === 1 ? (
                                            result.credit ? (
                                              <b className="green-color">
                                                +&nbsp;&nbsp; $
                                                {result.credit.credits}{" "}
                                              </b>
                                            ) : (
                                              <b className="red-color">$0</b>
                                            )
                                          ) : (
                                            <b className="red-color">$0</b>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })} */}
                            {history.map((result, index) => {
                              return (
                                <tr key={index}>
                                  <td>{result.test_id?result.test && result.test.test_name:result.description}</td>
                                  <td>
                                    <div className="active-tiles-wrap">
                                      {result.test_id ? result.is_test_completed === 1 ? (
                                        <span className="active">
                                          Completed
                                        </span>
                                      ) : (
                                        <span className="in-active">
                                          Abandoned
                                        </span>
                                      ):<td className="no-status">
                                      {result.status?<div className="payout-status">
                                      {result.status}
                                        </div>:<>-</>}
                                    </td>}
                                    </div>
                                  </td>

                                  <td>
                                    {format(
                                      new Date(result.created_at),
                                      "dd/MM/yyyy"
                                    )}
                                  </td>
                                  <td>
                                    <div className="td-flex-wrap">
                                      {result.test_id ?result.is_test_completed === 1 ? (
                                        result.credit ? (
                                          <b className="green-color">
                                            +&nbsp;&nbsp; $
                                            {roundNumber(result.credit.credits,2)}{" "}
                                          </b>
                                        ) : (
                                          <b className="red-color">$0</b>
                                        )
                                      ) : (
                                        <b className="red-color">$0</b>
                                      ):<>{result.credits ? (
                                        result.transaction_type=="credit"?(
                                      <b className="green-color">
                                        +&nbsp;&nbsp; $
                                        {roundNumber(result.credits,2)}{" "}
                                      </b>
                                        ):(
                                            <b className="red-color">
                                            -&nbsp;&nbsp; $
                                            {roundNumber(result.credits,2)}{" "}
                                          </b>  
                                        )
                                    ) : (
                                      <b className="red-color">$0</b>
                                    )
                                    }</>}
                                    </div>
                                  </td>

                                  <td className="sh-m-td">
                                    <div className="study-history-mobile">
                                      <p className="study-title">
                                      {result.test_id?result.test && result.test.test_name:result.description}
                                      </p>
                                      <p className="study-date">
                                        {format(
                                          new Date(result.created_at),
                                          "dd/MM/yyyy"
                                        )}
                                      </p>
                                      <div className="study-status-cr-wrap">
                                        <div className="active-tiles-wrap">
                                          {result.test_id?result.is_test_completed === 1 ? (
                                            <span className="active">
                                              Completed
                                            </span>
                                          ) : (
                                            <span className="in-active">
                                              Abandoned
                                            </span>
                                          ):<td className="no-status">
                                          {result.status?<div className="payout-status">
                                            {result.status}
                                        </div>:<>-</>}
                                        </td>}
                                        </div>
                                        <div className="study-cr-m">
                                          {result.test_id ?result.is_test_completed === 1 ? (
                                            result.credit ? (
                                              <b className="green-color">
                                                +&nbsp;&nbsp; $
                                                {result.credit.credits}{" "}
                                              </b>
                                            ) : (
                                              <b className="red-color">$0</b>
                                            )
                                          ) : (
                                            <b className="red-color">$0</b>
                                          ):<>{result.credits ? (
                                            result.transaction_type=="credit"?(
                                          <b className="green-color">
                                            +&nbsp;&nbsp; $
                                            {roundNumber(result.credits,2)}{" "}
                                          </b>
                                            ):(
                                                <b className="red-color">
                                                -&nbsp;&nbsp; $
                                                {roundNumber(result.credits,2)}{" "}
                                              </b>  
                                            )
                                        ) : (
                                          <b className="red-color">$0</b>
                                        )
                                        }</>}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}
