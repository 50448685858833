import {handleApiErrorResponse} from "../lib/helpers";

export function createTargetGroup(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/create', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function updateTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/update', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function deleteTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/delete', {
        method: 'POST',
        body:  JSON.stringify(formValues),
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}

export function renameTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT + "target_groups/rename", {
      method: "POST",
      body: JSON.stringify(formValues),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTargetGroupOptions(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/options', {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}
export function getTargetGroupService(formValues, token){
    return fetch(process.env.REACT_APP_API_END_POINT+'target_groups/fetch?target_group_id='+formValues.target_group_id, {
        method: 'GET',
        headers:{"Content-Type":"application/json","Accept":"application/json","Authorization":"Bearer "+token}
    }).then((res)=>{

        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data = isJson ? res.json() : null;

        if (!res.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || res.status;
            return Promise.reject(error);
        }

        return data;

    }).catch((res)=>{
        handleApiErrorResponse(res);
    });
}