import { useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { encryptClient, isFileImage } from "../../lib/helpers";
import { changePassword } from "../../services/user.js";

export default function ChangePasswordModal(props) {
  ReactSession.setStoreType("localStorage");

  const navigate = useNavigate();

  const [passwordFormErrors, setPasswordFormErrors] = useState({
    newpassword: null,
    confirmpassword: null,
    error_class: null,
  });

  const [passwordFormValues, setPasswordFormValues] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState({ project_name: "" });

  const [formErrors, setFormErrors] = useState({
    image: null,
    project_name: null,
    error_class: null,
  });

  const [errorMsg, setErrorMessage] = useState(null);

  const [passwordVisible, setPasswordVisibility] = useState(false);

  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePassword = () => {
    setPasswordVisibility(!passwordVisible);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordVisible(!confirmpasswordVisible);
  };

  const setPassword = (name) => {
    return ({ target: { value } }) => {
      setPasswordFormValues((oldValues) => ({ ...oldValues, [name]: value }));
      setPasswordFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
      setPasswordFormErrors((oldValues) => ({
        ...oldValues,
        confirmpassword_error_class: null,
      }));
    };
  };

  const validateForm = (name) => {
    return (event) => {
      setPasswordFormValues((oldValues) => ({
        ...oldValues,
        [name]: event.target.value,
      }));

      var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;

      if (
        passwordFormValues.newpassword.length < 6 ||
        !passwordFormValues.newpassword.match(re)
      ) {
        setPasswordFormErrors((oldValues) => ({
          ...oldValues,
          ["password_error_class"]: "password_error",
        }));
      } else {
        setPasswordFormErrors((oldValues) => ({
          ...oldValues,
          ["password_error_class"]: "none",
        }));
      }
    };
  };

  const closeModal = () => {
    resetFormErrors();

    props.closeModal(false);
  };

  const showFormError = (name, value) => {
    let formErrorsLocal = Object.assign({}, formErrors);

    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }
    formErrorsLocal[name] = value;

    formErrorsLocal["error_class"] = "input_error";

    setFormErrors(formErrorsLocal);
  };

  const resetFormErrors = () => {
    let formErrorsLocal = Object.assign({}, formErrors);

    for (var key in formErrors) {
      formErrorsLocal[key] = null;
    }

    setPasswordFormErrors({
      password: null,
      newpassword: null,
      error_class: null,
    });

    setFormErrors(formErrorsLocal);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };
  const validateFullForm = () => {
    var error = false;

    // var form_errors = resetFormErrors();
    var form_errors = {
      newpassword: null,
      confirmpassword: null,
      error_class: null,
    };

    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;

    if (!passwordFormValues.newpassword.match(re)) {
      form_errors = {
        ...form_errors,
        newpassword: "Required field",
        password_error_class: "password_error",
        error_class: "input_error",
      };
      error = true;
    }  else {
      form_errors ={
          ...form_errors,
          newpassword: null,  
          error_class: "input_error",
          password_error_class:"none", 
      };
  }
    if (passwordFormValues.confirmpassword.length == 0) {
      form_errors = {
        ...form_errors,
        confirmpassword: "Required field",
        confirmpassword_error_class: "password_error",
        error_class: "input_error",
      };
      error = true;
    } else if (
      passwordFormValues.confirmpassword != passwordFormValues.newpassword
    ) {
      form_errors = {
        ...form_errors,
        confirmpassword: "Password does not match.",
        confirmpassword_error_class: "password_error",
        error_class: "input_error",
        confirmpassword_errormessage: "Password does not match.",
      };

      error = true;
    }
    setPasswordFormErrors(form_errors);

    return error;
  };
  const onSubmitPassword = async (event) => {
    event.preventDefault();

    setPasswordFormErrors({
      password: null,
      newpassword: null,
      error_class: null,
    });

    setErrorMessage(null);

    if (!formLoading) {
      var error = validateFullForm();

      if (!error) {
        setFormLoading(true);
        let body = Object.assign({}, passwordFormValues);

        body.newpassword = encryptClient(body.newpassword);

        body.confirmpassword = encryptClient(body.confirmpassword);
        
        body = JSON.stringify(body);

        

        const token = ReactSession.get("token");

        changePassword(body, token).then((response) => {
          setFormLoading(false);

          if (response.success) {
            let user = ReactSession.get("user");

            ReactSession.set("user", user);

            props.closeModal(true);

            toast(response.message, {
              className: "successtoast",
              position: "bottom-center",
            });

            //setSuccessMessage(response.message);
          } else {
            setErrorMessage(response.message);
          }
        });
      }
    }
    return false;
  };

  return (
    <Modal
      show={props.openModal}
      centered
      className="fade custom-modal-wrap change-pass-modal"
    >
      <Modal.Body className="p-0">
        <div className="modal-inner-text">
          <button
            type="button"
            className="close"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <img
                src={process.env.REACT_APP_URL + "images/cross.svg"}
                alt="cross"
              />
            </span>
          </button>
          <div className="create-targetgroup-data-wrap">
            <h2 className="h2">Reset Password?</h2>
            <p>
              Remembering passwords is hard,
              <br /> so let’s get you a new one.
            </p>
          </div>

          {errorMsg && (
            <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
              <div className="pro-lft-wrap">{errorMsg}</div>
              <div className="pro-right-wrap">
                <div className="delete-progress-row">
                  <img
                    onClick={clearErrorMessage}
                    src={process.env.REACT_APP_URL + "images/cross.svg"}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          )}

          <form method="POST" onSubmit={onSubmitPassword}>
            <div className="create-projectmodal-form-group">
              <div className="input input-field form-group">
                <div className="position-relative w-100 ">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className={
                      passwordFormErrors.newpassword != null
                        ? "form-control " + passwordFormErrors.error_class
                        : "form-control"
                    }
                    id="pass_log_id"
                    name="newpassword"
                    onChange={setPassword("newpassword")}
                    onKeyUp={validateForm("newpassword")}
                  />
                  <i className="toggle-password" onClick={togglePassword}>
                    <img
                      src={process.env.REACT_APP_URL + "images/eye.svg"}
                      className="show-password-img"
                      alt="eye"
                    />
                  </i>
                  <label className="control-label" htmlFor="pass_log_id">
                    Password
                    <img
                      src={process.env.REACT_APP_URL + "images/star-field.svg"}
                      className="required-field-icon"
                      alt="required-field"
                    />
                  </label>
                </div>
                {formErrors.password_error_class != "none" && (
                  <p
                    className={
                      passwordFormErrors.password_error_class != null
                        ? "password-guide-text " +
                          passwordFormErrors.password_error_class
                        : "password-guide-text "
                    }
                    style={passwordFormErrors.passwordErrorStyle}
                  >
                    Password must contain at least 6 characters, and a combination of letters, numbers and special characters (e.g !@#$^&*)
                  </p>
                )}
              </div>

              <div className="input input-field mb-0 password-margin-top form-group">
                <div className="position-relative w-100">
                  <input
                    id="pass_log_id-2"
                    type={confirmpasswordVisible ? "text" : "password"}
                    className={
                      passwordFormErrors.confirmpassword != null
                        ? "form-control " + passwordFormErrors.error_class
                        : "form-control"
                    }
                    name="confirmpassword"
                    onChange={setPassword("confirmpassword")}
                  />
                  <i
                    className="toggle-password"
                    onClick={toggleConfirmPassword}
                  >
                    <img
                      src={process.env.REACT_APP_URL + "images/eye.svg"}
                      className="show-password-img"
                      alt="eye"
                    />
                  </i>
                  <label className="control-label" htmlFor="pass_log_id-2">
                    Confirm Password{" "}
                    <img
                      src={process.env.REACT_APP_URL + "images/star-field.svg"}
                      className="required-field-icon"
                      alt="required-field"
                    />
                  </label>
                </div>
                <p
                  className={
                    passwordFormErrors.confirmpassword_error_class != null
                      ? "password-guide-text " +
                        passwordFormErrors.confirmpassword_error_class
                      : "password-guide-text"
                  }
                  style={passwordFormErrors.confirmpasswordErrorStyle}
                >
                  {passwordFormErrors.confirmpassword}
                </p>
              </div>
            </div>
            <div className="create-project-form-btn justify-content-center">
              <button type="submit" className="btn form-btn">
                Reset
                {formLoading && (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    style={{ marginLeft: "5px" }}
                  ></i>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
