//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";


export default function ExistingTesterConcludeTestModal({openExistingTesterConcludeTestModal,closeExistingTesterConcludeTestModal, test}) {
    console.log(test);
    return (
        <Modal
            show={openExistingTesterConcludeTestModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal nda-agreement-modal test-exit-modal"
        >
            <Modal.Body className="modal-lg p-0">
                <div className={`modal-inner-text p-64 text-left ${test.language=="ar"? 'arabic_wrapper':''}` }>
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeExistingTesterConcludeTestModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <div>
                {test.target_group.target_type=="test-links"?
                <div>
                <h2>{test.language=="en"?"Thanks for your feedback":"شكرا لملاحظاتك"}</h2>
                
                {test.language === 'en'?
                <p className="w-100 text-left">$0 has been credited to your wallet.</p>
                :<p className="w-100 text-right">$0 تمت إضافته إلى محفظتك. &nbsp;</p>}
                
                </div>
                :
                <div>
                    <h2>{test.language=="en"?"Thanks for your feedback":"شكرا لملاحظاتك"}</h2>
                    
                    {test.language === 'en'?
                    <p className="w-100 text-left">Congrats! ${test.recruitment_fees_for_tester.toFixed(2)} has been credited to your wallet.</p>
                    :<p className="w-100 text-right"> تهاني!  ${test.recruitment_fees_for_tester.toFixed(2)} تمت إضافته إلى محفظتك. &nbsp;</p>}
                    
                </div>
                }

            </div>

                    <div className="button-wrap d-flex justify-content-between new-tester-conclude mt-32">
                        <div  className="button primary-btn w-100 m-0 ok-button-conclusion" onClick={() => {
                            closeExistingTesterConcludeTestModal(false);
                        }}>{test.language === 'en'?"OK":"موافق"}</div>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}