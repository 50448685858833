import { Dropdown } from "react-bootstrap";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

export default function TestFrame({
  planType,
  uniquekey,
  test,
  duplicate,
  renameTest,
  moveToAnotherProject,
  deleteTest,
  openCopyLink,
  endTest,
  archive,
}) {
  const navigate = useNavigate();
  let user = ReactSession.get("user");

  const getDaysLeft3days = () => {
      var daysdiff = moment.utc(test.closed_on).local().diff(moment(), "days");

      var hoursdiff = moment.utc(test.closed_on).local().diff(moment(), "hours");

      var minutesdiff = moment
          .utc(test.closed_on)
          .local()
          .diff(moment(), "minutes");

      if (daysdiff <= 3 && daysdiff > 0) {
        return (
            <span className="days-left-text">
          {daysdiff} day{daysdiff == 1 ? "" : "s"} left
        </span>
        );
      } else if (hoursdiff < 24 && hoursdiff > 0) {
        return (
            <span className="days-left-text">
          {hoursdiff} hour{hoursdiff == 1 ? "" : "s"} left
        </span>
        );
      } else if (minutesdiff < 60 && minutesdiff >= 0) {
        return (
            <span className="days-left-text">
          {minutesdiff} minute{minutesdiff <= 1 ? "" : "s"} left
        </span>
        );
      } else {
        return <></>;
      }
  };

  const getDaysLeft = () => {

      var daysdiff = moment.utc(test.closed_on).local().diff(moment(), "days");

      var hoursdiff = moment.utc(test.closed_on).local().diff(moment(), "hours");

      var minutesdiff = moment
          .utc(test.closed_on)
          .local()
          .diff(moment(), "minutes");

      if (daysdiff > 0) {
        return (
            <span className="days-left-text">
          {daysdiff} day{daysdiff == 1 ? "" : "s"}
        </span>
        );
      } else if (hoursdiff < 24 && hoursdiff > 0) {
        return (
            <span className="days-left-text">
          {hoursdiff} hour{hoursdiff == 1 ? "" : "s"}
        </span>
        );
      } else if (minutesdiff < 60 && minutesdiff >= 0) {
        return (
            <span className="days-left-text">
          {minutesdiff} minute{minutesdiff <= 1 ? "" : "s"}
        </span>
        );
      } else {
        return <>--</>;
      }
  };

  const getDaysPassed = () => {
      var daysdiff = -moment.utc(test.ended_on).local().diff(moment(), "days");

      var hoursdiff = -moment.utc(test.ended_on).local().diff(moment(), "hours");

      var minutesdiff = -moment
          .utc(test.ended_on)
          .local()
          .diff(moment(), "minutes");

      var secondsdiff = -moment
          .utc(test.ended_on)
          .local()
          .diff(moment(), "seconds");

      if (daysdiff > 0) {
        return (
            <span className="days-left-text">
          {daysdiff} day{daysdiff == 1 ? "" : "s"} ago
        </span>
        );
      } else if (hoursdiff < 24 && hoursdiff > 0) {
        return (
            <span className="days-left-text">
          {hoursdiff} hour{hoursdiff == 1 ? "" : "s"} ago
        </span>
        );
      } else if (minutesdiff < 60 && minutesdiff > 0) {
        return (
            <span className="days-left-text">
          {minutesdiff} minute{minutesdiff == 1 ? "" : "s"} ago
        </span>
        );
      } else if (secondsdiff < 60 && secondsdiff >= 0) {
        return (
            <span className="days-left-text">
          {secondsdiff} second{secondsdiff <= 1 ? "" : "s"} ago
        </span>
        );
      } else {
        return <>--</>;
      }
  };

  return (
    <div className="projects-test-repeat flex-wrap" key={uniquekey}>
      <div className="dash-project-content">
        <div className="das-pro-head">
        <div className="live-study-pills-wrap">
          <span className="ongoing-test-sorting">
              {test.methodology}
          </span>
            {test.status == "published" &&  
            <div className="study-draft-and-save-wrap">
                    <span className="green-btn pink-bg">Live</span>
            </div>
            }
        </div>

          <span className="pro-menu-icon">
            <span className="pro-menu-icon small-dropdown mid-menu-wrap dropdown-withclose-btn">
              <Dropdown>
                <Dropdown.Toggle
                  id={"dropdownMenuButton" + test.id}
                  className="dropdown-toggle"
                >
                  <img
                    src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                    alt="img"
                    onClick={() => {
                      document.getElementById(
                        "dropdownMenutest" + test.id
                      ).style.visibility = "visible";
                    }}
                  />
                </Dropdown.Toggle>

                {test.status == "published" && (
                  <Dropdown.Menu id={"dropdownMenutest" + test.id}>
                    <div className="dropdown-close">
                      <img
                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                        alt="img"
                        onClick={() => {
                          document.getElementById(
                            "dropdownMenutest" + test.id
                          ).style.visibility = "hidden";
                        }}
                      />
                    </div>
                    <div className="drop-down-link-hold">
                      <Dropdown.Item onClick={openCopyLink}>
                        Share Link
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              "images/repo-forked.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>

                      <Dropdown.Item onClick={endTest}>
                        {test.methodology=="Survey"?"End Survey":"End Test"}
                        <span className="end-test-icon">
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              "images/end-test-icon.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                )}
                {test.status == "completed" && (
                  <Dropdown.Menu id={"dropdownMenutest" + test.id}>
                    <div className="dropdown-close">
                      <img
                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                        alt="img"
                        onClick={() => {
                          document.getElementById(
                            "dropdownMenutest" + test.id
                          ).style.visibility = "hidden";
                        }}
                      />
                    </div>
                    <div className="drop-down-link-hold">

                      <Dropdown.Item disabled={true}>
                        Share
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              "images/repo-forked.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={duplicate}>
                        Duplicate
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/duplicate.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={moveToAnotherProject}>
                        Move to another project
                        <span>
                          <img
                            src={process.env.REACT_APP_URL + "images/move.svg"}
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                      {/* <Dropdown.Item>
                              <Link
                                to={
                                  "/researcher/tests/" +
                                  test.id +
                                  "/target-group/"
                                }
                              >
                                Publish Again
                                <span>
                                  <img
                                    src={
                                      process.env.REACT_APP_URL +
                                      "images/repeat.svg"
                                    }
                                    alt="img"
                                  />
                                </span>
                              </Link>
                            </Dropdown.Item> */}
                      <Dropdown.Item onClick={archive}>
                        Archive
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/archive.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                )}
                {test.status == "draft" && (
                  <Dropdown.Menu id={"dropdownMenutest" + test.id}>
                    <div className="dropdown-close">
                      <img
                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                        alt="img"
                        onClick={() => {
                          document.getElementById(
                            "dropdownMenutest" + test.id
                          ).style.visibility = "hidden";
                        }}
                      />
                    </div>
                    <div className="drop-down-link-hold">

                      <Dropdown.Item onClick={renameTest}>
                        Rename
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/edit-icon.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={duplicate}>
                        Duplicate
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL + "images/duplicate.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={moveToAnotherProject}>
                        Move to another project
                        <span>
                          <img
                            src={process.env.REACT_APP_URL + "images/move.svg"}
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={deleteTest}>
                        {" "}
                        Delete
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              "images/trash-black.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                )}
                {test.status == "archived" && (
                  <Dropdown.Menu id={"dropdownMenutest" + test.id}>
                    <div className="dropdown-close">
                      <img
                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                        alt="img"
                        onClick={() => {
                          document.getElementById(
                            "dropdownMenutest" + test.id
                          ).style.visibility = "hidden";
                        }}
                      />
                    </div>
                    <div className="drop-down-link-hold">
                      <Dropdown.Item onClick={deleteTest}>
                        {" "}
                        Delete
                        <span>
                          <img
                            src={
                              process.env.REACT_APP_URL +
                              "images/trash-black.svg"
                            }
                            alt="img"
                          />
                        </span>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </span>
          </span>
        </div>
        <div className="ongoing-test-head">
          <h3 className="h3">
            {test.status == "draft" ? (
              <Link onClick={()=>{
                  ReactSession.set("methodology", test.methodology);
              }} to={"/researcher/tests/" + test.id + "/introduction"}>
                {test.test_name}
              </Link>
            ) :
                (test.status == "published" || test.status == "completed") ?
                    <Link to={`/researcher/analytics/${test.id}/overview`}>
                        {test.test_name}
                    </Link>
                :
                <>
                {test.test_name}
                </>
            }
          </h3>
        </div>
        {test.status == "published" && (
          <div className="dash-pro-status-count">
            <div className="dp-stc-repeat">
              <div className="ong-text">
                <p>Participants</p>
                <h3 className="h3">
                  {test.participants
                    ? test.test_results_count + "/" + test.participants
                    : test.test_results_count}
                </h3>
              </div>
            </div>

            <div className="dp-stc-repeat">
              <div className="ong-text">
                <p>Avg time spent</p>
                <h3 className="h3">
                {test.test_results_count==0?
                <>
                0m{" "}
                0s
                </>
                :
                <>
                  {Math.floor(test.total_time_spent / (test.test_results_count*60))}m{" "}
                  {((test.total_time_spent/test.test_results_count) % 60).toFixed(0)}s
                </>}
                </h3>
              </div>
            </div>

            <div className="dp-stc-repeat">
              <div className="ong-text">
                <p>Study ending in</p>
                <h3 className="h3">
                  {test.close_condition == "specific-date" ||
                  test.close_condition == "specific-date-after-no-participants"
                    ? getDaysLeft()
                    : "--"}
                </h3>
              </div>
            </div>
          </div>
        )}

        {test.status == "completed" && (
          <div className="dash-pro-status-count">
            <div className="dp-stc-repeat">
              <div className="ong-text">
                <p>Participants</p>
                <h3 className="h3">
                  {test.participants
                    ? test.test_results_count + "/" + test.participants
                    : test.test_results_count}
                </h3>
              </div>
            </div>

            <div className="dp-stc-repeat">
              <div className="ong-text">
                <p>Avg time spent</p>
                <h3 className="h3">
                {test.test_results_count==0?
                <>
                0m{" "}
                0s
                </>
                :
                <>
                  {Math.floor(test.total_time_spent / (test.test_results_count*60))}m{" "}
                  {((test.total_time_spent/test.test_results_count) % 60).toFixed(0)}s
                </>}
                </h3>
              </div>
            </div>

            <div className="dp-stc-repeat">
              <div className="ong-text">
                <p>Study ended</p>
                <h3 className="h3">{getDaysPassed()}</h3>
              </div>
            </div>
          </div>
        )}
        {test.status == "draft" && (
          <div className="dash-pro-status-count  w-100">
            <div className="dp-stc-repeat  w-100">
              <div className="ong-text">
                <p>Status</p>
                <h3 className="h3">
                  {Math.round((test.saved_step * 100) / 6)}% completed
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className="dash-pro-link">
          {test.status == "draft" && (
            <div className="draft-publish-btn w-100 d-flex justify-content-end">
              <span
                className="project-link test-publish-btn"
                onClick={() => {
                        ReactSession.set("methodology", test.methodology);
                    navigate("/researcher/tests/" + test.id + "/introduction");
                //   if (test.saved_step == 0) {
                //     navigate("/researcher/tests/" + test.id + "/introduction");
                //   } else if (test.saved_step == 1) {
                //     navigate(
                //       "/researcher/tests/" + test.id + "/tree-sort-test/"
                //     );
                //   } else if (test.saved_step == 2) {
                //     navigate("/researcher/tests/" + test.id + "/conclusions");
                //   } else if (test.saved_step == 3) {
                //     navigate("/researcher/tests/" + test.id + "/target-group");
                //   } else if (test.saved_step == 4) {
                //     navigate("/researcher/tests/" + test.id + "/review");
                //   } else if (test.saved_step == 5) {
                //     navigate("/researcher/tests/" + test.id + "/publish");
                //   } else {
                //     navigate("/researcher/tests/" + test.id + "/introduction");
                //   }
                }}
              >
                Edit
                <img
                  src={process.env.REACT_APP_URL + "images/arrow-frame.svg"}
                  alt="img"
                />
              </span>
            </div>
          )}

          {test.status == "published" && (
            <div className="dash-pro-link">
              <div className="project-member-count">
                <span className="days-left-text">
                  {test.close_condition == "specific-date" ||
                  test.close_condition ==
                    "specific-date-after-no-participants" ? (
                    getDaysLeft3days()
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <span className="project-link">
                <Link to={`/researcher/analytics/${test.id}/overview`}>
                  VIEW RESULTS{" "}
                  <img
                    src={process.env.REACT_APP_URL + "images/link-fill.svg"}
                    alt="img"
                  />
                </Link>
              </span>
            </div>
          )}

          {test.status == "completed" && planType == "PAID" && (
            <div className="dash-pro-link">
              <div className="project-member-count"></div>
              <span className="project-link">
                <Link to={`/researcher/analytics/${test.id}/overview`}>
                  VIEW RESULTS{" "}
                  <img
                    src={process.env.REACT_APP_URL + "images/link-fill.svg"}
                    alt="img"
                  />
                </Link>
              </span>
            </div>
          )}

          {test.status == "completed" && planType == "FREE" && (
            <div className="project-member-count w-100 selected-free-test">
              <span className="tile-lock-view">
                <img
                  src={process.env.REACT_APP_URL + "images/lock-icon.svg"}
                  alt="img"
                  width="24"
                  height="24"
                />
                Upgrade to view results
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
