import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import Radiobtn from "../../../layouts/elements/fields/radiobtn";
import toast from 'react-hot-toast';
import { Link } from "react-router-dom";
import CheckboxNormal from "../../../layouts/elements/fields/checkbox_normal";
import { FormattedMessage } from "react-intl";
import {
    atom,
    useRecoilState,
    useResetRecoilState
} from 'recoil';
import { roundNumber } from "../../../../lib/helpers";

export default function PublishModal({ open, close, test, confirm, testmethodology }) {
    let user = ReactSession.get("user");
    const [formValues, setFormValues] = useState({ "checked_all_test": false, "accepted_terms": false })
    //const [credits, setCredits] = useState((ReactSession.get("credits"))?ReactSession.get("credits"):0)

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const [credits, setCredits] = useRecoilState(creditsState);


    const resetCredits = useResetRecoilState(creditsState);

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    useEffect(() => {
        if (!open) {
            setFormValues({ "checked_all_test": false, "accepted_terms": false });
        }
    }, [open]);

    return (
        <Modal
            show={open}
            centered
            className="fade custom-modal-wrap publish-modal-wrap"
        >
            <Modal.Body className="p-0">
                <div className="modal-inner-text p-64">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            close();
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img
                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                alt="cross"
                            />
                        </span>
                    </button>
                    <div className="modal-data-wrap publish-study-modal-data">
                        {testmethodology == "Survey" ? (
                            <h2>Publish your survey</h2>
                            )
                            :
                            (
                                <h2>Publish your study</h2>
                            )
                        }
                        <p className="modal-full-width-p w-100">
                            Ready for your test to go live? So are we! <br/>
                            Make sure you've double checked your test, as well as considered any privacy issues that might arise with your testers.<br/><br/>
                            You will be able to manually close your test at any moment from your project dashboard.

                        </p>
                        <div className="create-project-form-btn modal-flex-button btn-50-wrap mt-64">
                            <button
                                className="button  secondary-btn"
                                onClick={() => {
                                    close();
                                }}
                                type="button"
                            >
                                Cancel
                            </button>
                            <button
                                className={`button  primary-btn `}
                                onClick={() => {
                                    confirm();
                                }}
                                type="button"
                            >
                                {" "}
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
