import React, {useCallback} from 'react';
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestCardSortingRCategoryesultData,
    getTestCardSortingCardsResultData,
    getTestCardSortingMatrixResultData,
    getTestCardSortingSimilarityMatrixResultData
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import {getCurrentPlansService} from "../../../services/plans";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import {atom, useRecoilState} from 'recoil';

import AnyChart from 'anychart-react'
import {LoadingIcon} from "../../loader/loadingIcon";
import {Dropdown} from "react-bootstrap";
import exportAsImage from "../../../lib/exportAsImage";
import {downloadBlob} from "../../../lib/helpers";

export default function TestResultCardSortingSimilarity() {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const exportSimilarityRef = useRef();
    const exportResultGridRef = useRef();


    const testState = atom({
        key: 'testresult-'+test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-'+test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);

    const  [categoryArray,setCategoryArray] = useState([]);
    const  [cardArray,setCardArray] = useState([]);
    const  [matrixArray,setMatrixArray] = useState([]);
    const  [similarityMatrixArray,setSimilarityMatrixArray] = useState([]);
    const [metaData,setMetaData] = useState({active:'matrix','sorting_type':'open',cardSort:"",categorySort:""});
    const [expandData,setExpandData] = useState(false);
    const [expandSimilarityData,setExpandSimilarityData] = useState(false);

    const getTestafterEndTest=()=>{

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            }else{
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getSimilarityMatrix = ()=>{

        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingSimilarityMatrixResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            console.log(response.category)
            if(response){

                setSimilarityMatrixArray(response.category);
            }


        });
    }

    const getTest = () => {

        if(!test){
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    setTest(response.test);
                    getSimilarityMatrix();


                } else {
                    navigate("/dashboard");
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getSimilarityMatrix();
        }
    };

    const getCardSorting = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        getTestCardSortingRCategoryesultData(
            {
                test_id: test_id
            }
            ,token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                setCategoryArray(response.category);

                /*const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                response.matrix.forEach((items)=>{

                    var fill = '';
                    if(response.matrixRangeColor.minMatrixData === items.heat){
                        fill = '#ECE8F7';
                    }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                        fill = '#EED9EF';
                    }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                        fill = '#F2BCDE';
                    }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                        fill = '#F7A0CE';
                    }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                        fill = '#FB83BD';
                    }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                        fill = '#FF66AD';
                    }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                        fill = '#96FFFC';
                    }else{
                        fill = '#FF66AD';
                    }



                    matrixData.push({
                        x:items.x,
                        y:items.y,
                        heat:items.heat,
                        fill:fill
                    })
                })

                //console.log(matrixData)

                setMatrixArray(matrixData);*/

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };



    const getCardSortingMatrix = () => {
        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingMatrixResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            if (response.success) {

                // setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                //setCategoryArray(response.category);

                const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                response.matrix.forEach((items)=>{

                    var fill = '';
                    if(response.matrixRangeColor.minMatrixData === items.heat){
                        fill = '#ECE8F7';
                    }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                        fill = '#EED9EF';
                    }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                        fill = '#F2BCDE';
                    }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                        fill = '#F7A0CE';
                    }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                        fill = '#FB83BD';
                    }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                        fill = '#FF66AD';
                    }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                        fill = '#96FFFC';
                    }else{
                        fill = '#FF66AD';
                    }


                    //console.log(response.MatrixDataArrayTotal)

                    // var heatpercentage =  Math.round(((items.heat)*100)/response.MatrixDataArrayTotal)

                    var totalheat = 0;
                    response.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat
                        }
                    })

                    var heatpercentage = 0;

                    if(response.totalCountTestResult) {
                        var heatpercentage = Math.round(((items.heat) * 100) / response.totalCountTestResult);
                    }

                    matrixData.push({
                        x:items.x,
                        y:items.y,
                        heat:items.heat,
                        fill:fill,
                        participants: items.y+" in "+items.x+" "+heatpercentage+"% Participants",
                    })
                })

                //console.log(matrixData)

                setMatrixArray(matrixData);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };





    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - card sorting";
        getTest();


    }, []);



    const callbackTopbar = (data)=>{
        getTest();
        getTestafterEndTest();
    }


    var chart1_settings = {
        id: "Aerachart1",
        width: "100%",
        background:'transparent',
        height: 600,
        type: 'heatMap',
        data: matrixArray,
        colors:{
            darken:"#ff0000"
        },
        darken:{
            fill:"#ff0000"
        },
        hovered:{
            fill:"#FF66AD"
        },

        tooltip: {
            enabled: true,
            // background: "#defcfc",
            background: {
                fill: "#defcfc",
                corners: 17
            },
            fontColor: "#171637",
            fontSize: "14px",
            fontFamily: "sans-serif",
            // fontWeight: 100,
            title: false,
            separator: false,
            padding: "20px 40px",
            textAlign: "center",
            format: "{%participants}",
            // anchor:"left-center",
            offsetX: 30,
            offsetY: -30
        },
        xAxis: {
            // staggerMode:true,
            labels: {
                // rotation: -40,
                fontFamily: "sans-serif",
                fontSize: "12px",
                rotation:-45
            }
        },
        yAxis: {
            labels: {
                fontFamily: "sans-serif"
            }
        }

        /*label: {
            text: '',
            width: "100%",
            height: "100%",
            fontSize: '45px',
            fontColor: "#fff",
            hAlign: 'center',
            vAlign: 'middle'
        },
        title: {
            text: '',
            fontColor: "#fff",
            fontWeight: 'bold'
        }*/
    };

    const activeMatrixClass = (x,y)=>{
        document.getElementById(x).classList.add('active');
        document.getElementById(y).classList.add('active');
    }

    const  inactiveMatrixClass = (x,y)=>{

        document.getElementById(x).classList.remove('active');
        document.getElementById(y).classList.remove('active');
    }

    const downloadSVG = useCallback(() => {
        //  const svg = exportSimilarityRef.current.getElementsByTagName('div')[0].innerHTML;

        var svg = '<svg><foreignObject><body xmlns="http://www.w3.org/1999/xhtml">';
        svg +=  exportSimilarityRef.current.getElementsByTagName('div')[0].innerHTML;
        svg += '</body></foreignObject></svg>';

        const blob = new Blob([svg], { type: "image/svg+xml" });

        downloadBlob(blob, `similaritymatrix.svg`);
    }, []);




    return (
        <LayoutResearcherFullWidth

            hide_header_footer={true}
            fixed_header_target={true}
            isLoading={isLoading}
            wrapClass={"researcher-test-view-overflow overflow-visible chart-full-view pt-0"}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
        >
            {isLoading &&
            <div className="page-min-height">
                <LoadingIcon />
            </div>
            }
            {!isLoading && test &&
            <div className="analyze-results-page-wrapper">



                <div className={`container inner-page-container matrix-full-view`}>
                    <div className="sp5-content-wrap analyze-overflow-wrap">


                        <div className={`sp5-right-side-wrap card-sorting-anayl-data`}>
                            {sectionLoading &&
                            <LoadingIcon />
                            }
                            {!sectionLoading &&
                            <div className="bd-question-view-wrap resilt-grid-full-page">
                                <div className='bd-question-top-slide d-flex justify-content-between card-sorting-anayl-top-wrap'>
                                    <div className='bd-que-slide-left w-100 d-flex align-items-center justify-content-between'>

                                        <div>
                                            <h3 className='h3 font-inter'>Similarity matrix</h3>
                                            <p>Similarity matrix shows the pair combinations, intented to give a quick insight into the cards participants paired together in the same group the most often.</p>
                                        </div>



                                    </div>

                                    <span className="small-dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle">
                                                DOWNLOAD
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#"
                                                               onClick={() => exportAsImage(exportSimilarityRef.current, "similarityMatrix")}>
                                                    Download PNG
                                                    <span><img
                                                        src={process.env.REACT_APP_URL + "images/download.svg"}/></span>
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={() =>  downloadSVG(exportSimilarityRef.current)}>
                                                    Download  SVG
                                                    <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </span>
                                </div>

                                <div className="bd-que-table-wrapper">


                                    {dataLoading &&
                                    <LoadingIcon />
                                    }
                                    {!dataLoading &&
                                    <div className={`matricx-table-wrap ${(test.language !== 'en')?'cairo-font':''}`} ref={exportSimilarityRef}>
                                        <div className="table-responsive1">
                                            <table style={{marginLeft:'20px'}}>
                                                <tbody>

                                                {similarityMatrixArray.map((datas, indexs) => {

                                                    return (
                                                        <>

                                                            {(indexs === 0) &&

                                                            <tr>
                                                                <td id={datas[0].cardyId} className={`card-name-td ${(test.language !== 'en')?'cairo-font':''}`}
                                                                    colSpan="5">{datas[0].cardy}</td>
                                                            </tr>
                                                            }

                                                            <tr>
                                                                {datas.map((data,index)=>{
                                                                    return (
                                                                        <>
                                                                            <td
                                                                                onMouseOver={()=>activeMatrixClass(data.cardxId,data.cardyId)}
                                                                                onMouseOut={()=>inactiveMatrixClass(data.cardxId,data.cardyId)}
                                                                            >


                                                                                <div
                                                                                    className="table-tooltip position-relative">

                                                                                    <span
                                                                                        className={(test.language !== 'en')?'cairo-font':''}
                                                                                        style={{backgroundColor:data.colorCode,width:'40px',height:'40px',display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            borderRadius: '3px'
                                                                                        }}>
                                                                                        {(data.group_cards_percent != 0)? data.group_cards_percent+'%':''}
                                                                                    </span>

                                                                                    <div
                                                                                        className="tooltipChart">
                                                                                        <b>{data.cardy} </b>and
                                                                                        <b> {data.cardx} </b><br/>
                                                                                        {data.group_cards_percent}% of participants
                                                                                        {data.group_cards} times)
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            {(indexs === index) &&
                                                                            <td id={data.cardxId} className={`card-name-td ${(test.language !== 'en')?'cairo-font':''}`}
                                                                                colSpan={data.col}>{data.cardx}</td>
                                                                            }
                                                                        </>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                                {/*<tr>*/}
                                                {/*    <td tooltip="120 testers" flow="down"><span>80</span></td>*/}
                                                {/*    <td tooltip="120 testers" flow="down"><span>20</span></td>*/}
                                                {/*    <td className="card-name-td" colSpan="2">test card name</td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                {/*    <td tooltip="120 testers" flow="down"><span>80</span></td>*/}
                                                {/*    <td tooltip="120 testers" flow="down"><span>20</span></td>*/}
                                                {/*    <td tooltip="120 testers" flow="down"><span>10</span></td>*/}
                                                {/*    <td className="card-name-td">test card name</td>*/}
                                                {/*</tr>*/}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    }

                                </div>



                            </div>
                            }

                        </div>


                    </div>
                </div>

            </div>
            }
        </LayoutResearcherFullWidth>
    )
}