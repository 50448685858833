import React, { useState, useEffect } from 'react';
import { useNavigate , Link, useParams } from 'react-router-dom';
import { preventClick } from '../../lib/helpers';
import { Modal } from 'react-bootstrap';
import InputField from '../layouts/elements/fields/input';

export default function ChangeEmail() {
    
    let { code } = useParams();

    const [errorMsg, setErrorMessage] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', confirm_email:'', code: code});

    const [formErrors, setFormErrors] = useState({email:null, confirm_email:null, passwordErrorStyle: {color:'#000'}, terms:null, error_class:null});

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    useEffect(() => {
        setTimeout(function(){
            window.animate();
        },1000);
        document.title = process.env.REACT_APP_NAME+" - Change Email";
    }, []);

    const confirmEmail = () => {
        
        setLoading(true);

        setErrorMessage(null);

        fetch(process.env.REACT_APP_API_END_POINT+'confirm-email', {
            method: 'POST',
            body: JSON.stringify(formValues),
            headers:{"Content-Type":"application/json"}
        })
        .then(res => res.json())
        .then(response => {
            setLoading(false);
            if(response.success){
                setFormValues({email:'', confirm_email:'', code: code } );
                setModal({modalHeading:"Email Sent", modalMessage:"An email is sent to your new email address. Please go and verify.", modalOpen: true});                

            } else {
                setErrorMessage(response.message);
            }
        });
    }
    

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);
        
        for(var key in formErrors){
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    }
    const validateFullForm = () => {

        
        var form_errors = resetFormErrors();
        
        var error = false;
            
        var re = /^(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{6,}$/;

        //checking if email field is not blank
            
        if(formValues.email == ''){
            setLoading(false);
            setFormErrors({email: 'Required', confirm_email: null, "error_class": "input_error"});
            error = true;
            
        }

        //checking if email is in correct format
        
        else if(!formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
            setLoading(false);
            setFormErrors({email: 'Please enter a valid email', confirm_email: null, "error_class": "input_error"});
            error = true;
            
        }

        //checking if email and confirm email are both same

         else if(formValues.email!= formValues.confirm_email){
            setLoading(false);
            setFormErrors({email: null, confirm_email: 'Emails must be the same', "error_class": "input_error"});
            error = true;
            
        } else {

            error = false;
        } 


        return error;
    }
    const onSubmit =  async (event) => {
        event.preventDefault();

        setErrorMessage(null);

        if(!isLoading){

            var error = validateFullForm();
            
            if(!error){
                confirmEmail();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };

    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    return (
        <div className="login-page-wrapper">
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo"><img src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
            </div>
            <div className="login-data-wrap">
                <div className="container">
                    <div className="login-left-side">
                        <h1 className="form-heading">Update Your Email Address</h1>
                        <p className="form-sub-head">Enter the new email address you want to use to access UserQ.</p>
                        <div className="login-form-inner-data">
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <form action="#" onSubmit={onSubmit}>
                                <div className=" input-field form-group "  >
                                    <div className='position-relative w-100'>
                                        <InputField
                                        label="Email"
                                        onChange={set("email")}
                                        onClear={() => {
                                            setTimeout(() => {
                                            setFormValues((oldValues) => ({
                                                ...oldValues,
                                                email: "",
                                            }));
                                            }, 1000);
                                        }}
                                        required={true}
                                        value={formValues.email}
                                        // maxLength="45"
                                        error={formErrors.email}
                                        error_class={formErrors.error_class}
                                        id="email"
                                        rtl={false}
                                        readOnly={false}
                                        />    
                                    </div>
                                    
                                </div>
                                <div className=" input-field  form-group mb-0 password-margin-top">
                                    <div className='position-relative w-100'>
                                        <InputField
                                        label="Confirm Email"
                                        onChange={set("confirm_email")}
                                        onClear={() => {
                                            setTimeout(() => {
                                            setFormValues((oldValues) => ({
                                                ...oldValues,
                                                confirm_email: "",
                                            }));
                                            }, 1000);
                                        }}
                                        required={true}
                                        value={formValues.confirm_email}
                                        // maxLength="45"
                                        error={formErrors.confirm_email}
                                        error_class={formErrors.error_class}
                                        id="confirm_email"
                                        rtl={false}
                                        readOnly={false}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-wrap">
                                    <button type="submit" className="btn btn-block form-btn">
                                        Update Email
                                        {isLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                        }
                                    </button>
                                </div>
                                
                            </form>
                            <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap email-sent-modal">
                                <Modal.Body> 
                                    <div className="modal-inner-text">
                                        <button type="button" className="close" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } aria-label="Close">
                                            <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                                        </button>
                                        <h2>{modal.modalHeading}</h2>
                                        <p>{modal.modalMessage}</p>
                                        <Link to="/researcher/login" className="btn btn-block form-btn">BACK TO DASHBOARD</Link>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}