import { useEffect, useState } from "react";
import Task from "./task";
import {useLocation} from 'react-router-dom';
import toast from 'react-hot-toast';
import { savePreferenceTestTaskResultService } from "../../../../services/test_result";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import ConfirmModal from "../../../dialog/confirm_modal";
import { FormattedMessage } from "react-intl";
import PreferenceTestIntroduction from "./preference_test_introduction";

export default function PreferenceTest({test, result, callback,timeSpent}){
    const [initialScreen, setInitialScreen] = useState(true);
    const [designs,setDesigns] = useState([]);
    const [formLoading,setFormLoading] = useState(false);
    const [answer,setAnswer] = useState({});
    const [skippedTask,setSkippedTask] = useState(false);
    const location = useLocation();
    const [startTime, setStartTime] = useState(0);
    const [confirm, setConfirm] = useState({open:false});
    
   

    const savePreferenceTestTask = (skipped) => {
        var p = location.pathname.substr(1 ,1);
                                
        if(p=="p"){
            callback();
        } else {

            setFormLoading(true);

            savePreferenceTestTaskResultService({
                test_id: test.id,
                result_id: result.id,
                guest_token:ReactSession.get("guest_token"),
                task:answer,
                skipped:skipped,
                time_spent:timeSpent,
            }, ReactSession.get("token")).then((response) => {
                
                window.scrollTo(0, 0);

                setFormLoading(false);
    
                if (response.success) {
                    callback();
    
                } else {
                    toast((
                        <div className='toastinner'>
                            {response.message}
                            <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                        </div>), {
                        className: 'errortoast',
                        position: 'bottom-center'
                    });
                }
            });
            
        }
    }
    const skipPreferenceTestTask = () => {

        return () => {
            
            setAnswer({});
            savePreferenceTestTask(true);
        };
        
    }
    return (
        <>
            {initialScreen &&
                <PreferenceTestIntroduction test={test} goToTask={
                    ()=>{ 
                        setInitialScreen(false);

                        if(test.preference_test_setting){
                            
                            setStartTime(new Date().getTime());
                        }
                        window.scrollTo(0, 0);
                    }
                } />
            }
            {!initialScreen &&
                <>
                <Task test={test} preference_test_setting={test.preference_test_setting} preference_test_designs={test.preference_test_designs} answer={answer} 
                    formLoading={formLoading}
                    nextTask={()=>{
                        
                        if('answer_id' in answer){

                            savePreferenceTestTask(false);
                            
                        } else {

                            toast((
                                <div className='toastinner'>
                                    <><FormattedMessage id="Please select answer before going ahead." /></>
                                    <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center'
                            });
                        }
                    }}
                    skipTask={()=>{
                        
                        setConfirm({...confirm, open:true, callback:skipPreferenceTestTask()});
                    }}
                    setAnswer={
                        (answer_id, sequence)=>{
                            if('answer_id' in answer && answer_id==answer.answer_id){
                                setAnswer({});
                            } else {
                                setAnswer({answer_id: answer_id, sequence:sequence, time_spent: new Date().getTime() - startTime} );
                            }
                        }
                    }
                />
                </>
            }
            <ConfirmModal openModal={confirm.open} confirm_message="Are you sure you want to skip this task. This will not save any credits to your account?" confirm_title="Skip" close={()=>{setConfirm({...confirm, open:false})}} 
                confirm = {()=>{
                    confirm.callback();
                    setConfirm({...confirm, open:false});
                }}
             />
            
        </>

    );
}