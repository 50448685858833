import React, { useState,  useEffect, useRef  } from 'react';
import {useNavigate, Link, useSearchParams} from 'react-router-dom';
import { encryptClient, preventClick, saveLocalCache } from '../../../lib/helpers';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../../lib/secure_reactsession';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { Modal } from 'react-bootstrap';
import {FullPageLoadingIcon} from "../../loader/full_page_loader_lcon";
import { load } from 'recaptcha-v3'
import GoogleAuth from '../login-with-google';

let login_token = null;

let verify_token = null;



export default function Login() {

    ReactSession.setStoreType("localStorage");

    let passwordInput = useRef();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [modal, setModal] = useState({modalHeading:null, modalMessage:null, modalOpen:false});

    const [formErrors, setFormErrors] = useState({password:null, email: null, error_class:null});

    const [pageHeading, setPageHeading] = useState(<>I'm A <br/><span>RESEARCHER</span></>);

    const [subHeading, setSubHeading] = useState(
      <>And start testing your designs in minutes</>
    );

    const [social, setSocial] = useState(false);

    const [isEmailVerified, setEmailVerified] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [isAccountExist, setAccount] = useState(true);

    const [passwordVisible, setPasswordVisibility] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [socialLoading, setSocialLoading] = useState(false);

    const [formValues, setFormValues] = useState({email:'', password:''});

    const [accountLinked, setAccountLinked] = useState(false);

    const [hoverClass, setHoverClass] = useState("");

    
    const responseGoogle = (responseGoogle) => {
        
        if(responseGoogle.profileObj){

            setSocialLoading(true);

            generateCaptchaLogin(function(token){
                responseGoogle.profileObj['captcha'] = token;
                responseGoogle.profileObj['referralCode'] = searchParams.get('refID');
                responseGoogle.profileObj['tokenId'] = responseGoogle.tokenId;

                fetch(process.env.REACT_APP_API_END_POINT+'check-social-login/google/Researcher', {
                    method: 'POST',
                    body: JSON.stringify(responseGoogle.profileObj),
                    headers:{"Content-Type":"application/json"}
                })
                .then(res => res.json())
                .then(response => {
                    setSocialLoading(false);
                    
                    if(response.success){

                        if(response.login){
                            ReactSession.set("token", response.token);
                            ReactSession.set("user", response.user);
                            navigate('/dashboard');
                        } else {
                            ReactSession.set("provider","google");
                            ReactSession.set("provider_response",responseGoogle.profileObj); 
                            navigate("/researcher/social-signup");
                        }
                        
                    } else {
                        
                        setErrorMessage(response.message);
                    }
                });
            })
            
            
            
        }
    }
      
    useEffect(() => {
        ReactSession.set("register_email","");
        ReactSession.set("successVerifiedMessage","");
        setTimeout(function(){
            window.animate();
        },1000);
        
        document.title = process.env.REACT_APP_NAME+" - Login";

        
    }, [isEmailVerified]);


    useEffect(()=>{
        
        regenerateCaptcha();
    },[]);

    const regenerateCaptcha = ()=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;
    
                setFormValues({...formValues, login_token:login_token});
                
            })
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;           
            })
        })
    }
    const generateCaptchaLogin = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            recaptcha.execute('login').then((token) => {
                login_token = token;
    
                setFormValues({...formValues, login_token:login_token});

                callback(token);
                
            })
        })
    }
    const generateCaptchaVerify = (callback)=>{
        load(process.env.REACT_APP_GCAPTCHA_V3).then((recaptcha) => {
            
            recaptcha.execute('verifyemail').then((token) => {
                verify_token = token;      
                
                callback(token);
            })
        })
    }
    const loginUser = () => {
        
        generateCaptchaLogin(function(token){
            
            formValues.login_token = token;

            setLoading(true);

            setErrorMessage(null);

            

            var body = Object.assign({},formValues);

            

            body['password'] = encryptClient(body.password);

            body['role'] = 'Researcher';
            
            fetch(process.env.REACT_APP_API_END_POINT+'login', {
                method: 'POST',
                body: JSON.stringify(body),
                headers:{"Content-Type":"application/json"}
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);
                if(response.success){   
                    
                    ReactSession.set("token", response.token);
                    ReactSession.set("user", response.user);
                    navigate('/dashboard');
                } else {
                    
                    setErrorMessage(response.message);
                }
            });
        })
    }
    const checkIfEmailVerified = () => {
        
        generateCaptchaVerify(function(token){
            
            verify_token = token;

            setLoading(true);

            setErrorMessage(null);

            fetch(process.env.REACT_APP_API_END_POINT+'check_if_email_verified?verify_token='+verify_token+'&email='+formValues.email+"&role=Researcher", {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                regenerateCaptcha();
                setLoading(false);

                if(response.success){
                    
                    if(response.data.verified){ 
                        setEmailVerified(true);
                        passwordInput.current.focus();
                    } else {
                        navigate("/researcher/registration/success");
                        setErrorMessage(response.message);    
                    }
                } else {
                    
                    if(response.data && response.data.account && response.data.used_with_social){
                        
                        setPageHeading(response.message);

                        setSubHeading(response.data.sub_heading);

                        setSocial(true);

                        setAccountLinked(true);
                    }
                    else if(response.data &&  !response.data.account){
                        
                        ReactSession.set("register_email", formValues.email);
                        if(searchParams.get('refID')){
                            navigate({
                                "pathname":"/researcher/register",
                                "search" : '?refID='+searchParams.get('refID')
                            });
                        }else{
                            navigate("/researcher/register");
                        }
                    } else {
                        
                        setErrorMessage(response.message);
                    }
                    
                }
            });
        })
        
        
    }
    const ForgotPasswordService = () => {
        
        setLoading(true);

        generateCaptchaLogin(function(token){

            setErrorMessage(null);

            fetch(process.env.REACT_APP_API_END_POINT+'forgot-password?email='+formValues.email+"&role=Researcher&captcha="+token, {
                method: 'GET'
            })
            .then(res => res.json())
            .then(response => {
                setLoading(false);

                if(response.success){
                    
                    //navigate("/forgot-password-email");

                    setModal({
                    modalHeading: "Reset your password now",
                    modalMessage:
                        "We've just sent you an email with the link to reset your password.",
                    modalOpen: true,
                    });

                    /*if(response.data.verified){ 
                        setEmailVerified(true);

                    } else {
                        setErrorMessage(response.message);    
                    }*/
                } else {
                    if(!response.data.account){
                        //setAccount(false);
                        ReactSession.set("register_email", formValues.email);
                        navigate("/register");
                    } else {
                        //setAccount(true);
                        setErrorMessage(response.message);
                    }
                    
                }
            });
        });
        
    }
    const onSubmit =  async (event) => {
        event.preventDefault();

        setFormErrors({email: null, password: null, "error_class": null});
        
        setErrorMessage(null);

        if(!isLoading){
            if(formValues.email=='' || !formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                setLoading(false);
                setFormErrors({email: 'Please enter a valid email', password: null, "error_class": "input_error"});
                
            }  else {
                if(isEmailVerified==false){
                    checkIfEmailVerified();
                } else {
                    if(formValues.password==''){
                        setLoading(false);
                        setFormErrors({email: null, password: "Required field", "error_class": "input_error"});
                    } else {
                        loginUser();
                    }
                    
                }
            }
            
        }
        return false;
    }
    const forgotPassword = async (event) => {
        event.preventDefault();

        setFormErrors({email: null, password: null, "error_class": null});
        

        if(!isLoading){
            if(formValues.email=='' || !formValues.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
                setLoading(false);
                setFormErrors({email: 'Please enter a valid email', password: null, "error_class": "input_error"});
                
            } else {
                ForgotPasswordService();
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setErrorMessage(null);
            setFormValues(oldValues => ({...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {
            setTimeout(function(){
                setFormValues(oldValues => ({...oldValues, [name]: '' }));
            },1000)
            
        }
    };
    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible);
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    }
    return (
        <div className="login-page-wrapper  new-login-page-wrap researcher-login-page">
            {socialLoading &&
            <FullPageLoadingIcon />
            }
            {!socialLoading &&
            <>
            <div className="login-page-header">
                <div className="login-hdr-left">
                    <a href={process.env.REACT_APP_RESEARCHER_URL} className="logo"><img width={82} height={20} src={process.env.REACT_APP_URL+"images/logo.svg"} alt="logo"/></a>
                </div>
            </div>
            <div className="login-data-wrap ">
                <div className={`login-left-side ${hoverClass} `} >
                    <div className="login-left-data-inner">
                        {accountLinked?
                        <h2 className="form-heading google-linked-heading">{pageHeading}</h2>
                        : 
                        <h1 className="form-heading">{pageHeading}</h1>
                        }
                        <p className="form-sub-head">{subHeading}</p>        
                        <div className="login-form-inner-data">
                            {errorMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap error-bg-color">
                                    <div className="pro-lft-wrap">
                                        {errorMsg}
                                        {!isAccountExist &&
                                            <span> 
                                                Click <Link to="/tester/register">here</Link> to create an account.
                                            </span>
                                        }
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearErrorMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {successMsg &&
                                <div className="profile-complete-progress-wrap login-message-wrap">
                                    <div className="pro-lft-wrap">
                                        {successMsg}
                                    </div>
                                    <div className="pro-right-wrap">
                                        <div className="delete-progress-row">
                                            <img onClick={clearSuccessMessage}  src={process.env.REACT_APP_URL+"images/cross.svg"}  alt="img" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <form method='POST' onSubmit={onSubmit}>
                                <div className={`input input-field form-group ${(isEmailVerified || social) ? "read-only-field" : ""}`} >
                                    <input autoComplete='off' autocapitalize="off" readOnly={isEmailVerified} type="email"  className={formErrors.email!=null ? "form-control "+formErrors.error_class : "form-control"} value={formValues.email} onChange={set('email')} id="email" name='email'  />
                                    <label htmlFor="email">Enter your email to sign in or signup</label>
                                    {!(isEmailVerified && social) &&
                                        <span className="clear"  onClick={clearFormValue('email')}>
                                            <svg viewBox="0 0 24 24">
                                                <path className="line" d="M2 2L22 22" />
                                                <path className="long" d="M9 15L20 4" />
                                                <path className="arrow" d="M13 11V7" />
                                                <path className="arrow" d="M17 11H13" />
                                            </svg>
                                        </span>
                                    }
                                    {(isEmailVerified || social) &&
                                        <div className="data-field-icon">
                                            <img src={process.env.REACT_APP_URL+"images/fill-check.svg"} alt="icon" />
                                        </div>
                                    }
                                    {(formErrors.email!=null) &&
                                        <span className={formErrors.error_class}>{formErrors.email}</span>
                                    }
                                </div>
                                {isEmailVerified &&
                                    <div>
                                        <div className=" input-field form-group mb-0 password-margin-top">
                                            <div className='position-relative w-100'>
                                                <input ref={passwordInput} className={formErrors.password!=null ? "pass-input form-control "+formErrors.error_class : "pass-input form-control"} type={passwordVisible ? "text" : "password"} id="pass_log_id" value={formValues.password}   onChange={set('password')} />
                                                <i className="toggle-password" onClick={togglePassword}><img src={process.env.REACT_APP_URL+"images/eye.svg"} alt="eye"/></i>
                                                <label htmlFor="pass_log_id">Password <img src={process.env.REACT_APP_URL+"images/star-field.svg"} /></label>
                                                
                                            </div>
                                            {(formErrors.password!=null) &&
                                                <span className={formErrors.error_class}>{formErrors.password}</span>
                                            }
                                        </div>
                                        <p className="forgot-password-link"><a href="#" onClick={forgotPassword}>Forgot password</a></p>
                                    </div>
                                }
                                <div className="login-page-btn-wrap researcher-login-page-btn">
                                    {!social &&
                                    <button id="r_email_sign_in" type="submit" className="btn btn-block form-btn">
                                        Continue
                                        {isLoading &&
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{"marginLeft": "5px"}}></i>
                                        }
                                    </button>
                                    }
                                    {!isEmailVerified &&
                                    <div className="divider-wrap"><span>or</span></div>
                                    }
                                    {!isEmailVerified &&
                                    <>
                                        {/* <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_ID}
                                            render={renderProps => (
                                                <a href="#" id="r_google_sign_in" onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-block form-btn secondary-btn justify-content-center sign-in-google-btn"><img width={24} height={24} src={process.env.REACT_APP_URL+"images/sign-in-wgoogle.svg"} alt="icon"/> Sign in with google</a>
                                            )}
                                            buttonText="Login"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                        /> */}
                                        <GoogleAuth role="researcher" setLoading={(loading)=>{setSocialLoading(loading);}} setErrorMessage={(message)=>{setErrorMessage(message);}}/>
                                    </>
                                    }
                                    <div className="login-bottom-link tester-btn-login">
                                        <Link to="/tester/login">Are you a tester?</Link>
                                    </div>
                                </div>
                            </form>
                            <Modal show={modal.modalOpen} centered className="fade custom-modal-wrap email-sent-modal login-email-modal">
                                <Modal.Body> 
                                    <div className="modal-inner-text">
                                        <button type="button" className="close" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } aria-label="Close">
                                            <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                                        </button>
                                        <h2>{modal.modalHeading}</h2>
                                        <p>{modal.modalMessage}</p>
                                        <button type="button" onClick={()=>{ setModal({modalHeading:null,modalMessage:null,modalOpen:false}); } } className="btn btn-block form-btn">BACK TO SIGN IN</button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
                
                <div className={`login-right tester-login-side`} onMouseOver={()=>{ setHoverClass('hovered_fade')}} onMouseOut={()=>{ setHoverClass('')}}>
                    <Link className='login-fade-link' to="/tester/login">
                        <div className='test-logon-data'>
                            <h1 className="form-heading">I'm A <br /><span>Tester</span></h1>
                            <p className="form-sub-head">And get paid to give your feedback</p>

                            <div className='login-form-inner-data'>

                                <div className="input input-field form-group">
                                    <input type="email" className="form-control" id="email" name="email" value="" />
                                    <label for="email">Enter your email to sign in or signup</label>
                                    <span className="clear">
                                        <svg viewBox="0 0 24 24">
                                            <path className="line" d="M2 2L22 22"></path>
                                            <path className="long" d="M9 15L20 4"></path>
                                            <path className="arrow" d="M13 11V7"></path>
                                            <path className="arrow" d="M17 11H13"></path>
                                        </svg>
                                    </span>
                                </div>

                                <div className="login-page-btn-wrap">
                                    <button id="t_email_sign_in" type="submit" className="btn btn-block form-btn">Continue</button>
                                </div>

                                <div className="social-login-wrap">
                                    <span>
                                        <a id="t_google_sign_in">
                                            <img width={113} height={48} src={process.env.REACT_APP_URL + "images/google.svg"} alt="logo" />

                                        </a>
                                    </span>

                                    <span id="t_apple_sign_in">
                                        <a>
                                            <img width={113} height={48} src={process.env.REACT_APP_URL + "images/apple.svg"} alt="logo" />

                                        </a>
                                    </span>

                                    <span id="t_facebook_sign_in">
                                        <a>
                                            <img width={113} height={48} src={process.env.REACT_APP_URL + "images/facebook.svg"} alt="logo" />

                                        </a>
                                    </span>

                                </div>


                            </div>
                        </div>

                    </Link>
                </div>
            </div>
            </>
            }
        </div>
    )
}