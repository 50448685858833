import { Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import ResearcherProfileNavigation from "../../profile/researcher_profile_navigation.js";
import React, { useEffect, useState, useRef } from "react";
import LayoutResearcher from "../../layouts/layout_researcher.js";
import {
  getCreditSummaryService,
  getCreditHistoryService,
} from "../../../services/credits";
import toast from "react-hot-toast";
import moment from "moment";
import {LoadingIcon} from "../../loader/loadingIcon";

export default function ResearcherCredits() {
  ReactSession.setStoreType("localStorage");
  let user = ReactSession.get("user");

  const [isLoading, setLoading] = useState(false);

  const [summary, setSummary] = useState({
    credits_added: 0,
    credits_used: 0,
    credits_balance: ReactSession.get("credits")
      ? ReactSession.get("credits")
      : 0,
  });

  const [transLoading, setTransLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);

  const [hasTransactions, setHasTransactions] = useState(true);

  const [transactionPage, setTransactionPage] = useState(1);

  const getCreditHistory = () => {
    let token = ReactSession.get("token");

    setTransLoading(true);

    getCreditHistoryService(transactionPage, token).then((response) => {
      setTransLoading(false);

      if (response.success) {
        setTransactions(transactions.concat(response.credit_history));
        if (response.more_records) {
          setHasTransactions(true);
        } else {
          setHasTransactions(false);
        }
        setTransactionPage(transactionPage + 1);
      } else {
        var id = toast(
          <div className="toastinner">
            {response.message}
            <a
              onClick={() => {
                toast.dismiss(id);
              }}
            >
              &times;
            </a>
          </div>,
          {
            className: "errortoast",
            position: "bottom-center",
          }
        );
      }
    });
  };
  const renderTransactions = () => {
    var transactionsRender = [];
    const user = ReactSession.get("user");
    transactions.forEach(function (transaction) {
      transactionsRender.push(
        <tr key={"trans" + transaction.id}>
          <td>{moment(transaction.created_at).format("DD-MM-YY")}</td>
          <td>{transaction.description}</td>
          <td>
            <>{transaction.added_by}</>
          </td>
          <td>
            <div className="td-flex-wrap">
              <b
                className={
                  transaction.transaction_type == "credit"
                    ? "green-color"
                    : (transaction.credits === 0)? "green-color"
                      : "red-color"
                }
              >
                {transaction.transaction_type == "credit" ? "+" : "-"}{" "}
                {Math.abs(transaction.credits)} credits
              </b>
              {transaction.invoice_url && (
                <a href={transaction.invoice_url} target="_blank">
                  <span className="td-icon-span">
                    <img
                      src={process.env.REACT_APP_URL + "images/tb-status.svg"}
                      alt="img"
                    />
                  </span>
                </a>
              )}
            </div>
          </td>
        </tr>
      );
    });
    return transactionsRender;
  };
  const fetchCreditsSummary = () => {
    let token = ReactSession.get("token");

    setLoading(true);

    getCreditSummaryService(token).then((response) => {
      setLoading(false);

      if (response.success) {
        setSummary(response.summary);

        ReactSession.set("credits", response.summary.credits_balance);
      } else {
        var id = toast(
          <div className="toastinner">
            {response.message}
            <a
              onClick={() => {
                toast.dismiss(id);
              }}
            >
              &times;
            </a>
          </div>,
          {
            className: "errortoast",
            position: "bottom-center",
          }
        );
      }
    });
  };

  useEffect(function () {
    document.title = process.env.REACT_APP_NAME + " - Credits";
    fetchCreditsSummary();
    getCreditHistory();
  }, []);

  return (
    <LayoutResearcher
      isLoading={false}
      wrapClass="rs-inner-profile-wrapper"
      skipCheck={true}
      extendedFooter={false}
      activeMenu={"credits"}
    >
      <div className="billing-plan-page-wrapper credits-page-wrapper">
        <ResearcherProfileNavigation isLoading={false} activeMenu="credits" />

            <div className="page-min-height">
              <div className="profile-info-section d-block pb-64 pt-64">
                <div className="profile-info-left w-100">
                  <div className="profile-info-text  mt-0">
                    <h1 className="page-main-heading">Credits</h1>
                  </div>
                </div>

                {isLoading && (
                    <LoadingIcon/>
                )}
                {!isLoading && (
                <div className="selected-plan-wrapper mb-0 credits-pln-wrapper">
                  <div className="selected-plan-inner-data">
                    <div className="selected-plan-mid-wrap">
                      <div className="selected-plan-info-repeat">
                        <p>Your available credits</p>
                        <div className="d-flex align-items-center">
                          <h2>
                            <img
                              src={
                                process.env.REACT_APP_URL + "images/coins.svg"
                              }
                            />{" "}
                            {summary.credits_balance}
                          </h2>
                          {user.permission != "Editor" && (
                            <div className="selected-plan-bottright">
                              <Link to="/researcher/credits/buy-credits">
                                <button type="button" className="btn form-btn">
                                  Buy credits
                                </button>
                              </Link>
                            </div>
                          )}
                          {user.permission == "Editor" && (
                            <div className="selected-plan-bottright">
                              <button
                                type="button"
                                className="btn form-btn"
                                onClick={() => {
                                  toast(
                                    <div className="toastinner">
                                      {
                                        "You do not have permissions to buy credits. Please contact your Account Owner"
                                      }
                                      <a
                                        onClick={() => {
                                          toast.dismiss();
                                        }}
                                      >
                                        &times;
                                      </a>
                                    </div>,
                                    {
                                      className: "errortoast",
                                      position: "bottom-center",
                                      duration: 3000,
                                    }
                                  );
                                }}
                              >
                                Buy credits
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="selected-plan-info-repeat">
                        <p>Total Spent</p>
                        <h2 className="red-color">
                          <img
                            src={process.env.REACT_APP_URL + "images/coins.svg"}
                          />{" "}
                          {Math.abs(summary.credits_used)}
                        </h2>
                      </div>
                      <div className="selected-plan-info-repeat">
                        <p>Total Added</p>
                        <h2 className="green-color">
                          <img
                            src={process.env.REACT_APP_URL + "images/coins.svg"}
                          />{" "}
                          {Math.abs(summary.credits_added)}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>


              {!isLoading && user.permission != "Editor" && (
                <div className="profile-form-wrap">
                  <div className="profile-form-repeat pb-48">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-info-text">
                          <h3>Transaction history</h3>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="table-wrapper credits-trans-history-table">
                          {transactions.length > 0 && (
                            <div className="table-responsive">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>User</th>
                                    <th>Credits</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {renderTransactions()}
                                  {/*<tr>
                                                <td>dd/mm/yyyy</td>
                                                <td><b>Monthly credits (50x3 users)</b></td>
                                                <td>Jessica@domain.com</td>
                                                <td>
                                                    <div className="td-flex-wrap">
                                                    <b className="green-color">+ 150 credits</b>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>dd/mm/yyyy</td>
                                                <td><b>Monthly credits (50x3 users)</b></td>
                                                <td>--</td>
                                                <td>
                                                    <div className="td-flex-wrap">
                                                    <b className="red-color">- 254 credits</b>
                                                    <span className="td-icon-span"><img src={process.env.REACT_APP_URL+"images/tb-status.svg"} alt="img"/></span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>dd/mm/yyyy</td>
                                                <td><b>Monthly credits (50x3 users)</b></td>
                                                <td>you</td>
                                                <td>
                                                    <div className="td-flex-wrap">
                                                    <b className="red-color">+ 150 credits</b>
                                                    </div>
                                                </td>
                                            </tr>*/}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {!hasTransactions && transactions.length == 0 && (
                            <div className="nodata-wrap">
                              No transactions available at this moment.
                            </div>
                          )}
                          {hasTransactions && !transLoading && (
                            <div
                              className="load-more-data"
                              onClick={getCreditHistory}
                            >
                              <span>
                                Load more{" "}
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/dotts.png"
                                  }
                                />{" "}
                              </span>
                            </div>
                          )}
                          {hasTransactions && transLoading && (
                            <div
                              className="load-more-data"
                              onClick={getCreditHistory}
                            >
                              <span>
                                Loading{" "}
                                <img
                                  src={
                                    process.env.REACT_APP_URL +
                                    "images/dotts.png"
                                  }
                                />{" "}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
      </div>
    </LayoutResearcher>
  );
}
