import React, { useEffect, useState, useCallback, useRef } from "react";
import { Group } from "@visx/group";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import {Annotation} from "@visx/annotation"
import { animated, useTransition, interpolate } from "react-spring";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export default function PieChartSingleChoice({
	width,
	height,
	margin = defaultMargin,
	animate = true,
	data,
    test,
    colors
}) {
	const [chartData, setChartData] = useState([]);

	const [tooltip, showTooltip] = useState({
		tooltipLeft:0,
		tooltipTop:0,
		tooltipData:"",
		open: false
	});

	//if (width < 10) return null;
	const ref = useRef();
	const innerWidth = width - margin.left - margin.right;
	const innerHeight = height - margin.top - margin.bottom;
	const radius = Math.min(innerWidth, innerHeight) / 2;
	const centerY = innerHeight / 2;
	const centerX = innerWidth / 2;

	const handlePointerMove = (key) => {

		return (
			(event) => {
				
				// const containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left ;
				// const containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top ;

                let containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left +30;
                let containerY = ('clientY' in event ? event.clientY : 0)  - ref.current.getBoundingClientRect().top -10;
                
                var tool_width = 222;

                var tool_height = 74;

                var window_width = window.innerWidth;

                var window_height = window.innerHeight;
            
                if(event.clientX+30+tool_width > window_width){
                // alert(event.clientX+tooltipref.current.getBoundingClientRect().width+30+tool_width )
                    containerX = ('clientX' in event ? event.clientX : 0) - ref.current.getBoundingClientRect().left - tool_width -30;
                
                }
                if(event.clientY-10+tool_height > window_height){
                    //alert(window_width)
                    containerY = ('clientX' in event ? event.clientY : 0) - ref.current.getBoundingClientRect().top - tool_height -10;
                }

				showTooltip({
					tooltipLeft: containerX,
					tooltipTop: containerY,
					tooltipData: <><div className={`${test.language=="ar"?"cairo-font":""}`}>{key[0]}</div><br/>{key[1]+(key[1]>1?" Participants":" Participant")}</>,
					open:true
				});
			});
	}
	  
	useEffect(() => {
		var lChartData = [];

        var total = 0;


        let sortable = [];
        for (var option in data) {
            sortable.push([option, data[option]]);
        }

        sortable.sort(function(a, b) {
            return b[1] - a[1];
        });


        let dataSorted = {}
        sortable.forEach(function(item){
            if(item[1]>0){
            dataSorted[item[0]]=item[1]
            }
        })

        for (var key in dataSorted) {

            total += data[key];
        }


		var index = 0;
		for (var key in dataSorted) {

            var percentage =Math.round(((dataSorted[key]/total)*100) * 100) / 100 ;
			if (index == 0) {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					testers: dataSorted[key],
					value: percentage,
					color: colors[0],
				});
			} else if (index == 1) {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					value: percentage,
					color: colors[1],
					testers: dataSorted[key],
				});
			} else if (index == 2) {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					value: percentage,
					color: colors[2],
					testers: dataSorted[key],
				});
			} else {
				lChartData.push({
					label: key,
                    subLabel: percentage+"%",
					value: percentage,
					color: colors[2],
					testers: dataSorted[key],
				});
			}
			index++;
		}
		setChartData(lChartData);
	}, [data]);

	return (
        
		<div ref={ref} style={{position:'relative'}}>
			{tooltip.open ? (
				<>
					<div
						className={"tooltipChart"}
						key={Math.random()} // needed for bounds to update correctly
						style={{ zIndex:9999, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop-30)+"px"}}
					>
						{tooltip.tooltipData}
					
					</div> 
				</>
				): (
					<></>
				)}
			<svg width={width} height={height}>
				<Group top={centerY + margin.top} left={centerX + margin.left}>
					<Pie
						cornerRadius={3}
						padAngle={0.05}
						data={chartData}
						pieValue={(node) => {
							return node.value;
						}}
						pieSortValues={() => -1}
						outerRadius={radius}
					>
						{(pie) => (
							<AnimatedPie
								{...pie}
								animate={animate}
								getKey={(node) => {
									return node.data.label;
								}}
								getSubKey={(node) => {
									return node.data.subLabel;
								}}
								getColor={(node) => {
									return node.data.color;
								}}
								handlePointerMove={handlePointerMove}
								tooltip={tooltip}
								showTooltip={showTooltip}
								getTesters={(node) => {
									return [node.data.label, node.data.testers];
								}}
							/>
						)}
					</Pie>
				</Group>
			</svg>
		</div>
	);
}

const fromLeaveTransition = ({ endAngle }) => ({
	// enter from 360° if end angle is > 180°
	startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }) => ({
	startAngle,
	endAngle,
	opacity: 1,
});

function AnimatedPie({ animate, arcs, path, getKey, getColor, getSubKey,handlePointerMove, tooltip, showTooltip, getTesters }) {
	const transitions = useTransition(arcs, {
		from: animate ? fromLeaveTransition : enterUpdateTransition,
		enter: enterUpdateTransition,
		update: enterUpdateTransition,
		leave: animate ? fromLeaveTransition : enterUpdateTransition,
		keys: getKey,
	});
	return transitions((props, arc, { key }) => {
		const [centroidX, centroidY] = path.centroid(arc);
		const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

		return (
			<g key={key}>
				<animated.path
					// compute interpolated path d attribute from intermediate angle values
					d={interpolate(
						[props.startAngle, props.endAngle],
						(startAngle, endAngle) =>
							path({
								...arc,
								startAngle,
								endAngle,
							})
					)}
					fill={getColor(arc)}
					onPointerMove={handlePointerMove(getTesters(arc)) }
					onPointerLeave={()=>{ showTooltip({...tooltip, open:false}) } }
				/>
				{hasSpaceForLabel && (
					<animated.g style={{ opacity: props.opacity }}>
						{/* <text
							fill="black"
							x={centroidX}
							y={centroidY}
							dy=".33em"
							fontSize={14}
							textAnchor="middle"
							pointerEvents="none"
						>
							{getKey(arc)}
						</text> */}
                        <text
							fill="black"
							x={centroidX}
							y={centroidY+15}
							dy=".36em"
							fontSize={13}
							textAnchor="middle"
							pointerEvents="none"
                            bold
						>
							{getSubKey(arc)}
						</text>
					</animated.g>
				)}
			</g>
		);
	});
}
