import React from "react";

export default function CardSortingIntroduction({goToFirstTask,testlanguage}){
    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap card-sorting-introduction">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="tester-page-selected-img-wrap">
                                <div className="card-sort-test-img-view">
                                    <img src={process.env.REACT_APP_URL+"images/big-bag-img.png"}/>
                                </div>
                            </div>
                        </div>


                        <div className="selected-page-left-side">
                            <h1>{testlanguage=="en"?"Card sorting":"فرز البطاقات"}</h1>
                            <p>{testlanguage=="en"?
                            "We'll ask you to take a look at a list of cards that will appear on our screen and sort them into groups that you think would be best."
                            :"في هذا التمرين، سنطلب منك إلقاء نظرة على قائمة البطاقات الاحتياطية التي ستظهر على شاشتك. نود أن تقوم بفرزها في المجموعات الموجودة على الجانب الأيمن."}<br/><br/>

                            {testlanguage=="en"?"Remember, there is no right or wrong answer here - just choose what comes naturally."
                            :"تذكر، لا توجد إجابة صحيحة أو خاطئة - فقط افعل ما هو طبيعي لك."}
                            </p>
                            <div className="button-wrapper none-for-mobile">
                                <button className="button primary-btn ml-0" onClick={()=>{goToFirstTask()}}>{testlanguage=="en"?"Let’s Start":"لنبدأ"}</button>
                            </div>
                        </div>

                    </div>
                </div>
                </div>

                <div className="button-wrapper none-for-desktop taking-test-button">
                    <button className="button primary-btn ml-0" onClick={()=>{goToFirstTask()}}>{testlanguage=="en"?"Let’s Start":"لنبدأ"}</button>
                </div>
            </div>
        </div>
    );
}