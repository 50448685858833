export default function NationalitiesFlags({data}){
    const renderFlags = () => {
        console.log(data)
        var flags = [];

        data.forEach(function(item, index){

            flags.push((
                <div  key={"nationality"+item.nationality} className={`na-name-wrap ao ${index==0?'active':0} `}>
                    <span>
                        <img src={process.env.REACT_APP_URL+"images/flag_icons/"+(item.short_code!=null ? item.short_code.toLowerCase() : '')+".svg"} />
                    </span>
                    <p className={`country-name-txt ${item.nationality.length>8?'ellipsText':''} `} tooltip={`${item.nationality.length>8?item.nationality:''}`}> 
                        <span>{item.nationality}</span>
                    </p>
                    <span className="na-user-count">{item.count}</span>
                </div>
            ));
        })
        return flags;
    }
    return (
        <>{renderFlags()}</>
    );
}