import React from "react";
import {
    AreaChart,
    Area,
    YAxis,
    XAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";



export default function RechartArea({areaClosedChartData, testmethodology}) {

     /*const  data = [
        {
            name: "Jan 01",
            "Testers": 25
        },
        {
            name: "Feb 08",
            "Testers": 10
        },
        {
            name: "Mar 08",
            "Testers": 50
        },
        {
            name: "Apr 15",
            "Testers": 30
        },
        {
            name: "May 16",
            "Testers": 60
        }
    ];*/

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="tooltipChart">
                   {`${payload[0].value}`} {testmethodology=="Survey"?payload[0].value<2?<>participant</>:<>participants</>:payload[0].value<2?<>participant</>:<>participants</>}
                </div>
            );
        }

        return null;
    };
        return (
            <AreaChart
                width={600}
                height={200}
                data={areaClosedChartData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#FF66AD" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#FF66AD" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="name" tick={{fontSize:'12px'}} />
                <YAxis tick={false} axisLine={false}/>
                {/*<CartesianGrid strokeDasharray="0 0" />*/}
                <Tooltip
                    contentStyle={{ backgroundColor: "#fff", color: "#000" }}
                    itemStyle={{ color: "#000" }}
                    cursor={true}
                    content={<CustomTooltip />}
                />

                {/*<Legend />*/}
                <Area
                    type="monotone"
                    dataKey="Testers"
                    stroke="#FF66AD"
                    fillOpacity={1}
                    fill="url(#colorUv)"/>
                {/*<Area
                    type="monotone"
                    dataKey="Procuct B"
                    stroke="black"
                    fillOpacity={1}
                    fill="url(#colorPv)"/>*/}
            </AreaChart>
        );

}