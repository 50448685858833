import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession.js";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import React, { useEffect, useRef, useState } from "react";
import {getTestData, importCardSortingCardCSVService,importCardSortingCategoryCSVService, saveCardSortingTestService} from "../../../services/test";
import moment from "moment";
import toast from "react-hot-toast";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import redirectToStep from "./redirect_to_step";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import Checkbox from "../../layouts/elements/fields/checkbox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputField from "../../layouts/elements/fields/input";
import {FormattedMessage} from "react-intl";
import ConfirmModal from "../../dialog/confirm_modal";
import {LoadingIcon} from "../../loader/loadingIcon";



export default function CardSortingTest() {
    let { test_id } = useParams();
    const navigate = useNavigate();
    const fileRef = useRef(null);

    ReactSession.setStoreType("localStorage");

    const [isLoading, setLoading] = useState(false);
    const [lastSaved, setLastSaved] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [cardSortingStepLoading, setCardSortingStepLoading] = useState(false);

    const [formValues, setFormValues] = useState({card_sorting_id:"",cardSorting:'open',isRequiredCategories:0,isAddNewCategory:0});
    const [formError, setFormError] = useState({category:'',card:"",error_class:""});
    const [formCustomField, setFormCustomField] = useState({category:'',card:""});
    const [cardArray, setCardArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [language, setLanguage] = useState("en");

    const [confirmModal, setConfirmModal] = useState({ open: false });

    const [importModal, setImportModal] = useState({ open: false });
    const [importCardModal, setImportCardModal] = useState({ open: false });
    const [categoryImportLoading, setCategoryImportLoading] = useState(false);
    const [cardImportLoading, setCardImportLoading] = useState(false);

    const [test, setTest] = useState("");

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setPageLoading(false);

            if (response.success) {
                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/researcher/project/" + response.test.project_id);
                    } else {
                        if (
                            response.test.is_briefing_questions == 1 &&
                            response.test.briefingQuestion.length == 0
                        ) {
                            navigate("/researcher/tests/" + test_id + "/introduction");
                            showError("Please add briefing questions");
                        }
                    }
                }

                if(response.test.methodology !== 'Card Sorting'){
                    navigate("/researcher/tests/" + test_id + "/introduction");
                }

                setTest(response.test);
                setLanguage(response.test.language);

                // set card sorting data
                if(response.test.card_sorting){
                    setFormValues({card_sorting_id:response.test.card_sorting.id,'cardSorting':response.test.card_sorting.card_sorting,'isRequiredCategories':response.test.card_sorting.is_required_categories,'isAddNewCategory':response.test.card_sorting.is_add_new_category});
                }

                if(response.test.card_sorting_card){
                    setCardArray(response.test.card_sorting_card);
                }

                if(response.test.card_sorting_category){
                    setCategoryArray(response.test.card_sorting_category);
                }

                setLastSaved(moment(response.test.updated_at));
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                    }
                );
            }
        });
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Card Sorting";

        getTestApi();
    }, []);


    useEffect(() => {
        const timer = setInterval(() => {
            if(test && test.status!="published") {
                saveCardSortingData(formValues, false);
            }

        }, 30000);

        return () => {
            clearTimeout(timer);
        };
    }, [formValues, cardArray,categoryArray]);

    // delete category
    const deleteCategory = (index) =>{
        const newCategoryArray = categoryArray.filter((item,indexItems) => indexItems !== index);
        setCategoryArray(newCategoryArray);
    }

    // add new category
    const addCategory = (e) =>{
        e.preventDefault();

        // check value is not blank
        const value = e.target.value.trim();
        if(value){

            // check value is already exists or not
            const checkCategory = categoryArray.findIndex(item => item.name === value);
            if(checkCategory === -1){
                setFormCustomField({...formCustomField,"category":''});
                setCategoryArray(categoryArray.concat({'id':'','name':e.target.value}));
            }else{
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="A category with the same name already exists." />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 3000,
                    }
                );
            }

        }
    }

    // add new card
    const addCard = (e)=>{
        e.preventDefault();

        // check value is not blank
        const value = e.target.value.trim();
        if(value){

            // check value is already exists or not
            const checkCard = cardArray.findIndex(item => item.name === value);
            if(checkCard === -1){
                setFormCustomField({...formCustomField,"card":''});
                setCardArray(cardArray.concat({'id':'','name':e.target.value}));
            }else{
                toast(
                    <div className="toastinner">
                        <FormattedMessage id="A card with the same name already exists." />
                        <a
                            onClick={() => {
                                toast.dismiss();
                            }}
                        >
                            &times;
                        </a>
                    </div>,
                    {
                        className: "errortoast",
                        position: "bottom-center",
                        duration: 3000,
                    }
                );
            }

        }
    }

    // delete category
    const deleteCard = (index) =>{
        const newCardArray = cardArray.filter((item,indexItems) => indexItems !== index);
        setCardArray(newCardArray);
    }


    const handleSubmit = () => {

        if(cardArray.length === 0){
            toast(
                <div className="toastinner">
                    {
                        <FormattedMessage id="Looks like you haven't created card for this test! Please add a card to move to the next step." />
                    }
                    <a
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                }
            );
        }else if(categoryArray.length < 2 && formValues.cardSorting === 'close'){
            toast(
                <div className="toastinner">
                    {
                        <FormattedMessage id="You need to add a minimum of two categories to proceed to the next step." />
                    }
                    <a
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                }
            );
        }else{
            saveCardSortingData(formValues, true);
        }
    };

    // save data
    const saveCardSortingData = (form, shouldnavigate)=>{

        if (shouldnavigate) {
            setCardSortingStepLoading(true);
        } else {
            setLoading(true);
        }

        const token = ReactSession.get("token");
        let data = Object.assign({}, '');

        data["test_id"] = test_id;
        data["card_sorting"] = formValues.cardSorting;
        data["card_sorting_id"] = formValues.card_sorting_id;
        data["is_required_categories"] = formValues.isRequiredCategories;
        data["is_add_new_category"] = formValues.isAddNewCategory;
        data["cards"] = cardArray;
        data["category"] = categoryArray;

        data["autosave"] = 1;

        if (shouldnavigate) {
            data["autosave"] = 0;
        }

        saveCardSortingTestService(data, token).then((response) => {
            setCardSortingStepLoading(false);
            setLoading(false);

            if (response.success) {
                setLastSaved(moment.now());
                if (shouldnavigate) {
                    navigate("/researcher/tests/" + test_id + "/conclusions");
                }
            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });

    }

    const handleFileInput = (event,type) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let csvFile = event.target.files[0];

        if(type==='card'){
            setImportCardModal({ open: true, csvFile: csvFile });
        }else if(type === 'category'){
            setImportModal({ open: true, csvFile: csvFile });
        }

    };


    const importCSVApi = (csvFile,type) => {
        let token = ReactSession.get("token");

        var data = new FormData();

        data.append("file", csvFile);

        data.append("test_id", test_id);
        data.append("card_sorting_id", formValues.card_sorting_id);
        data.append("card_sorting", formValues.cardSorting);
        data.append("is_required_categories", formValues.isRequiredCategories);
        data.append("is_add_new_category", formValues.isAddNewCategory);


        if(type === 'card'){
            setCardImportLoading(true);

            importCardSortingCardCSVService(data, token).then((response) => {
                setCardImportLoading(false);

                if (response.success) {
                    setCardArray(response.data.cards);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });
        }else {
            setCategoryImportLoading(true);

            importCardSortingCategoryCSVService(data, token).then((response) => {
                setCategoryImportLoading(false);

                if (response.success) {
                    setCategoryArray(response.data.categories);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: "errortoast",
                            position: "bottom-center",
                        }
                    );
                }
            });
        }




    };
    return (
        <LayoutResearcherFullWidth
            fixed_header_target={true}
            isLoading={false}
            wrapClass={test && test.language == "ar" ? "arabic_wrapper_main" : ""}
            skipCheck={false}
            extendedFooter={false}
            activeMenu={""}
        >
            <div className="study-introduction-page-wrapper stydy-pages-wrapper study-review-page-wrapper pb-0">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <Topbar
                            test={test}
                            credits_required={test.credits_required}
                            onChange={(test) => {
                                setTest({ ...test });
                            }}
                            stepCallback={() => {
                                saveCardSortingData(formValues,false);
                            }}
                            status={test.status}
                            lastSaved={lastSaved}
                            path={"/researcher/tests/" + test_id + "/introduction"}
                            isLoading={false}
                            pageLoading={pageLoading}
                            title={test.test_name}
                        />
                    </div>
                </div>
                <div className="container inner-page-container">
                    <div className="study-steps-progress-wrap">
                        {(
                            <Progress
                                stepCallback={() => {
                                    saveCardSortingData(formValues,false);
                                }}
                                test={test}
                                completed_step={1}
                            />
                        )}
                    </div>
                    {pageLoading &&
                         <LoadingIcon />
                    }
                    {!pageLoading &&

                    <div className="target-group-data-wrapper card-sorting-page-data">
                        <div className="target-group-data-repeat">
                            <div className="targer-group-left-wrap">
                                <h3>Card sort type </h3>
                                <div className="full-width-radio">
                                    <div className="checkboxes radio_btn_class  d-flex">
                                        <Radiobtn
                                            isSelectedlabelBold={true}
                                            value={"open"}
                                            name={"card-sorting"}
                                            checked={
                                                formValues.cardSorting === "open"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    "isRequiredCategories": 0,
                                                    "isAddNewCategory": 0,
                                                    "cardSorting": e.target.value
                                                });
                                                //setCardArray([]);
                                                //setCategoryArray([]);
                                                setFormCustomField({'card': '', 'category': ''});
                                            }}
                                            label={<><b>Open card sorting</b> - Participants create and name their own
                                                categories</>}
                                        />
                                    </div>
                                </div>
                                {formValues.cardSorting === 'open' &&
                                <div className="selected-card-sort-option field1">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Checkbox
                                                value={1}
                                                checked={formValues.isRequiredCategories == 1 ? true : false}
                                                onChange={(e) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        "isRequiredCategories":
                                                            e.currentTarget.checked ? 1 : 0
                                                    });
                                                }}
                                                label="Require categories for all cards in this test"
                                            />
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="full-width-radio">
                                    <div className="checkboxes radio_btn_class  d-flex">
                                        <Radiobtn
                                            isSelectedlabelBold={true}
                                            value={"close"}
                                            name={"card-sorting"}
                                            checked={
                                                formValues.cardSorting === "close"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                setFormValues({
                                                    ...formValues,
                                                    "isRequiredCategories": 0,
                                                    "isAddNewCategory": 0,
                                                    "cardSorting": e.target.value
                                                });
                                                //setCardArray([]);
                                                //setCategoryArray([]);
                                                setFormCustomField({'card': '', 'category': ''});
                                            }}
                                            label={<><b>Closed card sorting</b> - You choose the categories that participants
                                                see</>}
                                        />
                                    </div>
                                </div>
                                {formValues.cardSorting === 'close' &&
                                <div className="selected-card-sort-option closed-card-selected field2">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Checkbox
                                                value={1}
                                                checked={formValues.isAddNewCategory == 1 ? true : false}
                                                onChange={(e) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        "isAddNewCategory":
                                                            e.currentTarget.checked ? 1 : 0
                                                    });
                                                }}
                                                label="Let users add their own categories"
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <Checkbox
                                                value={1}
                                                checked={formValues.isRequiredCategories == 1 ? true : false}
                                                onChange={(e) => {
                                                    setFormValues({
                                                        ...formValues,
                                                        "isRequiredCategories":
                                                            e.currentTarget.checked ? 1 : 0
                                                    });
                                                }}
                                                label="Require categories for all cards in this test"
                                            />
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Card sort type</h3>
                                <p>Choose between open and close card sorting and refine options according to the parameters of your test.</p>
                            </div>
                        </div>
                        {formValues.cardSorting === 'close' &&
                        <div className="target-group-data-repeat sort-card-name-wrap">
                            <div className="targer-group-left-wrap ">
                                <div className="profile-form-group">
                                    <h3 className="h3 font-inter d-flex align-items-center justify-content-between">
                                        <div className={"loader-wrap"}>Categories
                                            {categoryImportLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{marginLeft: "5px"}}
                                                ></i>
                                            )}
                                        </div>
                                        <button type="button" className="create-btn tree-import-btn">Import <span>
                                        <img src={
                                            process.env.REACT_APP_URL + "images/import-icon.svg"} alt="img"/>
                                    </span>
                                            <input type="file" ref={fileRef}
                                                   onChange={(e) => handleFileInput(e, 'category')}/>
                                        </button>
                                    </h3>
                                    <InputField
                                        label="Start typing category name..."
                                        onChange={(e) => setFormCustomField({
                                            ...formCustomField,
                                            category: e.target.value
                                        })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                addCategory(e);
                                            }
                                        }}
                                        value={formCustomField.category}
                                        maxLength="45"
                                        error={formError.category}
                                        error_class={formError.error_class}
                                        rtl={language == "ar" ? true : false}
                                        infoLabel={"Press ENTER to add a new category. You can also copy and paste content or import from a spreadsheet."}
                                    />
                                </div>
                                <div className="card-selected-category-wrap">
                                    <DragDropContext onDragEnd={(param) => {
                                        const srcI =
                                            param.source.index;
                                        const desI =
                                            param.destination.index;
                                        categoryArray.splice(
                                            desI,
                                            0,
                                            categoryArray.splice(
                                                srcI,
                                                1
                                            )[0]
                                        );
                                    }}>
                                        <Droppable droppableId="droppable-1">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {categoryArray && categoryArray.map((items, index) => {

                                                        return (
                                                            <Draggable
                                                                key={index}
                                                                draggableId={
                                                                    "draggable-" +
                                                                    index
                                                                }
                                                                index={index}
                                                            >
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => (
                                                                    <div
                                                                        className="ans-list-repeat selected-card-cat-repeat"
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div
                                                                            className="ans-icon " {...provided.dragHandleProps}>
                                                                            <img src={
                                                                                process.env.REACT_APP_URL + "images/drag-icon.svg"}
                                                                                 alt="img"/>
                                                                        </div>
                                                                        <span
                                                                            className={`card-cat-count`}>{index + 1}</span>
                                                                        <div className={`ans-data ${(language == 'ar')?'cairo-font':''}`}>{items.name}</div>
                                                                        <div className="ans-close-row"
                                                                             onClick={() => deleteCategory(index)}>
                                                                            <img src={
                                                                                process.env.REACT_APP_URL + "images/cross.svg"}
                                                                                 alt="img"/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                </div>

                                {categoryArray.length > 0 &&
                                <button className="mt-3 delete-all-category-btn" onClick={() => setCategoryArray([])}>
                                    Delete all categories <img
                                    src={process.env.REACT_APP_URL + "images/trash-black.svg"}/>
                                </button>
                                }
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Categories</h3>
                                <p>Create categories you would like your participants to group the cards into.
                                     You can also import from a document. Make sure to have a separate category name per row.</p>

                                <a
                                    href={
                                        process.env.REACT_APP_IMG_URL +
                                        "/docs/import-categorys-example.csv"
                                    }
                                    target="_blank"
                                    className="preview-example-link"
                                >Download CSV template</a>
                            </div>
                        </div>
                        }
                        <div className="target-group-data-repeat border-0 pb-0 sort-card-name-wrap">
                            <div className="targer-group-left-wrap border-0 pb-0">
                                <div className="profile-form-group">
                                    <h3 className="h3 font-inter d-flex align-items-center justify-content-between">
                                        <div className={"loader-wrap"}>
                                            Cards
                                            {cardImportLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{marginLeft: "5px"}}
                                                ></i>
                                            )}
                                        </div>
                                        <button type="button" className="create-btn tree-import-btn">Import
                                            <span> <img src={
                                                process.env.REACT_APP_URL + "images/import-icon.svg"} alt="img"/></span>
                                            <input type="file" ref={fileRef}
                                                   onChange={(e) => handleFileInput(e, 'card')}/></button>
                                    </h3>

                                    <InputField
                                        label="Start typing card name..."
                                        onChange={(e) => setFormCustomField({...formCustomField, card: e.target.value})}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                addCard(e);
                                            }
                                        }}
                                        value={formCustomField.card}
                                        maxLength="45"
                                        error={formError.card}
                                        error_class={formError.error_class}
                                        rtl={language == "ar" ? true : false}
                                        infoLabel={"Press ENTER to add a new card. You can also copy and past content or import from a CSV file."}
                                    />

                                    <div className="active-tiles-wrap">
                                        {cardArray && cardArray.map((items, index) => {
                                            return (
                                                <span className={`${(language == 'ar')?'cairo-font':''}`} key={index}>{items.name} <i className="fa fa-times-circle"
                                                                                  onClick={() => deleteCard(index)}
                                                                                  aria-hidden="true"></i></span>
                                            );
                                        })}

                                    </div>

                                    {cardArray.length > 0 &&
                                    <button className="mt-3 delete-all-category-btn" onClick={() => setCardArray([])}>
                                        Delete all cards <img
                                        src={process.env.REACT_APP_URL + "images/trash-black.svg"}/>
                                    </button>
                                    }
                                    <div className="button-wrap d-flex justify-content-end mt-64">
                                        <button id="r_create_step_2_build_next_btn" type="submit" onClick={handleSubmit}
                                                className={`button primary-btn ${(cardArray.length === 0 || (formValues.cardSorting === 'close' && categoryArray.length < 2)) ? 'disabled-button' : ''}`}>
                                            Next
                                            {cardSortingStepLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{marginLeft: "5px"}}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="target-group-info-repeat">
                                <h3>Cards</h3>
                                <p>Create the cards you want participants to categorise. You can also import from a CSV file. Make sure to have a separate card name per row. </p>
                                <a
                                    href={
                                        process.env.REACT_APP_IMG_URL +
                                        "/docs/import-card-example.csv"
                                    }
                                    target="_blank"
                                    className="preview-example-link"
                                >Download CSV template</a>
                            </div>
                        </div>

                    </div>
                    }



                </div>
            </div>
            <ConfirmModal
                confirm_message="Are you sure you want to import this file? This will remove all the categories that you've added."
                confirm_btn_title="YES"
                confirm_title="Import CSV"
                openModal={importModal.open}
                close={() => {
                    setImportModal({ ...importModal, open: false });
                    fileRef.current.value = "";
                }}
                confirm={() => {
                    setImportModal({ ...importModal, open: false });
                    importCSVApi(importModal.csvFile,'category');
                    fileRef.current.value = "";
                }}
            />
            <ConfirmModal
                confirm_message="Are you sure you want to import this file? This will remove all the cards that you've added."
                confirm_btn_title="YES"
                confirm_title="Import CSV"
                openModal={importCardModal.open}
                close={() => {
                    setImportCardModal({ ...importCardModal, open: false });
                    fileRef.current.value = "";
                }}
                confirm={() => {
                    setImportCardModal({ ...importCardModal, open: false });
                    importCSVApi(importCardModal.csvFile,'card');
                    fileRef.current.value = "";
                }}
            />
        </LayoutResearcherFullWidth>
    );
}
