var messages = {
  "An email has just been sent to your email address!":
    "An email has just been sent to your email address!",
  "Click on the link in the email to generate a new password":
    "Click on the link in the email to generate a new password",
  "Please enter a valid email": "Please enter a valid email",
  "Required field": "Required field",
  "Thanks for signing up!": "Thanks for signing up!",
  "Success! Your account has now been verified. Login to get started with UserQ!":
    "Success! Your account has now been verified. Login to get started with UserQ!",
  "You must agree to our Terms and Conditions and Privacy Policy":
    "You must agree to our Terms of Use and Privacy Policy",
  "Please fill required fields": "Please fill required fields",
  "Please enter a valid amount greater than or equal to 50":
    "Please enter a valid amount greater than or equal to 50",
  "Test moved successfully": "Test moved successfully",
  "Please select a project to move": "Please select a project to move",
  "User projects cann't be fetched": "User projects cann't be fetched",
  "Project Detail cann't be fetched": "Project Detail cann't be fetched",
  "Plan cann't be fetched": "Plan cann't be fetched",
  "Please accept Terms and conditions.": "Please accept Terms of Use.",
  "Your test has been published successfully.":
    "Your test has been published successfully.",
  "Please complete all steps before publishing the test":
    "Please complete all steps before publishing the test",
  "Please select a correct answer": "Please select a correct answer",
  "Free users can not access this feature":
    "Free users can not access this feature",
  "Finish editing earlier question.": "Finish editing earlier question.",
  "You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones.":
    "You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones.",
  "You are currently on a free plan. Upgrade your plan to add briefing questions.":
    "You are currently on a free plan. Upgrade your plan to add briefing questions.",
  "Thank you, your feedback has been recorded.":
    "Thank you, your feedback has been recorded.",
  "Selected Methodology Not Available": "Selected Methodology Not Available",
  "Please Select a project": "Please Select a project",
  "Method Not Available For Now!!": "Method Not Available For Now!!",
  "Your test has been published successfully.":
    "Your test has been published successfully.",
  "Looks like you have selected a wrong Correct Answer. Make sure that the Correct Answer for all tasks is the last child of a node.":
    "Looks like you have selected a wrong Correct Answer. Make sure that the Correct Answer for all tasks is the last child of a node.",
  "There might be some errors. Kindly check.":
    "There might be some errors. Kindly check.",
  "Please select answer before going ahead.":
    "Please select answer before going ahead.",
  "You can skip maximum of 2 tasks.":
    "Sorry – you can only skip two tasks per test",
  "Looks like you haven't entered a task for this test! Please add a task to move to the next steps.":
    "Looks like you haven't entered a task for this test! Please add a task to move to the next steps.",
  "Welcome to <a>user<b>Q</b></a>, your account has been created":
    "Welcome to <a>user<b>Q</b></a>, your account has been created",
  "You can add up to 5 child nodes only.":"You can add up to 5 child nodes only.",
  "Some required information for the tree testing is missing or incomplete. Please review and try again.":
    "Some required information for the tree testing is missing or incomplete. Please review and try again.",
  "There's some information we still need – please take a look to see if you missed anything!":
    "There's some information we still need – please take a look to see if you missed anything!",
    "No methodology selected":"No methodology selected",
};   
export default messages;