import React, { useEffect, useState } from "react";
import Layout from "./layouts/layout";
import { useNavigate,Link } from "react-router-dom";


export default function PageNotFound() {
	
	 useEffect(() => {
		 
		 document.title = process.env.REACT_APP_NAME + " - 404 error";
    }, []);
	
    return (
     <Layout isLoading={false} extended404Footer={false} skipCheckLogin={true}  wrapClass={"error-page-wrapper"}>
	

                    <div className="login-page-wrapper 404-err0r-wrap">
                        <div className="error-inner-404">
                            <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap">
								<div className="container">
									<div className="login-left-side">
										
										<h1 className="form-heading">
											   <> UMMMM! Well this is Strange </>
											

										</h1>

									</div>
								</div>
								<div className="full home-marquee-banner section">
									
									
									<div className="home-marque-banner-box">
										<div className="scrolling-box"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
										<div className="scrolling-box" aria-hidden="true"> Error 404</div>
									</div>
									
								</div>


								<div className="login-form-inner-data">
									
									<Link to={'/'} className="btn btn-block form-btn login-page-btn mt-0">
										Back to home
									</Link>
									
								</div>

							</div>
                        </div>
                    </div>
                </Layout>
    );
  }