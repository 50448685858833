import React from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import {
	getResearcherTestResultData,
	getTestParicipantsData,
} from "../../../services/researcher_test_results";
import toast, { LoaderIcon } from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import PieChart from "./components/pie_chart";
import NationalitiesFlags from "./components/nationalities_flags";
import BarChart from "./components/bar_chart";
import BubbleChart from "./components/bubble_chart";
import Checkbox from "../../layouts/elements/fields/checkbox";
import AreaChart from "./components/area_chart";
import {
	atom,
	useRecoilState,
} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import { propTypes } from "react-bootstrap/esm/Image";


export default function TestResultParticipants() {
	let { test_id } = useParams();
	const navigate = useNavigate();
	const parRef = useRef();

	const [isLoading, setLoading] = useState(false);

	const [sectionLoading, setSectionLoading] = useState(false);
	const [showParticipantNotification, setShowParticipantNotification] = useState(true);

	const testState = atom({
		key: 'testresult-' + test_id,
		default: ""
	});
	const planState = atom({
		key: 'testresultplan-' + test_id,
		default: ""
	});
	const partiState = atom({
		key: 'testresultparti-' + test_id,
		default: null
	});
	const gender_colors =["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(194, 194, 210)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(150, 255, 252)", "rgb(150, 155, 255)", "rgb(130, 255, 252)"];

	const age_colors =[ "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(130, 255, 252)", "rgb(150, 155, 255)","rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(194, 194, 210)"];

	const tech_colors =[ "rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)","rgb(194, 194, 210)", "rgb(85, 86, 111)", "rgb(119, 225, 254)", "rgb(150, 155, 255)", "rgb(130, 255, 252)"];

	const [test, setTest] = useRecoilState(testState);
	const [planType, setPlanType] = useRecoilState(planState);
	const [parti, setParti] = useRecoilState(partiState);
	const [term, setTerm] = useState("Monthly");
	const [collapse, setCollapse] = useState({ "apps": false, "nationalities": false, "residency": false, "education": false, "technology": false, "employment": false, "industry": false, "department": false, "household": false })

	const getParticipants = () => {

		//if (!parti) {
			let token = ReactSession.get("token");

			setSectionLoading(true);

			getTestParicipantsData({ test_id: test_id }, token).then((response) => {
				setSectionLoading(false);

				if (response.success) {
					setParti(response.data);
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: response.success ? "successtoast" : "errortoast",
							position: "bottom-center",
							duration: 2000,
						}
					);
				}
			});
		//}
	};

	const getTestafterEndTest = () => {

		let token = ReactSession.get("token");

		getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
			setLoading(false);

			if (response.success) {
				setTest(response.test);
			} else {
				navigate("/dashboard");
				toast(
					<div className="toastinner">
						{response.message}
						<a onClick={() => {
							toast.dismiss();
						}}>&times;</a>
					</div>,
					{
						className: (response.success) ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		})

	}

	const getTest = () => {

		if (!test) {
			let token = ReactSession.get("token");

			setLoading(true);

			getResearcherTestResultData({ test_id: test_id }, token).then(
				(response) => {
					setLoading(false);

					if (response.success) {
						setTest(response.test);

						getParticipants();
					} else {
						toast(
							<div className="toastinner">
								{response.message}
								<a
									onClick={() => {
										toast.dismiss();
									}}
								>
									&times;
								</a>
							</div>,
							{
								className: response.success ? "successtoast" : "errortoast",
								position: "bottom-center",
								duration: 2000,
							}
						);
					}
				}
			);
		} else {
			getParticipants();
		}
	};

	const getPlan = () => {

		if (!planType) {
			let token = ReactSession.get("token");

			setLoading(true);

			getCurrentPlansService(token).then((response) => {
				setLoading(false);

				if (response.success) {
					setPlanType(response.plan.type);

					getTest();
				} else {
					toast(
						<div className="toastinner">
							{response.message}
							<a
								onClick={() => {
									toast.dismiss();
								}}
							>
								&times;
							</a>
						</div>,
						{
							className: response.success ? "successtoast" : "errortoast",
							position: "bottom-center",
							duration: 2000,
						}
					);
				}
			});
		} else {
			getTest();
		}
	};

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Test Result Participants";
		getPlan();
	}, []);
	const renderTechnology = () => {

		var technology = [];

		for (var key in parti.hours) {

			technology.push(parti.hours[key])


		}
		for (var key in parti.devices) {

			technology.push(parti.devices[key]);


		}
		if (technology.length > 0) {

			return (
				<div className="confidence-with-tch-graph-wrap">
					<div className="confid-tech-devices-sec">
						<p className="confidance-heading-wrap">
							Favourite devices
						</p>

						<div className="fav-devices-list-wrap">
							{renderDevices()}

						</div>
					</div>
					<div className="confid-tech-graph-lhs">
						<p className="confidance-heading-wrap">
							AVERAGE TIME SPENT ONLINE PER DAY
						</p>

						<div className="confid-tech-hraph-hold">
							{renderHoursPie()}
						</div>
					</div>
				</div>);
		} else {
			return <div className="info-not-available">No info available</div>
		}

	}
	const renderHoursPie = () => {

		var hours = [];



		for (var key in parti.hours) {

			hours.push(parti.hours[key])


		}
		if (hours.length > 0) {
			return <>
				<PieChart txtcolors={{ "1-3 hours": "black", "4-6 hours": "white", "more than 6 hours": "black", "Unknown": "black" }} colors={{ "1-3 hours": tech_colors[0], "4-6 hours": tech_colors[1], "more than 6 hours": tech_colors[2], "Unknown": tech_colors[3] }} data={parti.hours} width={300} height={300} animate={true} />
				<div className="chart-info-wrap">
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[0] }}></span>
						<p>1-3 hours</p>
					</div>
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[1] }}></span>
						<p>4-6 hours</p>
					</div>
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[2] }}></span>
						<p>more than 6 hours</p>
					</div>
					<div className="chart-info-repeat">
						<span style={{ "display": "inline-block", "backgroundColor": tech_colors[3] }}></span>
						<p>Unknown</p>
					</div>
				</div>
			</>;
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderDevices = () => {
		var devices = [];

		var index = 0;

		for (var key in parti.devices) {

			devices.push((
				<div key={"device" + key} className={`device-list-repeat ${index == 0 ? 'active' : ''} `}>
					<div className="tech-icon-wname">
						{renderDeviceIcon(key)}
						<span>{key}</span>
					</div>
					<div className="device-selected-count">{parti.devices[key]}</div>
				</div>
			))
			index++;

		}

		if (devices.length > 0) {
			return devices;
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderDeviceIcon = (device) => {
		if (device == "Desktop") {
			return <img src={process.env.REACT_APP_URL + "images/desk-top-icon.svg"} alt="img" />
		}
		if (device == "Mobile") {
			return <img src={process.env.REACT_APP_URL + "images/mob-icon.svg"} alt="img" />
		}
		if (device == "Smartphone") {
			return <img src={process.env.REACT_APP_URL + "images/mob-icon.svg"} alt="img" />
		}
		if (device == "Tablet") {
			return <img src={process.env.REACT_APP_URL + "images/tab-licon.svg"} alt="img" />
		}
		if (device == "Unknown") {
			return <img src={process.env.REACT_APP_URL + "images/unknown-icon.svg"} alt="img" />
		}
	}
	const renderBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.education;

		//var data = {"A":1,"B":2,"C":3,"D":4,"E":4,"F":8,"G":8,"H":8};

		var colors = [
			{ color: "rgb(119, 225, 254)", text: "" },
			{ color: "rgb(89, 89, 114)", text: "white-text" },
			{ color: "rgb(150, 255, 252)", text: "" },
			{ color: "rgb(47, 46, 85)", text: "white-text" },
			{ color: "rgb(255, 102, 173)", text: "white-text" },
			{ color: "rgb(165, 159, 255)", text: "" },
			{ color: "rgb(194, 194, 210)", text: "" }
		];

		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}
		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;
			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color,
				percentage_label: percentage_label + "%"
			});
			index++;

			bars.push((
				<div className="edu-graph-data-repeat">
					<div className="edu-grph-data-lhs">
						<span>{key}</span>
						<b>-</b>
					</div>
					<div className={`edu-grph-data-rhs `}>
						<div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
							style={{ width: percentage + "%" }}
						>
							<div
								flow="down"
								className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
								tooltip={data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}
								style={{ backgroundColor: color.color }}
							>

							</div>
							<span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg industry-graph"><div className="edu-graph-inner-hold"> {bars}</div></div>;
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderEmploymentBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.employment;

		//var data = {"A":1,"B":2,"C":3,"D":4,"E":4,"F":8,"G":8,"H":8};

		var colors = [
			{ color: "rgb(255, 183, 183)", text: "" },
			{ color: "rgb(119, 225, 254)", text: "" },
			{ color: "rgb(89, 89, 114)", text: "white-text" },
			{ color: "rgb(150, 255, 252)", text: "" },
			{ color: "rgb(47, 46, 85)", text: "white-text" },
			{ color: "rgb(255, 102, 173)", text: "white-text" },
			{ color: "rgb(165, 159, 255)", text: "" },
			{ color: "rgb(194, 194, 210)", text: "" }
		];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];

		}
		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

			var color = colors[7];
			if (index <= 7) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color.color,
				textcolor: color.text,
				percentage_label: percentage + "%"
			});

			index++;

			bars.push((
				<div className="edu-graph-data-repeat">
					<div className="edu-grph-data-lhs">
						<span>{key}</span>
						<b>-</b>
					</div>
					<div className={`edu-grph-data-rhs `}>
						<div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
							style={{ width: percentage + "%" }}
						>
							<div
								className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
								flow="down"
								tooltip={data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}
								style={{ backgroundColor: color.color }}
							>

							</div>

							<span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><div className="edu-graph-inner-hold"> {bars}</div></div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderPeopleBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.people;

		var colors = [
			{ color: "rgb(119, 225, 254)", text: "" },
			{ color: "rgb(89, 89, 114)", text: "white-text" },
			{ color: "rgb(150, 255, 252)", text: "" },
			{ color: "rgb(47, 46, 85)", text: "white-text" },
			{ color: "rgb(255, 102, 173)", text: "white-text" },
			{ color: "rgb(165, 159, 255)", text: "" },
			{ color: "rgb(194, 194, 210)", text: "" }
		];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}

		var bars = [];

		for (var key in data) {
			var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;

			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				value: data[key],
				color: color,
				percentage_label: percentage + "%"
			});

			index++;

			bars.push((
				<div className="edu-graph-data-repeat">
					<div className="edu-grph-data-lhs">
						<span>{key}</span>
						<b>-</b>
					</div>
					<div className={`edu-grph-data-rhs `}>
						<div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
							style={{ width: percentage + "%" }}
						>
							<div
								className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
								flow="down"
								tooltip={data[key] > 1 ? data[key] + " Participants" : data[key] + ' Participant'}
								style={{ backgroundColor: color.color }}
							>

							</div>

							<span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
						</div>
					</div>
				</div>
			))
		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><div> {bars}</div></div>;
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderIndustryBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.industry;

		var colors = ["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(194, 194, 210)", "rgb(150, 155, 255)"];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}
		var bars = [];

		for (var key in data) {
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100)) : 0;
			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				percentage: percentage_label,
				value: data[key],
				color: color,
				percentage_label: percentage_label + "%"
			});

			index++;

		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><BarChart data={lChartData} /></div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderIncomeBarChart = () => {
		var lChartData = [];

		var index = 0;

		var data = parti.income;

		var colors = ["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(194, 194, 210)", "rgb(150, 155, 255)"];


		var max = 0;
		for (var key in data) {

			if (data[key] > max) {
				max = data[key];
			}
		}
		var sum = 0;
		for (var key in data) {

			sum += data[key];
		}
		var bars = [];

		for (var key in data) {
			var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100)) : 0;
			var color = colors[6];
			if (index < 6) {

				color = colors[index];
			}

			lChartData.push({
				label: key,
				percentage: percentage_label,
				value: data[key],
				color: color,
				percentage_label: percentage_label + "%"
			});

			index++;

		}
		if (lChartData.length > 0) {
			return <div className="edu-label-graph-bg"><BarChart marginLeft={30} data={lChartData} /></div>;

		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderDepartments = () => {
		var departments = [];

		var classes = {
			"Finance": "finance", "HR": "hr-depart", "Admin": "admin-depart", "Design & research": "ds-depart", "IT & tech": "it-tech-depart", "Branding & Marketing": "bm-depart",
			"Sales": "sales-depart", "Purchase": "purchase-depart", "Legal": "legal-depart", "Others": "other-depart", "Unknown": ""
		};
		var index = 0;
		for (var key in parti.department) {
			var bigClass = '';

			if (index == 0) {
				bigClass = 'active';
			}
			departments.push((
				<div className={'na-name-wrap ' + bigClass + ' ' + classes[key]}>
					<span className="flag-hold"></span>
					<p className={`country-name-txt ${key.length > 8 ? 'ellipsText' : ''}`} tooltip={`${key.length > 8 ? key : ''}`}>
						<span>{key}</span>
					</p>
					<span className="na-user-count">{parti.department[key]}</span>
				</div>
			));
			index++;
		}
		if (departments.length > 0) {
			return <div className="nationalities-list-wrap department-selected-analyze">{departments}</div>;
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderAppsBubbleChart = () => {

		var lChartData = [];

		var index = 0;

		var data = parti.apps;

		//var data = {"Shooping":9,"News":5,"Gamees":4};

		var colors = ["rgb(255, 102, 173)", "rgb(244, 158, 204)", "rgb(243, 160, 206)", "rgb(243, 160, 206)", "rgb(244, 159, 205)", "rgb(252, 117, 182)", "rgb(249, 131, 189)"];



		for (var key in data) {
			var color = colors[6];
			if (index < 6) {

				color = colors[index];

			}
			lChartData.push({
				label: key,
				value: data[key],
				color: color
			});
			index++;

		}
		if (lChartData.length > 0) {
			return (
				<div className="most-usedapp-graph-wrap"><BubbleChart data={{ "children": lChartData }} width={700} height={700} /></div>
			);
			//<BarChart data={lChartData} width={300} height={300} />
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderNationalities = () => {


		if (parti.nationalities && parti.nationalities.length > 0) {
			return <div className="nationalities-list-wrap"><NationalitiesFlags data={parti.nationalities} /></div>
		} else {
			return <div className="info-not-available">No info available</div>
		}
	}
	const renderAreaChart = () => {

		if ((parti.income && parti.income[term])) {

			var lChartData = parti.income[term];

			var data = [];

			data = [];

			//lChartData = [0,2,3,5,6,7,7,8,9,10];

			//lChartData.sort();

			let mean = getMean(lChartData);
			let stdDev = getStandardDeviation(lChartData);

			//getStdDeviation(Math.min(...lChartData), Math.max(...lChartData));

			//stdDev = 2;
			let points = lChartData;

			let seriesData = points.map(x => ({ x: x, y: normalY(x, mean, stdDev) }));



			let lineData = [
				[{ x: mean - 3 * stdDev, y: 0, "label": "2.1%", y0: 0 }, { x: mean - 3 * stdDev, y: normalY(mean - 3 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean - 2 * stdDev, y: 0, "label": "13.6%", y0: 0 }, { x: mean - 2 * stdDev, y: normalY(mean - 2 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean - 1 * stdDev, y: 0, "label": "34.1%", y0: 0 }, { x: mean - 1 * stdDev, y: normalY(mean - 1 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean, y: 0, "label": "0%", y0: 0 }, { x: mean, y: normalY(mean, mean, mean), "label": "34.1%", y0: mean }],
				[{ x: mean + 1 * stdDev, y: 0, "label": "34.1%", y0: 0 }, { x: mean + 1 * stdDev, y: normalY(mean + 1 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean + 2 * stdDev, y: 0, "label": "13.6%", y0: 0 }, { x: mean + 2 * stdDev, y: normalY(mean + 2 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }],
				[{ x: mean + 3 * stdDev, y: 0, "label": "2.1%", y0: 0 }, { x: mean + 3 * stdDev, y: normalY(mean + 3 * stdDev, mean, stdDev), "label": "34.1%", y0: mean }]
			];

			lineData.forEach(function (item) {
				seriesData.push({ x: item[1].x, y: item[1].y });
			})
			for (var i = 0; i < seriesData.length - 1; i++) {

				for (var j = i + 1; j < seriesData.length - 1; j++) {
					if (seriesData[i].x > seriesData[j].x) {
						var temp = seriesData[j];

						seriesData[j] = seriesData[i];

						seriesData[i] = temp;

					}
				}
			}

			if (lChartData.length > 0) {
				return (
					<div className="edu-label-graph-bg mt-32" ref={parRef}><AreaChart pwidth={parRef.current && parRef.current.getBoundingClientRect().width - 70} lineData={lineData} data={seriesData} /></div>
				);

			} else {
				return <div className="info-not-available mt-32">No info available</div>
			}
		} else {
			return <div className="info-not-available mt-32">No info available</div>
		}
	}
	const getMean = (arr) => {
		var sum = 0;

		for (var i = 0; i < arr.length; i++) {
			sum += arr[i];
		}
		return sum / arr.length;
	}
	const normalY = (x, mean, stdDev) => Math.exp((-0.5) * Math.pow((x - mean) / stdDev, 2));

	function getStandardDeviation(array) {
		const n = array.length
		const mean = array.reduce((a, b) => a + b) / n
		return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
	}

	const callbackTopbar = (data) => {
		getTest();
		getTestafterEndTest();
	}


	return (
		<LayoutResearcherFullWidth
			fixed_header_target={true}
			isLoading={false}
			wrapClass={"researcher-test-view-overflow overflow-visible"}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
		>

			{true && (
				<div className="analyze-results-page-wrapper">
					{isLoading &&
						<div className="page-min-height">
							<LoadingIcon />
						</div>
					}
					{!isLoading && test &&
						<>
							<Topbar test={test} callbackTopbar={callbackTopbar} />

							<div className="container inner-page-container">
								<div className="sp5-content-wrap analyze-overflow-wrap">
									<Sidebar
										test={test}
										planType={planType}
										activeMenu="participants"
									/>

									<div className="sp5-right-side-wrap">
										{sectionLoading &&
											<LoadingIcon />
										}
										{!sectionLoading && parti &&
											<>
												{parti && parti.testSharedTargetGroupWithAllGuest ?
													<div className="participants-overview-data-wrap">
														<div className="parti-overflow-top-graph-wrap shared-target-group-participants">
															<h3 className={`h3 font-inter mb-32`}>
																There are no demographic details available,
																as the test was shared with participants outside of the UserQ panel.
															</h3>

															<div className="participants-notification mb-32">
																<div className="profile-complete-progress-wrap notify-info w-100">
																	<div className="pro-lft-wrap">
																		<strong>

																			Looking for detailed analytics like the one below?
																			Try our <a className={"target-group-participants-notify pink-color"} href="https://userq.com/panel/" target={"_blank"}>UserQ Panel</a> with thousands of participants and data points.
																		</strong>
																	</div>
																</div>
															</div>

															<div className="shared-target-group-participants-img">
																<img src={process.env.REACT_APP_URL + "images/test-links-participants-test-result.png"} className={"w-100"} />
															</div>

														</div>
													</div>
													:
													<div className="participants-overview-data-wrap">
														<div className="parti-overflow-top-graph-wrap">
															<h3 className={`h3 font-inter mb-32`}>
																Participants overview
															</h3>

															{showParticipantNotification && parti && !parti.totalUserTestGivenMatchedTargetGroup && parti.target_group === 'target-group' &&
																<div className="participants-notification mb-32">
																	<div className="profile-complete-progress-wrap notify-info w-100">
																		<div className="pro-lft-wrap">
																			<strong>
																				Unknown users are those from outside the UserQ Panel.
																				If you directly shared the test link with others, you
																				might also see participants outside your target
																				demographic group.
																			</strong>
																		</div>
																		<div className="pro-right-wrap" onClick={()=>{setShowParticipantNotification(false) }}>
																			<div className="delete-progress-row">
																				<img src={process.env.REACT_APP_URL + "images/cross.svg"}
																					alt="img" />
																			</div>
																		</div>
																	</div>
																</div>
															}

															{showParticipantNotification && parti && !parti.totalUserTestGivenMatchedTargetGroup && parti.target_group === 'random-testers' &&
															<div className="participants-notification mb-32">
																<div className="profile-complete-progress-wrap notify-info w-100">
																	<div className="pro-lft-wrap">
																		<strong>
																			Unknown users are those from outside the UserQ Panel.
																			If you directly shared the test link with others, you
																			might also see participants outside your target
																			demographic group.
																		</strong>
																	</div>
																	<div className="pro-right-wrap" onClick={()=>{setShowParticipantNotification(false) }}>
																		<div className="delete-progress-row">
																			<img src={process.env.REACT_APP_URL + "images/cross.svg"}
																				 alt="img" />
																		</div>
																	</div>
																</div>
															</div>
															}

															{parti && (parti.gender || parti.age) &&
																<div className="row">
																	{parti.gender &&
																		<div className="col-md-6">
																			<p>Gender</p>
																			<div className="parti-ga-grapg-area">
																				{!Array.isArray(parti.gender) &&
																				
																					<>
																						<PieChart txtcolors={{ "Male": "black", "Female": "white", "Unknown": "black" }} colors={{ "Male": gender_colors[0], "Female": gender_colors[1], "Unknown": gender_colors[2] }} data={parti.gender} width={300} height={300} animate={false} />
																						<div className="chart-info-wrap">
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": gender_colors[0] }}></span>
																								<p>Male</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": gender_colors[1] }}></span>
																								<p>Female</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": gender_colors[2] }}></span>
																								<p>Unknown</p>
																							</div>
																						</div>
																					</>
																				}
																				{Array.isArray(parti.gender) && parti.gender.length == 0 &&
																					<div className="info-not-available">No info available</div>
																				}
																			</div>
																		</div>
																	}
																	{parti.age &&
																		<div className="col-md-6">
																			<p>Age</p>
																			<div className="parti-ga-grapg-area">

																				{!Array.isArray(parti.age) &&
																					<>
																						<PieChart txtcolors={{ "Less than 18": "white", "18-25": "black", "26-35": "black", "36-45": "black", "46-55": "black", "56-65": "white", "More than 65": "black", "Unknown": "black" }} colors={{ "Less than 18": age_colors[0], "18-25": age_colors[1], "26-35": age_colors[2], "36-45": age_colors[3], "46-55": age_colors[4], "56-65": age_colors[5], "More than 65": age_colors[6], "Unknown": age_colors[7] }} data={parti.age} width={300} height={300} animate={false} />
																						<div className="chart-info-wrap">
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[0] }}></span>
																								<p>Less than 18</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[1] }}></span>
																								<p>18-25</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[2] }}></span>
																								<p>26-35</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[3] }}></span>
																								<p>36-45</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[4] }}></span>
																								<p>46-55</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[5] }}></span>
																								<p>56-65</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[6] }}></span>
																								<p>More than 65</p>
																							</div>
																							<div className="chart-info-repeat">
																								<span style={{ "display": "inline-block", "backgroundColor": age_colors[7] }}></span>
																								<p>Unknown</p>
																							</div>
																						</div>
																					</>
																				}
																				{Array.isArray(parti.age) && parti.age.length == 0 &&
																					<div className="info-not-available">No info available</div>
																				}
																			</div>
																		</div>
																	}

																</div>
															}
															{parti && (!parti.gender && !parti.age) &&
																<div className="row">

																	<div className="col-md-6">
																		<div className="info-not-available">No info available</div>
																	</div>

																</div>
															}
														</div>

														<div className="partis-nationalities-wrap partis-row-repeat">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.nationalities ? 'closed-sec' : ''}  `} >Nationalities
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'nationalities': !collapse.nationalities }) }}>
																	{!collapse.nationalities &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.nationalities &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.nationalities &&
																<div>
																	{renderNationalities()}
																</div>
															}
														</div>

														<div className="partis-nationalities-wrap partis-row-repeat pb-0">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.residency ? 'closed-sec' : ''}  `}>
																Residency
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'residency': !collapse.residency }) }} >
																	{!collapse.residency &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.residency &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.residency &&
																<>
																	{parti && (parti.countries.emirates.countries.length > 0 && parti.countries.ksa.countries.length > 0 && parti.countries.others.countries.length > 0) &&
																		<>
																			{parti.countries.emirates.countries.length > 0 && parti.countries.emirates.total_count > 0 &&
																				<div className="res-country-wrap">
																					<div className="rs-country-block">
																						<div className="main-ctr-row">
																							<div className="na-name-wrap ae">
																								<span className="flag-hold">
																									<img src={process.env.REACT_APP_URL + "images/flag_icons/ae.svg"} />
																								</span>
																								<p className="country-name-txt">
																									<span>UAE</span>
																								</p>
																							</div>
																							<h5>{parti.countries.emirates.total_count}</h5>
																						</div>
																						<div className="sp5-country-inr-repeat-wrap">
																							<ul>
																								{parti.countries.emirates.countries.map(function (item, index) {
																									return (<li key={"emic" + item.country} className={`${index == 0 ? 'active' : ''}`}>
																										<span>{item.country}</span> <b>{item.count}</b>
																									</li>);
																								})}
																							</ul>
																						</div>
																					</div>
																				</div>
																			}
																			{parti.countries.ksa.countries.length > 0 && parti.countries.ksa.total_count > 0 &&
																				<div className="res-country-wrap">
																					<div className="rs-country-block">
																						<div className="main-ctr-row">
																							<div className="na-name-wrap ae">
																								<span className="flag-hold">
																									<img src={process.env.REACT_APP_URL + "images/flag_icons/sa.svg"} />
																								</span>
																								<p className="country-name-txt">
																									<span>KSA</span>
																								</p>
																							</div>
																							<h5>{parti.countries.ksa.total_count}</h5>
																						</div>
																						<div className="sp5-country-inr-repeat-wrap">
																							<ul>
																								{parti.countries.ksa.countries.map(function (item, index) {
																									return (<li key={"ksa" + item.country} className={`${index == 0 ? 'active' : ''}`}>
																										<span>{item.country}</span> <b>{item.count}</b>
																									</li>);
																								})}

																							</ul>
																						</div>
																					</div>
																				</div>
																			}
																			{parti.countries.others.countries.length > 0 &&
																				<div className="res-country-wrap">
																					<div className="rs-country-block">
																						<div className="sp5-country-inr-repeat-wrap">
																							<ul>
																								{parti.countries.others.countries.map(function (item, index) {
																									return (
																										<li key={"otherc" + item.country} className={`${index == 0 ? 'active' : ''}`}>

																											<div className="main-ctr-row w-100 mb-0">
																												<div className="na-name-wrap ae">
																													<span><img src={process.env.REACT_APP_URL + "images/flag_icons/" + (item.short_code != null ? item.short_code.toLowerCase() : '') + ".svg"} /></span>
																													<p className="country-name-txt">
																														<span>{item.country}</span>
																													</p>
																												</div>
																												<h5>{item.count}</h5>
																											</div>
																										</li>);
																								})}
																							</ul>
																						</div>
																					</div>
																				</div>
																			}
																		</>
																	}
																	{parti && (parti.countries.emirates.total_count == 0 && parti.countries.ksa.total_count == 0 && parti.countries.others.countries.length == 0) &&
																		<div className="info-not-available">No info available</div>
																	}
																</>
															}
														</div>

														<div className="partis-row-repeat sp5-highest-label-edu-graph">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.education ? 'closed-sec' : ''}  `}>
																Highest level of education
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'education': !collapse.education }) }}>
																	{!collapse.education &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.education &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.education &&
																<div className="eduChart">

																	<div>
																		{renderBarChart()}
																	</div>
																</div>
															}
														</div>

														<div className="partis-row-repeat sp5-highest-label-edu-graph mt-32">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between ${collapse.technology ? 'closed-sec' : ''} `}>
																Confidence with technology
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'technology': !collapse.technology }) }}>
																	{!collapse.technology &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.technology &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.technology &&
																<div>

																	<>
																		{renderTechnology()}
																	</>


																</div>
															}
														</div>

														<div className="partis-row-repeat sp5-highest-label-edu-graph mt-32">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.apps ? 'closed-sec' : ''}  `}>
																Most apps used daily
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'apps': !collapse.apps }) }}>
																	{!collapse.apps &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.apps &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.apps &&
																<div >

																	{renderAppsBubbleChart()}
																</div>
															}
														</div>

														<div className="partis-row-repeat sp5-highest-label-edu-graph">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.employment ? 'closed-sec' : ''}  `}>
																Current employment status
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'employment': !collapse.employment }) }}>
																	{!collapse.employment &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.employment &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.employment &&

																<div>
																	<div>
																		{renderEmploymentBarChart()}
																	</div>
																</div>
															}
														</div>

														<div className="partis-row-repeat sp5-highest-label-edu-graph industry-chart-wrapper">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.industry ? 'closed-sec' : ''}  `}>
																Industry
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'industry': !collapse.industry }) }}>
																	{!collapse.industry &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.industry &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.industry &&
																<div className="industryChart">
																	{renderIndustryBarChart()}
																</div>
															}
														</div>

														<div className="department-analyze-wrap partis-row-repeat">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.department ? 'closed-sec' : ''}  `}>
																Department
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'department': !collapse.department }) }}>
																	{!collapse.department &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.department &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.department &&
																<div >
																	{renderDepartments()}

																</div>
															}
														</div>

														<div className="partis-row-repeat sp5-household-label-edu-graph">
															<h3 className={`h3 font-inter mb-32 d-flex justify-content-between  ${collapse.household ? 'closed-sec' : ''}  `}>
																Household
																<span className="close-row" onClick={() => { setCollapse({ ...collapse, 'household': !collapse.household }) }}>
																	{!collapse.household &&
																		<img src={process.env.REACT_APP_URL + "images/minus-icon.svg"} />
																	}
																	{collapse.household &&
																		<img src={process.env.REACT_APP_URL + "images/plus-icon-black.png"} />
																	}
																</span>
															</h3>
															{!collapse.household &&
																<>
																	<div>
																		{renderPeopleBarChart()}
																	</div>

																	<div className="household-income-graph">
																		<div className="house-hold-income-top d-flex justify-content-between align-items-center">
																			<p className="confidance-heading-wrap  mb-0">
																				Household income $
																			</p>
																			{/*(parti.income && parti.income[term]) &&
		
																			<div className="d-flex">
																				<Checkbox className={"plan-change-switch"} beforeLabel="Monthly" label={"Yearly"} value={"Monthly"} name="term" checked={term=="Yearly"?true:false} onChange={(e)=>{setTerm(term=='Monthly'?'Yearly':'Monthly')}} />
																			</div>
																	*/}
																		</div>

																		<div className="industryChart">
																			{renderIncomeBarChart()}
																			{/*renderAreaChart()*/}
																		</div>
																	</div>
																</>
															}
														</div>
													</div>
												}
											</>

										}
									</div>
								</div>
							</div>
						</>
					}
				</div>
			)}
		</LayoutResearcherFullWidth>
	);
}
