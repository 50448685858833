export default function redirectToStep({current_step, saved_step, test_id, navigate, test}) {
    if(current_step > saved_step+1){
        if(saved_step==0){
            navigate("/researcher/tests/"+test_id+"/introduction")
        }
        if(saved_step==1){
            if(test.methodology=="Tree Test"){
                navigate("/researcher/tests/"+test_id+"/tree-sort-test")
            }
            if(test.methodology=="Preference Test"){
                navigate("/researcher/tests/"+test_id+"/preference-test")
            }
            
        }
        if(saved_step==2){
            navigate("/researcher/tests/"+test_id+"/conclusions")
        }
        if(saved_step==3){
            navigate("/researcher/tests/"+test_id+"/target-group")
        }
        if(saved_step==4){
            navigate("/researcher/tests/"+test_id+"/review")
        }
        if(saved_step==5){
            navigate("/researcher/tests/"+test_id+"/publish")
        }
    }
} 
