import React from "react";
import { Modal } from 'react-bootstrap';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from "../../../lib/secure_reactsession";
import { Link } from 'react-router-dom';


export default function SubscriptionCompleteModal(props) {

    
    const renderTitle = () => {
        if(ReactSession.get("show_pro_subscribed")  == 1){
            return "Welcome to UserQ Pro";
        } else {
            return "You’re good to go!";
        }
    }
    const renderMessage = () => {
        if(ReactSession.get("show_pro_subscribed")  == 1){
            // return <p>Congratulations. You are now on the Pro plan. Happy testing!</p>;
            return (
              <>
                <p>
                  Congratulations on completing your registration and
                  subscription! You can now start creating, running and
                  analysing user research tests using the credits available in
                  your account.
                </p>
                <p>
                  If you would like to get even more access to our platform,
                  upgrade your subscription anytime by visiting the{" "}
                  <Link to="/researcher/billing">Plan & Billing</Link> page and
                  clicking on'Upgrade Plan'
                </p>
              </>
            );
        } else {
            return (
              <>
                <p>
                  Welcome to UserQ. You can start running tests straight away. 
                </p>
                 {ReactSession.get("user").permission !== "Editor"  &&
                        <p>
                        Remember, you only need to pay for what you use, and there are no publishing fees for the first 30 days.  You can <Link to="/researcher/credits">buy extra credits</Link> at any time. 

                        

                        </p>
                }
                <p>
                  Happy testing!
                </p>
              </>
            );
        }
    }
    return (
        <Modal show={props.openModal} centered  className="fade custom-modal-wrap  plan-change-modal">
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text">
                    <button type="button" className="close" onClick={()=>{ props.closeAction(); } } aria-label="Close">
                        <span aria-hidden="true"><img src={process.env.REACT_APP_URL+"images/cross.svg"} alt="cross"/></span>
                    </button>
                    <div className={"change-plan-modal-data"}>
                        <div className="plan-modal-icon">
                            <img src={process.env.REACT_APP_URL+"images/plan-modal-icon.png"} />
                        </div>
                        <h2 className="h2">{renderTitle()} </h2>
                        {renderMessage()}
                    </div>
                    <div className="create-project-form-btn modal-flex-button">
                        {ReactSession.get("user").permission !== "Editor" &&
                            <button type="button" className="btn form-btn secondary-btn" onClick={(e) => {
                                props.secAction();
                            }} aria-label="Close">
                                Buy Credits
                            </button>
                        }
                        <button type="button" className="btn form-btn" onClick={()=>{ props.primaryAction(); } } aria-label="Close">
                            Go to Dashboard
                        </button> 
                    </div>  
                </div>
            </Modal.Body>
        </Modal>
    )
}