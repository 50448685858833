import React from "react";
import {  useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef,useCallback  } from "react";
import { Link } from 'react-router-dom';
import {
	getResearcherTestResultData,
	getTestTreeTestData,
    getTestTreeTestTaskData,
    getFirstClickData,
    getTreeChartData
} from "../../../services/researcher_test_results";
import toast, { LoaderIcon } from "react-hot-toast";
import Topbar from "./components/topbar";
import Sidebar from "./components/sidebar";
import { getCurrentPlansService } from "../../../services/plans";
import moment from "moment";
import {Dropdown} from "react-bootstrap";
import LinearAxisChart from "./components/linear_axis_chart";
import { calculateTime } from "../../../lib/helpers";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import SankeyChart from "./components/sankey_chart";
import {atom, useRecoilState, useRecoilValueLoadable_TRANSITION_SUPPORT_UNSTABLE} from 'recoil';
import { LoadingIcon } from "../../loader/loadingIcon";
import DeleteAnswer from "./dialog/hide-participant";
import ReportAnswer from "./dialog/report-answer";
import exportAsImage from "../../../lib/exportAsImage";
import { downloadBlob } from "../../../lib/helpers";
import Checkbox from "../../layouts/elements/fields/checkbox";

export default function TestResultTreeTest() {
	let { test_id } = useParams();

    const exportRef = useRef();

	const [isLoading, setLoading] = useState(false);

    const [expandData, setExpandData] = useState(false);

    const [sectionLoading, setSectionLoading] = useState(false);

	const testState = atom({
        key: 'testresult-'+test_id, 
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-'+test_id, 
        default: ""
    });
	const tabsState = atom({
        key: 'testresulttabs-'+test_id, 
        default: null
    });
    const currentTabState = atom({
        key: 'testresultcurrenttab-'+test_id, 
        default: 1
    });
	const [test, setTest] = useRecoilState(testState);
	const [planType, setPlanType] = useRecoilState(planState);
	const [tabs, setTabs] = useRecoilState(tabsState);
    const [currentTab, setCurrentTab] = useState(1);
    const [tasks, setTasks] = useState({});
    const [firstClick, setFirstClick] = useState({});
    const [treeChart, setTreeChart] = useState({});
    const [isTaskLoading, setTaskLoading] = useState(false);
    const [sortLoading, setSortLoading] = useState(false);
    const [isFirstClickLoading, setFirstClickLoading] = useState(false);
    const [isTreeChartLoading, setTreeChartLoading] = useState(false);
    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, answer_id: null });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null, type:null });
    const [treeState, setTreeState] = useState("horizontal");

    const downloadSVG = useCallback(() => {
        const svg = exportRef.current.getElementsByTagName('div')[0].innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
    
        downloadBlob(blob, `treechart.svg`);
      }, []);

    const deleteAnswerModal = (id,hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true,  test_result_id: id,hidden_result:hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            var localTasks = {...tasks};

            localTasks[tabs[currentTab-1].id]["tests"] = [];

            localTasks[tabs[currentTab-1].id]["initial_loaded"] = false; 

            setTasks(localTasks);

            
            getTaskAttempts(tabs[currentTab-1].id, 1,  tasks[tabs[currentTab-1].id].order,  tasks[tabs[currentTab-1].id].direction=="asc" ? "desc" : "asc" );
                                                

            getFirstClick(tabs[currentTab-1].id);
        }
        setOpenDeleteAnswerModal({ active: false,  test_result_id: null,hidden_result:false  })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id, type:'methodology' });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }
	const getTestTreeTestDataApi = () => {

        if(!tabs){
            let token = ReactSession.get("token");

            setSectionLoading(true);

            setCurrentTab(1); 

            getTestTreeTestData({ test_id: test_id }, token).then((response) => {
                setSectionLoading(false);

                if (response.success) {
                    
                    var tasks = response.data.api_tasks;

                    var viewTasks = {};

                    for(var i = 0; i<tasks.length; i++){
                        tasks[i].type = "task";
                        
                        viewTasks[tasks[i].id] = {
                            "view":"path",
                            "page":1,
                            "has_records":true,
                            "initial_loaded":false,
                            "firstclick_loaded":false,
                            "treechart_loaded":false,
                            "order":"created_at",
                            "direction":"desc",
                            "tests":[]
                        };
                    }
                    setTasks(viewTasks);

                    tasks.unshift({"title":"Tree testing overview","type":"overview","success_count":response.data.api_overview.success_count, "direct_count":response.data.api_overview.direct_count, "total_count":response.data.api_overview.total_count});

                    //console.log(tasks)

                    setTabs(tasks);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: response.success ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            
            var viewTasks = {};

            for(var i = 1; i<tabs.length; i++){
                
                
                viewTasks[tabs[i].id] = {
                    "view":"path",
                    "page":1,
                    "has_records":true,
                    "initial_loaded":false,
                    "order":"created_at",
                    "direction":"desc",
                    "tests":[]
                };
            }
            setTasks(viewTasks);
            
        }
	};
    const getTaskAttempts = (task_id, page, order, direction) => {
        let token = ReactSession.get("token");

		setTaskLoading(true);

		getTestTreeTestTaskData({page:page, test_id: test_id,task_id: task_id, order:order, direction:direction }, token).then((response) => {
			setTaskLoading(false);

            setSortLoading(false);

			if (response.success) {
				
                var localTasks = {...tasks};

                localTasks[task_id]["has_records"] = response.has_records;
                
                if(response.data.length>0){
                    localTasks[task_id]["page"] = page+1;
                    
                } else {
                    localTasks[task_id]["has_records"] = false;
                }
                localTasks[task_id]["initial_loaded"] = true;

                if(response.data.length<10){
                    localTasks[task_id]["has_records"] = false;
                }
                
                localTasks[task_id]["has_records"] = response.has_records;
                
                if(page==1){
                    localTasks[task_id]["tests"] = response.data;
                } else {
                    localTasks[task_id]["tests"] = localTasks[task_id]["tests"].concat(response.data);
                }
                

                localTasks[task_id]["order"] = order;

                localTasks[task_id]["direction"] = direction;
                setTasks(localTasks);
                
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: response.success ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		});
    }
    const getFirstClick = (task_id) => {
        let token = ReactSession.get("token");

		setFirstClickLoading(true);

		getFirstClickData({ test_id: test_id,task_id: task_id }, token).then((response) => {
			setFirstClickLoading(false);

			if (response.success) {
				
                
                var firstClickLocal = Object.assign({}, firstClick);
                
                firstClickLocal[task_id] = response.data.first_click;
                

                setFirstClick(firstClickLocal);

                
			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: response.success ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		});
    }
    const getTreeChart = (task_id) => {
        let token = ReactSession.get("token");

		setTreeChartLoading(true);

		getTreeChartData({ test_id: test_id,task_id: task_id }, token).then((response) => {
			setTreeChartLoading(false);

			if (response.success) {
				
                /*var localTasks = {...tasks};

                localTasks[task_id]["treechart_loaded"] = true;
                
                setTasks(localTasks);

                var  localTabs = Object.assign([],tabs);

                for(var i=0;i<localTabs.length;i++){
                    if(localTabs[i].id==task_id){
                        var tab = {...localTabs[i]};

                        tab['api_tree'] = response.data.tree_chart.api_tree;

                        tab['total_navigation'] = response.data.tree_chart.total_navigation;

                        tab['depth'] = response.data.tree_chart.depth;

                        tab['leaf_nodes'] = response.data.tree_chart.leaf_nodes;

                        localTabs[i] = tab;
                    }
                }
                setTabs(localTabs);
                */

                var tab = {};

                tab['api_tree'] = response.data.tree_chart.api_tree;

                tab['total_navigation'] = response.data.tree_chart.total_navigation;

                tab['depth'] = response.data.tree_chart.depth;

                tab['leaf_nodes'] = response.data.tree_chart.leaf_nodes;

                tab['answer_path'] = response.data.tree_chart.answer_path;
                
                var treeChartLocal = Object.assign({}, treeChart);

                treeChartLocal[task_id] = tab;

                console.log(treeChartLocal)

                setTreeChart(treeChartLocal) ;

			} else {
				toast(
					<div className="toastinner">
						{response.message}
						<a
							onClick={() => {
								toast.dismiss();
							}}
						>
							&times;
						</a>
					</div>,
					{
						className: response.success ? "successtoast" : "errortoast",
						position: "bottom-center",
						duration: 2000,
					}
				);
			}
		});
    }

    const getTestafterEndTest=()=>{

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            }else{
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }
    
	const getTest = () => {

        if(!test){
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then(
                (response) => {
                    setLoading(false);

                    if (response.success) {
                        setTest(response.test);

                        getTestTreeTestDataApi();
                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: response.success ? "successtoast" : "errortoast",
                                position: "bottom-center",
                                duration: 2000,
                            }
                        );
                    }
                }
            );
        } else {
            getTestTreeTestDataApi();
        }
	};

	const getPlan = () => {

        if(!planType){
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);

                    getTest();
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a
                                onClick={() => {
                                    toast.dismiss();
                                }}
                            >
                                &times;
                            </a>
                        </div>,
                        {
                            className: response.success ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getTest();
        }
	};

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " - Analytics | Tree Testing";
		getPlan();
	}, []);

	const renderOverview = ()=>{

        var task = tabs[currentTab-1];

        var success_perc = task.total_count > 0 ? ((task.success_count / task.total_count)*100).toFixed(0) : 0;

        var direct_perc = task.total_count > 0 ? ((task.direct_count / task.total_count)*100).toFixed(0) : 0;

        return (
            <div className="tree-testing-top-graph gradient-bg">
                <div className="tree-test-top-graphwrap">
                    <div className="tree-tst-grph-data">
                        <h4>Task success %</h4>
                        <p>Out of all the tasks completed,  <b>{success_perc}% of the testers ended up at the correct answer</b></p>
                        <div className="tree-task-graph-hold">
                            <LinearAxisChart height="100" id="overview-success-chart" percentage={success_perc} />
                        </div>
                    </div>
                    <div className="tree-tst-grph-data">
                        <h4>Task success directness %</h4>
                        <p>Out of all the tasks completed,  <b>{direct_perc}% of the testers ended up at the correct answer directly, without backtracking. </b></p>
                        <div className="tree-task-graph-hold">
                            <LinearAxisChart height="100" id="overview-success-chart" percentage={direct_perc} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const renderPath = (test, correct_answer_id, testg) => {
        //console.log(correct_answer_id);
        if(!test.path){
            return <></>;
        }
        return test.path.map(function(pitem,index) {

            var ele = this.testg.home_name;

            if(pitem.node){
                ele = pitem.node.title;
            
            

                if(correct_answer_id==pitem.node.id){
                    ele = <b>{pitem.node.title}</b>;
                }
            }
            var dir = <></>;
            if(index!=0){
                if(pitem.direction=="forward"){
                    dir = <>&gt;</>;
                } else {
                    dir = <>&lt;</>;
                }
            }
            return <> {dir} {ele}</>;
        },{testg:testg});
    }
    const renderTask = ()=>{

        var task = tabs[currentTab-1];

        var success_perc = task.total_count > 0 ? ((task.success_count / task.total_count)*100).toFixed(0) : 0;

        var direct_perc = task.total_count > 0 ? ((task.direct_count / task.total_count)*100).toFixed(0) : 0;

        var total_tasks = task.indirect_failure_count + task.direct_failure_count + task.indirect_success_count + task.skipped_count;

        var indirect_failure_perc = total_tasks > 0 ? ((task.indirect_failure_count / total_tasks)*100).toFixed(0) : 0;

        var direct_failure_perc = total_tasks > 0 ? ((task.direct_failure_count / total_tasks)*100).toFixed(0) : 0;

        var skipped_perc = total_tasks > 0 ? ((task.skipped_count / total_tasks)*100).toFixed(0) : 0;

        var indirect_success_perc = total_tasks > 0 ? ((task.indirect_success_count / total_tasks)*100).toFixed(0) : 0;

        var direct_success_perc = total_tasks > 0 ? ((task.direct_success_count / total_tasks)*100).toFixed(0) : 0;

        /*
        var task = tabs[currentTab-1];
        if(tasks[task.id].initial_loaded==false){
            getTaskAttempts(task.id, tasks[task.id].page);
        }*/
        return (
            <>
                <div className="d-flex justify-content-between align-items-center tree-testing-correct-ans-wrap mb-32">
                    <div className={`curt-ans-left-side  `}>
                        
                        <>
                            <span>
                                <img src={process.env.REACT_APP_URL+"images/green-check.svg"} /> 
                                Correct path<strong>:</strong>

                            </span>
                        
                            {task.correct_answer.map(function(item, index){
                                if(index==task.correct_answer.length-1){
                                    return <span className={`${test.language=='ar'?'cairo-font':''}`} key={"nodeans"+index}><b> {item}</b></span>
                                } else {
                                    return <span className={`${test.language=='ar'?'cairo-font':''}`} key={"nodeans"+index} style={{fontWeight:'normal'}}> {item} &gt;</span>
                                }
                            })}
                        </>
                    </div>
                    <div className="curt-ans-right-side">
                        <span>Average time <strong>-</strong></span><b>{calculateTime(task.average_time_spent)}</b>
                    </div>
                </div>
                <div className="tree-testing-top-graph gradient-bg">
                    <div className="tree-test-top-graphwrap">
                        <div className="tree-tst-grph-data">
                            <h4>Task success %</h4>
                            <div className="tree-task-graph-hold">
                                <LinearAxisChart expandData={expandData} height="100" id="overview-success-chart" percentage={success_perc} />
                            </div>
                        </div>
                        <div className="tree-tst-grph-data">
                            <h4>Task success directness %</h4>
                            <div className="tree-task-graph-hold">
                                <LinearAxisChart expandData={expandData} height="100" id="overview-success-chart" percentage={direct_perc} />
                            </div>
                        </div>
                        <div className="tree-tst-grph-data">
                            <h4>Task COMPLETION</h4>
                            <div className="completion-task-infor-wrap">
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-1"></span>
                                    Indirect failure
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-2"></span>
                                    Direct failure
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-3"></span>
                                    Task skipped
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-4"></span>
                                    Indirect success
                                </div>
                                <div className="comp-infow-repeat">
                                    <span className="info-bg-5"></span>
                                    Direct success
                                </div>
                            </div>
                            {(task.indirect_failure_count + task.direct_failure_count + task.skipped_count  + task.direct_success_count) >0 &&
                            <div className="completion-selected-task-count">
                                <div className="comp-selected-task-count-repeat" style={{"width": indirect_failure_perc+"%" }}>
                                    <span className="info-bg-1"></span>
                                    {task.indirect_failure_count >0 &&
                                    <p>{task.indirect_failure_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": direct_failure_perc+"%" }}>
                                    <span className="info-bg-2"></span>
                                    {task.direct_failure_count >0 &&
                                    <p>{task.direct_failure_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": skipped_perc +"%"}}>
                                    <span className="info-bg-3"></span>
                                    {task.skipped_count >0 &&
                                    <p>{task.skipped_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": indirect_success_perc+ "%"}}>
                                    <span className="info-bg-4"></span>
                                    {task.indirect_success_count >0 &&
                                    <p>{task.indirect_success_count}</p>
                                    }
                                </div>
                                <div className="comp-selected-task-count-repeat" style={{"width": direct_success_perc+"%"}}>
                                    <span className="info-bg-5"></span>
                                    {task.direct_success_count >0 &&
                                    <p>{task.direct_success_count}</p>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="bd-que-table-wrapper mt-32 tree-testing-tbldata-wrap">
                    <h3 className="h3 font-inter tree-path-view-h3">
                        <span>Path</span>
                    </h3>
                    <div className="bd-que-table-search-wrap">
                        <div className="bd-question-search-left w-100">
                            <div className="bd-question-radio-data  w-100">
                                <div className="checkboxes radio_btn_class  d-flex justify-content-between w-100">
                                    <div className="d-flex tree-view-radiobtns">
                                        Path view
                                         {/*<Radiobtn isSelectedlabelBold={true} name={"view-"+task.id} value="path" checked={tasks[task.id].view=="path"?true:false} onChange={()=>{ var lTasks = {...tasks}; lTasks[task.id].view="path";  setTasks(lTasks); setExpandData(true); }} label="Path View" />
                                       <Radiobtn isSelectedlabelBold={true} name={"view-"+task.id} value="tree" checked={tasks[task.id].view=="tree"?true:false} onChange={()=>{ 
                                            var lTasks = {...tasks}; lTasks[task.id].view="tree";  
                                            setTasks(lTasks);
                                            renderTreeChart(task.id);
                                            setExpandData(false);
                                        }} label="Tree View" />*/}
                                        
                                    </div>
                        
                                    <div className="max-matrix-screen">
                                        <span>
                                            <a target={"_blank"} href={"/researcher/analytics/"+test_id+"/tree-chart/"+task.id}>
                                                Open tree view
                                            
                                                <img 
                                                    src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}
                                                    alt="icon"/>
                                            </a>
                                            
                                        </span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    {tasks[task.id].view=="path" &&
                    <>
                    <div className="bd-que-ans-table-data-inner bd-que-buy-user-table tree-path-view-table" id="field1">
                        <div className="table-responsive sort-loading-table" >
                            {sortLoading &&
                            <div className="sort-loading">
                                <LoadingIcon />
                            </div>
                            }
                            <table>
                                <thead>
                                    <tr>
                                        <th className="bd-thcol-1">Participants</th>
                                        <th className="bd-thcol-2">Answer</th>
                                        <th className="bd-thcol-3">
                                            
                                            <div className={`${tasks[task.id].order=="outcome" ? tasks[task.id].direction: '' } col-sort justify-content-between`} onClick={()=>{
                                                
                                                var localTasks = {...tasks};

                                                //localTasks[task.id]["tests"] = [];

                                                localTasks[task.id]["initial_loaded"] = false; 

                                                setTasks(localTasks);

                                                setSortLoading(true);
                                                
                                                getTaskAttempts(task.id, 1,  "outcome",  tasks[task.id].direction=="asc" ? "desc" : "asc" );}}>
                                                Outcome
                                                {tasks[task.id].order=="outcome"  &&
                                                <span>
                                                    
                                                    <img src={process.env.REACT_APP_URL+"images/sort_enable.svg"} width={20} />
                                                </span>
                                                }
                                                {tasks[task.id].order!="outcome"  &&
                                                <span>
                                                    
                                                    <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                                </span>
                                                }
                                            </div>
                                        </th>
                                        <th className="bd-thcol-4">
                                            <div className={`${tasks[task.id].order=="time_spent" ? tasks[task.id].direction: '' } col-sort justify-content-between`} onClick={()=>{
                                                
                                                var localTasks = {...tasks};

                                                //localTasks[task.id]["tests"] = [];

                                                localTasks[task.id]["initial_loaded"] = false; 

                                                setTasks(localTasks);

                                                setSortLoading(true);

                                                getTaskAttempts(task.id, 1,  "time_spent",  tasks[task.id].direction=="asc" ? "desc" : "asc" );}}>
                                                Time spent 
                                                {tasks[task.id].order=="time_spent"  &&
                                                <span>
                                                    
                                                    <img src={process.env.REACT_APP_URL+"images/sort_enable.svg"} width={20} />
                                                </span>
                                                }
                                                {tasks[task.id].order!="time_spent"  &&
                                                <span>
                                                    
                                                    <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                                </span>
                                                }
                                            </div>
                                        </th>
                                        <th className="bd-thcol-5">
                                            <div className={`${tasks[task.id].order=="created_at" ? tasks[task.id].direction: '' } col-sort justify-content-between`} onClick={()=>{
                                                
                                                
                                                var localTasks = {...tasks};

                                                //localTasks[task.id]["tests"] = [];

                                                //localTasks[task.id]["initial_loaded"] = false; 

                                                setSortLoading(true);
                                                setTasks(localTasks);
                                                getTaskAttempts(task.id, 1,  "created_at",  tasks[task.id].direction=="asc" ? "desc" : "asc" );}}>
                                                Date 
                                                {tasks[task.id].order=="created_at"  &&
                                                <span>
                                                    
                                                    <img src={process.env.REACT_APP_URL+"images/sort_enable.svg"} width={20} />
                                                </span>
                                                }
                                                {tasks[task.id].order!="created_at"  &&
                                                <span>
                                                    
                                                    <img src={process.env.REACT_APP_URL+"images/sort_disable.svg"} width={20} />
                                                </span>
                                                }
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tasks[task.id].tests.map(function(item, index){
                                        
                                        return (
                                            <tr key={"test"+item.id} className={` ${(item.result && item.result.is_hidden==1)?'disabled-row':''}`}>
                                                <td className="bd-q-pat-col">
                                                    {item.result && item.result.user_id &&
                                                    <b>Participant {" "} 
                                                        {item.result && item.result.result_id ?
                                                            item.result.result_id
                                                            :
                                                            index+1
                                                        }
                                                    </b>
                                                    }
                                                    {item.result && !item.result.user_id &&
                                                    <b>
                                                        Unknown {" "}
                                                        {item.result && item.result.result_id ?
                                                            item.result.result_id
                                                            :
                                                            index+1
                                                        }
                                                    </b>
                                                    }
                                                </td>
                                                <td className="bd-q-ans-col">
                                                    <div className={`${test.language=='ar'?'cairo-font':''} bd-qu-td-ans tree-test-ans-col`}>
                                                        
                                                        {renderPath(item, task.correct_answer_id, test)}
                                                    </div>
                                                </td>
                                                <td className="bd-outcome-col">
                                                    <div className="test_outcome_td">
                                                    <br/>
                                                    {(item.is_skipped==1 ? <>
                                                        <span className={"skipped_dot"} style={{background:"#77E1FE"}}></span>
                                                    Skipped
                                                    </>: 
                                                    <>
                                                    <span className={(item.is_direct==1 ? "direct": "indirect")+"_"+(item.status=="Success" ? "success": "failure")+"_dot"}></span>
                                                    {item.is_direct==1 ? "Direct": "Indirect"}
                                                    {" "} 
                                                    {item.status=="Success" ? "success": "failure"}
                                                    </>)}
                                                    </div>
                                                </td>
                                                <td className="bd-time-col"><b>{calculateTime(item.time_spent)}</b></td>
                                                <td className="bd-q-date-col">
                                                    <div className="bd-col-action-wrap d-flex">
                                                        <span>{moment(item.created_at).format("DD/MM/YYYY")}</span>
                                                        <span
                                                            className="pro-menu-icon small-dropdown mid-menu-wrap dropdown-withclose-btn">

                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    id={"dropdownMenuButton" + item.id}
                                                                    className="dropdown-toggle"
                                                                >
                                                                    <img
                                                                        src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                                                                        alt="img"
                                                                    />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <div className="dropdown-close">
                                                                        <Dropdown.Toggle>
                                                                            <img
                                                                                src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                                                alt="img"
                                                                            />
                                                                        </Dropdown.Toggle>
                                                                    </div>
                                                                    <div className="drop-down-link-hold">
                                                                        <Dropdown.Item onClick={() => deleteAnswerModal(item.result.id,(item.result && item.result.is_hidden)?true:false)}>
                                                                        {(item.result && item.result.is_hidden)?'Show participant':'Hide participant'}
                                                                            <span>
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_URL +
                                                                                        "images/eye.svg"
                                                                                    }
                                                                                    alt="img"
                                                                                />
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => reportAnswerModal(item.result.id)}>
                                                                            Report participant
                                                                            <span>
                                                                                <img
                                                                                    src={
                                                                                        process.env.REACT_APP_URL + "images/warning.svg"
                                                                                    }
                                                                                    alt="img"
                                                                                />
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                    </div>

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {tasks[task.id].tests.length==0  && !isTaskLoading &&
                                    <tr>
                                        <td colSpan={10}>No data available</td>
                                    </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        {tasks[task.id].initial_loaded && !isTaskLoading && tasks[task.id].has_records &&
                        <div className="load-more-data" onClick={()=>{getTaskAttempts(task.id, tasks[task.id].page,  tasks[task.id].order,  tasks[task.id].direction);}}>
                            <span>Load more <img src={process.env.REACT_APP_URL+"images/dotts.png"} alt="load-more"/></span>
                        </div>
                        }
                        { isTaskLoading && !sortLoading &&
                        <div className="load-more-data">
                            <span>Loading <img src={process.env.REACT_APP_URL+"images/dotts.png"} alt="load-more"/></span>
                        </div>
                        }
                    </div>
                    
                    </>
                    }
                    {tasks[task.id].view=="tree"  &&
                    <div className="bd-que-ans-table-data-inner bd-que-buy-user-table" id="field2">
                        <div className="treew-view-graph-wrap w-100" >
                            {task.id in treeChart && treeChart[task.id].api_tree.length > 0 &&
                            <>
                            <div className="bd-que-ans-table-data-inner bd-que-buy-user-table  tree-chart-full-top-row" id="field2">
                                <div className="bd-question-top-slide d-flex justify-content-between">
                                    <div className="bd-que-slide-left b-tree-left">
                                        <div className="d-flex">
                                            <Checkbox className={"plan-change-switch"} onChange={(event)=>{ setTreeState(treeState=='horizontal' ? "radial" : "horizontal")} } value={"radial"} checked={treeState=="radial"?true:false} label="Radial" bigLabel={false} beforeLabel={"Horizontal"} />
                                        </div>
                                        
                                    </div>
                                    <div className="bd-que-slide-right tree-options-right">
                                        
                                        <span className="small-dropdown">
                                            <Dropdown>
                                                <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle">
                                                    Download
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={() =>  exportAsImage(exportRef.current, "treechart")}>
                                                        Download  PNG
                                                        <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() =>  downloadSVG(exportRef.current)}> 
                                                        Download  SVG
                                                        <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="treew-view-graph-wrap" ref={exportRef}>
                            
                                <SankeyChart expandData={expandData} radial={treeState=="radial"?true:false} height={treeState=="radial"  ? treeChart[task.id].depth*220 : treeChart[task.id].leaf_nodes*50+100} depth={treeChart[task.id].depth}  answer_path={treeChart[task.id].answer_path} total_navigation={treeChart[task.id].total_navigation}  rawdata={{"id":treeChart[task.id].api_tree[0]["id"], "depth": treeChart[task.id].api_tree[0]["depth"], "value": treeChart[task.id].api_tree[0]["value"], "participants": treeChart[task.id].api_tree[0]["participants"], "correct_answer_count": treeChart[task.id].api_tree[0]["correct_answer_count"],"incorrect_answer_count": treeChart[task.id].api_tree[0]["incorrect_answer_count"],"skipped_count": treeChart[task.id].api_tree[0]["skipped_count"],"nominated_answer_count": treeChart[task.id].api_tree[0]["nominated_answer_count"],"back_count": treeChart[task.id].api_tree[0]["back_count"], title:treeChart[task.id].api_tree[0]["title"], children:treeChart[task.id].api_tree[0]['children']} } owidth={100} />
                            </div>
                            </>
                            }
                            {!(task.id in treeChart) && isTreeChartLoading &&
                            <LoadingIcon />
                            }
                        </div>
                    </div>
                    }
                    <div className="first-click-table-wrap">
                        <h3 className="h3 font-inter">First click </h3>
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Label</th>
                                        <th className="text-right">Visited first</th>
                                        <th className="text-right">Visited during</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(task.id in firstClick) &&
                                    <>
                                    {firstClick[task.id].map(function(item){
                                        return (
                                    <tr key={"firstclick"+item.title}>
                                        <td>
                                            <div className={`first-click-td ${test.language=='ar'?'cairo-font':''}`}>{item.title}</div>
                                        </td>
                                        <td className="text-right"><b>{item.visited_first} %</b></td>
                                        <td className="text-right"><b>{item.visited_during} %</b></td>
                                    </tr>
                                        );
                                    })}
                                    
                                    {firstClick[task.id].length==0 && !isFirstClickLoading &&
                                    <tr>
                                        <td className="no-data-available" colSpan={10}>No data available</td>
                                    </tr>
                                    }
                                    </>
                                    }
                                    {isFirstClickLoading &&
                                    <tr>
                                        <td colSpan={10}><LoadingIcon /></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    const renderFirstClick = (task_id) => {
        if(!(task_id in firstClick)){
            getFirstClick(task_id);
        }
    }
    const renderTreeChart = (task_id) => {
        if(!(task_id in treeChart)){
            getTreeChart(task_id);
        }
    }
    const callbackTopbar = (data)=>{
        getTest();
        getTestafterEndTest();
    }
	return (
		<LayoutResearcherFullWidth
			fixed_header_target={true}
			isLoading={isLoading}
			wrapClass={"researcher-test-view-overflow overflow-visible"}
			skipCheck={false}
			extendedFooter={false}
			activeMenu={""}
		>
			{test  && (
				<div className="analyze-results-page-wrapper">
					<Topbar test={test} callbackTopbar={callbackTopbar}/>

					<div className={`container inner-page-container`}>
						<div className="sp5-content-wrap analyze-overflow-wrap">
							<Sidebar
								test={test}
								planType={planType}
								activeMenu="tree-test"
                                hideSidebar={false}
							/>

                            <div className="sp5-right-side-wrap card-sorting-anayl-data">
                                {sectionLoading &&
                                <LoadingIcon />
                                }
                                {!sectionLoading && tabs &&
                                <div className="bd-question-view-wrap">
                                    <div className="bd-question-top-slide d-flex justify-content-between">
                                        <div className="bd-que-slide-left">
                                            <p className="text-small-info">{currentTab} of {tabs.length}</p>
                                            <div className={`db-que-list-wrap ${test.language=='ar'?'cairo-font':''} `}>
                                                <Dropdown className="dropdown">
                                                    <Dropdown.Toggle className="dropdown-toggle">
                                                        {tabs[currentTab-1].title}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {tabs.map(function(item,index){
                                                            return (
                                                                <Dropdown.Item key={"question"+index} onClick={()=>{ setCurrentTab(index+1);
                                                                
                                                                    if(index>0){
                                                                        var task = tabs[index];
                                                                        if(tasks[task.id].initial_loaded==false){
                                                                            getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction );
                                                                        }
                                                                        renderFirstClick(task.id);
                                                                    }
                                                                } }>
                                                                    {item.title}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                        

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className="bd-que-slide-arrow">
                                            <span>
                                                <a href="#" onClick={(e)=>{ e.preventDefault(); 
                                                    if(currentTab > 1){ 
                                                        setCurrentTab(currentTab-1);
                                                    }
                                                    if(currentTab!=1){
                                                        var task = tabs[currentTab-1-1];
                                                        if(tasks[task.id].initial_loaded==false){
                                                            getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                        }
                                                        renderFirstClick(task.id);
                                                    }
                                                } } className={`${currentTab === 1 ? 'disabled-link' : ''} `} >
                                                    <img src={process.env.REACT_APP_URL+"images/arrow-left.png"} />
                                                </a>
                                            </span>
                                            <span>
                                                <a href="#" onClick={(e)=>{ e.preventDefault(); if(currentTab < tabs.length){ setCurrentTab(currentTab+1); }
                                                    if(currentTab+1>0){
                                                        var task = tabs[currentTab+1-1];
                                                        if(tasks[task.id].initial_loaded==false){
                                                            getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                        }
                                                        renderFirstClick(task.id);
                                                    }
                                            
                                            } } className={`${currentTab === tabs.length ? 'disabled-link' : ''} `}>
                                                    <img src={process.env.REACT_APP_URL+"images/arrow-right.png"} />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    {tabs[currentTab-1].type=="overview" &&
                                    <>{renderOverview()}</>
                                    }
                                    {tabs[currentTab-1].type=="task" &&
                                    <>{renderTask()}</>
                                    }
                                    <div className="bd-question-bottom-pagination-arrow">
                                        <div className="bd-que-slide-arrow">
                                            <span>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault(); if (currentTab > 1) {
                                                        setCurrentTab(currentTab - 1);

                                                    }
                                                    if (currentTab != 1) {
                                                        var task = tabs[currentTab - 1 - 1];
                                                        if (tasks[task.id].initial_loaded == false) {
                                                            getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                        }
                                                        if (tasks[task.id].firstclick_loaded == false) {
                                                            getFirstClick(task.id);
                                                        }
                                                    }
                                                }} className={`${currentTab === 1 ? 'disabled-link' : ''} `} >
                                                    <img src={process.env.REACT_APP_URL + "images/arrow-left.png"} />
                                                </a>
                                            </span>
                                            <span>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault(); if (currentTab < tabs.length) { setCurrentTab(currentTab + 1); }
                                                    if (currentTab + 1 > 0) {
                                                        var task = tabs[currentTab + 1 - 1];
                                                        if (tasks[task.id].initial_loaded == false) {
                                                            getTaskAttempts(task.id, tasks[task.id].page, tasks[task.id].order, tasks[task.id].direction);
                                                        }
                                                        if (tasks[task.id].firstclick_loaded == false) {
                                                            getFirstClick(task.id);
                                                        }
                                                    }

                                                }} className={`${currentTab === tabs.length ? 'disabled-link' : ''} `}>
                                                    <img src={process.env.REACT_APP_URL + "images/arrow-right.png"} />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                                }
                            </div>
						</div>
					</div>
                    <DeleteAnswer
                        test_id={test_id}
                        openModal={openDeleteAnswerModal}
                        closeModal={closeDeleteAnswerModal} />
                    <ReportAnswer
                        test_id={test_id}
                        openModal={openReportAnswerModal}
                        closeModal={closeReportAnswerModal} />
				</div>
			)}
		</LayoutResearcherFullWidth>
	);
}
