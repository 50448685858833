import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams, useLocation } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import {
    getLoadMoreSurveyQuestionData,
    getResearcherTestResultData,
    getTestQuestionResultData
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import { getCurrentPlansService } from "../../../services/plans";
import HideParticipant from './dialog/hide-participant';
import moment from "moment";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import { Dropdown } from "react-bootstrap";
import ReportAnswer from "./dialog/report-answer";
import { atom, useRecoilState } from 'recoil';
import PieChartSingleChoice from "./components/pie_chart_singlechoice";
import BarChart from "./components/bar_chart";
import BarChartRatingScale from "./components/bar_chart_rating_scale";
import AnyChart from 'anychart-react';
import { LoadingIcon } from "../../loader/loadingIcon";

export function compare(a, b) {

    const value1 = a[1]["averageranking"];
    const value2 = b[1]["averageranking"];

    let comparison = 0;

    if (value1 > value2) {
        comparison = -1;
    } else if (value1 < value2) {
        comparison = 1;
    }
    return comparison;
}

export default function SurveyResult() {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();


    const testState = atom({
        key: 'testresult-' + test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-' + test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);
    const [questionArray, setQuestionArray] = useState([]);
    const [isActive, setActive] = useState(0);
    const [dropdownQuestions, setDropdownQuestions] = useState(false);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState({ active: false, test_result_id: null, hidden_result: false });
    const [openReportAnswerModal, setOpenReportAnswerModal] = useState({ active: false, answer_id: null });

    const [answerArray, setAnswerArray] = useState([]);
    const [aggregrateArray, setAggregrateArray] = useState([]);
    const [metaData, setMetaData] = useState({ questionType: "", totalResultCount: 0, userType: "", aggregate_total_voters: 0, sort_time: "", sort_date: "", sort_vote: "", load_more: "", test_question_id: "", page: 1 });
    const [search, setSearch] = useState("");
    const [searchLoading, setSearchLoading] = useState("");
    const [chartData, setChartData] = useState({});
    const [rankingchartData, setRankingChartData] = useState({});
    const [matrixArray, setMatrixArray] = useState([]);
    const [matrixheight, setMatrixHeight] = useState(600);
    const [sortedchartData, setSortedChartData] = useState([['Mumbai', 2], ['Delhi', 1], ['Bangalore', 1], ['Kolkata', 0], ['Other', 0]]);
    const [highestRanking, setHighestRanking] = useState(null);
    const [rankingTableData, setRankingTableData] = useState([]);

    const colorArray = ["rgb(252,131,189)", "rgb(248,160,206)", "rgb(245,190,222)", "rgb(241,219,239)", "rgb(241,219,239)"];

    const [sortLoading, setSortLoading] = useState(false);

    const [resultCount, SetResultCount] = useState(0);

    const getTestafterEndTest = () => {

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            } else {
                navigate("/dashboard");
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }
    const getTest = () => {

        if (!test) {
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    setTest(response.test);
                    getQuestions();


                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getQuestions();
        }
    };

    const getQuestions = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        getTestQuestionResultData({ test_id: test_id, type: location.pathname.split("/").pop() }, token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                setQuestionArray(response.data);
                changeActiveQuestion(1, response.data);
                SetResultCount(response.resultcount);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };

    const getPlan = () => {

        if (!planType) {
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    };




    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - test result questions";
        getTest();
        getPlan();

    }, [location]);

    const callbackTopbar = (data) => {
        getTest();
        getTestafterEndTest();
    }

    const showPreQuestion = (type) => {
        if (type === "increase") {
            // decrease active question length
            if (isActive !== 1) {
                changeActiveQuestion(isActive - 1, questionArray);
            }
        } else {
            // increase active question length
            if (questionArray.length !== isActive) {
                changeActiveQuestion(isActive + 1, questionArray);
            }
        }
    }

    var chart1_settings = {
        id: "Aera chart 1 ",
        width: "100%",
        background: 'transparent',
        height: matrixheight,
        type: 'heatMap',
        data: matrixArray,
        colors: {
            darken: "#ff0000"
        },
        darken: {
            fill: "#ff0000"
        },
        hovered: {
            fill: "#FF66AD"
        },
        tooltip: {
            enabled: true,
            // background: "#defcfc",
            background: {
                fill: "#defcfc",
                corners: 17
            },
            fontColor: "#171637",
            fontSize: "14px",
            fontFamily: "sans-serif",
            // fontWeight: 100,
            title: false,
            separator: false,
            padding: "20px 40px",
            textAlign: "center",
            format: "{%participants}",
            // anchor:"left-center",
            offsetX: 30,
            offsetY: -30
        },
        xAxis: {
            // staggerMode:true,
            labels: {
                // rotation: -40,
                fontFamily: test.language=="ar"?"Cairo":"sans-serif",
                fontSize: "12px",
            }
        },
        yAxis: {
            labels: {
                fontFamily: test.language=="ar"?"Cairo":"sans-serif",
            }
        }

    };

    const changeActiveQuestion = (index, data) => {
        setActive(index)
        setDropdownQuestions(false); // hide dropdown value
        setSearch(""); // hide dropdown value

        // filter data from array
        const dataAnswer = data.filter((item, indexItem) => indexItem + 1 === index)[0];

        if (dataAnswer) {

            setAnswerArray(dataAnswer.result);  // set all test result data list
            setAggregrateArray(dataAnswer.aggregate);
            if (dataAnswer.question_type == "multiplechoice") {
                setChartData(dataAnswer.chartDataMultipleChoice);
            }
            else {
                setChartData(dataAnswer.chartData);
            }
            if (dataAnswer.question_type == "rankingscale") {
                setRankingChartData(dataAnswer.rankingchartData);
                setRankingTableData([dataAnswer.rankingtableData]);
            }
            if (dataAnswer.question_type == "likertscale") {
                let matrixData = [];

                dataAnswer.matrix.forEach((items) => {

                    var totalheat = 0;
                    dataAnswer.matrix.forEach((entry) => {
                        if (items.y == entry.y) {
                            totalheat = totalheat + entry.heat
                        }
                    })
                    var fill = '';
                    if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 80) {
                        fill = '#ff66ad';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 60 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 80) {
                        fill = '#fb83bd';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 40 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 60) {
                        fill = '#f7a0ce';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 20 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 40) {
                        fill = '#f2bcde';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) >= 10 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 20) {
                        fill = '#eed9ef';
                    }
                    else if ((Math.round((items.heat * 100 / totalheat) * 100) / 100) > 0 && (Math.round((items.heat * 100 / totalheat) * 100) / 100) < 10) {
                        fill = '#eed9ef';
                    }
                    else {
                        fill = '#eef8ff';
                    }


                    matrixData.push({
                        // x: items.x,
                        x: items.x.length>15?(test.language=="ar"?"..."+items.x.substring(0, 14):items.x.substring(0, 14) + ".."):items.x,
                        // y: items.y,
                        y: items.y.length>20?(test.language=="ar"?"..."+items.y.substring(0, 19):items.y.substring(0, 19) + "..."):items.y,
                        // heat: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "%",
                        heat: items.heat,
                        fill: fill,
                        // participants:items.heat>1?items.heat+" Participants":items.heat+" Participant",
                        participants: (Math.round((items.heat * 100 / totalheat) * 100) / 100) + "% Participants",
                    })
                })


                setMatrixArray(matrixData);
                setMatrixHeight(dataAnswer.subquestionscount * 30 + 50);
            }

            let sortable = [];
            for (var option in dataAnswer.chartData) {
                sortable.push([option, dataAnswer.chartData[option]]);
            }

            sortable.sort(function (a, b) {
                return b[1] - a[1];
            });

            setSortedChartData(sortable);

            setMetaData({
                'questionType': dataAnswer.question_type,
                'totalResultCount': dataAnswer.result_count,
                'userType': (dataAnswer.question_type === 'free') ? "by-user" : "chart",
                'aggregate_total_voters': dataAnswer.aggregate_total_voters,
                sort_time: "",
                sort_date: "",
                sort_vote: "",
                load_more: (dataAnswer.result_count > 10) ? true : false,
                test_question_id: dataAnswer.id,
                page: 1
            });
        }

    }

    const calculateTime = (time) => {
        var d = Math.floor(time / (3600 * 24));
        var h = Math.floor(time % (3600 * 24) / 3600);
        var m = Math.floor(time % 3600 / 60);
        var s = Math.floor(time % 60);

        if (m > 0) {
            return (`${m}min ${s}sec`);

        } else if (s > 0) {
            return (` ${s}sec`);
        } else {
            return ('-');
        }
    }

    const calculateAggregatePercentage = (vote) => {
        const totalPercentage = (vote) / (metaData.aggregate_total_voters) * 100;

        if (!isNaN(totalPercentage)) {
            return parseFloat(totalPercentage.toFixed(2));
        }

        return 0;
    }



    const sortData = (type) => {
        if (type === 'time') {
            if (metaData.sort_time === 'asc') {
                // setAnswerArray([]);
                sortDataApi('time', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('time', 'asc');
            }
        } else if (type === 'date') {
            if (metaData.sort_date === 'asc') {
                // setAnswerArray([]);
                sortDataApi('date', 'desc');
            } else {
                // setAnswerArray([]);
                sortDataApi('date', 'asc');
            }
        } else if (type === 'vote') {
            if (metaData.sort_vote === 'asc') {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote < b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "desc" });
            } else {
                setAggregrateArray(aggregrateArray.sort((a, b) => (a.vote > b.vote) ? 1 : -1));
                setMetaData({ ...metaData, sort_vote: "asc" });
            }
        }


    }

    const sortDataApi = (sortColumn, orderBy) => {

        // setMetaData({ ...metaData, load_more: true });
        // setLoadMoreLoading(true);
        setSortLoading(true);
        let token = ReactSession.get("token");
        getLoadMoreSurveyQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: 1,
            type: metaData.questionType,
            search: search,
            sort_column: sortColumn,
            order_by: orderBy,
            questiontype: "Survey"
        }, token).then((response) => {
            // setLoadMoreLoading(false);
            setSortLoading(false);

            if (response.success) {
                setAnswerArray(response.data);

                if (sortColumn === 'time') {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_time: "desc", sort_date: "", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_time: "asc", sort_date: "", page: 1, load_more: response.more_records });
                    }
                } else {
                    if (orderBy === 'desc') {
                        setMetaData({ ...metaData, sort_time: "", sort_date: "desc", page: 1, load_more: response.more_records });
                    } else {
                        setMetaData({ ...metaData, sort_time: "", sort_date: "asc", page: 1, load_more: response.more_records });
                    }
                }

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    const loadMore = () => {

        setLoadMoreLoading(true);
        let token = ReactSession.get("token");
        getLoadMoreSurveyQuestionData({
            test_id: test_id,
            test_question_id: metaData.test_question_id,
            page: metaData.page + 1,
            type: metaData.questionType,
            search: search,
            questiontype: "Survey"
        }, token).then((response) => {
            setLoadMoreLoading(false);

            if (response.success) {
                setAnswerArray(answerArray.concat(response.data));
                setMetaData({ ...metaData, page: metaData.page + 1, load_more: response.more_records });

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    }

    // delete modal popup functionlity
    const hideParticipantModal = (id, hiddenResult) => {
        setOpenDeleteAnswerModal({ active: true, test_result_id: id, hidden_result: hiddenResult });
    }

    const closeDeleteAnswerModal = (data) => {

        // if successfully delete then delete object of array from answerArray
        if (data) {

            const getAnswerArray = answerArray;
            for (var i = 0; i < getAnswerArray.length; i++) {
                if (openDeleteAnswerModal.test_result_id === getAnswerArray[i].test_result_id) {

                    if (getAnswerArray[i].result.is_hidden === 1) {
                        getAnswerArray[i].result.is_hidden = 0;
                    } else {
                        getAnswerArray[i].result.is_hidden = 1;
                    }
                }
            }
        }
        setOpenDeleteAnswerModal({ active: false, test_result_id: null, hidden_result: false })

    }

    const reportAnswerModal = (id) => {
        setOpenReportAnswerModal({ active: true, answer_id: id });
    }
    const closeReportAnswerModal = (data) => {
        setOpenReportAnswerModal({ active: false, answer_id: null });
    }

    const searchData = (search) => {
        setSearch(search);


        // if search record from aggregated data
        if (metaData.userType === 'aggregated') {
            const dataAnswer = questionArray.filter((item, indexItem) => indexItem + 1 === isActive)[0];
            if (dataAnswer) {
                const newAggregrateArray = dataAnswer.aggregate.filter(items => items.option.toLowerCase().indexOf(search.toLowerCase()) > -1);
                setAggregrateArray(newAggregrateArray)
            }
        } else {
            setSearchLoading(true);
            let token = ReactSession.get("token");
            getLoadMoreSurveyQuestionData({
                test_id: test_id,
                test_question_id: metaData.test_question_id,
                page: 1,
                type: metaData.questionType,
                search: search,
                questiontype: "Survey"
            }, token).then((response) => {
                setSearchLoading(false);

                if (response.success) {
                    setAnswerArray(response.data);
                    setMetaData({ ...metaData, page: 1, load_more: response.more_records });

                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }

    }

    const changeUserType = (type) => {

        changeActiveQuestion(isActive, questionArray);
        setMetaData({ ...metaData, 'userType': type })
        setSearch("");
    }

    // highlight text if matches
    const escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    );
    const Highlight = ({ children = '' }) => {
        const patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
        const parts = String(children).split(patt);
        if (search) {
            return parts.map((part, index) => (
                patt.test(part) ? <mark className={'search-highlight'} key={index}>{part}</mark> : part
            ));
        } else {
            return children;
        }
    };


    const renderMultipleCHoiceQuestionBarChart = () => {
        var lChartData = [];

        var index = 0;

        var data = chartData;


        var colors = [
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgb(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(47, 46, 85)", text: "white-text" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(165, 159, 255)", text: "" },
            { color: "rgb(194, 194, 210)", text: "" }
        ];


        var max = 0;
        for (var key in data) {

            if (data[key] > max) {
                max = data[key];
            }
        }
        var sum = 0;
        for (var key in data) {

            sum += data[key];
        }

        var bars = [];

        for (var key in data) {
            var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;

            var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

            var color = colors[6];
            if (index < 6) {

                color = colors[index];
            }

            lChartData.push({
                label: key,
                value: data[key],
                color: color,
                percentage_label: percentage + "%"
            });

            index++;

            bars.push((
                <div className="edu-graph-data-repeat">
                    <div className="edu-grph-data-lhs">
                        <span className={`${test.language=="ar"?"cairo-font":""}`}>{key}</span>
                        <b>-</b>
                    </div>
                    <div className={`edu-grph-data-rhs `}>
                        <div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
                            style={{ width: percentage + "%" }}
                        >
                            <div
                                className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
                                tooltip={data[key] > 1 ? data[key] + " Votes" : data[key] + ' Vote'}
                                style={{ backgroundColor: color.color }}
                            >

                            </div>
                            <span className={`${data[key] != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
                        </div>
                    </div>
                </div>
            ))
        }
        if (lChartData.length > 0) {
            return <div className="edu-label-graph-bg"><div> {bars}</div></div>;
            //<BarChart data={lChartData} width={300} height={300} />
        } else {
            return <div className="info-not-available">No info available</div>
        }
    }

    const renderMultipleCHoiceQuestionBarChart2 = () => {
        var lChartData = [];

        var index = 0;

        var data = chartData;


        var colors = [
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgb(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(47, 46, 85)", text: "white-text" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(165, 159, 255)", text: "" },
            { color: "rgb(194, 194, 210)", text: "" }
        ];


        var max = 0;
        for (var key in data) {

            if (data[key].value > max) {
                max = data[key].value;
            }
        }
        var sum = 0;
        for (var key in data) {

            sum += data[key].value;
        }

        var bars = [];

        for (var key in data) {
            var percentage = max > 0 ? Math.round(((data[key].value / max) * 100) * 100) / 100 : 0;

            var percentage_label = sum > 0 ? Math.round(((data[key].value / sum) * 100) * 100) / 100 : 0;

            var color = colors[6];
            if (index < 6) {

                color = colors[index];
            }

            lChartData.push({
                label: data[key].label,
                value: data[key].value,
                color: color,
                percentage_label: percentage + "%"
            });

            index++;

            bars.push((
                <div className="edu-graph-data-repeat">
                    <div className="edu-grph-data-lhs">
                        <span className={`${test.language=="ar"?"cairo-font":""}`}>{data[key].label}</span>
                        <b>-</b>
                    </div>
                    <div className={`edu-grph-data-rhs `}>
                        <div class={`edulabel-width-wrap ${percentage <= 30 ? 'small-width-chart-area' : ''} ${percentage >= 1 ? 'small-width-chart-area-1' : ''}`}
                            style={{ width: percentage + "%" }}
                        >
                            <div
                                className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
                                tooltip={data[key].value > 1 ? data[key].value + " Votes" : data[key].value + ' Vote'}
                                flow="down"
                                style={{ backgroundColor: color.color }}
                            >

                            </div>
                            <span className={`${data[key].value != 0 ? color.text : ''}`}>{percentage_label + "%"}</span>
                        </div>
                    </div>
                </div>
            ))
        }
        if (lChartData.length > 0) {
            return <div className="edu-label-graph-bg"><div> {bars}</div></div>;
            //<BarChart data={lChartData} width={300} height={300} />
        } else {
            return <div className="info-not-available">No info available</div>
        }
    }

    function sortByValue(jsObj) {
        var sortedArray = [];
        for (var i in jsObj) {
            // Push each JSON Object entry in array by [value, key]
            sortedArray.push([jsObj[i], i]);
        }
        return sortedArray.sort().reverse();
    }

    const renderRankingQuestionBarChart = () => {
        var lChartData = [];

        var index = 0;

        var data1 = rankingchartData;
        var data2 = sortByValue(data1);
        const data = {};
        data2.forEach((item) => {
            data[item[1]] = item[0]
        });

        var colors = [
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
        ];


        var max = 0;
        for (var key in data) {

            max = max + 1;
        }
        var sum = 0;
        for (var key in data) {

            sum += data[key];
        }

        var bars = [];

        for (var key in data) {
            var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;

            var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;

            var color = colors[6];
            if (index < 6) {

                color = colors[index];
            }

            lChartData.push({
                label: key,
                value: data[key],
                color: color,
                percentage_label: percentage + "%"
            });
            console.log(lChartData[0].label);

            index++;

            bars.push((
                <div className="edu-graph-data-repeat">
                    <div className="edu-grph-data-lhs">
                        <span className={`${test.language=="ar"?"cairo-font":""}`}>{key}</span>
                        <b>-</b>
                    </div>
                    <div className={`edu-grph-data-rhs `}>
                        <div
                            className={`edu-graph-count ${percentage == 0 ? 'p-0' : ''}`}
                            tooltip={"Average score: " + Math.round(data[key] * 100) / 100}
                            flow="down"
                            style={{ backgroundColor: color.color, width: percentage + "%" }}
                        >
                            <span style={percentage == 0 ? { right: '-18px' } : {}} className={`${data[key] != 0 ? color.text : ''}`}>{Math.round(data[key] * 100) / 100}</span>
                        </div>
                    </div>
                </div>
            ))
        }
        if (lChartData.length > 0) {
            return (<div>
                <div className="highest-ranked-info d-flex">
                    <img src={process.env.REACT_APP_URL + "images/recommend-icon.svg"} alt="img" />
                    {(lChartData[0].value!=lChartData[1].value) && 
                    <div className='info-right-highest'><span className={`highest-ranked-option ${test.language=="ar"?"cairo-font":""}`}>{lChartData[0].label}</span> was ranked higher than the rest by the participants.</div>
                    }
                    {(lChartData[0].value==lChartData[1].value) && 
                    <div className='info-right-highest'><span className={`highest-ranked-option ${test.language=="ar"?"cairo-font":""}`}>{lChartData[0].label}</span> and <span className={`highest-ranked-option ${test.language=="ar"?"cairo-font":""}`}>{lChartData[1].label}</span> were ranked higher than the rest by the participants.</div>
                    }
                    {/* {(lChartData[0].value==lChartData[1].value==lChartData[2].value) && 
                    <div className='info-right-highest'><span className='highest-ranked-option'>{lChartData[0].label}</span>, <span className='highest-ranked-option'>{lChartData[1].label}</span> and <span className='highest-ranked-option'>{lChartData[2].label}</span> were ranked higher than the rest by the participants.</div>
                    } */}
                </div>
                <div className='barchart-header d-flex justify-content-between'>
                    <h3>Bar chart</h3>
                    <span className="text-small-info">{metaData.totalResultCount}
                        {metaData.totalResultCount == 1 ? ' answer' : ' answers'}
                    </span>
                </div>
                <div className="edu-label-graph-bg">
                    <div> {bars}</div>
                </div>
            </div>);
            //<BarChart data={lChartData} width={300} height={300} />
        } else {
            return <div className="info-not-available">No info available</div>
        }
    }

    const renderRankingQuestionTable = () => {

        var colors = [
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
            { color: "rgba(47, 46, 85)", text: "white-text" },
            { color: "rgba(89, 89, 114)", text: "white-text" },
            { color: "rgb(150, 255, 252)", text: "" },
            { color: "rgb(255, 102, 173)", text: "white-text" },
            { color: "rgb(119, 225, 254)", text: "" },
        ];

        var tableData = [];
        for (var i in rankingTableData[0])
            tableData.push([i, rankingTableData[0][i]]);
        tableData.sort(compare);

        return (
            <div className='ranking-table-holder'>
                <table className="prefer-aggregatd-data-table ranking-table">
                    <thead>
                        <tr>
                            <th>Answers</th>
                            {tableData.map(function (item, index) {
                                return (<th>{index + 1 == 1 ? "1st" : index + 1 == 2 ? "2nd" : index + 1 == 3 ? "3rd" : index + 1 + "th"}</th>)
                            })}
                            <th className="score-heading-table"><b>Score</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map(function (item, index) {
                            return (
                                <tr className={`${index == 0 ? "ranking-table-first-row" : ""}`}>
                                    <td>
                                        <div className='d-flex table-answer-column-outer'>
                                            <div style={{ backgroundColor: colors[index].color, width: "25px", height: "25px" }} className='table-color-box'></div>
                                            <span className={`table-answer-column ${test.language=="ar"?"cairo-font":""}`}>{item[0]}</span>
                                        </div>
                                    </td>
                                    {tableData.map(function (itemm, i) {
                                        return (
                                            <td>
                                                <div className='d-flex table-answer-column-votes'>
                                                    <span className='table-votes'>{item[1]["votes"][i + 1]}</span>
                                                    <span className='table-percentage'> ({Math.round((item[1]["votes"][i + 1] * 100 / metaData.totalResultCount) * 100) / 100}%)</span>
                                                </div>

                                            </td>
                                        )
                                    })}
                                    <td>
                                        {Math.round(item[1]["averageranking"] * 100) / 100}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='ranking-table-footer'>
                    Total: <span>{metaData.totalResultCount == 1 ? metaData.totalResultCount + ' response' : metaData.totalResultCount + ' responses'}</span>
                </div>
            </div>
        )
    }

    const renderRatingScaleBarChart = () => {


        var lChartData = [];

        var index = 0;

        var data = chartData;

        var colors = ["rgb(119, 225, 254)", "rgb(85, 86, 111)", "rgb(150, 255, 252)", "rgb(47, 46, 85)", "rgb(255, 102, 173)", "rgb(194, 194, 210)", "rgb(150, 155, 255)"];


        var max = 0;
        for (var key in data) {

            if (data[key] > max) {
                max = data[key];
            }
        }
        var sum = 0;
        for (var key in data) {

            sum += data[key];
        }
        var bars = [];

        for (var key in data) {
            var percentage = max > 0 ? Math.round(((data[key] / max) * 100) * 100) / 100 : 0;
            var percentage_label = sum > 0 ? Math.round(((data[key] / sum) * 100) * 100) / 100 : 0;
            var color = colors[6];
            if (index < 6) {

                color = colors[index];
            }

            lChartData.push({
                label: key,
                percentage: percentage_label,
                value: data[key],
                color: color,
                percentage_label: percentage_label + "%",
            });

            index++;



        }
        console.log(lChartData);
        if (lChartData.length > 0) {
            return <div className="edu-label-graph-bg"><BarChartRatingScale data={lChartData} test={test}/></div>;

        } else {
            return <div className="info-not-available">No info available</div>
        }
    }


    return (
        <LayoutResearcherFullWidth fixed_header_target={true} isLoading={isLoading} wrapClass={"researcher-test-view-overflow overflow-visible"} skipCheck={false} extendedFooter={false} activeMenu={""}>
            {test &&
                <div className="analyze-results-page-wrapper">


                    <Topbar test={test} callbackTopbar={callbackTopbar} />

                    <div className="container inner-page-container">
                        <div className="sp5-content-wrap analyze-overflow-wrap">

                            <Sidebar test={test} planType={planType} activeMenu={location.pathname.split("/").pop()} />


                            <div className="sp5-right-side-wrap survey-analy-section-wrap">
                                {sectionLoading &&
                                    <LoadingIcon />
                                }
                                {!sectionLoading && questionArray && questionArray.length > 0 && resultCount > 0 &&
                                    <div className="bd-question-view-wrap">
                                        <div className="bd-question-top-slide d-flex justify-content-between">
                                            <div className="bd-que-slide-left">
                                                <p className="text-small-info text-uppercase">{isActive} <span className='text-lowercase'>of</span> {questionArray.length} - {" "}
                                                    {(metaData.questionType === 'singlechoice') ? 'Single choice' :
                                                        (metaData.questionType === 'multiplechoice') ? 'Multiple choice' :
                                                            (metaData.questionType === 'free') ? 'free text' :
                                                                (metaData.questionType === 'rankingscale') ? 'Ranking Scale' :
                                                                    (metaData.questionType === 'ratingscale') ? 'Rating Scale' :
                                                                        (metaData.questionType === 'likertscale') ? 'Likert Scale' :
                                                                            <>{metaData.questionType}</>
                                                    }
                                                </p>
                                                <div className="db-que-list-wrap">
                                                    <Dropdown className="dropdown">
                                                        <Dropdown.Toggle className="dropdown-toggle">
                                                            {questionArray && questionArray.map((items, index) => {
                                                                return (
                                                                    <>
                                                                        {(isActive === index + 1) &&
                                                                            <div className={`${test.language=="ar"?"cairo-font":""}`}>{items.questions.question}</div>
                                                                        }
                                                                    </>
                                                                );
                                                            })}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>

                                                            {questionArray && questionArray.map((items, index) => {
                                                                return (
                                                                    <>
                                                                        <Dropdown.Item key={"question" + index} className={`${(isActive === index + 1) ? 'active-item' : ''} ${test.language=="ar"?"cairo-font":""}`}  onClick={() => changeActiveQuestion(index + 1, questionArray)}>
                                                                            {items.questions.question}
                                                                        </Dropdown.Item>
                                                                    </>
                                                                );
                                                            })}


                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="bd-que-slide-arrow">
                                                <span>
                                                    <a onClick={() => showPreQuestion("increase")}
                                                        className={`${isActive === 1 ? 'disabled-link' : ''} `}>
                                                        <img src={process.env.REACT_APP_URL + "images/arrow-left.png"} />
                                                    </a>
                                                </span>
                                                <span>
                                                    <a onClick={() => showPreQuestion("decrease")}
                                                        className={`${questionArray.length === isActive ? 'disabled-link' : ''} `}>
                                                        <img src={process.env.REACT_APP_URL + "images/arrow-right.png"} />
                                                    </a>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="bd-que-table-wrapper">
                                            <div className="bd-que-table-search-wrap">
                                                <div className="bd-question-search-left">
                                                    {(metaData.questionType !== 'rankingscale') && (
                                                        <span className="text-small-info">{metaData.totalResultCount}
                                                            {metaData.totalResultCount == 1 ? ' answer' : ' answers'}
                                                        </span>
                                                    )}

                                                    {((metaData.questionType !== 'free') && (metaData.questionType !== 'rankingscale') && (metaData.questionType !== 'likertscale')) &&
                                                        <div className="bd-question-radio-data">

                                                            <div className="checkboxes radio_btn_class  d-flex">
                                                                <Radiobtn
                                                                    id={`chart-select`}
                                                                    isSelectedlabelBold={true}
                                                                    name="bdq"
                                                                    value="chart"
                                                                    checked={(metaData.userType === "chart") ? true : false}
                                                                    onChange={(e) => { changeUserType("chart") }}
                                                                    label="Chart" />

                                                                <Radiobtn
                                                                    id={`aggregated-select`}
                                                                    isSelectedlabelBold={true}
                                                                    name="bdq"
                                                                    value="aggregated"
                                                                    checked={(metaData.userType === "aggregated") ? true : false}
                                                                    onChange={(e) => { changeUserType("aggregated") }}
                                                                    label="Aggregated data" />

                                                                <Radiobtn
                                                                    id={`by-user-select`}
                                                                    isSelectedlabelBold={true}
                                                                    name="bdq"
                                                                    value="by-user"
                                                                    checked={(metaData.userType === "by-user") ? true : false}
                                                                    onChange={(e) => { changeUserType("by-user") }}
                                                                    label="By user" />
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                                {metaData.userType != 'chart' &&
                                                    <div className="tree-search-wrap">
                                                        <div className="tree-search-inner">
                                                            <div className="input form-group input-field mb-0">
                                                                <input type="text" placeholder="Search" value={search} onChange={(e) => searchData(e.target.value)} />
                                                            </div>
                                                            <span className="tree-search-icon">
                                                                {searchLoading && (
                                                                    <i
                                                                        className="fa fa-spinner fa-spin"
                                                                        aria-hidden="true"
                                                                        style={{ marginLeft: "5px" }}
                                                                    ></i>
                                                                )}
                                                                <img src={process.env.REACT_APP_URL + "images/search-icon.svg"}
                                                                    alt="search" />
                                                            </span>
                                                        </div>
                                                    </div>}
                                            </div>
                                            <div className={`bd-que-ans-table-data-inner survey-anly-data-table ${(metaData.userType === 'aggregated' && metaData.questionType !== 'free') ? 'bd-que-aggregated-table survey-anly-aggregated-table' : ''}`}>
                                                {(metaData.userType === 'chart') && (metaData.questionType == 'singlechoice') &&
                                                    <div className='d-flex align-items-center survey-singlechoice-chart-wrap'>
                                                        <div className='chart-info-wrap'>
                                                            {sortedchartData.map((element, index) => (
                                                                (element[1] > 0) && (
                                                                    <div className='chart-info-repeat' key={index}>
                                                                        <span style={{ display: "inline-block", backgroundColor: `${colorArray[index]}` }}></span><p className={`${test.language=="ar"?"cairo-font":""}`}>{element[0]}</p>
                                                                    </div>
                                                                )

                                                            ))}
                                                        </div>


                                                        <div className="parti-ga-grapg-area">
                                                            <PieChartSingleChoice colors={["rgb(252,131,189)", "rgb(248,160,206)", "rgb(245,190,222)", "rgb(241,219,239)", "rgb(241,219,239)"]} data={chartData} width={300} height={300} animate={true} test={test}/>
                                                        </div>

                                                    </div>
                                                }
                                                {(metaData.userType === 'chart') && (metaData.questionType == 'multiplechoice') &&
                                                    <div className="parti-ga-grapg-area">
                                                        {renderMultipleCHoiceQuestionBarChart2()}
                                                    </div>
                                                }

                                                {(metaData.userType === 'chart') && (metaData.questionType == 'ratingscale') &&
                                                    <div className="parti-ga-grapg-area">
                                                        {renderRatingScaleBarChart()}
                                                    </div>
                                                }


                                                <div className="table-responsive sort-loading-table">
                                                    {sortLoading &&
                                                        <div className="sort-loading">
                                                            <LoadingIcon />
                                                        </div>
                                                    }
                                                    <table>
                                                        <thead>
                                                            {(metaData.userType === 'by-user') &&
                                                                <tr>
                                                                    <th className="bd-thcol-1">Participant</th>
                                                                    <th className="bd-thcol-2">Answer</th>
                                                                    <th className="bd-thcol-3">
                                                                        <div onClick={() => sortData('time')} className={`${(metaData.sort_time) ? metaData.sort_time : ''} col-sort justify-content-between`}>
                                                                            Time spent <span>
                                                                                {metaData.sort_time ?
                                                                                    <img src={
                                                                                        process.env.REACT_APP_URL + "images/sort_enable.svg"
                                                                                    } alt="icon" width={20} />
                                                                                    :
                                                                                    <img src={
                                                                                        process.env.REACT_APP_URL + "images/sort_disable.svg"
                                                                                    } alt="icon" width={20} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bd-thcol-4">
                                                                        <div onClick={() => sortData('date')} className={`${(metaData.sort_date) ? metaData.sort_date : ''}  col-sort justify-content-between`}>
                                                                            Date <span>
                                                                                {metaData.sort_date ?
                                                                                    <img src={
                                                                                        process.env.REACT_APP_URL + "images/sort_enable.svg"
                                                                                    } alt="icon" width={20} />
                                                                                    :
                                                                                    <img src={
                                                                                        process.env.REACT_APP_URL + "images/sort_disable.svg"
                                                                                    } alt="icon" width={20} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            }
                                                            {(metaData.userType === 'aggregated' && metaData.questionType !== 'free') &&
                                                                <tr className='survey-aggregated-data'>
                                                                    <th className="pl-0">List of choices</th>
                                                                    <th className="text-right">
                                                                        <div onClick={() => sortData('vote')} className={`${(metaData.sort_vote) ? metaData.sort_vote : ''} col-sort`}>
                                                                            Responses <span>
                                                                                {metaData.sort_vote ?
                                                                                    <img src={
                                                                                        process.env.REACT_APP_URL + "images/sort_enable.svg"
                                                                                    }
                                                                                        alt="icon" width={20} />
                                                                                    :
                                                                                    <img src={
                                                                                        process.env.REACT_APP_URL + "images/sort_disable.svg"
                                                                                    } alt="icon" width={20} />

                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="text-right">% of total</th>
                                                                    <th className="text-right">Participants from panel</th>
                                                                    <th className="text-right">Unknown participants</th>
                                                                </tr>
                                                            }
                                                        </thead>
                                                        <tbody>
                                                            {answerArray && (metaData.userType === 'by-user') && answerArray.map((result, index) => {

                                                                return (
                                                                    <tr id={`hide-row-${result.id}`} className={` ${(result.result && result.result.is_hidden) ? 'disabled-row' : ''}`}>
                                                                        <td className="bd-q-pat-col">
                                                                            <b>
                                                                                {result.result &&
                                                                                    result.result.user_type
                                                                                }
                                                                                {" "}


                                                                                {result.result && result.result.result_id ?
                                                                                    result.result.result_id
                                                                                    :
                                                                                    index + 1
                                                                                }


                                                                            </b>
                                                                        </td>
                                                                        <td className="bd-q-ans-col">
                                                                            <div className={`bd-qu-td-ans ${test.language=="ar"?"cairo-font":""}`}>
                                                                                {result.type === 'free' &&
                                                                                    <><Highlight>{result.answer}</Highlight></>
                                                                                }

                                                                                {result.type === 'single' &&
                                                                                    <><Highlight>{result.option}{result.option == "Other" && result.other_option_value && ": ".concat(result.other_option_value)}{result.option == "آخر" && result.other_option_value && ": ".concat(result.other_option_value)}</Highlight></>
                                                                                }

                                                                                {result.type === 'multiple' && result.option.map((items, index) => {
                                                                                    return (
                                                                                        <p key={index} className={`${test.language=="ar"?"cairo-font":""}`}>
                                                                                            <Highlight>{items}{items == "Other" && result.other_option_value && ": ".concat(result.other_option_value)}{items == "آخر" && result.other_option_value && ": ".concat(result.other_option_value)}</Highlight>
                                                                                        </p>
                                                                                    );
                                                                                })
                                                                                }

                                                                                {result.type === 'ratingscale' &&
                                                                                    <><Highlight>{result.option}</Highlight></>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="bd-time-col"><b>{calculateTime(result.time_spent)}</b></td>
                                                                        <td className="bd-q-date-col">
                                                                            <div className="bd-col-action-wrap d-flex">
                                                                                <span>{moment(result.created_at).format('DD/MM/Y')}</span>
                                                                                <span
                                                                                    className="pro-menu-icon small-dropdown mid-menu-wrap dropdown-withclose-btn">

                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle
                                                                                            id={"dropdownMenuButton" + result.id}
                                                                                            className="dropdown-toggle"
                                                                                        >
                                                                                            <img
                                                                                                src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                                                                                                alt="img"
                                                                                            />
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            <div className="dropdown-close">
                                                                                                <Dropdown.Toggle>
                                                                                                    <img
                                                                                                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                                                                        alt="img"
                                                                                                    />
                                                                                                </Dropdown.Toggle>
                                                                                            </div>
                                                                                            <div className="drop-down-link-hold">
                                                                                                <Dropdown.Item onClick={() => hideParticipantModal(result.result.id, (result.result && result.result.is_hidden) ? true : false)}>
                                                                                                    {(result.result && result.result.is_hidden) ? 'Show participant' : 'Hide participant'}
                                                                                                    <span>
                                                                                                        <img
                                                                                                            src={
                                                                                                                process.env.REACT_APP_URL +
                                                                                                                "images/eye.svg"
                                                                                                            }
                                                                                                            alt="img"
                                                                                                        />
                                                                                                    </span>
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item onClick={() => reportAnswerModal(result.result.id)}>
                                                                                                    Report participant
                                                                                                    <span>
                                                                                                        <img
                                                                                                            src={
                                                                                                                process.env.REACT_APP_URL + "images/warning.svg"
                                                                                                            }
                                                                                                            alt="img"
                                                                                                        />
                                                                                                    </span>
                                                                                                </Dropdown.Item>
                                                                                            </div>

                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>

                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}

                                                            {aggregrateArray && (metaData.userType === 'aggregated' && metaData.questionType !== 'free') && aggregrateArray.map((result, index) => {
                                                                return (
                                                                    <tr className='survey-aggregated-data-row' key={index}>
                                                                        <td><div className={`lc-column ${test.language=="ar"?"cairo-font":""}`}><Highlight>{result.option}</Highlight></div></td>
                                                                        <td ><b>{result.vote}</b></td>
                                                                        <td className="text-right">{calculateAggregatePercentage(result.vote)} %</td>
                                                                        <td className="text-right"><b>{result.total_tester}</b></td>
                                                                        <td className="text-right"><b>{result.total_guest}</b></td>
                                                                    </tr>
                                                                );

                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                {(metaData.userType === 'by-user') && metaData.load_more &&
                                                    <div onClick={() => loadMore()} className="load-more-data">

                                                        <span>
                                                            {!loadMoreLoading &&
                                                                "Load more "
                                                            }
                                                            {loadMoreLoading &&
                                                                "Loading "
                                                            }
                                                            <img
                                                                src={process.env.REACT_APP_URL + "images/dotts.png"}
                                                                alt="load-more" />
                                                        </span>

                                                    </div>
                                                }
                                            </div>
                                            {metaData.questionType == 'likertscale' &&
                                                (<div className={`likert-area-chart ${test.language=="ar"?"cairo-font":""}`}>
                                                    <AnyChart
                                                        {...chart1_settings}
                                                    />
                                                </div>)
                                            }
                                            {metaData.questionType == 'rankingscale' &&
                                                (<div className="parti-ga-grapg-area">
                                                    <div className="barchart-ranking-scale">
                                                        {renderRankingQuestionBarChart()}
                                                    </div>
                                                    <div className="ranking-scale-table">
                                                        {renderRankingQuestionTable()}
                                                    </div>
                                                    <div className="ranking-page-infodata-wrap">
                                                      <h3>How to read this bar chart?</h3>
                                                      <p>The bar chart is based on the score each ranked option has received. The scores represent the average ranking.</p>
                                                      <h3>How the scores are calculated?</h3>
                                                      <p>Think of the score as a weighted average. Suppose you have 5 options for testers to rank. The testers’ most preferred option (ranked as #1 most of the times) is given the largest weight (in this case 5). Their least preferred option has a weight of 1. The weights are then multiplied by the numbers of testers selected a particular option. 
                                                      Their sum is divided by the total number of testers.</p>                                                      
                                                    
                                                      <div className="response-text-wrap">
                                                        <p>X<sub>1</sub> W<sub>1</sub> + X<sub>2</sub> W<sub>2</sub>
                                                        + X<sub>3</sub> W<sub>3</sub> + ... + X<sub>n</sub> W<sub>n</sub></p>
                                                        <span>Total responses</span>
                                                      </div>
                                                      <div className="ranking-wrap-info-bottom">
                                                      Where:
                                                      <p>X - number of testers</p>
                                                      <p>W - weight of the ranked option</p>
                                                      </div>
                                                      
                                                    </div>
                                                </div>)
                                            }
                                        </div>


                                        <div className='bd-question-bottom-pagination-arrow'>
                                            <div className="bd-que-slide-arrow">
                                                <span>
                                                    <a onClick={() => showPreQuestion("increase")}
                                                        className={`${isActive === 1 ? 'disabled-link' : ''} `}>
                                                        <img src={process.env.REACT_APP_URL + "images/arrow-left.png"} />
                                                    </a>
                                                </span>
                                                <span>
                                                    <a onClick={() => showPreQuestion("decrease")}
                                                        className={`${questionArray.length === isActive ? 'disabled-link' : ''} `}>
                                                        <img src={process.env.REACT_APP_URL + "images/arrow-right.png"} />
                                                    </a>
                                                </span>
                                            </div>
                                        </div>


                                    </div>
                                }

                                {!sectionLoading && questionArray && questionArray.length === 0 &&
                                    <p className={"info-not-available big-font"}>
                                        You have not added any questions to your test
                                    </p>
                                }
                                {!sectionLoading && resultCount === 0 &&
                                    <p className={"info-not-available big-font"}>
                                        There are no results available yet
                                    </p>
                                }
                            </div>


                        </div>
                    </div>
                    <HideParticipant
                        test_id={test_id}
                        openModal={openDeleteAnswerModal}
                        closeModal={closeDeleteAnswerModal} />
                    <ReportAnswer
                        test_id={test_id}
                        openModal={openReportAnswerModal}
                        closeModal={closeReportAnswerModal} />
                </div>
            }
        </LayoutResearcherFullWidth>
    )
}