//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {validateResultData} from "../../../services/test_result";
import toast from "react-hot-toast";
import CheckboxNormal from "../../layouts/elements/fields/checkbox_normal";


export default function NdaModal({openModal,closeModal,result,timeSpent,testLanguage}) {

    const [formErrors, setFormErrors] = useState({ privacy: null, privacy_error_class: null });

    const [formValues, setFormValues] = useState({  privacy: false });

    const [ndaLoading, setNdaLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        // reset form values when popup again open
        if(!openModal){
            setFormValues({privacy: false});
        }
    },[openModal]);



    const validateNda = (e)=>{

        e.preventDefault();
        if(!formValues.privacy){
            toast(
                <div className="toastinner">
                    Please accept NDA policy
                    <a onClick={() => {
                        toast.dismiss();
                    }}>&times;</a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: 2000,
                }
            );
        }else{
            const token = ReactSession.get("token");
            const data = new FormData();
            data.append("test_id", result.test_id);
            data.append("guest_token", ReactSession.get("guest_token"));
            data.append("validate", 'nda');
            data.append("result_id", result.id);
            data.append("type", 't');
            data.append("time_spent", timeSpent);

            setNdaLoading(true);
            validateResultData(data, token).then((response) => {
                setNdaLoading(false);

                if (response.success) {
                    closeModal(true);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    }

    return (
        <Modal
            show={openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal nda-agreement-modal"
        >
            <Modal.Body className="modal-lg p-0">
                <div className={`modal-inner-text p-64 text-left ${(testLanguage=="ar") ? "arabic_wrapper" : ""}`}>
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                           // closeModal(false);
                            {!(ReactSession.get("token")) ?navigate('/tester/login'):navigate('/')}
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <h2>{testLanguage=="en"?"Before we begin…":"قبل أن نبدأ..."}</h2>
                   
                    <p className="w-100">{testLanguage=="en"?"Each test on UserQ is protected by our privacy policy, meaning that any data collected is confidential and contents should not be shared."
                    :"كل اختبار في UserQ محمي بموجب سياسة NDA الخاصة بنا ، مما يعني أن أي بيانات يتم جمعها والمعلومات المقدمة سرية. يرجى تحديد المربع أدناه لتأكيد موافقتك على الالتزام بسياسة الخصوصية الخاصة بنا."}</p>

                    <div className="account-term-and-condition-wrap">
                        <CheckboxNormal
                            value={formValues.privacy}
                            onChange={(e)=>setFormValues({...formValues,"privacy":!formValues.privacy})}
                            id={"customCheck1"}
                            darkCheckbox={"dark-checkbox"}
                            checked={formValues.privacy ? true : false }
                            label={testLanguage=="en"?<> I agree to the UserQ <b><a href={process.env.REACT_APP_SITE_URL+"privacy-policy"} target="_blank">privacy policy</a></b> and won’t discuss the contents of the test I am about to take.</>:<>أوافق على <b>UserQ  <a href={process.env.REACT_APP_SITE_URL+"privacy-policy"} target="_blank">سياسة الخصوصية</a></b> ولن أناقش محتويات الاختبار الذي أنا على وشك إجرائه.</>}
                        />
                    </div>
                    <div className="button-wrap d-flex nda-agreement-modal-button">
                        <button type="button" onClick={() => {
                            //closeModal(false);
                            {!(ReactSession.get("token")) ?navigate('/tester/login'):navigate('/')}
                        }} className="button secondary-btn">{testLanguage=="en"?"cancel":"إلغاء"}</button>
                        <button type="button" onClick={(e)=>validateNda(e)}
                                className={`button  primary-btn  ${!(formValues.privacy)?'disabled-button':''}`}>
                            {testLanguage=="en"?"Agree":"موافق"}
                            {ndaLoading && (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                ></i>
                            )}
                        </button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}