import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import Layout from "../../layouts/layout";
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
import Accordian from '../../Accordian';



export default function TesterSupport() {

    const navigate = useNavigate();

    const [accordianData] = useState([
        {
            id: 1,
            title: 'What is UserQ?',
            info: 'UserQ is a user research platform that enables designers, managers and researchers to gain unique insights from testers in the MENA region about their digital products. You can build and publish your own tests, in English or Arabic, and easily analyse any data you collect through your UserQ account.\n' +
                '\n <br/><br/>' +
                'For testers, UserQ is a platform that pays you for your insights and opinions – it really is as simple as that.'
        },
        {
            id: 2,
            title: ' How does UserQ work?',
            info:
                'We bring researchers and testers together on an easily-accessible, powerful research platform. Researchers can easily create, publish and analyse tests that are filled out by real people in the MENA region (testers). Once a test goes live, the UserQ platform finds the perfect testers based on the parameters set by the researcher, and results can come in after as soon as a few minutes.\n' +
                '\n <br/><br/>' +
                'Using the UserQ platform, researchers get the invaluable insights they need to support their projects and design process in real time, and testers get paid for their time and thoughts.',
        }
    ]);

    const [accordianSecondData] = useState([
        {
            id: 1,
            title: ' What happens if I forget my password?',
            info: 'If you forget your login details, all you have to do is click the forgot password link on the login page. You’ll receive a reset link on the email you signed up with, and choose a new password.'
        },
        {
            id: 2,
            title: '  I signed up as a tester, but I now want to run my own tests. How do I change my account? ',
            info:'To run your own tests on the UserQ platform, you’ll need to have a research account with us using a different email address to your test account.'
                 },
        {
            id: 3,
            title: ' How do I close my UserQ account?',
            info:'If you need to close your UserQ account for any reason, it’s a simple process. Once you’re logged into your account, go to ‘Edit profile’, where you will see a ‘Delete your account’ option. We’ll delete your account, along with any personal information you’ve shared with us too.'
        }
    ]);

    const [accordianThirdData] = useState([
        {
            id: 1,
            title: '  Is my data secure on the UserQ platform?',
            info: 'All data that you provide in setting up your account, including private information and payment details – is safe with us. We are completely compliant with all data privacy laws in the UAE, and your security is our top priority.'
        },
        {
            id: 2,
            title: '   How does UserQ use my personal information?  ',
            info:'The UserQ platform works by matching your profile to tests where researchers are looking for insights from a specific group of people. This means that we’ll use your demographic data such as gender, age or occupation to match you to the right tests. The researchers who create the tests won’t have access to this information – it’s only used by us at UserQ to make sure we send you the right tests.\n' +
                '\n <br/> <br/>' +
                'Your data is always stored securely and safely on UserQ – only you will be able to access your sensitive information such as payment details and passwords.'
        }
    ]);

    const [accordianFourData] = useState([
        {
            id: 1,
            title: '   How do I register with UserQ? ',
            info: 'Creating an account with UserQ is quick and easy – all you need to do is fill in a profile and start taking tests. You can register as a tester here.'
        },
        {
            id: 2,
            title: '    What do I need to get started? ',
            info:'It’s easy to become a UserQ tester, and you just need to take a few steps to get started:\n' +
                '<br/><br/><ul><li>Create a UserQ tester account on a PC or mobile device\n </li>' +
                '<br/><li>Fill out your details so we can match you to the right tests\n </li>' +
                '<br/><li>Wait to be selected!</li></ul>'
        }
    ]);

    const [accordianFifthData] = useState([
        {
            id: 1,
            title: '   How often can I take UserQ tests?  ',
            info: 'Whenever you fit the right requirements that a researcher is looking for, we’ll send you an email or notification to let you know you’ve been selected, and you can take the test. Please note that you can only complete one test at a time – so make sure you complete the one you’re on before moving onto another.'
        },
        {
            id: 2,
            title: '    If I don’t complete a test, will I still be paid? ',
            info:"As a UserQ tester, you must get to the end of the test that you’re taking until you see a message that confirms that you’ve completed the test in order to be paid for your insights. <br/>Guest testers aren’t compensated for their time, but you can create an account with us at UserQ to start earning money for your insights."
        },
        {
            id: 3,
            title: '     How can I increase my chances of being selected for a test?  ',
            info:"Because researchers are often looking for insights from a specific group of people, there isn’t a lot you can do to increase your chances of being chosen for a testing panel. But be patient, as our community of researchers are gathering insights for a range of digital experiences!"
        }
    ]);

    const [accordianSixData] = useState([
        {
            id: 1,
            title: '    How long does a test run for?  ',
            info: 'The researchers who create the test decide how long it runs for. That’s why it’s best that when you receive a notification for a test, you complete it as soon as you can. If you leave it for a few days, the test may end.'
        },
        {
            id: 2,
            title: '    How do I take tests on UserQ? ',
            info:'Each test you take on UserQ will be different, but you’ll find clear and easy to follow instructions on each one. All you have to do is open the test and follow along.\n' +
                '\n <br/><br/>' +
                'Remember, there are no wrong answers when taking a UserQ test – the most important thing is that you’re honest and express your opinions. Your insights are invaluable to the researchers who create the tests.'
        },
        {
            id: 3,
            title: '    I think there is an error with the test I’m taking. What should I do?  ',
            info:"If you notice a glitch in your test or that something doesn’t seem to be working, you should get in touch with us at UserQ."
        }
    ]);

    const [accordianSevenData] = useState([
        {
            id: 1,
            title: '   When do I receive my rewards? ',
            info: 'Your UserQ wallet will be updated with your rewards soon after you complete a test.'
        },
        {
            id: 2,
            title: '   How much will I receive for each test I take? ',
            info:'You will be paid a different amount depending on the type and length of the test you take. This information will be available to you before you start your test.'
        },
        {
            id: 3,
            title: '    Will you pay me in my local currency?  ',
            info:"You will always be paid in US dollars ($) for each test you take"
        }
    ]);

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Frequently asked questions";

    }, []);

 const checkUser = () =>{
     if(ReactSession.get("token")){
         return  false;
     }
        return true;
 }

    return (
        <Layout fixed_header_target={true} isLoading={false} skipCheckLogin={checkUser()} isFullWidth={true} extendedFooter={false}  >
            <div className="analyze-results-page-wrapper FAQ-support-page-wrap">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <div className="profile-info-section">
                            <div className="target-back-option-data">
                                <div className="backto-list-wrap">
                                    <a href="#"  onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1)
                                    }}>
                                        <img src={process.env.REACT_APP_URL+"images/back-arrow.svg"} alt="img"/> Back</a>
                                </div>
                                <div className="profile-info-text">
                                    <div className="target-group-heading-top studyint-top-flex-wrap w-100">
                                        <h1 className="h1"><span>Tester</span> support</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inner-page-container">
                    <div className="sp5-content-wrap analyze-overflow-wrap">
                        <div className="sidebar sp5-left-side-bar">
                            <div className="sp5-side-bar-innerdata">
                                <div className="sp5-side-nav-wrap border-0">
                                    <ul>
                                        <li><Link to={'/tester/support/'} className="active">Frequently asked questions</Link></li>
                                        <li><Link to={'/tester/support/contact'}>Contact us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="sp5-right-side-wrap">
                            <div className="sp5-overview-data faq-data-wrapper">
                                <h2>General</h2>
                                {/*<p className="faqsub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                <div className="accordion" id="faq">

                                    {accordianData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}

                                    <h2 className="FQA-next-titles mt-64">Navigating your account</h2>
                                    {accordianSecondData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">Privacy and security</h2>
                                    {accordianThirdData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}

                                    <h2 className="FQA-next-titles mt-64"> Becoming a tester</h2>
                                    {accordianFourData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">  UserQ tests</h2>
                                    {accordianFifthData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">  Taking UserQ tests</h2>
                                    {accordianSixData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}

                                    <h2 className="FQA-next-titles mt-64">  Your rewards</h2>
                                    {accordianSevenData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}






                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}