import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";

export default function AlreadyTakenTest() {


    return (
        <div className="login-data-wrap flex-wrap already-taken-testlogin-wrap">
            <div className="container">
                <div className="login-left-side">
                    <h1 className="form-heading">It looks like you’ve already taken this test!</h1>
                    {(ReactSession.get("token")) &&
                    <p className="form-sub-head">You can only take each test once – head back to the UserQ dashboard to
                        see if<br/> another one is available. </p>
                    }

                    {!(ReactSession.get("token")) &&
                    <p>Share your opinion and make money. Create your profile on UserQ.</p>
                    }
                    <div className="login-form-inner-data">
                        {(ReactSession.get("token")) &&
                        <Link to={"/dashboard"} className="btn btn-block form-btn login-page-btn mt-0">
                            Back to dashboard
                        </Link>
                        }
                        {!(ReactSession.get("token")) &&
                        <Link to={"/tester/login"} className="btn btn-block form-btn login-page-btn mt-0">
                            Get Started
                        </Link>
                        }
                    </div>
                </div>
            </div>
            <div className="full home-marquee-banner section">
                <div className="home-marque-banner-box">
                    <div className="scrolling-box">Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                    <div className="scrolling-box" aria-hidden="true"> Hold on wait a minute</div>
                </div>
            </div>
        </div>
        );
}
