import { useNavigate, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { Dropdown } from "react-bootstrap";
import { getCreditSummaryService } from "../../../services/credits";
import React, { useEffect, useState } from "react";
import { logoutService } from "../../../services/user";
import {
    atom,
    useRecoilState,
    useResetRecoilState
} from 'recoil';
import Skeleton from "react-loading-skeleton";

export default function HeaderResearcher(props) {
    ReactSession.setStoreType("localStorage");
    const navigate = useNavigate();
    const [user, setUser] = useState({ first_name: "", last_name: "", role: "" });
    const [creditsLoading, setCreditsLoading] = useState(false);

    const creditsState = atom({
        key: 'credits',
        default: 0
    });
    const [credits, setCredits] = useRecoilState(creditsState);

    
    const resetCredits = useResetRecoilState(creditsState);
    

    const [mobileMenu, setMobileMenu] = useState(false);

    const logout = (e) => {
        e.preventDefault();
        let user = ReactSession.get("user");
        if (!user) {
            navigate("/tester/login");
        }
        let token = ReactSession.get("token");

        logoutService(token).then((response) => {
            if (response.success) {
            }
        });
        resetCredits();
        
        ReactSession.set("token", null);
        ReactSession.set("user", null);
        ReactSession.set("plan", null);
        ReactSession.set("show_subscribed", null);
        ReactSession.set("username", null);
        ReactSession.set("new_target_id", null);

        localStorage.clear();

        if (user.role == "Tester") {
            navigate("/tester/login");
        } else {
            navigate("/researcher/login");
        }
    };
    const goToHome = () => {
        let user = ReactSession.get("user");

        if (user.role == "Tester") {
            return process.env.REACT_APP_TESTER_URL;
        } else {
            return process.env.REACT_APP_RESEARCHER_URL;
        }
    };
    const fetchCreditsSummary = () => {

        //if (!credits) {
            let token = ReactSession.get("token");

            setCreditsLoading(true);

            getCreditSummaryService(token).then((response) => {
                setCreditsLoading(false);
                if (response.success) {
                    ReactSession.set("credits", response.summary.credits_balance);
                    setCredits(response.summary.credits_balance);
                }
            });
        //}
    };
    useEffect(() => {
        let user = ReactSession.get("user");
        if (!user) {
            navigate("/tester/login");
        }
        setUser(user);
        fetchCreditsSummary();
    }, [credits]);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMobileMenu(!mobileMenu);
    };
    const getFooterYear = () => {
        const d = new Date();
        let year = d.getFullYear();
        return <>{year}</>;
    };
    return (
        <div
            className={`login-page-header inner-page-hdr-wrap ${props.fixed_header_target ? "fixed_header_target" : "fixed_header"
                }`}
        >
            <div className="container inner-page-container d-flex justify-content-between align-items-center">
                <div className="login-hdr-left researcher-header-left">
                    <Link to="/dashboard" className="logo">
                        <img
                            src={process.env.REACT_APP_URL + "images/logo.svg"}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="login-hdr-right inner-page-right-hdr">
                    <div className="header-plan-update-wrap">
                        {/*user.plan_id == 2 ?
                            <span className="selected-plan-wrap pink-bg">PRO PLAN</span>
                            : <>
                                <span className="selected-plan-wrap">FREE PLAN</span>
                                <Link to="/researcher/billing/plan/change" className="upgrade-link">
                                    Upgrade now
                                </Link>
                        </>*/}
                        {user.role === 'Researcher' &&
                            <Link to="/researcher/invite-and-earn" className="upgrade-link researcher-refer-link">
                                Refer & Earn 50 credits
                            </Link>
                        }
                        {user.permission != "Editor" &&
                            <Link to="/researcher/credits/buy-credits" className="upgrade-link">
                                Buy credits
                            </Link>
                        }

                    </div>
                    <span className="count-coin-wrap">
                        <Link to="/researcher/credits">
                            {!creditsLoading &&
                                <>{props.credits ? props.credits : credits}{" "}</>
                            }
                            {creditsLoading &&
                                <><Skeleton width={30} height={14} /></>
                            }
                            <img src={process.env.REACT_APP_URL + "images/coin.svg"} />
                        </Link>
                    </span>
                    <span className="username-drop-down">
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdownMenuButton"
                                className="dropdown-toggle"
                            >
                                {/* {props.username ? props.username : user.first_name+" "+user.last_name} */}
                                {props.username
                                    ? props.username
                                    : user.first_name
                                        ? user.first_name +
                                        " " +
                                        (user.last_name ? user.last_name : "")
                                        : "Welcome User"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    className="profile-link"
                                    as={Link}
                                    to={"/profile"}
                                >
                                    <img
                                        src={process.env.REACT_APP_URL + "images/profile-icon.svg"}
                                        alt="img"
                                    />{" "}
                                    Profile
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as={Link}
                                    to={"/researcher/team"}
                                    className="profile-link"
                                >
                                    <img
                                        src={process.env.REACT_APP_URL + "images/team-icon.svg"}
                                        alt="img"
                                    />
                                    Team
                                </Dropdown.Item>
                                {user.permission != "Editor" && (
                                    <Dropdown.Item
                                        as={Link}
                                        to={"/researcher/billing"}
                                        className="profile-link"
                                    >
                                        <img
                                            src={
                                                process.env.REACT_APP_URL + "images/plan-bill-icon.svg"
                                            }
                                            alt="img"
                                        />
                                        Billing
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item
                                    as={Link}
                                    to={"/researcher/credits"}
                                    className="profile-link"
                                >
                                    <img
                                        src={process.env.REACT_APP_URL + "images/credits-icon.svg"}
                                        alt="img"
                                    />
                                    Credits
                                </Dropdown.Item>
                                 <Dropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/researcher/settings");
                                    }}
                                    className="profile-link"
                                    >
                                    <img
                                        src={
                                        process.env.REACT_APP_URL + "images/pro-setting-icon.svg"
                                        }
                                        alt="img"
                                    />
                                    Settings
                                </Dropdown.Item>
                                <div className="border-wrap"></div>
                                <Dropdown.Item href="#" onClick={()=>{
                                    navigate('/researcher/support/')
                                }} class={"support-link-btn"}>
                                    SUPPORT
                                </Dropdown.Item>

                                <Dropdown.Item href="#" onClick={logout}>
                                    Log out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                    <span className="username-drop-down notification-btn mr-0 pr-0">
                        <div className="dropdown">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdownMenuButton"
                                    className="dropdown-toggle"
                                >
                                    <img
                                        src={process.env.REACT_APP_URL + "images/notification.svg"}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="no-data">No notifications</div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </span>
                </div>
                <div className="none-for-desktop header-mobile-right-side">
                    <div className="mobile-notification-data">
                        <div className="m-username-noti-wrap">
                            <div className="wallet-notification-mobile-both">
                                <Link to="/researcher/credits" className="m-credit-coin-wrap">
                                    {!creditsLoading &&
                                        <>{props.credits ? props.credits : credits}{" "}</>
                                    }
                                    {creditsLoading &&
                                        <><Skeleton width={30} height={12} /></>
                                    }

                                    <img src={process.env.REACT_APP_URL + "images/coin.svg"} />
                                </Link>

                                <span className="m-notification-icon">
                                    <img
                                        src={process.env.REACT_APP_URL + "images/notification-icon.svg"}
                                    />
                                </span>
                            </div>
                        </div>

                        <div
                            className="m-menu-open-btn"
                            style={!mobileMenu ? { display: "none" } : {}}
                            onClick={toggleMenu}
                        >
                            <span>
                                <img
                                    src={process.env.REACT_APP_URL + "images/mobile-menu.svg"}
                                    alt="logo"
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    className="mobile-menu-wrap"
                    style={!mobileMenu ? { display: "none" } : {}}
                >
                    <div className="mobile-menu-header">
                        <div className="mobile-menu-logo">
                            <a href="#" className="logo">
                                <img
                                    src={process.env.REACT_APP_URL + "images/logo.svg"}
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <div className="mobile-menu-btn">
                            <div className="menu-btn-wrap" onClick={toggleMenu}>
                                <img
                                    src={process.env.REACT_APP_URL + "images/close-menu.svg"}
                                    alt="img"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="m-menu-inner-data">
                        <div className="m-username-noti-wrap">
                            <span className="m-user-name">
                                {user.first_name + " " + user.last_name}
                            </span>
                            {/* <span className="m-notification-icon">
                                <img
                                    src={process.env.REACT_APP_URL + "images/notification.svg"}
                                />
                            </span> */}
                        </div>
                        <div className="m-profile-menu-wrap">
                            <ul className="m-menu-ul">
                                <li
                                    className={
                                        props.activeMenu == "profile" ? "profile-menu-link" : ""
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/profile");
                                    }}
                                >
                                    <a href="#">Profile</a>
                                </li>
                                <li
                                    className={
                                        props.activeMenu == "team" ? "profile-menu-link" : ""
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/researcher/team");
                                    }}
                                >
                                    <a href="#">Team</a>
                                </li>
                                <li
                                    className={
                                        props.activeMenu == "billing" ? "profile-menu-link" : ""
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/researcher/billing");
                                    }}
                                >
                                    <a href="#">Billing</a>
                                </li>
                                {user.permission != "Editor" &&
                                <li
                                    className={
                                        props.activeMenu == "credits" ? "profile-menu-link" : ""
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/researcher/credits/buy-credits");
                                    }}
                                >
                                    <a href="#" className="d-flex align-items-center justify-content-between by-credit-link">
                                        <span>Buy credits </span>
                                        <b>
                                        {!creditsLoading &&
                                        <>{props.credits ? props.credits : credits}{" Credits "}</>
                                        }
                                        </b>

                                    </a>
                                </li>
                                }
                                {/*<li className="m-reward-li-wrap">*/}
                                {/*    <Link to={"/researcher/credits/buy-credits"}>*/}
                                {/*        <b>*/}
                                {/*            {!creditsLoading &&*/}
                                {/*                <>{props.credits ? props.credits : credits}{" "}</>*/}
                                {/*            }*/}
                                {/*            /!*{creditsLoading &&*!/*/}
                                {/*            /!*    <> </>*!/*/}
                                {/*            /!*}*!/*/}
                                {/*        </b>*/}
                                {/*        {!creditsLoading &&*/}
                                {/*            "Credits"*/}
                                {/*        }*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="m-reward-li-wrap menu-selected-plan">
                                    <div className="header-plan-update-wrap">
                                        {user.plan_id == 2 ?
                                            <span className="selected-plan-wrap pink-bg">PRO PLAN</span>
                                            : <>
                                                <span className="selected-plan-wrap">FREE PLAN</span>
                                                <Link to="/researcher/billing/plan/change" className="upgrade-link">
                                                    Upgrade now
                                                </Link>
                                            </>}
                                    </div>
                                </li>*/}

                                <li
                                    className={
                                        props.activeMenu == "settings" ? "profile-menu-link" : ""
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/researcher/settings");
                                    }}
                                    >
                                    <a href="#">Settings</a>
                                </li>

                                {user.role === 'Researcher' &&
                                <li
                                    className={
                                        props.activeMenu == "invite-and-earn" ? "profile-menu-link" : ""
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/researcher/invite-and-earn");
                                    }}
                                >
                                    <a href="#">Refer & Earn 50 credits</a>
                                </li>
                                }

                            </ul>
                        </div>
                {/*        <div className="m-company-menu-wrap">*/}
                {/*            <h3>Company</h3>*/}
                {/*            <ul className="m-menu-ul">*/}
                {/*                <li>*/}
                {/*                    <a href="https://userq.com/about-us/">About us</a>*/}
                {/*                </li>*/}
                {/*                /!*<li>*/}
                {/*  <a href="#">Careers</a>*/}
                {/*</li>*!/*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*        <div className="m-company-menu-wrap">*/}
                {/*            <h3>Support</h3>*/}
                {/*            /!*<ul className="m-menu-ul">*!/*/}
                {/*            /!*    <li>*!/*/}
                {/*            /!*        <a href="https://userq.com/contact-us/">Contact support</a>*!/*/}
                {/*            /!*    </li>*!/*/}
                {/*            /!*    <li>*!/*/}
                {/*            /!*        <a href="https://userq.com/privacy-policy/">Privacy Policy</a>*!/*/}
                {/*            /!*    </li>*!/*/}
                {/*            /!*    <li>*!/*/}
                {/*            /!*        <a href="https://userq.com/terms-of-use/">Terms of Use</a>*!/*/}
                {/*            /!*    </li>*!/*/}
                {/*            /!*</ul>*!/*/}
                {/*        </div>*/}

                        <div className="m-footer-logout-wrap">
                            <div className="m-logout-left-wrap">
                                <Link to={'/researcher/support/'} className={"support-link-mob"}>
                                    SUPPORT
                                </Link>

                                <a href="#" onClick={logout}>
                                    Log out{" "}
                                    <img
                                        src={process.env.REACT_APP_URL + "images/arrow-frame.svg"}
                                        alt="img"
                                    />
                                </a>
                            </div>

                            <div className="m-social-links-wrap">
                                <a href="https://www.linkedin.com/company/userqofficial/">
                                    <img
                                        src={process.env.REACT_APP_URL + "images/linkedin-icon.svg"}
                                        alt="img"
                                    />
                                </a>
                                <a href="https://twitter.com/userqofficial">
                                    <img
                                        src={process.env.REACT_APP_URL + "images/twitter-icon.svg"}
                                        alt="img"
                                    />
                                </a>
                                <a href="https://www.instagram.com/userqofficial/">
                                    <img
                                        src={process.env.REACT_APP_URL + "images/insta-icon.svg"}
                                        alt="img"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="m-menu-footer-wrap">
                            <p>&copy; {getFooterYear()} USERQ. All rights reserved.</p>
                            <div className={"footer-extra-link"}>
                                <span className="terms-of-use-txt-link-mob">
                                    <a href="https://userq.com/terms-of-use/">
                                        Terms of Use
                                    </a>
                                </span>
                                <span className="privacy-policy-txt-link-mob">
                                    <a href="https://userq.com/privacy-policy/">
                                        Privacy Policy
                                    </a>
                                </span>
                                <span className="cookie-policy-text">
                                    <a href="https://userq.com/privacy-policy/#cookies-policy">
                                        Cookie policy{" "}
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cookie-icon.svg"}
                                            alt="img"
                                        />{" "}
                                    </a>
                                </span>
                            </div>

                            <p>
                                Proudly created by <b>Digital Of Things</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
