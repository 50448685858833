import {handleApiErrorResponse} from "../lib/helpers";

export function createTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/create", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveintroduction", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function saveConclusion(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveconclusion", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveTargetGroup(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/savetargetgroup", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveReview(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/saveReview", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function sendFeedback(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/sendFeedback", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function closeFeedback(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/closeFeedback", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestData(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/get?test_id=" +
      formValues.test_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestOrderData(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/order/get?test_id=" +
      formValues.test_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getTestsService(formValues, token) {
  
  return fetch(
    process.env.REACT_APP_API_END_POINT +
      "test/list?project_id=" +
      formValues.project_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addNodeService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/create", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function removeNodeService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/node/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function removeNodesService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/nodes/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addTaskService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/task/create", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updateTreeService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePublishSettingsService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/publish/settings/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deleteTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/delete", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function renameTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/rename", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function publishTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/publish", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function importCSVService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/tree/import", {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function changeTestStatus(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/changestatus", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function changeTestLanguage(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/changelanguage", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function deleteTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/delete", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function duplicateTest(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/duplicatetest", {
    method: "POST",
    body: formValues,
    headers: { Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function moveTestToAnotherProject(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/move", {
    method: "POST",
    body: formValues,
    headers: {"Content-Type": "application/json",
    Accept: "application/json", Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function updateTestStep(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/updateStep", {
    method: "POST",
    body: formValues,
    headers: {"Content-Type": "application/json",
    Accept: "application/json", Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function getLiveTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "tester/tests/list/live?sort="+formValues.sort+"&language="+formValues.language+"&duration="+formValues.duration+"&location="+formValues.location, {
    method: "GET",
    headers: { "Content-Type": "application/json",
    Accept: "application/json",Authorization: "Bearer " + token },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function addDesignService(formValues, token) {
  return   fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/create", {
    method: "POST",
    body: formValues,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function deleteDesignService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/remove", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function reorderDesignService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/design/reorder", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}
export function updatePreferenceTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/preference-test/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveSurveyQuestions(formValues, token) {
  return fetch(
    process.env.REACT_APP_API_END_POINT + "test/saveSurveyQuestions",
    {
      method: "POST",
      body: formValues,
      headers: { Authorization: "Bearer " + token },
    }
  ).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveCardSortingTestService(formValues, token) {
  return fetch(process.env.REACT_APP_API_END_POINT + "test/card-sorting/save", {
    method: "POST",
    body: JSON.stringify(formValues),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((res)=>{

    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? res.json() : null;

    if (!res.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || res.status;
      return Promise.reject(error);
    }

    return data;

  }).catch((res)=>{
    handleApiErrorResponse(res);
  });
}

export function saveFiveSecondsTestData(formValues, token) {
    return fetch(
      process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/save",
      {
        method: "POST",
        body: formValues,
        headers: { Authorization: "Bearer " + token },
      }
    ).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }

  export function addFiveSecondsDesignService(formValues, token) {
    return   fetch(process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/design/create", {
      method: "POST",
      body: formValues,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }

  export function removeFiveSecondsDesignService(formValues, token) {
    return   fetch(process.env.REACT_APP_API_END_POINT + "test/five-seconds-test/design/remove", {
      method: "POST",
      body: formValues,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res)=>{
  
      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;
  
      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
  
      return data;
  
    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
  }

export function importCardSortingCardCSVService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/card-sorting/import-card", {
        method: "POST",
        body: formValues,
        headers: {
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }

      return data;

    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
}

export function importCardSortingCategoryCSVService(formValues, token) {
    return fetch(process.env.REACT_APP_API_END_POINT + "test/card-sorting/import-category", {
        method: "POST",
        body: formValues,
        headers: {
            Authorization: "Bearer " + token,
        },
    }).then((res)=>{

      const isJson = res.headers.get('content-type')?.includes('application/json');
      const data = isJson ? res.json() : null;

      if (!res.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }

      return data;

    }).catch((res)=>{
      handleApiErrorResponse(res);
    });
}


