import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
import Accordian from '../../Accordian';



export default function ResearcherSupport() {

    const navigate = useNavigate();

    const [accordianData] = useState([
        {
            id: 1,
            title: ' What is UserQ? ',
            info:
                'UserQ is a user research platform that enables designers, managers and researchers to gain unique insights from testers in the MENA region about their digital products. You can build and publish your own tests, in English or Arabic, and easily analyse any data you collect through your UserQ account.\n' +
                '\n <br/><br/>' +
                'For testers, UserQ is a platform that pays you for your insights and opinions – it really is as simple as that.'
        },
        {
            id: 2,
            title: ' How does UserQ work? ',
            info:
                'We bring researchers and testers together on an easily-accessible, powerful research platform. Researchers can easily create, publish and analyse tests that are filled out by real people in the MENA region (testers). Once a test goes live, the UserQ platform finds the perfect testers based on the parameters set by the researcher, and results can come in after as soon as a few minutes.\n' +
                '\n <br/><br/>' +
                'Using the UserQ platform, researchers get the invaluable insights they need to support their projects and design process in real time, and testers get paid for their time and thoughts.',
        },
        {
            id: 3,
            title: ' What tests can I do with UserQ? ',
            info:
                'The UserQ platform offers six user research tests based on varying methodologies types that can be taken part in remotely.\n' +
                '\n <br/><br/>' +
                'Each of these offers a way for businesses to collect primary research data about their digital experiences from real people.\n' +
                '\n <br/><br/>' +
                'What is tree testing?\n <br/>' +
                'This kind of testing helps you to define an easy to follow, logical site hierarchy by asking testers to complete tasks using a text-only version of your site.\n' +
                '\n <br/><br/>' +
                'What is card sorting?\n <br/>' +
                'Card sorting helps you to group information together in the right way, and involves asking testers to categorise information that you give them. This will help shape your navigation structure.\n' +
                '\n <br/><br/>' +
                'What is a preference test?\n <br/>' +
                'A preference test allows you to validate your designs easily by giving your testers two options to choose from with preference testing.\n' +
                '\n <br/><br/>' +
                'What is an online survey?\n <br/>' +
                'This type of test allows you to ask whatever questions you have about your site, customers or product. Running a survey means you can get all the answers you need to more specific questions.\n' +
                '\n <br/><br/>' +
                'What is a 5 seconds test?\n <br/>' +
                'First impressions are everything in UX, and a 5 second test can tell you what your users think of your designs in the first few seconds of seeing them.\n' +
                '\n <br/><br/>' +
                'What is a first click test?\n <br/>' +
                'This test involves asking testers to perform a task using wireframes or prototypes and recording where they first click.',
        },
        {
            id: 4,
            title: ' And what tests can’t I carry out on UserQ?',
            info:
                ' As a remote research tool, any user tests that require observation or in-person facilitation (such as focus groups) can’t be done on the UserQ platform. If you want to run in-person user research using primary research methods like focus groups, you can book a slot in our parent company, <a href="https://www.digitalofthings.com/usability-lab">Digital of Things’ in-house UX lab</a> - where you can access state-of-the-art tech and in-house expertise to support your design process.',
        },
        {
            id: 5,
            title: ' How do I recruit testers? ',
            info:
                'When it comes to finding the right testers for your project, you have access to UserQ’s extensive panel of MENA-based testers – where you can build your own target group or select a random group of testers.\n' +
                '\n <br/><br/>' +
                'You also have the option of recruiting your own testers and distributing your test to them yourself – however, this can be more time consuming and difficult to find local testers.',
        },
        {
            id: 6,
            title: ' What is user testing? ',
            info:
                'User testing, whether it’s through online surveys or preference tests, assists businesses at each step of the website design process. The data collected from these different primary research methods provides key information about what designs, functionalities and navigation structures come together to create a seamless user experience.\n' +
                '\n <br/><br/>' +
                'If you’re completely new to user testing, don’t worry. UserQ is easy to use and guides you through each step of testing – from design to publishing. If you want to see how well your website functions before you fully develop it, you can carry out any of our remote tests to gain insights from real people in your local area. It’s as easy as that.',
        }
    ]);

    const [accordianSecondData] = useState([
        {
            id: 1,
            title: '  Is my data secure on the UserQ platform? ',
            info: 'All data that you provide in setting up your account, including private information and payment details – is safe with us. We are completely compliant with all data privacy laws in the UAE, and your security is our top priority.'
        },
        {
            id: 2,
            title: '   How does UserQ use my personal information?  ',
            info:'The UserQ platform works by matching your profile to tests where researchers are looking for insights from a specific group of people. This means that we’ll use your demographic data such as gender, age or occupation to match you to the right tests. The researchers who create the tests won’t have access to this information – it’s only used by us at UserQ to make sure we send you the right tests.\n' +
                '\n <br/><br/>' +
                'Your data is always stored securely and safely on UserQ – only you will be able to access your sensitive information such as payment details and passwords.'
        }
    ]);

    const [accordianThirdData] = useState([
        {
            id: 1,
            title: '   How much does it cost to use UserQ? ',
            info: 'We offer you complete flexibility with our pay-as-you-go pricing, so you will only pay for what you use, without the need to commit to a subscription or contract. This means you can quickly scale your testing requirements up or down depending on the needs of your business.\n' +
                '\n <br/><br/>' +
                'Each credit costs $1. When your account has enough available credits, you will have access to an unlimited number of users, tests and respondents. When you publish your tests, you’ll need to use your credits to be able to recruit testers from the UserQ panel. If you want to use your own group of testers, you can get a free link to share with them to your test – no credits needed.'
        },
        {
            id: 2,
            title: '    What are credits?  ',
            info:'Credits are used to recruit testers for your projects and pay them for their time and insights. You’ll need credits to publish any tests using UserQ’s panel.\n' +
                '\n  <br/><br/>' +
                'Credits start at $1 each, with the minimum number of credits you can buy at any one time is 50. If you’re buying 50 credits, you would pay $50 at $1 each. But when you choose to buy your credits in bulk (500 or more), you’ll qualify for a discount. Here’s how it would work:\n' +
                ' <br/><ul><li>500 credits = 5% discount, so you pay $475\n</li>' +
                ' <br/><li>1000 credits =10% discount, so you pay $900\n</li>' +
                ' <br/><li>1500 credits = 15% discount, so you pay $1275\n</li>' +
                ' <br/><li>2000 credits = 20% discount, so you pay $1600\n</li></ul>' +
                '\n <br/><br/>' +
                'The discounts are included on each transaction up to the next bracket, so if you buy between 500 and 1000 credits, you’ll get a 5% discount on your total. All you need to do is add credits to your basket and your discount will be automatically applied.'
        },
        {
            id: 3,
            title: '    How many credits do I need to publish a test?   ',
            info:'Publishing a test is a set fee of 10 credits. If you want to use our panel, you can use credits to recruit. Depending on the size of the panel you select, and the type and length of your test, the amount of credits you need to use will change. Here’s an overview of average credit costs per respondent:\n' +
                '<br/><br/><ul><li>Preference tests (short length tests) - 2 credits per tester\n</li>' +
                '<li>Card sorting, tree testing and online survey (medium length tests) - 6 credits per tester\n</li></ul>' +
                '\n <br/><br/>' +
                'You can also use our credit estimate tool to work out an estimate of how many credits you’ll need to publish your test.'
        },
        {
            id: 4,
            title: '    How do I purchase credits?   ',
            info:'You can buy credits through your UserQ account – and you can save money when you buy credits in bulk. Prices start at as little as $0.80 per credit.'
        }
    ]);

    const [accordianFourData] = useState([
        {
            id: 1,
            title: '   What tests can I build?  ',
            info: 'The UserQ platform offers six tests based on varying methodologies. They are:\n <br/><br/>' +
                '<ul><li>Tree testing\n</li><br/>' +
                '<li>Card sorting\n</li><br/>' +
                '<li>Preference test\n</li><br/>' +
                '<li>Survey\n</li><br/>' +
                '<li>5 seconds test\n</li><br/>' +
                '<li>First click test\n</li></ul> <br/><br/>' +
                '\n' +
                'Depending on what insights you’re looking for, each test can give you different information at a different stage of the design process.'
        },
        {
            id: 2,
            title: '    What about other types of tests?   ',
            info:'For tests that need to be carried out in a lab, you won’t be able to use the UserQ platform, but can book a slot in our parent company, <a href="https://www.digitalofthings.com/usability-lab">Digital of Things’ in-house UX lab</a>'
        },
        {
            id: 3,
            title: '   Can I edit my test once it’s live?   ',
            info:'Unfortunately, once your test is live, you won’t be able to edit it or make changes. So make sure you double check everything before hitting that publish button!'
        },
        {
            id: 4,
            title: '    Who will have access to my test once it’s live?   ',
            info:'Only the testers you choose or people you give the test link to will be able to see your test. Each of our testers in the UserQ panel also agree to not copy or share any of the content they see in their tests.'
        }
    ]);

    const [accordianFifthData] = useState([
        {
            id: 1,
            title: '    Can I recruit my own testers?   ',
            info: 'Yes, of course! When you have created your test and it’s ready to be published, you can choose who will take the test by selecting the ‘Share the test link with your own panel’ option. Remember that if your testers don’t have a UserQ account, you won’t be able to access demographic data.\n' +
                '\n <br/><br/>' +
                'Even if you do use a panel from UserQ, once your test is live, you’ll still be able access and distribute a link to your test to your own pool of testers too.'
        },
        {
            id: 2,
            title: '    What about UserQ’s panel?   ',
            info:'We have access to an extensive pool of testers in the MENA region. So when you choose to recruit testers from the UserQ panel, your testers will be locals who can give their unique insights, fast, and offer accurate primary research data.\n' +
                '\n  <br/><br/>' +
                'Using the UserQ panel, you have the option to create a specific target group to take your test, or select your panel randomly – the choice is yours.'
        },
        {
            id: 3,
            title: '    How many tests can I create at the same time?    ',
            info:'You’ll be able to create and run as many tests as you want on the UserQ platform, providing your account has enough credits.'
        },
        {
            id: 4,
            title: '   How do I pay for a test I create and launch?  ',
            info:'Once your online surveys or preferences tests are ready to be published, you’ll need to use credits to recruit testers from the UserQ panel so they can be compensated for their time. You can buy credits from your account dashboard.\n' +
                '\n <br/><br/>' +
                'If you’re planning to distribute your test to your own panel of testers, you won’t need to use any credits, and can publish your test for free.'
        }
    ]);

    const [accordianSixData] = useState([
        {
            id: 1,
            title: '    How can I see the results of my test?    ',
            info: 'Once your test has a few responses, you’ll be able to access all of the insights and data collected throughout the live period through your UserQ account dashboard. You’ll have unlimited access to your results, as well as the option to download a .csv file of your findings.'
        },
        {
            id: 2,
            title: '    How do I analyse my results?    ',
            info:'It’s easy to look at the data collected from your test, no matter the research methodology chosen, because UserQ displays it in an easy-to-understand way, with automatically-generated charts and diagrams.\n' +
                '\n <br/><br/>' +
                'You can also download insights using whatever parameters you want – choose to download the data from specific questions, filter out certain insights as you need, or download a general overview to identify any trends.'
        },
        {
            id: 3,
            title: '    Can I download data if I’ve self-recruited for my test?    ',
            info:'Yes! Even if you use your own panel, testers’ insights will all still be recorded and available on the UserQ platform. You can view, analyse and download information the same way you could if you used a target panel from UserQ’s network.'
        }
    ]);


    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Frequently asked questions";

    }, []);



    return (
        <LayoutResearcherFullWidth fixed_header_target={true} isLoading={false} wrapClass={"researcher-test-view-overflow overflow-visible"}  skipCheck={false} extendedFooter={false} activeMenu={""}>
            <div className="analyze-results-page-wrapper FAQ-support-page-wrap">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        <div className="profile-info-section">
                            <div className="target-back-option-data">
                                <div className="backto-list-wrap">
                                    <a href="#"  onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1)
                                    }}>
                                        <img src={process.env.REACT_APP_URL+"images/back-arrow.svg"} alt="img"/> Back</a>
                                </div>
                                <div className="profile-info-text">
                                    <div className="target-group-heading-top studyint-top-flex-wrap w-100">
                                        <h1 className="h1"><span>Researchers</span> support</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container inner-page-container">
                    <div className="sp5-content-wrap analyze-overflow-wrap">
                        <div className="sidebar sp5-left-side-bar">
                            <div className="sp5-side-bar-innerdata">
                                <div className="sp5-side-nav-wrap border-0">
                                    <ul>
                                        <li><Link to={'/researcher/support/'} className="active">Frequently asked questions</Link></li>
                                        <li><Link to={'/researcher/support/contact'}>Contact us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="sp5-right-side-wrap">
                            <div className="sp5-overview-data faq-data-wrapper">
                                <h2>General</h2>
                                {/*<p className="faqsub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                <div className="accordion" id="faq">

                                    {accordianData.map((data) => (
                                        <Accordian key={data.id} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">  Privacy and security</h2>
                                    {accordianSecondData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}



                                    <h2 className="FQA-next-titles mt-64">  Pricing plan</h2>
                                    {accordianThirdData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">  Building tests</h2>
                                    {accordianFourData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">  Recruiting testers</h2>
                                    {accordianFifthData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}


                                    <h2 className="FQA-next-titles mt-64">  Analysing your results</h2>
                                    {accordianSixData.map((data,index) => (
                                        <Accordian key={index} {...data} />
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutResearcherFullWidth>
    )
}