import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../../lib/secure_reactsession';
import { useState, useEffect, useRef } from "react";
import toast from 'react-hot-toast';
import moment from "moment";
import ArchiveTest from "../dialog/archive_test";
import Unpublished from "../dialog/unpublished";
import CopyTest from "../dialog/copy_test";
import {Dropdown} from "react-bootstrap";
import RenameModal from "../../../dialog/rename_model";
import {renameTestService} from "../../../../services/test";

export default function Topbar({test,path,callbackTopbar}) {
    const navigate = useNavigate();
    const [openArchiveTestModal, setOpenArchiveTestModal] = useState(false );
    const [openUnpublishedModal, setOpenUnpublishedModal] = useState(false );
    const [openCopyTestModal, setOpenCopyTestModal] = useState(false );
    const [rename, setRename] = useState({open:false,isLoading:false});
    const [title, setTitle] = useState(test.test_name);

    const closeUnpublishedModal = (data)=>{
        setOpenUnpublishedModal(false);
        if(data === true){
            callbackTopbar(true);
        }
    }

    const renameTest = (title) => {


        let token = ReactSession.get("token");

        setRename({...rename,isLoading:true});

        renameTestService({test_id:test.id, title:title}, token).then((response) => {

            setRename({...rename,isLoading:false});

            if (response.success) {

                setRename({...rename, open:false,isLoading:false});

                setTitle(title)
            } else {

                toast((
                    <div className='toastinner'>
                        {response.message}
                        <a  onClick={()=>{toast.dismiss(); } }>&times;</a>
                    </div>), {
                    className: 'errortoast',
                    position: 'bottom-center'
                });
            }
        });

    }
    return (
        <div className="page-back-option-wrap">
            <div className="container inner-page-container">
                <div className="profile-info-section">
                    <div className="target-back-option-data">
                        <div className="backto-list-wrap">
                            <a href="#" onClick={(e)=>{e.preventDefault(); navigate(path ? path : "/researcher/project/" + test.project_id ) }}>
                                <img src={process.env.REACT_APP_URL + "images/back-arrow.svg"} alt="img"/>
                                Back
                            </a>
                        </div>
                        <div className="profile-info-text">
                            <div className="target-group-heading-top studyint-top-flex-wrap">
                                <h1 
                                className="h1"
                                tooltip={
                                    title
                                    ?
                                    (
                                    title.length>25?title:""
                                    )
                                    :
                                    ""
                                }
                                >
                                    {title
                                    ?
                                    (
                                    title.length>25?title.substring(0, 24) + "...":title
                                    )
                                    :
                                    ""}
                                </h1>
                                <div className="study-draft-and-save-wrap">
                                    {test && test.status === 'published' &&
                                        <span className="green-btn pink-bg">Live</span>
                                    }
                                    {test && test.status === 'completed' &&
                                        <span className="green-btn info-bg text-capitalize">{test && test.status}</span>
                                    }

                                    {test && test.status === 'completed' && test.ended_on &&

                                    <p>Ended on  <b className="font-weight-600">
                                        {moment.utc(test.ended_on).format('DD MMM, Y')}
                                         {/* or at <b className="font-weight-600"> 400 participants</b>*/}
                                    </b></p>
                                    }

                                    {test && test.status === 'published' &&
                                        (test.close_condition === "specific-date" || test.close_condition === "specific-date-after-no-participants" || test.close_condition === 'after-no-participants') &&
                                    <p>
                                        {test && test.closed_on && (test.close_condition !== 'after-no-participants') &&
                                            <>
                                                Ending in <b className="font-weight-600">{moment.utc(test.closed_on).local().diff(moment(), "days")} days</b>
                                            </>
                                        }


                                        {test && test.participants &&
                                            <>
                                                {test && test.close_condition !== 'after-no-participants' &&
                                                   <>, or at <b className="font-weight-600"> {test.participants}  participants</b></>
                                                }

                                                {test && test.close_condition === 'after-no-participants' &&
                                                <>Ending at <b className="font-weight-600">{test.participants}  participants</b></>
                                                }
                                            </>

                                        }
                                    </p>
                                    }
                                </div>
                            </div>
                            <div className="target-group-btns-top top-wdropd">
                                <div className="top-buttons-wdropd">
                                    {test && test.status === 'published' &&
                                    <>
                                    < button type="button" onClick={()=>setOpenUnpublishedModal(true)} className="btn form-btn">{test.methodology=="Survey"?"End Survey":"End Test"}
                                        </button>
                                    </>
                                    }

                                    {test && test.status === 'completed' &&
                                    <>
                                        <button type="button" onClick={()=>setOpenCopyTestModal(true)}  className="btn form-btn secondary-btn">Make a Copy
                                        </button>
                                        {/*< button type="button" onClick={()=>setOpenPublishedModal(true)} className="btn form-btn" >Publish Again*/}
                                        {/*</button>*/}
                                    </>
                                    }
                                </div>
                                <span className="pro-menu-icon small-dropdown">
                                    <Dropdown>
									<Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
										<img
                                            src={
                                                process.env.REACT_APP_URL + "images/menu-bg-icon.svg"
                                            }
                                            alt="img"
                                        />
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<div className="drop-down-link-hold">
											<Dropdown.Item className={`dropdown-item ${(test && test.status === 'published')?'disabled-link':''}`} onClick={()=>{

											    if((test && test.status === 'completed')) {
                                                    setRename({open: true, isLoading: false});
                                                }

											}}>
												Rename
												<span>
													<img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/edit-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
												</span>
											</Dropdown.Item>
                                            {test && test.status === 'completed' &&
                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                setOpenArchiveTestModal(true);
                                            }}>
                                                Archive
                                                <span>
													<img
                                                        src={
                                                            process.env.REACT_APP_URL +
                                                            "images/archive.svg"
                                                        }
                                                        alt="img"
                                                    />
												</span>
                                            </Dropdown.Item>
                                            }
										</div>
									</Dropdown.Menu>
								</Dropdown>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ArchiveTest
                test={test}
            openModal={openArchiveTestModal}
            closeModal={()=>{setOpenArchiveTestModal(false)}}/>
            <Unpublished
                test={test}
            openModal={openUnpublishedModal}
            closeModal={closeUnpublishedModal}/>
            <CopyTest
                test={test}
            openModal={openCopyTestModal}
            closeModal={()=>{setOpenCopyTestModal(false)}} />
            <RenameModal
                title={title}
                open={rename.open}
                heading={"Rename test"}
                close={()=>{setRename({"open":false,isLoading:false} )}}
                language={test.language}
                isLoading={rename.isLoading}
                confirm={(title)=>renameTest(title)}
            />
        </div>
    )
}