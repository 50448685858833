import React, { useEffect, useState, useRef } from "react";
export default function FiveSecondsTestTask({ test, goToFirstQuestion }) {
    const [imageScreen, setImageScreen] = useState(false);
    const [remainingSeconds, setRemainingSeconds] = useState(4);
    const [remainingSubSeconds, setRemainingSubSeconds] = useState(60);
    const [remainingSubSubSeconds, setRemainingSubSubSeconds] = useState(0);
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        if (imageScreen && test.five_seconds_test_task.task_file) {
            setTimeout(function () {
                if (remainingSubSeconds == 1) {
                    if (remainingSeconds > 0) {
                        setRemainingSeconds(remainingSeconds - 1);
                    }
                    else {
                        goToFirstQuestion();
                    }
                    setRemainingSubSeconds(60);
                } else {
                    setRemainingSubSeconds(remainingSubSeconds - 1);
                }

            }, (1000 / 60));
        }
    }, [remainingSubSeconds, imageScreen]);

    // useEffect(() => {
    //     if (imageScreen) {
    //         setTimeout(function () {
    //             if(remainingSeconds>0){
    //             setRemainingSeconds(remainingSeconds - 1);
    //             }
    //         }, 1000);

    //     }
    // }, [remainingSeconds, imageScreen]);

    return (

        <div className="tester-page-wrapper tester-welcome-page-wrap">
            <div className="container inner-page-container">
                <div className="five-seconds-task-wrapper">
                    {!imageScreen &&
                        <>

                            <div className="five-sec-inner-text-data">
                                <div className="five-second-task">

                                    <h2 className={`h2 ${test.language == "ar" ? "cairo-font" : ""}`}>
                                        {test.five_seconds_test_task.task}
                                    </h2>


                                </div>
                                <div className="five-second-show-design-button">
                                    <div className="button-wrapper">
                                        <button className="button primary-btn ml-0" onClick={() => {
                                            setImageScreen(true)
                                            // setTimeout(function () {
                                            //     goToFirstQuestion();

                                            // }, 5000)
                                        }}>{test.language == "en" ? "Show the design" : "اعرض التصميم"}</button>
                                    </div>
                                    <div className="subheading">{test.language == "en" ? "The design will be shown for 5 seconds after you click the button." : "سيظهر التصميم لمدة 5 ثوانٍ بعد النقر فوق الزر."}</div>
                                </div>
                            </div>
                        </>
                    }
                    {imageScreen &&
                        <>
                            {!fullScreen &&
                                <>
                                    <div className="five-sec-timer-design-wrap">
                                        <div>
                                            <div className="five-seconds-timer">
                                                <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.14331 6.6989L9.58268 2.98113L16.022 6.6989V14.1344L9.58268 17.8522L3.14331 14.1344V6.6989Z" stroke="#171637" stroke-width="0.833333" />
                                                    <path d="M5.24568 7.99723L9.58398 5.49252L13.9223 7.99723V13.0067L9.58398 15.5114L5.24568 13.0067V7.99723Z" stroke="#171637" stroke-width="0.416667" />
                                                    <circle cx="9.58398" cy="10.416" r="1.25" fill="#171637" />
                                                    <path d="M9.16602 1.48778L9.58268 0.833984L9.99935 1.48778V10.834H9.16602V1.48778Z" fill="#171637" />
                                                    <path d="M13.26 6.84738L13.7136 6.62957L13.4957 7.08308L9.81937 10.7595L9.58367 10.5238L13.26 6.84738Z" fill="#171637" />
                                                </svg>
                                                <span>{('0' + remainingSeconds).slice(-2) + ":" + ('0' + remainingSubSeconds).slice(-2)}</span>
                                            </div>
                                            <div className="five-seconds-task-design small-screen">
                                                <img src={process.env.REACT_APP_IMG_URL + test.five_seconds_test_task.task_file} />
                                                <div className="design-max-screen-icon" onClick={() => { setFullScreen(true) }}>
                                                    <img src={process.env.REACT_APP_URL + "images/maxim-icon.svg"} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {fullScreen &&
                                <div class="design-fixed-full-view five-seconds-full-view" style={{ backgroundColor: test.five_seconds_test_task.background ? test.five_seconds_test_task.background + " !important" : "" }}>
                                    <div class="prefer-design-slider-hold" style={{ backgroundColor: test.five_seconds_test_task.background ? test.five_seconds_test_task.background : "" }}>
                                        <div class="close-design-modal" onClick={() => { setFullScreen(false) }}><img src="https://appdevelopment.userq.com/images/close-menu.svg" alt="icon" /></div>

                                        <div class="preference-test-design-wrap preference-taking-task-wrap">
                                            <div class="prefer-design-image-view-hold">
                                                <div class="prefer-test-selected-design-view" style={{ backgroundColor: test.five_seconds_test_task.background ? test.five_seconds_test_task.background : "" }}>
                                                    <img src={process.env.REACT_APP_IMG_URL + test.five_seconds_test_task.task_file} />
                                                    <div class="design-max-screen-icon"><img src="https://appdevelopment.userq.com/images/maxim-icon.svg" alt="icon" /></div>
                                                </div>
                                                <div class="test-design-name-and-option-wrap">
                                                    <div className="five-seconds-timer">
                                                        <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.14331 6.6989L9.58268 2.98113L16.022 6.6989V14.1344L9.58268 17.8522L3.14331 14.1344V6.6989Z" stroke="#171637" stroke-width="0.833333" />
                                                            <path d="M5.24568 7.99723L9.58398 5.49252L13.9223 7.99723V13.0067L9.58398 15.5114L5.24568 13.0067V7.99723Z" stroke="#171637" stroke-width="0.416667" />
                                                            <circle cx="9.58398" cy="10.416" r="1.25" fill="#171637" />
                                                            <path d="M9.16602 1.48778L9.58268 0.833984L9.99935 1.48778V10.834H9.16602V1.48778Z" fill="#171637" />
                                                            <path d="M13.26 6.84738L13.7136 6.62957L13.4957 7.08308L9.81937 10.7595L9.58367 10.5238L13.26 6.84738Z" fill="#171637" />
                                                        </svg>
                                                        <span>{('0' + remainingSeconds).slice(-2) + ":" + ('0' + remainingSubSeconds).slice(-2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}