import { useNavigate, Link } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import { Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { logoutService } from "../../../services/user";
import { getTesterCreditSummaryService } from "../../../services/credits";
import { atom, useRecoilState } from "recoil";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { roundNumber } from "../../../lib/helpers";

export default function Header(props) {
    ReactSession.setStoreType("localStorage");
    const navigate = useNavigate();
    const [user, setUser] = useState({ first_name: "", last_name: "", role: "" });

    const [creditsLoading, setCreditsLoading] = useState(false);

    /*const creditsState = atom({
          key: 'credits',
          default: 0
      });
      const [credits, setCredits] = useRecoilState(creditsState);*/
    const [credits, setCredits] = useState(null);

    const [mobileMenu, setMobileMenu] = useState(false);

    const logout = (e) => {
        e.preventDefault();
        let user = ReactSession.get("user");

        if (!user) {
            navigate("/tester/login");
        }

        let token = ReactSession.get("token");

        logoutService(token).then((response) => {
            if (response.success) {
            }
        });
        //resetCredits();

        ReactSession.set("token", null);
        ReactSession.set("user", null);
        ReactSession.set("plan", null);
        ReactSession.set("show_subscribed", null);
        ReactSession.set("username", null);

        localStorage.clear();
        if (user.role == "Tester") {
            navigate("/tester/login");
        } else {
            navigate("/researcher/login");
        }
    };
    const goToHome = () => {
        let user = ReactSession.get("user");

        if (user.role == "Tester") {
            return process.env.REACT_APP_TESTER_URL;
        } else {
            return process.env.REACT_APP_RESEARCHER_URL;
        }
    };

    const fetchCreditsSummary = () => {
        if (!credits) {
            let token = ReactSession.get("token");

            setCreditsLoading(true);

            getTesterCreditSummaryService(token).then((response) => {
                setCreditsLoading(false);
                if (response.success) {
                    ReactSession.set("credits", response.summary.credits_balance);
                    setCredits(response.summary.credits_balance);
                }
            });
        }
    };



    useEffect(() => {
        let user = ReactSession.get("user");
        console.log(user);
        if (!props.skipCheckLogin) {

            if (!user) {
                navigate("/tester/login");
            }
            setUser(user);
            fetchCreditsSummary();

        }
    }, []);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMobileMenu(!mobileMenu);
    };
    const getFooterYear = () => {
        const d = new Date();
        let year = d.getFullYear();
        return <>{year}</>;
    };
    return (
        <div className={`login-page-header inner-page-hdr-wrap ${(props.fixed_header_target) ? "fixed_header_target" : "fixed_header"}`}>
            <div className="container inner-page-container d-flex justify-content-between align-items-center">
                <div className="login-hdr-left">
                    {(!props.skipCheckLogin) ?
                        <Link to="/dashboard" className="logo">
                            <img
                                src={process.env.REACT_APP_URL + "images/logo.svg"}
                                alt="logo"
                            />
                        </Link>
                        :
                        <Link to="/tester/login" className="logo">
                            <img
                                src={process.env.REACT_APP_URL + "images/logo.svg"}
                                alt="logo"
                            />
                        </Link>
                    }
                </div>
                {(!props.skipCheckLogin) &&
                    <div className="login-hdr-right inner-page-right-hdr">

                        {/*<Link className="upgrade-link" to={"/tester/invite-and-earn"}>*/}
                        {/*  Invite & Earn*/}
                        {/*</Link>*/}

                        {props.profileCompleted == false ?
                            <></>
                            :
                            <span className="count-coin-wrap tester-wallet-count">
                                <Link to={"/tester/wallet"} className="wallet-cout-link">
                                    {!creditsLoading ?
                                        <>
                                            <span className="tester-wallet-count-symbol">$</span>
                                            {props.setCreditZero == true ? 0 : (props.credits ? roundNumber(props.credits, 2) : roundNumber(credits, 2))}{" "}
                                        </>
                                        :
                                        <Skeleton width={30} height={12} />

                                    }
                                </Link>
                            </span>}
                        <span className="username-drop-down">
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdownMenuButton"
                                    className="dropdown-toggle"
                                >
                                    {props.username
                                        ? props.username
                                        : user.first_name + " " + user.last_name}
                                </Dropdown.Toggle>
                                {props.profileCompleted == false ?
                                    <Dropdown.Menu className="step-dropdown-menu">
                                        <Dropdown.Item href="#" onClick={logout} className="step-dropdown-item">
                                            Log out
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                    :
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            as={Link}
                                            to={"/profile"}
                                            className="profile-link"
                                        >
                                            <img
                                                src={process.env.REACT_APP_URL + "images/profile-icon.svg"}
                                                alt="img" width="24" height="24"
                                            />{" "}
                                            Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={"/tester/tests"}
                                            className="profile-link"
                                        >
                                            <img
                                                src={process.env.REACT_APP_URL + "images/test-history-icon.svg"}
                                                alt="img" width="24" height="24"
                                            />
                                            History
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={"/tester/wallet"}
                                            className="profile-link"
                                        >
                                            <img
                                                src={process.env.REACT_APP_URL + "images/credits-icon.svg"}
                                                alt="img" width="24" height="24"
                                            />
                                            Wallet
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                            as={Link}
                                            to={"/tester/settings"}
                                            className="profile-link"
                                        >
                                            <img
                                                src={
                                                    process.env.REACT_APP_URL + "images/pro-setting-icon.svg"
                                                }
                                                alt="img"
                                            />
                                            Settings
                                        </Dropdown.Item>
                                        <div className="border-wrap"></div>
                                        <Dropdown.Item href="#" onClick={() => {
                                            navigate('/tester/support/')
                                        }} class={"support-link-btn"}>
                                            SUPPORT
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={logout}>
                                            Log out
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                }
                            </Dropdown>
                        </span>
                        <span className="username-drop-down notification-btn mr-0 pr-0">
                            <div className="dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdownMenuButton"
                                        className="dropdown-toggle"
                                    >
                                        <img
                                            src={process.env.REACT_APP_URL + "images/notification.svg"}
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="no-data">No notifications</div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </span>
                    </div>
                }
                {(!props.skipCheckLogin) &&
                    <div className="none-for-desktop header-mobile-right-side">
                        <div className="mobile-notification-data">
                            <div className="m-username-noti-wrap">

                                <div className="wallet-notification-mobile-both">
                                    {props.profileCompleted == false ?
                                        <></>
                                        :
                                        <Link to={"/tester/wallet"}>
                                            <b>

                                                {!creditsLoading ?
                                                    <>${props.setCreditZero == true ? 0 : (props.credits ? roundNumber(props.credits, 2) : roundNumber(credits, 2))} </>
                                                    :
                                                    <Skeleton width={30} height={12} />
                                                }
                                            </b>
                                        </Link>
                                    }
                                    <span className="m-notification-icon">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/notification-icon.svg"}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="m-menu-open-btn"
                            style={!mobileMenu ? { display: "none" } : {}}
                            onClick={toggleMenu}
                        >
                            <span>
                                <img
                                    src={process.env.REACT_APP_URL + "images/mobile-menu.svg"}
                                    alt="logo"
                                />
                            </span>
                        </div>

                    </div>
                }
                <div
                    className="mobile-menu-wrap"
                    style={!mobileMenu ? { display: "none" } : {}}
                >
                    <div className="mobile-menu-header">
                        <div className="mobile-menu-logo">
                            <a href="/dashboard" className="logo">
                                <img
                                    src={process.env.REACT_APP_URL + "images/logo.svg"}
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <div className="mobile-menu-btn">
                            <div className="menu-btn-wrap" onClick={toggleMenu}>
                                <img
                                    src={process.env.REACT_APP_URL + "images/close-menu.svg"}
                                    alt="img"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="m-menu-inner-data">
                        <div className="m-username-noti-wrap">
                            <span className="m-user-name">
                                {props.username
                                    ? props.username
                                    : user.first_name + " " + user.last_name}
                            </span>

                        </div>
                        
                            <div className="m-profile-menu-wrap">
                            {props.profileCompleted == false ?
                            <></>
                            :
                                <ul className="m-menu-ul">
                                    <li
                                        className={
                                            props.activeMenu == "profile" ? "profile-menu-link" : ""
                                        }
                                    >
                                        <Link to={"/profile"}>Profile</Link>
                                    </li>
                                    <li
                                        className={
                                            props.activeMenu == "dashboard" ? "profile-menu-link" : ""
                                        }
                                    >
                                        <Link to={"/tester/tests"}>History</Link>
                                    </li>
                                    <li
                                        className={
                                            props.activeMenu == "dashboard" ? "profile-menu-link" : ""
                                        }
                                    >
                                        <Link to={"/tester/wallet"} className={"d-flex align-items-center justify-content-between by-credit-link"}>
                                            <span>Wallet</span>
                                            <b>
                                                $
                                                {!creditsLoading && (
                                                    <>{props.credits ? props.credits : credits} </>
                                                )}
                                            </b>
                                        </Link>
                                    </li>
                                    {/*<li*/}
                                    {/*  className={*/}
                                    {/*    props.activeMenu == "invite-and-earn" ? "profile-menu-link" : ""*/}
                                    {/*  }*/}
                                    {/*>*/}
                                    {/*  <Link to={"/tester/invite-and-earn"}>*/}
                                    {/*    Invite & Earn*/}
                                    {/*  </Link>*/}
                                    {/*</li>*/}
                                    <li
                                        className={
                                            props.activeMenu == "settings" ? "profile-menu-link" : ""
                                        }
                                    >
                                        <Link to={"/tester/settings"}>
                                            Settings
                                        </Link>
                                    </li>
                                </ul>
                                }
                            </div>
                        

                        
                            <div className="m-footer-logout-wrap">
                            {props.profileCompleted == false ?
                                <div className="m-logout-left-wrap">
                                    <a href="#" onClick={logout}>
                                        Log out{" "}
                                        <img
                                            src={process.env.REACT_APP_URL + "images/arrow-frame.svg"}
                                            alt="img"
                                        />
                                    </a>
                                </div>
                                :
                                <div className="m-logout-left-wrap">
                                    <Link to={'/tester/support/'} className={"support-link-mob"}>
                                        SUPPORT
                                    </Link>
                                    <a href="#" onClick={logout}>
                                        Log out{" "}
                                        <img
                                            src={process.env.REACT_APP_URL + "images/arrow-frame.svg"}
                                            alt="img"
                                        />
                                    </a>
                                </div>}

                                <div className="m-social-links-wrap">
                                    <a href="https://www.linkedin.com/company/userqofficial/">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/linkedin-icon.svg"}
                                            alt="img"
                                        />
                                    </a>
                                    <a href="https://twitter.com/userqofficial">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/twitter-icon.svg"}
                                            alt="img"
                                        />
                                    </a>
                                    <a href="https://www.instagram.com/userqofficial/">
                                        <img
                                            src={process.env.REACT_APP_URL + "images/insta-icon.svg"}
                                            alt="img"
                                        />
                                    </a>
                                </div>
                            </div>
                        
                        <div className="m-menu-footer-wrap">
                            <p>&copy; {getFooterYear()} USERQ. All rights reserved.</p>
                            <div className={"footer-extra-link"}>
                                <span className="terms-of-use-txt-link-mob">
                                    <a href="https://userq.com/terms-of-use/">
                                        Terms of Use
                                    </a>
                                </span>
                                <span className="privacy-policy-txt-link-mob">
                                    <a href="https://userq.com/privacy-policy/">
                                        Privacy Policy
                                    </a>
                                </span>
                                <span className="cookie-policy-text">
                                    <a href="https://userq.com/privacy-policy/#cookies-policy">
                                        Cookie policy{" "}
                                        <img
                                            src={process.env.REACT_APP_URL + "images/cookie-icon.svg"}
                                            alt="img"
                                        />{" "}
                                    </a>
                                </span>
                            </div>
                            <p>
                                Proudly created by <b>Digital Of Things</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
