import React from "react";
import { useNavigate, useParams } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import ConfirmModal from "../../dialog/confirm_modal";
import moment from "moment";
import redirectToStep from "./redirect_to_step";
import { FormattedMessage } from "react-intl";
import Frame from "../study/frame/frame.js";
import Questions from "./survey/questions";
import EditQuestion from "./survey/editQuestion";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getTestData, saveSurveyQuestions } from "../../../services/test";
import { isEmpty } from "lodash";
import {LoadingIcon} from "../../loader/loadingIcon";

export default function Survey() {
    let { test_id } = useParams();
    const nodeRef = useRef(null);
    const navigate = useNavigate();
    ReactSession.setStoreType("localStorage");
    const [formValues, setFormValues] = useState({
        tree: [],
        tasks: [],
    });
    const [pageLoading, setPageLoading] = useState(false);

    const [surveyStepLoading, setSurveyStepLoading] = useState(false);
    const [language, setLanguage] = useState("en");
    const [questionType, setQuestionType] = useState("");
    const [surveyQuestions, setSurveyQuestions] = useState([]);

    const [lastSaved, setLastSaved] = useState(null);

    const [isLoading, setLoading] = useState(false);

    const [addingSurveyQuestions, setAddingSurveyQuestions] = useState(false);
    const [edittingSurveyQuestions, setEdittingSurveyQuestions] = useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);

    const [confirmModal, setConfirmModal] = useState({ open: false });

    const [test, setTest] = useState("");

    const [step, setStep] = useState(0);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [dataSaving, setDataSaving]=useState(false);

    const showError = (error) => {
        toast(
        <div className="toastinner">
            {error}
            <a
            onClick={() => {
                toast.dismiss();
            }}
            >
            &times;
            </a>
        </div>,
        {
            className: "errortoast",
            position: "bottom-center",
            duration: 3000,
        }
        );
    };

    const cancelQuestionHandler = () => {
        if (surveyQuestions.length != 0) {
        setAddingSurveyQuestions(false);
        }
        setQuestionType("");
    };
    const cancelEditQuestionHandler = () => {
        setEdittingSurveyQuestions(false);
        setEditQuestionId(null);
    };

    const removequestion = (index) => {
        if (!edittingSurveyQuestions) {
        const list = [...surveyQuestions];
        list.splice(index, 1);
        setSurveyQuestions(list);
        if (list.length == 0) {
            setAddingSurveyQuestions(true);
        }
        } else {
        showError("Finish editing question");
        }
    };

    const duplicatequestion = (index) => {
        if (!edittingSurveyQuestions) {
        const list = [...surveyQuestions];
        const question = surveyQuestions[index];
        // list.push(question);
        list.splice(index,0,question);
        setSurveyQuestions(list);
        } else {
        showError("Finish editing question");
        }
    };

    const edittingQuestionsHandler = (index) => {
        if (!edittingSurveyQuestions) {
        setEdittingSurveyQuestions(true);
        setEditQuestionId(index);
        } else {
        toast(
            <div className="toastinner">
            {<FormattedMessage id="Finish editing earlier question." />}
            <a
                onClick={() => {
                toast.dismiss();
                }}
            >
                &times;
            </a>
            </div>,
            {
            className: "errortoast",
            position: "bottom-center",
            duration: 3000,
            }
        );
        }
    };

    const saveSurvey = (saveUsingNavigation) => {
        let token = ReactSession.get("token");
        if (!saveUsingNavigation) {
            setSurveyStepLoading(true);
        }
        var data = new FormData();

        data.append("test_id", test_id);

        if (!saveUsingNavigation) {
            data.append("saved_step", 2);
        }

        surveyQuestions.forEach((item) => {
            data.append("survey_questions[]", JSON.stringify(item));
        });
        if(dataSaving==false){
        setDataSaving(true)
        saveSurveyQuestions(data, token).then((response) => {
            setSurveyStepLoading(false);
            setDataSaving(false);
            if (response.success) {
                
                setLastSaved(moment.now());
                if (!saveUsingNavigation) {
                navigate("/researcher/tests/" + test_id + "/conclusions/");
                }
            } else {
                console.log(response);
            }
        });
        }
    };

    const getTestApi = () => {
        let token = ReactSession.get("token");

        setPageLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
        setPageLoading(false);

        if (response.success) {
            setTest(response.test);
            setLanguage(response.test.language);

            if (response.test.status) {
            if (response.test.status == "published") {
                navigate("/researcher/project/" + response.test.project_id);
            } else {
                if (
                response.test.is_briefing_questions == 1 &&
                response.test.briefingQuestion.length == 0
                ) {
                navigate("/researcher/tests/" + test_id + "/introduction");
                showError("Please add briefing questions");
                }
            }
            }

            setLastSaved(moment(response.test.updated_at));

            let questions = [];
            response.test.surveyQuestion.forEach((question) => {
            questions.push(JSON.parse(question));
            });
            if (questions.length < 1) {
            setAddingSurveyQuestions(true);
            }

            setSurveyQuestions(questions);

            setFormValues({ tree: response.test.tree, tasks: response.test.tasks });
        } else {
            toast(
            <div className="toastinner">
                {response.message}
                <a
                onClick={() => {
                    toast.dismiss();
                }}
                >
                &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
            }
            );
        }
        });
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Survey";

        getTestApi();
    }, []);

    useEffect(() => {
        if (nodeRef.current) {
        nodeRef.current.focus();
        }
        const timer = setInterval(() => {
        if (test && test.status != "published" && !edittingSurveyQuestions) {
            saveSurvey(true);
        }
        }, 30000);

        return () => {
        clearTimeout(timer);
        };
    }, [surveyQuestions,edittingSurveyQuestions]);
    const set = (name) => {
        return ({ target: { value } }) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    const setFormValue = (name, value) => {
        setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
        return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
        parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
        [...draggedDOM.parentNode.children]
            .slice(0, sourceIndex)
            .reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
            window.getComputedStyle(draggedDOM.parentNode).paddingLeft
        ),
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
        return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
        return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;
        
        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
        parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
        updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
        }, 0);

        setPlaceholderProps({
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(
            window.getComputedStyle(draggedDOM.parentNode).paddingLeft
        ),
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    return (
    <LayoutResearcherFullWidth
        isLoading={false}
        fixed_header_target={true}
        wrapClass={test && test.language == "ar" ? "arabic_wrapper_main" : ""}
        skipCheck={false}
        extendedFooter={false}
        activeMenu={""}
    >
        <div className="study-introduction-page-wrapper survey-test-page-wrapper stydy-pages-wrapper target_group_fixed_header">
            <div className="page-back-option-wrap">
                <div className="container inner-page-container">
                    <Topbar
                    test={test}
                    credits_required={test.credits_required}
                    enablePublish={false}
                    onChange={(test) => {
                        setTest({ ...test });
                    }}
                    status={test.status}
                    lastSaved={lastSaved}
                    path={"/researcher/tests/" + test_id + "/introduction"}
                    isLoading={false}
                    pageLoading={pageLoading}
                    title={test.test_name}
                    stepCallback={() => {
                        saveSurvey(true);
                    }}
                    />
                </div>
            </div>
            <div className="container inner-page-container">
                <div className="study-steps-progress-wrap">
                    {(
                        <Progress
                            stepCallback={() => {
                            saveSurvey(true);
                            }}
                            test={test}
                            completed_step={1}
                        />
                    )}
                </div>

                {pageLoading &&
                <LoadingIcon />
                }
                {!pageLoading &&
                <div className="study-introduction-page-wrapper preference-test-page-data">
                    <div className="target-group-data-wrapper">
                        <div className="target-group-data-repeat">
                            <div className="targer-group-left-wrap pb-0 border-0">
                                {/* <p className="page-step-count">Page 2</p> */}
                                <h3 className="mb-0">Survey</h3>
                                {surveyQuestions.length === 0 ? (
                                    <div className="nodata-wrap"></div>
                                ) : (
                                    <div className="added-question-wrap mb-32" style={{position: 'relative'}}>
                                        <DragDropContext
                                            onDragEnd={(param) => {
                                                setPlaceholderProps({});
                                                if (edittingSurveyQuestions) {
                                                    showError("Finish editing your question first.");
                                                } else {
                                                    const srcI = param.source.index;
                                                    const desI = param.destination.index;
                                                    surveyQuestions.splice(
                                                        desI,
                                                        0,
                                                        surveyQuestions.splice(srcI, 1)[0]
                                                    );
                                                }
                                            }}
                                            onDragStart={handleDragStart}
                                            onDragUpdate={handleDragUpdate}
                                        >
                                            <Droppable droppableId="droppable-1" isCombineEnabled>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        {surveyQuestions.map((question, index) => (
                                                            <div
                                                                key={index}
                                                                className="survey-added-question-repeat"
                                                            >
                                                                <Draggable
                                                                    draggableId={"draggable-" + index}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={
                                                                                snapshot.isDragging
                                                                                    ? "question-answers-dragging"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {editQuestionId === index ? (
                                                                                <></>
                                                                            ) : (
                                                                                <div
                                                                                    key={index}
                                                                                    className="survey-question-preview"
                                                                                >
                                                                                    <div
                                                                                        className={`question-answer-holder ${(test.language=="en") ? "" : "arabic_wrapper"}`}>
                                                                                        <div className="add-more-question-option">
                                                                                            <div
                                                                                                className="ans-list-repeat saved-ans-repeat">
                                                                                                <div
                                                                                                    className="ans-icon"
                                                                                                    {...provided.dragHandleProps}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env
                                                                                                                .REACT_APP_URL +
                                                                                                            "images/drag-icon.svg"
                                                                                                        }
                                                                                                        alt="img"
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="ans-data">
                                                                                                    {question.questionType ==
                                                                                                    "free" ? (
                                                                                                        <h3 className="h3 mb-0 font-inter">
                                                                                                            Question {index + 1} -
                                                                                                            Free Text
                                                                                                        </h3>
                                                                                                    ) : question.questionType ==
                                                                                                    "singlechoice" ? (
                                                                                                        <h3 className="h3 mb-0 font-inter">
                                                                                                            Question {index + 1} -
                                                                                                            Single
                                                                                                            Choice
                                                                                                        </h3>
                                                                                                    ) : question.questionType ==
                                                                                                    "multiplechoice" ? (
                                                                                                        <h3 className="h3 mb-0 font-inter">
                                                                                                            Question {index + 1} -
                                                                                                            Multi Choice
                                                                                                        </h3>
                                                                                                    ) : question.questionType ==
                                                                                                    "ratingscale" ? (
                                                                                                        <h3 className="h3 mb-0 font-inter">
                                                                                                            Question {index + 1} -
                                                                                                            Rating Scale
                                                                                                        </h3>
                                                                                                    ) : question.questionType ==
                                                                                                    "likertscale" ? (
                                                                                                        <h3 className="h3 mb-0 font-inter">
                                                                                                            Question {index + 1} -
                                                                                                            Likert Scale
                                                                                                        </h3>
                                                                                                    ) : (
                                                                                                        <h3 className="h3 mb-0 font-inter">
                                                                                                            Question {index + 1} -
                                                                                                            Ranking
                                                                                                            Scale
                                                                                                        </h3>)}
                                                                                                    {question.is_optional ==
                                                                                                    true && (
                                                                                                        <span
                                                                                                            className="optional-span">
                                                                                                            optional
                                                                                                            </span>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="ans-close-row ans-edit-icon">
                                                                                                    <span onClick={() =>
                                                                                                        duplicatequestion(index)
                                                                                                    }>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env
                                                                                                                .REACT_APP_URL +
                                                                                                            "images/copy-icon.svg"
                                                                                                        }
                                                                                                        alt="img"

                                                                                                    />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="ans-close-row ans-edit-icon">
                                                                                                    <span onClick={() =>
                                                                                                        edittingQuestionsHandler(
                                                                                                            index
                                                                                                        )
                                                                                                    }>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process.env
                                                                                                                .REACT_APP_URL +
                                                                                                            "images/edit-icon.svg"
                                                                                                        }
                                                                                                        alt="img"

                                                                                                    />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="ans-close-row">
                                                                                                <span onClick={() =>
                                                                                                    removequestion(index)
                                                                                                }>
                                                                                                <img
                                                                                                    src={
                                                                                                        process.env
                                                                                                            .REACT_APP_URL +
                                                                                                        "images/cross.svg"
                                                                                                    }
                                                                                                    alt="img"

                                                                                                />
                                                                                                </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <p className="survey-question-added-info">
                                                                                                {question.question}
                                                                                            </p>
                                                                                        </div>
                                                                                        {question.questionType !=
                                                                                        "free" && (
                                                                                            <div
                                                                                                className="ans-list-wrap your-selected-answer-view">
                                                                                                <p className="small-info-heading">
                                                                                                    Your answers
                                                                                                </p>
                                                                                                {(question.questionType != "ratingscale" && question.questionType != "likertscale") && question.options.map(
                                                                                                    (option, i) => (

                                                                                                        <div
                                                                                                            className={`ans-list-repeat saved-ans-repeat ${question.is_logic==1?"add-logic-read-mode":""}`}>
                                                                                                            <img
                                                                                                                src={
                                                                                                                    process.env
                                                                                                                        .REACT_APP_URL +
                                                                                                                    "images/Polygon-bg.svg"
                                                                                                                }
                                                                                                                alt="img"
                                                                                                            />

                                                                                                            <div
                                                                                                                className="ans-data">
                                                                                                                {option}
                                                                                                            </div>
                                                                                                            {question.is_logic==1 &&
                                                                                                            <div
                                                                                                                className="ans-data jump-to-box">
                                                                                                                {
                                                                                                                    question.jumpTo[i] == "End Survey" ?
                                                                                                                    <>Jump to &nbsp;<b>End Survey</b></>
                                                                                                                    :
                                                                                                                    question.jumpTo[i] - (index+1) == 1 ?
                                                                                                                        <>Jump to &nbsp;<b>Next Question</b></>
                                                                                                                        :
                                                                                                                        <>Jump to &nbsp;<b>Question {question.jumpTo[i]}</b></>
                                                                                                                }
                                                                                                            </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                                {((question.questionType == "ratingscale") || (question.questionType == "likertscale")) && (
                                                                                                    <div
                                                                                                        className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                        <div
                                                                                                            className="rating-survey-view-mode">
                                                                                                            {question.questionType == "ratingscale" && question.options.map(
                                                                                                                (option, index) => (

                                                                                                                    <label
                                                                                                                        for="radio4">
                                                                                                                        <p>{option}</p>
                                                                                                                        <input
                                                                                                                            id={`radio-${index}`}
                                                                                                                            type="radio"
                                                                                                                            name="ans"
                                                                                                                            value={option}
                                                                                                                            className="hidden radio_btn_input"
                                                                                                                            required="required"/>
                                                                                                                        <span>
                                                                                                                            <img
                                                                                                                                className="empty-fill-icon"
                                                                                                                                src={
                                                                                                                                    process.env.REACT_APP_URL +
                                                                                                                                    "images/Polygon-blank.svg"
                                                                                                                                }
                                                                                                                            />

                                                                                                                        </span>
                                                                                                                    </label>

                                                                                                                )
                                                                                                            )}
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className="likert-question-data-hold likert-view-wrap">

                                                                                                            {question.questionType == "likertscale" && (
                                                                                                                <div
                                                                                                                    className="survey-rating-options-wrap">
                                                                                                                    <div
                                                                                                                        className="bd-question-radio-data">
                                                                                                                        <div
                                                                                                                            className="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                                            <div
                                                                                                                                className="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                                                <div
                                                                                                                                    className="ans-list-repeat saved-ans-repeat">

                                                                                                                                    <div
                                                                                                                                        className="likert-option-left">
                                                                                                                                        <div
                                                                                                                                            className="ans-icon">
                                                                                                                                            &nbsp;
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            className="ans-data">
                                                                                                                                            &nbsp;
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                    <div
                                                                                                                                        className="likert-option-right">
                                                                                                                                        {question.options.map((option, index) => (
                                                                                                                                            <label
                                                                                                                                                for="radio4">
                                                                                                                                                <p>{option}</p>
                                                                                                                                            </label>
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>


                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            {question.questionType == "likertscale" && question.subQuestions.map(
                                                                                                                (subQuestion, index) => (

                                                                                                                    <div
                                                                                                                        className="ans-list-wrap likert-scale-option-add">
                                                                                                                        <div
                                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                                            <div
                                                                                                                                className="likert-option-left">
                                                                                                                                <div
                                                                                                                                    className="ans-data">{subQuestion}</div>
                                                                                                                            </div>


                                                                                                                            <div
                                                                                                                                className="likert-option-right">
                                                                                                                                <div
                                                                                                                                    className="survey-rating-options-wrap">
                                                                                                                                    <div
                                                                                                                                        className="bd-question-radio-data">
                                                                                                                                        <div
                                                                                                                                            className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                                            {question.options.map((option, index) => (
                                                                                                                                                <label
                                                                                                                                                    for="radio4">
                                                                                                                                                    {/* <p>{option}</p> */}
                                                                                                                                                    <input
                                                                                                                                                        id={`radio-${index}`}
                                                                                                                                                        type="radio"
                                                                                                                                                        name="ans"
                                                                                                                                                        value={option}
                                                                                                                                                        className="hidden radio_btn_input"
                                                                                                                                                        required="required"/>
                                                                                                                                                    <span>
                                                                                                                                                    <img
                                                                                                                                                        className="empty-fill-icon"
                                                                                                                                                        src={
                                                                                                                                                            process.env.REACT_APP_URL +
                                                                                                                                                            "images/Polygon-blank.svg"
                                                                                                                                                        }
                                                                                                                                                    />
                                                                                                                                                    <img
                                                                                                                                                        className="fill-icon"
                                                                                                                                                        src={
                                                                                                                                                            process.env.REACT_APP_URL +
                                                                                                                                                            "images/pol-fill.svg"
                                                                                                                                                        }
                                                                                                                                                    />
                                                                                                                                                    </span>
                                                                                                                                                </label>
                                                                                                                                            ))}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>)}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                                <div className={`question-edit ${test.language=="ar"?"arabic_wrapper":""}`}>
                                                                    {edittingSurveyQuestions &&
                                                                    editQuestionId === index && (
                                                                        <EditQuestion
                                                                            questionDetails={
                                                                                surveyQuestions[index]
                                                                            }
                                                                            cancelEditQuestion={
                                                                                cancelEditQuestionHandler
                                                                            }
                                                                            editQuestions={(question) => {
                                                                                surveyQuestions.splice(
                                                                                    index,
                                                                                    1,
                                                                                    question
                                                                                );
                                                                                setSurveyQuestions([
                                                                                    ...surveyQuestions,
                                                                                ]);
                                                                            }}
                                                                            language={language}
                                                                            other={
                                                                                test.language == "en" ?
                                                                                    question.questionType == "free"
                                                                                        ? "Other"
                                                                                        : surveyQuestions[
                                                                                            index
                                                                                            ].options.slice(-1) == "Other"
                                                                                        ? true
                                                                                        : false : question.questionType == "free"
                                                                                    ? "آخر"
                                                                                    : surveyQuestions[
                                                                                        index
                                                                                        ].options.slice(-1) == "آخر"
                                                                                        ? true
                                                                                        : false
                                                                            }
                                                                            questionno={index + 1}
                                                                            questionlist={surveyQuestions}
                                                                            changeQuestionType={(questionType)=>{surveyQuestions[index].questionType=questionType}}
                                                                            addLikertSubquestions={()=>{surveyQuestions[index].subQuestions=[""]}}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {provided.placeholder}
                                                        {!isEmpty(placeholderProps) && (
                                                            <div
                                                                className="placeholder"
                                                                style={{
                                                                    top: placeholderProps.clientY,
                                                                    left: placeholderProps.clientX,
                                                                    height: placeholderProps.clientHeight,
                                                                    width: placeholderProps.clientWidth,
                                                                    position: "absolute",
                                                                    borderColor: "#000000",
                                                                }}
                                                            />
                                                        )}

                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                )}
                                {!edittingSurveyQuestions &&
                                (!addingSurveyQuestions ? (
                                    <button
                                        className="create-btn add-question-btn"
                                        onClick={() => {
                                            if (surveyQuestions.length < 5000) {
                                                setAddingSurveyQuestions(true);
                                            } else {
                                                toast(
                                                    <div className="toastinner">
                                                        {
                                                            <FormattedMessage
                                                                id="You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones."/>
                                                        }
                                                        <a
                                                            onClick={() => {
                                                                toast.dismiss();
                                                            }}
                                                        >
                                                            &times;
                                                        </a>
                                                    </div>,
                                                    {
                                                        className: "errortoast",
                                                        position: "bottom-center",
                                                        duration: 3000,
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        ADD QUESTION
                                        <span>
                                <img
                                    src={
                                        process.env.REACT_APP_URL +
                                        "images/plus-white.svg"
                                    }
                                    alt="img"
                                />
                                </span>
                                    </button>
                                ) : (
                                    <></>
                                ))}
                                {addingSurveyQuestions && questionType === "" ? (
                                    <div className="ans-list-wrap question-prev-wrap  survey-test-option-wrap mb-0">
                                        <h3 className="mt-32 mb-0 d-flex justify-content-between">
                                            Question {surveyQuestions.length + 1}
                                            {surveyQuestions.length>0 && (
                                            <span className="close-question-options" onClick={()=>{setAddingSurveyQuestions(false)}}>
                                                <img alt="close-icon" src={process.env.REACT_APP_URL + "images/cross.svg"}/>
                                            </span>
                                            )}
                                        </h3>
                                        <div className="stud-tiles-hold mt-32">
                                            <Frame
                                                questionType="FREE TEXT"
                                                subheading="Free form answers"
                                                bgstyle="bg-1"
                                                onClick={() => {
                                                    setQuestionType("free");
                                                }}
                                            />
                                            <Frame
                                                questionType="SINGLE CHOICE"
                                                subheading="One answer can be selected"
                                                bgstyle="bg-2"
                                                onClick={() => {
                                                    setQuestionType("singlechoice");
                                                }}
                                            />
                                            <Frame
                                                questionType="MULTIPLE-CHOICE"
                                                subheading="Multiple answers can be selected"
                                                bgstyle="bg-3"
                                                onClick={() => {
                                                    setQuestionType("multiplechoice");
                                                }}
                                            />
                                            <Frame
                                                questionType="RATING SCALE"
                                                subheading="Answers on a rating scale"
                                                bgstyle="bg-1"
                                                onClick={() => {
                                                    setQuestionType("ratingscale");
                                                }}
                                            />
                                            <Frame
                                                questionType="LIKERT SCALE"
                                                subheading="Answers on a rating scale"
                                                bgstyle="bg-2"
                                                onClick={() => {
                                                    setQuestionType("likertscale");
                                                }}
                                            />
                                            <Frame
                                                questionType="RANKING SCALE"
                                                subheading="Answers ranked in order of preference"
                                                bgstyle="bg-3"
                                                onClick={() => {
                                                    setQuestionType("rankingscale");
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={`${(test.language == "en") ? "" : "arabic_wrapper"}`}>
                                    <Questions
                                        questionType={questionType}
                                        cancelQuestion={cancelQuestionHandler}
                                        addQuestions={(question) => {
                                            setSurveyQuestions([...surveyQuestions, question]);
                                            setAddingSurveyQuestions(false);
                                            setQuestionType("");
                                        }}
                                        language={language}
                                        questionno={surveyQuestions.length + 1}
                                        questionlist={surveyQuestions}
                                        changeQuestionType={(questionType)=>{setQuestionType(questionType)}}
                                    />
                                    </div>
                                )}
                                {!addingSurveyQuestions && questionType == "" ? (
                                    <div className="button-wrap d-flex justify-content-end mt-64">
                                        <button
                                            id="r_create_step_2_build_next_btn"
                                            type="submit"
                                            className="button primary-btn"
                                            onClick={() => saveSurvey(false)}
                                        >
                                            Next
                                            {surveyStepLoading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    aria-hidden="true"
                                                    style={{marginLeft: "5px"}}
                                                ></i>
                                            )}
                                        </button>
                                    </div>
                                ) : (
                                    <div className="button-wrap d-flex justify-content-end mt-64">
                                        <button
                                            id="r_create_step_2_build_next_btn"
                                            type="submit"
                                            className="button primary-btn disabled-button"
                                        >
                                            Next
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="target-group-info-repeat pb-0">
                                <h3>Questions</h3>
                                <p>
                                    There are various types of questions that you can ask in a
                                    survey. Choose open-ended or closed-ended questions to help
                                    you gather relevant data about your project.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>

        </div>
        <ConfirmModal
            confirm_message="Are you sure you want to delete this node?"
            confirm_btn_title="Delete"
            confirm_title="Delete"
            openModal={confirmModal.open}
            close={() => {
            setConfirmModal({ ...confirmModal, open: false });
            }}
            confirm={() => {
            setConfirmModal({ ...confirmModal, open: false });
            }}
        />
    </LayoutResearcherFullWidth>
  );
}
