import React, {useCallback} from 'react';
import { useNavigate, useParams,useLocation } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from '../../../lib/secure_reactsession';
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import { useState, useEffect, useRef } from "react";
import {
    getResearcherTestResultData,
    getTestCardSortingRCategoryesultData,
    getTestCardSortingCardsResultData,
    getTestCardSortingMatrixResultData,
    getTestCardSortingSimilarityMatrixResultData
} from "../../../services/researcher_test_results";
import toast from 'react-hot-toast';
import Topbar from './components/topbar';
import Sidebar from './components/sidebar';
import {getCurrentPlansService} from "../../../services/plans";
import Radiobtn from "../../layouts/elements/fields/radiobtn";
import {atom, useRecoilState} from 'recoil';

import AnyChart from 'anychart-react'
import {LoadingIcon} from "../../loader/loadingIcon";
import {Dropdown} from "react-bootstrap";
import exportAsImage from "../../../lib/exportAsImage";
import {downloadBlob} from "../../../lib/helpers";

export default function TestResultCardSorting() {

    let { test_id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const exportSimilarityRef = useRef();
    const exportResultGridRef = useRef();


    const testState = atom({
        key: 'testresult-'+test_id,
        default: ""
    });
    const planState = atom({
        key: 'testresultplan-'+test_id,
        default: ""
    });

    const [isLoading, setLoading] = useState(false);
    const [sectionLoading, setSectionLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [test, setTest] = useRecoilState(testState);
    const [planType, setPlanType] = useRecoilState(planState);

    const  [categoryArray,setCategoryArray] = useState([]);
    const  [cardArray,setCardArray] = useState([]);
    const  [matrixArray,setMatrixArray] = useState([]);
    const  [similarityMatrixArray,setSimilarityMatrixArray] = useState([]);
    const [metaData,setMetaData] = useState({active:'category','sorting_type':'open',cardSort:"",categorySort:""});
    const [expandData,setExpandData] = useState(false);
    const [expandSimilarityData,setExpandSimilarityData] = useState(false);

    const getTestafterEndTest=()=>{

        let token = ReactSession.get("token");

        getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                setTest(response.test);
            }else{
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        })

    }

    const getTest = () => {

        if(!test){
            let token = ReactSession.get("token");

            setLoading(true);

            getResearcherTestResultData({ test_id: test_id }, token).then((response) => {
                setLoading(false);

                if (response.success) {

                    setTest(response.test);
                    getCardSorting();


                } else {
                    navigate("/dashboard");
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        } else {
            getCardSorting();
        }
    };

    const getCardSorting = () => {
        let token = ReactSession.get("token");

        setSectionLoading(true);

        getTestCardSortingRCategoryesultData(
            {
                     test_id: test_id
                }
            ,token).then((response) => {
            setSectionLoading(false);

            if (response.success) {

                setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                setCategoryArray(response.category);

                /*const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                response.matrix.forEach((items)=>{

                    var fill = '';
                    if(response.matrixRangeColor.minMatrixData === items.heat){
                        fill = '#ECE8F7';
                    }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                        fill = '#EED9EF';
                    }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                        fill = '#F2BCDE';
                    }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                        fill = '#F7A0CE';
                    }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                        fill = '#FB83BD';
                    }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                        fill = '#FF66AD';
                    }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                        fill = '#96FFFC';
                    }else{
                        fill = '#FF66AD';
                    }



                    matrixData.push({
                        x:items.x,
                        y:items.y,
                        heat:items.heat,
                        fill:fill
                    })
                })

                //console.log(matrixData)

                setMatrixArray(matrixData);*/

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };

    const getCardSortingCards = () => {
        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingCardsResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            if (response.success) {

                //setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                setCardArray(response.cards);
                //setCategoryArray(response.category);


            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };

    const getCardSortingMatrix = () => {
        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingMatrixResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            if (response.success) {

               // setMetaData({...metaData,"sorting_type":response.test.card_sorting.card_sorting || 'open'});
                //setCardArray(response.cards);
                //setCategoryArray(response.category);

                const matrixData = [];
                // color code
                // #96FFFC
                // #FF66AD
                // #FB83BD
                // #F7A0CE
                // #F2BCDE
                //#EED9EF
                //#ECE8F7
                response.matrix.forEach((items)=>{

                    var fill = '';
                    if(response.matrixRangeColor.minMatrixData === items.heat){
                        fill = '#ECE8F7';
                    }else if (response.matrixRangeColor.minMatrixData > items.heat ) {
                        fill = '#EED9EF';
                    }else if (response.matrixRangeColor.firstMatrixData === items.heat &&  response.matrixRangeColor.secondMatrixData < items.heat) {
                        fill = '#F2BCDE';
                    }else if (response.matrixRangeColor.secondMatrixData === items.heat &&  response.matrixRangeColor.thirdMatrixData < items.heat) {
                        fill = '#F7A0CE';
                    }else if (response.matrixRangeColor.thirdMatrixData === items.heat &&  response.matrixRangeColor.fourthMatrixData < items.heat) {
                        fill = '#FB83BD';
                    }else if (response.matrixRangeColor.fourthMatrixData === items.heat &&  response.matrixRangeColor.fifthMatrixData < items.heat) {
                        fill = '#FF66AD';
                    }else if (response.matrixRangeColor.maxMatrixData === items.heat) {
                        fill = '#96FFFC';
                    }else{
                        fill = '#FF66AD';
                    }



                    matrixData.push({
                        x:items.x,
                        y:items.y,
                        heat:items.heat,
                        fill:fill
                    })
                })

                //console.log(matrixData)

                setMatrixArray(matrixData);

            } else {
                toast(
                    <div className="toastinner">
                        {response.message}
                        <a onClick={() => {
                            toast.dismiss();
                        }}>&times;</a>
                    </div>,
                    {
                        className: (response.success) ? "successtoast" : "errortoast",
                        position: "bottom-center",
                        duration: 2000,
                    }
                );
            }
        });
    };


    const getPlan = () => {

        if(!planType){
            let token = ReactSession.get("token");

            setLoading(true);

            getCurrentPlansService(token).then((response) => {
                setLoading(false);

                if (response.success) {
                    setPlanType(response.plan.type);
                } else {
                    toast(
                        <div className="toastinner">
                            {response.message}
                            <a onClick={() => {
                                toast.dismiss();
                            }}>&times;</a>
                        </div>,
                        {
                            className: (response.success) ? "successtoast" : "errortoast",
                            position: "bottom-center",
                            duration: 2000,
                        }
                    );
                }
            });
        }
    };




    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - card sorting";
        getTest();
        getPlan();


    }, []);

    const getSimilarityMatrix = ()=>{

        let token = ReactSession.get("token");

        setDataLoading(true);

        getTestCardSortingSimilarityMatrixResultData({ test_id: test_id },token).then((response) => {
            setDataLoading(false);

            console.log(response.category)
            if(response){

                setSimilarityMatrixArray(response.category);
            }


        });
    }


    const callbackTopbar = (data)=>{
        getTest();
        getTestafterEndTest();
    }


    var chart1_settings = {
        id: "Aera chart 1 ",
        width: "100%",
        background:'transparent',
        height: 600,
        type: 'heatMap',
        data: matrixArray,
        colors:{
            darken:"#ff0000"
        },
        darken:{
            fill:"#ff0000"
        },
        hovered:{
            fill:"#FF66AD"
        },
        xAxis:{
            // staggerMode:true,
            labels:{
                rotation:-45
            }
        }

        /*label: {
            text: '',
            width: "100%",
            height: "100%",
            fontSize: '45px',
            fontColor: "#fff",
            hAlign: 'center',
            vAlign: 'middle'
        },
        title: {
            text: '',
            fontColor: "#fff",
            fontWeight: 'bold'
        }*/
    };




    const changeType = (type)=>{

        setMetaData({...metaData,'active':type,cardSort:"",categorySort:""})

        //console.log(type)
        if(type === 'card'){

            if(cardArray.length === 0) {
                getCardSortingCards();
            }
        }else if(type === 'matrix'){

            if(matrixArray.length === 0) {
                getCardSortingMatrix();
            }
        }else if(type === 'similarity'){

            if(similarityMatrixArray.length === 0){
                getSimilarityMatrix();
            }
        }

    }

    const sortData = (type)=>{
        if(type === 'card') {
            if (metaData.cardSort === 'asc') {
                setCardArray(cardArray.sort((a, b) => (a.total_category < b.total_category) ? 1 : -1));
                setMetaData({...metaData, cardSort: "desc"});
            } else {
                setCardArray(cardArray.sort((a, b) => (a.total_category > b.total_category) ? 1 : -1));
                setMetaData({...metaData, cardSort: "asc"});
            }
        }else if(type === 'category') {

            if (metaData.categorySort === 'asc') {
                setCategoryArray(categoryArray.sort((a, b) => (a.total_cards < b.total_cards) ? 1 : -1));
                setMetaData({...metaData, categorySort: "desc"});
            } else {
                setCategoryArray(categoryArray.sort((a, b) => (a.total_cards > b.total_cards) ? 1 : -1));
                setMetaData({...metaData, categorySort: "asc"});
            }
        }


    }

    const runCommon = ()=>{
        
        var data = [];
        
        for (var i=0; i<=5; i++){
            //var datas = '<p>'+i+'</p>';
            //data.push(datas)
            for(var j=1; j<=i; j++){
               // echo "* ";
                data.push(i+'*'+j);
            }

            //data.push('<br/>');
            //echo "<br>";

        }
        return (
            console.log(data)
        );
    }

    const activeMatrixClass = (x,y)=>{
        document.getElementById(x).classList.add('active');
        document.getElementById(y).classList.add('active');
    }

    const  inactiveMatrixClass = (x,y)=>{

        document.getElementById(x).classList.remove('active');
        document.getElementById(y).classList.remove('active');
    }

    const downloadSVG = useCallback(() => {
      //  const svg = exportSimilarityRef.current.getElementsByTagName('div')[0].innerHTML;

        var svg = '<svg><foreignObject><body xmlns="http://www.w3.org/1999/xhtml">';
        svg +=  exportSimilarityRef.current.getElementsByTagName('div')[0].innerHTML;
        svg += '</body></foreignObject></svg>';

        const blob = new Blob([svg], { type: "image/svg+xml" });

        downloadBlob(blob, `similaritymatrix.svg`);
    }, []);



    return (
        <LayoutResearcherFullWidth fixed_header_target={true} isLoading={isLoading} wrapClass={"researcher-test-view-overflow overflow-visible"}  skipCheck={false} extendedFooter={false} activeMenu={""}>
            {isLoading &&
            <div className="page-min-height">
                <LoadingIcon />
            </div>
            }
            {!isLoading && test &&
            <div className="analyze-results-page-wrapper">


                <Topbar test={test} callbackTopbar={callbackTopbar} />

                <div className={`container inner-page-container ${(expandData || expandSimilarityData)?'matrix-full-view':''}`}>
                    <div className="sp5-content-wrap analyze-overflow-wrap">

                        <Sidebar test={test} planType={planType} hideSidebar={expandData || expandSimilarityData} activeMenu={location.pathname.split("/").pop()} />


                        <div className={`sp5-right-side-wrap card-sorting-anayl-data`}>
                            {sectionLoading &&
                            <LoadingIcon />
                            }
                            {!sectionLoading &&
                            <div className="bd-question-view-wrap">
                                <div className='bd-question-top-slide d-flex justify-content-between card-sorting-anayl-top-wrap'>
                                    <div className='bd-que-slide-left w-100 d-flex align-items-center justify-content-between'>
                                        <h3 className='h3 font-inter'>{test.card_sorting.card_sorting === "close" ? ' Closed card sorting':' Open card sorting'}</h3>


                                    </div>
                                </div>

                                <div className="bd-que-table-wrapper">
                                    <div className="bd-que-table-search-wrap mb-32">
                                        <div className="">

                                            <div className="bd-question-radio-data metrix-card-sort d-flex justify-content-between w-100 border-0 pl-0 ml-0">



                                                <div className="checkboxes radio_btn_class  d-flex">
                                                    {!expandData && !expandSimilarityData &&
                                                        <>
                                                        <Radiobtn
                                                            id={`category-select`}
                                                            isSelectedlabelBold={true}
                                                            name="bdq"
                                                            value="category"
                                                            checked={(metaData.active === "category") ? true : false}
                                                            onChange={(e) => {
                                                                changeType("category")
                                                            }}
                                                            label="By category"/>

                                                        < Radiobtn
                                                            id={`card-select`}
                                                            isSelectedlabelBold={true}
                                                            name="bdq"
                                                            value="card"
                                                            checked={(metaData.active === "card")?true:false}
                                                            onChange={(e)=>{changeType("card")}}
                                                            label="By card" />
                                                        </>
                                                    }

                                                    {/*{metaData.sorting_type === 'close' &&*/}
                                                    {/*<Radiobtn*/}
                                                    {/*    id={`matrix-select`}*/}
                                                    {/*    isSelectedlabelBold={true}*/}
                                                    {/*    name="bdq"*/}
                                                    {/*    value="matrix"*/}
                                                    {/*    checked={(metaData.active === "matrix") ? true : false}*/}
                                                    {/*    onChange={(e) => {*/}
                                                    {/*        changeType("matrix")*/}
                                                    {/*    }}*/}
                                                    {/*    label="By matrix"/>*/}
                                                    {/*}*/}
                                                </div>

                                                {expandSimilarityData &&
                                                <span className="small-dropdown">
                                                    <Dropdown>
                                                        <Dropdown.Toggle id={"dropdownMenuButton"}
                                                                         className="dropdown-toggle">
                                                            DOWNLOAD
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#"
                                                                           onClick={() => exportAsImage(exportSimilarityRef.current, "similarityMatrix")}>
                                                                Download PNG
                                                                <span><img
                                                                    src={process.env.REACT_APP_URL + "images/download.svg"}/></span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item href="#" onClick={() =>  downloadSVG(exportSimilarityRef.current)}>
                                                                Download  SVG
                                                                <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </span>
                                                }

                                                <div className="max-matrix-screen">

                                                    {(test.publish_date > '13-10') &&
                                                    <a href={`/researcher/analytics/${test_id}/card-sorting-similarity`}
                                                       target={'_blank'}>
                                                        Open similarity matrix
                                                        <img
                                                            src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}
                                                            alt="icon"/>
                                                    </a>
                                                    }
                                                    {/*<span>*/}
                                                    {/*    {!expandSimilarityData && (test.publish_date>'13-10') && !expandData &&*/}
                                                    {/*    <span onClick={()=>{*/}
                                                    {/*        setExpandSimilarityData(true)*/}
                                                    {/*        changeType("similarity")*/}
                                                    {/*    }}>*/}
                                                    {/*          Open similarity matrix*/}
                                                    {/*        <img*/}
                                                    {/*            src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}*/}
                                                    {/*            alt="icon"/>*/}
                                                    {/*         </span>*/}
                                                    {/*    }*/}
                                                    {/*    {expandSimilarityData &&*/}
                                                    {/*        <>*/}

                                                    {/*            <img onClick={()=>{*/}
                                                    {/*                setExpandSimilarityData(false)*/}
                                                    {/*                changeType("card")*/}
                                                    {/*            }}*/}
                                                    {/*                 src={process.env.REACT_APP_URL + "images/close-menu.svg"}*/}
                                                    {/*                 alt="icon"/>*/}
                                                    {/*         </>*/}
                                                    {/*    }*/}
                                                    {/*</span>*/}

                                                    {!expandSimilarityData && test.card_sorting.card_sorting === "close" &&
                                                    <a href={`/researcher/analytics/${test_id}/card-sorting-chart`} target={'_blank'}>
                                                        Open result grid
                                                            <img
                                                             src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}
                                                             alt="icon"/>

                                                        {/*{!expandData && !expandSimilarityData &&*/}
                                                        {/*  <span onClick={()=>{*/}
                                                        {/*      setExpandData(true)*/}
                                                        {/*      changeType("matrix")*/}
                                                        {/*  }}>*/}
                                                        {/*      Open result grid*/}
                                                        {/*    <img*/}
                                                        {/*     src={process.env.REACT_APP_URL + "images/maxim-icon.svg"}*/}
                                                        {/*     alt="icon"/>*/}
                                                        {/*     </span>*/}
                                                        {/*}*/}
                                                        {/*{expandData &&*/}

                                                        {/*  <>*/}
                                                        {/*  <span className="small-dropdown">*/}
                                                        {/*    <Dropdown>*/}
                                                        {/*        <Dropdown.Toggle id={"dropdownMenuButton"} className="dropdown-toggle">*/}
                                                        {/*            Download*/}
                                                        {/*        </Dropdown.Toggle>*/}
                                                        {/*        <Dropdown.Menu>*/}
                                                        {/*            <Dropdown.Item href="#"  onClick={() =>  exportAsImage(exportResultGridRef.current, "resultGrid")}>*/}
                                                        {/*                Download PNG*/}
                                                        {/*                <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>*/}
                                                        {/*            </Dropdown.Item>*/}
                                                        {/*            /!*<Dropdown.Item href="#" onClick={() =>  downloadSVG(exportResultGridRef.current)}>*!/*/}
                                                        {/*            /!*    Download  SVG*!/*/}
                                                        {/*            /!*    <span><img src={process.env.REACT_APP_URL+"images/download.svg"} /></span>*!/*/}
                                                        {/*            /!*</Dropdown.Item>*!/*/}
                                                        {/*        </Dropdown.Menu>*/}
                                                        {/*    </Dropdown>*/}
                                                        {/*</span>*/}
                                                        {/*<img onClick={()=>{*/}
                                                        {/*    setExpandData(false)*/}
                                                        {/*    changeType("card")*/}
                                                        {/*}}*/}
                                                        {/*     src={process.env.REACT_APP_URL + "images/close-menu.svg"}*/}
                                                        {/*     alt="icon"/>*/}

                                                        {/*     </>*/}
                                                        {/*}*/}
                                                    </a>
                                                    }


                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                    {dataLoading &&
                                        <LoadingIcon />
                                    }
                                    {!dataLoading &&
                                    <div className={`bd-que-ans-table-data-inner`}>
                                        {metaData.active !== 'matrix' && metaData.active !== 'similarity' &&
                                        <div className="table-responsive">
                                            <table>
                                                <thead>
                                                {(metaData.active === 'category') &&
                                                <tr className="card-sorting-category-view-tr">
                                                    <th className="bd-thcol-1 pl-0">Category</th>
                                                    <th className="bd-thcol-2">
                                                        <div
                                                            onClick={() => sortData('category')}
                                                            className={`${(metaData.categorySort) ? metaData.categorySort : ''} col-sort justify-content-between`}>
                                                            No. of cards
                                                            <span>
                                                                {metaData.categorySort ?
                                                                    <img src={
                                                                        process.env.REACT_APP_URL + "images/sort_enable.svg"
                                                                    } alt="icon" width={20}/>
                                                                    :
                                                                    <img src={
                                                                        process.env.REACT_APP_URL + "images/sort_disable.svg"
                                                                    } alt="icon" width={20}/>
                                                                }
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="bd-thcol-3">
                                                        Cards
                                                    </th>
                                                    <th className="bd-thcol-4">
                                                        Frequency
                                                    </th>
                                                </tr>
                                                }
                                                {(metaData.active === 'card') &&
                                                <tr className="card-buy-category-view-tr">
                                                    <th className='pl-0'>Cards</th>
                                                    <th>

                                                        <div
                                                            onClick={() => sortData('card')}
                                                            className={`${(metaData.cardSort) ? metaData.cardSort : ''} col-sort justify-content-between pr-3`}>
                                                            Sorted into
                                                            <span>
                                                                {metaData.cardSort ?
                                                                    <img src={
                                                                        process.env.REACT_APP_URL + "images/sort_enable.svg"
                                                                    } alt="icon" width={20}/>
                                                                    :
                                                                    <img src={
                                                                        process.env.REACT_APP_URL + "images/sort_disable.svg"
                                                                    } alt="icon" width={20}/>
                                                                }
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th>Category</th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody>
                                                {(metaData.active === 'category') && categoryArray.map((items, index) => {
                                                    return (

                                                        <tr key={index}>
                                                            <td className="bd-q-pat-col">
                                                                <div className='card-catname-view-td'>
                                                                    {items.is_custom === 0 &&
                                                                    <img
                                                                        src={process.env.REACT_APP_URL + "images/folder-opened.svg"}
                                                                        alt="icon"/>
                                                                    }
                                                                    {items.is_custom === 1 &&
                                                                    <img
                                                                        src={process.env.REACT_APP_URL + "images/folder-closed.svg"}
                                                                        alt="icon"/>
                                                                    }
                                                                    <span className={(test.language !== 'en')?'cairo-font':''}>{items.name}</span></div>
                                                            </td>
                                                            <td className="bd-q-ans-col">
                                                                {items.total_cards}
                                                            </td>
                                                            <td className="bd-time-col">
                                                                <div className='card-sorting-selected-cards-td-view'>
                                                                    {items.results.map((data, index) => {

                                                                        return (
                                                                            <b key={index}
                                                                               className={` ${(data.result && data.result.is_hidden) ? 'disabled-row' : ''} ${(test.language !== 'en')?'cairo-font':''}`}>
                                                                                {data.name}
                                                                            </b>
                                                                        );
                                                                    })}

                                                                    <b>{items.results.length === 0 ? '-' : ''}</b>
                                                                </div>
                                                            </td>
                                                            <td className="bd-q-date-col">
                                                                {items.results.map((data, index) => {

                                                                    return (
                                                                        <p key={index}>
                                                                            {data.frequency}
                                                                        </p>
                                                                    );
                                                                })}

                                                                {items.results.length === 0 ? 0 : ''}
                                                            </td>
                                                        </tr>
                                                    );

                                                })}

                                                {(metaData.active === 'card') && cardArray.map((items, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <b className={(test.language !== 'en')?'cairo-font':''}>{items.name}</b>
                                                            </td>
                                                            <td>
                                                                <div className='card-catname-td'>

                                                                    {items.total_category}
                                                                    {items.total_category > 1 ? ' categories' : ' category'}
                                                                </div>
                                                            </td>
                                                            <td>


                                                                {items.results.map((data, index) => {

                                                                    return (
                                                                        <div
                                                                            className={`card-catname-view-td ${(data.result && data.result.is_hidden) ? 'disabled-row' : ''}`}>
                                                                            {data.category.is_custom === 0 &&
                                                                            <img
                                                                                src={process.env.REACT_APP_URL + "images/folder-opened.svg"}
                                                                                alt="icon"/>
                                                                            }
                                                                            {data.category.is_custom === 1 &&
                                                                            <img
                                                                                src={process.env.REACT_APP_URL + "images/folder-closed.svg"}
                                                                                alt="icon"/>
                                                                            }
                                                                            <p key={index} className={(test.language !== 'en')?'cairo-font':''}>
                                                                                {data.category.name || ''}
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                })}

                                                                {items.results.length === 0 ? '-' : ''}

                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                                </tbody>
                                            </table>
                                        </div>
                                        }

                                        {/*<div className='card-sorting-matrix-view'>*/}
                                        {/*    <div className='matrix-view-cat-list'>*/}
                                        {/*        <div className='matrix-category-repeat'  tooltip="Lorem ipsum dolor sit amet... Lorem ipsum dolor sit amet... Lorem ipsum dolor sit amet...">*/}
                                        {/*            <img src={process.env.REACT_APP_URL + "images/folder-icon.svg"} alt="icon"/>*/}
                                        {/*            <span>Lorem ipsum dolor sit amet...</span>*/}
                                        {/*        </div>*/}

                                        {/*        <div className='matrix-category-repeat'  tooltip="Lorem ipsum dolor sit amet...">*/}
                                        {/*            <img src={process.env.REACT_APP_URL + "images/folder-icon.svg"} alt="icon"/>*/}
                                        {/*            <span>Lorem ipsum dolor sit amet...</span>*/}
                                        {/*        </div>*/}

                                        {/*        <div className='matrix-category-repeat'  tooltip="Lorem ipsum dolor sit amet...">*/}
                                        {/*            <img src={process.env.REACT_APP_URL + "images/folder-icon.svg"} alt="icon"/>*/}
                                        {/*            <span>Lorem ipsum dolor sit amet...</span>*/}
                                        {/*        </div>*/}

                                        {/*        <div className='matrix-category-repeat'  tooltip="Lorem ipsum dolor sit amet...">*/}
                                        {/*            <img src={process.env.REACT_APP_URL + "images/folder-icon.svg"} alt="icon"/>*/}
                                        {/*            <span>Lorem ipsum dolor sit amet...</span>*/}
                                        {/*        </div>*/}

                                        {/*        <div className='matrix-category-repeat'  tooltip="Lorem ipsum dolor sit amet...">*/}
                                        {/*            <img src={process.env.REACT_APP_URL + "images/folder-icon.svg"} alt="icon"/>*/}
                                        {/*            <span>Lorem ipsum dolor sit amet...</span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {metaData.active === 'matrix' &&

                                        <div ref={exportResultGridRef}>
                                        <AnyChart
                                            {...chart1_settings}
                                            // type="heatMap"
                                            // data={matrixArray}
                                            // /* data={[ {x: "2010", y: "A", heat: 15},
                                            //      {x: "2011", y: "A", heat: 17},
                                            //      {x: "2012", y: "A", heat: 21},
                                            //      {x: "2010", y: "B", heat: 34},
                                            //      {x: "2011", y: "B", heat: 33},
                                            //      {x: "2012", y: "B", heat: 32},
                                            //      {x: "2010", y: "C", heat: 51},
                                            //      {x: "2011", y: "C", heat: 0},
                                            //      {x: "2012", y: "C", heat: 47}]}*/
                                            // //width={800}
                                            // height={200}
                                            // colors={["#00ccff", "#ffcc00"]}
                                            //title="Simple pie chart"
                                        />
                                        </div>
                                        }

                                        {metaData.active === 'similarity' &&

                                        <div className={`matricx-table-wrap ${(test.language !== 'en')?'cairo-font':''}`} ref={exportSimilarityRef}>
                                            <div className="table-responsive">
                                                <table style={{marginLeft:'20px'}}>
                                                    <tbody>

                                                    {similarityMatrixArray.map((datas, indexs) => {

                                                        return (
                                                            <>

                                                            {(indexs === 0) &&

                                                                <tr>
                                                                    <td id={datas[0].cardyId} className={`card-name-td ${(test.language !== 'en')?'cairo-font':''}`}
                                                                        colSpan="5">{datas[0].cardy}</td>
                                                                </tr>
                                                            }

                                                            <tr>
                                                                {datas.map((data,index)=>{
                                                                    return (
                                                                        <>
                                                                        <td tooltip={
                                                                            `${data.cardy} / ${data.cardx} - grouped together by ${data.group_cards_percent}% of participants
                                                                             (${data.group_cards} times)`
                                                                        } flow="down"
                                                                            onMouseOver={()=>activeMatrixClass(data.cardxId,data.cardyId)}
                                                                            onMouseOut={()=>inactiveMatrixClass(data.cardxId,data.cardyId)}
                                                                        >
                                                                            <span
                                                                                className={(test.language !== 'en')?'cairo-font':''}
                                                                                style={{backgroundColor:data.colorCode,width:'40px',height:'40px',display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    borderRadius: '3px'
                                                                                }}>
                                                                                {data.group_cards_percent}
                                                                            </span>
                                                                        </td>

                                                                        {(indexs === index) &&
                                                                            <td id={data.cardxId} className={`card-name-td ${(test.language !== 'en')?'cairo-font':''}`}
                                                                                colSpan={data.col}>{data.cardx}</td>
                                                                        }
                                                                        </>
                                                                    );
                                                                })}
                                                            </tr>
                                                            </>
                                                        );
                                                    })}
                                                    {/*<tr>*/}
                                                    {/*    <td tooltip="120 testers" flow="down"><span>80</span></td>*/}
                                                    {/*    <td tooltip="120 testers" flow="down"><span>20</span></td>*/}
                                                    {/*    <td className="card-name-td" colSpan="2">test card name</td>*/}
                                                    {/*</tr>*/}
                                                    {/*<tr>*/}
                                                    {/*    <td tooltip="120 testers" flow="down"><span>80</span></td>*/}
                                                    {/*    <td tooltip="120 testers" flow="down"><span>20</span></td>*/}
                                                    {/*    <td tooltip="120 testers" flow="down"><span>10</span></td>*/}
                                                    {/*    <td className="card-name-td">test card name</td>*/}
                                                    {/*</tr>*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                    }

                                </div>



                            </div>
                            }

                        </div>


                    </div>
                </div>

            </div>
            }
        </LayoutResearcherFullWidth>
    )
}