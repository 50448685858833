//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {reportIssueAnswer} from "../../../../services/researcher_test_results";
import InputField from "../../../layouts/elements/fields/input";
import {maxLengthCheck} from "../../../../lib/helpers";


export default function ReportAnswer({openModal,closeModal,test_id}) {
    const navigate = useNavigate();

    const [isReportAnswerLoading, setReportAnswerLoading] = useState(false);
    const [formValues, setFormValues] = useState({title:''});
    const [formErrors, setFormErrors] = useState({error_msg:'',error_class:''});

    useEffect(function(){
        setFormValues({title:''})
    },[openModal])
    const reportIssueHandler = ()=>{

        setFormErrors({error_msg:'',error_class:''});

        if(formValues.title === ''){
            setFormErrors({'error_msg':'Required field','error_class':"input_error"});
            return false;
        }
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id",test_id);

        if(openModal.type=="methodology"){
            data.append("result_id",openModal.answer_id);
        } else {
            data.append("answer_id",openModal.answer_id);
        }
        data.append("comment",formValues.title);

        setReportAnswerLoading(true);
        reportIssueAnswer(data, token).then((response) => {
            setReportAnswerLoading(false);

            if (response.success) {
                closeModal(true);
            }

            toast(
                <div className="toastinner">
                    {response.message}
                    <a onClick={() => {
                        toast.dismiss();
                    }}>&times;</a>
                </div>,
                {
                    className: (response.success) ? "successtoast" : "errortoast",
                    position: "bottom-center",
                    duration: 2000,
                }
            );
        });
    }
    return (
        <Modal
            show={openModal.active}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal report-modal-wrap"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64 text-center">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <h2 className="h2">Report an issue</h2>
                    <p className="w-100">Let us know what the issue is below</p>

                        <InputField id={"What would you like to report?"} label={"What would you like to report?"}  error={formErrors.error_msg} error_class={formErrors.error_class}  value={formValues.title} onChange={(e)=>setFormValues({title:e.target.value})} required={true} />


                    <div className="button-wrap d-flex justify-content-between mt-32 btn-50-wrap flex-nowrap">
                        <button type="button" onClick={() => {
                            closeModal(false);
                        }} className="button secondary-btn">Cancel</button>
                        <button type="button" onClick={(e)=>reportIssueHandler()}
                                className={`button  primary-btn`}>
                            Report issue
                            {isReportAnswerLoading && (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                ></i>
                            )}
                        </button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}