import { Link, useNavigate } from "react-router-dom";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import React, { useEffect, useState } from "react";
import LayoutResearcher from "../layouts/layout_researcher";
import { getProjects, deleteProject } from "../../services/project.js";
import { getCurrentPlansService } from "../../services/plans";
import { updateWelcomeMessage } from "../../services/user.js";
import NewProjectModal from "../researcher/projects/new_project_modal";
import NewTestModal from "../researcher/tests/new_test_modal";

import { Dropdown } from "react-bootstrap";
import ConfirmModal from "../dialog/confirm_modal";
import SubscriptionCompleteModal from "../researcher/billing/subscription_complete_modal";
import toast from "react-hot-toast";
import ProjectSettingsModal from './../researcher/projects/project_settings_modal';
import { FormattedMessage } from "react-intl";
import { settings } from "../../data/settings";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function ResearcherDashboard() {
    ReactSession.setStoreType("localStorage");

    const navigate = useNavigate();

    const [show_subscribed, setShowSubscribed] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [planType, setPlanType] = useState("FREE");

    const [projects, setProjects] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [settingsModal, setSettingsModal] = useState({
        open: false,
        target_id: null,
    });
    const [openNewTestModal, setOpenNewTestModal] = useState(false);
    const [modalMethodology, setModalMethodology] = useState("");

    const [confirmModal, setConfirmModal] = useState({
        open: false,
        target_id: null,
    });

    const [newAccountToast, setNewAccountToast] = useState(true);

    const abortController = new AbortController();

    const getPlan = () => {
        let token = ReactSession.get("token");

        //setLoading(true);

        getCurrentPlansService(token).then((response) => {


            if (response.success) {
                setPlanType(response.plan.type);
            } else {
                //   setErrorMessage(response.setErrorMessage);
            }

           // setLoading(false);
        });
    };

    const getProjectsApi = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getProjects(token, abortController.signal)
            .then((response) => {


                if (response.success) {
                    setProjects(response.projects);
                }
                setLoading(false);
            })
            .catch(function (err) {
                console.error(` Err: ${err}`);
            });
    };
    const deleteProjectApi = (project_id) => {
        let token = ReactSession.get("token");

        setLoading(true);

        deleteProject({ project_id: project_id }, token)
            .then((response) => {


                if (response.success) {
                    getProjectsApi();
                }

                setLoading(false);
            })
            .catch(function (err) {
                console.error(` Err: ${err}`);
            });
    };
    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Dashboard";

        const user = ReactSession.get("user");

        let toast_message = user.welcome_message_shown == 1 ? false : true;

        /*if (ReactSession.get("show_subscribed") == 1) {
            setShowSubscribed(1);
        }*/
        if (toast_message) {
            setShowSubscribed(1);
        }

        if (!toast_message) {
            setNewAccountToast(false);
        } else {
            hideToast(null);
            // toast(
            //     <div className="toastinner">
            //         {
            //             <div className="pro-lft-wrap">
            //                 <FormattedMessage id="Welcome to <a>user<b>Q</b></a>, your account has been created"
            //                     values={{
            //                         a: chunks => <span>{chunks}</span>,
            //                         b: chunks => <b className="pink-color">{chunks}</b>,
            //                     }}
            //                 />
            //             </div>
            //         }
            //         <a
            //             onClick={() => {
            //                 toast.dismiss();
            //             }}
            //         >
            //             &times;
            //         </a>
            //     </div>,
            //     {
            //         className: "successtoast",
            //         position: "bottom-center",
            //         duration: 3000,
            //     }
            // );
        }

        getProjectsApi();
        getPlan();
        return () => {
            abortController.abort(); // cancel pending fetch request on component unmount
        };
    }, []);

    const renderProjects = () => {

        var ProjectData = (isLoading) ? [1, 2, 3, 4, 5, 6] : projects;
        return renderProjectHtmlWithLoading(ProjectData)
    };

    const renderProjectHtmlWithLoading = (projectData) => {
        var render = [];
        projectData.forEach(function (project) {

            if (!isLoading) {
                var draft = project.tests.filter(function (item) {
                    return item.status == "draft";
                });
                var draftcount = draft.length;
                var completed = project.tests.filter(function (item) {
                    return item.status == "completed";
                });
                var completedcount = completed.length;
                var published = project.tests.filter(function (item) {
                    return item.status == "published";
                });
                var publishedcount = published.length;
            }
            var repeat = (
                <div key={"projectdash"+project.id+Math.random()} className={`dash-projects-repeat project-dashboard-data-repeat ${isLoading ? 'tester-dashboard-skelton-loading' : ''}`}>
                    <span className="pro-menu-icon small-dropdown">
                        {!isLoading &&
                            <Dropdown>
                                <Dropdown.Toggle
                                    id={"dropdownMenuButton" + project.id}
                                    className="dropdown-toggle"
                                >
                                    <img
                                        src={process.env.REACT_APP_URL + "images/menu-dotts.svg"}
                                        alt="img"
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="drop-down-link-hold">

                                        <Dropdown.Item
                                            onClick={() => {
                                                setSettingsModal({
                                                    open: true,
                                                    target_id: project.id,
                                                });
                                            }}
                                        >
                                            Edit
                                            <span>
                                                <img
                                                    src={
                                                        process.env.REACT_APP_URL + "images/edit-icon.svg"
                                                    }
                                                    alt="img"
                                                />
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                setConfirmModal({
                                                    open: true,
                                                    confirm_btn_title: "Delete",
                                                    confirm_title: "Delete project",
                                                    confirm_message:
                                                        " Are you sure you want to delete this project?",
                                                    target_id: project.id,
                                                });
                                            }}
                                        >
                                            {" "}
                                            Delete
                                            <span>
                                                <img
                                                    src={
                                                        process.env.REACT_APP_URL +
                                                        "images/trash-black.svg"
                                                    }
                                                    alt="img"
                                                />
                                            </span>
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </span>

                    <div className="dash-project-img">
                        {!isLoading ?
                            <Link to={"/researcher/project/" + project.id}>
                                {project.image && (
                                    <img
                                        src={process.env.REACT_APP_IMG_URL + project.image}
                                        className="project-selected-image"
                                    />
                                )}
                                {!project.image && (
                                    <img
                                        src={process.env.REACT_APP_URL + "images/project-img.png"}
                                        className="project-selected-image"
                                    />
                                )}
                            </Link>
                            :
                            <Skeleton
                                circle
                                height="80px"
                                width="80px"
                                containerClassName="avatar-skeleton" />
                        }
                    </div>

                    <div className="dash-project-content">
                        <div className="das-pro-head">
                            <h3 className="h3">
                                {!isLoading ?
                                    <Link to={"/researcher/project/" + project.id}>
                                        {project.project_name}
                                    </Link>
                                    :
                                    <Skeleton width={150} />
                                }
                            </h3>


                        </div>

                        <div className="project-member-count">
                            <div className="project-member-images">
                                <span><img src={process.env.REACT_APP_URL + "images/user-place-icon.png"} alt="img" /></span>
                            </div>
                            <p>
                                {!isLoading ?
                                    <>
                                        Collaborators<b>{project.collaborators.length}</b>
                                    </>
                                    :
                                    <Skeleton width={50} />
                                }
                            </p>
                        </div>


                        <h4 className="tests-in-project-text">Tests in this project</h4>

                        <div className="dash-pro-status-count">
                            <div className="dp-stc-repeat">
                                <p>
                                    {!isLoading ?
                                        "In progress"
                                        :
                                        <Skeleton width={50} />
                                    }
                                </p>
                                <h3 className="h3">
                                    {!isLoading ?
                                        publishedcount
                                        :
                                        <Skeleton width={50} />
                                    }
                                </h3>
                            </div>
                            <div className="dp-stc-repeat">
                                <p>
                                    {!isLoading ?
                                        'Ended'
                                        :
                                        <Skeleton width={50} />
                                    }
                                </p>
                                <h3 className="h3">
                                    {!isLoading ?
                                        completedcount
                                        :
                                        <Skeleton width={50} />
                                    }
                                </h3>
                            </div>
                            <div className="dp-stc-repeat">
                                <p>
                                    {!isLoading ?
                                        'Drafts'
                                        :
                                        <Skeleton width={50} />
                                    }
                                </p>
                                <h3 className="h3">
                                    {!isLoading ?
                                        draftcount
                                        :
                                        <Skeleton width={50} />
                                    }
                                </h3>
                            </div>
                        </div>

                        <div className="dash-pro-link">


                            {!isLoading ?
                                <Link to={"/researcher/project/" + project.id}>
                                    <span className="project-link">
                                        GO TO PROJECT{" "}
                                        <img
                                            src={process.env.REACT_APP_URL + "images/link-fill.svg"}
                                            alt="img"
                                        />
                                    </span>
                                </Link>
                                :
                                <Skeleton width={50} />
                            }
                        </div>
                    </div>
                </div>
            );
            render.push(repeat);
        });

        return render;
    }
    const closeModal = (reload) => {
        setOpenModal(false);

        if (reload) {
            getProjectsApi();
        }
    };

    const closeSettingsModal = (reload) => {
        setSettingsModal({
            open: false,
            target_id: null,
        });

        if (reload) {
            getProjectsApi();
        }
    };
    const closeNewTestModal = (reload) => {
        setOpenNewTestModal(false);

        if (reload) {
            getProjectsApi();
        }
    };
    const hideToast = (e) => {
        let user = ReactSession.get("user");

        user.welcome_message_shown = 1;
        ReactSession.set("user", user);
        updateWelcomeMessage(ReactSession.get("token")).then((response) => {
        });
    };

    const showTestUnavailabily = () => {
        toast(
            <div className="toastinner">
                {"Test Not Available"}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 2000,
            }
        );
    };

    return (
        <LayoutResearcher
            isLoading={false}
            skipCheck={false}
            activeMenu={"dashboard"}
        >

            <div className="dashboard-data-wrapper">
                <div className="dashboard-top-wrapper">
                    <div className="dash-top-left">
                        <h1 className="page-main-sm-heading">Your dashboard</h1>
                    </div>

                    <div className="dashboard-top-right">
                        <button
                            id="r_new_project"
                            onClick={() => {
                                setOpenModal(true);
                            }}
                            className="btn form-btn"
                        >
                            NEW PROJECT
                        </button>

                        {/*<button
                            onClick={() => {
                                if (projects.length > 0) {
                                    setOpenNewTestModal(true);
                                }
                            }}
                            className={`btn form-btn secondary-btn ${projects.length == 0 ? 'disabled-button' : ''} `}
                        >
                            NEW TEST
                        </button>*/}
                    </div>
                </div>

                <div className="dashboard-inner-data-wrapper project-dashboard-data">
                    {/*{isLoading && (*/}
                    {/*    <div className="project-listing-loader loader_section">*/}
                    {/*        Loading...*/}
                    {/*    </div>*/}
                    {/*)}*/}


                    {isLoading ?
                        <>
                            <h2 className="h2"><Skeleton width={'30%'} /></h2>
                            <div className="project-das-data">{renderProjects()}</div>
                        </>
                        :
                        <>
                            {projects && projects.length > 0 ?
                                <>
                                    <h2 className="h2">Your projects</h2>
                                    <div className="project-das-data">{renderProjects()}</div>
                                </>
                                :
                                <>
                                    <h2 className="h2">Create a new project to start</h2>
                                    <p className="page-small-info-text">
                                        Projects help you organise your tests into folders.{" "}
                                        <button className={"new-data-btn"} onClick={() => {setOpenModal(true);}}>Create a new project</button>{" "}now.
                                    </p>
                                    {/*
                                            <div className="dashboard-cards-wrapper">
                                                <div className="row">
                                                    <div className="col-md-3"
                                                         onClick={() => {
                                                             setOpenNewTestModal(true);
                                                             setModalMethodology("Card Sorting");
                                                         }}>
                                                        <div className="dash-cards-wrap flex-wrap">
                                                            <div className="cards-data">
                                                                <h3>CARD SORTING</h3>
                                                                <p>
                                                                    Find out how users think content should be
                                                                    organised and labelled to create a validated
                                                                    information architecture.
                                                                </p>

                                                            </div>
                                                            <div className="dash-create-test-btn-wrap">
                                                                <p className="card-credit-info-wrap">
                                                                    <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                    />
                                                                    {settings.publishing_credits} credits/publish
                                                                </p>

                                                                <button className="create-btn">
                                                                    Create{" "}
                                                                    <span>
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/arrow-right.png"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3"
                                                         onClick={() => {
                                                             setOpenNewTestModal(true);
                                                             setModalMethodology("Preference Test");
                                                         }}>
                                                        <div className="dash-cards-wrap dash-card-2 flex-wrap">
                                                            <div className="cards-data">
                                                                <h3>PREFERENCE TEST</h3>
                                                                <p>
                                                                    Measure impact by having users evaluate different
                                                                    designs to understand which ones perform better.
                                                                </p>
                                                            </div>

                                                            <div className="dash-create-test-btn-wrap">
                                                                <p className="card-credit-info-wrap">
                                                                    <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                    />
                                                                    {settings.publishing_credits} credits/publish
                                                                </p>

                                                                <button className="create-btn">
                                                                    Create{" "}
                                                                    <span>
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/arrow-right.png"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="col-md-3"
                                                        onClick={() => {
                                                            setOpenNewTestModal(true);
                                                            setModalMethodology("Tree Test");
                                                        }}
                                                    >
                                                        <div className="dash-cards-wrap dash-card-3 flex-wrap">
                                                            <div className="cards-data">
                                                                <h3>TREE TEST</h3>
                                                                <p>
                                                                    Pinpoint exactly where your users are getting lost
                                                                    within your existing “tree” of information &
                                                                    hierarchical structure.
                                                                </p>

                                                            </div>
                                                            <div className="dash-create-test-btn-wrap">
                                                                <p className="card-credit-info-wrap">
                                                                    <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                    />
                                                                    {settings.publishing_credits} credits/publish
                                                                </p>

                                                                <button className="create-btn">
                                                                    Create{" "}
                                                                    <span>
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/arrow-right.png"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3"
                                                         onClick={() => {
                                                             setOpenNewTestModal(true);
                                                             setModalMethodology("Survey");
                                                         }}>
                                                        <div className="dash-cards-wrap dash-card-4 flex-wrap">
                                                            <div className="cards-data">
                                                                <h3>SURVEY</h3>
                                                                <p>
                                                                    Get your target customers to answer your burning
                                                                    questions and acquire precise insights and data
                                                                    points.
                                                                </p>

                                                            </div>

                                                            <div className="dash-create-test-btn-wrap">
                                                                <p className="card-credit-info-wrap">
                                                                    <img src={process.env.REACT_APP_URL + "images/coin.svg"}
                                                                    />
                                                                    {settings.publishing_credits} credits/publish
                                                                </p>

                                                                <button className="create-btn">
                                                                    Create{" "}
                                                                    <span>
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/arrow-right.png"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                                */}
                                </>

                            }
                        </>

                    }



                </div>
            </div>

            <NewProjectModal
                openModal={openModal}
                closeModal={closeModal}
                planType={planType}
            />
            {settingsModal.open && (
                <ProjectSettingsModal
                    openModal={settingsModal.open}
                    closeModal={closeSettingsModal}
                    planType={planType}
                    project_id={settingsModal.target_id}
                />
            )}
            {openNewTestModal && (
                <NewTestModal
                    openModal={openNewTestModal}
                    closeModal={closeNewTestModal}
                    projectlist={projects}
                    openProjectModal={() => {
                        setOpenModal(true);
                    }}
                    selectedMethodology={modalMethodology}
                />
            )}
            <ConfirmModal
                confirm_message={confirmModal.confirm_message}
                confirm_btn_title={confirmModal.confirm_btn_title}
                confirm_title={confirmModal.confirm_title}
                openModal={confirmModal.open}
                close={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                }}
                confirm={() => {
                    setConfirmModal({ ...confirmModal, open: false });
                    deleteProjectApi(confirmModal.target_id);
                }}
            />
            <SubscriptionCompleteModal
                openModal={show_subscribed}
                closeAction={() => {
                    setShowSubscribed(false);
                    ReactSession.set("show_subscribed", 0);
                    ReactSession.set("selected_plan", null);
                    ReactSession.set("plan_id", null);
                }}
                secAction={() => {
                    navigate("/researcher/credits/buy-credits");
                    ReactSession.set("show_subscribed", 0);
                    ReactSession.set("selected_plan", null);
                    ReactSession.set("plan_id", null);
                }}
                primaryAction={() => {
                    setShowSubscribed(false);
                    ReactSession.set("show_subscribed", 0);
                    ReactSession.set("selected_plan", null);
                    ReactSession.set("plan_id", null);
                }}
            />
        </LayoutResearcher>
    );
}
