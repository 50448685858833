import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../lib/secure_reactsession";
import NdaModal from "./dialog/nda_modal";

export default function Introduction({test,result,isNda,introductionCallback,timeSpent}) {

    const [openNdaModal, setOpenNdaModal] = useState(false );
    const [checkNda, setCheckNda] = useState(false );
    const closeNdaModal = (data) => {
        setOpenNdaModal(false);
        if(data){
            setCheckNda(true);
        }
    };

    useEffect(()=>{
        // check if user has not accepted nda then user will show form
        if(result && (result.is_nda === 0)){
            setOpenNdaModal(true);
        }
    },[isNda]);


    return (
        <div className="tester-page-wrapper tester-welcome-page-wrap">
            <div className="container inner-page-container">
                <div className="taking-test-page-height">
                <div className="tester-screens-hold">
                    <div className="tester-selected-page-wrap">
                        <div className="selected-page-right-side">
                            <div className="tester-page-selected-img-wrap">
                                <img src={
                                    (test && test.image)?
                                        process.env.REACT_APP_IMG_URL+''+test.image   :
                                        process.env.REACT_APP_URL+"images/welcome-placeholder.png"
                                }/>
                            </div>
                        </div>

                        <div className="selected-page-left-side">
                            <h1>
                                {
                                (test && test.language === 'en')?
                                    test && test.welcome_title :
                                    test && test.welcome_title_arabic
                                }
                            </h1>
                            <p>
                                {
                                (test && test.language === 'en')?
                                    test && test.welcome_description :
                                    test && test.welcome_description_arabic
                                }
                            <br/><br/>
                                {/*Click the button below to get started!*/}
                            </p>
                            <div className="button-wrapper none-for-mobile">
                                <button className="button primary-btn ml-0" onClick={()=>introductionCallback(checkNda)}>
                                {test.language=="en" &&
                                <>Let’s Start</>
                                }
                                {test.language=="ar" &&
                                <>لنبدأ</>
                                }
                                </button>
                            </div>
                        </div>

                    </div>
                    </div>
                    
                </div>
                <div className="button-wrapper none-for-desktop taking-test-button">
                    <button className="button primary-btn ml-0" onClick={()=>introductionCallback(checkNda)}>
                        {test.language=="en" &&
                        <>Let’s Start</>
                        }
                        {test.language=="ar" &&
                        <>لنبدأ</>
                        }
                    </button>
                </div>
            </div>
            <NdaModal
                timeSpent={timeSpent}
                result={result}
                openModal={openNdaModal}
                closeModal={closeNdaModal}
                testLanguage={test.language} />
        </div>
    );
}
