import { useNavigate } from 'react-router-dom';
//import { ReactSession } from 'react-client-session';
import { ReactSession } from '../../lib/secure_reactsession.js';
import React, { useEffect, useState, useRef } from "react";
import LayoutResearcher from '../layouts/layout_researcher.js';
import { getProfile, deleteAccount, updateResearcherProfile, sendChangeEmailToUser } from "../../services/user.js";
import { Dropdown } from 'reactjs-dropdown-component';
import toast from 'react-hot-toast';
import ResearcherProfileNavigation from './researcher_profile_navigation.js';
import ChangePasswordModal from './reset_password_modal';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'
import { PhoneNumberUtil } from 'google-libphonenumber';

import ConfirmModal from '../dialog/confirm_modal.js';
import { LoadingIcon } from "../loader/loadingIcon";

export default function ProfileResearcher() {

    ReactSession.setStoreType("localStorage");

    const [confirm, setConfirm] = useState({ open: false });

    let jobRolesSelect = useRef();

    const jobRoleOptions = [{ "label": "UX/UI designer", "value": "UX/UI designer" }, { "label": "UX researcher", "value": "UX researcher" }, { "label": "Product owner", "value": "Product owner" }, { "label": "Product manager", "value": "Product manager" }, { "label": "Social media manager", "value": "Social media manager" }, { "label": "Marketing manager", "value": "Marketing manager" }, { "label": "Entrepreneur", "value": "Entrepreneur" }, { "label": "Other...", "value": "Other..." }];

    const [jobRoleLabelClass, setJobRoleLabelClass] = useState("inactive_label");

    const [formLoading, setFormLoading] = useState(false);

    const [profile_loaded, setProfileLoaded] = useState(false);

    const [formValues, setFormValues] = useState({ phone_number:"", dialcode_phone:"", country_phone
    :"", "first_name": "", "last_name": "", "company_name": "", "job_role": "" });

    const [formErrors, setFormErrors] = useState({phone_no:null, first_name: null, last_name: null, company_name: null, job_role: null, "error_class": null });

    const [successMsg, setSuccessMessage] = useState(ReactSession.get("successVerifiedMessage"));

    const [isLoading, setLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [liveTestCount, setLiveTestCount] = useState(0);

    const [accountLoading, setAccountLoading] = useState(false);

    const [errorMsg, setErrorMessage] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [changeEmailModal, setChangeEmailModal] = useState({ open: false });

    let user = ReactSession.get("user");



    const navigate = useNavigate();

    useEffect(() => {

        document.title = process.env.REACT_APP_NAME + " - Edit Profile";

        let token = ReactSession.get("token");

        setLoading(true);

        getProfile(token).then(response => {
            setLoading(false);

            

            if (response.success) {

                setUserProfile(response.data.user)

                setLiveTestCount(response.data.user.live_test_count);
                var other = false;
                if (response.data.user.job_role != null && response.data.user.job_role != '') {

                    var found = false;
                    for (var i = 0; i < jobRoleOptions.length; i++) {
                        if (jobRoleOptions[i].label == response.data.user.job_role) {
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        jobRolesSelect.current.selectSingleItem({ value: response.data.user.job_role });
                        setJobRoleLabelClass('active_label');
                    } else {
                        jobRolesSelect.current.selectSingleItem({ value: "Other..." });
                        setJobRoleLabelClass('active_label');
                        other = true;
                    }
                }

                setFormValues({
                    "first_name": response.data.user.first_name,
                    "last_name": response.data.user.last_name,
                    "company_name": response.data.user.company_name,
                    "job_role": other ? "Other..." : response.data.user.job_role,
                    "job_role_other": other ? response.data.user.job_role : '',
                    "dialcode_phone": response.data.user.dialcode_phone==null ? '' : response.data.user.dialcode_phone,
                    "phone_number": response.data.user.phone_number==null ? '' : response.data.user.phone_number,
                    "country_phone": response.data.user.country_phone==null ? '' :  response.data.user.country_phone,
                })
                setProfileLoaded(true);


                window.setLabels();
                window.animate();


            }
        });
    }, []);

    const deleteUserAccount = (event) => {
        setAccountLoading(false);


        if (!accountLoading) {

            setAccountLoading(true);

            const token = ReactSession.get("token");

            deleteAccount(token)
                .then(response => {
                    setAccountLoading(false);

                    if (response.success) {

                        ReactSession.set("user", {});

                        ReactSession.set("token", null);

                        toast(
                            <div className="toastinner">
                                Account successfully deleted
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );

                        navigate("/researcher/login");

                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                        // setErrorMessage(response.message);
                    }
                })

        }
    }

    const sendChangeEmail = (event) => {
        setAccountLoading(false);
        if (!accountLoading) {

            setAccountLoading(true);

            const token = ReactSession.get("token");

            sendChangeEmailToUser(token)
                .then(response => {
                    setAccountLoading(false);

                    if (response.success) {

                        setChangeEmailModal({ ...changeEmailModal, open: false });

                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "successtoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );

                        // ReactSession.set("user",{});

                        // ReactSession.set("token",null);

                        // navigate("/researcher/login");

                    } else {
                        toast(
                            <div className="toastinner">
                                {response.message}
                                <a
                                    onClick={() => {
                                        toast.dismiss();
                                    }}
                                >
                                    &times;
                                </a>
                            </div>,
                            {
                                className: "errortoast",
                                position: "bottom-center",
                                duration: 3000,
                            }
                        );
                        // setErrorMessage(response.message);
                    }
                })

        }
    }
    const closeModal = (reload) => {
        setOpenModal(false);
    }
    const resetFormErrors = () => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };
    const onPhoneNumberChange = name => {
        return (status, phone_number, country) => {

            if (name == "phone_number") {

                setFormValues(oldValues => ({ ...oldValues, [name]: phone_number, ['dialcode_phone']: country.dialCode, ['country_phone']: country.iso2 }));
            } else {
                setFormValues(oldValues => ({ ...oldValues, [name]: phone_number, ['dialcode_whatsapp']: country.dialCode, ['country_whatsapp']: country.iso2 }));
            }
            setFormErrors((oldValues) => ({ ...oldValues, phone_no: null }));

        }
    };
    const onFlagChange = name => {
        return (status, country) => {

            setFormValues(oldValues => ({ ...oldValues, ['dialcode_phone']: country.dialCode, ['country_phone']: country.iso2 }));

        }
    };
    const validateFullForm = () => {
        var error = false;

        var form_errors = resetFormErrors();
        var firsterrorid = null;

        if (formValues.first_name == '') {

            form_errors = {
                ...form_errors,
                first_name: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "first_name" : firsterrorid;

        }
        if (formValues.last_name == '') {

            form_errors = {
                ...form_errors,
                last_name: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "last_name" : firsterrorid;
        }
        if (formValues.company_name == '') {

            form_errors = {
                ...form_errors,
                company_name: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "company-name" : firsterrorid;
        }
        if (formValues.job_role == '') {

            form_errors = {
                ...form_errors,
                job_role: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "job_role" : firsterrorid;
        }
        if (formValues.job_role == 'Other...' && formValues.job_role_other == '') {

            form_errors = {
                ...form_errors,
                job_role_other: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "job_role_other" : firsterrorid;
        }
        if (formValues.phone_number == '') {
            form_errors = {
                ...form_errors,
                phone_no: "Required field",
                error_class: "input_error"
            };

            error = true;

        } else if (formValues.phone_number != '') {

            let valid = false;
            try {

                const phoneUtil = PhoneNumberUtil.getInstance();
                valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + formValues.dialcode_phone + formValues.phone_number));

            } catch (e) {

                valid = false;

                error = true;
            }
            if (!valid) {
                form_errors = {
                    ...form_errors,
                    phone_no: "Phone no is not valid",
                    error_class: "input_error"
                };
                error = true;
            }
        }
        if (formValues.email == '') {

            form_errors = {
                ...form_errors,
                email: "Required field",
                error_class: "input_error"
            };
            error = true;
            firsterrorid = firsterrorid == null ? "email" : firsterrorid;
        }
        setFormErrors(form_errors);

        if (firsterrorid != null) {
            document.getElementById(firsterrorid).scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        return error;
    };
    const onSubmit = async (event) => {
        event.preventDefault();

        setFormErrors({ first_name: null, last_name: null, company_name: null, job_role: null, job_role_other: null, password: null, email: null, terms: false, password_error_class: null, "error_class": null });

        setErrorMessage(null);

        if (!formLoading) {

            var error = validateFullForm();


            if (!error) {
                setFormLoading(true);

                const token = ReactSession.get("token");

                let body = Object.assign({}, formValues);

                if (body['job_role'] == "Other...") {
                    body['job_role'] = body['job_role_other'];
                }

                updateResearcherProfile(body, token)
                    .then(response => {
                        setFormLoading(false);

                        if (response.success) {

                            let user = ReactSession.get("user");

                            user.first_name = response.user.first_name;

                            user.last_name = response.user.last_name;

                            ReactSession.set("user", user);

                            toast((
                                <div className='toastinner'>
                                    {response.message}
                                    <a onClick={() => { toast.dismiss(); }}>&times;</a>
                                </div>), {
                                className: 'successtoast',
                                position: 'bottom-center',
                                duration: 3000
                            });



                            // setSuccessMessage(response.message);

                            setUsername(user.first_name + " " + user.last_name)


                        } else {

                            toast((
                                <div className='toastinner'>
                                    {response.message}
                                    <a onClick={() => { toast.dismiss(); }}>&times;</a>
                                </div>), {
                                className: 'errortoast',
                                position: 'bottom-center',
                                duration: 3000
                            });
                            //setErrorMessage(response.message);
                        }
                    })
            }
        }
        return false;
    }
    const set = name => {
        return ({ target: { value } }) => {
            setFormValues(oldValues => ({ ...oldValues, [name]: value }));
            setFormErrors((oldValues) => ({ ...oldValues, [name]: null }));
        }
    };
    const clearFormValue = name => {
        return ({ target: { value } }) => {

            setTimeout(function () {
                setFormValues(oldValues => ({ ...oldValues, [name]: '' }));
            }, 1000)

        }
    };
    const clearErrorMessage = () => {
        setErrorMessage(null);
    }
    const jobRoleChange = (item) => {
        jobRolesSelect.current.selectSingleItem({ value: item.value });
        setFormValue("job_role", item.value);
        setFormErrors((oldValues) => ({ ...oldValues, job_role: null }));
        setJobRoleLabelClass('active_label')
        window.setLabels()
    }
    const setFormValue = (name, value) => {
        setFormValues(oldValues => ({ ...oldValues, [name]: value }));



    };
    const clearSuccessMessage = () => {
        setSuccessMessage(null);
    }

    return (
        <LayoutResearcher
            isLoading={false}
            wrapClass="rs-inner-profile-wrapper"
            username={username}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={"profile"}
        >
            <ResearcherProfileNavigation isLoading={false} activeMenu="profile" />



            <div className="profile-info-section">
                <div className="profile-info-left w-100">
                    <div className="profile-info-text">
                        <h1 className="page-main-heading">Your Profile</h1>
                        <p>
                            Keep your profile details up to date for the best UserQ
                            experience.
                        </p>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <LoadingIcon />
            ) : (
                <>
                    <div className="profile-form-wrap">
                        <form onSubmit={onSubmit}>
                            <div className="profile-form-repeat pb-0">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="profile-form-group">
                                                    <div
                                                        className="input form-group  input-field"
                                                        style={
                                                            formValues.first_name
                                                                ? { "--clear-scale": 1, "--clear-opacity": 1 }
                                                                : {}
                                                        }
                                                    >
                                                        <input
                                                            type="text"
                                                            className={
                                                                formErrors.first_name != null
                                                                    ? "form-control " + formErrors.error_class
                                                                    : "form-control"
                                                            }
                                                            id="first_name"
                                                            name="first_name"
                                                            value={formValues.first_name}
                                                            onChange={set("first_name")}
                                                        />
                                                        <label
                                                            htmlFor="first_name"
                                                            className="control-label"
                                                        >
                                                            First name
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue("first_name")}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path className="line" d="M2 2L22 22" />
                                                                <path className="long" d="M9 15L20 4" />
                                                                <path className="arrow" d="M13 11V7" />
                                                                <path className="arrow" d="M17 11H13" />
                                                            </svg>
                                                        </span>
                                                        {formErrors.first_name != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.first_name}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="profile-form-group">
                                                    <div
                                                        className="input form-group input-field"
                                                        style={
                                                            formValues.last_name
                                                                ? { "--clear-scale": 1, "--clear-opacity": 1 }
                                                                : {}
                                                        }
                                                    >
                                                        <input
                                                            type="text"
                                                            id="last_name"
                                                            className={
                                                                formErrors.last_name != null
                                                                    ? "form-control " + formErrors.error_class
                                                                    : "form-control"
                                                            }
                                                            name="last_name"
                                                            value={formValues.last_name}
                                                            onChange={set("last_name")}
                                                        />
                                                        <label
                                                            htmlFor="last_name"
                                                            className="control-label"
                                                        >
                                                            Last name
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue("last_name")}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path className="line" d="M2 2L22 22" />
                                                                <path className="long" d="M9 15L20 4" />
                                                                <path className="arrow" d="M13 11V7" />
                                                                <path className="arrow" d="M17 11H13" />
                                                            </svg>
                                                        </span>
                                                        {formErrors.last_name != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.last_name}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="profile-form-group ">
                                                    <div className="input input-field form-group read-only-field mb-0 change-email-option">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={user.email}
                                                            readOnly
                                                            id="email"
                                                        />
                                                        <label htmlFor="email" className="control-label">
                                                            Your Email
                                                        </label>
                                                        {/* <div className="data-field-icon">
                                <img src="images/fill-check.svg" alt="icon" />
                              </div> */}
                                                        <div className="data-field-icon" onClick={() => { setChangeEmailModal({ ...changeEmailModal, open: true }); }}>
                                                            {/* <img src="images/fill-check.svg" alt="icon" /> */}
                                                            Change
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="profile-form-group">
                                                    <div className="input-field static-label" id='job_role'>
                                                        <label
                                                            className={jobRoleLabelClass}
                                                            htmlFor="job_role"
                                                        >
                                                            Job role
                                                        </label>
                                                        <div
                                                            className={
                                                                formErrors.job_role != null
                                                                    ? formErrors.error_class
                                                                    : ""
                                                            }
                                                        >
                                                            <Dropdown
                                                                name="job_role"
                                                                title="Job Role"
                                                                list={jobRoleOptions}
                                                                onChange={jobRoleChange}
                                                                ref={jobRolesSelect}
                                                                styles={{
                                                                    headerArrowUpIcon: { backgroundImage: `url("${process.env.REACT_APP_URL}/images/up-chevron.png")`, backgroundSize: '24px', width: '24px', height: '24px' },
                                                                    headerArrowDownIcon: { backgroundImage: `url("${process.env.REACT_APP_URL}/images/down-chevron.png")`, backgroundSize: '24px', width: '24px', height: '24px' }
                                                                }}
                                                            />
                                                        </div>
                                                        {formErrors.job_role != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.job_role}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {formValues.job_role == "Other..." && (
                                                <div className="col-md-6">
                                                    <div className="profile-form-group">
                                                        <div className="input input-field form-group" >
                                                            <input
                                                                type="text"
                                                                id="job_role_other"
                                                                className={
                                                                    formErrors.job_role_other != null
                                                                        ? "form-control " + formErrors.error_class
                                                                        : "form-control"
                                                                }
                                                                name="job_role_other"
                                                                value={formValues.job_role_other}
                                                                onChange={set("job_role_other")}
                                                            />
                                                            <label
                                                                htmlFor="job_role_other"
                                                                className="control-label"
                                                            >
                                                                Job Role Other{" "}
                                                                <img
                                                                    src={
                                                                        process.env.REACT_APP_URL +
                                                                        "images/star-field.svg"
                                                                    }
                                                                    className="required-field-icon"
                                                                    alt="required-field"
                                                                />
                                                            </label>
                                                            <span
                                                                className="clear"
                                                                onClick={clearFormValue("job_role_other")}
                                                            >
                                                                <svg viewBox="0 0 24 24">
                                                                    <path className="line" d="M2 2L22 22" />
                                                                    <path className="long" d="M9 15L20 4" />
                                                                    <path className="arrow" d="M13 11V7" />
                                                                    <path className="arrow" d="M17 11H13" />
                                                                </svg>
                                                            </span>
                                                            {formErrors.job_role_other != null && (
                                                                <span className={formErrors.error_class}>
                                                                    {formErrors.job_role_other}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-6">
                                                <div className="profile-form-group">
                                                    <div
                                                        className={`input form-group input-field ${userProfile && userProfile.role === 'Collaborator' && formValues.company_name ? "read-only-field mb-0" : ""} `}
                                                        style={
                                                            formValues.company_name
                                                                ? { "--clear-scale": 1, "--clear-opacity": 1 }
                                                                : {}
                                                        }
                                                    >
                                                        <input
                                                            type="text"
                                                            id="company-name"
                                                            className={
                                                                formErrors.company_name != null
                                                                    ? "form-control " + formErrors.error_class
                                                                    : "form-control"
                                                            }
                                                            readOnly={userProfile && userProfile.role === 'Collaborator' && formValues.company_name && 'readonly'}
                                                            value={formValues.company_name}
                                                            onChange={set("company_name")}
                                                        />
                                                        <label
                                                            htmlFor="company-name"
                                                            className="control-label"
                                                        >
                                                            Company name
                                                        </label>
                                                        <span
                                                            className="clear"
                                                            onClick={clearFormValue("company_name")}
                                                        >
                                                            <svg viewBox="0 0 24 24">
                                                                <path className="line" d="M2 2L22 22" />
                                                                <path className="long" d="M9 15L20 4" />
                                                                <path className="arrow" d="M13 11V7" />
                                                                <path className="arrow" d="M17 11H13" />
                                                            </svg>
                                                        </span>
                                                        {formErrors.company_name != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.company_name}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="profile-form-group">
                                                    <div className="input-field static-label multi-field-wrap">
                                                        <label htmlFor="phone">Your phone number</label>
                                                        {profile_loaded &&
                                                        <IntlTelInput
                                                            containerClassName="intl-tel-input"
                                                            onPhoneNumberChange={onPhoneNumberChange(
                                                                "phone_number"
                                                            )}
                                                            defaultValue={formValues.phone_number}
                                                            defaultCountry={formValues.country_phone}
                                                            onSelectFlag={onFlagChange("phone_number")}
                                                            inputClassName={
                                                                formErrors.phone_no != null
                                                                    ? "form-control " + formErrors.error_class
                                                                    : "form-control "
                                                            }
                                                            format={"true"}
                                                            preferredCountries={["ae", "sa"]}
                                                        />
}
                                                        {formErrors.phone_no != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.phone_no}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="inner-form-btn res-profle-btn-right mt-64 profile-buttons-wrapper">
                                                    <a
                                                        className="btn form-btn secondary-btn change-password-btn"
                                                        onClick={() => {
                                                            setOpenModal(true);
                                                        }}
                                                    >
                                                        Change password
                                                    </a>
                                                    {user.role == "Collaborator" && (
                                                        <button type="submit" className="btn form-btn">
                                                            Save Updates
                                                            {formLoading && (
                                                                <i
                                                                    className="fa fa-spinner fa-spin"
                                                                    aria-hidden="true"
                                                                    style={{ marginLeft: "5px" }}
                                                                ></i>
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="researcher-profile-form-btn">
                                                    {user.role == "Researcher" && (
                                                        <>
                                                            <div
                                                                className="delete-res-profile"
                                                                onClick={() => {
                                                                    setConfirm({ open: true });
                                                                }}
                                                            >
                                                                <span className="delete-account-btn">
                                                                    Delete Account
                                                                    {accountLoading && (
                                                                        <i
                                                                            className="fa fa-spinner fa-spin"
                                                                            aria-hidden="true"
                                                                            style={{ marginLeft: "5px" }}
                                                                        ></i>
                                                                    )}
                                                                    {!accountLoading && (
                                                                        // <img src="images/trash.svg" alt="trash-icon" onClick={deleteUserAccount} />
                                                                        <img
                                                                            src="images/trash.svg"
                                                                            alt="trash-icon"
                                                                        />
                                                                    )}
                                                                </span>
                                                            </div>

                                                            <div className="inner-form-btn res-profle-btn-right">
                                                                {/*<button className="btn form-btn secondary-btn"  onClick={(e) => { e.preventDefault(); navigate(-1); } }>Cancel</button>*/}
                                                                <button
                                                                    type="submit"
                                                                    className="btn form-btn"
                                                                >
                                                                    Save Updates
                                                                    {formLoading && (
                                                                        <i
                                                                            className="fa fa-spinner fa-spin"
                                                                            aria-hidden="true"
                                                                            style={{ marginLeft: "5px" }}
                                                                        ></i>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <ChangePasswordModal
                            openModal={openModal}
                            closeModal={closeModal}
                        />
                        <ConfirmModal
                            openModal={confirm.open}
                            cancel_btn_title={"Cancel"}
                            confirm_title="Delete account"
                            confirm_message={
                                liveTestCount > 0
                                    ? "We cannot delete your account while you have live tests running. Please close all tests and try again."
                                    : "Are you sure you want to delete your account? All projects, collaborator accounts and unused credits will be removed. This action cannot be undone."
                            }
                            close={() => {
                                setConfirm({ ...confirm, open: false });
                            }}
                            confirm={() => {
                                setConfirm({ ...confirm, open: false });
                                deleteUserAccount();
                            }}
                        />

                        <ConfirmModal
                            openModal={changeEmailModal.open}
                            confirm_btn_title={"Confirm"}
                            confirm_title="Are you sure?"
                            confirm_message="An email will be sent to current email address for you to verify."
                            close={() => {
                                setChangeEmailModal({ ...changeEmailModal, open: false });
                            }}
                            confirm={() => {
                                sendChangeEmail();
                            }}
                        />
                    </div>
                </>
            )}
        </LayoutResearcher>
    );
}