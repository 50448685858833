import { Dropdown } from "react-bootstrap";
import PublishModal from "../publishmodal/publish_modal_with_end_condition";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import InputField from "../../../layouts/elements/fields/input.js";
import ConfirmModal from "../../../dialog/confirm_modal.js";
import { renameTestService, deleteTestService, publishTestService } from "../../../../services/test.js";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession.js";
import toast from 'react-hot-toast';
import RenameModal from "../../../dialog/rename_model.js";
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { encryptId } from "../../../../lib/helpers.js";
import {roundNumber} from "../../../../lib/helpers";

export default function Topbar(props) {
	const navigate = useNavigate();
	const location = useLocation();

	let user = ReactSession.get("user");
	const [publish, setPublish] = useState({ open: false });

	const [confirm, setConfirm] = useState({ open: false });

	const [rename, setRename] = useState({ open: false, isLoading: false });

	const inputref = useRef(null);

	const [isLoading, setLoading] = useState(false);

	const [formValues, setFormValues] = useState({ "title": "" });

	const [title, setTitle] = useState("");

	const [formErrors, setFormErrors] = useState({ "title": null, "error_class": "" });

	const [isEditable, setEditable] = useState(false);

	useEffect(() => {

		if (props.test) {
			if (!isEditable) {
				setFormValues({ 'title': props.test.test_name });
			}
		}

	}, [props.test]);

	useEffect(() => {

		var locationArr = location.pathname.split("/");

        console.log(props);

											
		if(ReactSession.get("open_publish_modal")=="1" && locationArr[locationArr.length-1]=="publish"){
			setPublish({open:true});
			//
		}

	}, []);

	useEffect(() => {
		setTitle(props.title);
	}, [props.title]);

	useEffect(() => {

		if (isEditable) {
			if (inputref.current) {
				inputref.current.focus()
			}
		}
	}, [isEditable]);


	const renameTest = (title) => {


		let token = ReactSession.get("token");

		setRename({ ...rename, isLoading: true });

		renameTestService({ test_id: props.test.id, title: title }, token).then((response) => {
			setLoading(false);

			if (response.success) {

				setRename({ ...rename, open: false, isLoading: false });

				if (props.onChange) {
					props.test.test_name = title;
					props.onChange(props.test);
				}
				setTitle(title)
			} else {

				toast((
					<div className='toastinner'>
						{response.message}
						<a onClick={() => { toast.dismiss(); }}>&times;</a>
					</div>), {
					className: 'errortoast',
					position: 'bottom-center'
				});
			}
		});

	}
	const deleteTest = () => {

		let token = ReactSession.get("token");

		setLoading(true);

		deleteTestService({ test_id: props.test.id }, token).then((response) => {
			setLoading(false);

			if (response.success) {

				toast((
					<div className='toastinner'>
						{response.message}
						<a onClick={() => { toast.dismiss(); }}>&times;</a>
					</div>), {
					className: 'successtoast',
					position: 'bottom-center'
				});
				navigate("/researcher/project/" + props.test.project_id);

			} else {

				toast((
					<div className='toastinner'>
						{response.message}
						<a onClick={() => { toast.dismiss(); }}>&times;</a>
					</div>), {
					className: 'errortoast',
					position: 'bottom-center'
				});
			}
		});
	}
	const publishTest = () => {
		if (props.onPublish) {

			props.onPublish();
		} else {

			let token = ReactSession.get("token");

			setLoading(true);

			publishTestService({ test_id: props.test.id, validate: false }, token).then((response) => {
				setLoading(false);

				if (response.success) {

					if (props.test.status == "draft") {
						navigate("/researcher/tests/" + props.test.id + "/live");
					} else {
						props.onChange();
						toast((
							<div className='toastinner'>
								<><FormattedMessage id="Your test has been published successfully." /></>
								<a onClick={() => { toast.dismiss(); }}>&times;</a>
							</div>), {
							className: 'successtoast',
							position: 'bottom-center'
						});
					}


				} else {

					toast((
						<div className='toastinner'>
							{response.message}
							<a onClick={() => { toast.dismiss(); }}>&times;</a>
						</div>), {
						className: 'errortoast',
						position: 'bottom-center'
					});
				}
			});
		}
	}

	return (
		<>
			<div className="profile-info-section">
				<div className="target-back-option-data">
					<div className="backto-list-wrap">


						{!props.pageLoading ?

							<a href="#" onClick={(e) => {
								e.preventDefault();
								(!props.pageLoading ? navigate("/researcher/project/" + props.test.project_id) : navigate(-1))
							}}>
								<img
									src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
									alt="img"
								/>{" "}
								BACK TO PROJECT
							</a>
							:
							<a href="#">
								<img
									src={process.env.REACT_APP_URL + "images/back-arrow.svg"}
									alt="img"
								/>{" "}
								BACK TO PROJECT
							</a>
						}

					</div>
					<div className="profile-info-text">
						{!props.pageLoading &&
						<div className="target-group-heading-top studyint-top-flex-wrap">
							{!isEditable &&
								<>
									{!props.pageLoading &&
								<h1 
                                className="h1" 
                                tooltip={
                                    props.title
                                    ?
                                    (
                                    props.title.length>25?props.title:""
                                    )
                                    :
                                    ""
                                }>
								{props.title
                                    ?
                                    (
                                        props.title.length>25?props.title.substring(0, 24) + "...":props.title
                                    )
                                    :
                                    ""}

								</h1>
									}
									{props.pageLoading &&
								<h1 className="h1">
									<Skeleton width={100} height={52} />
								</h1>
									}
								</>
							}
							{isEditable &&
								<InputField onInput={(e) => { if (e.target.value.length > 0) { setFormErrors({ ...formErrors, 'title': null, 'error_class': '' }) } }} inputref={inputref} error_class={formErrors.error_class} error={formErrors.title} label={"Title"} value={formValues.title} onChange={(e) => { setFormValues({ ...formValues, title: e.target.value }) }} />
							}
							{(isEditable) &&
								<div className="study-draft-and-save-wrap">
									{!isLoading &&
										<i onClick={() => { renameTest(); }} className="fa fa-check"></i>
									}
									{isLoading &&
										<p><i
											className="fa fa-spinner fa-spin"
											aria-hidden="true"
											style={{ marginLeft: "5px" }}
										></i></p>
									}
								</div>
							}
							{(!isEditable && isLoading) &&
								<div className="study-draft-and-save-wrap">
									<p><i
										className="fa fa-spinner fa-spin"
										aria-hidden="true"
										style={{ marginLeft: "5px" }}
									></i></p>
								</div>
							}

							{!isEditable && !isLoading &&

								<div className="study-draft-and-save-wrap">
									{!props.pageLoading &&
									<span className="green-btn">{props.status ? props.status : "Draft"}</span>
									}
									{props.pageLoading &&
									
									<Skeleton width={50} height={30} />
									
									}
									
									{props.test && props.test.status == "published" && (
										<p>
											<i className="fa fa-exclamation-triangle"></i>
											Autosave will not work in live mode
										</p>
									)}
									{(props.isLoading) &&

										<p><i
											className="fa fa-spinner fa-spin"
											aria-hidden="true"
											style={{ marginLeft: "5px" }}
										></i></p>
									}
									{!props.pageLoading && props.test && !props.isLoading && props.lastSaved &&
										
											
									
											<p>
												<i className="fa fa-check"></i> Saved {moment(props.lastSaved).fromNow(true)}  ago
											</p>
											
											
										
										
										
									}
									{props.pageLoading &&
									<p>
										<Skeleton width={20} height="20" style={{marginLeft:"20px"}} /> <Skeleton width={100} height="30" style={{marginLeft:"10px"}} />
									</p>
									}
								</div>
							}
						</div>
						}
						{(props.pageLoading) &&
						<div className="target-group-heading-top studyint-top-flex-wrap">
							<Skeleton width={"300px"} height={45}  style={{"marginRight":"30px"}} />
						</div>
						}
						<div className="target-group-btns-top top-wdropd create-test-process-btns">
							
							<>
							<div className="top-buttons-wdropd auto-width-button">
								
								<a
                                    id="r_create_preview"
									onClick={(e) => { if (props.stepCallback) { props.stepCallback(); } }}
									href={process.env.REACT_APP_URL + "p/" + encryptId(props.test.id, props.test)} target="_blank" className="btn form-btn secondary-btn">
									Preview
								</a>

								{/*<button
                                    id="r_create_publish"
									onClick={() => {

										if (props.enablePublish || props.test.saved_step==5) {

                                            if(props.stepCallback){
                                                props.stepCallback();
                                            }
											
											var locationArr = location.pathname.split("/");

											if(locationArr[locationArr.length-1]=="publish"){
												if (props.test.status == "draft") {

													setPublish({ open: true });
												} else {
													publishTest();
												}
											} else {
												navigate("/researcher/tests/"+props.test.id+"/publish");
												
												ReactSession.set("open_publish_modal","1");
											}
										} else {
											toast((
												<div className='toastinner'>
													<><FormattedMessage id="Please complete all steps before publishing the test" /></>
													<a onClick={() => { toast.dismiss(); }}>&times;</a>
												</div>), {
												className: 'errortoast',
												position: 'bottom-center',
												duration: 3000,
											});
										}


									}}
									type="button"
									className={`btn form-btn ${props.enablePublish || props.test.saved_step==5 ? '' : 'disabled-button'}`}
								>
									Publish
									{ parseInt(props.credits_required) > 0 &&
										<>
											{" "}
											for
											{" "}
											{roundNumber(props.credits_required,2)}
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
												<path d="M10 0.5C5.03 0.5 0 2.046 0 5V9C0 11.954 5.03 13.5 10 13.5C14.97 13.5 20 11.954 20 9V5C20 2.046 14.97 0.5 10 0.5ZM2 9V7.803C2.63334 8.16168 3.30399 8.45006 4 8.663V10.544C2.688 10.03 2 9.418 2 9ZM14 9.148V11.119C13.133 11.298 12.133 11.429 11 11.477V9.477C12.0064 9.43693 13.0088 9.327 14 9.148ZM9 11.478C7.99201 11.4394 6.98877 11.3197 6 11.12V9.149C6.959 9.323 7.972 9.436 9 9.479V11.478ZM16 10.544V8.663C16.6962 8.45049 17.3669 8.16209 18 7.803V9C18 9.418 17.313 10.03 16 10.544ZM10 7.5C4.823 7.5 2 5.849 2 5C2 4.151 4.823 2.5 10 2.5C15.177 2.5 18 4.151 18 5C18 5.849 15.177 7.5 10 7.5Z" fill="#171637" />
											</svg>
										</>
									}
								</button>*/}
							</div>
							<span className="pro-menu-icon small-dropdown">
								<Dropdown>
									<Dropdown.Toggle
										id="dropdownMenuButton"
										className="dropdown-toggle"
									>
										<img
											src={
												process.env.REACT_APP_URL + "images/menu-bg-icon.svg"
											}
											alt="img"
										/>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<div className="drop-down-link-hold">
											<Dropdown.Item className="dropdown-item" onClick={() => { setRename({ open: true, isLoading: false }); }}>
												Rename
												<span>
													<img
														src={
															process.env.REACT_APP_URL +
															"images/edit-icon.svg"
														}
														alt="img"
													/>
												</span>
											</Dropdown.Item>
											<Dropdown.Item className="dropdown-item" onClick={() => { setConfirm({ open: true }) }}>
												Delete
												<span>
													<img
														src={
															process.env.REACT_APP_URL +
															"images/trash-black.svg"
														}
														alt="img"
													/>
												</span>
											</Dropdown.Item>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</span>
							</>
							
						</div>
					</div>
				</div>
			</div>
			<ConfirmModal
				openModal={confirm.open}
				confirm_message="Are you sure you want to delete this test?"
				confirm_title="Delete"
				confirm_btn_title="Delete"
				close={() => { setConfirm({ "open": false }) }}
				confirm={() => { setConfirm({ "open": false }); deleteTest(); }}
			/>

			<PublishModal
				test={props.test}
				open={publish.open}
				close={() => { setPublish({ "open": false }); ReactSession.set("open_publish_modal",0); }}
				confirm={() => { setPublish({ "open": false }); ReactSession.set("open_publish_modal",0); publishTest();  }}
				testmethodology={props.test.methodology}
			/>
			<RenameModal
				title={props.test.test_name}
				open={rename.open}
				heading={"Rename test"}
				close={() => { setRename({ "open": false, isLoading: false }) }}
				language={props.test.language}
				isLoading={rename.isLoading}
				confirm={(title) => renameTest(title)}
			/>
		</>
	);
}
