//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";
import toast from "react-hot-toast";
import {ExportTestResult} from "../../../../services/researcher_test_results";


export default function DownloadCsv({openModal,closeModal,test}) {
    const navigate = useNavigate();


    const [downloadTestLoading, setDownloadTestLoading] = useState(false);

    const downTestHandler = ()=>{
        const token = ReactSession.get("token");
        const data = new FormData();
        data.append("test_id", test.id);

        setDownloadTestLoading(true);
        ExportTestResult(data, token).then((response) => {
            setDownloadTestLoading(false);

            if (response.success) {
                closeModal(true);
                window.open(response.url, '_blank');
            }
            toast(
                <div className="toastinner">
                    {response.message}
                    <a onClick={() => {
                        toast.dismiss();
                    }}>&times;</a>
                </div>,
                {
                    className: (response.success) ? "successtoast" : "errortoast",
                    position: "bottom-center",
                    duration: 2000,
                }
            );
        });
    }

    return (
        <Modal
            show={openModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal copytest-modal-wrap"
        >
            <Modal.Body className="modal-lg p-0">
                <div className="modal-inner-text p-64 text-left">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>


                    <h2 className="h2">Download report</h2>
                    <p className="w-100">Are you sure you want to download report?</p>


                    <div className="button-wrap d-flex flex-nowrap btn-50-wrap mt-64">
                        <button type="button" onClick={() => {
                            closeModal(false);
                        }} className="button secondary-btn">close</button>
                        <button type="button" onClick={(e)=>downTestHandler(e)}
                                className={`button  primary-btn`}>
                            confirm
                            {downloadTestLoading && (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    aria-hidden="true"
                                    style={{ marginLeft: "5px" }}
                                ></i>
                            )}

                        </button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}