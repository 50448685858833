import { useD3 } from '../../../../hooks/useD3';
import React, {useState,useRef} from 'react';
import * as d3 from 'd3';

function BubbleChart({ data=[] , width, height}) {
    const [cheight, setHeight] = useState(height);
    const tooltipref = useRef();
    const [tooltip, showTooltip] = useState({
            tooltipLeft:0,
            tooltipTop:0,
            tooltipData:"",
            open: false
        });
    const handlePointerMove = (event) => {
        let containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left + 70 ;
        let containerY = ('clientY' in event ? event.clientY : 0)  - tooltipref.current.getBoundingClientRect().top +30;
        
        var tool_width = 246;

        var tool_height = 46;

        var window_width = window.innerWidth;

        var window_height = window.innerHeight;
    
        if(event.clientX+70+tool_width > window_width){
        // alert(event.clientX+tooltipref.current.getBoundingClientRect().width+30+tool_width )
            containerX = ('clientX' in event ? event.clientX : 0) - tooltipref.current.getBoundingClientRect().left - tool_width +20;
        }
        if(event.clientY+30+tool_height > window_height){
            //alert(window_width)
            containerY = ('clientX' in event ? event.clientY : 0) - tooltipref.current.getBoundingClientRect().top - tool_height -10;
        }
        //}
       
        showTooltip({
            tooltipLeft: containerX,
            tooltipTop: containerY,
            tooltipData: event.target.__data__.data.label+" "+(parseInt(event.target.__data__.value) > 1 ? event.target.__data__.value+" Participants" : event.target.__data__.value+" Participant"),
            open:true
        });
    }
    const ref = useD3(
    (svg) => {
      
        

        var diameter = ref.current.getBoundingClientRect().width;
        
        height = ref.current.getBoundingClientRect().width;

        setHeight(height);
        var bubble = d3.pack(data)
            .size([diameter, diameter])
            .padding(1.5);

        var svg = d3.select(".plot-area");

        var nodes = d3.hierarchy(data)
            .sum(function(d) { return d.value; });

        var max = 1;

        
        data.children.forEach(function(item){
            
            if(max < item.value){
                max = item.value;
            }
            
        });

        var color = (perc) => {
            return "rgba(255, 102, 173, "+(perc/10*0.1)+")";
        }

        var node = svg.selectAll(".node")
            .data(bubble(nodes).descendants())
            .enter()
            .filter(function(d){
                return  !d.children
            })
            .append("g")
            .attr("class", "node")
            .attr("transform", function(d) {
                return "translate(" + d.x + "," + d.y + ")";
            });

        /*node.append("title")
            .text(function(d) {
                
                return d.data.label + ": " + d.value;
            });*/

        node.append("circle")
            .attr("r", function(d) {
                return d.r;
            })
            .on("mousemove", function(d){
                handlePointerMove(d);
            })
            .on("mouseout", function(){
                showTooltip({...tooltip, open:false}) 
            })
            .style("fill", function(d) {
               
                return color((d.value/max)*100);
            });

            node.append("text")
                .attr("dy", ".2em")
                .style("text-anchor", "middle")
                .text(function(d) {
                    
                    return d.data.label.substring(0, d.r / 3);
                })
                .on("mousemove", function(d){
                    handlePointerMove(d);
                })
                .on("mouseout", function(){
                    showTooltip({...tooltip, open:false}) 
                })
                .attr("font-family", "Inter")
                .attr("font-size", function(d){
                    if((d.value/max)*14 < 9){
                        return 9;
                    } else {
                        return (d.value/max)*14;
                    }
                })
                .attr("font-weight", 600)
                .attr("fill", "rgb(23,22,55)");

            node.append("text")
                .attr("dy", "1.7em")
                .style("text-anchor", "middle")
                .text(function(d) {
                    return d.data.value;
                })
                .attr("font-family",  "Inter", "sans-serif")
                .attr("font-size", function(d){
                    if((d.value/max)*14 < 9){
                        return 9;
                    } else {
                        return (d.value/max)*14;
                    }
                    //
                    //return d.r/8;
                })
                .attr("font-weight", 300)
                .attr("fill", "rgb(23,22,55)");
        },
        [data.length]
    );

     return (
        <div ref={tooltipref}>
            {tooltip.open ? (
            <>
                <div
                    className={"tooltipChart"}
                    key={Math.random()} // needed for bounds to update correctly
                    style={{ zIndex:9999, position:"absolute",left:(tooltip.tooltipLeft)+"px",top:(tooltip.tooltipTop)+"px"}}
                >
                    {tooltip.tooltipData}
                </div>
            </>
            ): (
                <></>
            )}
        <svg
        ref={ref}
        style={{
            height: cheight,
            width: "100%",
            marginRight: "0px",
            marginLeft: "0px",
        }}
        >
        <g className="plot-area" />
        </svg>
        </div>
    );
}

export default BubbleChart;