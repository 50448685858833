//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import React, {useState} from "react";


export default function ExitTestModal({openExitTestModal,closeExitTestModal, test}) {

    return (
        <Modal
            show={openExitTestModal}
            centered
            size="lg"
            className="fade custom-modal-wrap mid-width-modal nda-agreement-modal test-exit-modal"
        >
            <Modal.Body className="modal-lg p-0">
                <div className={`modal-inner-text p-64 text-left ${test.language=="ar"? 'arabic_wrapper':''}` }>
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            closeExitTestModal(false);
                        }}
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                        <img
                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                            alt="cross"
                        />
                        </span>
                    </button>

                    <h2>{test.language=="en"?"Are you sure you want to exit the test?":"هل أنت متأكد أنك تريد الخروج من الاختبار؟"}</h2>
                    <p className="w-100">{test.language=="en"?"Remember – if you end the test at this stage, you won’t get paid for your insights. Return to the test to make sure you get rewarded for your time."
                    :"تذكر - إذا أنهيت الاختبار في هذه المرحلة ، فلن تحصل على أموال مقابل أفكارك. عد إلى الاختبار للتأكد من حصولك على مكافأة مقابل وقتك."}</p>

                    <div className="button-wrap d-flex justify-content-between">
                        <button type="button" onClick={() => {
                            closeExitTestModal(false);
                        }} className="button secondary-btn">{test.language=="en"?"cancel":"إلغاء"}</button>
                        <button type="button" onClick={()=>{
                            closeExitTestModal(true);
                        }} className="button primary-btn">{test.language=="en"?"Exit test":"إنهاء الاختبار"}</button>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    );
}