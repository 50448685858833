import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
//import { ReactSession } from "react-client-session";
import { ReactSession } from "../../../lib/secure_reactsession";
import LayoutResearcherFullWidth from "../../layouts/layout_researcher_full_width";
import Frame from "../study/frame/frame.js";
import Progress from "../study/progress/progress.js";
import Topbar from "../study/topbar/topbar.js";
import { getCurrentPlansService } from "../../../services/plans";
import { getTestData, changeTestLanguage } from "../../../services/test";
import { saveTest } from "../../../services/test";
import { maxLengthCheck, isFileImage, encryptId } from "../../../lib/helpers";
import toast from "react-hot-toast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Questions from "./introduction/questions";
import EditQuestion from "./introduction/editQuestion";
import moment from "moment";
import TextAreaField from "./../../layouts/elements/fields/textarea";
import redirectToStep from "./redirect_to_step";
import InputField from "./../../layouts/elements/fields/input";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import { LoadingIcon } from "../../loader/loadingIcon";
import { FullPageLoadingIcon } from "../../loader/full_page_loader_lcon";

export function getFileType(file) {

    if (file.type.match('image.*'))
        return 'image';

    // if(file.type.match('video.*'))
    //   return 'video';

    // if(file.type.match('audio.*'))
    //   return 'audio';



    return 'other';
}
export function matchExtensions(extns, file) {

    return extns.indexOf(file.name.split('.').pop()) != -1 ? true : false;
}

export default function Study() {
    ReactSession.setStoreType("localStorage");

    const [divHeight, setDivHeight] = useState(0);
    const [seeMore, setSeeMore] = useState(false);

    let { test_id } = useParams();
    const [test, setTest] = useState("");

    const navigate = useNavigate();

    const [lastSaved, setLastSaved] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [errorMsg, setErrorMessage] = useState(null);

    const [planType, setPlanType] = useState("FREE");

    const [image, setImage] = useState(null);
    const [language, setLanguage] = useState("en");
    const [title, setTitle] = useState("");
    const [isWelcome, setIsWelcome] = useState(1);
    const [isBriefingQuestions, setIsBriefingQuestions] = useState(0);
    const [introtitles, setIntrotitles] = useState({
        engTitle: "",
        engDes: "",
        arabicTitle: "",
        arabicDesc: "",
    });
    const [previewUrl, setPreviewUrl] = useState("");
    const [testImage, setTestImage] = useState(null);
    const formValueRef = useRef({ test_name: "", message: "" });
    const lang = useRef("en");
    const [formValues, setFormValues] = useState({
        test_name: "",
        message: "",
    });

    const [formErrors, setFormErrors] = useState({
        image: null,
        test_name: null,
        message: null,
        error_class: null,
    });

    const [questionType, setQuestionType] = useState("");
    const [briefingQuestions, setBriefingQuestions] = useState([]);

    const [addingBriefingQuestions, setAddingBriefingQuestions] = useState(false);
    const [edittingBriefingQuestions, setEdittingBriefingQuestions] =
        useState(false);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const [placeholderProps, setPlaceholderProps] = useState({});
    const [imageDeleted, setImageDeleted] = useState(false);

    const showError = (error) => {
        toast(
            <div className="toastinner">
                {error}
                <a
                    onClick={() => {
                        toast.dismiss();
                    }}
                >
                    &times;
                </a>
            </div>,
            {
                className: "errortoast",
                position: "bottom-center",
                duration: 3000,
            }
        );
    };

    function showmore(divID) {
        //document.getElementById(divID).style.height = '600px';
        document.getElementById(divID).classList.add("expendit");
    }

    function showless(divID) {
        document.getElementById(divID).classList.remove("expendit");
    }

    //useEffect(() => {
    //
    // //console.log('jkjkjkjkjkjkjk');
    //
    //
    //window.addEventListener('load', () => {
    //  console.log("page is loaded");
    //   var elHeight = document.getElementsByClassName('repaetbox1left').clientHeight;
    //    console.log(elHeight);
    //});
    //
    //  });target-group-data-repeat

    useEffect(() => {
        const timer = setTimeout(() => {
            var slides = document.getElementsByClassName("target-group-data-repeat");
            for (var i = 0; i < slides.length; i++) {
                var bbheight = 0;
                var ccheight = 0;
                var bb = slides
                    .item(i)
                    .getElementsByClassName("targer-group-left-wrap")[0];
                var cc = slides
                    .item(i)
                    .getElementsByClassName("target-group-info-repeat")[0];
                if (bb) {
                    bbheight = bb.offsetHeight;
                }
                if (cc) {
                    ccheight = cc.offsetHeight;
                }
                //console.log('>>'+bbheight+'<><>'+ccheight);
                if (bbheight < ccheight) {
                    cc.classList.add("lessshow");
                    cc.style.height = bbheight + "px";
                } else {
                    cc.classList.add("normalshow");
                }
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    // const handleFile = (file) => {
    //     //you can carry out any file validations here...
    //     if (!isFileImage(file)) {
    //         showFormError("image", "Please select a valid image");
    //         return;
    //     }

    //     setImage(file);
    //     setPreviewUrl(URL.createObjectURL(file));
    // };

    const resetFormErrors = () => {

        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        setFormErrors(formErrorsLocal);

        return formErrorsLocal;
    };

    const handleFile = (file) => {

        resetFormErrors();
        //let's grab the image file
        var error = false;

        var fileType = getFileType(file);

        if (fileType == "image" && file.size / 1024 > 2048) {

            setFormErrors({ ...formErrors, image: "Please select file <= 2MB.", "error_class": "input_error" });

            error = true;
        }
        if (!matchExtensions(["jpg", "jpeg", "gif", "png", "JPG", "JPEG", "GIF", "PNG"], file)) {


            setFormErrors({ ...formErrors, image: "Only image files are accepted with extensions  jpg, jpeg, gif and png.", "error_class": "input_error" });
            error = true;
        }

        if (fileType != "image") {

            setFormErrors({ ...formErrors, image: "Only image files are accepted.", "error_class": "input_error" });
            error = true;
        }

        if (!error) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }

    };

    const handleOnDragOver = (event) => {
        event.preventDefault();
    };
    const handleOnDrop = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file
        let imageFile = event.dataTransfer.files[0];

        handleFile(imageFile);
    };
    const removeImage = (e) => {
        setImage(null);
        setPreviewUrl(null);
        setTestImage(null);
        setImageDeleted(true);
    };
    const handleFileInput = (event) => {
        //prevent the browser from opening the image
        event.preventDefault();
        event.stopPropagation();
        //let's grab the image file

        let imageFile = event.target.files[0];
        handleFile(imageFile);
    };

    const showFormError = (name, value) => {
        let formErrorsLocal = Object.assign({}, formErrors);

        for (var key in formErrors) {
            formErrorsLocal[key] = null;
        }
        formErrorsLocal[name] = value;

        formErrorsLocal["error_class"] = "input_error";

        setFormErrors(formErrorsLocal);
    };

    const set = (name) => {
        return ({ target: { value } }) => {
            setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
            formValueRef.current = { ...formValueRef.current, [name]: value };
        };
    };

    const getPlan = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getCurrentPlansService(token).then((response) => {
            setLoading(false);

            if (response.success) {
                setPlanType(response.plan.type);
                response.plan.type == "FREE" && setIsBriefingQuestions(0);
            } else {
                showError(response.message);
            }
        });
    };
    const getTest = () => {
        let token = ReactSession.get("token");

        setLoading(true);

        getTestData({ test_id: test_id }, token).then((response) => {
            setLoading(false);

            if (response.success) {
                if (response.test.status) {
                    if (response.test.status == "published") {
                        navigate("/researcher/project/" + response.test.project_id);
                    }
                }

                ReactSession.set("methodology", response.test.methodology);

                redirectToStep({
                    test_id: test_id,
                    current_step: 1,
                    saved_step: response.test.saved_step,
                    navigate: navigate,
                    test: test,
                });

                setTest(response.test);
                lang.current = response.test.language;
                setLastSaved(moment(response.test.updated_at));
                const {
                    welcome_title,
                    welcome_description,
                    welcome_title_arabic,
                    welcome_description_arabic,
                    language,
                    is_welcome,
                    test_name,
                    image,
                    is_briefing_questions,
                } = response.test;

                let questions = [];
                response.test.briefingQuestion.forEach((question) => {
                    questions.push(JSON.parse(question));
                });
                if (questions.length < 1) {
                    setAddingBriefingQuestions(true);
                }

                setBriefingQuestions(questions);

                setFormValues((oldValues) => ({
                    ...oldValues,
                    test_name: welcome_title,
                    message: welcome_description,
                }));
                formValueRef.current = {
                    ...formValueRef.current,
                    test_name: welcome_title,
                    message: welcome_description,
                };
                setIntrotitles({
                    engTitle: welcome_title,
                    engDes: welcome_description,
                    arabicTitle: welcome_title_arabic,
                    arabicDesc: welcome_description_arabic,
                });
                setLanguage(language);
                setIsWelcome(is_welcome);
                setTitle(test_name);

                setTestImage(image);
                setIsBriefingQuestions(is_briefing_questions);
            } else {
                setErrorMessage(response.setErrorMessage);
            }
        });
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME + " - Introduction";
        getPlan();
        getTest();

        ReactSession.set("new_target_id", null);
    }, []);

    const saveData = () => {
        const token = ReactSession.get("token");

        const { test_name, message } = formValueRef.current;

        var data = new FormData();

        data.append("test_id", test_id);

        data.append("encrypted_test_id", encryptId(test_id));
        data.append("language", language);
        if (language == "en") {
            data.append("title", formValues.test_name);
            data.append("description", formValues.message);
        } else {
            data.append("title_arabic", formValues.test_name);
            data.append("description_arabic", formValues.message);
        }
        if (image) {
            data.append("image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        data.append("is_welcome", isWelcome);
        data.append("is_briefing_questions", isBriefingQuestions);
        briefingQuestions.forEach((item) => {
            data.append("briefing_questions[]", JSON.stringify(item));
        });

        saveTest(data, token).then((response) => {
            if (response.success) {
                setLastSaved(moment.now());
            } else {
                console.log(response);
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            //  This will run every 30 seconds
            if (test.status == "draft") {
                saveData();
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [
        isWelcome,
        image,
        language,
        formValues,
        isBriefingQuestions,
        briefingQuestions,
    ]);

    const briefingQuestionsHandler = () => {
        if (planType === "FREE") {
            toast(
                <div className="toastinner">
                    {
                        <FormattedMessage id="You are currently on a free plan. Upgrade your plan to add briefing questions." />
                    }
                    <a
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: 3000,
                }
            );
        } else {

            if(isBriefingQuestions){
                setQuestionType("");
                setEdittingBriefingQuestions(false);
                setEditQuestionId(null);
                setAddingBriefingQuestions(false);
            }

            isBriefingQuestions
                ? setIsBriefingQuestions(0)
                : setIsBriefingQuestions(1);
   
        }
    };

    const setForm = () => {
        if (language === "ar") {
            setFormValues((oldValues) => ({
                ...oldValues,
                test_name: introtitles.arabicTitle,
                message: introtitles.arabicDesc,
            }));
            formValueRef.current = {
                ...formValueRef.current,
                test_name: introtitles.arabicTitle,
                message: introtitles.arabicDesc,
            };
        } else {
            setFormValues((oldValues) => ({
                ...oldValues,
                test_name: introtitles.engTitle,
                message: introtitles.engDes,
            }));
            formValueRef.current = {
                ...formValueRef.current,
                test_name: introtitles.engTitle,
                message: introtitles.engDes,
            };
        }
    };
    useEffect(() => {
        setForm();
    }, [language]);

    const introductionsubmitHandler = (saveUsingNavigation) => {
        const token = ReactSession.get("token");
        setFormLoading(true);

        const { test_name, message } = formValueRef.current;

        var data = new FormData();

        data.append("test_id", test_id);
        data.append("language", language);
        data.append("encrypted_test_id", encryptId(test_id));
        if (language == "en") {
            data.append("title", formValues.test_name);
            data.append("description", formValues.message);
        } else {
            data.append("title_arabic", formValues.test_name);
            data.append("description_arabic", formValues.message);
        }
        if (image) {
            data.append("image", image);
        }
        if (imageDeleted) {
            data.append("imageDeleted", imageDeleted);
        }
        data.append("is_welcome", isWelcome);
        data.append("is_briefing_questions", isBriefingQuestions);
        if (!saveUsingNavigation) {
            data.append("saved_step", 1);
        }

        briefingQuestions.forEach((item) => {
            data.append("briefing_questions[]", JSON.stringify(item));
        });

        saveTest(data, token).then((response) => {
            setFormLoading(false);
            if (response.success) {
                if (!saveUsingNavigation) {
                    if (test.methodology == "Tree Test") {
                        navigate("/researcher/tests/" + test_id + "/tree-sort-test/");
                    } else if (test.methodology == "Survey") {
                        navigate("/researcher/tests/" + test_id + "/survey/");
                    } else if (test.methodology == "Preference Test") {
                        navigate("/researcher/tests/" + test_id + "/preference-test/");
                    } else if (test.methodology == "Card Sorting") {
                        navigate("/researcher/tests/" + test_id + "/card-sorting-test/");
                    } else if (test.methodology == "Five Seconds Test") {
                        navigate("/researcher/tests/" + test_id + "/five-seconds-test/");
                    }
                }
            } else {
                if(response.message=="The image must be an image."){
                setImage(null);
                setPreviewUrl("");
                setFormErrors({ ...formErrors, image: "The image must be an image.", "error_class": "input_error" });
                }else{
                showError(response.message);
                console.log(response);
                }
            }
        });
    };

    const removequestion = (index) => {
        if (!edittingBriefingQuestions) {
            const list = [...briefingQuestions];
            list.splice(index, 1);
            setBriefingQuestions(list);
            // if(list.length<1){
            //     setAddingBriefingQuestions(true);
            // }
        } else {
            showError("Finish editing question");
        }
    };
    const cancelQuestionHandler = () => {
        setAddingBriefingQuestions(false);
        setQuestionType("");
    };
    const cancelEditQuestionHandler = () => {
        setEdittingBriefingQuestions(false);
        setEditQuestionId(null);
    };

    const duplicatequestion = (index) => {
        if (!edittingBriefingQuestions) {
            const list = [...briefingQuestions];
            const question = briefingQuestions[index];
            // list.push(question);
            list.splice(index, 0, question);
            setBriefingQuestions(list);
        } else {
            showError("Finish editing question");
        }
    };
    const edittingQuestionsHandler = (index) => {
        if (!edittingBriefingQuestions) {
            setEdittingBriefingQuestions(true);
            setEditQuestionId(index);
        } else {
            toast(
                <div className="toastinner">
                    {<FormattedMessage id="Finish editing earlier question." />}
                    <a
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        &times;
                    </a>
                </div>,
                {
                    className: "errortoast",
                    position: "bottom-center",
                    duration: 3000,
                }
            );
        }
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const handleDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;

        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1),
        ];


        var clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            ),
        });
    };

    const handleDragEnd = result => {
        setPlaceholderProps({});
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            briefingQuestions,
            result.source.index,
            result.destination.index
        );

        setBriefingQuestions(items);
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    const changeLanguage = (language) => {
        let token = ReactSession.get("token");

        var data = new FormData();
        data.append("test_id", test_id);
        data.append("language", language);
        changeTestLanguage(data, token)
            .then((response) => {

                if (response.success) {
                    console.log(response);
                }
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    return (
        <LayoutResearcherFullWidth
            fixed_header_target={true}
            isLoading={false}
            wrapClass={test && lang.current == "ar" ? "arabic_wrapper_main page-min-height" : "page-min-height"}
            skipCheck={true}
            extendedFooter={false}
            activeMenu={""}
        >


            <div className="study-introduction-page-wrapper stydy-pages-wrapper">
                <div className="page-back-option-wrap">
                    <div className="container inner-page-container">
                        {(
                            <Topbar
                                test={test}
                                enablePublish={false}
                                credits_required={test.credits_required}
                                onChange={(test) => {
                                    setTest({ ...test });
                                }}
                                status={test.status}
                                lastSaved={lastSaved}
                                isLoading={false}
                                pageLoading={isLoading}
                                title={test.test_name}
                                stepCallback={() => {
                                    introductionsubmitHandler(true);
                                }}
                            />
                        )}
                    </div>
                </div>


                <div className="container inner-page-container">
                    <div className="study-steps-progress-wrap">
                        {(
                            <Progress
                                test={test}
                                completed_step={0}
                                stepCallback={() => {
                                    introductionsubmitHandler(true);
                                }}
                            />
                        )}
                    </div>

                    {isLoading &&
                        <LoadingIcon />
                    }
                    {!isLoading &&
                        <div className="target-group-data-wrapper">
                            <div className="target-group-data-repeat repaetbox1">
                                <div className="targer-group-left-wrap repaetbox1left">
                                    <h3>Test language</h3>
                                    <div className="target-form-wrap study-len-wrap">
                                        <div className="target-group-col-data">
                                            <div className="input-field static-label mb-0">
                                                <div
                                                    className="checkboxes radio_btn_class gender_btn  d-flex language-checkbox-wrap">
                                                    <label htmlFor="English" className="mt-0">
                                                        <input
                                                            type="radio"
                                                            name="len"
                                                            id="English"
                                                            value="English"
                                                            className="hidden radio_btn_input"
                                                            checked={language === "en" ? true : false}
                                                            onChange={(e) => {
                                                                setLanguage("en");
                                                                lang.current = "en";
                                                                changeLanguage("en");
                                                            }}
                                                        />
                                                        <span>
                                                            <img
                                                                className="empty-fill-icon"
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/Polygon-blank.svg"
                                                                }
                                                            />
                                                            <img
                                                                className="fill-icon"
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/pol-fill.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <p>English</p>
                                                    </label>
                                                    <label htmlFor="Arabic" className="mt-0">
                                                        <input
                                                            type="radio"
                                                            name="len"
                                                            id="Arabic"
                                                            value="Arabic"
                                                            className="hidden radio_btn_input"
                                                            checked={language === "ar" ? true : false}
                                                            onChange={() => {
                                                                setLanguage("ar");
                                                                lang.current = "ar";
                                                                changeLanguage("ar");
                                                            }}
                                                        />
                                                        <span>
                                                            <img
                                                                className="empty-fill-icon"
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/Polygon-blank.svg"
                                                                }
                                                            />
                                                            <img
                                                                className="fill-icon"
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/pol-fill.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <p>Arabic</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="repaetinfobox1"
                                    className="target-group-info-repeat repaetinfobox1 language-info-text"
                                >
                                    <h3>Test language</h3>
                                    <p>
                                        The test language will be what the participants see. Remember to write questions in the language you want your participants to see.
                                    </p>
                                    <a
                                        target="_blank"
                                        href={
                                            process.env.REACT_APP_IMG_URL +
                                            "/docs/Test-language_arabic.jpg"
                                        }
                                        className="preview-example-link expendit"
                                    >
                                        Preview example
                                    </a>
                                    <div
                                        className="seemorebox-sidebar"
                                        onClick={() => {
                                            showmore("repaetinfobox1");
                                        }}
                                    >
                                        See more
                                    </div>
                                    <div
                                        className="seelessbox-sidebar"
                                        onClick={() => {
                                            showless("repaetinfobox1");
                                        }}
                                    >
                                        See less
                                    </div>
                                </div>
                            </div>
                            <div className="target-group-data-repeat repaetbox2 welcome-section-with-subheading">
                                <div
                                    className={`${test.methodology != "Survey"
                                        ? "targer-group-left-wrap"
                                        : "targer-group-left-wrap border-0"
                                        }`}
                                >
                                    <p className="page-step-count">Page 1</p>
                                    <div className="target-form-wrap free-plan-selected-top-wrap">
                                        <label
                                            className={`${planType == "FREE"
                                                ? "check-switch d-flex justify-content-between w-100 free-plan-class"
                                                : "check-switch"
                                                }`}
                                        >
                                            <p className="big-label-text pl-0">Welcome page</p>
                                            {planType === "FREE" && (
                                                <span>
                                                    <Link
                                                        className={"updrate-plan-link"}
                                                        to={"/researcher/billing/plan/change"}
                                                    >
                                                        Upgrade to PRO PLAN
                                                    </Link>
                                                    to edit the welcome page{" "}
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/lock-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    {isWelcome ? (
                                        <div className={`intro-page-text-hold ${(lang.current == "ar") ? "arabic_wrapper" : ""}`}>
                                            <div className="row row-auto-margin">
                                                <div className="col-md-6">
                                                    <div className="image-size-info">Images files must be under 2MB</div>
                                                    <div
                                                        className={`${planType == "FREE"
                                                            ? "project-modal-img disabled-item"
                                                            : "project-modal-img"
                                                            }`}
                                                    >

                                                        {!previewUrl &&
                                                            (testImage ? (
                                                                <>
                                                                    <div className="uploaded-project-img">
                                                                        <img
                                                                            src={
                                                                                process.env.REACT_APP_IMG_URL + testImage
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    </div>
                                                                    <img
                                                                        className="remove-img"
                                                                        onClick={removeImage}
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/cross.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                </>
                                                            ) : (

                                                                <div
                                                                    className="upload-project-img"
                                                                    onDragOver={handleOnDragOver}
                                                                    onDrop={handleOnDrop}
                                                                >
                                                                    <img
                                                                        src={
                                                                            process.env.REACT_APP_URL +
                                                                            "images/upload-img.svg"
                                                                        }
                                                                        alt="img"
                                                                    />
                                                                    <p>
                                                                        Drag and drop an image, or &nbsp;
                                                                        <b>
                                                                            Browse{" "}
                                                                            {planType == "PAID" && (
                                                                                <input
                                                                                    type="file"
                                                                                    onChange={handleFileInput}
                                                                                />
                                                                            )}
                                                                        </b>
                                                                    </p>

                                                                </div>
                                                            ))}
                                                        {previewUrl && (
                                                            <>
                                                                <div className="uploaded-project-img">
                                                                    <img src={previewUrl} alt="img" />
                                                                </div>
                                                                <img
                                                                    className="remove-img"
                                                                    onClick={removeImage}
                                                                    src={
                                                                        process.env.REACT_APP_URL + "images/cross.svg"
                                                                    }
                                                                    alt="img"
                                                                />
                                                            </>
                                                        )}
                                                        <div
                                                            className="uploaded-project-img"
                                                            style={{ display: "none" }}
                                                        >
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/project-img.png"
                                                                }
                                                                alt="img"
                                                            />
                                                        </div>


                                                        {formErrors.image != null && (
                                                            <span className={formErrors.error_class}>
                                                                {formErrors.image}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="welcome-page-data-wrap">
                                                        <div className="create-projectmodal-form-group">
                                                            <InputField
                                                                label="Title of the page"
                                                                onChange={set("test_name")}
                                                                onClear={() => {
                                                                    setTimeout(() => {
                                                                        setFormValues((oldValues) => ({
                                                                            ...oldValues,
                                                                            test_name: "",
                                                                        }));
                                                                    }, 1000);
                                                                }}
                                                                value={formValues.test_name}
                                                                maxLength="45"
                                                                error={formErrors.test_name}
                                                                error_class={formErrors.error_class}
                                                                id="title"
                                                                rtl={language == "ar" ? true : false}
                                                                readOnly={planType == "FREE" ? true : false}
                                                            />
                                                        </div>
                                                        <div className="create-projectmodal-form-group">
                                                            <TextAreaField
                                                                autosize={true}
                                                                value={formValues.message}
                                                                onChange={set("message")}
                                                                maxLength="300"
                                                                label="Edit your welcome message"
                                                                language={language}
                                                                readOnly={planType == "FREE" ? true : false}
                                                                onClear={() => {
                                                                    setTimeout(() => {
                                                                        setFormValues((oldValues) => ({
                                                                            ...oldValues,
                                                                            message: "",
                                                                        }));
                                                                    }, 1000);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {isWelcome ? (
                                    <div
                                        id="repaetinfobox2"
                                        className="target-group-info-repeat repaetinfobox2"
                                    >
                                        <h3>Welcome page</h3>
                                        <p>
                                            To help participants feel more at home, personalise your welcome
                                            page with a title, background, and custom message.
                                        </p>
                                        <img
                                            src={process.env.REACT_APP_URL + "images/uq_welcome-image_reference.jpg"}
                                            alt="img"
                                            className="welcome-page-img-prev"
                                        />

                                        <div
                                            className="seemorebox-sidebar"
                                            onClick={() => {
                                                showmore("repaetinfobox2");
                                            }}
                                        >
                                            See more
                                        </div>

                                        <div
                                            className="seelessbox-sidebar"
                                            onClick={() => {
                                                showless("repaetinfobox2");
                                            }}
                                        >
                                            See less
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <div className="target-group-data-repeat">
                                <div className="targer-group-left-wrap  border-0">
                                    {test.methodology != "Survey" && (
                                        <>
                                            <p className="page-step-count">Page 2</p>
                                            <div className="target-form-wrap">
                                                <label
                                                    className={`${planType == "FREE"
                                                        ? "check-switch free-plan-selected-top-wrap"
                                                        : "check-switch"
                                                        }`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={Boolean(isBriefingQuestions)}
                                                        onChange={briefingQuestionsHandler}
                                                    />
                                                    <span className="check-slider"></span>
                                                    <p className="big-label-text ">Briefing questions</p>
                                                    {planType === "FREE" && (
                                                        <span>
                                                            <Link
                                                                className={"updrate-plan-link"}
                                                                to={"/researcher/billing/plan/change"}
                                                            >
                                                                Upgrade to PRO PLAN{" "}
                                                            </Link>{" "}
                                                            to add briefing questions{" "}
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/lock-icon.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                        </>
                                    )}
                                    {isBriefingQuestions ? (
                                        <div className={`ans-list-wrap question-prev-wrap mt-32 ${(lang.current == "en") ? "" : "arabic_wrapper"}`}>
                                            {briefingQuestions.length === 0 ? (
                                                <div className="nodata-wrap"></div>
                                            ) : (
                                                <div className="added-question-wrap mb-32" style={{ position: 'relative' }}>
                                                    <DragDropContext
                                                        //   onDragEnd={(param) => {
                                                        //     if (edittingBriefingQuestions) {
                                                        //       showError("Finish editing your question first.");
                                                        //     } else {
                                                        //       const srcI = param.source.index;
                                                        //       const desI = param.destination.index;
                                                        //       briefingQuestions.splice(
                                                        //         desI,
                                                        //         0,
                                                        //         briefingQuestions.splice(srcI, 1)[0]
                                                        //       );
                                                        //     }
                                                        //    setPlaceholderProps({});
                                                        //   }}
                                                        onDragEnd={handleDragEnd}
                                                        onDragStart={handleDragStart}
                                                        onDragUpdate={handleDragUpdate}
                                                    >
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}
                                                                >
                                                                    {briefingQuestions.map((question, index) => (
                                                                        <div key={index}
                                                                            className="survey-added-question-repeat"
                                                                        >
                                                                            <Draggable
                                                                                draggableId={"draggable-" + index}
                                                                                index={index}
                                                                            >
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        className={
                                                                                            snapshot.isDragging
                                                                                                ? "question-answers-dragging"
                                                                                                : ""
                                                                                        }
                                                                                    >
                                                                                        {editQuestionId === index ? (
                                                                                            <></>
                                                                                        ) : (
                                                                                            <div
                                                                                                key={index}
                                                                                                className="survey-question-preview"
                                                                                            >
                                                                                                <div
                                                                                                    className={`question-answer-holder ${(lang.current == "en") ? "" : "arabic_wrapper"}`}>
                                                                                                    <div className="add-more-question-option">
                                                                                                        <div
                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                            <div
                                                                                                                className="ans-icon"
                                                                                                                {...provided.dragHandleProps}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        process.env
                                                                                                                            .REACT_APP_URL +
                                                                                                                        "images/drag-icon.svg"
                                                                                                                    }
                                                                                                                    alt="img"
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="ans-data">
                                                                                                                {question.questionType ==
                                                                                                                    "free" ? (
                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                        Question {index + 1} -
                                                                                                                        Free Text
                                                                                                                    </h3>
                                                                                                                ) : question.questionType ==
                                                                                                                    "singlechoice" ? (
                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                        Question {index + 1} -
                                                                                                                        Single
                                                                                                                        Choice
                                                                                                                    </h3>
                                                                                                                ) : question.questionType ==
                                                                                                                    "multiplechoice" ? (
                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                        Question {index + 1} -
                                                                                                                        Multi Choice
                                                                                                                    </h3>
                                                                                                                ) : question.questionType ==
                                                                                                                    "ratingscale" ? (
                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                        Question {index + 1} -
                                                                                                                        Rating Scale
                                                                                                                    </h3>
                                                                                                                ) : question.questionType ==
                                                                                                                    "likertscale" ? (
                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                        Question {index + 1} -
                                                                                                                        Likert Scale
                                                                                                                    </h3>
                                                                                                                ) : (
                                                                                                                    <h3 className="h3 mb-0 font-inter">
                                                                                                                        Question {index + 1} -
                                                                                                                        Ranking
                                                                                                                        Scale
                                                                                                                    </h3>)}
                                                                                                                {question.is_optional ==
                                                                                                                    true && (
                                                                                                                        <span
                                                                                                                            className="optional-span">
                                                                                                                            optional
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="ans-close-row ans-edit-icon">
                                                                                                                <span onClick={() =>
                                                                                                                    duplicatequestion(index)
                                                                                                                }>
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            process.env
                                                                                                                                .REACT_APP_URL +
                                                                                                                            "images/copy-icon.svg"
                                                                                                                        }
                                                                                                                        alt="img"

                                                                                                                    />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="ans-close-row ans-edit-icon">
                                                                                                                <span onClick={() =>
                                                                                                                    edittingQuestionsHandler(
                                                                                                                        index
                                                                                                                    )
                                                                                                                }>
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            process.env
                                                                                                                                .REACT_APP_URL +
                                                                                                                            "images/edit-icon.svg"
                                                                                                                        }
                                                                                                                        alt="img"

                                                                                                                    />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="ans-close-row">
                                                                                                                <span onClick={() =>
                                                                                                                    removequestion(index)
                                                                                                                }>
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            process.env
                                                                                                                                .REACT_APP_URL +
                                                                                                                            "images/cross.svg"
                                                                                                                        }
                                                                                                                        alt="img"

                                                                                                                    />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <p className="survey-question-added-info">
                                                                                                            {question.question}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    {question.questionType !=
                                                                                                        "free" && (
                                                                                                            <div
                                                                                                                className="ans-list-wrap your-selected-answer-view">
                                                                                                                <p className="small-info-heading">
                                                                                                                    Your answers
                                                                                                                </p>

                                                                                                                {(question.questionType != "ratingscale" && question.questionType != "likertscale") && question.options.map(
                                                                                                                    (option, index) => (

                                                                                                                        <div
                                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    process.env
                                                                                                                                        .REACT_APP_URL +
                                                                                                                                    "images/Polygon-bg.svg"
                                                                                                                                }
                                                                                                                                alt="img"
                                                                                                                            />

                                                                                                                            <div
                                                                                                                                className="ans-data">
                                                                                                                                {option}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                )}
                                                                                                                {((question.questionType == "ratingscale") || (question.questionType == "likertscale")) && (
                                                                                                                    <div
                                                                                                                        className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                        <div
                                                                                                                            className="rating-survey-view-mode">
                                                                                                                            {question.questionType == "ratingscale" && question.options.map(
                                                                                                                                (option, index) => (

                                                                                                                                    <label
                                                                                                                                        for="radio4">
                                                                                                                                        <p>{option}</p>
                                                                                                                                        <input
                                                                                                                                            id={`radio-${index}`}
                                                                                                                                            type="radio"
                                                                                                                                            name="ans"
                                                                                                                                            value={option}
                                                                                                                                            className="hidden radio_btn_input"
                                                                                                                                            required="required" />
                                                                                                                                        <span>
                                                                                                                                            <img
                                                                                                                                                className="empty-fill-icon"
                                                                                                                                                src={
                                                                                                                                                    process.env.REACT_APP_URL +
                                                                                                                                                    "images/Polygon-blank.svg"
                                                                                                                                                }
                                                                                                                                            />

                                                                                                                                        </span>
                                                                                                                                    </label>

                                                                                                                                )
                                                                                                                            )}
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            className="likert-question-data-hold likert-view-wrap">

                                                                                                                            {question.questionType == "likertscale" && (
                                                                                                                                <div
                                                                                                                                    className="survey-rating-options-wrap">
                                                                                                                                    <div
                                                                                                                                        className="bd-question-radio-data">
                                                                                                                                        <div
                                                                                                                                            className="checkboxes radio_btn_class  d-flex rating-answer-options-radio mb-0 mt-0">

                                                                                                                                            <div
                                                                                                                                                className="ans-list-wrap likert-scale-option-add likert-scale-top-input-wrap">
                                                                                                                                                <div
                                                                                                                                                    className="ans-list-repeat saved-ans-repeat">

                                                                                                                                                    <div
                                                                                                                                                        className="likert-option-left">
                                                                                                                                                        <div
                                                                                                                                                            className="ans-icon">
                                                                                                                                                            &nbsp;
                                                                                                                                                        </div>
                                                                                                                                                        <div
                                                                                                                                                            className="ans-data">
                                                                                                                                                            &nbsp;
                                                                                                                                                        </div>
                                                                                                                                                    </div>

                                                                                                                                                    <div
                                                                                                                                                        className="likert-option-right">
                                                                                                                                                        {question.options.map((option, index) => (
                                                                                                                                                            <label
                                                                                                                                                                for="radio4">
                                                                                                                                                                <p>{option}</p>
                                                                                                                                                            </label>
                                                                                                                                                        ))}
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>


                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                            {question.questionType == "likertscale" && question.subQuestions.map(
                                                                                                                                (subQuestion, index) => (

                                                                                                                                    <div
                                                                                                                                        className="ans-list-wrap likert-scale-option-add">
                                                                                                                                        <div
                                                                                                                                            className="ans-list-repeat saved-ans-repeat">
                                                                                                                                            <div
                                                                                                                                                className="likert-option-left">
                                                                                                                                                <div
                                                                                                                                                    className="ans-data">{subQuestion}</div>
                                                                                                                                            </div>


                                                                                                                                            <div
                                                                                                                                                className="likert-option-right">
                                                                                                                                                <div
                                                                                                                                                    className="survey-rating-options-wrap">
                                                                                                                                                    <div
                                                                                                                                                        className="bd-question-radio-data">
                                                                                                                                                        <div
                                                                                                                                                            className="checkboxes radio_btn_class  d-flex rating-answer-options-radio">
                                                                                                                                                            {question.options.map((option, index) => (
                                                                                                                                                                <label
                                                                                                                                                                    for="radio4">
                                                                                                                                                                    {/* <p>{option}</p> */}
                                                                                                                                                                    <input
                                                                                                                                                                        id={`radio-${index}`}
                                                                                                                                                                        type="radio"
                                                                                                                                                                        name="ans"
                                                                                                                                                                        value={option}
                                                                                                                                                                        className="hidden radio_btn_input"
                                                                                                                                                                        required="required" />
                                                                                                                                                                    <span>
                                                                                                                                                                        <img
                                                                                                                                                                            className="empty-fill-icon"
                                                                                                                                                                            src={
                                                                                                                                                                                process.env.REACT_APP_URL +
                                                                                                                                                                                "images/Polygon-blank.svg"
                                                                                                                                                                            }
                                                                                                                                                                        />
                                                                                                                                                                        <img
                                                                                                                                                                            className="fill-icon"
                                                                                                                                                                            src={
                                                                                                                                                                                process.env.REACT_APP_URL +
                                                                                                                                                                                "images/pol-fill.svg"
                                                                                                                                                                            }
                                                                                                                                                                        />
                                                                                                                                                                    </span>
                                                                                                                                                                </label>
                                                                                                                                                            ))}
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>

                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>)}
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                            <div className="question-edit">
                                                                                {edittingBriefingQuestions &&
                                                                                    editQuestionId === index && (
                                                                                        <EditQuestion
                                                                                            questionDetails={
                                                                                                briefingQuestions[index]
                                                                                            }
                                                                                            cancelEditQuestion={
                                                                                                cancelEditQuestionHandler
                                                                                            }
                                                                                            editQuestions={(question) => {
                                                                                                briefingQuestions.splice(
                                                                                                    index,
                                                                                                    1,
                                                                                                    question
                                                                                                );
                                                                                                setBriefingQuestions([
                                                                                                    ...briefingQuestions,
                                                                                                ]);
                                                                                            }}
                                                                                            language={language}
                                                                                            other={
                                                                                                lang.current == "en" ?
                                                                                                    question.questionType == "free"
                                                                                                        ? "Other"
                                                                                                        : briefingQuestions[
                                                                                                            index
                                                                                                        ].options.slice(-1) == "Other"
                                                                                                            ? true
                                                                                                            : false : question.questionType == "free"
                                                                                                        ? "آخر"
                                                                                                        : briefingQuestions[
                                                                                                            index
                                                                                                        ].options.slice(-1) == "آخر"
                                                                                                            ? true
                                                                                                            : false
                                                                                            }
                                                                                            questionno={index + 1}
                                                                                            changeQuestionType={(questionType) => { briefingQuestions[index].questionType = questionType }}
                                                                                            addLikertSubquestions={() => { briefingQuestions[index].subQuestions = [""] }}
                                                                                        />
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                    {!isEmpty(placeholderProps) && (
                                                                        <div
                                                                            className="placeholder"
                                                                            style={{
                                                                                top: placeholderProps.clientY,
                                                                                left: placeholderProps.clientX,
                                                                                height: placeholderProps.clientHeight,
                                                                                width: placeholderProps.clientWidth,
                                                                                position: "absolute",
                                                                                borderColor: "#000000",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                            )}
                                            {!edittingBriefingQuestions &&
                                                (!addingBriefingQuestions ? (
                                                    <button
                                                        className="create-btn add-question-btn"
                                                        onClick={() => {
                                                            if (briefingQuestions.length < 5) {
                                                                setAddingBriefingQuestions(true);
                                                            } else {
                                                                toast(
                                                                    <div className="toastinner">
                                                                        {
                                                                            <FormattedMessage
                                                                                id="You've hit the maximum amount of test questions! If you need to add more, consider removing earlier ones." />
                                                                        }
                                                                        <a
                                                                            onClick={() => {
                                                                                toast.dismiss();
                                                                            }}
                                                                        >
                                                                            &times;
                                                                        </a>
                                                                    </div>,
                                                                    {
                                                                        className: "errortoast",
                                                                        position: "bottom-center",
                                                                        duration: 3000,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Add question
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_URL +
                                                                    "images/plus-white.svg"
                                                                }
                                                                alt="img"
                                                            />
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <></>
                                                ))}
                                            {addingBriefingQuestions && questionType === "" ? (
                                                <div className="add-more-question-option">
                                                    <h3 className="mt-32 mb-0 d-flex justify-content-between">
                                                        Question {briefingQuestions.length + 1}
                                                        {briefingQuestions.length>0 && (
                                                        <span className="close-question-options" onClick={() => { setAddingBriefingQuestions(false) }}>
                                                            <img alt="close-icon" src={process.env.REACT_APP_URL + "images/cross.svg"} />
                                                        </span>
                                                        )}
                                                    </h3>
                                                    <div className="stud-tiles-hold mt-32">
                                                        <Frame
                                                            questionType="FREE TEXT"
                                                            subheading="Free form answers"
                                                            bgstyle="bg-1"
                                                            onClick={() => {
                                                                setQuestionType("free");
                                                            }}
                                                        />
                                                        <Frame
                                                            questionType="SINGLE CHOICE"
                                                            subheading="One answer can be selected"
                                                            bgstyle="bg-2"
                                                            onClick={() => {
                                                                setQuestionType("singlechoice");
                                                            }}
                                                        />
                                                        <Frame
                                                            questionType="MULTIPLE-CHOICE"
                                                            subheading="Multiple answers can be selected"
                                                            bgstyle="bg-3"
                                                            onClick={() => {
                                                                setQuestionType("multiplechoice");
                                                            }}
                                                        />
                                                        <Frame
                                                            questionType="LIKERT SCALE"
                                                            subheading="Answers on a rating scale"
                                                            bgstyle="bg-2"
                                                            onClick={() => {
                                                                setQuestionType("likertscale");
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <Questions
                                                    questionType={questionType}
                                                    cancelQuestion={cancelQuestionHandler}
                                                    addQuestions={(question) => {
                                                        setBriefingQuestions([
                                                            ...briefingQuestions,
                                                            question,
                                                        ]);
                                                    }}
                                                    language={language}
                                                    questionno={briefingQuestions.length + 1}
                                                    changeQuestionType={(questionType) => { setQuestionType(questionType) }}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className="study-QA-wrap">
                                        <div className="dflex-wrap select-question-type">
                                            <h3 className="h3 mb-0 font-inter">Free text</h3>
                                            <div className="sqa-check-top">
                                                <div
                                                    className="form-group custom-control custom-checkbox mb-0 dark-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="customCheck1"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="customCheck1"
                                                    >
                                                        Optional
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="create-projectmodal-form-group">
                                            <div className="input form-group  input-field">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="question"
                                                    required
                                                />
                                                <label className="control-label" htmlFor="question">
                                                    Your question
                                                </label>
                                                <span className="clear">
                                                    <svg viewBox="0 0 24 24">
                                                        <path className="line" d="M2 2L22 22" />
                                                        <path className="long" d="M9 15L20 4" />
                                                        <path className="arrow" d="M13 11V7" />
                                                        <path className="arrow" d="M17 11H13" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <p className="project-name-guide-text">
                                                Max characters:{" "}
                                                <span className="pink-color">
                                                    <b>300</b>/300
                                                </span>
                                            </p>
                                        </div>
                                        <div className="ans-list-wrap">
                                            <p className="small-info-heading">Your answers</p>
                                            <div className="ans-list-repeat">
                                                <div className="ans-icon">
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/drag-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="ans-data">
                                                    <input type="text" />
                                                </div>
                                                <div className="ans-close-row">
                                                    <span>
                                                        <img
                                                            src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                            alt="img"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="ans-list-repeat">
                                                <div className="ans-icon">
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/drag-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="ans-data">Lorem ipsum dolor sit amet</div>
                                                <div className="ans-close-row">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                            <div className="ans-list-repeat">
                                                <div className="ans-icon">
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/drag-icon.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="ans-data">Lorem ipsum dolor sit amet</div>
                                                <div className="ans-close-row">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button-wrap d-flex justify-content-end">
                                            <button type="button" className="button secondary-btn">
                                                Cancel
                                            </button>
                                            <button type="submit" className="button primary-btn">
                                                Save
                                            </button>
                                        </div>
                                        <div className="ans-list-wrap question-prev-wrap mt-32">
                                            <div className="ans-list-repeat">
                                                <div className="ans-icon">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/drag.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="ques-count">01</div>
                                                <div className="ans-data">
                                                    <p className="small-info-heading">Free text</p>
                                                    Lorem ipsum dolor sit amet
                                                </div>
                                                <div className="ans-close-row">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                            <div className="ans-list-repeat">
                                                <div className="ans-icon">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/drag.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="ques-count">02</div>
                                                <div className="ans-data">
                                                    <p className="small-info-heading">
                                                        Single choice question
                                                    </p>
                                                    Lorem ipsum dolor sit amet
                                                </div>
                                                <div className="ans-close-row">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                            <div className="ans-list-repeat">
                                                <div className="ans-icon">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/drag.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="ques-count">03</div>
                                                <div className="ans-data">
                                                    <p className="small-info-heading">
                                                        Multi choice question
                                                    </p>
                                                    Lorem ipsum dolor sit amet
                                                </div>
                                                <div className="ans-close-row">
                                                    <img
                                                        src={process.env.REACT_APP_URL + "images/cross.svg"}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                            <button className="create-btn add-question-btn">
                                                Add question
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_URL + "images/plus-white.svg"
                                                        }
                                                        alt="img"
                                                    />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className={`${test.methodology != "Survey"
                                            ? "button-wrap d-flex justify-content-end mt-64"
                                            : "button-wrap d-flex justify-content-end"
                                            }`}
                                    >
                                        {!edittingBriefingQuestions &&
                                            questionType === "" &&
                                            (briefingQuestions.length < 1 && isBriefingQuestions ? (
                                                <button
                                                    id="r_create_step_1_intro_next_btn"
                                                    type="submit"
                                                    className="button primary-btn disabled-button"
                                                    disabled={true}
                                                >
                                                    Next
                                                </button>
                                            ) : (
                                                <button
                                                    id="r_create_step_1_intro_next_btn"
                                                    type="submit"
                                                    className="button primary-btn"
                                                    onClick={() => introductionsubmitHandler(false)}
                                                >
                                                    Next
                                                    {formLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            aria-hidden="true"
                                                            style={{ marginLeft: "5px" }}
                                                        ></i>
                                                    )}
                                                </button>
                                            ))}
                                    </div>
                                </div>
                                {test.methodology != "Survey" && (
                                    <div
                                        id="repaetinfobox3"
                                        className="target-group-info-repeat repaetinfobox3"
                                    >
                                        <h3>Briefing questions</h3>

                                        <p>
                                            Ask your testers pre-test questions to gain deeper insights.
                                        </p>
                                        <div
                                            className="seemorebox-sidebar"
                                            onClick={() => {
                                                showmore("repaetinfobox3");
                                            }}
                                        >
                                            See more
                                        </div>

                                        <div
                                            className="seelessbox-sidebar"
                                            onClick={() => {
                                                showless("repaetinfobox3");
                                            }}
                                        >
                                            See less
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className="target-group-right-side"
                                style={{ display: "none" }}
                            >
                                <div className="target-group-info-repeat">
                                    <h3>Test language</h3>
                                    <p>
                                        The test language will be what the participants see. Remember to write questions in the language you want your participants to see.
                                    </p>
                                    <a href="#" className="preview-example-link">
                                        Preview example
                                    </a>
                                </div>
                                <div className="target-group-info-repeat">
                                    <h3>Welcome page</h3>
                                    <p>
                                        To help participants feel more at home, personalise your welcome
                                        page with a title, background, and custom message.
                                    </p>
                                    <img
                                        src={process.env.REACT_APP_URL + "images/uq_welcome-image_reference.jpg"}
                                        alt="img"
                                        className="welcome-page-img-prev"
                                    />
                                </div>
                                {test.methodology != "Survey" && (
                                    <div className="target-group-info-repeat">
                                        <h3>Briefing questions</h3>
                                        <p>
                                            Ask your testers pre-test questions to gain deeper insights.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </LayoutResearcherFullWidth>
    );
}
